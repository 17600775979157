import { fromJS } from 'immutable';
import * as ActionTypes from '../../constants/ActionTypes';
import { getCreatedTaxonomyId } from '../../selectors/actions/taxonomy';
import TaxonomyType from '../../constants/TaxonomyType';

const initialState = fromJS({
  createdItemId: null,
  filterText: null,
  selectedTab: TaxonomyType.KEYWORD,
  pageSize: 500,
  selectedPageNumber: 0,
});

export default function taxonomyPageReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.API_TAXONOMY_CREATE_SUCCESS:
      return state.merge({
        createdItemId: getCreatedTaxonomyId(action),
      });
    case ActionTypes.APP_PAGE_TAXONOMY_SET_FILTERTEXT: {
      return state.get('filterText') !== action.payload.filterText
        ? state.merge({
            selectedPageNumber: 0,
            filterText: action.payload.filterText,
          })
        : state;
    }
    case ActionTypes.APP_PAGE_TAXONOMY_SET_CURRENT_TAB: {
      return state.merge({
        selectedPageNumber: 0,
        selectedTab: action.payload.tabName,
      });
    }
    case ActionTypes.APP_PAGE_TAXONOMY_SET_PAGE_NUMBER: {
      return state.merge({
        selectedPageNumber: action.payload.pageNumber,
      });
    }
    default:
      return state;
  }
}
