import merge from 'deepmerge';
import * as ActionTypes from '../../constants/ActionTypes';
import isPlainObject from 'is-plain-object';
import { getPaging } from '../../constants/HalProperties';

export const getAllPages = (initAction, paging = getPaging()) => ({
  type: ActionTypes.TXN_ALL_PAGES_INIT,
  payload: { initAction, paging },
});
export const getAllPagesComplete = (initAction, isSuccess) => ({
  type: ActionTypes.TXN_ALL_PAGES_COMPLETE,
  payload: { initAction, isSuccess },
});
export const cloneInitAction = (initAction, pagesize, page) =>
  merge(
    initAction,
    { payload: { paging: getPaging(page, pagesize) } },
    {
      isMergeableObject: isPlainObject,
    },
  );
