import BaseConnector from './Base';
import * as EntityTypes from '../constants/EntityTypes';
import * as Projections from '../constants/Projections';
import * as Searches from '../constants/Searches';
import * as Sort from '../constants/Sort';
import { DECISION_LINKS } from '../constants/ApiLinks';
import { getSortConfiguration } from '../constants/HalProperties';
import { DECISION } from '../constants/EntityTypes';
import EntityFields from '../constants/EntityFields';

export default class Decision extends BaseConnector {
  constructor(...args) {
    super(...args);
    this.entityType = EntityTypes.DECISION;
  }

  getById(id, projection) {
    const url = this.query().withId(id).withProjection(projection).build();
    return this.authGet(url);
  }

  getDecisionFileByDecisionId(id) {
    const url = this.query().withId(id).withLink(DECISION_LINKS.pdf).build();
    return this.authGet(url);
  }

  getByIds(ids) {
    const query = this.query()
      .withSearch(Searches.findByIds, [ids])
      .withProjection(Projections.WITHID)
      .withSize(1000) // TODO: HACK, Works until documents grow larger :/
      .build();

    return this.authGet(query);
  }

  getAll(paging, sort) {
    return this.authGet(
      this.query()
        .withPaging(paging)
        .withSort(sort)
        .withProjection(Projections.WITHID)
        .build(),
    );
  }

  getByUri(uri) {
    return this.authGet(
      this.query()
        .withSearch(Searches.findByUri, uri)
        .withProjection(Projections.WITHID)
        .build(),
    );
  }

  getAllByCourts(courts, paging, sort) {
    const query = this.query()
      .withSearch(Searches.findByCourtIdIn, [courts])
      .withProjection(Projections.WITHID)
      .withPaging(paging)
      .withSort(sort)
      .build();
    return this.authGet(query);
  }

  getAllByDecisionTypes(decisionTypes, paging, sort) {
    const query = this.query()
      .withSearch(Searches.findByDecisionTypeIn, [decisionTypes])
      .withProjection(Projections.WITHID)
      .withPaging(paging)
      .withSort(sort)
      .build();
    return this.authGet(query);
  }

  getAllByDecisionTypeAndCourts(decisions, courts, paging, sort) {
    const query = this.query()
      .withSearch(Searches.findByDecisionTypeInAndCourtIdIn, [
        decisions,
        courts,
      ])
      .withProjection(Projections.WITHID)
      .withPaging(paging)
      .withSort(sort)
      .build();
    return this.authGet(query);
  }

  createDecision(decision) {
    return this.authPost(this.query().build(), decision);
  }

  searchByEditorNameOrCaseName(editorNameOrCaseName) {
    const url = this.query()
      .withSearch(Searches.findDecisionEditorNameOrCaseName, [
        editorNameOrCaseName,
      ])
      .withSort(
        getSortConfiguration(
          EntityFields[DECISION].EDITOR_NAME,
          Sort.Direction.ASC,
        ),
      )
      .build();
    return this.authGet(url);
  }

  getAllDecisionCase() {
    const url = this.query().withLink(DECISION_LINKS.decisionCaseTypes).build();
    return this.authGet(url);
  }

  getAllDecisionItem() {
    const url = this.query().withLink(DECISION_LINKS.decisionTypes).build();
    return this.authGet(url);
  }

  uploadFile(decisionId, file) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.authFetch(
      this.query().withId(decisionId).withLink(DECISION_LINKS.pdf).build(),
      { method: 'POST', body: formData },
      false,
    );
  }

  getNewestDecisions() {
    const url = this.query()
      .withSort(
        getSortConfiguration(Sort.createdTimeStamp, Sort.Direction.DESC),
      )
      .withSize(15)
      .build();
    return this.authGet(url);
  }

  updateDecision(decision) {
    return this.authPatch(this.query().withId(decision.id).build(), decision);
  }
}
