/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import { push } from 'connected-react-router/immutable';
import { Badge } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';
import { Trans } from 'react-i18next';
import * as searchPageSelectors from '../../selectors/page/search';
import * as searchPageActions from '../../actions/page/search';

const pillButtonStyles = {
  cursor: 'pointer',
  filter: 'brightness(85%)',
  ':hover': {
    filter: 'brightness(100%)',
  },
};

const isGetter = (x, name) =>
  (Object.getOwnPropertyDescriptor(x, name) || {}).get;
const deepGetters = (x) =>
  x &&
  x !== Object.prototype &&
  Object.getOwnPropertyNames(x)
    .filter((name) => isGetter(x, name))
    .concat(deepGetters(Object.getPrototypeOf(x)) || []);
const distinctDeepGetters = (x) => Array.from(new Set(deepGetters(x)));

const isDefined = (value) => value !== undefined && value !== '';

class FilterOverview extends React.Component {
  constructor(props) {
    super(props);
    this.remove = this.remove.bind(this);
  }

  remove(key) {
    const filter = this.props.currentSearchFilter.copy();
    if (Array.isArray(filter[key])) {
      filter[key] = [];
    } else if (
      filter[key].hasOwnProperty('max') &&
      filter[key].hasOwnProperty('min')
    ) {
      filter[`${key}Max`] = undefined;
      filter[`${key}Min`] = undefined;
    } else {
      filter[key] = undefined;
    }
    this.props.actions.updateSearch(filter);
  }

  render() {
    const { currentSearchFilter, translationKey, filterSet } = this.props;
    return (
      <div>
        {distinctDeepGetters(currentSearchFilter)
          .map((key) => [key, currentSearchFilter[key]])
          .filter(
            ([key, value]) =>
              !!value &&
              filterSet.has(key) &&
              (!(value instanceof Array) || value.length) &&
              (!(value instanceof Object) || Object.keys(value).length) &&
              (!(value.hasOwnProperty('max') && value.hasOwnProperty('min')) ||
                isDefined(value.max) ||
                isDefined(value.min)),
          )
          .map(([key, value]) => {
            if (value instanceof Array) {
              return [
                key,
                value
                  .map((inner) => (inner.label ? inner.label : inner))
                  .reduce((a, b) => `${a}, ${b}`),
              ];
            } else if (
              value.hasOwnProperty('max') &&
              value.hasOwnProperty('min')
            ) {
              if (isDefined(value.max)) {
                return [
                  key,
                  isDefined(value.min)
                    ? `${value.min} - ${value.max}`
                    : `< ${value.max}`,
                ];
              } else {
                return [key, `> ${value.min}`];
              }
            } else {
              return [key, value.label ? value.label : value];
            }
          })
          .map(([key, value]) => (
            <Badge pill variant="primary">
              <Trans i18nKey={`search-page.${translationKey}.${key}`} />
              {`: ${value} `}
              <Badge
                variant="dark"
                css={pillButtonStyles}
                onClick={() => this.remove(key)}
              >
                <FaTimes />
              </Badge>
            </Badge>
          ))}
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  currentSearchFilter: searchPageSelectors.getCurrentSearchFilter,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      push: push,
      updateSearch: searchPageActions.updateFilter,
    },
    dispatch,
  ),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withImmutablePropsToJS,
)(FilterOverview);
