import { fromJS } from 'immutable';
import * as ActionTypes from '../../constants/ActionTypes';
import { compressApiResponse } from '../api';
import { getResponseData } from '../../selectors/actions';

const initialState = fromJS({
  currentNode: null,
  editNode: null,
});

export default function decisionPageReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.APP_PAGE_DECISION_SELECT_NODE:
      return state.merge(
        fromJS({
          currentNode: action.payload.node,
        }),
      );
    case ActionTypes.API_DECISION_GET_RELATED_BY_ID_SUCCESS:
      const node = getResponseData(action);
      return state.merge(
        fromJS({
          editNode: node ? compressApiResponse({ ...node }) : null,
          isLoading: false,
        }),
      );
    case ActionTypes.API_DECISION_GET_RELATED_BY_ID_ERROR:
      return state.merge({ editNode: null, isLoading: false });
    case ActionTypes.API_DECISION_GET_RELATED_BY_ID_REQUEST:
      return state.merge({ editNode: null, isLoading: true });
    case ActionTypes.APP_PAGE_DECISION_SET_PAGING:
      return state.set('paging', action.payload.paging);
    default:
      return state;
  }
}
