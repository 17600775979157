import * as taxonomyApiSelectors from '../api/taxonomy';
import { fromJS, Map } from 'immutable';
import { createSelector } from 'reselect';
import * as theoryItemApiSelectors from '../api/theoryitem';
import * as lawItemApiSelectors from '../api/lawitem';
import * as lawApiSelectors from '../api/law';
import * as decisionApiSelectors from '../api/decision';
import * as processStepApiSelectors from '../api/processstep';
import * as processApiSelectors from '../api/process';
import { buildTreeExcerpt } from '../store/tree';
import * as appSelector from '../store/app';
import * as lawSelector from './law';
import * as processSelector from './process';
import * as jurisdictionApiSelectors from '../api/jurisdiction';
import * as courtApiSelectors from '../api/court';
import * as decisionSelector from './decision';

const mapTaxonomyToSelectValue = (val) =>
  Map({
    value: val.getIn(['node', 'id']),
    label: val.getIn(['node', 'description']),
  });

export const getKeywords = createSelector(
  taxonomyApiSelectors.getAllKeywords,
  (taxonomyKeywords) =>
    taxonomyKeywords
      .map(mapTaxonomyToSelectValue)
      .toList()
      .sort((a, b) => a.get('label').localeCompare(b.get('label'))),
);

export const getCurrentSearchFilter = (state) =>
  state.getIn(['page', 'search', 'searchFilter']);

export const getCurrentTab = (state) => state.getIn(['page', 'search', 'tab']);

export const getKeywordsForCurrentFilter = createSelector(
  taxonomyApiSelectors.getAllKeywords,
  getCurrentSearchFilter,
  (taxonomyKeywords, filter) =>
    fromJS(filter.relatedTaxonomy)
      .map((id) => taxonomyKeywords.get(id))
      .filter((x) => !!x)
      .map(mapTaxonomyToSelectValue),
);

export const getLatestSearchError = (state) =>
  state.getIn(['page', 'search', 'latestSearchError']);

export const isSearchRunningForCurrentFilter = (state) =>
  state.getIn(['page', 'search', 'isSearchRunningForCurrentFilter']);

export const getLatestSearchResult = (state) =>
  state.getIn(['page', 'search', 'latestSearchResult']);

// region Theory Items
export const getLatestSearchResultTheoryItemIds = createSelector(
  getLatestSearchResult,
  (searchResult) =>
    searchResult !== null ? searchResult.get('theoryItemIds') : null,
);

export const getLatestSearchTheoryItemSummary = createSelector(
  getLatestSearchResultTheoryItemIds,
  theoryItemApiSelectors.getAll,
  (theoryItemIds, allTheoryItems) => {
    const stats = countSearchItemStats(allTheoryItems, theoryItemIds);
    return fromJS({
      theoryItemIds,
      theoryItems: stats.foundItems,
      missingEntityIds: stats.missingIds,
      totalItems: stats.totalItems,
      totalItemsMissing: stats.totalItemsMissing,
    });
  },
);

export const getLatestSearchTheoryItemTree = createSelector(
  getLatestSearchTheoryItemSummary,
  theoryItemApiSelectors.getAll,
  (theoryItemSummary, allTheoryItems) =>
    buildTreeExcerpt(allTheoryItems, theoryItemSummary.get('theoryItems')),
);
// endregion Theory Items

// region Law Items
export const getLatestSearchResultLawItemIds = createSelector(
  getLatestSearchResult,
  (searchResult) =>
    searchResult !== null ? searchResult.get('lawItemIds') : null,
);

export const getLatestSearchLawItemSummary = createSelector(
  getLatestSearchResultLawItemIds,
  lawItemApiSelectors.getAll,
  (lawItemIds, allLawItems) => {
    const stats = countSearchItemStats(allLawItems, lawItemIds);
    return fromJS({
      lawItemIds,
      lawItems: stats.foundItems,
      missingEntityIds: stats.missingIds,
      totalItems: stats.totalItems,
      totalItemsMissing: stats.totalItemsMissing,
    });
  },
);

export const getLatestSearchLawItemTree = createSelector(
  getLatestSearchLawItemSummary,
  appSelector.getFilteredJurisdictions,
  lawSelector.getLawGroupTopLevel,
  lawSelector.getAllLawGroups,
  lawApiSelectors.getAll,
  lawItemApiSelectors.getAll,
  (
    lawItemSummary,
    jurisdictions,
    topLawGroups,
    allLawGroups,
    allLaws,
    allLawItems,
  ) => {
    const tree = buildTreeExcerpt(allLawItems, lawItemSummary.get('lawItems'));
    const lawTree = lawSelector.buildLawTree(allLaws, tree);
    return lawSelector.buildLawGroupNavigationTree(
      jurisdictions,
      topLawGroups,
      allLawGroups,
      lawTree,
    );
  },
);
// endregion Law Items

// region Decisions
export const getLatestSearchResultDecisionIds = createSelector(
  getLatestSearchResult,
  (searchResult) =>
    searchResult !== null ? searchResult.get('decisionIds') : null,
);

export const getLatestSearchDecisionSummary = createSelector(
  getLatestSearchResultDecisionIds,
  decisionApiSelectors.getAll,
  (decisionIds, allDecisions) => {
    const stats = countSearchItemStats(allDecisions, decisionIds);
    return fromJS({
      decisionIds,
      decisions: stats.foundItems,
      missingEntityIds: stats.missingIds,
      totalItems: stats.totalItems,
      totalItemsMissing: stats.totalItemsMissing,
    });
  },
);

export const getLatestSearchDecisionsTree = createSelector(
  getLatestSearchDecisionSummary,
  appSelector.getFilteredJurisdictions,
  courtApiSelectors.getAll,
  (decisionSummary, filteredJurisdictions, allCourts) => {
    const searchDecisions = decisionSummary
      .get('decisions')
      .map((decision) => decision.set('highlight', true));
    return decisionSelector.buildNavigationTree(
      filteredJurisdictions,
      allCourts,
      searchDecisions,
    );
  },
);
// endregion Decisions

// region Process Steps
export const getLatestSearchResultProcessStepIds = createSelector(
  getLatestSearchResult,
  (searchResult) =>
    searchResult !== null ? searchResult.get('processStepIds') : null,
);

export const getLatestSearchProcessStepSummary = createSelector(
  getLatestSearchResultProcessStepIds,
  processStepApiSelectors.getAll,
  (processStepIds, allProcessSteps) => {
    const stats = countSearchItemStats(allProcessSteps, processStepIds);
    return fromJS({
      processStepIds,
      processSteps: stats.foundItems,
      missingEntityIds: stats.missingIds,
      totalItems: stats.totalItems,
      totalItemsMissing: stats.totalItemsMissing,
    });
  },
);

export const getLatestSearchProcessStepTree = createSelector(
  getLatestSearchProcessStepSummary,
  lawApiSelectors.getAll,
  processApiSelectors.getAll,
  processStepApiSelectors.getAll,
  (processStepSummary, laws, processes, processSteps) => {
    const processStepTree = buildTreeExcerpt(
      processSteps,
      processStepSummary.get('processSteps'),
    );
    return processSelector.buildProcessTree(laws, processes, processStepTree);
  },
);
// endregion Process Steps

// region Helper methods
const countSearchItemStats = (allItems, ids) => {
  const missingIds = new Set(),
    foundItems = [];
  if (ids !== null) {
    ids.forEach((id) => {
      let item = allItems.get(parseInt(id));
      if (item) {
        foundItems.push(item);
        // check if we have the parents
        let parentId;
        while ((parentId = item.get('parent')) != null) {
          // != means we also don't want it to be undefined (which it is for Decisions - they never have parents)
          item = allItems.get(parentId);
          if (!item?.get('node')) {
            missingIds.add(parentId);
            break;
          }
        }
      } else {
        missingIds.add(id);
      }
    });
  }

  return {
    totalItems: ids?.size || 0,
    foundItems,
    missingIds: [...missingIds],
    totalItemsMissing: missingIds.size,
  };
};
// endregion Helper methods
