import { all, fork } from 'redux-saga/effects';
import taxonomySaga from './taxonomy';
import courtSaga from './court';
import theoryItemSaga from './theoryitem';
import processSaga from './process';

export default function* dataRootSaga() {
  yield all([
    fork(taxonomySaga),
    fork(courtSaga),
    fork(theoryItemSaga),
    fork(processSaga),
  ]);
}
