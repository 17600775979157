import { fromJS } from 'immutable';
import * as ActionTypes from '../../constants/ActionTypes';
import * as EntityTypes from '../../constants/EntityTypes';
import {
  buildStructuredTree,
  mergeEntityListToState,
  mergeResponseToState,
  mergeSingleResponseToState,
} from './index';
import {
  getAllPagesRequestType,
  getResponseData,
} from '../../selectors/actions';
import { createTagName } from '../../utils/filter';
import { HAL_LIST_PROPERTY } from '../../constants/HalProperties';
import { STATE_ENTITY_PROPERTY } from '../../constants/StateProperties';

const initialState = fromJS({
  data: {},
  [STATE_ENTITY_PROPERTY.searchdata]: {},
  metaData: {
    decisionTypes: [],
    decisionCaseTypes: [],
    decisionMeritTypes: ['AN', 'ER'],
    decisionReactionTypes: ['SUS', 'ANN', 'PB1', 'PB2', 'UVI', 'ØKS', 'ERS'],
    decisionSuspensiveEffect: ['SE1', 'SE2', 'SE3'],
    decisionFormalityTypes: ['AF1', 'AF2', 'AF3', 'OV', 'PF', 'ØF'],
    decisionReactionFormality: [
      'AF+',
      'AF-',
      'OV+',
      'OV-',
      'PF+',
      'PF-',
      'ØF+',
      'ØF-',
    ],
    decisionProcurementForm: [
      'LIC',
      'TBL',
      'UBL',
      'UBD',
      'FVD',
      'FSD',
      'KCD',
      'TYD',
      'BAD',
      'IKD',
      'LFF',
    ],
    decisionProcurementFormProcedure: [
      '-UDB',
      'LIC',
      'OFU',
      'BGU',
      'FU1',
      'FU2',
      'KCU',
      'KPD',
      'KVO',
      'IVP',
      'INH',
      'DIR',
      'RD1',
      'RD2',
      'RF3',
      'DY1',
      'DY2',
      'LIG',
      '+GO',
      '-GO',
      'PJK',
      'TBI',
    ],
    decisionProcurementFormAddon: ['HAS', 'FHM', 'ELE', 'AUK', 'OPE'],
  },
});

export default function decisionApiReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.AUTH_LOGIN_COMPLETE:
    case ActionTypes.AUTH_LOGOUT_SUCCESS:
      return initialState;
    case ActionTypes.API_DECISIONS_GET_SUCCESS:
    case ActionTypes.API_DECISION_GET_BY_IDS_SUCCESS:
      return mergeResponseToState(state, action, EntityTypes.DECISION);
    case ActionTypes.API_DECISION_GET_BY_ID_SUCCESS:
      return mergeSingleResponseToState(state, action, EntityTypes.DECISION);
    case ActionTypes.API_GET_RELATION_TREE_DECISIONS:
      return mergeEntityListToState(
        state,
        action.payload,
        EntityTypes.DECISION,
      );
    case ActionTypes.TXN_ALL_PAGES_COMPLETE:
      if (
        getAllPagesRequestType(action) === ActionTypes.API_DECISION_GET_ALL_INIT
      ) {
        return buildStructuredTree(state);
      }
      return state;
    case ActionTypes.API_DECISION_SEARCH_BY_EDITOR_NAME_OR_CASE_NAME_SUCCESS:
      const responseData =
        getResponseData(action)[HAL_LIST_PROPERTY][EntityTypes.DECISION];
      const searchKey = createTagName(
        action.payload.initAction.payload.editorNameOrCaseName,
      );
      return state.setIn(
        [STATE_ENTITY_PROPERTY.searchdata, searchKey],
        fromJS(responseData),
      );
    case ActionTypes.API_DECISION_CASE_TYPE_GET_ALL_SUCCESS:
      return state.setIn(
        ['metaData', 'decisionCaseTypes'],
        fromJS(action.payload.responseData),
      );
    case ActionTypes.API_DECISION_TYPE_GET_ALL_SUCCESS:
      return state.setIn(
        ['metaData', 'decisionTypes'],
        fromJS(action.payload.responseData),
      );
    case ActionTypes.API_DECISIONS_GET_TOP_SUCCESS:
      return mergeResponseToState(state, action, EntityTypes.DECISION, true);
    default:
      return state;
  }
}
