import { call, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import { generateApiSaga } from '../../../utils/sagas';
import Decision from '../../../connectors/Decision';

const update = generateApiSaga(Decision, function* (connector, action) {
  const decision = action.payload.decision;
  return yield call([connector, connector.updateDecision], decision);
});

export default function* updateDecision() {
  yield takeEvery(ActionTypes.API_UPDATE_DECISION_INIT, update);
}
