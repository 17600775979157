/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import PropTypes from 'prop-types';
import { fontColors, uiColors } from '../styles/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const styles = (offset) => ({
  backgroundColor: uiColors.BRAND,
  color: fontColors.WHITE,
  border: '1px solid ' + uiColors.GREY,
  position: 'absolute',
  bottom: '1rem',
  right: `${(offset || 0) * 3.5 + 1}rem`,
  borderRadius: '50%',
  height: '3rem',
  width: '3rem',

  '&:hover:enabled': {
    borderColor: uiColors.WHITE,
    transform: 'scale(1.1)',
  },
  '&:focus': {
    outline: '0',
  },
});

class FloatingButton extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    icon: PropTypes.object,
  };

  static defaultProps = {
    className: '',
    disabled: false,
    icon: faPlus,
  };

  render() {
    const { className, disabled, icon, onClick, offset } = this.props;
    return (
      <button
        onClick={onClick}
        className={`round-button ${className}`}
        disabled={disabled}
        css={styles(offset)}
      >
        <FontAwesomeIcon icon={icon} />
      </button>
    );
  }
}

export default FloatingButton;
