import { generateApiSaga } from '../../../utils/sagas';
import { call, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import Newsworthy from '../../../connectors/Newsworthy';

const doCreate = generateApiSaga(Newsworthy, function* (connector, action) {
  const { newsworthyItem } = action.payload;
  return yield call(
    [connector, connector.createNewsworthyItem],
    newsworthyItem,
  );
});

export default function* createNewsworthy() {
  yield takeEvery(ActionTypes.API_NEWSWORTHY_ITEM_CREATE_INIT, doCreate);
}
