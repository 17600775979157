import { List, Map } from 'immutable';

export const groupingByKey = (key) => groupingBy((element) => element[key]);

export const groupingBy = (classifier) => [
  (accumulator, currentValue) => {
    (accumulator[classifier(currentValue)] =
      accumulator[classifier(currentValue)] || []).push(currentValue);
    return accumulator;
  },
  {},
];

export const groupingByImmutable = (classifier) => [
  (accumulator, currentValue) => {
    return accumulator.set(
      classifier(currentValue),
      accumulator.get(classifier(currentValue), List()).push(currentValue),
    );
  },
  Map(),
];

export const toMap = (keyMapper, valueMapper) => [
  (accumulator, currentValue) =>
    accumulator.set(keyMapper(currentValue), valueMapper(currentValue)),
  Map(),
];

export const not = (func) => (x) => !func(x);

export const identity = (node) => node;
