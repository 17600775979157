import { put, takeEvery, call } from 'redux-saga/effects';
import * as ActionTypes from '../../constants/ActionTypes';
import * as theoryItemApiAction from '../../actions/api/theoryitem';
import * as dataActions from '../../actions/data';
import { getAndAwaitAllPages } from '../../utils/sagas';

function* doGetAllPagesTheory(action) {
  const apiAction = theoryItemApiAction.getTopLevel(action.payload.theory);
  const completed = yield call(getAndAwaitAllPages, apiAction);
  yield put(dataActions.preloadTheoryCompleted(completed.isSuccess));
}

export default function* getAllTheoryItemPages() {
  yield takeEvery(ActionTypes.DATA_PRELOAD_THEORY_INIT, doGetAllPagesTheory);
}
