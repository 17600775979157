/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import PropTypes from 'prop-types';
import { fontColors, fontSize, uiColors } from '../styles/styles';
import { refBind } from '../../../utils/ref-bind';

const styles = {
  color: fontColors.MAIN,
  backgroundColor: uiColors.WHITE,
  border: '1px solid ' + uiColors.GREY,

  borderRadius: '4px',
  outline: 'none',
  boxShadow: 'inset 0 1px 2px 0 ' + uiColors.GREY,
  width: '100%',
  height: '38px',
  cursor: 'text',
  paddingLeft: '6px',
  fontSize: fontSize.FORM_INPUT,
  '&:hover': {
    border: '1px solid ' + uiColors.DARK,
    boxShadow: '1px 1px 1px 0 rgba(0 , 0, 0, 0.3)',
  },
  '&:focus': {
    border: '1px solid ' + uiColors.BRAND_LIGHT,
  },
  '&.placeholder': {
    color: fontColors.MAIN,
  },
  '&:disabled': {
    border: '1px solid ' + uiColors.GREY,
    boxShadow: 'inset 0 1px 2px 0 ' + uiColors.GREY,
  },
};

class FormFile extends React.Component {
  static propTypes = {
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    placeholder: '',
    onChange: () => {},
  };

  constructor(props) {
    super(props);
    this.state = { selectedFile: null };
    this.fileRef = React.createRef();
    refBind(this, 'onFileChange', 'handleFocus');
  }

  onFileChange(event) {
    this.setState({ selectedFile: event.target.files[0] });
    this.props.onChange(event.target.files[0]);
  }

  handleFocus() {
    this.fileRef && this.fileRef.current.click();
  }

  render() {
    return (
      <div className={this.props.className || ''}>
        <input
          type="file"
          onChange={this.onFileChange}
          className="d-none"
          ref={this.fileRef}
        />
        <input
          {...this.props}
          css={styles}
          value={this.state.selectedFile ? this.state.selectedFile.name : ''}
          placeholder={this.props.placeholder}
          readOnly
          onClick={this.handleFocus}
        />
      </div>
    );
  }
}

export default FormFile;
