import { fromJS } from 'immutable';
import * as ActionTypes from '../../constants/ActionTypes';
import * as ConsentType from '../../constants/ConsentTypes';

const initialState = fromJS({ unsubscribedNewsletterType: ConsentType.NONE });

export default function unsubscribePageReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.API_UNSUBSCRIBE_NOTIFICATION_SUCCESS:
      return state.merge(
        fromJS({
          unsubscribedNewsletterType: action.payload.responseData,
        }),
      );
    default:
      return state;
  }
}
