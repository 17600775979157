import { put, select } from 'redux-saga/effects';
import { callAndAwaitApi, takeLatestWhenAppReady } from '../../../utils/sagas';
import * as ActionTypes from '../../../constants/ActionTypes';
import * as EntityType from '../../../constants/EntityTypes';
import * as theoryPageActions from '../../../actions/page/theory';
import * as theoryItemApiActions from '../../../actions/api/theoryitem';
import * as routerActionSelector from '../../../selectors/actions/router';
import * as theoryPageSelector from '../../../selectors/page/theory';
import { replace } from 'connected-react-router/immutable';
import { getEntities } from '../../../reducers/api';
import { buildPageLink } from '../../../utils/link';
import { THEORY_ROUTE } from '../../../constants/Urls';

function* doLocationChange(action) {
  const path = routerActionSelector.getSplitPath(action);
  if (!path.length) return;
  const entity = path[0];
  const uri = path[1];
  if (entity === EntityType.THEORY) {
    if (!uri) {
      const result = yield callAndAwaitApi(
        theoryItemApiActions.getDefaultUri(),
      );
      const data = result.success
        ? getEntities(result.success, EntityType.THEORYITEM)
        : [];
      const url = data.length
        ? buildPageLink(THEORY_ROUTE, data[0].uri)
        : '404';
      yield put(replace(url));
      return;
    }
    // TODO: find better solution for duplicated route change event
    const lastUri = yield select(theoryPageSelector.getLastUri);

    if (lastUri === uri) return;
    yield put(theoryPageActions.pageLoad(uri));
    yield put(theoryPageActions.setLastUri(uri));
  }
}

export default function* locationChange() {
  yield takeLatestWhenAppReady(
    ActionTypes.APP_ROUTER_LOCATION_CHANGE,
    doLocationChange,
  );
}
