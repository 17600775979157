import { fromJS } from 'immutable';
import * as ActionTypes from '../../constants/ActionTypes';
import {
  buildStructuredTree,
  mergeEntityListToState,
  mergeResponseToState,
} from './index';
import * as EntityTypes from '../../constants/EntityTypes';
import {
  getAllPagesRequestType,
  getResponseData,
} from '../../selectors/actions';
import { createTagName } from '../../utils/filter';

const initialState = fromJS({
  data: {},
  tree: {}, // TODO - THIS SHOULD BE OBTAINED VIA A CACHED (PAGE) SELECTOR
  searchdata: {},
});

export default function processApiReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.AUTH_LOGIN_COMPLETE:
    case ActionTypes.AUTH_LOGOUT_SUCCESS:
      return initialState;
    case ActionTypes.API_PROCESSSTEP_GET_ALL_BY_PROCESS_SUCCESS:
    case ActionTypes.API_GET_PROCESS_STEP_BY_IDS_SUCCESS:
      return mergeResponseToState(state, action, EntityTypes.PROCESSSTEP);
    case ActionTypes.API_GET_RELATION_TREE_PROCESS_STEPS:
      return mergeEntityListToState(
        state,
        action.payload,
        EntityTypes.PROCESSSTEP,
      );
    case ActionTypes.TXN_ALL_PAGES_COMPLETE: // TODO - THIS SHOULD BE OBTAINED VIA A CACHED (PAGE) SELECTOR
      if (
        getAllPagesRequestType(action) ===
        ActionTypes.API_PROCESSSTEP_GET_ALL_BY_PROCESS_INIT
      ) {
        return buildStructuredTree(state);
      }
      return state;
    case ActionTypes.API_FIND_PROCESS_STEP_BY_NAME_SUCCESS:
      const responseData =
        getResponseData(action)['_embedded'][EntityTypes.PROCESSSTEP];
      const searchKey = createTagName(action.payload.initAction.payload.name);
      return state.setIn(['searchdata', searchKey], fromJS(responseData));
    default:
      return state;
  }
}
