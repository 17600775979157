import { call, takeLatest } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import { generateApiSaga } from '../../../utils/sagas';
import Taxonomy from '../../../connectors/Taxonomy';

const doCreate = generateApiSaga(Taxonomy, function* (connector, action) {
  return yield call(
    [connector, connector.createTaxonomy],
    action.payload.taxonomy,
  );
});

export default function* createTaxonomy() {
  yield takeLatest(ActionTypes.API_TAXONOMY_CREATE_INIT, doCreate);
}
