import { call, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import lawItemConnector from '../../../connectors/LawItem';
import { generateApiSaga } from '../../../utils/sagas';

const getChildren = generateApiSaga(
  lawItemConnector,
  function* (connector, action) {
    const nodeId = action.payload.nodeId;
    return yield call([connector, connector.getLawItemChildren], nodeId);
  },
);

export default function* getAllChildren() {
  yield takeEvery(ActionTypes.API_LAWITEM_GET_ALL_CHILDREN_INIT, getChildren);
}
