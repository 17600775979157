import { List } from 'immutable';
import {
  getChildTypes,
  getEntityId,
  getEntityType,
} from '../../utils/entities';
import * as EntityTypes from '../../constants/EntityTypes';
import EntityFields from '../../constants/EntityFields';

export const getAll = (state, type) => state.getIn(['api', type, 'data']);
export const getTree = (state, type) => state.getIn(['api', type, 'tree']);
export const getEntity = (state, type, id) =>
  state.getIn(['api', type, 'data', id]);
export const getParents = (state, node) => {
  let current = node;
  let result = new List();

  let type = getEntityType(node);

  if (state.has('api')) {
    state = state.getIn(['api', type]);
  }

  while (current) {
    let parentId = current.get(EntityFields[type].PARENT_ID);
    current = state.getIn(['data', parentId]);
    if (current) {
      result = result.push(current);
    }
  }
  return result.reverse();
};

export const getNodeLocationInTree = (state, node) => {
  let location = ['tree'];
  let parents = getParents(state, node);
  parents.forEach((item) => {
    location.push(getEntityId(item));
    location.push('children');
  });
  location.push(getEntityId(node));

  return location;
};

export const getChildNodes = (state, node, recursive) => {
  let items = state.getIn(['api', getChildTypes(node)]);

  let location = ['tree'];
  let type = getEntityType(node);
  if (type !== EntityTypes.THEORY && type !== EntityTypes.LAW) {
    location = getNodeLocationInTree(items, node);
    !recursive && location.push('children');
  }
  return items.getIn(location);
};

export const getByUri = (state, uri, entityType) =>
  state
    .getIn(['api', entityType, 'data'])
    .find((val) => val.getIn(['node', 'uri'], -1) === uri);
