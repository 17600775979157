import { call, select, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import TheoryItemConnector from '../../../connectors/TheoryItem';
import { generateApiSaga } from '../../../utils/sagas';
import * as appSelector from '../../../selectors/store/app';

const doFetch = generateApiSaga(TheoryItemConnector, function* (connector) {
  const jurisdictions = yield select(appSelector.getActiveJurisdictions);
  return yield call(
    [connector, connector.getTheoryItemWithParentAndJurisdiction],
    jurisdictions,
  );
});

export default function* findUriToSelect() {
  yield takeEvery(ActionTypes.API_THEORY_ITEM_GET_DEFAULT_URI_INIT, doFetch);
}
