/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import PropTypes from 'prop-types';
import { Form, Modal } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import Button from '../atoms/Button';
import { CREATED, UPDATED } from '../../../constants/UpdateType';
import Filter from './Filter';
import Datepicker from '../atoms/Datepicker';
import moment from 'moment/moment';
import { DateFormat } from '../../../constants/DateFormat';
import equal from 'fast-deep-equal';
import { refBind } from '../../../utils/ref-bind';

class LicenseModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      license: {
        role: undefined,
        expirationDate: undefined,
        ...this.props.license,
      },
      acceptIsDisabled: true,
    };
    refBind(
      this,
      'onRoleChange',
      'onExpirationDateChange',
      'getAcceptIsDisabled',
    );
  }

  static propTypes = {
    show: PropTypes.bool,
    license: PropTypes.shape({
      operation: PropTypes.oneOf([CREATED, UPDATED]),
    }),
    roles: PropTypes.arrayOf(PropTypes.object),
    onAccept: PropTypes.func,
    onDecline: PropTypes.func,
  };

  static defaultProps = {
    show: false,
    license: {},
    roles: [],
  };

  onRoleChange = (option) => {
    const updatedLicense = {
      ...this.state.license,
      role: { id: option.value, roleName: option.label },
    };
    this.setState({
      license: updatedLicense,
      acceptIsDisabled: this.getAcceptIsDisabled(updatedLicense),
    });
  };

  onExpirationDateChange = (date) => {
    const updatedLicense = {
      ...this.state.license,
      expirationDate: date
        ? moment(date).endOf('day').format(DateFormat.requestDateTime)
        : undefined,
    };
    this.setState({
      license: updatedLicense,
      acceptIsDisabled: this.getAcceptIsDisabled(updatedLicense),
    });
  };

  getAcceptIsDisabled = (updatedLicense) =>
    equal(this.props.license, updatedLicense) ||
    Object.values(updatedLicense).some((x) => !x);

  render() {
    const { show, roles, onAccept, onDecline } = this.props;
    const { license, acceptIsDisabled } = this.state;

    const expirationDate = license.expirationDate
      ? moment(license.expirationDate, DateFormat.requestDateTime)
      : undefined;
    const role = license.role?.id
      ? roles.find((r) => r.value === license.role.id)
      : undefined;

    return show ? (
      <Modal
        show={show}
        backdrop="static"
        keyboard={true}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>
            <Trans
              i18nKey={`license-modal.header.${license.operation?.toLowerCase()}`}
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Row>
            <Form.Group
              className="w-100"
              controlId="license-modal.role-id"
              key="license-modal.role-key"
            >
              <Form.Label>
                <span className="text-title-case">
                  <Trans i18nKey="license-form.license-type" />:
                </span>
              </Form.Label>
              <Filter
                options={roles}
                defaultValue={role}
                placeholder={''}
                onChange={this.onRoleChange}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group
              className="w-100"
              controlId="license-modal.expiration-date-id"
              key="license-modal.expiration-date-key"
            >
              <Form.Label>
                <span className="text-title-case">
                  <Trans i18nKey="license-form.expiration-date" />:
                </span>
              </Form.Label>
              <Datepicker
                defaultValue={{ startDate: expirationDate?.toDate() }}
                disableRange
                onDateSelected={this.onExpirationDateChange}
              />
            </Form.Group>
          </Form.Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type={Button.PRIMARY_SMALL}
            onClick={() => onAccept(license)}
            disabled={acceptIsDisabled}
          >
            <Trans i18nKey="license-modal.accept" />
          </Button>
          <Button type={Button.PRIMARY_SMALL} onClick={onDecline}>
            <Trans i18nKey="license-modal.decline" />
          </Button>
        </Modal.Footer>
      </Modal>
    ) : null;
  }
}

export default LicenseModal;
