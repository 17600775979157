/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import Text, { TextType } from '../atoms/Text';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans, withTranslation } from 'react-i18next';
import ControlledByPermission from '../../../containers/shared/ControlledByPermission';
import { PERMISSIONS } from '../../../constants/Permissions';
import Button from '../atoms/Button';
import FavoriteIcon from '../atoms/FavoriteIcon';
import HiddenText from '../atoms/HiddenText';
import { DateFormat } from '../../../constants/DateFormat';
import * as Urls from '../../../constants/Urls';
import { buildPageLink } from '../../../utils/link';
import { sort } from '../../../utils/sort';
import { DECISION_LIST } from '../../../constants/Sort';

const styles = {
  '.table-body': {
    'tr:nth-of-type(odd)': {
      backgroundColor: '#F4F6FB',
    },
    'tr td': {
      padding: '10px',
    },
  },
  '.decision-favorite': {
    marginLeft: '1em',
  },
};

const onlyAdminFields = [
  'decision.table.editor-name',
  'decision.table.short-name',
];

class DecisionTableEntry extends Component {
  onDecisionClick(uri, id) {
    const { push } = this.props;
    push(buildPageLink(Urls.DECISION_ROUTE, uri, id));
  }

  getText(node, key) {
    switch (key) {
      case 'decision.table.short-name':
        return node[key]?.replaceAll(';', ' ');
      case 'decision.table.reaction-type':
      case 'decision.table.formality-type':
      case 'decision.table.reaction-formality':
      case 'decision.table.suspensive-effect':
      case 'decision.table.procurement-form':
      case 'decision.table.procurement-form-procedure':
      case 'decision.table.procurement-form-addon':
      case 'decision.table.merit-type':
      case 'decision.table.case-type':
      case 'decision.table.decision-type':
        return node[key]
          ?.split(';')
          .filter(Boolean)
          .map((value, index) => (
            <Text key={`${key}-${index}`} type={TextType.TABLE_VALUE}>
              {' '}
              {this.props.t(`abbreviations.${key}.${value}`)}
            </Text>
          ));
      case 'decision.table.judge':
      case 'decision.table.complaint':
      case 'decision.table.complaint-representative':
      case 'decision.table.accused':
      case 'decision.table.accused-representative':
        return node[key]
          ?.split(';')
          .filter(Boolean)
          .map((value, index) => (
            <Text key={`${key}-${index}`} type={TextType.TABLE_VALUE}>
              {value}
            </Text>
          ));
      case 'decision.table.case-cost':
      case 'decision.table.reaction-formality-amount':
      case 'decision.table.pos-opf-int-claim':
      case 'decision.table.pos-opf-int-awarded':
      case 'decision.table.neg-kons-int-claim':
      case 'decision.table.neg-kons-int-awarded':
        return (
          (node[key] || node[key] === 0) &&
          `DKK ${new Intl.NumberFormat('da-DK', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(node[key])}`
        );
      default:
        let defaultText = node[key];
        if (typeof defaultText !== 'number') {
          defaultText = node[key]?.replaceAll(';', ' ');
        }
        return defaultText;
    }
  }

  getHiddenText(data) {
    let blur = (
      <HiddenText
        text={data.node[data.key]}
        onHiddenClicked={data.onHiddenClicked}
      />
    );
    switch (data.key) {
      case 'decision.table.document-date':
      case 'decision.table.case-opening':
        blur = (
          <HiddenText
            text={data.node[data.key]}
            dateFormat={DateFormat.requestDate}
            type={HiddenText.DATE}
            onHiddenClicked={data.onHiddenClicked}
          />
        );
        break;
      case 'decision.table.court':
        blur = (
          <Text type={TextType.TABLE_VALUE}>
            <Trans
              i18nKey={`abbreviations.${data.key}.${data.node[data.key]}`}
            />
          </Text>
        );
    }
    return (
      <tr key={data.key}>
        <td>
          <Text type={TextType.TABLE_KEY}>
            <Trans i18nKey={data.key} />
          </Text>
        </td>
        <td>{blur}</td>
      </tr>
    );
  }

  render() {
    const {
      decisionData,
      favorite,
      onFavoriteClick,
      onDownloadDecisionFile,
      onHiddenClicked,
    } = this.props;
    const node = decisionData.node;
    const decision = decisionData.decision;
    const sortedKeys = sort(Object.keys(node), DECISION_LIST);
    return (
      <div css={styles}>
        <div className="my-4 show-on-hover-container d-flex">
          <Text type={TextType.HEADER_1_SMALL}>
            <ControlledByPermission
              permission={PERMISSIONS.DECISION_VIEW_CONTENT}
              fallback={<HiddenText text={node['decision.table.short-name']} />}
              render={
                node['decision.table.short-name'] ?? (
                  <Trans i18nKey="decision.table.no-text" />
                )
              }
            />
          </Text>
          <ControlledByPermission
            permission={PERMISSIONS.FAVORITE}
            render={
              <FavoriteIcon
                className="decision-favorite"
                favorite={favorite}
                onFavoriteClick={onFavoriteClick}
              />
            }
          />
        </div>
        <Table borderless>
          <tbody className="table-body">
            {sortedKeys.map((key) => {
              const permission = onlyAdminFields.includes(key)
                ? PERMISSIONS.DECISION_UPDATE_CONTENT
                : PERMISSIONS.DECISION_VIEW_CONTENT;
              const fallbackFn =
                permission === PERMISSIONS.DECISION_VIEW_CONTENT
                  ? this.getHiddenText
                  : null;
              return (
                <ControlledByPermission
                  key={key}
                  permission={permission}
                  fallbackFn={fallbackFn}
                  fallbackArgs={{ node, key, onHiddenClicked }}
                  render={
                    <tr>
                      <td>
                        <Text type={TextType.TABLE_KEY}>
                          <Trans i18nKey={key} />
                        </Text>
                      </td>
                      <td>
                        <Text type={TextType.TABLE_VALUE}>
                          {this.getText(node, key)}
                        </Text>
                      </td>
                    </tr>
                  }
                />
              );
            })}
            <tr>
              <td>
                <Text type={TextType.TABLE_KEY}>
                  <Trans i18nKey="decision.table.preceding-decisions" />
                </Text>
              </td>
              <td style={{ display: 'grid' }}>
                {decision.precedingDecisions?.map((decision) => (
                  <Link
                    onClick={() =>
                      this.onDecisionClick(decision.courtUri, decision.id)
                    }
                    key={'preceding-decision-id' + decision.id}
                  >
                    <Text type={TextType.TABLE_VALUE}>{decision.caseName}</Text>
                  </Link>
                ))}
              </td>
            </tr>
            <tr>
              <td>
                <Text type={TextType.TABLE_KEY}>
                  <Trans i18nKey="decision.table.ensuing-decisions" />
                </Text>
              </td>
              <td style={{ display: 'grid' }}>
                {decision.ensuingDecisions?.map((decision) => (
                  <Link
                    onClick={() =>
                      this.onDecisionClick(decision.courtUri, decision.id)
                    }
                    key={'ensuing-decision-id' + decision.id}
                  >
                    <Text type={TextType.TABLE_VALUE}>{decision.caseName}</Text>
                  </Link>
                ))}
              </td>
            </tr>
          </tbody>
        </Table>
        <ControlledByPermission
          permission={PERMISSIONS.DECISION_VIEW_CONTENT}
          render={
            <Button
              onClick={onDownloadDecisionFile}
              type={Button.PRIMARY_LARGE}
            >
              <FontAwesomeIcon icon={faDownload} />
              &nbsp;
              <Trans i18nKey="decision.download" />
            </Button>
          }
        />
      </div>
    );
  }
}

export default withTranslation()(DecisionTableEntry);
