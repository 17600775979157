import { combineReducers } from 'redux-immutable';
import theoryPageReducer from './theory';
import lawPageReducer from './law';
import decisionPageReducer from './decision';
import adminPageReducer from './admin';
import adminHomePageReducer from './adminhome';
import searchPageReducer from './search';
import processPageReducer from './process';
import profilePageReducer from './profile';
import signupPageReducer from './signup';
import articleFooter from './articlefooter';
import taxonomy from './taxonomy';
import newsletterPageReducer from './writeNewsletter';
import unsubscribePageReducer from './unsubscribe';

export default combineReducers({
  theory: theoryPageReducer,
  law: lawPageReducer,
  decision: decisionPageReducer,
  admin: adminPageReducer,
  adminhome: adminHomePageReducer,
  search: searchPageReducer,
  process: processPageReducer,
  profile: profilePageReducer,
  signup: signupPageReducer,
  articlefooter: articleFooter,
  taxonomy: taxonomy,
  newsletter: newsletterPageReducer,
  unsubscribe: unsubscribePageReducer,
});
