/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment } from 'react';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Dropdown,
  Form,
} from 'react-bootstrap';
import ContentOuterBound from '../../components/common/atoms/ContentOuterBound';
import wavesImagePath from '../../images/wave.svg';
import { FaLock } from 'react-icons/fa';
import * as appSelectors from '../../selectors/store/app';
import * as authSelectors from '../../selectors/store/auth';
import * as authActions from '../../actions/auth';
import { bindActionCreators } from 'redux';
import Text, { TextType } from '../../components/common/atoms/Text';
import {
  fontColors,
  gradientColors,
  uiColors,
} from '../../components/common/styles/styles';
import { Trans, withTranslation } from 'react-i18next';
import { push } from 'connected-react-router/immutable';
import * as URLs from '../../constants/Urls';

const styles = {
  '& .page-main': {
    background: 'url(' + wavesImagePath + ') ' + uiColors.BLUE_MAGENTA,
    backgroundSize: 'cover',
    height: 'fit-content',
    minHeight: '100vh',
    color: fontColors.WHITE,
    paddingTop: '200px',
    '@media (max-width: 991px)': {
      paddingTop: '70px',
    },
    '& .login-info': {
      '& button+button': {
        marginLeft: '1em',
      },
      '& button': {
        background: gradientColors.PURPLE,
        borderColor: uiColors.DARK,
        '&.accent': {
          background: gradientColors.ORANGE,
          borderColor: uiColors.DARK,
        },
      },
    },
    '& .login-component .loginBox': {
      padding: '3em',
      paddingTop: '2em',
      paddingBottom: '1em',
      marginBottom: '20px',
      backgroundColor: fontColors.WHITE,
      color: fontColors.BLACK,
      '& form': {
        '& label': {
          textTransform: 'uppercase',
          color: fontColors.GREY,
        },
        '& Button': {
          borderColor: uiColors.DARK,
          background: gradientColors.PURPLE,
          '& svg': {
            verticalAlign: 'baseline',
          },
        },
        '& .dropdown-button': {
          width: '100%',
        },
      },
      '& hr': {
        marginLeft: '-3em',
        marginRight: '-3em',
      },
      '& a': {
        color: fontColors.GREY,
      },
    },
  },
  '& .flex-row': {
    alignItems: 'center',
    justifyContent: 'center',
  },
  '& .error-text': {
    color: fontColors.RED,
  },
  '& .small-view': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
};

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      errorSection: null,
    };

    this._onEmailChange = this._onEmailChange.bind(this);
    this._onPasswordChange = this._onPasswordChange.bind(this);
    this._onDevelopmentLogin = this._onDevelopmentLogin.bind(this);
    this._onJwtLogin = this._onJwtLogin.bind(this);
    this._onTryForFreeClick = this._onTryForFreeClick.bind(this);
    this._onGotoForgotPassword = this._onGotoForgotPassword.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { loginError } = this.props;
    if (prevProps.loginError !== loginError) {
      const errorSection = this.createErrorSection();
      this.setState({ errorSection });
    }
  }

  createErrorSection() {
    const { loginError } = this.props;
    let errorSection =
      loginError &&
      (loginError.indexOf('[') > -1
        ? loginError.replace(/[[\]']/g, '').split(', ')
        : [loginError]);
    return errorSection?.map((error) => (
      <p className="error-text" key={error}>
        <Trans i18nKey={`login.${error}`} />
      </p>
    ));
  }

  _onGotoForgotPassword() {
    const { actions } = this.props;
    actions.clearForm();
    actions.push(URLs.FORGOT_PASSWORD_ROUTE);
  }

  _onEmailChange(event) {
    this.setState({ email: event.target.value });
  }

  _onPasswordChange(event) {
    this.setState({ password: event.target.value });
  }

  _onDevelopmentLogin() {
    const { actions } = this.props;
    actions.devLogin(this.state.email);
  }

  _onTryForFreeClick() {
    const { actions } = this.props;
    actions.push('/signup');
  }

  _onJwtLogin(event) {
    event.preventDefault();
    const { actions } = this.props;
    actions.jwtLogin(this.state.email, this.state.password);
  }

  render() {
    return (
      <div css={styles}>
        <div className="page-main">
          <ContentOuterBound>
            <Container className="flex-row">
              <Col className="login-component" xs={12} lg={6}>
                {this.renderLoginBox()}
              </Col>
              <Col className="login-info" xs={{ span: 12 }} lg={6}>
                {this.renderLoginInfo()}
              </Col>
            </Container>
          </ContentOuterBound>
        </div>
      </div>
    );
  }

  renderLoginInfo() {
    return (
      <div>
        <Text as="h3" type={TextType.HEADER_2_SMALL}>
          <Trans i18nKey="login.description-title" />
        </Text>
        <p className="mb-5">
          <Trans i18nKey="login.description" />
        </p>
        <Button onClick={this._onTryForFreeClick} className="accent" size="lg">
          <Trans i18nKey="login.buy-licence" />
        </Button>
        <Button onClick={this._onTryForFreeClick} size="lg">
          <Trans i18nKey="login.try-free" />
        </Button>
      </div>
    );
  }

  renderLoginBox() {
    const { isDevelopment, isLoading } = this.props;
    const { errorSection } = this.state;
    return (
      <div className="loginBox border rounded">
        <Text as="p" type={TextType.HEADER_2_SMALL}>
          <Trans i18nKey="login.hello" />
        </Text>
        <Text as="p" type={TextType.HEADER_4}>
          <Trans i18nKey="login.greeting" />
        </Text>
        <Form onSubmit={this._onJwtLogin}>
          <Fragment>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>
                <Trans i18nKey="login.user-name-label" />
              </Form.Label>
              <Form.Control
                autoComplete="username"
                onChange={this._onEmailChange}
                type="email"
                required
                placeholder={this.props.t('login.user-name-placeholder')}
              />
            </Form.Group>
            <Form.Group controlId="formBasicPassword">
              <Form.Label>
                <Trans i18nKey="login.password-label" />
              </Form.Label>
              <Form.Control
                autoComplete="current-password"
                onChange={this._onPasswordChange}
                type="password"
                required
                placeholder={this.props.t('login.password-placeholder')}
              />
            </Form.Group>
          </Fragment>
          <Form.Group>
            {errorSection}
            <Dropdown as={ButtonGroup} className="mt-4 dropdown-button">
              <Button
                disabled={isLoading}
                size="lg"
                variant="primary"
                type="submit"
                block
              >
                <Fragment>
                  <FaLock /> <Trans i18nKey="login.log-in" />
                </Fragment>
              </Button>
              {isDevelopment && (
                <Fragment>
                  <Dropdown.Toggle split variant="success" />
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={this._onDevelopmentLogin}>
                      Development login
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Fragment>
              )}
            </Dropdown>
          </Form.Group>
        </Form>
        <hr className="mt-4 mb-4" />
        <p className="text-center">
          <Trans i18nKey="login.forgot-password" />{' '}
          <Link to="#" onClick={this._onGotoForgotPassword}>
            <Trans i18nKey="login.get-new" />
          </Link>
        </p>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  isDevelopment: appSelectors.appModeIsDevelopment,
  isLoading: authSelectors.isLoading,
  loginError: authSelectors.loginError,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      devLogin: authActions.devLogin,
      jwtLogin: authActions.jwtLogin,
      clearForm: authActions.clearLogin,
      push: push,
    },
    dispatch,
  ),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  withTranslation()(LoginPage),
);
