import { combineReducers } from 'redux-immutable';
import apiReducers from './api';
import pageReducers from './page';
import appReducers from './app';
import { connectRouter } from 'connected-react-router/immutable';
import * as ActionTypes from '../constants/ActionTypes';

const rootReducer = (history) =>
  combineReducers({
    api: clearOnLogout(apiReducers),
    page: clearOnLogout(pageReducers),
    app: appReducers,
    router: connectRouter(history),
  });

const clearOnLogout = (reducer) => (state, action) => {
  switch (action.type) {
    case ActionTypes.AUTH_LOGOUT_SUCCESS:
      return reducer(undefined, action);
    default:
      return reducer(state, action);
  }
};

export default rootReducer;
