import { generateApiSaga } from '../../../utils/sagas';
import RoleConnector from '../../../connectors/Role';
import { call, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';

const doUpdateRole = generateApiSaga(
  RoleConnector,
  function* (connector, action) {
    const role = action.payload.role;
    const patch = {
      permissions: role.permissions
        .filter((p) => p.active)
        .map((p) => `/${p.id}`),
    };
    if (patch.permissions.length) {
      return yield call([connector, connector.updateRole], role.roleId, patch);
    }
  },
);

export default function* updateRole() {
  yield takeEvery(ActionTypes.API_UPDATE_ROLE_INIT, doUpdateRole);
}
