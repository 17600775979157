import { generateApiSaga } from '../../../utils/sagas';
import * as ActionTypes from '../../../constants/ActionTypes';
import { call, takeEvery } from 'redux-saga/effects';
import NewsletterConnector from '../../../connectors/Newsletter';

const doUnsubscribeSingleNotification = generateApiSaga(
  NewsletterConnector,
  function* (connector, action) {
    return yield call(
      [connector, connector.unsubscribeSingleNotification],
      action.payload,
    );
  },
);

export default function* unsubscribeSingleNotification() {
  yield takeEvery(
    ActionTypes.API_UNSUBSCRIBE_NOTIFICATION_INIT,
    doUnsubscribeSingleNotification,
  );
}
