/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component } from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import { refBind } from '../../utils/ref-bind';
import { Form } from 'react-bootstrap';
import { copyObject } from '../../utils/objectHelper';
import * as adminPageSelector from '../../selectors/page/admin';
import { FaTrash } from 'react-icons/all';
import { uiColors } from '../../components/common/styles/styles';
import ItemSearch from './ItemSearch';
import * as EntityTypes from '../../constants/EntityTypes';

const style = {
  '.item-title': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '.delete-icon': {
    color: uiColors.BRAND,
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.2)',
    },
  },
  '.disabled': {
    color: uiColors.GREY_DISABLED,
  },
};

class SearchableMultiSelectDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      deletedItems: [],
    };
    refBind(this, 'notifyParent', 'handleItemsChange', 'renderListElement');
  }

  /**
   * Updates the list of deleted Items with item id. If the item id is already exist in the list it will remove it from deleted list otherwise it will add it to the deleted items list.
   * @param deleted that is list of deleted items.
   * @param itemId that is the item id that needs to be added or removed from the deleted item list.
   * @return an updated list of deleted items.
   */
  updateDeletedItemList(deleted, itemId) {
    if (deleted[itemId]) {
      delete deleted[itemId];
    }
    return deleted;
  }

  handleItemsChange(item) {
    const { items, deletedItems } = this.state;
    const updatedDeletedItems = this.updateDeletedItemList(deletedItems, item);
    this.setState({ deletedItems: updatedDeletedItems });
    this.notifyParent({ items: [...items, item] });
  }

  notifyParent(update) {
    if (update) {
      this.setState({ ...update });
    }
    const { mapFn, onChange, fieldName, currentTargetItems } = this.props;
    if (onChange && mapFn) {
      const { deletedItems } = this.state;
      const copyState = update
        ? { ...this.state, ...update }
        : copyObject(this.state, true);
      let { items } = copyState;
      const links = {
        [fieldName]: adminPageSelector
          .getUniqueItems(currentTargetItems, deletedItems, items, mapFn)
          .map((item) => `/${item.value}`),
      };
      onChange(links);
    }
  }

  renderFormLabel(key) {
    return (
      <Form.Label>
        {' '}
        <span className="text-title-case">
          <Trans i18nKey={key} />:
        </span>
      </Form.Label>
    );
  }

  renderListElement(list, item) {
    return (
      <div key={item.value} className="d-flex justify-content-between">
        <div className="item-title" title={item.label}>
          {item.label}
        </div>
        <div className="d-flex align-items-center delete-icon">
          <FaTrash onClick={() => this.removeItem(list, item)} />
        </div>
      </div>
    );
  }

  /**
   * Removes a passed Item from existing items in the list and if the item does not exist, it removes it from newly added items state and calls notify parent function.
   * @param list that has the existing items.
   * @param item that needs to be removed.
   */
  removeItem(list, item) {
    const { deletedItems, items } = this.state;
    if (list.find((listItem) => listItem.id === item.value)) {
      deletedItems[item.value] = item;
      this.setState({ deletedItems }, this.notifyParent);
    } else {
      this.setState(
        { items: items.filter((i) => i.value !== item.value) },
        this.notifyParent,
      );
    }
  }

  render() {
    const { currentTargetItems, mapFn, itemType, title } = this.props;
    let { items, deletedItems } = this.state;
    const isNotDisabled = currentTargetItems !== 'disabled';
    items =
      isNotDisabled &&
      adminPageSelector.getUniqueItems(
        currentTargetItems,
        deletedItems,
        items,
        mapFn,
      );
    return (
      <div css={style} className={itemType}>
        <div
          className={`${itemType}-drop-down${isNotDisabled ? '' : ' disabled'}`}
        >
          {this.renderFormLabel(title || `article-editor.${itemType}`)}
          <ItemSearch
            itemSearchType={itemType}
            ignoreOptions={items}
            onChange={this.handleItemsChange}
            isDisabled={!isNotDisabled}
          />
          <div className={`${itemType}-list`}>
            {isNotDisabled &&
              items.map((item) =>
                this.renderListElement(currentTargetItems, item),
              )}
          </div>
        </div>
      </div>
    );
  }
}

SearchableMultiSelectDropdown.propTypes = {
  fieldName: PropTypes.string.isRequired,
  itemType: PropTypes.oneOf([
    EntityTypes.DECISION,
    EntityTypes.LAWITEM,
    EntityTypes.TAXONOMY,
    EntityTypes.THEORYITEM,
    EntityTypes.PROCESSSTEP,
  ]).isRequired,
  mapFn: PropTypes.func.isRequired,
  currentTargetItems: PropTypes.object,
  title: PropTypes.string,
};

SearchableMultiSelectDropdown.defaultProps = {
  currentTargetItems: undefined,
  children: '',
};

export default SearchableMultiSelectDropdown;
