/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component } from 'react';
import ArticleFooterLayout from '../molecules/ArticleFooterLayout';
import { uiColors } from '../styles/styles';
import {
  mapDecisions,
  mapKeywords,
  mapLawItems,
  mapProcessSteps,
  mapTheoryItems,
} from '../../../utils/AutocompleteMapper';
import SearchableMultiSelectDropdown from '../../../containers/shared/SearchableMultiSelectDropdown';
import * as EntityTypes from '../../../constants/EntityTypes';

const style = {
  minHeight: '20rem',
  '.delete-icon': {
    color: uiColors.BRAND,
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.2)',
    },
  },
  '.disabled': {
    color: uiColors.GREY_DISABLED,
  },
};

class RelationEditor extends Component {
  render() {
    const {
      theoryItems,
      lawItems,
      decisions,
      processSteps,
      taxonomy,
      className,
      onChange,
      entityType,
    } = this.props;
    return (
      <section css={style} className={`relation-editor ${className}`}>
        <ArticleFooterLayout entityType={entityType}>
          <SearchableMultiSelectDropdown
            itemType={EntityTypes.LAWITEM}
            currentTargetItems={lawItems}
            onChange={onChange}
            fieldName="relatedLawItems"
            mapFn={mapLawItems}
          />
          <SearchableMultiSelectDropdown
            itemType={EntityTypes.THEORYITEM}
            currentTargetItems={theoryItems}
            onChange={onChange}
            fieldName="relatedTheoryItems"
            mapFn={mapTheoryItems}
          />
          <SearchableMultiSelectDropdown
            itemType={EntityTypes.DECISION}
            currentTargetItems={decisions}
            onChange={onChange}
            fieldName="relatedDecisions"
            mapFn={mapDecisions}
          />
          <SearchableMultiSelectDropdown
            itemType={EntityTypes.PROCESSSTEP}
            currentTargetItems={processSteps}
            onChange={onChange}
            fieldName="relatedProcessSteps"
            mapFn={mapProcessSteps}
          />
          <SearchableMultiSelectDropdown
            itemType={EntityTypes.TAXONOMY}
            currentTargetItems={taxonomy}
            onChange={onChange}
            fieldName="keywords"
            mapFn={mapKeywords}
          />
        </ArticleFooterLayout>
      </section>
    );
  }
}

export default RelationEditor;
