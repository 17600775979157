/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

let noop = () => null;

const styles = {
  '.col-body': {
    position: 'sticky',
    top: '57px',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  '.left': {
    height: 'calc(100vh - 185px)',
  },
  '.right': {
    marginBottom: '5rem',
  },
};

class LayoutGrid1To2 extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    onRenderColumn1: PropTypes.func,
    onRenderColumn2: PropTypes.func,
    base12LeftPanelSmSize: PropTypes.number,
  };

  static defaultProps = {
    className: '',
    onRenderColumn1: noop,
    onRenderColumn2: noop,
    base12LeftPanelSmSize: undefined,
  };

  render() {
    const {
      className,
      onRenderColumn1,
      onRenderColumn2,
      base12LeftPanelSmSize,
    } = this.props;
    return (
      <Container className={className} fluid>
        <Row css={styles}>
          <Col xs={12} sm={base12LeftPanelSmSize ?? 5}>
            <div className="col-body left scroll-bar overflow-holder">
              {onRenderColumn1()}
            </div>
          </Col>
          <Col
            xs={12}
            sm={base12LeftPanelSmSize ? 12 - base12LeftPanelSmSize : 7}
          >
            <div className="col-body right">{onRenderColumn2()}</div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default LayoutGrid1To2;
