import { fromJS } from 'immutable';
import * as ActionTypes from '../../constants/ActionTypes';
import { mergeFavoriteToState } from './index';

const initialState = fromJS({ data: {}, filter: {} });

export default function favoriteApiReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.AUTH_LOGIN_COMPLETE:
    case ActionTypes.AUTH_LOGOUT_SUCCESS:
      return initialState;
    case ActionTypes.API_SET_FAVORITE_SUCCESS:
    case ActionTypes.API_GET_FAVORITES_SUCCESS:
      return mergeFavoriteToState(state, action);
    case ActionTypes.API_GET_ALL_FAVORITES_SUCCESS:
      state = mergeFavoriteToState(state, action);
      return state.setIn(['filter', 'all'], true);
    default:
      return state;
  }
}
