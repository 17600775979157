import * as ActionTypes from '../../../constants/ActionTypes';
import { takeLatestWhenAppReady } from '../../../utils/sagas';

function* doPageTheorySelectNode(action) {
  // let node = action.payload.node;
  // if(!node) {return;}
  //
  //
  // let type = getEntityType(node);
  // if(type == EntityTypes.THEORYITEM)
  // {
  //     node = yield select(TheoryItemSelectors.getTheory, node);
  // }
}

export default function* getAll() {
  yield takeLatestWhenAppReady(
    ActionTypes.APP_PAGE_THEORY_SELECT_NODE,
    doPageTheorySelectNode,
  );
}
