import { getByFn } from './index';
import * as EntityTypes from '../../constants/EntityTypes';
import EntityFields from '../../constants/EntityFields';
import * as CommonSelectors from '../entity/common';
import * as appSelector from '../store/app';

export const getAll = (state) => CommonSelectors.getAll(state, EntityTypes.LAW);
export const getLawNameById = (state, id) =>
  getById(state, id)?.getIn(['node', 'name'], null);
export const getById = (state, id) =>
  CommonSelectors.getEntity(state, EntityTypes.LAW, id);
export const getByUri = (state, uri) =>
  getByFn(
    state,
    EntityTypes.LAW,
    (x) => x.get(EntityFields[EntityTypes.LAW].TITLE) === uri,
  ) ||
  getByFn(
    state,
    EntityTypes.LAWITEM,
    (x) => x.get(EntityFields[EntityTypes.LAWITEM].URI) === uri,
  );

export const getJurisdictionFilteredLaws = (state) => {
  const filter = appSelector.jurisdictionFilter(state);
  return CommonSelectors.getAll(state, EntityTypes.LAW).filter((law) =>
    filter.get(law.getIn(['node', 'jurisdictionCode'], '').toLowerCase()),
  );
};
