/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import PropTypes from 'prop-types';
import { fontFamily, fontSize } from '../styles/styles';

export const TextType = {
  HEADER_1_LARGE: 'header-1-large',
  HEADER_1_SMALL: 'header-1-small',
  HEADER_2_LARGE: 'header-2-large',
  HEADER_2_SMALL: 'header-2-small',
  HEADER_3: 'header-3',
  HEADER_4: 'header-4',
  HEADER_5: 'header-5',
  PARAGRAPH_DEFAULT: 'paragraph-default',
  PARAGRAPH_STRONG: 'paragraph-strong',
  PARAGRAPH_SMALL: 'paragraph-small',
  FORM_INPUT: 'form-input',
  MAIN_SEARCH_INPUT: 'main-search-input',
  TOP_SEARCH_INPUT: 'top-search-input',
  SIGNUP_INPUT: 'signup-input',
  FORM_LINK: 'form-link',
  HEADER_1_LARGE_WHITE: 'header-1-large-white',
  PARAGRAPH_DEFAULT_WHITE: 'paragraph-default-white',
  PARAGRAPH_FOOTER: 'paragraph-footer',
  NAVBAR_LINK: 'navbar-link',
  SUBMIT_BUTTON: 'submit-button',
  HEADER_EXTRA_LARGE: 'header-extra-large',
  PROFILE_LINK: 'profile-link',
  FOOTER_LINK: 'footer-link',
  LIST_LINK_BRAND: 'list-link-brand',
  PROFILE_LIST: 'profile-list',
  PROFILE_LIST_ACTIVE: 'profile-list-active',
  TABLE_HEADER: 'table-header',
  TABLE_ROW: 'table-row',
  READ_MORE: 'read-more',
  TABLE_KEY: 'table-key',
  TABLE_VALUE: 'table-value',
  NAVIGATION_TREE_PARENT: 'navigation-tree-parent',
  NAVIGATION_TREE_CHILD: 'navigation-tree-child',
  CHOSEN_ITEM_IN_NAVIGATION_TREE: 'navigation-tree-child',
};

const styles = {
  '&.header-1-large': {
    fontFamily: fontFamily.HEADER_1_LARGE,
    fontSize: fontSize.HEADER_1_LARGE,
  },
  '&.header-1-small': {
    fontFamily: fontFamily.HEADER_1_SMALL,
    fontSize: fontSize.HEADER_1_SMALL,
  },
  '&.header-2-large': {
    fontFamily: fontFamily.HEADER_2_LARGE,
    fontSize: fontSize.HEADER_2_LARGE,
  },
  '&.header-2-small': {
    fontFamily: fontFamily.HEADER_2_SMALL,
    fontSize: fontSize.HEADER_2_SMALL,
  },
  '&.header-3': {
    fontFamily: fontFamily.HEADER_3,
    fontSize: fontSize.HEADER_3,
  },
  '&.header-4': {
    fontFamily: fontFamily.HEADER_4,
    fontSize: fontSize.HEADER_4,
  },
  '&.header-5': {
    fontFamily: fontFamily.HEADER_5,
    fontSize: fontSize.HEADER_5,
  },
  '&.paragraph-default': {
    fontFamily: fontFamily.PARAGRAPH_DEFAULT,
    fontSize: fontSize.PARAGRAPH_DEFAULT,
  },
  '&.paragraph-strong': {
    fontFamily: fontFamily.PARAGRAPH_STRONG,
    fontSize: fontSize.PARAGRAPH_STRONG,
  },
  '&.paragraph-small': {
    fontFamily: fontFamily.PARAGRAPH_SMALL,
    fontSize: fontSize.PARAGRAPH_SMALL,
  },
  '&.form-input': {
    fontFamily: fontFamily.FORM_INPUT,
    fontSize: fontSize.FORM_INPUT,
  },
  '&.main-search-input': {
    fontFamily: fontFamily.MAIN_SEARCH_INPUT,
    fontSize: fontSize.MAIN_SEARCH_INPUT,
  },
  '&.top-search-input': {
    fontFamily: fontFamily.TOP_SEARCH_INPUT,
    fontSize: fontSize.TOP_SEARCH_INPUT,
  },
  '&.signup-input': {
    fontFamily: fontFamily.SIGNUP_INPUT,
    fontSize: fontSize.SIGNUP_INPUT,
  },
  '&.form-link': {
    fontFamily: fontFamily.FORM_LINK,
    fontSize: fontSize.FORM_LINK,
  },
  '&.header-1-large-white': {
    fontFamily: fontFamily.HEADER_1_LARGE,
    fontSize: fontSize.HEADER_1_LARGE,
  },
  '&.paragraph-default-white': {
    fontFamily: fontFamily.PARAGRAPH_DEFAULT,
    fontSize: fontSize.PARAGRAPH_DEFAULT,
  },
  '&.paragraph-footer': {
    fontFamily: fontFamily.PARAGRAPH_FOOTER,
    fontSize: fontSize.PARAGRAPH_FOOTER,
  },
  '&.navbar-link': {
    fontFamily: fontFamily.NAVBAR_LINK,
    fontSize: fontSize.NAVBAR_LINK,
  },
  '&.submit-button': {
    fontFamily: fontFamily.SUBMIT_BUTTON,
    fontSize: fontSize.SUBMIT_BUTTON,
  },
  '&.header-extra-large': {
    fontFamily: fontFamily.HEADER_EXTRA_LARGE,
    fontSize: fontSize.HEADER_EXTRA_LARGE,
  },
  '&.profile-link': {
    fontFamily: fontFamily.PARAGRAPH_SMALL,
    fontSize: fontSize.PARAGRAPH_SMALL,
  },
  '&.footer-link': {
    fontFamily: fontFamily.FOOTER_LINK,
    fontSize: fontSize.FOOTER_LINK,
  },
  '&.list-link-brand': {
    fontFamily: fontFamily.LIST_LINK,
    fontSize: fontSize.LIST_LINK,
  },
  '&.profile-list': {
    fontFamily: fontFamily.PROFILE_LIST,
    fontSize: fontSize.PROFILE_LIST,
  },
  '&.profile-list-active': {
    fontFamily: fontFamily.PROFILE_LIST_ACTIVE,
    fontSize: fontSize.PROFILE_LIST,
  },
  '&.table-header': {
    fontFamily: fontFamily.TABLE_HEADER,
    fontSize: fontSize.TABLE_HEADER,
  },
  '&.table-row': {
    fontFamily: fontFamily.TABLE_ROW,
    fontSize: fontSize.TABLE_ROW,
  },
  '&.read-more': {
    fontFamily: fontFamily.TABLE_HEADER,
    fontSize: fontSize.READ_MORE,
  },
  '&.table-key': {
    fontFamily: fontFamily.TABLE_HEADER,
    fontSize: fontSize.DECISION,
  },
  '&.table-value': {
    fontFamily: fontFamily.TABLE_ROW,
    fontSize: fontSize.DECISION,
    display: 'block',
  },
  '&.navigation-tree-parent': {
    fontFamily: fontFamily.PROFILE_LIST_ACTIVE,
    fontSize: fontSize.PROFILE_LIST,
  },
  '&.navigation-tree-child': {
    fontFamily: fontFamily.PROFILE_LIST,
    fontSize: fontSize.PROFILE_LIST,
    wordBreak: 'break-word',
  },
  '&.chosen-item-article-footer': {
    fontFamily: fontFamily.LIST_CARD,
    fontSize: fontSize.LIST_CARD,
  },
};

const tags = ['span', 'p'].concat([1, 2, 3, 4, 5, 6].map((i) => 'h' + i));

class Text extends React.Component {
  static propTypes = {
    type: PropTypes.oneOf(Object.values(TextType)),
    as: PropTypes.oneOf(tags),
    className: PropTypes.string,
  };

  static defaultProps = {
    type: TextType.PARAGRAPH_DEFAULT,
    as: 'span',
    className: 'default-text-class',
  };

  render() {
    const { type, as, className } = this.props;
    let Tag = as;
    const classes = `${type} ${className}`;
    return (
      <Tag css={styles} className={classes}>
        {this.props.children}
      </Tag>
    );
  }
}

export default Text;
