import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';
import * as AppModes from '../../constants/AppModes';
import { Map } from 'immutable';
import * as jurisdictionApiSelector from '../api/jurisdiction';

export const appIsReady = (store) =>
  store.getIn(['app', 'runtime', 'appIsReady']);

export const preloadReady = (store) =>
  store.getIn(['app', 'runtime', 'preloadReady']);

export const appMode = (store) => store.getIn(['app', 'runtime', 'appMode']);

export const baseApiUrl = (store) =>
  store.getIn(['app', 'runtime', 'baseApiUrl']);

export const baseAuthUrl = (store) =>
  store.getIn(['app', 'runtime', 'baseAuthUrl']);

export const baseSignupUrl = (store) =>
  store.getIn(['app', 'runtime', 'baseSignupUrl']);

export const buildVersion = (store) =>
  store.getIn(['app', 'runtime', 'buildVersion']);

export const buildEnv = (store) => store.getIn(['app', 'runtime', 'buildEnv']);

export const appModeIsDevelopment = createSelector(
  appMode,
  (appMode) => appMode === AppModes.DEVELOPMENT,
);

export const appModeIsProduction = createSelector(
  appMode,
  (appMode) => appMode === AppModes.PRODUCTION,
);

export const liveApiRequests = (store) =>
  store.getIn(['app', 'runtime', 'liveApiRequests']);

export const hasLiveApiRequests = (store) => liveApiRequests(store).size > 0;

// While we are no longer able to toggle the jurisdiction filter on the frontend, the backend expects and requires a
// list of available jurisdictions to be given. To avoid any changes in the backend these functions have to return what
// we need to make the backend run
export const jurisdictionFilter = (store) =>
  store.getIn(
    ['app', 'global', 'jurisdictionFilter'],
    Map({
      eu: true,
      dk: true,
    }),
  );

export const getFilteredJurisdictions = createSelector(
  jurisdictionFilter,
  jurisdictionApiSelector.getAll,
  (jurisdictionFilter, allJurisdictions) => {
    return allJurisdictions.filter((jurisdiction) =>
      jurisdictionFilter.get(
        jurisdiction.getIn(['node', 'alpha2Code'], '').toLowerCase(),
      ),
    );
  },
);

export const getActiveJurisdictions = (store) =>
  Object.keys(
    jurisdictionFilter(store)
      .filter((f) => f)
      .toJS(),
  ).map((v) => v.toUpperCase());
export const notificationType = (store) =>
  store.getIn(['app', 'notification'], {}).type;
export const notificationMessage = (store) =>
  store.getIn(['app', 'notification'], {}).messages;
export const getCurrentLocation = (store) =>
  store.getIn(['app', 'runtime', 'currentLocation'], {});
export const getPreviousLocation = (store) =>
  store.getIn(['app', 'runtime', 'previousLocation'], {});
export const getPreviousPathName = (store) =>
  !isEmpty(getPreviousLocation(store))
    ? getPreviousLocation(store).pathname
    : undefined;
