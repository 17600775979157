import { call, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import LawItemConnector from '../../../connectors/LawItem';
import { generateApiSaga } from '../../../utils/sagas';

var doGetAllLawItems = generateApiSaga(
  LawItemConnector,
  function* (connector, action) {
    let { pagesize, page } = action.payload.paging;
    return yield call([connector, connector.getAll], pagesize, page);
  },
);

export default function* getAll() {
  yield takeEvery(ActionTypes.API_LAWITEM_GET_ALL_INIT, doGetAllLawItems);
}
