/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { Table } from 'react-bootstrap';
import Text, { TextType } from '../atoms/Text';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from 'react-i18next';
import * as TableStyle from '../styles/TableStyle';
import HiddenText from '../atoms/HiddenText';
import { refBind } from '../../../utils/ref-bind';
import { DateFormat } from '../../../constants/DateFormat';
import ControlledByPermission from '../../../containers/shared/ControlledByPermission';
import { PERMISSIONS } from '../../../constants/Permissions';
import { formatToRequestDate } from '../../../utils/hocs/dateutils';

class DecisionTable extends React.Component {
  constructor(props) {
    super(props);
    refBind(this, 'renderList', 'renderAnonymousList');
  }

  resolveCourtName(allCourts, courtId) {
    if (allCourts) {
      const entry = Object.values(allCourts).find((x) => x.node.id === courtId);
      return entry ? (
        <Trans
          i18nKey={`abbreviations.decision.table.court.${entry.node.shortName}`}
        />
      ) : (
        ''
      );
    }
    return '';
  }

  renderList() {
    const { items, allCourts, onDecisionClick } = this.props;
    return (
      items &&
      Object.values(items).map((decision) => (
        <tr className="table-row" key={decision.node.id}>
          <td>
            <Text type={TextType.TABLE_ROW}>{decision.node.shortName}</Text>
          </td>
          <td>
            <Text type={TextType.TABLE_ROW}>
              {this.resolveCourtName(allCourts, decision.node.court)}
            </Text>
          </td>
          <td>
            <Text type={TextType.TABLE_ROW}>
              {formatToRequestDate(
                decision.node.rulingDate,
                DateFormat.requestDateTime,
              )}
            </Text>
          </td>
          <td onClick={() => onDecisionClick && onDecisionClick(decision)}>
            <Text type={TextType.READ_MORE} className="font-weight-bold">
              <Trans i18nKey="decision-table.more-information" />{' '}
              <FontAwesomeIcon icon={faAngleRight} />
            </Text>
          </td>
        </tr>
      ))
    );
  }

  renderAnonymousList() {
    const { items, allCourts, onDecisionClick } = this.props;
    return (
      items &&
      Object.values(items).map((decision) => (
        <tr className="table-row" key={decision.node.id}>
          <td>
            <HiddenText
              text={decision.node.plaintiff}
              onHiddenClicked={this.props.onHiddenClicked}
            />
          </td>
          <td>
            <Text type={TextType.TABLE_ROW}>
              {this.resolveCourtName(allCourts, decision.node.court)}
            </Text>
          </td>
          <td>
            <HiddenText
              text={decision.node.rulingDate}
              type={HiddenText.DATE}
              onHiddenClicked={this.props.onHiddenClicked}
              dateFormat={DateFormat.requestDate}
            />
          </td>
          <td onClick={() => onDecisionClick && onDecisionClick(decision)}>
            <Text type={TextType.READ_MORE} className="font-weight-bold">
              <Trans i18nKey="decision-table.more-information" />{' '}
              <FontAwesomeIcon icon={faAngleRight} />
            </Text>
          </td>
        </tr>
      ))
    );
  }

  render() {
    return (
      <Table css={TableStyle.styles} borderless>
        <thead>
          <tr className="text-uppercase">
            <th>
              <Text type={TextType.TABLE_HEADER}>
                <Trans i18nKey="decision-table.decision" />
              </Text>
            </th>
            <th>
              <Text type={TextType.TABLE_HEADER}>
                <Trans i18nKey="decision-table.court" />
              </Text>
            </th>
            <th>
              <Text type={TextType.TABLE_HEADER}>
                <Trans i18nKey="decision-table.date" />
              </Text>
            </th>
            <th />
          </tr>
        </thead>
        <tbody className="table-body">
          <ControlledByPermission
            permission={PERMISSIONS.DECISION_VIEW_CONTENT}
            fallbackFn={this.renderAnonymousList}
            render={this.renderList()}
          />
        </tbody>
      </Table>
    );
  }
}

export default DecisionTable;
