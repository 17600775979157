import { fromJS, List, Map } from 'immutable';

export const treeNode = (entity, parent, children) =>
  fromJS({ parent: parent, node: entity, children: children });
export const createTreeNode = (node, children) =>
  Map().set('node', node).set('children', children);
export const getChildNodes = (parent, collection) =>
  collection.filter((child) => child.get('parent') === parent.get('id'));
export const buildTree = (parents, collection) =>
  parents.map((child) =>
    createTreeNode(
      child,
      buildTree(getChildNodes(child, collection), collection),
    ),
  );
export const findInTree = (tree, id) => {
  if (tree && tree.size) {
    let lookup = tree.get(id);
    if (lookup) {
      return lookup;
    }
    tree.forEach((branch) => {
      let lookup = findInTree(branch.get('children'), id);
      if (lookup) {
        return lookup;
      }
    });
    return lookup;
  }
  return undefined;
};
export const searchTree = (branch, id) => {
  if (branch.node.id === id) {
    return branch;
  } else if (branch.children != null) {
    // TODO: refactor to use immutable helper methods
    let result = null;
    for (let i = 0; result == null && i < branch.children.length; i++) {
      result = this.searchTree(branch.children[i], id);
    }
    return result;
  }
  return null;
};

export const findBranchInTree = (tree, predicate) => {
  if (!tree || !tree.size) {
    return undefined;
  }

  let lookup = tree.find(predicate);

  if (lookup) {
    return lookup;
  }

  let foundInChild;
  tree.forEach((branch) => {
    let childLookup = findBranchInTree(branch.get('children'), predicate);
    if (childLookup) {
      foundInChild = childLookup;
    }
  });
  return foundInChild;
};
export const findPathInTree = (tree, node) => {
  if (!tree || !tree.size) {
    return undefined;
  }

  let lookup = tree.find(
    (branch) => branch.get('node').get('uri') === node.get('node').get('uri'),
  );

  if (lookup) {
    return new List().push(lookup);
  }

  let foundInChild = new List();
  tree.forEach((branch) => {
    let childLookup = findPathInTree(branch.get('children'), node);
    if (childLookup && childLookup.size) {
      foundInChild = new List().push(branch).merge(childLookup);
    }
  });
  return foundInChild;
};
export const getParentPath = (state, tree, node) => {
  if (!node) {
    return List();
  }
  return findPathInTree(tree, node);
};

export const resolveTree = (node, resolveEntity) => {
  const baseMap = node?.get('children') || Map();
  return baseMap
    .sort((a, b) => a - b)
    .map((id) => resolveEntity(node.get('node').get('entityType'), id))
    .filter((child) => child)
    .map((child) => child.set('children', resolveTree(child, resolveEntity)))
    .toList();
};

export const resolveParentForList = (state, nodes, entityType) =>
  nodes.map((node) => getParentPathForNode(state, node, entityType));

export const getParentPathForNode = (state, node, entityType) => {
  if (!node) {
    return List();
  }
  const list = List().push(node);
  return updateParentIdPath(
    state.getIn(['api', entityType, 'data']),
    node.get('parent'),
    list,
  ).reverse();
};

export const updateParentIdPath = (lawItemList, targetId, list) => {
  const parent = lawItemList.find(
    (item) => item.getIn(['node', 'id']) === targetId,
  );
  if (parent) {
    list = list.push(parent);
    const parentId = parent.get('parent');
    if (parentId) {
      return updateParentIdPath(lawItemList, parentId, list);
    }
  }
  return list;
};
// region Helper methods
export const buildTreeExcerpt = (allItems, excerptItems) => {
  // build a map driven by the items to display and including all ancestors
  const idMapRoot = {};
  const excerptMap = {};
  excerptItems.forEach((leafItem) => {
    // create the list of ancestor ids from root to leaf
    const ancestorLineIds = [];
    let cur = leafItem;
    excerptMap[cur.getIn(['node', 'id'])] = cur;
    do {
      ancestorLineIds.unshift(cur.getIn(['node', 'id']));
      const parentId = cur.get('parent');
      cur = allItems.get(parentId);
    } while (cur);

    // merge the ancestor line into a common map of ids
    ancestorLineIds.reduce((idMapNode, id) => {
      idMapNode[id] = idMapNode[id] || {};
      return idMapNode[id];
    }, idMapRoot);
  });

  // flip the map into objects
  const excerptIds = new Set(Object.keys(excerptMap));
  const flip = (idToChildrenMap) =>
    Object.keys(idToChildrenMap)
      .map((id) => {
        const idValue = parseInt(id);
        const object = allItems.get(idValue);
        return object
          ?.set('children', flip(idToChildrenMap[id]))
          .set('highlight', excerptIds.has(id))
          .set(
            'node',
            excerptIds.has(id)
              ? excerptMap[id].get('node')
              : object.get('node'),
          );
      })
      .filter((node) => !!node);

  return fromJS(flip(idMapRoot));
};
