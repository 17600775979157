import * as ActionTypes from '../../constants/ActionTypes';

export const pageLoad = (uri) => ({
  type: ActionTypes.APP_PAGE_PROCESS_LOAD,
  payload: { uri },
});
export const selectNode = (node) => ({
  type: ActionTypes.APP_PAGE_PROCESS_SELECT_NODE,
  payload: { node },
});
