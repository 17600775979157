/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import PropTypes from 'prop-types';
import GanttChartHeader from './GanttChartHeader';
import GanttChartRow from './GanttChartRow';
import { uiColors } from '../common/styles/styles';
import { Trans } from 'react-i18next';
import { isEqual, isNil } from 'lodash';

const styles = () => ({
  '.gantt-wrapper': {
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'hidden',
  },
  '@media screen and (-webkit-min-device-pixel-ratio:0)': {
    'input[type=range]': {
      overflow: 'hidden',
      WebkitAppearance: 'none',
      backgroundColor: uiColors.GREY,
      borderRadius: '30px',
    },
    'input[type=range]:focus': {
      outline: 'none',
    },
    "input[type='range']::-webkit-slider-runnable-track": {
      height: '10px',
      WebkitAppearance: 'none',
      color: 'red',
      marginTop: '-1px',
    },

    "input[type='range']::-webkit-slider-thumb": {
      width: '10px',
      WebkitAppearance: 'none',
      height: '10px',
      cursor: 'ew-resize',
      background: uiColors.PURPLE,
      borderRadius: '60px',
      boxShadow: `-80px -5px 5px 80px ${uiColors.BRAND}`,
    },
  },

  '.range': {
    position: 'absolute',
    right: 0,
    top: '-5px',
    input: {
      margin: '10px',
    },
  },

  '.gant-wrapper-scroll': {
    overflowX: 'auto',
    '& > div': {
      width: 'fit-content',
    },

    '& .gant-first-column': {
      position: 'sticky',
      left: '0',
      minWidth: '350px',
      maxWidth: '350px',
      zIndex: 1,
      backgroundColor: uiColors.WHITE,
      paddingLeft: '20px',
    },
  },
});

const TimeSlots = {
  day: 1,
  week: 7,
  month: 30,
};

const maxVisibleTimeSlot = 20;

Object.freeze(TimeSlots);

const chartType = {
  text: PropTypes.string,
  duration: PropTypes.number,
  offset: PropTypes.number,
};
chartType.children = PropTypes.arrayOf(PropTypes.shape(chartType));

class GanttChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      totalDays: 0,
      timeSlot: null,
      visibleDay: null,
      mainContainerClassName: null,
      leftSideColumnClassName: null,
      mainContainerWidth: null,
      leftSideColumnWidth: null,
    };
    this.ganttWrapper = React.createRef();
    this.leftSideColumn = React.createRef();
    this.onChangeZoom = this.onChangeZoom.bind(this);
    this.setTimeSlot = this.setTimeSlot.bind(this);
  }

  static defaultProps = {
    data: [],
  };

  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape(chartType)),
  };

  getFirstDomElementWidthByClassName = (elementClassName) =>
    document.getElementsByClassName(elementClassName)[0].offsetWidth;

  componentDidUpdate(prevProps, prevStates) {
    const { data } = this.props;
    const { mainContainerWidth, timeSlot } = this.state;
    const mainContainerClassName = `${this.ganttWrapper.current.className}`;
    const leftSideColumnClassName = `${this.leftSideColumn.current.className}`;
    let timeScale;
    if (
      prevProps !== this.props ||
      prevStates.mainContainerWidth !== mainContainerWidth
    ) {
      const totalDays = Math.max.apply(
        Math,
        data.map((d) =>
          d.offset === Number.NEGATIVE_INFINITY
            ? d.duration
            : d.duration + d.offset,
        ),
      );
      if (!isEqual(prevProps.data, data) || (isNil(timeSlot) && !isNil(data))) {
        if (totalDays > 21 && timeSlot) {
          timeScale = TimeSlots.month;
        } else if (
          (totalDays >= 14 && !timeSlot) ||
          timeSlot === TimeSlots.week
        ) {
          timeScale = TimeSlots.week;
        } else {
          timeScale = TimeSlots.day;
        }
        this.setState({
          timeSlot: timeScale,
          totalDays,
          mainContainerClassName,
          leftSideColumnClassName,
          mainContainerWidth: this.getFirstDomElementWidthByClassName(
            mainContainerClassName,
          ),
          leftSideColumnWidth: this.getFirstDomElementWidthByClassName(
            leftSideColumnClassName,
          ),
        });
        return;
      }
      this.setState({
        totalDays,
        mainContainerClassName,
        leftSideColumnClassName,
        mainContainerWidth: this.getFirstDomElementWidthByClassName(
          mainContainerClassName,
        ),
        leftSideColumnWidth: this.getFirstDomElementWidthByClassName(
          leftSideColumnClassName,
        ),
      });
    }
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    const { mainContainerElementClassName, leftSideColumnClassName } =
      this.state;
    const mainContainerWidth = this.getFirstDomElementWidthByClassName(
      mainContainerElementClassName,
    );
    const leftSideColumnWidth = this.getFirstDomElementWidthByClassName(
      leftSideColumnClassName,
    );
    this.setState({ mainContainerWidth, leftSideColumnWidth });
  };

  onChangeZoom(event) {
    this.setState({
      visibleDay: parseInt(event.target.value, 10),
    });
  }

  setTimeSlot(event) {
    event.stopPropagation();
    this.setState({ timeSlot: Number(event.target.value) });
  }

  getTimeLabel() {
    const { timeSlot } = this.state;
    switch (timeSlot) {
      case TimeSlots.week:
        return 'gantt.week';
      case TimeSlots.month:
        return 'gantt.month';
      default:
        return 'gantt.day';
    }
  }

  render() {
    const { data, onSelectNode, selectedItem, id } = this.props;
    const {
      mainContainerWidth,
      leftSideColumnWidth,
      visibleDay,
      totalDays,
      timeSlot,
    } = this.state;
    let itemWidth;
    let ganttHeader;
    let timeHeaderColumnNumbers;
    timeHeaderColumnNumbers =
      Math.ceil(totalDays / timeSlot) > maxVisibleTimeSlot
        ? maxVisibleTimeSlot
        : Math.ceil(totalDays / timeSlot);
    itemWidth =
      (mainContainerWidth - leftSideColumnWidth) /
      (visibleDay || timeHeaderColumnNumbers);
    const getTimeLabel = this.getTimeLabel();
    ganttHeader = (
      <GanttChartHeader
        id={id}
        leftSideColumnRef={this.leftSideColumn}
        startDay={1}
        endDay={totalDays}
        chartInterval={timeSlot}
        itemWidth={itemWidth}
        timeLabelKey={getTimeLabel}
      />
    );
    const maxVisible =
      Math.ceil(totalDays / timeSlot) > maxVisibleTimeSlot
        ? maxVisibleTimeSlot
        : Math.ceil(totalDays / timeSlot);
    return (
      <div css={styles}>
        <div
          key={`wrapper-key-${id}`}
          ref={this.ganttWrapper}
          className={`gantt-wrapper ${id}`}
        >
          <div className="gant-wrapper-scroll scroll-bar">
            {totalDays > TimeSlots.day && (
              <div className="range">
                <input
                  type="radio"
                  id={`${id}-gantt.day`}
                  name={`timeSlot-${id}`}
                  value={TimeSlots.day}
                  checked={timeSlot === TimeSlots.day}
                  onChange={this.setTimeSlot}
                />
                <label htmlFor={`${id}-gantt.day`}>
                  <Trans i18nKey="gantt.day" />
                </label>
                <input
                  type="radio"
                  id={`${id}-gantt.week`}
                  name={`timeSlot-${id}`}
                  value={TimeSlots.week}
                  checked={timeSlot === TimeSlots.week}
                  onChange={this.setTimeSlot}
                />
                <label htmlFor={`${id}-gantt.week`}>
                  <Trans i18nKey="gantt.week" />
                </label>
                <input
                  type="radio"
                  id={`${id}-gantt.month`}
                  name={`timeSlot-${id}`}
                  value={TimeSlots.month}
                  checked={timeSlot === TimeSlots.month}
                  onChange={this.setTimeSlot}
                />
                <label htmlFor={`${id}-gantt.month`}>
                  <Trans i18nKey="gantt.month" />
                </label>
                <input
                  onChange={this.onChangeZoom}
                  value={visibleDay || timeHeaderColumnNumbers}
                  type="range"
                  min={1}
                  max={maxVisible}
                  step={1}
                />
              </div>
            )}
            {ganttHeader}
            {data.map((processStep, index) => {
              return (
                <GanttChartRow
                  onSelectNode={onSelectNode}
                  data={processStep}
                  selectedItem={selectedItem}
                  itemWidth={itemWidth}
                  key={index}
                  level={1}
                  totalDays={totalDays}
                  timeScale={timeSlot}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default GanttChart;
