import { call, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import lawPrefaceItemConnector from '../../../connectors/LawPrefaceItem';
import { generateApiSaga } from '../../../utils/sagas';

const doGetLawPrefaceItemsByIds = generateApiSaga(
  lawPrefaceItemConnector,
  function* (connector, action) {
    let nodeFragment = action.payload.ids;
    return yield call([connector, connector.getByIds], nodeFragment);
  },
);

export default function* getLawPrefaceItemsByIds() {
  yield takeEvery(
    ActionTypes.API_LAW_PREFACE_ITEM_GET_BY_IDS_INIT,
    doGetLawPrefaceItemsByIds,
  );
}
