import { put, select } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import * as decisionPageSelector from '../../../selectors/page/decision';
import * as decisionApiSelector from '../../../selectors/api/decision';
import * as decisionPageActions from '../../../actions/page/decision';
import * as decisionApiActions from '../../../actions/api/decision';
import {
  callAndAwaitApi,
  takeLatestWhenPreloadReady,
} from '../../../utils/sagas';
import * as favoriteApiSelector from '../../../selectors/api/favorite';
import { PageEntities } from '../../../constants/PageEntities';
import * as favoriteApiActions from '../../../actions/api/favorite';
import * as appActions from '../../../actions/app';

function* doDecisionPageLoad(action) {
  const [uri, id] = action.payload.uri;
  const favorites = yield select(
    favoriteApiSelector.getPageFavorites,
    PageEntities.DECISION_PAGE,
  );
  if (!(favorites && favorites.size)) {
    yield put(
      favoriteApiActions.getFavoritesByType(PageEntities.DECISION_PAGE),
    );
  }
  const node = yield select(decisionPageSelector.getNavigationNode, uri);
  if (id) {
    // Load decision
    yield callAndAwaitApi(decisionApiActions.getById(id));
    let decision = yield select(decisionApiSelector.get, id);
    if (!decision) {
      yield put(appActions.navigateTo404());
      return;
    }
    yield put(decisionPageActions.selectNode(decision));
    return;
  }
  yield put(decisionPageActions.getDecisions(false));
  yield put(decisionPageActions.selectNode(node));
}

export default function* decisionPageLoad() {
  yield takeLatestWhenPreloadReady(
    ActionTypes.APP_PAGE_DECISION_LOAD,
    doDecisionPageLoad,
  );
}
