import { Map } from 'immutable';
import * as permissionsApiSelector from '../api/permissions';
import * as roleApiSelector from '../api/role';
import * as userApiSelector from '../api/appuser';
import * as companyApiSelector from '../api/company';
import * as licenseApiSelector from '../api/license';
import { ADMIN_ONLY_PERMISSIONS } from '../../constants/Permissions';
import { createSelector } from 'reselect';
import moment from 'moment';
import {
  STATE_ENTITY,
  STATE_ENTITY_PROPERTY,
  STATE_ROOT_PROPERTY,
} from '../../constants/StateProperties';

export const mapRoleToOptions = (roles) =>
  toList(
    roles && roles.map((o) => getOption(get(o, 'roleName'), get(o, 'id'))),
  );
export const mapCompanyToOptions = (companies) =>
  toList(
    companies &&
      companies.map((o) => getOption(get(o, 'companyVAT'), get(o, 'id'))),
  );
const toList = (map) => (map ? map.toList() : []);
const getOption = (label, value) => Map({ label, value });
const get = (node, prop) => node.getIn(['node', prop]);

export const isLoading = (state) =>
  state.getIn([
    STATE_ROOT_PROPERTY.page,
    STATE_ENTITY.adminHome,
    STATE_ENTITY_PROPERTY.isLoading,
  ]);

export const getRolesWithPermissions = (state) => {
  const roles = roleApiSelector.getAll(state);
  return roles
    .map((role) =>
      role
        .setIn(['node', 'permissions'], getRolePermission(state, role))
        .get('node'),
    )
    .filter((role) => (role.get('roleName') || '').toLowerCase() !== 'admin')
    .toList();
};

export const getRolePermission = (state, role) => {
  const permissions = permissionsApiSelector.getAll(state);
  const roleActivePermissions = role.getIn(['node', 'permissions']);
  return (permissions || [])
    .filter(
      (permission) =>
        !ADMIN_ONLY_PERMISSIONS.includes(permission.getIn(['node', 'value'])),
    )
    .map((permission) =>
      Map({
        id: permission.getIn(['node', 'id']),
        value: permission.getIn(['node', 'value']),
        active: (roleActivePermissions || []).some(
          (rolePermission) =>
            rolePermission.get('id') === permission.getIn(['node', 'id']),
        ),
      }),
    )
    .toList();
};

export const getAllUserDetails = createSelector(
  userApiSelector.getAll,
  licenseApiSelector.getAll,
  roleApiSelector.getAll,
  companyApiSelector.getAll,
  (users, licenses, roles, companies) =>
    users
      .map((user) => {
        const filteredLicenses = licenses
          .filter(
            (license) =>
              license.getIn(['node', 'user', 'id']) ===
              user.getIn(['node', 'id']),
          )
          .map((license) =>
            license.setIn(
              ['node', 'role'],
              roles.get(license.getIn(['node', 'role', 'id']))?.get('node'),
            ),
          )
          .toList();
        const currentLicense = filteredLicenses.maxBy((license) =>
          moment(get(license, 'expirationDate')),
        );
        const company = companies.get(user.getIn(['node', 'company', 'id']));

        return Map({
          user: user.get('node'),
          company: company?.get('node'),
          licenses: {
            current: currentLicense?.get('node'),
            all: filteredLicenses?.map((license) => license.get('node')),
          },
        });
      })
      .toList(),
);
