/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import CustomModal from './CustomModal';

class LicenseRequiredView extends React.PureComponent {
  render() {
    const { t, show, onClose } = this.props;
    return (
      <CustomModal
        show={show}
        title={t('license.upgrade-license-modal-title')}
        body={t('license.need-license-upgrade')}
        cancelClickHandler={onClose}
        cancelButtonText={t('license.upgrade-license-modal-button-close-text')}
        acceptClickHandler={onClose}
        acceptButtonText={t(
          'license.upgrade-license-modal-button-subscription-text',
        )}
        promoteSubscription
      />
    );
  }
}

export default withTranslation()(LicenseRequiredView);
