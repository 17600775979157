/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import Input from '../../components/common/atoms/Input';
import * as searchPageSelectors from '../../selectors/page/search';
import * as courtApiSelectors from '../../selectors/api/court';
import * as searchPageActions from '../../actions/page/search';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import Button from '../../components/common/atoms/Button';
import MultiSelector from '../../components/common/molecules/MultiSelector';
import * as decisionApiSelectors from '../../selectors/api/decision';

class DecisionFilter extends React.Component {
  constructor(props) {
    super(props);
    this.handleStateChange = this.handleStateChange.bind(this);
    this.applyAndClose = this.applyAndClose.bind(this);
  }

  handleStateChange(val) {
    const filter = this.props.filter.copy();
    Object.entries(val).forEach(([key, value]) => (filter[key] = value));
    this.props.actions.updateSearch(filter);
  }

  applyAndClose() {
    const { filter, close } = this.props;
    close();
    this.props.actions.doSearch(filter);
  }

  render() {
    const {
      filter,
      courts,
      decisionTypes,
      caseTypes,
      meritTypes,
      reactionTypes,
      suspensiveEffects,
      formalityTypes,
      reactionFormalities,
      procurementForms,
      procurementFormProcedures,
      procurementFormAddons,
    } = this.props;
    const {
      facetDecisionCourt,
      facetDecisionDecisionType,
      facetDecisionCaseType,
      facetDecisionMeritType,
      facetDecisionReactionType,
      facetDecisionSuspensiveEffect,
      facetDecisionFormalityType,
      facetDecisionReactionFormality,
      facetDecisionProcurementForm,
      facetDecisionProcurementFormProcedure,
      facetDecisionProcurementFormAddon,
      searchTextDecisionPlaintiff,
      searchTextDecisionPlaintiffRepresentative,
      searchTextDecisionDefendant,
      searchTextDecisionDefendantRepresentative,
      rangeDecisionReactionFormalityMin,
      rangeDecisionReactionFormalityMax,
      rangeDecisionPosOpfIntClaimMin,
      rangeDecisionPosOpfIntClaimMax,
      rangeDecisionPosOpfIntAwardedMin,
      rangeDecisionPosOpfIntAwardedMax,
      rangeDecisionNegKonsIntClaimMin,
      rangeDecisionNegKonsIntClaimMax,
      rangeDecisionNegKonsIntAwardedMin,
      rangeDecisionNegKonsIntAwardedMax,
      rangeDecisionCaseCostMin,
      rangeDecisionCaseCostMax,
    } = filter;

    const onKeyDown = (event) => {
      event.stopPropagation();
    };
    return (
      <div className="d-flex flex-wrap flex-column flex-fill">
        <MultiSelector
          className="my-2"
          placeholder={this.props.t(
            'search-page.decision-filter.facetDecisionCourt',
          )}
          multiSelectedText={this.props.t(
            'search-page.decision-filter.facetDecisionCourtPlural',
          )}
          options={Object.values(courts).map((court) => ({
            value: court.node.id,
            label: court.node.name,
          }))}
          values={facetDecisionCourt}
          onChange={(value) =>
            this.handleStateChange({ facetDecisionCourt: value })
          }
        />

        <MultiSelector
          className="mb-2"
          placeholder={this.props.t(
            'search-page.decision-filter.facetDecisionDecisionType',
          )}
          multiSelectedText={this.props.t(
            'search-page.decision-filter.facetDecisionDecisionTypePlural',
          )}
          options={decisionTypes.map((decisionType) => ({
            value: decisionType,
            label: this.props.t(
              `abbreviations.decision.table.decision-type.${decisionType}`,
            ),
          }))}
          values={facetDecisionDecisionType}
          onChange={(value) =>
            this.handleStateChange({ facetDecisionDecisionType: value })
          }
        />

        <MultiSelector
          className="mb-2"
          placeholder={this.props.t(
            'search-page.decision-filter.facetDecisionCaseType',
          )}
          multiSelectedText={this.props.t(
            'search-page.decision-filter.facetDecisionCaseTypePlural',
          )}
          options={caseTypes.map((caseType) => ({
            value: caseType.code,
            label: caseType.label,
          }))}
          values={facetDecisionCaseType}
          onChange={(value) =>
            this.handleStateChange({ facetDecisionCaseType: value })
          }
        />

        <MultiSelector
          className="mb-2"
          placeholder={this.props.t(
            'search-page.decision-filter.facetDecisionMeritType',
          )}
          multiSelectedText={this.props.t(
            'search-page.decision-filter.facetDecisionMeritTypePlural',
          )}
          options={meritTypes.map((meritType) => ({
            value: meritType,
            label: this.props.t(
              `abbreviations.decision.table.merit-type.${meritType}`,
            ),
          }))}
          values={facetDecisionMeritType}
          onChange={(value) =>
            this.handleStateChange({ facetDecisionMeritType: value })
          }
        />

        <MultiSelector
          className="mb-2"
          placeholder={this.props.t(
            'search-page.decision-filter.facetDecisionReactionType',
          )}
          multiSelectedText={this.props.t(
            'search-page.decision-filter.facetDecisionReactionTypePlural',
          )}
          options={reactionTypes.map((reactionType) => ({
            value: reactionType,
            label: this.props.t(
              `abbreviations.decision.table.reaction-type.${reactionType}`,
            ),
          }))}
          values={facetDecisionReactionType}
          onChange={(value) =>
            this.handleStateChange({ facetDecisionReactionType: value })
          }
        />

        <MultiSelector
          className="mb-2"
          placeholder={this.props.t(
            'search-page.decision-filter.facetDecisionSuspensiveEffect',
          )}
          multiSelectedText={this.props.t(
            'search-page.decision-filter.facetDecisionSuspensiveEffectPlural',
          )}
          options={suspensiveEffects.map((suspensiveEffect) => ({
            value: suspensiveEffect,
            label: this.props.t(
              `abbreviations.decision.table.suspensive-effect.${suspensiveEffect.replace(
                '.',
                '',
              )}`,
            ),
          }))}
          values={facetDecisionSuspensiveEffect}
          onChange={(value) =>
            this.handleStateChange({ facetDecisionSuspensiveEffect: value })
          }
        />

        <MultiSelector
          className="mb-2"
          placeholder={this.props.t(
            'search-page.decision-filter.facetDecisionFormalityType',
          )}
          multiSelectedText={this.props.t(
            'search-page.decision-filter.facetDecisionFormalityTypePlural',
          )}
          options={formalityTypes.map((formalityType) => ({
            value: formalityType,
            label: this.props.t(
              `abbreviations.decision.table.formality-type.${formalityType}`,
            ),
          }))}
          values={facetDecisionFormalityType}
          onChange={(value) =>
            this.handleStateChange({ facetDecisionFormalityType: value })
          }
        />

        <MultiSelector
          className="mb-2"
          placeholder={this.props.t(
            'search-page.decision-filter.facetDecisionReactionFormality',
          )}
          multiSelectedText={this.props.t(
            'search-page.decision-filter.facetDecisionReactionFormalityPlural',
          )}
          options={reactionFormalities.map((reactionFormality) => ({
            value: reactionFormality,
            label: this.props.t(
              `abbreviations.decision.table.reaction-formality.${reactionFormality}`,
            ),
          }))}
          value={facetDecisionReactionFormality}
          onChange={(value) =>
            this.handleStateChange({ facetDecisionReactionFormality: value })
          }
        />

        <div className="d-flex justify-content-between">
          <Input
            className="mb-2 flex-grow-1"
            placeholder={this.props.t(
              'search-page.decision-filter.rangeDecisionReactionFormalityMin',
            )}
            type={Input.FORM}
            value={rangeDecisionReactionFormalityMin}
            onKeyDown={onKeyDown}
            onChange={(value) =>
              this.handleStateChange({
                rangeDecisionReactionFormalityMin: value.target.value,
              })
            }
          />
          <div className="mx-2 d-flex font-weight-bold mt-1">-</div>
          <Input
            className="mb-2 flex-grow-1"
            placeholder={this.props.t(
              'search-page.decision-filter.rangeDecisionReactionFormalityMax',
            )}
            type={Input.FORM}
            value={rangeDecisionReactionFormalityMax}
            onKeyDown={onKeyDown}
            onChange={(value) =>
              this.handleStateChange({
                rangeDecisionReactionFormalityMax: value.target.value,
              })
            }
          />
        </div>

        <Input
          className="mb-2"
          placeholder={this.props.t(
            'search-page.decision-filter.searchTextDecisionPlaintiff',
          )}
          type={Input.FORM}
          value={searchTextDecisionPlaintiff}
          onKeyDown={onKeyDown}
          onChange={(value) =>
            this.handleStateChange({
              searchTextDecisionPlaintiff: value.target.value,
            })
          }
        />

        <Input
          className="mb-2"
          placeholder={this.props.t(
            'search-page.decision-filter.searchTextDecisionPlaintiffRepresentative',
          )}
          type={Input.FORM}
          value={searchTextDecisionPlaintiffRepresentative}
          onKeyDown={onKeyDown}
          onChange={(value) =>
            this.handleStateChange({
              searchTextDecisionPlaintiffRepresentative: value.target.value,
            })
          }
        />

        <Input
          className="mb-2"
          placeholder={this.props.t(
            'search-page.decision-filter.searchTextDecisionDefendant',
          )}
          type={Input.FORM}
          value={searchTextDecisionDefendant}
          onKeyDown={onKeyDown}
          onChange={(value) =>
            this.handleStateChange({
              searchTextDecisionDefendant: value.target.value,
            })
          }
        />

        <Input
          className="mb-2"
          placeholder={this.props.t(
            'search-page.decision-filter.searchTextDecisionDefendantRepresentative',
          )}
          type={Input.FORM}
          value={searchTextDecisionDefendantRepresentative}
          onKeyDown={onKeyDown}
          onChange={(value) =>
            this.handleStateChange({
              searchTextDecisionDefendantRepresentative: value.target.value,
            })
          }
        />

        <MultiSelector
          className="mb-2"
          placeholder={this.props.t(
            'search-page.decision-filter.facetDecisionProcurementForm',
          )}
          multiSelectedText={this.props.t(
            'search-page.decision-filter.facetDecisionProcurementFormPlural',
          )}
          options={procurementForms.map((procurementForm) => ({
            value: procurementForm,
            label: this.props.t(
              `abbreviations.decision.table.procurement-form.${procurementForm}`,
            ),
          }))}
          values={facetDecisionProcurementForm}
          onChange={(value) =>
            this.handleStateChange({ facetDecisionProcurementForm: value })
          }
        />

        <MultiSelector
          className="mb-2"
          placeholder={this.props.t(
            'search-page.decision-filter.facetDecisionProcurementFormProcedure',
          )}
          multiSelectedText={this.props.t(
            'search-page.decision-filter.facetDecisionProcurementFormProcedurePlural',
          )}
          options={procurementFormProcedures.map(
            (procurementFormProcedure) => ({
              value: procurementFormProcedure,
              label: this.props.t(
                `abbreviations.decision.table.procurement-form-procedure.${procurementFormProcedure}`,
              ),
            }),
          )}
          values={facetDecisionProcurementFormProcedure}
          onChange={(value) =>
            this.handleStateChange({
              facetDecisionProcurementFormProcedure: value,
            })
          }
        />

        <MultiSelector
          className="mb-2"
          placeholder={this.props.t(
            'search-page.decision-filter.facetDecisionProcurementFormAddon',
          )}
          multiSelectedText={this.props.t(
            'search-page.decision-filter.facetDecisionProcurementFormAddonPlural',
          )}
          options={procurementFormAddons.map((procurementFormAddon) => ({
            value: procurementFormAddon,
            label: this.props.t(
              `abbreviations.decision.table.procurement-form-addon.${procurementFormAddon}`,
            ),
          }))}
          values={facetDecisionProcurementFormAddon}
          onChange={(value) =>
            this.handleStateChange({ facetDecisionProcurementFormAddon: value })
          }
        />

        <div className="d-flex justify-content-between">
          <Input
            className="mb-2 flex-grow-1"
            placeholder={this.props.t(
              'search-page.decision-filter.rangeDecisionPosOpfIntClaimMin',
            )}
            type={Input.FORM}
            value={rangeDecisionPosOpfIntClaimMin}
            onKeyDown={onKeyDown}
            onChange={(value) =>
              this.handleStateChange({
                rangeDecisionPosOpfIntClaimMin: value.target.value,
              })
            }
          />
          <div className="mx-2 d-flex font-weight-bold mt-1">-</div>
          <Input
            className="mb-2 flex-grow-1"
            placeholder={this.props.t(
              'search-page.decision-filter.rangeDecisionPosOpfIntClaimMax',
            )}
            type={Input.FORM}
            value={rangeDecisionPosOpfIntClaimMax}
            onKeyDown={onKeyDown}
            onChange={(value) =>
              this.handleStateChange({
                rangeDecisionPosOpfIntClaimMax: value.target.value,
              })
            }
          />
        </div>

        <div className="d-flex justify-content-between">
          <Input
            className="mb-2 flex-grow-1"
            placeholder={this.props.t(
              'search-page.decision-filter.rangeDecisionPosOpfIntAwardedMin',
            )}
            type={Input.FORM}
            value={rangeDecisionPosOpfIntAwardedMin}
            onKeyDown={onKeyDown}
            onChange={(value) =>
              this.handleStateChange({
                rangeDecisionPosOpfIntAwardedMin: value.target.value,
              })
            }
          />
          <div className="mx-2 d-flex font-weight-bold mt-1">-</div>
          <Input
            className="mb-2 flex-grow-1"
            placeholder={this.props.t(
              'search-page.decision-filter.rangeDecisionPosOpfIntAwardedMax',
            )}
            type={Input.FORM}
            value={rangeDecisionPosOpfIntAwardedMax}
            onKeyDown={onKeyDown}
            onChange={(value) =>
              this.handleStateChange({
                rangeDecisionPosOpfIntAwardedMax: value.target.value,
              })
            }
          />
        </div>

        <div className="d-flex justify-content-between">
          <Input
            className="mb-2 flex-grow-1"
            placeholder={this.props.t(
              'search-page.decision-filter.rangeDecisionNegKonsIntClaimMin',
            )}
            type={Input.FORM}
            value={rangeDecisionNegKonsIntClaimMin}
            onKeyDown={onKeyDown}
            onChange={(value) =>
              this.handleStateChange({
                rangeDecisionNegKonsIntClaimMin: value.target.value,
              })
            }
          />
          <div className="mx-2 d-flex font-weight-bold mt-1">-</div>
          <Input
            className="mb-2 flex-grow-1"
            placeholder={this.props.t(
              'search-page.decision-filter.rangeDecisionNegKonsIntClaimMax',
            )}
            type={Input.FORM}
            value={rangeDecisionNegKonsIntClaimMax}
            onKeyDown={onKeyDown}
            onChange={(value) =>
              this.handleStateChange({
                rangeDecisionNegKonsIntClaimMax: value.target.value,
              })
            }
          />
        </div>

        <div className="d-flex justify-content-between">
          <Input
            className="mb-2 flex-grow-1"
            placeholder={this.props.t(
              'search-page.decision-filter.rangeDecisionNegKonsIntAwardedMin',
            )}
            type={Input.FORM}
            value={rangeDecisionNegKonsIntAwardedMin}
            onKeyDown={onKeyDown}
            onChange={(value) =>
              this.handleStateChange({
                rangeDecisionNegKonsIntAwardedMin: value.target.value,
              })
            }
          />
          <div className="mx-2 d-flex font-weight-bold mt-1">-</div>
          <Input
            className="mb-2 flex-grow-1"
            placeholder={this.props.t(
              'search-page.decision-filter.rangeDecisionNegKonsIntAwardedMax',
            )}
            type={Input.FORM}
            value={rangeDecisionNegKonsIntAwardedMax}
            onKeyDown={onKeyDown}
            onChange={(value) =>
              this.handleStateChange({
                rangeDecisionNegKonsIntAwardedMax: value.target.value,
              })
            }
          />
        </div>

        <div className="d-flex justify-content-between">
          <Input
            className="mb-2 flex-grow-1"
            placeholder={this.props.t(
              'search-page.decision-filter.rangeDecisionCaseCostMin',
            )}
            type={Input.FORM}
            value={rangeDecisionCaseCostMin}
            onKeyDown={onKeyDown}
            onChange={(value) =>
              this.handleStateChange({
                rangeDecisionCaseCostMin: value.target.value,
              })
            }
          />
          <div className="mx-2 d-flex font-weight-bold mt-1">-</div>
          <Input
            className="mb-2 flex-grow-1"
            placeholder={this.props.t(
              'search-page.decision-filter.rangeDecisionCaseCostMax',
            )}
            type={Input.FORM}
            value={rangeDecisionCaseCostMax}
            onKeyDown={onKeyDown}
            onChange={(value) =>
              this.handleStateChange({
                rangeDecisionCaseCostMax: value.target.value,
              })
            }
          />
        </div>

        <Button
          className="mt-3 align-self-end"
          variant="secondary"
          onClick={this.applyAndClose}
        >
          {this.props.t('search-page.apply')}
        </Button>
      </div>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  filter: searchPageSelectors.getCurrentSearchFilter,
  courts: courtApiSelectors.getAll,
  caseTypes: decisionApiSelectors.getDecisionCaseTypes,
  decisionTypes: decisionApiSelectors.getDecisionTypes,
  meritTypes: decisionApiSelectors.getDecisionMeritTypes,
  reactionTypes: decisionApiSelectors.getDecisionReactionTypes,
  formalityTypes: decisionApiSelectors.getDecisionFormalityTypes,
  reactionFormalities: decisionApiSelectors.getDecisionReactionFormality,
  suspensiveEffects: decisionApiSelectors.getDecisionSuspensiveEffect,
  procurementForms: decisionApiSelectors.getDecisionProcurementForm,
  procurementFormProcedures:
    decisionApiSelectors.getDecisionProcurementFormProcedure,
  procurementFormAddons: decisionApiSelectors.getDecisionProcurementFormAddon,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      updateSearch: searchPageActions.updateFilter,
      doSearch: searchPageActions.runSearch,
    },
    dispatch,
  ),
});

export default withTranslation()(
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    withImmutablePropsToJS,
  )(DecisionFilter),
);
