import { call, select, takeLatest } from 'redux-saga/effects';
import * as ActionTypes from '../../constants/ActionTypes';
import AuthConnector from '../../connectors/Auth';
import { generateApiSaga } from '../../utils/sagas';
import { baseAuthUrl } from '../../selectors/store/app';

const doJwtLogin = generateApiSaga(
  AuthConnector,
  function* (connector, action) {
    let username = action.payload.username;
    let password = action.payload.password;
    return yield call([connector, connector.loginJwt], username, password);
  },
  function* (ConnectorClass) {
    const url = yield select(baseAuthUrl);
    return yield new ConnectorClass(url);
  },
);

export default function* jwtLogin() {
  yield takeLatest(ActionTypes.AUTH_JWT_CALL_INIT, doJwtLogin);
}
