import userAnonymousSrc from '../../images/user-anonymous.svg';
import userRegularSrc from '../../images/user-regular.svg';
import userNavigatorSrc from '../../images/user-navigator.svg';
import userAnalystSrc from '../../images/user-analyst.svg';
import i18n from '../../localization/i18n';

const cardInfos = () => {
  return [
    {
      headline: i18n.t('front.promotion.licenses.anonymous.headline'),
      imgSrc: userAnonymousSrc,
      capabilities: i18n.t('front.promotion.licenses.anonymous.capabilities', {
        returnObjects: true,
      }),
    },
    {
      headline: i18n.t('front.promotion.licenses.regular.headline'),
      subtitle: i18n.t('front.promotion.licenses.regular.price', {
        returnObjects: true,
      }),
      subSubtitle: i18n.t('front.promotion.excl-moms', { returnObjects: true }),
      imgSrc: userRegularSrc,
      capabilities: i18n.t('front.promotion.licenses.regular.capabilities', {
        returnObjects: true,
      }),
    },
    {
      headline: i18n.t('front.promotion.licenses.navigator.headline'),
      subtitle: i18n.t('front.promotion.licenses.navigator.price', {
        returnObjects: true,
      }),
      subSubtitle: i18n.t('front.promotion.excl-moms', { returnObjects: true }),
      imgSrc: userNavigatorSrc,
      capabilities: i18n.t('front.promotion.licenses.navigator.capabilities', {
        returnObjects: true,
      }),
    },
    {
      headline: i18n.t('front.promotion.licenses.analyst.headline'),
      subtitle: i18n.t('front.promotion.licenses.analyst.price', {
        returnObjects: true,
      }),
      subSubtitle: i18n.t('front.promotion.excl-moms', { returnObjects: true }),
      imgSrc: userAnalystSrc,
      capabilities: i18n.t('front.promotion.licenses.analyst.capabilities', {
        returnObjects: true,
      }),
    },
  ];
};

export default cardInfos;
