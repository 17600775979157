import { call, takeEvery, select } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import { generateApiSaga } from '../../../utils/sagas';
import FavoriteConnector from '../../../connectors/Favorite';
import * as authSelectors from '../../../selectors/store/auth';

const entityFields = {
  taxonomy: 'keyword',
  decision: 'decision',
  lawitem: 'lawItem',
  theoryitem: 'theoryItem',
  process: 'process',
};

const doSet = generateApiSaga(FavoriteConnector, function* (connector, action) {
  const favorites = action.payload.favorites || {};
  const node = action.payload.node;
  const favorite =
    (action.payload.favorite || favorites[node.entityType]) &&
    favorites[node.entityType][`${node.id}`];

  if (favorite) {
    favorite.active = !favorite.active;
    return yield call([connector, connector.updateFavorite], favorite);
  } else {
    const userId = yield select(authSelectors.getUserId);
    const newFavorite = {
      active: true,
      entityId: node.id,
      type: node.entityType,
      appUser: `appUser/${userId}`,
    };
    if (entityFields[node.entityType]) {
      newFavorite[entityFields[node.entityType]] = `${
        entityFields[node.entityType]
      }/${node.id}`;
    }
    return yield call([connector, connector.createFavorite], newFavorite);
  }
});

export default function* setFavorite() {
  yield takeEvery(ActionTypes.API_SET_FAVORITE_INIT, doSet);
}
