import { fontColors } from './styles';

export const styles = {
  '.table-body': {
    'tr:first-of-type': {
      borderTop: '1px solid black',
    },
    'tr:nth-of-type(odd)': {
      backgroundColor: '#F4F6FB',
    },
  },
  '.table-head': {
    '&.clickable': {
      cursor: 'pointer',
    },
    '.sort-icon': {
      float: 'right',
    },
  },
  '&:not(.generic-table)': {
    '.table-row': {
      td: {
        padding: '10px',
      },
      'td:not(first-of-type)': {
        width: '15%',
      },
      'td:first-of-type': {
        width: '45%',
      },
      'td:last-of-type': {
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        width: '10%',
        color: fontColors.BRAND,
      },
    },
  },
  '&.generic-table': {
    tableLayout: 'fixed',
    wordBreak: 'break-word',
  },
};
