import BaseConnector from './Base';
import * as Searches from '../constants/Searches';
import * as EntityTypes from '../constants/EntityTypes';

export default class TaxonomyConnector extends BaseConnector {
  constructor(...args) {
    super(...args);
    this.entityType = EntityTypes.TAXONOMY;
  }

  getById(id, projection) {
    const url = this.query().withId(id).withProjection(projection).build();
    return this.authGet(url);
  }

  getAll(paging) {
    return this.authGet(this.query().withPaging(paging).build());
  }

  getByIdsWithParents(ids) {
    let query = this.query().withSearch(Searches.findByIds, [ids]).build();

    return this.authGet(query);
  }

  createTaxonomy(taxonomy) {
    return this.authPost(this.query().build(), taxonomy);
  }
}
