import { fromJS } from 'immutable';
import * as ActionTypes from '../../constants/ActionTypes';
import { API_GET_RELATION_TREE_TAXONOMY } from '../../constants/ActionTypes';
import { mergeEntityListToState, mergeResponseToState } from './index';
import * as EntityTypes from '../../constants/EntityTypes';
import { getAllPagesRequestType } from '../../selectors/actions';

const initialState = fromJS({
  isLoadingRelatedTaxonomies: false,
  isLoading: false, // TODO: Move to a page reducer
  data: {},
});
export default function taxonomyApiReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.API_TAXONOMY_CREATE_REQUEST:
    case ActionTypes.API_TAXONOMY_GET_ALL_REQUEST:
      return state.merge({
        isLoading: true, // TODO: Move to a page reducer
      });
    case ActionTypes.API_TAXONOMY_GET_BY_IDS_REQUEST:
      return state.merge({
        isLoadingRelatedTaxonomies: true, // TODO: Move to a page reducer
      });
    case ActionTypes.TXN_ALL_PAGES_COMPLETE:
      if (
        getAllPagesRequestType(action) === ActionTypes.API_TAXONOMY_GET_ALL_INIT
      ) {
        return state.merge({
          isLoading: false, // TODO: Move to a page reducer
        });
      }
      return state;
    case ActionTypes.API_TAXONOMY_CREATE_SUCCESS:
      return state.withMutations((ctx) => {
        mergeResponseToState(ctx, action, EntityTypes.TAXONOMY);
        ctx.set('isLoading', false); // TODO: Move to a page reducer
      });
    case API_GET_RELATION_TREE_TAXONOMY:
      return mergeEntityListToState(
        state,
        action.payload,
        EntityTypes.TAXONOMY,
      );
    case ActionTypes.API_TAXONOMY_GET_ALL_SUCCESS:
    case ActionTypes.API_TAXONOMY_GET_BY_IDS_SUCCESS:
      return state.withMutations((ctx) => {
        mergeResponseToState(ctx, action, EntityTypes.TAXONOMY);
        ctx.set('isLoadingRelatedTaxonomies', false);
      });
    default:
      return state;
  }
}
