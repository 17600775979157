/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component } from 'react';
import { compose } from 'recompose';
import { Trans, withTranslation } from 'react-i18next';
import noPhoneSupport from '../../images/noPhoneSupport.png';
import Text, { TextType } from '../../components/common/atoms/Text';
import { HOME_ROUTE } from '../../constants/Urls';

const styles = {
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  '.no-support-image': {
    width: '100vw',
    paddingBottom: '50px',
    paddingTop: '50px',
  },
  '.no-support-text': {
    textAlign: 'center',
    height: '90px',
  },
};

class NoMobileSupportPage extends Component {
  render() {
    return (
      <div css={styles}>
        <Text type={TextType.HEADER_1_LARGE} className="no-support-text">
          <Trans i18nKey="noMobileSupportPage.header" />
        </Text>
        <img
          src={noPhoneSupport}
          alt={this.props.t('noMobileSupportPage.imageAlt')}
          title={this.props.t('noMobileSupportPage.imageTitle')}
          className="no-support-image"
        />
        <a href={HOME_ROUTE} className="no-support-text">
          <Trans i18nKey="noMobileSupportPage.hyperLink" />
        </a>
      </div>
    );
  }
}

export default compose(withTranslation())(NoMobileSupportPage);
