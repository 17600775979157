import { put, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import * as lawItemActions from '../../../actions/api/lawitem';
import { getAllPages } from '../../../actions/api';

function* doGetAllByLaw(action) {
  let node = action.payload.node;
  let initAction = lawItemActions.getAllByLaw(node);
  yield put(getAllPages(initAction));
}
export default function* getAllLawItems() {
  yield takeEvery(ActionTypes.API_LAWITEM_GET_ALL_BY_LAW_TXN, doGetAllByLaw);
}
