/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component } from 'react';
import { compose, pure } from 'recompose';
import { connect } from 'react-redux';
import ContentOuterBound from '../../components/common/atoms/ContentOuterBound';
import { bindActionCreators } from 'redux';
import * as lawPageSelector from '../../selectors/page/law';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import { goToAnchor } from 'react-scrollable-anchor';
import { push } from 'connected-react-router/immutable';
import LayoutGrid1To2 from '../../components/common/atoms/LayoutGrid1To2';
import ArticleNode from '../../components/common/molecules/ArticleNode';
import * as EntityTypes from '../../constants/EntityTypes';
import NavigationTree from '../../components/navigationTree/NavigationTree';
import * as lawPageActions from '../../actions/page/law';
import DefaultPageLayout from '../../components/common/atoms/DefaultPageLayout';
import * as favoriteSelector from '../../selectors/api/favorite';
import * as favoriteApiActions from '../../actions/api/favorite';
import ArticleFooter from '../../components/common/molecules/ArticleFooter';
import * as htmlHelpers from '../../utils/HtmlHelpers';
import { refBind } from '../../utils/ref-bind';
import {
  LAW_FOOTER_PERMISSIONS,
  PERMISSIONS,
} from '../../constants/Permissions';
import ControlledByPermission from '../shared/ControlledByPermission';
import { buildPageLink } from '../../utils/link';
import * as urls from '../../constants/Urls';
import NoMobileSupportPage from './NoMobileSupportPage';
import { MOBILE_SIZE } from '../../constants/ScreenSize';

const SmartArticleNode = pure(withImmutablePropsToJS(ArticleNode));
const SmartNavigationTree = pure(withImmutablePropsToJS(NavigationTree));

class LawPage extends Component {
  constructor(props) {
    super(props);
    refBind(
      this,
      'handleNavigationClick',
      'onSelectNode',
      'handleNodeExpand',
      'getUri',
      'handleNodeFavorite',
      'handleNodeExpandLawGroup',
    );
  }

  componentDidUpdate(prevProps) {
    const { currentNode } = this.props;
    const curNodeId = currentNode?.getIn(['node', 'id']);
    if (
      currentNode &&
      prevProps.currentNode?.getIn(['node', 'id']) !== curNodeId
    ) {
      this.goToCurrentNode(curNodeId);
    }
  }

  goToCurrentNode(curNodeId) {
    goToAnchor(curNodeId);
    htmlHelpers.goToAnchorInContainerWithScroll(
      'overflow-holder',
      curNodeId,
      140,
      'tree',
    );
  }

  /**
   * Takes a node and checks the entityType and uri. If the selected node is not a Jurisdiction and has
   * an uri, a URL is generated pointing to the specific law.
   * @param {*} node An object in a tree holding all information about the node itself,
   * its parents and its children.
   * @return {?string} the URL pointing to the node.
   */
  getUri(node) {
    const { currentParent } = this.props;
    const currentParentNodeUri =
      currentParent && currentParent.getIn(['node', 'uri']);
    let uri = node.uri;
    if (node.entitytype === EntityTypes.JURISDICTION || !uri) {
      return null;
    }
    return this.props.currentParent
      ? buildPageLink(urls.LAW_ROUTE, currentParentNodeUri, uri)
      : buildPageLink(urls.LAW_ROUTE, uri);
  }

  handleNavigationClick(node) {
    const { actions } = this.props;
    const link = this.getUri(node.node);
    link && actions.push(link);
  }

  onSelectNode(node) {
    const { actions } = this.props;
    const link = this.getUri(node.node);
    link && actions.push(link);
    this.handleNodeExpand(node, true);
  }

  handleNodeExpand(node, expanded) {
    expanded && this.props.actions.getChildren(node.node.id);
  }

  handleNodeExpandLawGroup(node, expand) {}

  handleNodeFavorite(node) {
    this.props.actions.toggleFavorite(node, this.props.favorites.toJS());
  }

  render() {
    const {
      currentNode,
      favorites,
      articleNode,
      navigationTree,
      currentParent,
    } = this.props;

    let navigationEntityType,
      navigationOnNodeExpand,
      navigationCountPermission,
      navigationIsArticle,
      expandLevel;
    const shouldRenderNormalTree = currentParent && navigationTree;
    const shouldRenderLawTree = !currentParent;
    if (shouldRenderNormalTree) {
      navigationEntityType =
        navigationTree.first()?.getIn(['node', 'entityType']) ??
        EntityTypes.LAWITEM;
      navigationOnNodeExpand = this.handleNodeExpand;
      navigationCountPermission = PERMISSIONS.LAW_VIEW_RELATED_COUNT;
      navigationIsArticle = true;
    } else if (shouldRenderLawTree) {
      navigationEntityType = EntityTypes.JURISDICTION;
      expandLevel = 2;
      navigationOnNodeExpand = this.handleNodeExpandLawGroup;
      navigationIsArticle = false;
    }
    const isMobile = window.innerWidth < MOBILE_SIZE;

    return (
      <DefaultPageLayout>
        {isMobile ? (
          <NoMobileSupportPage />
        ) : (
          <div>
            <ArticleFooter
              addLevelView
              permissionSet={LAW_FOOTER_PERMISSIONS}
            />
            <ContentOuterBound>
              <LayoutGrid1To2
                onRenderColumn1={() => (
                  <ControlledByPermission
                    permission={PERMISSIONS.LAW_VIEW_HEADERS}
                    render={
                      <SmartNavigationTree
                        entityType={navigationEntityType}
                        onNodeExpand={navigationOnNodeExpand}
                        countPermission={navigationCountPermission}
                        isArticle={navigationIsArticle}
                        tree={navigationTree}
                        selectedNode={currentNode}
                        expandLevel={expandLevel}
                        //The headline defaults to Regler so we avoid empty titles on the
                        //base navigation page where we do not have a selection yet.
                        headline={
                          (currentParent &&
                            currentParent.getIn(['node', 'title'])) ||
                          'Regler'
                        }
                        onNodeSelection={this.onSelectNode}
                      />
                    }
                  />
                )}
                onRenderColumn2={() =>
                  currentNode && articleNode ? (
                    <SmartArticleNode
                      singleLevel={true}
                      viewPermission={PERMISSIONS.LAW_VIEW_CONTENT}
                      onArticleNodeClick={this.handleNavigationClick}
                      articleNode={articleNode}
                      currentNode={currentNode}
                      favorites={favorites}
                      noNavigation
                      entityType={EntityTypes.LAWITEM}
                      linkConstructor={this.getUri}
                      onFavoriteClick={this.handleNodeFavorite}
                    />
                  ) : null
                }
              />
            </ContentOuterBound>
          </div>
        )}
      </DefaultPageLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const currentParent = lawPageSelector.getCurrentParent(state);
  const currentNode = currentParent && lawPageSelector.getCurrentNode(state);
  const articleNode =
    currentNode && lawPageSelector.getArticleNodeForCurrentNode(state);
  const navigationTree = currentParent
    ? lawPageSelector.getLawItemsAndLawPrefaceItemsNavigationTreeForCurrentParent(
        state,
      )
    : lawPageSelector.getLawGroupNavigationTree(state);
  const favorites = favoriteSelector.getLawPageFavorites(state);

  return {
    navigationTree,
    currentParent,
    currentNode,
    articleNode,
    favorites,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      push,
      getChildren: lawPageActions.getChildren,
      toggleFavorite: favoriteApiActions.toggleFavoriteByNode,
    },
    dispatch,
  ),
});
export default compose(connect(mapStateToProps, mapDispatchToProps))(LawPage);
