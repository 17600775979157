import React, { Component } from 'react';
import DecisionSearch from './DecisionSearch';
import TheorySearch from './TheorySearch';
import ProcessStepSearch from './ProcessStepSearch';
import TaxonomySearch from './TaxonomySearch';
import LawSearch from './LawSearch';
import * as EntityTypes from '../../constants/EntityTypes';
import PropTypes from 'prop-types';
import { refBind } from '../../utils/ref-bind';

export default class ItemSearch extends Component {
  constructor(props) {
    super(props);
    refBind(this, 'renderByType');
  }

  renderByType() {
    const { itemSearchType, ignoreOptions, onChange, isDisabled } = this.props;
    switch (itemSearchType) {
      case EntityTypes.LAWITEM:
        return (
          <LawSearch
            clearOnSelect
            ignoreOptions={ignoreOptions}
            onLawItemsChange={onChange}
            isDisabled={isDisabled}
          />
        );
      case EntityTypes.THEORYITEM:
        return (
          <TheorySearch
            clearOnSelect
            ignoreOptions={ignoreOptions}
            onTheoryChange={onChange}
            isDisabled={isDisabled}
          />
        );
      case EntityTypes.DECISION:
        return (
          <DecisionSearch
            clearOnSelect
            ignoreOptions={ignoreOptions}
            onDecisionsChange={onChange}
            isDisabled={isDisabled}
          />
        );
      case EntityTypes.PROCESSSTEP:
        return (
          <ProcessStepSearch
            clearOnSelect
            ignoreOptions={ignoreOptions}
            onProcessStepChange={onChange}
            isDisabled={isDisabled}
          />
        );
      case EntityTypes.TAXONOMY:
        return (
          <TaxonomySearch
            clearOnSelect
            ignoreOptions={ignoreOptions}
            onKeywordsChange={onChange}
            isDisabled={isDisabled}
          />
        );
    }
  }

  render() {
    return <div>{this.renderByType()}</div>;
  }
}

ItemSearch.propTypes = {
  itemSearchType: PropTypes.oneOf([
    EntityTypes.DECISION,
    EntityTypes.LAWITEM,
    EntityTypes.TAXONOMY,
    EntityTypes.THEORYITEM,
    EntityTypes.PROCESSSTEP,
  ]).isRequired,
  ignoreOptions: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

ItemSearch.defaultProps = {
  ignoreOptions: undefined,
  isDisabled: false,
};
