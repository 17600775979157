import { fromJS } from 'immutable';
import * as ActionTypes from '../../constants/ActionTypes';
import { mergeResponseToState } from './index';
import * as EntityTypes from '../../constants/EntityTypes';

const initialState = fromJS({ data: {} });

export default function permissionApiReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.API_GET_ALL_PERMISSION_SUCCESS:
      return mergeResponseToState(state, action, EntityTypes.PERMISSION);
    default:
      return state;
  }
}
