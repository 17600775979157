import React from 'react';
import ReactSelect from 'react-select';
import { fontColors, rgbaColors, uiColors } from '../styles/styles';

const colourStyles = {
  control: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: uiColors.WHITE,
    boxShadow: 'none',
    border: `1px solid ${uiColors.GREY}`,
    ':hover': {
      ...styles[':active'],
      cursor: 'pointer',
      boxShadow: `1px 1px 1px 0 ${rgbaColors.BLACK_SHADOW_30}`,
      borderColor: isFocused ? uiColors.BRAND_LIGHT : uiColors.BLACK,
    },
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      color: isDisabled
        ? uiColors.GREY_DISABLED
        : isSelected
        ? fontColors.WHITE
        : fontColors.BLACK,
      fontWeight: isSelected ? 'bold' : 'normal',
      cursor: isDisabled ? 'not-allowed' : 'default',
    };
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: uiColors.BRAND,
      color: fontColors.WHITE,
      fontWeight: 'bold',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    paddingLeft: '10px',
    paddingRight: '10px',
    color: fontColors.WHITE,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    paddingTop: '2px',
    fontWeight: 'bold',
    color: fontColors.WHITE,
  }),
};

const filterTheme = (theme) => ({
  ...theme,
  colors: { ...theme.colors, primary: uiColors.BRAND_LIGHT },
});

class Filter extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <ReactSelect styles={colourStyles} theme={filterTheme} {...this.props} />
    );
  }
}

export default Filter;
