import EntityFields from '../constants/EntityFields';
import * as EntityTypes from '../constants/EntityTypes';

export const createLawPrefaceItemText = (node) =>
  [
    node[EntityFields[EntityTypes.LAWPREFACEITEM].LFFKAT],
    node[EntityFields[EntityTypes.LAWPREFACEITEM].LFFO1],
    node[EntityFields[EntityTypes.LAWPREFACEITEM].LFF02],
    node[EntityFields[EntityTypes.LAWPREFACEITEM].LFF03],
    node[EntityFields[EntityTypes.LAWPREFACEITEM].LFF04],
    node[EntityFields[EntityTypes.LAWPREFACEITEM].LFFPAR],
    node[EntityFields[EntityTypes.LAWPREFACEITEM].LFFNR],
    node[EntityFields[EntityTypes.LAWPREFACEITEM].LFFPKT],
  ]
    .filter(Boolean)
    .join(' ');
