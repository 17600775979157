import BaseConnector from './Base';
import * as EntityTypes from '../constants/EntityTypes';

export default class CompanyConnector extends BaseConnector {
  constructor(...args) {
    super(...args);
    this.entityType = EntityTypes.COMPANY;
  }

  getAll() {
    return this.authGet(this.query().withSize(1000).build());
  }

  updateCompany(company) {
    return this.authPut(this.query().withId(company.id).build(), company);
  }
}
