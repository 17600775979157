/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Component } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { uiColors } from '../common/styles/styles';
import Text from '../common/atoms/Text';

const selectedStyles = {
  backgroundColor: uiColors.DARK_BLUE,
  borderColor: uiColors.DARK_BLUE,
  boxShadow: '0 0 0 0.2rem rgb(38 143 255 / 50%)',
};

const borderStyles = {
  backgroundColor: uiColors.BRAND,
  borderColor: uiColors.BRAND,
};

const styles = ({ barWidth, offset, level, focus }) => ({
  margin: 'auto 0',
  minWidth: barWidth + 'px',
  height: '16px',
  borderRadius: '8px',
  marginLeft: offset + 'px',
  opacity: level > 1 ? 0.5 : 1,
  ...(focus ? selectedStyles : borderStyles),
  '&:focus': selectedStyles,
});

class HorizontalBar extends Component {
  render() {
    const { itemWidth, data, level, onClick, timeScale, selectedItem } =
      this.props;
    const parts = [];
    if (data.node.noDaysDescription) {
      parts.push(data.node.noDaysDescription);
    }
    if (data.node.timeLimit) {
      parts.push(data.node.timeLimit);
    }
    const tooltip =
      parts.length &&
      parts
        .map((string) => <Text>{string}</Text>)
        .reduce((accumulator, currentValue) => (
          <React.Fragment>
            {accumulator}
            <br />
            {currentValue}
          </React.Fragment>
        ));
    const barWidth = (data.duration * itemWidth) / timeScale;
    const offset = (data.offset * itemWidth) / timeScale;
    const focus = selectedItem && selectedItem.node.uri === data.node.uri;
    const button = (
      <Button
        onClick={() => onClick(data)}
        variant="primary"
        css={styles({ barWidth, offset, level, focus })}
      />
    );
    return tooltip ? (
      <OverlayTrigger
        key="top"
        placement="top"
        overlay={<Tooltip id={data.node.uri}>{tooltip}</Tooltip>}
      >
        {button}
      </OverlayTrigger>
    ) : (
      button
    );
  }
}

export default HorizontalBar;
