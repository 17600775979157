/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import GenericForm from './GenericForm';

class CompanyForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getForm(this.props);
    this.props.defaults &&
      Object.entries(this.props.defaults).forEach(([key, value]) => {
        if (this.state.form[key]) {
          this.state.form[key].value = value;
        }
      });
  }

  componentDidUpdate(prevProps) {
    if (this.props.disabled != prevProps.disabled) {
      this.setState(this.getForm(this.props));
    }
  }

  getForm({ company, disabled }) {
    return {
      form_name: 'company',
      form: {
        vat: {
          value: company?.companyVAT ?? '',
          type: 'text',
          required: true,
          pattern: '[0-9]+',
          disabled: disabled,
        },
        name: {
          value: company?.name ?? '',
          type: 'text',
          required: true,
          disabled: disabled,
        },
      },
    };
  }

  render() {
    const { numberOfColumns, onChange } = this.props;
    return (
      <GenericForm
        form={this.state}
        numberOfColumns={numberOfColumns}
        onChange={onChange}
      />
    );
  }
}

export default withTranslation()(CompanyForm);
