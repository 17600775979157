import { call, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import LawItemConnector from '../../../connectors/LawItem';
import { generateApiSaga } from '../../../utils/sagas';

const doFetch = generateApiSaga(
  LawItemConnector,
  function* (connector, action) {
    return yield call(
      [connector, connector.getTopLevel],
      action.payload.node.getIn(['node', 'id']),
      null,
      1,
    );
  },
);

export default function* findUriToSelect() {
  yield takeEvery(ActionTypes.API_LAW_ITEM_GET_FIRST_ITEM_BY_LAW_INIT, doFetch);
}
