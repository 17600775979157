import * as permissionSelector from '../selectors/store/permissions';
import { PERMISSIONS } from './Permissions';

export const AdminPagePermissions = {
  fn: permissionSelector.hasAnyPermissions,
  permissions: [
    PERMISSIONS.LAW_UPDATE_CONTENT,
    PERMISSIONS.THEORY_UPDATE_CONTENT,
    PERMISSIONS.DECISION_UPDATE_CONTENT,
    PERMISSIONS.PROCESS_UPDATE_CONTENT,
    PERMISSIONS.USER_VIEW_NEWS_SUBSCRIPTIONS,
    PERMISSIONS.USER_CREATE_LICENSE,
    PERMISSIONS.USER_MANAGE_ROLES,
  ],
};

export const LawPagePermissions = {
  fn: permissionSelector.hasAnyPermissions,
  permissions: [
    PERMISSIONS.LAW_VIEW_ANONYMOUS,
    PERMISSIONS.LAW_VIEW_CONTENT,
    PERMISSIONS.LAW_VIEW_RELATED_COUNT,
    PERMISSIONS.LAW_FOOTER_LAW_NAVIGATE,
    PERMISSIONS.LAW_FOOTER_LAW_RED_THREAD,
    PERMISSIONS.LAW_FOOTER_CONTENT,
    PERMISSIONS.LAW_FOOTER_NAVIGATE,
    PERMISSIONS.LAW_FOOTER_SEARCH,
    PERMISSIONS.LAW_UPDATE_CONTENT,
  ],
};

export const TheoryPagePermissions = {
  fn: permissionSelector.hasAnyPermissions,
  permissions: [
    PERMISSIONS.LAW_VIEW_ANONYMOUS,
    PERMISSIONS.LAW_VIEW_CONTENT,
    PERMISSIONS.LAW_VIEW_RELATED_COUNT,
    PERMISSIONS.LAW_FOOTER_LAW_NAVIGATE,
    PERMISSIONS.LAW_FOOTER_LAW_RED_THREAD,
    PERMISSIONS.LAW_FOOTER_CONTENT,
    PERMISSIONS.LAW_FOOTER_NAVIGATE,
    PERMISSIONS.LAW_FOOTER_SEARCH,
    PERMISSIONS.LAW_UPDATE_CONTENT,
  ],
};

export const ProcessPagePermissions = {
  fn: permissionSelector.hasAnyPermissions,
  permissions: [
    PERMISSIONS.PROCESS_VIEW_ANONYMOUS,
    PERMISSIONS.PROCESS_VIEW_CONTENT,
    PERMISSIONS.PROCESS_VIEW_LAW,
    PERMISSIONS.PROCESS_VIEW_THEORY,
    PERMISSIONS.PROCESS_VIEW_RELATED_COUNT,
    PERMISSIONS.PROCESS_NAVIGATE_TO_LAW,
    PERMISSIONS.PROCESS_NAVIGATE_TO_THEORY,
    PERMISSIONS.PROCESS_FOOTER_CONTENT,
    PERMISSIONS.PROCESS_FOOTER_NAVIGATE,
    PERMISSIONS.PROCESS_FOOTER_SEARCH,
    PERMISSIONS.PROCESS_UPDATE_CONTENT,
  ],
};

export const SearchPermissions = {
  fn: permissionSelector.hasAnyPermissions,
  permissions: [
    PERMISSIONS.SEARCH_VIEW_ANONYMOUS,
    PERMISSIONS.SEARCH_VIEW_CONTENT,
    PERMISSIONS.SEARCH_FREETEXT,
    PERMISSIONS.SEARCH_GENERAL_FILTERS,
    PERMISSIONS.SEARCH_ADVANCED_FILTERS,
  ],
};

export const DecisionPermissions = {
  fn: permissionSelector.hasAnyPermissions,
  permissions: [
    PERMISSIONS.DECISION_VIEW_ANONYMOUS,
    PERMISSIONS.DECISION_VIEW_CONTENT,
    PERMISSIONS.DECISION_VIEW_RELATED_COUNT,
    PERMISSIONS.DECISION_VIEW_METADATA,
    PERMISSIONS.DECISION_FOOTER_LAW_RED_THREAD,
    PERMISSIONS.DECISION_FOOTER_CONTENT,
    PERMISSIONS.DECISION_FOOTER_NAVIGATE,
    PERMISSIONS.DECISION_FOOTER_SEARCH,
    PERMISSIONS.DECISION_UPDATE_CONTENT,
  ],
};

export const AdminHomePagePermissions = {
  fn: permissionSelector.hasAnyPermissions,
  permissions: [
    PERMISSIONS.USER_VIEW_NEWS_SUBSCRIPTIONS,
    PERMISSIONS.USER_CREATE_LICENSE,
    PERMISSIONS.USER_MANAGE_ROLES,
  ],
};
