import { put, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import { callAndAwaitApi } from '../../../utils/sagas';
import { getResponseData } from '../../../selectors/actions';
import * as EntityTypes from '../../../constants/EntityTypes';
import * as articleFooterActions from '../../../actions/page/articlefooter';
import * as relationTreeActions from '../../../actions/api/relationTree';
import * as relationHelper from '../../../utils/relationhelper';
import { RED_THREAD } from '../../../utils/relationhelper';
import EntityFields from '../../../constants/EntityFields';

function* doFetch(action) {
  if (!action.payload.action) return;
  const response = yield callAndAwaitApi(action.payload.action);
  if (!response.success) return;
  const entity = getResponseData(response.success);
  entity.entityType = action.payload.entityType;
  const payload = {
    [EntityTypes.LAWITEM]: {
      [EntityFields[EntityTypes.LAWITEM].CHANGED_BY]: {},
      [EntityFields[EntityTypes.LAWITEM].CHANGING]: {},
      [EntityFields[EntityTypes.LAWITEM].REPLACED_BY]: {},
      [EntityFields[EntityTypes.LAWITEM].REPLACING]: {},
      [EntityFields[EntityTypes.LAWITEM].JURISDICTION_BY]: {},
      [EntityFields[EntityTypes.LAWITEM].JURISDICTION]: {},
      [RED_THREAD]: {},
    },
    [EntityTypes.DECISION]: {
      [RED_THREAD]: {},
    },
    [EntityTypes.PROCESSSTEP]: {},
    [EntityTypes.THEORYITEM]: {},
    [EntityTypes.TAXONOMY]: {},
    [EntityTypes.LAWPREFACEITEM]: {},
  };
  switch (action.payload.entityType) {
    case EntityTypes.LAWITEM:
      relationHelper.addLawItem([entity], payload, 0);
      break;
    case EntityTypes.LAWPREFACEITEM:
      relationHelper.addLawPrefaceItem([entity], payload, 0);
      break;
    case EntityTypes.THEORYITEM:
      relationHelper.addTheoryItem([entity], payload);
      break;
    case EntityTypes.DECISION:
      relationHelper.addDecisions(entity, payload);
      break;
    case EntityTypes.PROCESSSTEP:
      relationHelper.addProcessStepData([entity], payload);
      break;
    case EntityTypes.TAXONOMY:
      relationHelper.addKeywordItem([entity], payload);
      break;
    default:
      break;
  }
  yield put(
    relationTreeActions.lawItems([
      ...Object.values(payload[EntityTypes.LAWITEM]).filter(
        (lawItem) => lawItem.id,
      ),
      ...Object.values(
        payload[EntityTypes.LAWITEM][
          EntityFields[EntityTypes.LAWITEM].CHANGED_BY
        ],
      ),
      ...Object.values(
        payload[EntityTypes.LAWITEM][
          EntityFields[EntityTypes.LAWITEM].CHANGING
        ],
      ),
      ...Object.values(
        payload[EntityTypes.LAWITEM][
          EntityFields[EntityTypes.LAWITEM].REPLACED_BY
        ],
      ),
      ...Object.values(
        payload[EntityTypes.LAWITEM][
          EntityFields[EntityTypes.LAWITEM].REPLACING
        ],
      ),
      ...Object.values(
        payload[EntityTypes.LAWITEM][
          EntityFields[EntityTypes.LAWITEM].JURISDICTION_BY
        ],
      ),
      ...Object.values(
        payload[EntityTypes.LAWITEM][
          EntityFields[EntityTypes.LAWITEM].JURISDICTION
        ],
      ),
      ...Object.values(payload[EntityTypes.LAWITEM][RED_THREAD]),
    ]),
  );
  yield put(
    relationTreeActions.lawPrefaceItems(
      Object.values(payload[EntityTypes.LAWPREFACEITEM]),
    ),
  );
  yield put(
    relationTreeActions.decisions([
      ...Object.values(payload[EntityTypes.DECISION]).filter(
        (decision) => decision.id,
      ),
      ...Object.values(payload[EntityTypes.DECISION][RED_THREAD]),
    ]),
  );
  yield put(
    relationTreeActions.processSteps(
      Object.values(payload[EntityTypes.PROCESSSTEP]),
    ),
  );
  yield put(
    relationTreeActions.theoryItems(
      Object.values(payload[EntityTypes.THEORYITEM]),
    ),
  );
  yield put(
    relationTreeActions.taxonomy(Object.values(payload[EntityTypes.TAXONOMY])),
  ); // Even though we pre-load, a fast user may need this

  // TODO - We really shouldn't be rewriting the values of 'payload' here...
  payload[EntityFields[EntityTypes.LAWITEM].CHANGING] = Object.values(
    payload[EntityTypes.LAWITEM][EntityFields[EntityTypes.LAWITEM].CHANGING],
  )
    .filter((lawItem) => lawItem.id)
    .map((lawItem) => lawItem.id);
  payload[EntityFields[EntityTypes.LAWITEM].CHANGED_BY] = Object.values(
    payload[EntityTypes.LAWITEM][EntityFields[EntityTypes.LAWITEM].CHANGED_BY],
  )
    .filter((lawItem) => lawItem.id)
    .map((lawItem) => lawItem.id);
  payload[EntityFields[EntityTypes.LAWITEM].REPLACING] = Object.values(
    payload[EntityTypes.LAWITEM][EntityFields[EntityTypes.LAWITEM].REPLACING],
  )
    .filter((lawItem) => lawItem.id)
    .map((lawItem) => lawItem.id);
  payload[EntityFields[EntityTypes.LAWITEM].REPLACED_BY] = Object.values(
    payload[EntityTypes.LAWITEM][EntityFields[EntityTypes.LAWITEM].REPLACED_BY],
  )
    .filter((lawItem) => lawItem.id)
    .map((lawItem) => lawItem.id);
  payload[EntityFields[EntityTypes.LAWITEM].JURISDICTION] = Object.values(
    payload[EntityTypes.LAWITEM][
      EntityFields[EntityTypes.LAWITEM].JURISDICTION
    ],
  )
    .filter((lawItem) => lawItem.id)
    .map((lawItem) => lawItem.id);
  payload[EntityFields[EntityTypes.LAWITEM].JURISDICTION_BY] = Object.values(
    payload[EntityTypes.LAWITEM][
      EntityFields[EntityTypes.LAWITEM].JURISDICTION_BY
    ],
  )
    .filter((lawItem) => lawItem.id)
    .map((lawItem) => lawItem.id);
  payload[RED_THREAD] = Object.values(
    payload[EntityTypes.LAWITEM][RED_THREAD],
  ).map((lawItem) => lawItem.id);
  payload[EntityTypes.LAWITEM] = Object.values(payload[EntityTypes.LAWITEM])
    .filter((lawItem) => lawItem.id)
    .map((lawItem) => lawItem.id);
  payload[EntityTypes.LAWPREFACEITEM] = Object.values(
    payload[EntityTypes.LAWPREFACEITEM],
  ).map((lawPrefaceItem) => lawPrefaceItem.id);
  payload[EntityTypes.DECISION] = Object.values(payload[EntityTypes.DECISION])
    .filter((decision) => decision.id)
    .map((decision) => decision.id); // We are not storing the red thread here. We are calculating it in the selector.
  payload[EntityTypes.PROCESSSTEP] = Object.values(
    payload[EntityTypes.PROCESSSTEP],
  ).map((processStep) => processStep.id);
  payload[EntityTypes.THEORYITEM] = Object.values(
    payload[EntityTypes.THEORYITEM],
  ).map((lawItem) => lawItem.id);
  payload[EntityTypes.TAXONOMY] = Object.values(
    payload[EntityTypes.TAXONOMY],
  ).map((taxonomy) => taxonomy.id);
  yield put(
    articleFooterActions.footerSelectData(
      entity.id,
      entity.entityType,
      payload,
    ),
  );
}

export default function* fetchRelatedData() {
  yield takeEvery(ActionTypes.PAGE_FOOTER_FETCH_DATA, doFetch);
}
