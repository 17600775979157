/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment } from 'react';
import { Col, Row } from 'react-bootstrap';
import ScrollableAnchor from 'react-scrollable-anchor';
import { FaPencilAlt } from 'react-icons/all';
import FavoriteIcon from '../atoms/FavoriteIcon';
import { fontColors, uiColors } from '../styles/styles';
import ControlledByPermission from '../../../containers/shared/ControlledByPermission';
import {
  ADMIN_UPDATE_PERMISSIONS,
  VIEW_PERMISSIONS,
  PERMISSIONS,
} from '../../../constants/Permissions';
import { refBind } from '../../../utils/ref-bind';
import ArticleEditor from '../../../containers/shared/ArticleEditor';
import * as EntityTypes from '../../../constants/EntityTypes';
import * as ItemTypes from '../../../constants/ItemTypes';
import Button from '../atoms/Button';
import ArticleNodeChildren from './ArticleNodeChildren';
import Text, { TextType } from '../atoms/Text';
import { head, isObject } from 'lodash';
import { sort } from '../../../utils/sort';
import * as htmlHelpers from '../../../utils/HtmlHelpers';
import { createLawPrefaceItemText } from '../../../utils/LawHelpers';
import { getEntityType } from '../../../utils/entities';

const style = (nodeLevel) => ({
  '& span': {
    '& h4, & p': {
      display: 'inline',
    },
  },
  '& .article-section-link': {
    color: 'inherit',
    '&:hover': {
      textDecoration: 'none',
    },
  },

  '& .article-row': {
    marginBottom: '.5em',
    '& .article-row-indicator': {
      display: 'inline-block',
      flexBasis: '8px',
      marginRight: '10px',
      flexShrink: 0,
      '& div': {
        border: '1px solid',
        borderColor: uiColors.ICON_GRAY,
        height: '8px',
        width: '8px',
        marginTop: '9px',
        borderRadius: '4px',
      },
    },
    '& .article-row-canonical-name': {
      flexBasis: '10px',
      marginRight: '10px',
      flexShrink: 0,
      '& .parent-name': {
        color: fontColors.GREY,
      },
    },
    '& .article-row-content': {
      '&.node-level': {
        paddingLeft: `${nodeLevel * 1.2}em`,
      },
      '& .grey-vertical-line': {
        borderLeft: `3px solid ${uiColors.GREY}`,
        padding: ' 5px 10px',
      },
    },
    '&.active': {
      '& .article-row-indicator div': {
        borderColor: uiColors.BRAND,
        background: uiColors.BRAND,
      },
    },
    '&:hover': {
      '& .article-row-indicator div': {
        borderColor: uiColors.BRAND,
      },
    },
    '& .text-container': {
      border: 'solid 1px transparent',
      cursor: 'pointer',
      '&:hover': {
        borderBottom: `1px solid ${uiColors.BRAND}`,
      },
    },
  },
});

class ArticleNode extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showModalEditor: false };
    refBind(
      this,
      '_handleArticleNodeClick',
      '_handleOnFavoriteClick',
      'toggleEditor',
    );
  }

  renderNode(articleNode, nodeLevel) {
    let content;
    let title;
    const entityType = getEntityType(articleNode.node);
    if (articleNode.node) {
      if (entityType === EntityTypes.LAWPREFACEITEM) {
        title = createLawPrefaceItemText(articleNode.node);
      } else if (entityType === EntityTypes.LAWITEM) {
        title = articleNode.node.title;
      }
    }
    if (
      (nodeLevel > 1 ||
        articleNode.children.length === 0 ||
        !this.hasObjectChildren(articleNode)) &&
      articleNode.node.content
    ) {
      content = htmlHelpers.newLineText(articleNode.node.content);
    } else {
      content = htmlHelpers.newLineText(
        articleNode.node.title.replace(articleNode.node.uri, '').trim(),
      );
    }

    return (
      <Col className={`article-row-content node-level node-level-${nodeLevel}`}>
        {title ? (
          <Fragment>
            <h2>{title}</h2>
            <div className="grey-vertical-line">{content}</div>
          </Fragment>
        ) : (
          <h2>{content}</h2>
        )}
      </Col>
    );
  }

  _handleArticleNodeClick(event) {
    event.stopPropagation();
    this.props.onArticleNodeClick &&
      this.props.onArticleNodeClick(this.props.articleNode);
  }

  _handleOnFavoriteClick(node) {
    this.props.onFavoriteClick && this.props.onFavoriteClick(node);
  }

  getFavorite(node, favorites) {
    return (
      favorites &&
      favorites[node.entityType] &&
      favorites[node.entityType][`${node.id}`]
    );
  }

  getClass(isActive) {
    return isActive ? ' active' : '';
  }

  toggleEditor() {
    this.setState({ showModalEditor: !this.state.showModalEditor });
  }

  getArticleNumber() {
    const { articleNode } = this.props;
    if (articleNode.node.entityType === EntityTypes.THEORYITEM) {
      if (articleNode.node.theoryItemType === ItemTypes.SECTION) {
        return <h4>{articleNode.node.sectionNumber}</h4>;
      }
      return (
        <h4>{`${articleNode.node.sectionNumber}(${articleNode.node.subSectionNumber})`}</h4>
      );
    } else if (articleNode.node.entityType === EntityTypes.LAWITEM) {
      return <h4>{articleNode.node.itemNumber}</h4>;
    }
  }

  getActiveArticle() {
    const { articleNode, currentNode } = this.props;
    return (
      currentNode &&
      articleNode &&
      articleNode.node &&
      articleNode.node.id === currentNode.node.id
    );
  }

  hasObjectChildren = (articleNode) =>
    articleNode.children && isObject(head(articleNode.children));

  render() {
    const {
      articleNode,
      currentNode,
      favorites,
      onArticleNodeClick,
      goBack,
      goNext,
      isGoNextButtonDisabled,
      isGoPreviousButtonDisabled,
      previousTitle,
      nextTitle,
      singleLevel,
      entityType,
      noNavigation,
    } = this.props;

    const nodeLevel = 0;
    const isActive = this.getActiveArticle();
    const nodeText =
      articleNode.node && this.renderNode(articleNode, nodeLevel);
    const permission = ADMIN_UPDATE_PERMISSIONS[articleNode?.node.entityType];
    const permissionView = VIEW_PERMISSIONS[articleNode?.node.entityType];
    const articleNumber = this.getArticleNumber();

    return (
      <Fragment>
        {entityType !== EntityTypes.LAWITEM && (
          <div css={style(nodeLevel)}>
            {!noNavigation && (
              <Button
                className="m-2"
                type={Button.BACK}
                disabled={isGoPreviousButtonDisabled ?? true}
                onClick={goBack}
              />
            )}
            <Text type={TextType.HEADER_5}>{previousTitle}</Text>
            {articleNode.node && (
              <ScrollableAnchor id={`${articleNode.node.id}`}>
                <Row className={'article-row root' + this.getClass(isActive)}>
                  <Col xs={2}>
                    <div
                      onClick={this._handleArticleNodeClick}
                      className="article-section-link"
                    >
                      <div className="article-row-indicator">
                        <div className="d-inline-block" />
                        {articleNumber && (
                          <span className="d-inline ml-2">{articleNumber}</span>
                        )}
                      </div>
                    </div>
                    <div className="article-row-canonical-name">
                      <span className="parent-name" />
                    </div>
                  </Col>
                  <Col xs={9}>{nodeText}</Col>
                  <Col xs={1} className="p-0">
                    <ControlledByPermission
                      permission={permission}
                      render={
                        <div
                          className="show-on-hover-container pr-2 d-inline-block"
                          onClick={() => this.toggleEditor()}
                        >
                          <span className="change-color-on-hover-to-brand-color color-gray text-container edit-icon">
                            <FaPencilAlt />
                          </span>
                        </div>
                      }
                    />
                    <ControlledByPermission
                      permission={PERMISSIONS.FAVORITE}
                      render={
                        <div className="favorite-icon d-inline-block">
                          <FavoriteIcon
                            favorite={this.getFavorite(
                              articleNode.node,
                              favorites,
                            )}
                            onFavoriteClick={() =>
                              this._handleOnFavoriteClick(articleNode.node)
                            }
                          />
                        </div>
                      }
                    />
                  </Col>
                </Row>
              </ScrollableAnchor>
            )}
          </div>
        )}
        {articleNode && articleNode.node && (
          <ArticleEditor
            show={this.state.showModalEditor}
            title={'article-editor.edit-section'}
            article={articleNode.node}
            enableRemove={
              articleNode.node?.entityType === EntityTypes.THEORYITEM
            }
            cancelClickHandler={this.toggleEditor}
          ></ArticleEditor>
        )}
        {this.hasObjectChildren(articleNode) &&
          !singleLevel &&
          sort(
            Object.values(articleNode.children),
            entityType || articleNode.node?.entityType,
          ).map((value, key) => {
            const myKey =
              'article-branch-ArticleNode-' +
              (entityType || articleNode.node?.entityType) +
              '-' +
              articleNode.node?.id +
              '-' +
              value.node?.id +
              '-' +
              (nodeLevel + 1) +
              '-' +
              key;
            return (
              <ArticleNodeChildren
                key={myKey}
                articleNode={value}
                nodeLevel={nodeLevel + 1}
                viewPermission={this.props.viewPermission}
                onArticleNodeClick={onArticleNodeClick}
                entityType={entityType}
                currentNode={currentNode}
                handleOnFavoriteClick={this._handleOnFavoriteClick}
                permissionView={permissionView}
                favorites={favorites}
                getFavorite={this.getFavorite}
              />
            );
          })}
        {entityType === EntityTypes.LAWITEM && (
          <ArticleNodeChildren
            singleLevel={true}
            key={'article-branch-' + articleNode.node.id}
            articleNode={articleNode}
            nodeLevel={nodeLevel + 1}
            viewPermission={this.props.viewPermission}
            onArticleNodeClick={onArticleNodeClick}
            entityType={entityType}
            currentNode={currentNode}
            handleOnFavoriteClick={this._handleOnFavoriteClick}
            permissionView={permissionView}
            favorites={favorites}
            getFavorite={this.getFavorite}
          />
        )}
        <Text type={TextType.HEADER_5}>{nextTitle}</Text>
        {!noNavigation && (
          <Button
            className="mx-2"
            type={Button.NEXT}
            disabled={isGoNextButtonDisabled ?? true}
            onClick={goNext}
          />
        )}
      </Fragment>
    );
  }
}

export default ArticleNode;
