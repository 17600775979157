import BaseConnector from './Base';
import * as EntityTypes from '../constants/EntityTypes';

export default class PermissionConnector extends BaseConnector {
  constructor(...args) {
    super(...args);
    this.entityType = EntityTypes.PERMISSION;
  }

  getAll() {
    return this.authGet(this.query().build());
  }
}
