/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import PropTypes from 'prop-types';
import wavesImagePath from '../../../images/wave.svg';
import { fontColors, fontSize, uiColors } from '../styles/styles';
import linklogo from '../../../images/linklogo.png';
import { Col, Row } from 'react-bootstrap';
import ContentOuterBound from './ContentOuterBound';
import { Trans } from 'react-i18next';

const styles = {
  marginTop: '170px',
  height: '370px',
  background: 'url(' + wavesImagePath + ') ' + uiColors.BLUE_MAGENTA,
  backgroundSize: 'cover',
  color: fontColors.WHITE,
  paddingLeft: '2rem',
  paddingRight: '2rem',
  '@media (max-width: 576px)': {
    paddingLeft: '1rem',
    paddingRight: '1rem',
  },
  '& .description': {
    maxWidth: '35rem',
  },
  '& .copyright': {
    color: fontColors.LIGHT,
    fontSize: fontSize.PARAGRAPH_FOOTER,
  },
  '& .link-wrapper': {
    display: 'grid',
    gridTemplateColumns: 'repeat(5, auto)',
    gridGap: '1.5rem',
    a: {
      color: fontColors.WHITE,
      textDecoration: 'none',
    },
    'a:first-letter': {
      textTransform: 'capitalize',
    },
  },
};

class PageFooter extends React.Component {
  static propTypes = {
    cssOverrides: PropTypes.object,
  };

  static defaultProps = {
    cssOverrides: {},
  };

  render() {
    const { cssOverrides, otherProps } = this.props;
    return (
      <div {...otherProps} css={[styles, cssOverrides]}>
        <ContentOuterBound>
          <Row className="py-5">
            <Col sm={6}>
              <img
                src={linklogo}
                alt="logo"
                className="d-inline-block align-top"
              />{' '}
              ProcurementLink
            </Col>
          </Row>
          <Row className="pb-5">
            <Col sm={6}>
              <div className="description">
                <Trans i18nKey="footer.description" />
              </div>
            </Col>
          </Row>
          <Row className="pt-3">
            <Col sm={6}>
              <div className="copyright">
                &copy;{' '}
                <span className="ml-2">
                  <Trans i18nKey="footer.copyright" />
                </span>
              </div>
            </Col>
          </Row>
        </ContentOuterBound>
      </div>
    );
  }
}

export default PageFooter;
