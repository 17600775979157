/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import HorizontalBar from './HorizontalBar';
import { uiColors } from '../common/styles/styles';

const styles = (rowWidth) => ({
  display: 'flex',
  flexDirection: 'row',

  '.chart-row-text': {
    margin: 'auto 0',
    flexGrow: 1,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  '.article-row-indicator': {
    flexBasis: '8px',
    margin: 'auto 10px auto 0',
    flexShrink: 0,
    '& div': {
      border: '1px solid',
      borderColor: uiColors.ICON_GRAY,
      height: '8px',
      width: '8px',
      borderRadius: '4px',
    },
  },
  '.article-row-expander': {
    width: '10px',
    height: '10px',
    display: 'inline-block',
    margin: 'auto 10px',
    borderTop: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderBottom: '5px solid transparent',
    borderLeft: '5px solid black',
    transition: '0.5s',
    position: 'absolute',
    '&.row-expanded': {
      transition: '0.5s',
      transform: 'rotate(90deg)',
    },
  },
  '.dummy-expander': {
    marginLeft: '30px',
  },
  '.level-1': {
    fontSize: '15px',
    fontWeight: 'bold',
  },
  '.level-2, .level-3, .level-4, .level-5': {
    fontSize: '12px',
  },
  '.header-level-2': {
    paddingLeft: '10px',
  },
  '.header-level-3': {
    paddingLeft: '20px',
  },
  '.header-level-4': {
    paddingLeft: '30px',
  },
  '.title-row': {
    display: 'flex',
    flexDirection: 'row',
  },
  '.row-height': {
    height: '50px',
  },
  '.row-border': {
    borderBottom: `1.5px solid ${uiColors.GREY}`,
  },
  '.process-button': {
    minWidth: `${rowWidth}px`,
  },
  '.gant-first-column': {
    borderRight: `1.5px solid ${uiColors.GREY}`,
  },
});

class GanttChartRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: !this.hasSelectedChildren(
        this.props.data,
        this.props.selectedItem,
      ),
    };
  }

  toggle = () => this.setState({ collapsed: !this.state.collapsed });

  componentDidUpdate(prevProps, prevState, snapshot) {
    const prevSelectedUri =
      prevProps.selectedItem && prevProps.selectedItem.node.uri;
    const currentSelectedUri =
      this.props.selectedItem && this.props.selectedItem.node.uri;
    if (prevSelectedUri !== currentSelectedUri && currentSelectedUri) {
      const expanded = this.hasSelectedChildren(
        this.props.data,
        this.props.selectedItem,
      );
      expanded && this.setState({ collapsed: false });
    }
  }

  getLevel(currentLevel = 1) {
    // allow maximum of 4 levels
    return currentLevel > 4 ? 4 : currentLevel;
  }

  getParentClassName(expansionState) {
    return expansionState
      ? 'article-row-expander'
      : 'article-row-expander row-expanded';
  }

  hasSelectedChildren(branch, target) {
    return (
      target &&
      (branch.node.uri === target.node.uri ||
        (branch.children || []).some((child) =>
          this.hasSelectedChildren(child, target),
        ))
    );
  }

  render() {
    const level = this.getLevel(this.props.level);
    const {
      data,
      itemWidth,
      totalDays,
      onSelectNode,
      timeScale,
      selectedItem,
    } = this.props;
    const { collapsed } = this.state;
    const totalRoundDays =
      totalDays % timeScale !== 0
        ? totalDays + (timeScale - (totalDays % timeScale))
        : totalDays;
    const rowWidth = (totalRoundDays * itemWidth) / timeScale;
    return (
      <div key={`key-${data.id}`} className={`row-${data.id}`}>
        <div css={styles(rowWidth)}>
          <div className="row-height row-border title-row d-flex align-items-center gant-first-column">
            {(data.children || []).length ? (
              <div
                className={this.getParentClassName(collapsed)}
                onClick={this.toggle}
              />
            ) : null}
            <div
              className={
                'dummy-expander article-row-indicator header-level-' + level
              }
            >
              <div />
            </div>
            <div
              className={'chart-row-text level-' + level}
              data-tip={data.text}
            >
              {data.node.sectionNumber}: {data.text}
            </div>
            <ReactTooltip />
          </div>
          <div className="row-height row-border d-flex align-items-center process-button">
            <HorizontalBar
              onClick={onSelectNode}
              data={data}
              itemWidth={itemWidth}
              timeScale={timeScale}
              level={level}
              selectedItem={selectedItem}
            />
          </div>
        </div>
        {!collapsed &&
          (data.children || []).map((processStep, index) => {
            return (
              <GanttChartRow
                onSelectNode={onSelectNode}
                data={processStep}
                itemWidth={itemWidth}
                key={index}
                selectedItem={selectedItem}
                level={level + 1}
                totalDays={totalDays}
                timeScale={timeScale}
              />
            );
          })}
      </div>
    );
  }
}

export default GanttChartRow;
