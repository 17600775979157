const parse = require('url-parse');

export const FILTER_DOG_TAG = 'Filter:';

export class Filter {
  constructor(url, queryParams = {}, serializedFilter = '') {
    this.url = url;
    this.queryParams = queryParams;
    this.serializedFilter = serializedFilter;
    this.generateFilter(url);
  }

  generateFilter(url) {
    let urlObject = parse(url);
    let { query } = urlObject;
    const urlParams = new URLSearchParams(query);
    this.queryParams = Object.fromEntries(urlParams);
    this.serializeFilter();
    return this;
  }

  deserializeFilter() {
    let toJSON = this.serializedFilter.replace(FILTER_DOG_TAG, '');
    this.queryParams = JSON.parse(toJSON);

    return this.queryParams;
  }

  serializeFilter() {
    let params = this.queryParams;
    params = this.trimQueryParams(params);
    this.serializedFilter = FILTER_DOG_TAG.concat(JSON.stringify(params));

    return this.serializedFilter;
  }

  // Removing query parameters not needed as part of filter key
  trimQueryParams(params) {
    let filteredParams = new Object({ ...params });

    // TODO: consider how to avoid filter these out.
    //  Right now though, we cant use the entire filter as lookup string in store,
    //   as out selector would have to know about going from only courtIds, to including some values of page, size and projection as well.
    delete filteredParams.size;
    delete filteredParams.page;
    delete filteredParams.projection;

    return filteredParams;
  }

  equals(filterAsObjectOrSerialized) {
    if (typeof filterAsObjectOrSerialized === 'object') {
      return this.compareObjects(filterAsObjectOrSerialized);
    }
    return this.compareStrings(filterAsObjectOrSerialized);
  }

  compareObjects(filterObject) {
    return this.queryParams === filterObject;
  }

  compareStrings(filterString) {
    return this.serializedFilter === filterString;
  }

  //TODO: add methods to change filter (would probably be the same as QueryBuilder?)
}

export const createTagName = (...param) =>
  param.map((p) => p.split(' ').join('')).join('AND');
