import { take, select, call, put, race } from 'redux-saga/effects';
import { takeLatestWhenAppReady } from '../../utils/sagas';
import * as ActionTypes from '../../constants/ActionTypes';
import * as LoginTypes from '../../constants/LoginTypes';
import * as authActions from '../../actions/auth';
import * as appActions from '../../actions/app';
import * as LocalStorageKeys from '../../constants/LocalStorageKeys';
import * as authSelectors from '../../selectors/store/auth';
import * as actionSelectors from '../../selectors/actions';
import jwtDecode from 'jwt-decode';

function* doDeveloperLogin(action) {
  const { username } = action.payload;
  window.localStorage.setItem(
    LocalStorageKeys.LOGIN_DEVELOPER_USERNAME,
    username,
  );
  yield put(authActions.loginVerified(username, action.payload.loginType));
}

function* doJwtLogin(action) {
  yield put(
    authActions.initJwtLogin(action.payload.username, action.payload.password),
  );
  let result = yield race({
    success: take(ActionTypes.AUTH_JWT_CALL_SUCCESS),
    error: take(ActionTypes.AUTH_JWT_CALL_ERROR),
  });

  if (result.success) {
    let response = actionSelectors.getResponseData(result.success);
    let jwt = response.jwt;
    let jwtObj = jwtDecode(jwt);
    window.localStorage.setItem(
      LocalStorageKeys.LOGIN_JWT_TOKEN_EXPIRATION,
      jwtObj.exp,
    );
    yield put(
      authActions.loginVerified(
        action.payload.username,
        action.payload.loginType,
        jwt,
      ),
    );
  } else {
    yield put(
      authActions.loginFailed(
        result.error.payload.error ||
          JSON.stringify(result.error.payload.responseData, null, 1),
      ),
    );
  }
}

function* completeLogout() {
  yield put(authActions.logout());
  yield take(ActionTypes.AUTH_LOGOUT_COMPLETE);
}

function* doLogin(action) {
  // check if we are already logged in with another method and if so, log out with that method before logging in another way
  let isLoggedIn = yield select(authSelectors.isLoggedIn);
  if (isLoggedIn) {
    yield call(completeLogout);
  }

  // do the new login
  let logins = {
    [LoginTypes.DEVELOPER_LOGIN]: doDeveloperLogin,
    [LoginTypes.JWT_LOGIN]: doJwtLogin,
  };

  if (action.payload.loginType in logins) {
    yield call(logins[action.payload.loginType], action);
  } else {
    console.warn('Unknown login type: ' + action.payload.loginType);
    console.trace();
  }

  isLoggedIn = yield select(authSelectors.isLoggedIn);
  if (isLoggedIn) {
    yield put(appActions.relocateToFrontPage());
  }
  yield put(authActions.loginComplete());
  yield put(authActions.authCompleted()); // TODO: Small hack to make it work for tomorrow
}

export default function* login() {
  yield takeLatestWhenAppReady(ActionTypes.AUTH_LOGIN_INIT, doLogin);
}
