import { call, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import { generateApiSaga } from '../../../utils/sagas';
import DecisionConnector from '../../../connectors/Decision';
import * as Projections from '../../../constants/Projections';

const doGetDecisionById = generateApiSaga(
  DecisionConnector,
  function* (connector, action) {
    let id = action.payload.id;
    return yield call([connector, connector.getById], id, Projections.WITHID);
  },
);

export default function* getById() {
  yield takeEvery(ActionTypes.API_DECISION_GET_BY_ID_INIT, doGetDecisionById);
}
