import { call, select, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import Decision from '../../../connectors/Decision';
import { generateApiSaga } from '../../../utils/sagas';
import * as decisionApiSelector from '../../../selectors/api/decision';
import { createTagName } from '../../../utils/filter';

const search = generateApiSaga(Decision, function* (connector, action) {
  const { editorNameOrCaseName } = action.payload;
  const decisionData = yield select(decisionApiSelector.getSearchData);
  if (!decisionData.get(createTagName(editorNameOrCaseName))) {
    return yield call(
      [connector, connector.searchByEditorNameOrCaseName],
      editorNameOrCaseName,
    );
  }
});

export default function* searchByEditorNameOrByCaseName() {
  // It must be 'takeEvery' because 'liveApiRequest' take care of number of request to show/ hide the loading icon. and each request will add one to the counter and reduce one when the request return error or success.
  yield takeEvery(
    ActionTypes.API_DECISION_SEARCH_BY_EDITOR_NAME_OR_CASE_NAME_INIT,
    search,
  );
}
