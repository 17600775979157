import * as ActionTypes from '../../constants/ActionTypes';
import * as authActions from '../../actions/auth';
import * as authSelectors from '../../selectors/store/auth';
import { put, select, takeLatest } from 'redux-saga/effects';
import jwtDecode from 'jwt-decode';
import { callAndAwaitApi } from '../../utils/sagas';
import * as JwtConfig from '../../constants/Jwt';
import * as LoginTypes from '../../constants/LoginTypes';

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

function* doHandleJwtTimeout(action) {
  if (
    action.type === ActionTypes.AUTH_LOGIN_VERIFIED &&
    action.payload.loginType === LoginTypes.DEVELOPER_LOGIN
  ) {
    return;
  }

  const tokenToExpire = action.payload.bearerToken;
  const currentTime = new Date();
  const tokenExpiration = new Date(jwtDecode(tokenToExpire).exp * 1000);
  const expirationInterval = tokenExpiration.getTime() - currentTime.getTime();

  const timeout =
    expirationInterval > JwtConfig.JWT_EXPIRATION_OFFSET
      ? expirationInterval - JwtConfig.JWT_EXPIRATION_OFFSET
      : expirationInterval;
  yield delay(timeout);

  const tokenFromState = yield select(authSelectors.bearerToken);
  if (tokenFromState === tokenToExpire) {
    let response = yield callAndAwaitApi(authActions.refreshJwtLogin());
    if (response.success) {
      let jwt = response.success.payload.responseData.jwt;
      yield put(authActions.loginRefreshed(jwt));
    }
  }
}

export default function* handleJwtTimeout() {
  yield takeLatest(
    [ActionTypes.AUTH_LOGIN_REFRESHED, ActionTypes.AUTH_LOGIN_VERIFIED],
    doHandleJwtTimeout,
  );
}
