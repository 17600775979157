import { call, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import { generateApiSaga } from '../../../utils/sagas';
import FavoriteConnector from '../../../connectors/Favorite';

const doFetch = generateApiSaga(
  FavoriteConnector,
  function* (connector, action) {
    const types = action.payload.types;
    if (types && types.length) {
      return yield call([connector, connector.getByTypes], types);
    }
    return yield call([connector, connector.getAll]);
  },
);

export default function* getFavorite() {
  yield takeEvery(ActionTypes.API_GET_FAVORITES_INIT, doFetch);
}
