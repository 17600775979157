import { call, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import TheoryItemConnector from '../../../connectors/TheoryItem';
import { generateApiSaga } from '../../../utils/sagas';

const doGetAllTheoryItemsBySectionAndType = generateApiSaga(
  TheoryItemConnector,
  function* (connector, action) {
    return yield call(
      [connector, connector.getAllBySectionNumberAndType],
      action.payload.sectionNumber,
      action.payload.theoryItemType,
    );
  },
);

export default function* getAllBySectionAndType() {
  yield takeEvery(
    ActionTypes.API_THEORY_ITEM_GET_BY_SECTIONNUMBER_AND_TYPE_INIT,
    doGetAllTheoryItemsBySectionAndType,
  );
}
