/** @jsx jsx */
import React, { Component } from 'react';
import { ListGroup } from 'react-bootstrap';
import ProductPresentationList from './ProductPresentationList';
import { jsx } from '@emotion/core';
import VimeoModal from './VimeoModal';

/**
 * Capabilities are aligned across cards to make comparisons of licenses easier
 * This alignment happens by setting the same height on CapabilityGroups across LicenseCards
 */
class CapabilityGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }
  render() {
    const handleClose = () => this.setState({ show: false });
    const handleOpen = () => this.setState({ show: true });

    const { capability, height } = this.props;

    return (
      <>
        <VimeoModal
          id={capability.videoTutorialId}
          isOpen={this.state.show}
          closeModal={handleClose}
          size="lg"
        />
        {/* temp. disable of onclick until video ids are corrected in translation.json 
        <ListGroup.Item as="li" onClick={handleOpen}>  */}
        <ListGroup.Item as="li">
          <div
            className={capability?.text ? 'capability' : null}
            style={{ height: height }}
          >
            <ProductPresentationList input={capability} />
          </div>
        </ListGroup.Item>
      </>
    );
  }
}

export default CapabilityGroup;
