import { put, select } from 'redux-saga/effects';
import { head } from 'lodash';
import { push, replace } from 'connected-react-router';
import * as ActionTypes from '../../../constants/ActionTypes';
import * as lawPageSelectors from '../../../selectors/page/law';
import * as lawPageActions from '../../../actions/page/law';
import {
  callAndAwaitApi,
  getAndAwaitAllPages,
  takeEveryWhenPreloadReady,
} from '../../../utils/sagas';
import * as appActions from '../../../actions/app';
import * as lawItemApiActions from '../../../actions/api/lawitem';
import * as favoriteApiActions from '../../../actions/api/favorite';
import * as favoriteApiSelector from '../../../selectors/api/favorite';
import { LawPageEntities, PageEntities } from '../../../constants/PageEntities';
import { buildPageLink } from '../../../utils/link';
import * as urls from '../../../constants/Urls';
import * as lawItemApiSelectors from '../../../selectors/api/lawitem';
import * as EntityType from '../../../constants/EntityTypes';
import { sort } from '../../../utils/sort';
import * as lawPrefaceItemApiActions from '../../../actions/api/lawprefaceitem';
import * as lawgroupApiActions from '../../../actions/api/lawgroup';
import { ARTICLE_BETRAGTNING } from '../../../constants/LawItemType';

function* doLawPageLoad(action) {
  const [uri, id] = action.payload.uri;
  if (!uri || uri === 'all') {
    if (id) {
      yield navigateToLaw(id);
    }

    yield put(lawgroupApiActions.getAll());
    return;
  }

  const lawItemsFavorites = yield select(
    favoriteApiSelector.getPageFavorites,
    PageEntities.LAW_PAGE[LawPageEntities.LAWITEM],
  );
  const lawPrefaceItemsFavorites = yield select(
    favoriteApiSelector.getPageFavorites,
    PageEntities.LAW_PAGE[LawPageEntities.LAWPREFACEITEM],
  );

  if (
    !(lawItemsFavorites && lawItemsFavorites.size) &&
    !(lawPrefaceItemsFavorites && lawPrefaceItemsFavorites.size)
  ) {
    yield put(favoriteApiActions.getFavoritesByType(PageEntities.LAW_PAGE));
  } else {
    if (!(lawItemsFavorites && lawItemsFavorites.size)) {
      yield put(
        favoriteApiActions.getFavoritesByType(
          PageEntities.LAW_PAGE[LawPageEntities.LAWITEM],
        ),
      );
    }
    if (!(lawPrefaceItemsFavorites && lawPrefaceItemsFavorites.size)) {
      yield put(
        favoriteApiActions.getFavoritesByType(
          PageEntities.LAW_PAGE[LawPageEntities.LAWPREFACEITEM],
        ),
      );
    }
  }
  const selectedLaw = yield select(lawPageSelectors.lookupLawByUri, uri);

  if (!selectedLaw) {
    yield put(appActions.navigateTo404());
    return;
  }

  // get top level if no law items are loaded
  const lawId = selectedLaw.getIn(['node', 'id']);
  let isLoaded = yield select(
    lawItemApiSelectors.isNavigationLoadedForLaw,
    lawId,
  );
  if (!isLoaded) {
    yield callAndAwaitApi(
      lawItemApiActions.getTopLevel(selectedLaw.get('node')),
    );
    yield getAndAwaitAllPages(
      lawPrefaceItemApiActions.getAllByLawTxn(selectedLaw.get('node')),
    );
  }

  const lawItemOrLawPrefaceItemNode =
    (yield select(lawPageSelectors.lookupLawItemByUri, id)) ??
    (yield select(lawPageSelectors.lookupLawPrefaceItemByUri, id));
  if (!lawItemOrLawPrefaceItemNode && id) {
    yield callAndAwaitApi(lawItemApiActions.getParent([id]));
    yield callAndAwaitApi(lawPrefaceItemApiActions.getByUri([id]));
  }

  let lawItemsTree = (yield select(
    lawPageSelectors.getLawItemsAndLawPrefaceItemsNavigationTreeForCurrentParent,
  )).toJS();
  lawItemsTree = lawItemsTree && (yield sort(lawItemsTree, EntityType.LAWITEM));
  if (yield navigateToFirstLawItem(selectedLaw, uri, id, lawItemsTree)) {
    return;
  }

  yield put(lawPageActions.pageLoaded());
}

function* navigateToLaw(id) {
  let node =
    (yield select(lawPageSelectors.lookupLawItemByUri, id)) ||
    (yield select(lawPageSelectors.lookupLawPrefaceItemByUri, id)) ||
    (yield fetchLawItemById(id)) ||
    (yield fetchLawPrefaceItemById(id));
  if (node) {
    const selectedLaw = yield select(
      lawPageSelectors.getLawById,
      node.getIn(['node', 'lawId']),
    );
    if (selectedLaw) {
      yield put(
        push(
          buildPageLink(
            urls.LAW_ROUTE,
            selectedLaw.getIn(['node', 'uri']),
            node.getIn(['node', 'uri']),
          ),
        ),
      );
    } else {
      yield put(appActions.navigateTo404());
    }
  }
}

function* fetchLawItemById(id) {
  yield callAndAwaitApi(lawItemApiActions.getByUri([id]));
  return yield select(lawPageSelectors.lookupLawItemByUri, id);
}

function* fetchLawPrefaceItemById(id) {
  yield callAndAwaitApi(lawPrefaceItemApiActions.getByUri([id]));
  return yield select(lawPageSelectors.lookupLawPrefaceItemByUri, id);
}

function* navigateToFirstLawItem(
  selectedLaw,
  lawUri,
  lawItemUri,
  lawItemsTree,
) {
  if (lawItemsTree.length > 0 && selectedLaw && !lawItemUri) {
    while (head(lawItemsTree).node?.lawItemType === ARTICLE_BETRAGTNING) {
      lawItemsTree.shift();
    }
    yield put(
      replace(
        buildPageLink(urls.LAW_ROUTE, lawUri, head(lawItemsTree).node?.uri),
      ),
    );
    return true;
  }
  return false;
}

export default function* getAll() {
  yield takeEveryWhenPreloadReady(ActionTypes.APP_PAGE_LAW_LOAD, doLawPageLoad);
}
