import BaseConnector from './Base';
import * as EntityTypes from '../constants/EntityTypes';
import * as Searches from '../constants/Searches';
import * as Projections from '../constants/Projections';

export default class FavoriteConnector extends BaseConnector {
  constructor(...args) {
    super(...args);
    this.entityType = EntityTypes.FAVORITE;
  }

  getAll() {
    return this.authGet(this.query().withProjection(Projections.NAME).build());
  }

  getAllExpanded() {
    return this.authGet(
      this.query().withProjection(Projections.EXPAND).build(),
    );
  }

  getByTypes(types) {
    return this.authGet(
      this.query().withSearch(Searches.findFavoritesByTypes, [types]).build(),
    );
  }

  createFavorite(favorite) {
    return this.authPost(this.query().build(), favorite);
  }

  updateFavorite(favorite) {
    return this.authPut(this.query().withId(favorite.id).build(), favorite);
  }
}
