/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import PropTypes from 'prop-types';

const styles = {
  maxWidth: '1280px',
  marginLeft: 'auto',
  marginRight: 'auto',
};

class ContentOuterBound extends React.Component {
  static propTypes = {
    cssOverrides: PropTypes.object,
  };

  static defaultProps = {
    cssOverrides: {},
  };

  render() {
    const { cssOverrides, otherProps } = this.props;
    return (
      <div {...otherProps} css={[styles, cssOverrides]} className="scroll-bar">
        {this.props.children}
      </div>
    );
  }
}

export default ContentOuterBound;
