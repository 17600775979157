/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import PropTypes from 'prop-types';
import { fontColors, fontFamily, uiColors, boxShadows } from '../styles/styles';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const styles = {
  outline: 'none',
  fontFamily: fontFamily.FORM_INPUT,
  '&.link': {
    background: 'none!important',
    border: 'none',
    padding: '0!important',
    textDecoration: 'underline',
    cursor: 'pointer',
    color: 'blue',
    '&:hover': {
      color: uiColors.HOVER_LINK,
      boxShadow: 'none',
    },
  },
  '&.justify-self-baseline': {
    justifySelf: 'baseline',
  },
  '&.back, &.next': {
    backgroundColor: uiColors.WHITE,
    border: '1px solid ' + uiColors.GREY,
    borderRadius: '100%',
    boxShadow: boxShadows.DEFAULT_OR_DISABLED_SMALL,
    width: '32px',
    height: '32px',
    lineHeight: '30px',
    textAlign: 'center',
    fontSize: '11px',
    '&:hover': {
      border: '1px solid ' + uiColors.DARK,
      boxShadow: boxShadows.HOVER_DEFAULT,
    },
    '&:active': {
      border: '4px solid ' + uiColors.BRAND_LIGHT,
      lineHeight: '24px',
      fontSize: '10px',
    },
    '&:disabled': {
      border: '1px solid ' + uiColors.GREY,
      boxShadow: boxShadows.DEFAULT_OR_DISABLED_SMALL,
    },
  },
  '&.primary-large, &.primary-small': {
    backgroundColor: uiColors.BRAND,
    border: '1px solid ' + uiColors.BRAND,
    borderRadius: '3px',
    boxShadow: boxShadows.DEFAULT_OR_DISABLED,
    color: fontColors.WHITE,
    '&:hover': {
      opacity: '0.8',
      boxShadow: boxShadows.HOVER_DEFAULT,
    },
    '&:active': {
      border: '4px solid ' + uiColors.BRAND_LIGHT,
      opacity: '1',
      fontSize: '13px',
    },
    '&:disabled': {
      border: '1px solid ' + uiColors.BRAND,
      boxShadow: boxShadows.DEFAULT_OR_DISABLED,
    },
  },
  '&.primary-large': {
    width: '260px',
    height: '48px',
    fontSize: '14px',
    lineHeight: '22px',
  },
  '&.primary-small': {
    width: '130px',
    height: '24px',
    fontSize: '14px',
    lineHeight: '15px',
  },
  '&.primary-brand': {
    backgroundColor: uiColors.BRAND,
    border: '1px solid ' + uiColors.BRAND,
    borderRadius: '3px',
    boxShadow: boxShadows.DEFAULT_OR_DISABLED,
    width: '260px',
    height: '38px',
    fontSize: '12px',
    lineHeight: '22px',
    color: fontColors.WHITE,
    '&:hover': {
      opacity: '0.8',
      boxShadow: boxShadows.HOVER_DEFAULT,
    },
    '&:active': {
      border: '4px solid ' + uiColors.BRAND_LIGHT,
      opacity: '1',
      fontSize: '11px',
    },
    '&:disabled': {
      border: '1px solid ' + uiColors.BRAND,
      boxShadow: boxShadows.DEFAULT_OR_DISABLED,
    },
  },
  '&.primary-dark': {
    backgroundColor: uiColors.DARK,
    border: '1px solid ' + uiColors.DARK,
    borderRadius: '3px',
    boxShadow: '0 2px 3px 0 ' + uiColors.TRANSPARENT_BLUEISH_GREY,
    width: '260px',
    height: '38px',
    fontSize: '12px',
    lineHeight: '22px',
    color: fontColors.WHITE,
    '&:hover': {
      opacity: '0.8',
      boxShadow: boxShadows.HOVER_DEFAULT,
    },
    '&:active': {
      border: '4px solid ' + uiColors.TRANSPARENT_BLUEISH_GREY,
      opacity: '1',
      fontSize: '11px',
    },
    '&:disabled': {
      border: '1px solid ' + uiColors.DARK,
      boxShadow: '0 2px 3px 0 ' + uiColors.TRANSPARENT_BLUEISH_GREY,
    },
  },
  '&.primary-white': {
    backgroundColor: uiColors.WHITE,
    border: '1px solid ' + uiColors.GREY,
    borderRadius: '3px',
    boxShadow: boxShadows.DEFAULT_OR_DISABLED_LIGHT,
    width: '260px',
    height: '38px',
    fontSize: '12px',
    lineHeight: '22px',
    color: fontColors.MAIN,
    '&:hover': {
      opacity: '0.8',
      boxShadow: boxShadows.HOVER_DEFAULT,
    },
    '&:active': {
      border: '4px solid rgba(224, 228, 234, 0.3)',
      opacity: '1',
      fontSize: '11px',
    },
    '&:disabled': {
      border: '1px solid ' + uiColors.GREY,
      boxShadow: boxShadows.DEFAULT_OR_DISABLED_LIGHT,
    },
  },
  '&.form-submit': {
    width: '260px',
    height: '48px',
    fontSize: '14px',
    lineHeight: '22px',
    backgroundColor: uiColors.BRAND,
    border: '1px solid ' + uiColors.BRAND,
    borderRadius: '3px',
    boxShadow: boxShadows.DEFAULT_OR_DISABLED,
    color: fontColors.WHITE,
    '&:hover': {
      opacity: '0.8',
      boxShadow: boxShadows.HOVER_DEFAULT,
    },
    '&:active': {
      border: '4px solid ' + uiColors.BRAND_LIGHT,
      opacity: '1',
      fontSize: '13px',
    },
    '&:disabled': {
      border: '1px solid ' + uiColors.BRAND,
      boxShadow: boxShadows.DEFAULT_OR_DISABLED,
    },
  },
  '&.bottom': {
    backgroundColor: '#B0AFBB',
    border: '1px solid #B0AFBB',
    borderRadius: '3px',
    boxShadow: boxShadows.DEFAULT_OR_DISABLED_SMALL,
    width: '180px',
    height: '46px',
    fontSize: '12px',
    color: fontColors.WHITE,
    '&:hover': {
      opacity: '0.8',
      boxShadow: boxShadows.HOVER_DEFAULT,
    },
    '&:active': {
      border: '4px solid #B0AFBB',
      opacity: '1',
      fontSize: '11px',
    },
    '&:disabled': {
      border: '1px solid ' + uiColors.GREY,
      boxShadow: boxShadows.DEFAULT_OR_DISABLED_SMALL,
    },
  },
  '&.orange': {
    backgroundColor: uiColors.BRAND,
    border: '1px solid ' + uiColors.BRAND,
    borderRadius: '3px',
    boxShadow: boxShadows.DEFAULT_OR_DISABLED,
    width: '180px',
    height: '46px',
    fontSize: '12px',
    lineHeight: '22px',
    color: fontColors.WHITE,
    '&:hover': {
      opacity: '0.8',
      boxShadow: boxShadows.HOVER_DEFAULT,
    },
    '&:active': {
      border: '4px solid ' + uiColors.BRAND_LIGHT,
      opacity: '1',
      fontSize: '11px',
    },
    '&:disabled': {
      border: '1px solid ' + uiColors.BRAND,
      boxShadow: boxShadows.DEFAULT_OR_DISABLED,
    },
  },
  '&.filter': {
    backgroundColor: uiColors.WHITE,
    border: '1px solid ' + uiColors.GREY,
    boxShadow: boxShadows.DEFAULT_OR_DISABLED_LIGHT,
    inset: '0 1px 2px 0 rgba(102, 113, 123, 0.21)',
    borderRadius: '3px',
    width: '102px',
    height: '34px',
    fontSize: '12px',
    lineHeight: '22px',
    '&:hover': {
      border: '1px solid ' + uiColors.DARK,
      boxShadow: boxShadows.HOVER_DEFAULT,
    },
    '&:focus': {
      border: '4px solid ' + uiColors.BRAND_LIGHT,
    },
    '&:disabled': {
      border: '1px solid ' + uiColors.GREY,
      boxShadow: boxShadows.DEFAULT_OR_DISABLED_LIGHT,
    },
  },
  '&.showMore': {
    backgroundColor: uiColors.WHITE,
    border: '1px solid ' + uiColors.BRAND,
    borderRadius: '3px',
    boxShadow: boxShadows.DEFAULT_OR_DISABLED,
    width: '120px',
    height: '38px',
    fontSize: '12px',
    lineHeight: '22px',
    color: uiColors.BRAND,
    '&:hover': {
      border: '1.5px solid ' + uiColors.BRAND,
      boxShadow: boxShadows.HOVER_DEFAULT,
    },

    '&:active': {
      border: '4px solid ' + uiColors.BRAND_LIGHT,
      fontSize: '11px',
    },
    '&:disabled': {
      border: '1px solid ' + uiColors.BRAND,
      boxShadow: boxShadows.DEFAULT_OR_DISABLED,
    },
  },
  '&:hover': {
    boxShadow: boxShadows.HOVER_DEFAULT,
  },
  '&:active': {
    boxShadow: 'inset 0px  0px 2px 2px ' + uiColors.TRANSPARENT_GREY,
  },
  '&:focus': {
    outline: 'none',
  },
  '&:disabled': {
    border: '1px solid ' + uiColors.GREY,
  },
};

const BACK = 'back';
const NEXT = 'next';
const PRIMARY_LARGE = 'primary-large';
const PRIMARY_SMALL = 'primary-small';
const PRIMARY_BRAND = 'primary-brand';
const PRIMARY_DARK = 'primary-dark';
const PRIMARY_WHITE = 'primary-white';
const BOTTOM = 'bottom';
const ORANGE = 'orange';
const FILTER = 'filter';
const SHOW_MORE = 'showMore';
const LINK = 'link';
const FORM_SUBMIT = 'form-submit';

const propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf([
    BACK,
    NEXT,
    PRIMARY_LARGE,
    PRIMARY_BRAND,
    PRIMARY_DARK,
    PRIMARY_WHITE,
    BOTTOM,
    ORANGE,
    FILTER,
    SHOW_MORE,
    PRIMARY_SMALL,
    LINK,
    FORM_SUBMIT,
  ]),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

const defaultProps = {
  className: '',
  type: PRIMARY_LARGE,
  disabled: false,
};

class Button extends React.Component {
  static BACK = BACK;
  static NEXT = NEXT;
  static PRIMARY_LARGE = PRIMARY_LARGE;
  static PRIMARY_SMALL = PRIMARY_SMALL;
  static PRIMARY_BRAND = PRIMARY_BRAND;
  static PRIMARY_DARK = PRIMARY_DARK;
  static PRIMARY_WHITE = PRIMARY_WHITE;
  static BOTTOM = BOTTOM;
  static ORANGE = ORANGE;
  static FILTER = FILTER;
  static SHOW_MORE = SHOW_MORE;
  static LINK = LINK;
  static FORM_SUBMIT = FORM_SUBMIT;

  static propTypes = {
    className: PropTypes.string,
    type: PropTypes.oneOf([
      Button.BACK,
      Button.NEXT,
      Button.PRIMARY_LARGE,
      Button.PRIMARY_BRAND,
      Button.PRIMARY_DARK,
      Button.PRIMARY_WHITE,
      Button.BOTTOM,
      Button.ORANGE,
      Button.FILTER,
      Button.SHOW_MORE,
      Button.PRIMARY_SMALL,
      Button.LINK,
      Button.FORM_SUBMIT,
    ]),
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    className: '',
    type: Button.PRIMARY_LARGE,
    disabled: false,
  };

  renderContent(type, children) {
    switch (type) {
      case BACK:
        return <FontAwesomeIcon icon={faArrowLeft} />;
      case NEXT:
        return <FontAwesomeIcon icon={faArrowRight} />;
      default:
        return children;
    }
  }

  render() {
    const { className, type, disabled, onClick, children } = this.props;
    return (
      <button
        onClick={onClick}
        className={type + ' ' + className}
        type={type === Button.FORM_SUBMIT ? 'submit' : 'button'}
        css={styles}
        disabled={disabled}
      >
        {this.renderContent(type, children)}
      </button>
    );
  }
}

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;
