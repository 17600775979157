import * as deepCopy from 'deepcopy';
import equal from 'fast-deep-equal';
import moment from 'moment';

const getEmptyFilter = () => ({
  // common
  content: undefined,
  date: { min: undefined, max: undefined },
  jurisdiction: undefined,
  // relations
  relatedLawItems: undefined,
  relatedTheoryItems: undefined,
  relatedDecisions: undefined,
  relatedProcessSteps: undefined,
  relatedTaxonomy: undefined,
  // Entity Specific
  lawItem: {},
  theoryItem: {},
  processStep: {},
  decision: {
    // facets
    court: undefined,
    decisionType: undefined,
    caseType: undefined,
    meritType: undefined,
    reactionType: undefined,
    suspensiveEffect: undefined,
    formalityType: undefined,
    reactionFormality: undefined,
    // searchText
    judges: undefined,
    plaintiff: undefined,
    plaintiffRepresentative: undefined,
    defendant: undefined,
    defendantRepresentative: undefined,
    procurementForm: undefined,
    procurementFormProcedure: undefined,
    procurementFormAddon: undefined,
    // range
    reactionFormalityAmount: { min: undefined, max: undefined },
    posOpfIntClaim: { min: undefined, max: undefined },
    posOpfIntAwarded: { min: undefined, max: undefined },
    negKonsIntClaim: { min: undefined, max: undefined },
    negKonsIntAwarded: { min: undefined, max: undefined },
    caseCost: { min: undefined, max: undefined },
  },
});

/**
 * Iteratively strips an object of empty or undefined values, and transforms dates into strings in the process.
 * @param obj the object to clean up.
 * @return {*} cleaned object, or `undefined` if no value has been set for it.
 */
const cleaner = (obj) => {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }
  if (obj instanceof Date) {
    return moment(obj).toISOString(true);
  }
  if (obj instanceof Array) {
    const cleanedArray = obj.map((value) => cleaner(value));
    return cleanedArray.length ? cleanedArray : undefined;
  }
  const cleaned = {};
  Object.entries(obj)
    .map(([key, value]) => [key, cleaner(value)])
    .filter(([key, value]) => value !== undefined && key !== 'uuid')
    .forEach(([key, value]) => (cleaned[key] = value));
  if (cleaned.value) {
    return cleaned.value;
  }
  return Object.keys(cleaned).length ? cleaned : undefined;
};

/**
 * Filter to be used when searching for relevant documents.
 *
 * Note that fields set to `undefined` will not be included in the final filter, while fields set to `null`
 * will.
 */
export default class SearchFilter {
  constructor() {
    this.clear();
  }

  /**
   * Creates a SearchFilter instance from a URL encoded JSON string.
   * @param data the data to create the internal filter from.
   * @return {SearchFilter}
   */
  static deserialize(data) {
    let result = new SearchFilter();
    if (data) {
      try {
        const parse = JSON.parse(decodeURIComponent(data));
        parse.date?.min && (parse.date.min = new Date(parse.date.min));
        parse.date?.max && (parse.date.max = new Date(parse.date.max));
        parse.date.uuid = Math.random();
        result._filter = parse;
      } catch (e) {
        debugger;
      }
    }
    return result;
  }

  /**
   * Serializes the filter into a URL encoded JSON string.
   * @return {string}
   */
  serialize() {
    return this.isEmpty()
      ? ''
      : encodeURIComponent(JSON.stringify(this._filter));
  }

  equals(otherFilter) {
    return equal(this._filter, otherFilter._filter);
  }

  /**
   * @return {SearchFilter} A deep copy of the filter
   */
  copy() {
    let result = new SearchFilter();
    result._filter = deepCopy(this._filter);
    return result;
  }

  isEmpty() {
    return equal(this._filter, getEmptyFilter());
  }

  clear() {
    this._filter = getEmptyFilter();
  }

  /**
   * Strips the filter down to the relevant parts where values have been set, such that undefined fields are excluded.
   * @return {*} Cleaned version of the filter.
   */
  toContentFilter() {
    return cleaner(this._filter);
  }

  /*
     __   __               __       
    /  ` /  \  |\/|  |\/| /  \ |\ | 
    \__, \__/  |  |  |  | \__/ | \| 
    */

  // General filters and facets

  set searchText(text) {
    this._filter.content = text;
  }

  get searchText() {
    return this._filter.content;
  }

  set dateRangeBegin(beginDate) {
    return (this._filter.date.min = beginDate);
  }

  get dateRangeBegin() {
    return this._filter.date.min;
  }

  set dateRangeEnd(endDate) {
    return (this._filter.date.max = endDate);
  }

  get dateRangeEnd() {
    return this._filter.date.max;
  }

  get dateRangeUuid() {
    return this._filter.date.uuid;
  }

  get relatedLawItems() {
    return this._filter.relatedLawItems?.slice() || [];
  }

  set relatedLawItems(listOfLawItemIds) {
    this._filter.relatedLawItems = listOfLawItemIds;
  }

  get relatedTheoryItems() {
    return this._filter.relatedTheoryItems?.slice() || [];
  }

  set relatedTheoryItems(listOfTheoryItemIds) {
    this._filter.relatedTheoryItems = listOfTheoryItemIds;
  }

  get relatedDecisions() {
    return this._filter.relatedDecisions?.slice() || [];
  }

  set relatedDecisions(listOfDecisionIds) {
    this._filter.relatedDecisions = listOfDecisionIds;
  }

  get relatedProcessSteps() {
    return this._filter.relatedProcessSteps?.slice() || [];
  }

  set relatedProcessSteps(listOfProcessStepIds) {
    this._filter.relatedProcessSteps = listOfProcessStepIds;
  }

  get relatedTaxonomy() {
    return this._filter.relatedTaxonomy?.slice() || [];
  }

  set relatedTaxonomy(listOfKeywordIds) {
    this._filter.relatedTaxonomy = listOfKeywordIds;
  }

  get jurisdiction() {
    return this._filter.jurisdiction?.slice() || [];
  }

  set jurisdiction(listOfJurisdictionIds) {
    this._filter.jurisdiction = listOfJurisdictionIds;
  }

  /*
    __   ___  __     __     __       
    |  \ |__  /  ` | /__` | /  \ |\ | 
    |__/ |___ \__, | .__/ | \__/ | \| 
                                    
    */

  // Facets

  set facetDecisionCourt(courtId) {
    this._filter.decision.court = courtId;
  }

  get facetDecisionCourt() {
    return this._filter.decision.court?.slice() || [];
  }

  set facetDecisionDecisionType(decisionType) {
    this._filter.decision.decisionType = decisionType;
  }

  get facetDecisionDecisionType() {
    return this._filter.decision.decisionType?.slice() || [];
  }

  set facetDecisionCaseType(caseType) {
    this._filter.decision.caseType = caseType;
  }

  get facetDecisionCaseType() {
    return this._filter.decision.caseType?.slice() || [];
  }

  set facetDecisionMeritType(meritType) {
    this._filter.decision.meritType = meritType;
  }

  get facetDecisionMeritType() {
    return this._filter.decision.meritType?.slice() || [];
  }

  set facetDecisionReactionType(reactionType) {
    this._filter.decision.reactionType = reactionType;
  }

  get facetDecisionReactionType() {
    return this._filter.decision.reactionType?.slice() || [];
  }

  set facetDecisionSuspensiveEffect(suspensiveEffect) {
    this._filter.decision.suspensiveEffect = suspensiveEffect;
  }

  get facetDecisionSuspensiveEffect() {
    return this._filter.decision.suspensiveEffect?.slice() || [];
  }

  set facetDecisionFormalityType(formalityType) {
    this._filter.decision.formalityType = formalityType;
  }

  get facetDecisionFormalityType() {
    return this._filter.decision.formalityType?.slice() || [];
  }

  set facetDecisionReactionFormality(reactionFormality) {
    this._filter.decision.reactionFormality = reactionFormality;
  }

  get facetDecisionReactionFormality() {
    return this._filter.decision.reactionFormality?.slice() || [];
  }

  set facetDecisionProcurementForm(procurementForm) {
    this._filter.decision.procurementForm = procurementForm;
  }

  get facetDecisionProcurementForm() {
    return this._filter.decision.procurementForm?.slice() || [];
  }

  set facetDecisionProcurementFormProcedure(procurementFormProcedure) {
    this._filter.decision.procurementFormProcedure = procurementFormProcedure;
  }

  get facetDecisionProcurementFormProcedure() {
    return this._filter.decision.procurementFormProcedure?.slice() || [];
  }

  set facetDecisionProcurementFormAddon(procurementFormAddon) {
    this._filter.decision.procurementFormAddon = procurementFormAddon;
  }

  get facetDecisionProcurementFormAddon() {
    return this._filter.decision.procurementFormAddon?.slice() || [];
  }

  // Search texts

  set searchTextDecisionJudges(judges) {
    this._filter.decision.judges = judges;
  }

  get searchTextDecisionJudges() {
    return this._filter.decision.judges;
  }

  set searchTextDecisionPlaintiff(searchText) {
    this._filter.decision.plaintiff = searchText;
  }

  get searchTextDecisionPlaintiff() {
    return this._filter.decision.plaintiff;
  }

  set searchTextDecisionPlaintiffRepresentative(searchText) {
    this._filter.decision.plaintiffRepresentative = searchText;
  }

  get searchTextDecisionPlaintiffRepresentative() {
    return this._filter.decision.plaintiffRepresentative;
  }

  set searchTextDecisionDefendant(searchText) {
    this._filter.decision.defendant = searchText;
  }

  get searchTextDecisionDefendant() {
    return this._filter.decision.defendant;
  }

  set searchTextDecisionDefendantRepresentative(searchText) {
    this._filter.decision.defendantRepresentative = searchText;
  }

  get searchTextDecisionDefendantRepresentative() {
    return this._filter.decision.defendantRepresentative;
  }

  // Ranges

  get rangeDecisionReactionFormality() {
    return this._filter.decision.reactionFormalityAmount;
  }

  set rangeDecisionReactionFormalityMin(value) {
    this._filter.decision.reactionFormalityAmount.min = value;
  }

  get rangeDecisionReactionFormalityMin() {
    return this._filter.decision.reactionFormalityAmount.min;
  }

  set rangeDecisionReactionFormalityMax(value) {
    this._filter.decision.reactionFormalityAmount.max = value;
  }

  get rangeDecisionReactionFormalityMax() {
    return this._filter.decision.reactionFormalityAmount.max;
  }

  get rangeDecisionPosOpfIntClaim() {
    return this._filter.decision.posOpfIntClaim;
  }

  set rangeDecisionPosOpfIntClaimMin(value) {
    this._filter.decision.posOpfIntClaim.min = value;
  }

  get rangeDecisionPosOpfIntClaimMin() {
    return this._filter.decision.posOpfIntClaim.min;
  }

  set rangeDecisionPosOpfIntClaimMax(value) {
    this._filter.decision.posOpfIntClaim.max = value;
  }

  get rangeDecisionPosOpfIntClaimMax() {
    return this._filter.decision.posOpfIntClaim.max;
  }

  get rangeDecisionPosOpfIntAwarded() {
    return this._filter.decision.posOpfIntAwarded;
  }

  set rangeDecisionPosOpfIntAwardedMin(value) {
    this._filter.decision.posOpfIntAwarded.min = value;
  }

  get rangeDecisionPosOpfIntAwardedMin() {
    return this._filter.decision.posOpfIntAwarded.min;
  }

  set rangeDecisionPosOpfIntAwardedMax(value) {
    this._filter.decision.posOpfIntAwarded.max = value;
  }

  get rangeDecisionPosOpfIntAwardedMax() {
    return this._filter.decision.posOpfIntAwarded.max;
  }

  get rangeDecisionNegKonsIntClaim() {
    return this._filter.decision.negKonsIntClaim;
  }

  set rangeDecisionNegKonsIntClaimMin(value) {
    this._filter.decision.negKonsIntClaim.min = value;
  }

  get rangeDecisionNegKonsIntClaimMin() {
    return this._filter.decision.negKonsIntClaim.min;
  }

  set rangeDecisionNegKonsIntClaimMax(value) {
    this._filter.decision.negKonsIntClaim.max = value;
  }

  get rangeDecisionNegKonsIntClaimMax() {
    return this._filter.decision.negKonsIntClaim.max;
  }

  get rangeDecisionNegKonsIntAwarded() {
    return this._filter.decision.negKonsIntAwarded;
  }

  set rangeDecisionNegKonsIntAwardedMin(value) {
    this._filter.decision.negKonsIntAwarded.min = value;
  }

  get rangeDecisionNegKonsIntAwardedMin() {
    return this._filter.decision.negKonsIntAwarded.min;
  }

  set rangeDecisionNegKonsIntAwardedMax(value) {
    this._filter.decision.negKonsIntAwarded.max = value;
  }

  get rangeDecisionNegKonsIntAwardedMax() {
    return this._filter.decision.negKonsIntAwarded.max;
  }

  get rangeDecisionCaseCost() {
    return this._filter.decision.caseCost;
  }

  set rangeDecisionCaseCostMin(value) {
    this._filter.decision.caseCost.min = value;
  }

  get rangeDecisionCaseCostMin() {
    return this._filter.decision.caseCost.min;
  }

  set rangeDecisionCaseCostMax(value) {
    this._filter.decision.caseCost.max = value;
  }

  get rangeDecisionCaseCostMax() {
    return this._filter.decision.caseCost.max;
  }

  /*
    ___       ___  __   __      
     |  |__| |__  /  \ |__) \ / 
     |  |  | |___ \__/ |  \  |  
    */

  /*
       |     /\  |  | 
       |___ /~~\ |/\| 
    */
}
