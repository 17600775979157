import { all, fork } from 'redux-saga/effects';
import taxonomyPageLoadSaga from './pageload';
import locationChange from './pageopen';
import setFilterText from './setfiltertext';

export default function* taxonomyRootSaga() {
  yield all([
    fork(locationChange),
    fork(taxonomyPageLoadSaga),
    fork(setFilterText),
  ]);
}
