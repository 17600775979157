import { put, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import * as appActions from '../../../actions/app';

function* doHandleNewsletterSuccess() {
  yield put(appActions.relocateToFrontPage());
}

export default function* handleNewsletterSuccess() {
  yield takeEvery(
    ActionTypes.API_NEWSLETTER_CREATE_SUCCESS,
    doHandleNewsletterSuccess,
  );
}
