import * as ActionTypes from '../../../constants/ActionTypes';
import { call, takeEvery } from 'redux-saga/effects';
import { generateApiSaga } from '../../../utils/sagas';
import AppUserConnector from '../../../connectors/AppUser';

const doSave = generateApiSaga(AppUserConnector, function* (connector, action) {
  const user = action.payload.appUser;
  return yield call([connector, connector.updateAppUser], user);
});

export default function* updateAppUser() {
  yield takeEvery(ActionTypes.API_USER_UPDATE_APP_USER_INIT, doSave);
}
