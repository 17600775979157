import { fromJS } from 'immutable';
import * as ActionTypes from '../../constants/ActionTypes';

const initialState = fromJS({ isDisabled: false });

export default function newsletterPageReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.API_NEWSLETTER_CREATE_INIT:
      return state.merge(
        fromJS({
          isDisabled: true,
        }),
      );
    case ActionTypes.API_NEWSLETTER_CREATE_ERROR:
    case ActionTypes.API_NEWSLETTER_CREATE_SUCCESS:
      return state.merge(
        fromJS({
          isDisabled: false,
        }),
      );
    default:
      return state;
  }
}
