/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component, createRef } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import { Form, Modal } from 'react-bootstrap';
import * as UpdateType from '../../constants/UpdateType';
import * as EntityType from '../../constants/UpdatedEntityType';
import * as decisionActions from '../../actions/page/decision';
import * as courtApiSelector from '../../selectors/api/court';
import * as decisionApiSelector from '../../selectors/api/decision';
import * as taxonomyApiSelector from '../../selectors/api/taxonomy';
import * as createDecisionPageSelector from '../../selectors/page/createdecision';
import Filter from '../../components/common/molecules/Filter';
import MultiSelector from '../../components/common/molecules/MultiSelector';
import Datepicker from '../../components/common/atoms/Datepicker';
import Text, { TextType } from '../../components/common/atoms/Text';
import { Trans, withTranslation } from 'react-i18next';
import Input from '../../components/common/atoms/Input';
import { refBind } from '../../utils/ref-bind';
import FormFile from '../../components/common/atoms/FormFile';
import Button from '../../components/common/atoms/Button';
import { CREATE_DECISION_MODE } from '../../constants/CreateDecisionMode';
import * as decisionPageSelector from '../../selectors/page/decision';
import * as decisionApiActions from '../../actions/api/decision';
import * as newsworthyApiActions from '../../actions/api/newsworthy';
import RelationEditor from '../../components/common/organisms/RelationEditor';
import * as EntityTypes from '../../constants/EntityTypes';
import { DECISION } from '../../constants/EntityTypes';
import { fontColors, fontSize } from '../../components/common/styles/styles';
import NewsWorthy from '../../components/common/molecules/Newsworthy';
import SearchableMultiSelectDropdown from './SearchableMultiSelectDropdown';
import { mapDecisions } from '../../utils/AutocompleteMapper';

const styles = {
  '.layout-container': {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridGap: '.5rem 1rem',
    marginBottom: '3rem',
  },
  '.modal-body': {
    height: '70vh',
    overflow: 'auto',
  },
  '.error': {
    height: '5px',
    fontSize: fontSize.PARAGRAPH_FOOTER,
    color: fontColors.RED,
  },
};

class DecisionEditor extends Component {
  constructor(props) {
    super(props);
    this.state = createDecisionPageSelector.getEmptyState();
    refBind(
      this,
      'renderForm',
      'handleCaseNameChange',
      'handleCaseTypeChange',
      'handleItemTypeChange',
      'handleInitiatedDateChange',
      'handleRulingDateChange',
      'handleJudgeChange',
      'handleDefendantChange',
      'handleDefendantRepresentativeChange',
      'handlePlaintiffChange',
      'handleCourtChange',
      'handleFileChange',
      'submitDecision',
      'handlePlaintiffRepresentativeChange',
      'updateRelations',
      'handleMeritTypeChange',
      'handleReactionTypeChange',
      'handleFormalityTypeChange',
      'handleReactionFormalityChange',
      'handleSuspensiveEffectChange',
      'handleProcurementFormChange',
      'handleProcurementFormProcedureChange',
      'handleProcurementFormAddonChange',
      'handleEditorNameChange',
      'handleUfrReferenceChange',
      'handleShortNameChange',
      'renderCurrencyInput',
      'handleMoneyInputBlur',
      'handleMoneyInputChange',
      'handleCaseNumber',
      'handleNewsworthyText',
      'handleNewsworthyCheckbox',
    );
    this.modalRef = createRef();
  }

  submitDecision() {
    this.setState({ validated: true });
    const { court, file, id, changeDescription, isNewsworthy } = this.state;
    const { actions, mode, onCancel } = this.props;
    if (court) {
      actions.createDecision(
        file,
        createDecisionPageSelector.mapDecisionStateToDecision(this.state),
        mode,
        onCancel,
      );
      if (changeDescription && id && isNewsworthy) {
        actions.createNewsworthyItem({
          description: changeDescription,
          entityType: EntityType.DECISION,
          entityId: id,
          updateType: UpdateType.UPDATED,
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.show) {
      if (this.props.mode === CREATE_DECISION_MODE.EDIT) {
        const currentId = this.props.decisionId;
        if (!prevProps.show && currentId) {
          this.props.actions.getDecisionById(currentId);
          return;
        }
        if (prevProps.isLoading && !this.props.isLoading) {
          this.props.decision
            ? this.setState(
                createDecisionPageSelector.getDecisionState(
                  this.props.decision,
                ),
              )
            : this.setState(createDecisionPageSelector.getEmptyState());
        }
      }
      if (!prevProps.show && this.props.mode === CREATE_DECISION_MODE.NEW) {
        this.setState(createDecisionPageSelector.getEmptyState());
      }
    }
  }

  mapPropToOptionsObject(prop, translationKey) {
    return prop.map((value) => ({
      label: (
        <Trans
          i18nKey={`abbreviations.decision.table.${translationKey}.${value.label}`}
        />
      ),
      value: value.label,
    }));
  }

  mapPropFromApiToOptionsObject(prop, translationKey) {
    return prop.map((value) => ({
      label: (
        <Trans
          i18nKey={`abbreviations.decision.table.${translationKey}.${value.value}`}
        />
      ),
      value: value.value,
    }));
  }

  mapOutputObject(update) {
    return {
      label: update.value,
      value: update.value,
    };
  }

  mapMultipleOutputObject(update) {
    return {
      value: update.map((selection) => selection.value).join(';'),
    };
  }

  handleCaseNameChange(event) {
    this.setState({ caseName: event.target.value });
  }

  handleCaseTypeChange(update) {
    this.setState({ caseType: this.mapMultipleOutputObject(update) });
  }

  handleItemTypeChange(update) {
    this.setState({ decisionType: update });
  }

  handleInitiatedDateChange(date) {
    this.setState({ initiatedDate: date });
  }

  handleRulingDateChange(date) {
    this.setState({ rulingDate: date });
  }

  handleJudgeChange(event) {
    this.setState({ judges: event.target.value });
  }

  handleDefendantChange(event) {
    this.setState({ defendant: event.target.value });
  }

  handleDefendantRepresentativeChange(event) {
    this.setState({ defendantRepresentative: event.target.value });
  }

  handlePlaintiffChange(event) {
    this.setState({ plaintiff: event.target.value });
  }

  handleCourtChange(update) {
    this.setState({ court: update });
  }

  handleFileChange(file) {
    this.setState({ file: file });
  }

  handlePlaintiffRepresentativeChange(event) {
    this.setState({ plaintiffRepresentative: event.target.value });
  }

  handleKeywordsChange(keywords) {
    this.setState({ keywords: keywords });
  }

  handleMeritTypeChange(update) {
    this.setState({ meritType: this.mapMultipleOutputObject(update) });
  }

  handleReactionTypeChange(update) {
    this.setState({ reactionType: this.mapMultipleOutputObject(update) });
  }

  handleFormalityTypeChange(update) {
    this.setState({ formalityType: this.mapMultipleOutputObject(update) });
  }

  handleReactionFormalityChange(update) {
    this.setState({ reactionFormality: this.mapMultipleOutputObject(update) });
  }

  handleSuspensiveEffectChange(update) {
    this.setState({ suspensiveEffect: this.mapMultipleOutputObject(update) });
  }

  handleProcurementFormChange(update) {
    this.setState({ procurementForm: this.mapMultipleOutputObject(update) });
  }

  handleProcurementFormProcedureChange(update) {
    this.setState({
      procurementFormProcedure: this.mapMultipleOutputObject(update),
    });
  }

  handleProcurementFormAddonChange(update) {
    this.setState({
      procurementFormAddon: this.mapMultipleOutputObject(update),
    });
  }

  handleEditorNameChange(event) {
    this.setState({ editorName: event.target.value });
  }

  handleShortNameChange(event) {
    this.setState({ shortName: event.target.value });
  }

  handleCaseNumber(event) {
    this.setState({ caseNumber: event.target.value });
  }

  handleUfrReferenceChange(event) {
    this.setState({ ufrReference: event.target.value });
  }

  handleMoneyInputChange(event, moneyStateName) {
    const { value } = event.target;
    this.setState({
      [moneyStateName]: createDecisionPageSelector.getRawNumber(value),
      [`${moneyStateName}Caption`]: value,
    });
  }

  handleMoneyInputBlur(moneyStateName) {
    const price = this.state[moneyStateName];
    const priceCaption = createDecisionPageSelector.getFormattedMoney(price);
    this.setState({ [`${moneyStateName}Caption`]: priceCaption });
  }

  handleNewsworthyText(description) {
    this.setState({ changeDescription: description });
  }

  handleNewsworthyCheckbox() {
    const { isNewsworthy } = this.state;
    this.setState({ isNewsworthy: !isNewsworthy });
  }

  updateRelations(update) {
    this.setState(update);
  }

  getError(hasError) {
    return (
      <div className="error">
        {hasError && <Trans i18nKey="add-decision.required" />}
      </div>
    );
  }

  renderMultiSelect(key, values, options, fn, hasErrors) {
    let selected = values && values.value ? values.value.split(';') : [];
    selected = selected.map((selection) => ({ value: selection }));
    return (
      <div>
        <Form.Label>
          <span className="text-title-case">
            <Trans i18nKey={`add-decision.${key}`} />:
          </span>
        </Form.Label>
        <MultiSelector
          searchFieldPlaceholder={this.props.t('search-page.search-text')}
          placeholder={this.props.t(`add-decision.${key}`)}
          multiSelectedText={this.props.t('decision.chosen')}
          options={options}
          values={selected}
          hideIndicator={true}
          onChange={fn}
        />
        {this.getError(hasErrors)}
      </div>
    );
  }

  renderInput(key, value, fn, hasErrors) {
    return (
      <div>
        <Form.Label>
          <span className="text-title-case">
            <Trans i18nKey={`add-decision.${key}`} />:
          </span>
        </Form.Label>
        <Input type={Input.FORM} value={value} onChange={fn} />
        {this.getError(hasErrors)}
      </div>
    );
  }

  renderCurrencyInput(key, moneyStateName, currencyAbbreviation, hasErrors) {
    const priceCaption = this.state[`${moneyStateName}Caption`];
    return (
      <div>
        <Form.Label>
          <span className="text-title-case">
            <Trans i18nKey={`add-decision.${key}`} />:
          </span>
        </Form.Label>
        <Input
          onBlur={() => this.handleMoneyInputBlur(moneyStateName)}
          type={Input.WITH_LABEL}
          labelCaption={currencyAbbreviation}
          value={priceCaption}
          onChange={(e) => this.handleMoneyInputChange(e, moneyStateName)}
        />
        {this.getError(hasErrors)}
      </div>
    );
  }

  renderFilter(key, value, options, fn, hasErrors) {
    const displayValue = value
      ? (options || []).find(
          (option) =>
            option.value === value.value || option.label === value.value,
        )
      : null;
    return (
      <div>
        <Form.Label>
          <span className="text-title-case">
            <Trans i18nKey={`add-decision.${key}`} />:
          </span>
        </Form.Label>
        <Filter options={options} value={displayValue} onChange={fn} />
        {this.getError(hasErrors)}
      </div>
    );
  }

  renderDatePicker(key, date, fn, ref, index) {
    return (
      <div>
        <Form.Label>
          <span className="text-title-case">
            <Trans i18nKey={`add-decision.${key}`} />:
          </span>
        </Form.Label>
        <Datepicker
          disableRange
          key={`${key}-${index}`}
          placeholderKey={''}
          defaultValue={{ startDate: date }}
          parentContainer={ref}
          onDateSelected={fn}
        />
      </div>
    );
  }

  renderForm(ref) {
    const { validated, court, changeDescription, isNewsworthy } = this.state;
    return (
      <div className="layout-container">
        {this.renderInput(
          'case-name',
          this.state.caseName,
          this.handleCaseNameChange,
        )}
        {this.renderInput(
          'short-name',
          this.state.shortName,
          this.handleShortNameChange,
        )}
        {this.renderDatePicker(
          'ruling-date',
          this.state.rulingDate,
          this.handleRulingDateChange,
          ref,
          this.state.datepickerKey,
        )}
        {this.renderDatePicker(
          'initiated-date',
          this.state.initiatedDate,
          this.handleInitiatedDateChange,
          ref,
          this.state.datepickerKey,
        )}
        {this.renderFilter(
          'item-type',
          this.state.decisionType,
          this.mapPropToOptionsObject(
            this.props.decisionTypes,
            'decision-type',
          ),
          this.handleItemTypeChange,
        )}
        {this.renderMultiSelect(
          'case-type',
          this.state.caseType,
          this.mapPropFromApiToOptionsObject(
            this.props.decisionCaseTypes,
            'case-type',
          ),
          this.handleCaseTypeChange,
        )}
        {this.renderFilter(
          'court-name',
          court,
          this.props.courts,
          this.handleCourtChange,
          !court && validated,
        )}
        {this.renderMultiSelect(
          'merit-type',
          this.state.meritType,
          this.mapPropToOptionsObject(
            this.props.decisionMeritTypes,
            'merit-type',
          ),
          this.handleMeritTypeChange,
        )}
        {this.renderMultiSelect(
          'reaction-type',
          this.state.reactionType,
          this.mapPropToOptionsObject(
            this.props.decisionReactionTypes,
            'reaction-type',
          ),
          this.handleReactionTypeChange,
        )}
        {this.renderMultiSelect(
          'formality-type',
          this.state.formalityType,
          this.mapPropToOptionsObject(
            this.props.decisionFormalityTypes,
            'formality-type',
          ),
          this.handleFormalityTypeChange,
        )}
        {this.renderMultiSelect(
          'reaction-formality',
          this.state.reactionFormality,
          this.mapPropToOptionsObject(
            this.props.decisionReactionFormality,
            'reaction-formality',
          ),
          this.handleReactionFormalityChange,
        )}
        {this.renderCurrencyInput(
          'reaction-formality-amount',
          'reactionFormalityAmount',
          'DKK',
        )}
        {this.renderMultiSelect(
          'suspensive-effect',
          this.state.suspensiveEffect,
          this.mapPropToOptionsObject(
            this.props.decisionSuspensiveEffect,
            'suspensive-effect',
          ),
          this.handleSuspensiveEffectChange,
        )}
        {this.renderInput(
          'editor-name',
          this.state.editorName,
          this.handleEditorNameChange,
        )}
        {this.renderInput(
          'ufr-Reference',
          this.state.ufrReference,
          this.handleUfrReferenceChange,
        )}
        {this.renderInput('judges', this.state.judges, this.handleJudgeChange)}
        {this.renderInput(
          'plaintiff',
          this.state.plaintiff,
          this.handlePlaintiffChange,
        )}
        {this.renderInput(
          'plaintiff-representative',
          this.state.plaintiffRepresentative,
          this.handlePlaintiffRepresentativeChange,
        )}
        {this.renderInput(
          'defendant',
          this.state.defendant,
          this.handleDefendantChange,
        )}
        {this.renderInput(
          'defendant-representative',
          this.state.defendantRepresentative,
          this.handleDefendantRepresentativeChange,
        )}
        {this.renderMultiSelect(
          'procurement-form',
          this.state.procurementForm,
          this.mapPropToOptionsObject(
            this.props.decisionProcurementForm,
            'procurement-form',
          ),
          this.handleProcurementFormChange,
        )}
        {this.renderMultiSelect(
          'procurement-form-procedure',
          this.state.procurementFormProcedure,
          this.mapPropToOptionsObject(
            this.props.decisionProcurementFormProcedure,
            'procurement-form-procedure',
          ),
          this.handleProcurementFormProcedureChange,
        )}
        {this.renderMultiSelect(
          'procurement-form-addon',
          this.state.procurementFormAddon,
          this.mapPropToOptionsObject(
            this.props.decisionProcurementFormAddon,
            'procurement-form-addon',
          ),
          this.handleProcurementFormAddonChange,
        )}
        {this.renderInput(
          'official-case-number',
          this.state.caseNumber,
          this.handleCaseNumber,
        )}
        {this.renderCurrencyInput('pos-opf-int-claim', 'posOpfIntClaim', 'DKK')}
        {this.renderCurrencyInput(
          'pos-opf-int-awarded',
          'posOpfIntAwarded',
          'DKK',
        )}
        {this.renderCurrencyInput(
          'neg-kons-int-claim',
          'negKonsIntClaim',
          'DKK',
        )}
        {this.renderCurrencyInput(
          'neg-kons-int-awarded',
          'negKonsIntAwarded',
          'DKK',
        )}
        {this.renderCurrencyInput('case-cost', 'caseCost', 'DKK')}
        <SearchableMultiSelectDropdown
          itemType={EntityTypes.DECISION}
          currentTargetItems={this.state.initPrecedingDecisions}
          onChange={this.updateRelations}
          fieldName="precedingDecisions"
          mapFn={mapDecisions}
          title="article-editor.preceding-decisions"
        />
        <Form.Label>
          <span className="text-uppercase">
            <Trans i18nKey="add-decision.file-path" />:
          </span>
        </Form.Label>
        <FormFile onChange={this.handleFileChange} required />
        <RelationEditor
          entityType={DECISION}
          entityId={this.state.id}
          decisions={this.state.initRelatedDecisions}
          lawItems={this.state.initRelatedLawItems}
          taxonomy={this.state.initKeywords}
          processSteps="disabled"
          theoryItems={this.state.initRelatedTheoryItems}
          onChange={this.updateRelations}
        />
        {this.props.mode === CREATE_DECISION_MODE.EDIT && (
          <NewsWorthy
            onChange={this.handleNewsworthyText}
            onClickCheckbox={this.handleNewsworthyCheckbox}
            checked={isNewsworthy}
            changeDescription={changeDescription}
          />
        )}
      </div>
    );
  }

  render() {
    const { show, onCancel, mode } = this.props;
    const keys = createDecisionPageSelector.getLocalizationKeys(mode);
    return (
      <Modal show={show} css={styles} size="xl" scrollable>
        <Modal.Header>
          <Modal.Title>
            <Text as="span" type={TextType.HEADER_1_SMALL}>
              <Trans i18nKey={keys.title} />
            </Text>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body ref={this.modalRef} className="modal-body scroll-bar">
          {show && this.renderForm(this.modalRef)}
        </Modal.Body>
        <Modal.Footer>
          <div className="my-3 w-100 d-flex justify-content-between">
            <Button type={Button.PRIMARY_DARK} onClick={onCancel}>
              <span className="text-title-case">
                <Trans i18nKey="add-decision.cancel-button" />
              </span>
            </Button>

            <Button type={Button.PRIMARY_BRAND} onClick={this.submitDecision}>
              <span className="text-title-case">
                <Trans i18nKey={keys.submitBtn} />
              </span>
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const courts = createDecisionPageSelector
    .mapCourtsToSelectOptions(courtApiSelector.getAll(state) || [])
    .toList();
  const decisionCaseTypes = createDecisionPageSelector.mapCaseTypesToOptions(
    decisionApiSelector.getDecisionCaseTypes(state),
  );
  const decisionTypes = createDecisionPageSelector.mapItemsToOptions(
    decisionApiSelector.getDecisionTypes(state),
  );
  const decisionMeritTypes = createDecisionPageSelector.mapItemsToOptions(
    decisionApiSelector.getDecisionMeritTypes(state),
  );
  const decisionReactionTypes = createDecisionPageSelector.mapItemsToOptions(
    decisionApiSelector.getDecisionReactionTypes(state),
  );
  const decisionFormalityTypes = createDecisionPageSelector.mapItemsToOptions(
    decisionApiSelector.getDecisionFormalityTypes(state),
  );
  const decisionReactionFormality =
    createDecisionPageSelector.mapItemsToOptions(
      decisionApiSelector.getDecisionReactionFormality(state),
    );
  const decisionSuspensiveEffect = createDecisionPageSelector.mapItemsToOptions(
    decisionApiSelector.getDecisionSuspensiveEffect(state),
  );
  const decisionProcurementForm = createDecisionPageSelector.mapItemsToOptions(
    decisionApiSelector.getDecisionProcurementForm(state),
  );
  const decisionProcurementFormProcedure =
    createDecisionPageSelector.mapItemsToOptions(
      decisionApiSelector.getDecisionProcurementFormProcedure(state),
    );
  const decisionProcurementFormAddon =
    createDecisionPageSelector.mapItemsToOptions(
      decisionApiSelector.getDecisionProcurementFormAddon(state),
    );
  let decision = decisionPageSelector.getEditNode(state);
  const isLoading = decisionPageSelector.isLoading(state);
  decision =
    decision && decision.get('id') === ownProps.decisionId ? decision : null;
  if (!isLoading && decision && decision.get('keywords')) {
    const taxonomyIds = decision
      .get('keywords')
      .map((k) => k.get('id'))
      .toJS();
    decision = decision.set(
      'keywords',
      taxonomyApiSelector
        .getByIds(state, taxonomyIds)
        .map((i) => i.get('node'))
        .toList(),
    );
  }
  return {
    ...ownProps,
    courts,
    decisionCaseTypes,
    decisionTypes,
    decisionMeritTypes,
    decisionReactionTypes,
    decisionFormalityTypes,
    decisionReactionFormality,
    decisionSuspensiveEffect,
    decisionProcurementForm,
    decisionProcurementFormProcedure,
    decisionProcurementFormAddon,
    decision,
    isLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      createDecision: decisionActions.submitDecisionFormData,
      getDecisionById: decisionApiActions.getRelatedById,
      createNewsworthyItem: newsworthyApiActions.createNewsworthyItem,
    },
    dispatch,
  ),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withImmutablePropsToJS,
)(withTranslation()(DecisionEditor));
