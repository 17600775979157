/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose, pure } from 'recompose';
import { createStructuredSelector } from 'reselect';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import * as EntityTypes from '../../constants/EntityTypes';
import { push } from 'connected-react-router/immutable';
import { ProgressBar } from 'react-bootstrap';
import { uiColors } from '../../components/common/styles/styles';
import NavigationTree from '../../components/navigationTree/NavigationTree';
import * as linkUtils from '../../utils/link';
import * as urls from '../../constants/Urls';
import * as searchPageActions from '../../actions/page/search';
import * as searchPageSelectors from '../../selectors/page/search';

const styles = { backgroundColor: uiColors.WHITE };

const SmartNavigationTree = pure(withImmutablePropsToJS(NavigationTree));

class LawSearchView extends React.Component {
  constructor(props) {
    super(props);
    this.handleRedirect = this.handleRedirect.bind(this);
    this.updateFilter = this.updateFilter.bind(this);
  }

  updateFilter(filter) {
    this.props.actions.updateSearch(filter);
  }

  handleRedirect(selectedNode) {
    if (selectedNode.node.entityType === EntityTypes.LAW) {
      this.props.actions.push(
        linkUtils.buildPageLink(urls.LAW_ROUTE, selectedNode.node.uri),
      );
    } else {
      const law = (this.props.laws || []).find(
        (law) => law.node.id === selectedNode.node.lawId,
      );
      this.props.actions.push(
        linkUtils.buildPageLink(
          urls.LAW_ROUTE,
          law ? law.node.uri : 'all',
          selectedNode.node.uri,
        ),
      );
    }
  }

  render() {
    const { lawItemSummary, tree } = this.props;
    const totalItems = lawItemSummary.get('totalItems');
    const loadedItems =
      lawItemSummary.get('totalItems') -
      lawItemSummary.get('totalItemsMissing');

    return (
      <React.Fragment>
        <ProgressBar
          animated
          max={totalItems}
          now={loadedItems}
          label={`${loadedItems}/${totalItems}`}
          className={loadedItems === totalItems ? 'loading-done' : ''}
        />
        <div css={styles}>
          <SmartNavigationTree
            entityType={EntityTypes.LAW}
            tree={tree}
            onNodeSelection={this.handleRedirect}
          />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  lawItemSummary: searchPageSelectors.getLatestSearchLawItemSummary,
  tree: searchPageSelectors.getLatestSearchLawItemTree,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      push: push,
      updateSearch: searchPageActions.updateFilter,
    },
    dispatch,
  ),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  LawSearchView,
);
