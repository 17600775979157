import { fromJS } from 'immutable';
import * as ActionTypes from '../../constants/ActionTypes';
import * as EntityTypes from '../../constants/EntityTypes';
import { mergeResponseToState } from './index';

const initialState = fromJS({ data: {} });
export default function companyApiReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.API_GET_ALL_COMPANIES_SUCCESS:
    case ActionTypes.API_UPDATE_COMPANY_SUCCESS:
      return mergeResponseToState(state, action, EntityTypes.COMPANY);
    default:
      return state;
  }
}
