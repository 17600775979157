import * as ActionTypes from '../../constants/ActionTypes';

export const unsubscribeTypeOfNotification = (token) => ({
  type: ActionTypes.API_UNSUBSCRIBE_NOTIFICATION_INIT,
  payload: token,
});

export const unsubscribeAllNotifications = (token) => ({
  type: ActionTypes.API_UNSUBSCRIBE_ALL_NOTIFICATIONS_INIT,
  payload: token,
});
