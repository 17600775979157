import { all, fork } from 'redux-saga/effects';
import bootstrapSaga from './bootstrap';
import initAuthSaga from './initAuth';
import loginSaga from './login';
import logoutSaga from './logout';
import jwtLoginSaga from './jwtLogin';
import refreshAuthSaga from './jwtRefresh';
import timeoutAuthSaga from './jwtTimeout';
import preloadSaga from './preload';
import resetPassword from './resetPassword';
import setPassword from './setPassword';
import keepHistoryTrack from './routerhistory';

export default function* appRootSaga() {
  yield all([
    fork(bootstrapSaga),
    fork(initAuthSaga),
    fork(loginSaga),
    fork(logoutSaga),
    fork(refreshAuthSaga),
    fork(jwtLoginSaga),
    fork(preloadSaga),
    fork(timeoutAuthSaga),
    fork(resetPassword),
    fork(setPassword),
    fork(keepHistoryTrack),
  ]);
}
