import * as ActionTypes from '../../../constants/ActionTypes';
import { takeLatestWhenAppReady } from '../../../utils/sagas';
import * as decisionApiActions from '../../../actions/api/decision';
import * as lawItemApiActions from '../../../actions/api/lawitem';
import * as theoryItemApiActions from '../../../actions/api/theoryitem';
import * as decisionApiSelector from '../../../selectors/api/decision';
import * as lawItemApiSelector from '../../../selectors/api/lawitem';
import * as theoryItemApiSelector from '../../../selectors/api/theoryitem';
import { put, select } from 'redux-saga/effects';
import * as Sort from '../../../constants/Sort';
import { getSortConfiguration } from '../../../constants/HalProperties';

function* doFrontPageLoad() {
  const decisionFilter = JSON.stringify({
    sort: getSortConfiguration(Sort.createdTimeStamp, Sort.Direction.DESC),
  });
  const decisions = yield select(
    decisionApiSelector.getFilterEntities,
    decisionFilter,
  );
  if (!(decisions && decisions.size)) {
    yield put(decisionApiActions.getLatest());
  }

  const updatedTimeStampFilter = JSON.stringify({
    sort: getSortConfiguration(Sort.updatedTimestamp, Sort.Direction.DESC),
  });
  const lawItems = yield select(
    lawItemApiSelector.getFilterEntities,
    updatedTimeStampFilter,
  );
  if (!(lawItems && lawItems.size)) {
    yield put(lawItemApiActions.getLatest());
  }

  const theoryItems = yield select(
    theoryItemApiSelector.getFilterEntities,
    updatedTimeStampFilter,
  );
  if (!(theoryItems && theoryItems.size)) {
    yield put(theoryItemApiActions.getLatest());
  }
}

export default function* frontPageLoad() {
  yield takeLatestWhenAppReady(
    ActionTypes.APP_PAGE_FRONT_LOAD,
    doFrontPageLoad,
  );
}
