import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import * as ActionTypes from '../../constants/ActionTypes';
import * as LoginTypes from '../../constants/LoginTypes';
import * as authActions from '../../actions/auth';
import * as LocalStorageKeys from '../../constants/LocalStorageKeys';
import { callAndAwaitApi } from '../../utils/sagas';
import * as authSelectors from '../../selectors/store/auth';

function* detectDeveloperAuth() {
  return yield window.localStorage.getItem(
    LocalStorageKeys.LOGIN_DEVELOPER_USERNAME,
  );
}

function* detectJwtAuth() {
  const isLoggedIn = yield select(authSelectors.isLoggedIn);
  if (!isLoggedIn) {
    let response = yield callAndAwaitApi(authActions.refreshJwtLogin());
    if (response.success) {
      return response;
    }
  }
}

// verify if authentication credentials are present from the current (boot) state. If so, make the remaining application aware by indicating that the login is verified.
function* doAuthInit(action) {
  // test which logins are detected as available
  const [devLogin, jwtLogin] = yield all([
    call(detectDeveloperAuth, action),
    call(detectJwtAuth, action),
  ]);

  // settle priority in logins

  if (jwtLogin) {
    yield put(
      authActions.loginRefreshed(jwtLogin.success.payload.responseData.jwt),
    );
  } else if (devLogin) {
    yield put(authActions.loginVerified(devLogin, LoginTypes.DEVELOPER_LOGIN));
  }

  yield put(authActions.authCompleted());
}

export default function* initAuth() {
  yield takeLatest(ActionTypes.AUTH_INIT, doAuthInit);
}
