/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { bindActionCreators } from 'redux';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
import { createStructuredSelector } from 'reselect';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import { Trans, withTranslation } from 'react-i18next';
import Input from '../../components/common/atoms/Input';
import * as searchPageSelectors from '../../selectors/page/search';
import * as searchPageActions from '../../actions/page/search';
import * as permissionStoreSelectors from '../../selectors/store/permissions';
import Filter from '../../components/common/molecules/Filter';
import Datepicker from '../../components/common/atoms/Datepicker';
import { refBind } from '../../utils/ref-bind';
import Text, { TextType } from '../../components/common/atoms/Text';
import FilterOverview from './FilterOverview';

const style = {
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gridGap: '.5rem 2rem',
};

const generalFilters = new Set([
  'relatedLawItems',
  'relatedTheoryItems',
  'relatedDecisions',
  'relatedProcessSteps',
]);

class GeneralEntityFilter extends React.Component {
  constructor(props) {
    super(props);
    refBind(
      this,
      'handleSearchTextChange',
      'handleKeywordsInputChange',
      'handleDateChange',
      'handleDatepickerClose',
      'handleSearchTextInputBlur',
      'handleSearchTextFormSubmit',
      'handleKeywordsInputClose',
    );
    this.state = {
      searchText: undefined,
      keywordsChanged: false,
      datesChanged: false,
    };
  }

  handleSearchTextChange(event) {
    this.setState({ searchText: event.target.value });
  }

  handleSearchTextInputBlur(event) {
    this.setState({ searchText: event.target.value });
    this._updateFilterWithSearchText();
  }

  handleSearchTextFormSubmit(event, newStateForm) {
    event.preventDefault();
    this._updateFilterWithSearchText();
    this.props.actions.runSearch();
  }

  handleKeywordsInputChange(selectedKeywordOptions) {
    const newFilter = this.props.filter.copy();
    newFilter.relatedTaxonomy = (selectedKeywordOptions ?? []).map(
      (opt) => opt.value,
    );
    if (!newFilter.equals(this.props.filter)) {
      this.props.actions.updateFilter(newFilter);
      this.setState({ keywordsChanged: true });
    }
  }

  handleKeywordsInputClose() {
    if (this.state.keywordsChanged) {
      this.props.actions.runSearch();
      this.setState({ keywordsChanged: false });
    }
  }

  handleDateChange(startDate, endDate) {
    const newFilter = this.props.filter.copy();
    newFilter.dateRangeBegin = startDate;
    newFilter.dateRangeEnd = endDate;
    if (!newFilter.equals(this.props.filter)) {
      this.props.actions.updateFilter(newFilter);
      if (startDate === undefined && endDate === undefined) {
        this.props.actions.runSearch();
      } else {
        this.setState({ datesChanged: true });
      }
    }
  }

  handleDatepickerClose() {
    if (this.state.datesChanged) {
      this.props.actions.runSearch();
      this.setState({ datesChanged: false });
    }
  }

  _updateFilterWithSearchText() {
    if (
      this.state.searchText !== this.props.filter.searchText &&
      !(!this.state.searchText && !this.props.filter.searchText)
    ) {
      const newFilter = this.props.filter.copy();
      newFilter.searchText = this.state.searchText || undefined;
      this.props.actions.updateFilter(newFilter);
    }
  }

  render() {
    const {
      keywords,
      filter,
      keywordsForCurrentFilter,
      advancedSearchPermission,
    } = this.props;

    return (
      <React.Fragment>
        <div css={style}>
          <div>
            <Text
              as="p"
              className="text-capitalize"
              type={TextType.PARAGRAPH_STRONG}
            >
              <Trans i18nKey="search-page.search-text" />
            </Text>
            <Form onSubmit={this.handleSearchTextFormSubmit}>
              <Input
                placeholder={this.props.t('search-page.placeholder-search')}
                type={Input.FORM}
                value={
                  this.state.searchText !== undefined
                    ? this.state.searchText
                    : this.props.filter.searchText || ''
                }
                onChange={this.handleSearchTextChange}
                onBlur={this.handleSearchTextInputBlur}
              />
            </Form>
          </div>
          <div>
            <Text
              as="p"
              className="text-capitalize"
              type={TextType.PARAGRAPH_STRONG}
            >
              <Trans i18nKey="search-page.taxonomy" />
            </Text>
            <Filter
              value={keywordsForCurrentFilter}
              options={keywords}
              isMulti
              onChange={this.handleKeywordsInputChange}
              onMenuClose={this.handleKeywordsInputClose}
            />
          </div>
          <div>
            <Text
              as="p"
              className="text-capitalize"
              type={TextType.PARAGRAPH_STRONG}
            >
              <Trans i18nKey="search-page.period" />
            </Text>
            {advancedSearchPermission ? (
              <Datepicker
                key={filter.dateRangeUuid || 'none'}
                rangeTillEndOfDay
                defaultValue={{
                  startDate: filter.dateRangeBegin,
                  endDate: filter.dateRangeEnd,
                }}
                onDateSelected={this.handleDateChange}
                onClose={this.handleDatepickerClose}
              />
            ) : (
              <Input
                disabled={true}
                type={Input.FORM}
                placeholder={this.props.t('search-page.disabled')}
              />
            )}
          </div>
        </div>
        <FilterOverview
          translationKey="general-filter"
          filterSet={generalFilters}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  keywords: searchPageSelectors.getKeywords,
  filter: searchPageSelectors.getCurrentSearchFilter,
  keywordsForCurrentFilter: searchPageSelectors.getKeywordsForCurrentFilter,
  advancedSearchPermission:
    permissionStoreSelectors.checkAdvancedSearchPermission,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      runSearch: searchPageActions.runSearch,
      updateFilter: searchPageActions.updateFilter,
    },
    dispatch,
  ),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withImmutablePropsToJS,
  withTranslation(),
)(GeneralEntityFilter);
