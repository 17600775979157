import { all, fork } from 'redux-saga/effects';
import pageloadSaga from './pageload';
import pageopenSaga from './pageopen';
import selectNodeSaga from './selectnode';
import expandnodeSaga from './expandnode';
import theoryCreateItem from './createtheoryitem';

export default function* appRootSaga() {
  yield all([
    fork(pageloadSaga),
    fork(selectNodeSaga),
    fork(pageopenSaga),
    fork(expandnodeSaga),
    fork(theoryCreateItem),
  ]);
}
