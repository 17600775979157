import BaseConnector from './Base';

export default class AuthConnector extends BaseConnector {
  constructor(baseApiUrl) {
    super(baseApiUrl);
  }
  post(methodName, body = {}) {
    return super.post(this.baseApiUrl + methodName, body);
  }

  logOut() {
    return this.post('logout');
  }

  loginJwt(username, password) {
    return this.post('login', { email: username, password });
  }

  RefreshJwt() {
    return this.post('refresh', {});
  }

  signup(request) {
    return this.post('signup', request);
  }

  resetPassword(username) {
    return this.post('forgot', { email: username });
  }

  setPassword(accessToken, newPassword) {
    return this.post('changepassword', { accessToken, newPassword });
  }
}
