import { fromJS } from 'immutable';
import * as ActionTypes from '../../constants/ActionTypes';

const initialState = fromJS({
  buildEnv: process.env.NODE_ENV,
  appMode: process.env.REACT_APP_APP_MODE,
  buildVersion: process.env.REACT_APP_BUILD_VERSION,
  baseApiUrl: window.location.origin + '/api/',
  baseAuthUrl: window.location.origin + '/authentication/',
  baseSignupUrl: window.location.origin + '/signup/',
  appIsReady: false,
  liveApiRequests: [],
  liveTxnRequests: [],
  previousLocation: null,
});

const apiActionRegex = /^API_(.+)_(INIT|ERROR|SUCCESS)$/;

export default function runtimeReducer(state = initialState, action) {
  // check if it's an api action
  if (apiActionRegex.test(action.type)) {
    if (action.type.endsWith('INIT')) {
      return state.set(
        'liveApiRequests',
        state.get('liveApiRequests').push(action),
      );
    } else {
      return state.set(
        'liveApiRequests',
        state
          .get('liveApiRequests')
          .filter((item) => item !== action.payload.initAction),
      );
    }
  } else {
    switch (action.type) {
      case ActionTypes.APP_BOOTSTRAP_COMPLETED: {
        return state.set('appIsReady', true);
      }
      case ActionTypes.APP_PRELOAD_COMPLETED: {
        return state.set('preloadReady', true);
      }
      case ActionTypes.APP_ROUTE_SAVE:
        state = state.set('previousLocation', state.get('currentLocation'));
        return state.set('currentLocation', action.payload.location);
      default:
        return state;
    }
  }
}
