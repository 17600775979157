/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { FaSearch } from 'react-icons/all';
import Text, { TextType } from './Text';
import { Trans } from 'react-i18next';
import { createStructuredSelector } from 'reselect';
import * as permissionSelectors from '../../../selectors/store/permissions';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { fontFamily, fontSize } from '../styles/styles';

const styles = {
  fontSize: '5rem',
  marginTop: '1em',
  '& ul > li': {
    fontFamily: fontFamily.PARAGRAPH_DEFAULT,
    fontSize: fontSize.PARAGRAPH_DEFAULT,
  },
};

class NoSearch extends React.Component {
  render() {
    const { hasAdvancedSearchPermission } = this.props;
    return (
      <div css={styles} className="d-flex align-items-center flex-column">
        <Text type={TextType.HEADER_1_SMALL}>
          <Trans i18nKey="search-page.no-search-performed" />
          <div className="mb-2" />
        </Text>
        <span className="my-2">
          <FaSearch />
        </span>
        <div className="mb-5" />
        <div>
          <Text className="helper-text" type={TextType.PARAGRAPH_DEFAULT}>
            <Trans i18nKey="search-page.help.intro" />
          </Text>
          <ul>
            <li>
              <Trans i18nKey="search-page.help.union" />
            </li>
            <li>
              <Trans i18nKey="search-page.help.exact-order" />
            </li>
            <li>
              <Trans i18nKey="search-page.help.intersection" />
            </li>
            {hasAdvancedSearchPermission && (
              <li>
                <Trans i18nKey="search-page.help.advanced-filters" />
              </li>
            )}
          </ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  hasAdvancedSearchPermission:
    permissionSelectors.checkAdvancedSearchPermission,
});

export default compose(connect(mapStateToProps))(NoSearch);
