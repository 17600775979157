import { call, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import theoryItemConnector from '../../../connectors/TheoryItem';
import { generateApiSaga } from '../../../utils/sagas';

var doGetTheoryItemsByIds = generateApiSaga(
  theoryItemConnector,
  function* (connector, action) {
    let nodeFragment = action.payload.ids;
    return yield call([connector, connector.getByIdsWithParents], nodeFragment);
  },
);

export default function* getbyids() {
  yield takeEvery(
    ActionTypes.API_THEORY_ITEM_GET_BY_IDS_INIT,
    doGetTheoryItemsByIds,
  );
}
