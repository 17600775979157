/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { Button, Card } from 'react-bootstrap';
import Checkbox from '../atoms/Checkbox';
import PropTypes from 'prop-types';
import { fontColors, rgbaColors, uiColors } from '../styles/styles';

const styles = {
  '.multiselect-option': {
    cursor: 'pointer',
    height: '3rem',
    display: 'flex',
    alignItems: 'center',
    padding: '5px 20px 5px 10px',
    ':hover': {
      backgroundColor: rgbaColors.ORANGE_10,
    },
  },
  '.multiselect-card': {
    padding: 0,
    position: 'absolute',
    zIndex: '1000',
  },
  '.multiselect-button.selected': {
    color: fontColors.BLACK,
  },
  '.selected:after': {
    content: '""',
    position: 'absolute',
    top: '-15px',
    right: '-15px',
    width: '30px',
    height: '30px',
    transform: 'rotate(45deg)',
    backgroundColor: uiColors.BRAND,
  },
  '.multiselect-card-body': {
    padding: '4px 0',
    maxHeight: '15rem',
    overflow: 'auto',
  },
  '.multiselect-option label': {
    cursor: 'pointer',
  },
  '.multiselect-button': {
    position: 'relative',
    overflow: 'hidden',
    'text-align': 'left',
    backgroundColor: uiColors.WHITE,
    color: fontColors.GREY,
    border: `1px solid ${uiColors.GREY}`,
    borderRadius: '3px',
    padding: '.375rem .75rem',
    boxshadow: 'inset 0 1px 2px 0',
    ':hover': {
      border: `1px solid ${uiColors.DARK}`,
    },
    '&.multiselect-open': {
      marginBottom: 0,
      border: `1px solid ${rgbaColors.ORANGE_20}`,
    },
  },
};

class MultiSelector extends React.Component {
  static propTypes = {
    options: PropTypes.array,
  };

  static defaultProps = {
    options: [],
    values: [],
  };

  constructor(props) {
    super(props);
    this.thisComponentRef = React.createRef();

    this.state = {
      collapsed: true,
      options: props.options.map((o) => this.getOption(o, props.values)),
    };
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleCheckEvent = this.handleCheckEvent.bind(this);
  }

  objectsEqual(o1, o2) {
    return (
      Object.keys(o1).length === Object.keys(o2).length &&
      Object.keys(o1).every((p) => o1[p] === o2[p])
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const propsDefined = prevProps.options && this.props.options;
    if (
      propsDefined &&
      !this.objectsEqual(prevProps.options, this.props.options) &&
      this.state.options.every((o) => !o.checked)
    ) {
      this.setState({
        options: this.props.options.map((o) =>
          this.getOption(o, this.props.values),
        ),
      });
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  handleClickOutside(e) {
    const shouldClose = !(
      this.state.collapsed || this.thisComponentRef.current.contains(e.target)
    );
    shouldClose && this.toggle();
  }

  toggle() {
    this.setState({ collapsed: !this.state.collapsed });
  }

  handleCheckEvent(option, index) {
    let item = this.state.options[index];
    if (item) {
      item.checked = !item.checked;

      this.setState({
        options: [
          ...this.state.options.slice(0, index), // everything before current item
          item,
          ...this.state.options.slice(index + 1), // everything after current item
        ],
      });
      this.props.onChange(this.state.options.filter((o) => o.checked));
    }
  }

  getOptionText(checked, multiSelectedText) {
    return checked.length === 1
      ? checked[0].label
      : `${checked.length} ${multiSelectedText}`;
  }

  getOption(o, values) {
    return {
      value: o.value,
      label: o.label,
      checked: values.some((v) => v.value === o.value),
    };
  }

  render() {
    const { className, placeholder } = this.props;
    const isOpen = !this.state.collapsed;

    const checked = this.state.options.filter((element) => element.checked);
    return (
      <div css={styles} className={className} ref={this.thisComponentRef}>
        <Button
          className={[
            'multiselect-button w-100',
            isOpen ? 'multiselect-open' : '',
            checked.length !== 0 ? ' selected' : '',
          ]}
          onClick={this.toggle}
          variant="outline-light"
        >
          {placeholder}
        </Button>
        {!this.state.collapsed && (
          <Card className={'multiselect-card'}>
            <Card.Body className={'multiselect-card-body scroll-bar'}>
              {this.state.options &&
                this.state.options.map((o, i) => {
                  return (
                    <div
                      className={'multiselect-option'}
                      key={i}
                      onClick={() => this.handleCheckEvent(o, i)}
                    >
                      <Checkbox
                        checked={o.checked}
                        onChange={() => this.handleCheckEvent(o, i)}
                      >
                        <label>{o.label}</label>
                      </Checkbox>
                    </div>
                  );
                })}
            </Card.Body>
          </Card>
        )}
      </div>
    );
  }
}

export default MultiSelector;
