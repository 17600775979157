import * as ActionTypes from '../../constants/ActionTypes';

export const searchByName = (editorNameOrCaseName) => ({
  type: ActionTypes.API_DECISION_SEARCH_BY_EDITOR_NAME_OR_CASE_NAME_INIT,
  payload: { editorNameOrCaseName },
});
export const getById = (id) => ({
  type: ActionTypes.API_DECISION_GET_BY_ID_INIT,
  payload: { id },
});
export const getByIds = (ids) => ({
  type: ActionTypes.API_DECISION_GET_BY_IDS_INIT,
  payload: { ids },
});
export const getDecisions = (paging, sort, courts) => ({
  type: ActionTypes.API_DECISIONS_GET_INIT,
  payload: { paging, sort, courts },
});
export const uploadDecisionFile = (decisionId, file) => ({
  type: ActionTypes.API_UPLOAD_DECISION_FILE_INIT,
  payload: { decisionId, file },
});
export const createDecision = (decision) => ({
  type: ActionTypes.API_CREATE_DECISION_INIT,
  payload: { decision },
});
export const getLatest = () => ({
  type: ActionTypes.API_DECISIONS_GET_TOP_INIT,
  payload: {},
});
export const getRelationTreeById = (id) => ({
  type: ActionTypes.API_DECISION_GET_RELATION_TREE_BY_ID_INIT,
  payload: { id },
});
export const getRelatedById = (id) => ({
  type: ActionTypes.API_DECISION_GET_RELATED_BY_ID_INIT,
  payload: { id },
});
export const updateDecision = (decision) => ({
  type: ActionTypes.API_UPDATE_DECISION_INIT,
  payload: { decision },
});
