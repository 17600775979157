import * as EntityTypes from '../../constants/EntityTypes';
import * as CommonSelectors from '../entity/common';
import { List } from 'immutable';
import { FILTER_DOG_TAG } from '../../utils/filter';
import {
  STATE_ENTITY_PROPERTY,
  STATE_ROOT_PROPERTY,
} from '../../constants/StateProperties';
import * as Searches from '../../constants/Searches';

const getBasePath = (...path) => [
  STATE_ROOT_PROPERTY.api,
  EntityTypes.LAWPREFACEITEM,
  ...path,
];

export const getAll = (state) =>
  CommonSelectors.getAll(state, EntityTypes.LAWPREFACEITEM);
export const getById = (state, id) =>
  CommonSelectors.getEntity(state, EntityTypes.LAWPREFACEITEM, id);
export const getByIds = (state, lawPrefaceItemIds) =>
  List.of(
    ...(lawPrefaceItemIds || [])
      .map((id) => getById(state, Number(id)))
      .filter((v) => !!v),
  );
export const getSearchData = (state) =>
  state.getIn(['api', EntityTypes.LAWPREFACEITEM, 'searchdata'], {});
export const getFilterEntities = (state, filter = '{}') =>
  state.getIn([
    'api',
    EntityTypes.LAWPREFACEITEM,
    'page',
    FILTER_DOG_TAG.concat(filter),
    'data',
  ]);
export const getIsLoadingRelatedTaxonomies = (state) =>
  state.getIn(['api', EntityTypes.LAWPREFACEITEM, 'isLoadingRelatedLawItems']);

export const isNavigationLoadedForLaw = (state, lawId) => {
  const searchTag = FILTER_DOG_TAG.concat(
    JSON.stringify(Searches.findByLaw([lawId]).criteria),
  );
  return !!state.getIn(getBasePath(STATE_ENTITY_PROPERTY.page, searchTag));
};
