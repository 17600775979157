import { fromJS } from 'immutable';
import * as ActionTypes from '../../constants/ActionTypes';
import * as EntityTypes from '../../constants/EntityTypes';
import {
  mergeEntityListToState,
  mergeResponseToState,
  mergeSingleResponseToState,
} from './index';
import { getResponseData } from '../../selectors/actions';
import { STATE_ENTITY_PROPERTY } from '../../constants/StateProperties';
import { createTagName } from '../../utils/filter';

const initialState = fromJS({
  [STATE_ENTITY_PROPERTY.data]: {},
  [STATE_ENTITY_PROPERTY.searchdata]: {},
  [STATE_ENTITY_PROPERTY.page]: {},
  [STATE_ENTITY_PROPERTY.theoryItemTypes]: [],
});

export default function theoryItemApiReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.API_THEORY_ITEM_GET_TOP_SUCCESS:
    case ActionTypes.API_THEORY_ITEM_GET_ALL_BY_URI_SUCCESS:
    case ActionTypes.API_THEORY_ITEM_GET_RELATION_BY_ID_SUCCESS:
    case ActionTypes.API_THEORY_ITEM_GET_ALL_CHILDREN_SUCCESS:
    case ActionTypes.API_THEORY_ITEM_GET_BY_IDS_SUCCESS:
      return mergeResponseToState(state, action, EntityTypes.THEORYITEM);
    case ActionTypes.API_THEORY_ITEM_GET_BY_ID_WITH_PARENTS_SUCCESS:
    case ActionTypes.API_THEORY_ITEM_GET_RELATION_TREE_BY_ID_SUCCESS:
      return mergeSingleResponseToState(state, action, EntityTypes.THEORYITEM);
    case ActionTypes.API_GET_RELATION_TREE_THEORY_ITEMS:
      return mergeEntityListToState(
        state,
        action.payload,
        EntityTypes.THEORYITEM,
      );
    case ActionTypes.API_FIND_THEORY_ITEM_BY_TITLE_SUCCESS:
      let responseData =
        getResponseData(action)['_embedded'][EntityTypes.THEORYITEM];
      const searchKey = createTagName(action.payload.initAction.payload.title);
      return state.setIn(
        [STATE_ENTITY_PROPERTY.searchdata, searchKey],
        fromJS(responseData),
      );
    case ActionTypes.API_THEORY_ITEM_GET_LATEST_SUCCESS:
      return mergeResponseToState(state, action, EntityTypes.THEORYITEM, true);
    case ActionTypes.API_GET_ALL_THEORY_ITEM_TYPES_SUCCESS:
      const data = getResponseData(action);
      return state.set(STATE_ENTITY_PROPERTY.theoryItemTypes, fromJS(data));
    case ActionTypes.API_THEORY_ITEM_DELETE_BY_ID_SUCCESS:
    case ActionTypes.API_CREATE_THEORY_ITEM_SUCCESS:
    case ActionTypes.AUTH_LOGIN_COMPLETE:
    case ActionTypes.AUTH_LOGOUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
}
