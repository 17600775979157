import { call, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import TheoryItemConnector from '../../../connectors/TheoryItem';
import { generateApiSaga } from '../../../utils/sagas';

const doCreate = generateApiSaga(
  TheoryItemConnector,
  function* (connector, action) {
    return yield call([connector, connector.createItem], action.payload.item);
  },
);

export default function* createTheoryItem() {
  yield takeEvery(ActionTypes.API_CREATE_THEORY_ITEM_INIT, doCreate);
}
