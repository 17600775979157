/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { Trans } from 'react-i18next';
import { uiColors } from '../common/styles/styles';

const styles = ({ itemWidth }) => ({
  display: 'flex',
  flexDirection: 'row',
  fontSize: '12px',
  borderBottom: `1.5px solid ${uiColors.BLACK}`,
  fontWeight: 'bold',

  '.chart-header-item': {
    textAlign: 'center',
    minWidth: itemWidth + 'px',
    fontWeight: 'bold',
  },
  '.long-time-slot': {
    borderRight: '1px solid',
    '&:last-child': {
      border: 'none',
    },
  },
});

class GanttChartHeader extends React.Component {
  render() {
    const {
      startDay,
      endDay,
      chartInterval,
      itemWidth,
      leftSideColumnRef,
      timeLabelKey,
      id,
    } = this.props;

    let rows = [];
    const offset = chartInterval >= 7 ? 1 : 0;
    for (let _i = startDay; _i <= endDay; _i = _i + chartInterval) {
      rows.push(
        <div
          className={`chart-header-item text-uppercase${
            chartInterval >= 14 && ' long-time-slot'
          }`}
          key={_i}
        >
          <Trans i18nKey={timeLabelKey} />{' '}
          {Math.floor(_i / chartInterval) === 0
            ? 1
            : Math.floor(_i / chartInterval) + offset}
        </div>,
      );
    }
    return (
      <div css={styles({ itemWidth })}>
        <div
          key={`key-${id}`}
          ref={leftSideColumnRef}
          className={`align-items-center text-uppercase gant-first-column ${id}`}
        >
          <Trans i18nKey="gantt.phase" />
        </div>
        {rows}
      </div>
    );
  }
}

export default GanttChartHeader;
