import ItemConnector from './Item';
import * as Searches from '../constants/Searches';
import * as Projections from '../constants/Projections';
import * as EntityTypes from '../constants/EntityTypes';

export default class LawPrefaceItemConnector extends ItemConnector {
  constructor(...args) {
    super(...args);
    this.entityType = EntityTypes.LAWPREFACEITEM;
  }

  getAll(pageSize, page) {
    const query = this.query()
      .withProjection(Projections.WITHID)
      .withSize(pageSize)
      .withPage(page)
      .build();

    return this.authGet(query);
  }

  getAllByLaw(lawId, pageSize, page) {
    const query = this.query()
      .withSearch(Searches.findByLaw, [lawId])
      .withProjection(Projections.WITH_LAW_ID)
      .withSize(pageSize)
      .withPage(page)
      .build();
    return this.authGet(query);
  }

  getByUri(uri) {
    const query = this.query()
      .withSearch(Searches.findByUri, [uri])
      .withProjection(Projections.WITH_LAW_ID)
      .build();
    return this.authGet(query);
  }

  getById(id, projection) {
    const query = this.query().withId(id).withProjection(projection).build();
    return this.authGet(query);
  }

  getByIds(ids) {
    const query = this.query()
      .withSearch(Searches.findByIds, [ids])
      .withSize(1000)
      .build();

    return this.authGet(query);
  }
}
