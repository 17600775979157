import * as ActionTypes from '../../../constants/ActionTypes';
import { generateApiSaga } from '../../../utils/sagas';
import { call, takeEvery } from 'redux-saga/effects';
import LicenseConnector from '../../../connectors/License';

const doFetch = generateApiSaga(LicenseConnector, function* (connector) {
  return yield call([connector, connector.getAll]);
});

export default function* getAll() {
  yield takeEvery(ActionTypes.API_LICENSE_GET_ALL_INIT, doFetch);
}
