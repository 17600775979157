import { put } from 'redux-saga/effects';
import { push } from 'connected-react-router/immutable';
import { takeLatestWhenAppReady } from '../../../utils/sagas';
import * as ActionTypes from '../../../constants/ActionTypes';
import * as adminHomeActions from '../../../actions/page/adminhome';
import * as routerActionSelector from '../../../selectors/actions/router';
import { buildPageLink } from '../../../utils/link';
import * as Urls from '../../../constants/Urls';
import { ADMIN_HOME_TABS } from '../../../constants/AdminHomeTabs';

function* doLocationChange(action) {
  const pageSegment = routerActionSelector.getPageSegment(action);
  if (pageSegment === Urls.ADMIN_HOME_PAGE_SEGMENT) {
    const pathSegments = routerActionSelector.getSplitPath(action);
    if (pathSegments.length === 1) {
      yield put(
        push(buildPageLink(Urls.ADMIN_HOME_ROUTE, ADMIN_HOME_TABS.LICENSE)),
      );
    } else {
      const [page, ...uri] = pathSegments;
      yield put(adminHomeActions.pageLoad(uri));
    }
  }
}

export default function* locationChange() {
  yield takeLatestWhenAppReady(
    ActionTypes.APP_ROUTER_LOCATION_CHANGE,
    doLocationChange,
  );
}
