/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { kebabizeCamelCase } from '../../../utils/stringHelper';
import { refBind } from '../../../utils/ref-bind';
import { Trans, withTranslation } from 'react-i18next';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import GenericForm from '../molecules/GenericForm';
import PropTypes from 'prop-types';
import {
  QuickFilters,
  SearchFilters,
} from '../../../constants/UserOverviewFilterTypes';

class UserOverviewFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        quickFilter: QuickFilters.ALL,
        searchFilters: Object.values(SearchFilters).reduce(
          (obj, value) => ({ ...obj, [value]: '' }),
          {},
        ),
      },
      disabled: this.props.disabled,
      csvFileName: undefined,
    };
    refBind(this, 'setQuickFilter', 'setSearchFilters', 'renderToggleButtons');
  }

  static propTypes = {
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    renderDownloadButton: PropTypes.func,
  };

  static defaultProps = {
    disabled: false,
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.disabled !== prevProps.disabled) {
      this.setState({ disabled: this.props.disabled });
    }
  };

  setQuickFilter = (value) => {
    const newFilters = { ...this.state.filters, quickFilter: value };
    this.setState({ filters: newFilters });
    this.props.onChange && this.props.onChange(newFilters);
  };

  setSearchFilters = (_, { target: { name, value } }) => {
    const newFilters = {
      ...this.state.filters,
      searchFilters: { ...this.state.filters.searchFilters, [name]: value },
    };
    this.setState({ filters: newFilters });
    this.props.onChange && this.props.onChange(newFilters);
  };

  renderToggleButtons = (quickFilters) =>
    quickFilters.map((value) => {
      const key = kebabizeCamelCase(value);
      return (
        <ToggleButton
          key={value}
          id={`radio-${key}`}
          type="radio"
          value={value}
          checked={value === this.state.filters.quickFilter}
          onChange={(e) => this.setQuickFilter(e.currentTarget.value)}
        >
          <Trans i18nKey={`user-overview-filter.quick-filters.${key}`} />
        </ToggleButton>
      );
    });

  render() {
    const quickFilters = Object.values(QuickFilters);
    const searchFiltersForm = {
      form_name: 'user-overview-filter.search-filters',
      form: Object.keys(this.state.filters.searchFilters).reduce(
        (obj, value) => ({
          ...obj,
          [value]: { value: '', type: 'text', disabled: this.state.disabled },
        }),
        {},
      ),
    };

    return (
      <>
        <div className="mb-3 d-flex justify-content-between">
          <ButtonGroup toggle>
            {this.renderToggleButtons(quickFilters)}
          </ButtonGroup>
          {this.props.renderDownloadButton && this.props.renderDownloadButton()}
        </div>
        <GenericForm
          form={searchFiltersForm}
          numberOfColumns={2}
          onChange={this.setSearchFilters}
        />
      </>
    );
  }
}

export default withTranslation()(UserOverviewFilter);
