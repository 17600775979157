import { all, fork } from 'redux-saga/effects';
import lawSaga from './law';
import lawItemSaga from './lawitem';
import jurisdictionSaga from './jurisdiction';
import theorySaga from './theory';
import theoryItemSaga from './theoryitem';
import decisionSaga from './decision';
import processSaga from './process';
import getAllSaga from './getall';
import apiCourtSaga from './court';
import taxonomyRootSaga from './taxonomy';
import searchSaga from './search';
import appUserConsentSaga from './appuserconsent';
import favoriteSaga from './favorite';
import signupSaga from './signup';
import apiCompanySaga from './company';
import apiRoleSaga from './role';
import apiLicenseSaga from './license';
import apiAppUserSaga from './appuser';
import handleDataPatchAction from './patchentity';
import apiRelationSaga from './relation';
import apiPermissionSaga from './permission';
import apiLawPrefaceItemSaga from './lawprefaceitem';
import lawGroupSaga from './lawgroup';
import unsubscribeRootSaga from './unsubscribe';
import newsletterRootSaga from './newsletter';
import apiNewsworthySagas from './newsworthy';

export default function* apiRootSaga() {
  yield all([
    fork(jurisdictionSaga),
    fork(apiCourtSaga),
    fork(lawSaga),
    fork(lawItemSaga),
    fork(theorySaga),
    fork(theoryItemSaga),
    fork(decisionSaga),
    fork(processSaga),
    fork(getAllSaga),
    fork(taxonomyRootSaga),
    fork(searchSaga),
    fork(appUserConsentSaga),
    fork(signupSaga),
    fork(favoriteSaga),
    fork(apiCompanySaga),
    fork(apiRoleSaga),
    fork(apiLicenseSaga),
    fork(apiAppUserSaga),
    fork(handleDataPatchAction),
    fork(apiRelationSaga),
    fork(apiPermissionSaga),
    fork(apiLawPrefaceItemSaga),
    fork(lawGroupSaga),
    fork(newsletterRootSaga),
    fork(apiNewsworthySagas),
    fork(unsubscribeRootSaga),
  ]);
}
