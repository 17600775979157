import { fromJS } from 'immutable';
import * as ActionTypes from '../../constants/ActionTypes';
import SearchFilter from '../../utils/searchfilter';
import * as SearchTabs from '../../constants/SearchTabs';

const initialState = fromJS({
  searchFilter: new SearchFilter(),
  latestSearchResult: null,
  latestSearchError: null,
  tab: SearchTabs.THEORY,
  isSearchRunningForCurrentFilter: false,
});

export default function searchPageReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.PAGE_SEARCH_FILTER_UPDATE_SET: {
      return state.merge(
        fromJS({
          searchFilter: action.payload.filter,
        }),
      );
    }

    case ActionTypes.PAGE_SEARCH_SEARCH_ACTIVE: {
      return state.merge(
        fromJS({
          isSearchRunningForCurrentFilter: true,
        }),
      );
    }

    case ActionTypes.PAGE_SEARCH_SEARCH_SUCCESS: {
      return state.merge(
        fromJS({
          latestSearchResult: action.payload.searchResult,
          latestSearchError: null,
          isSearchRunningForCurrentFilter: false,
        }),
      );
    }

    case ActionTypes.PAGE_SEARCH_SEARCH_ERROR: {
      return state.merge(
        fromJS({
          latestSearchResult: null,
          latestSearchError: {
            message: action.payload.message,
          },
          isSearchRunningForCurrentFilter: false,
        }),
      );
    }

    case ActionTypes.PAGE_SEARCH_SEARCH_ABORT: {
      return state.merge(
        fromJS({
          latestSearchResult: null,
          latestSearchError: null,
          isSearchRunningForCurrentFilter: false,
        }),
      );
    }

    case ActionTypes.PAGE_SEARCH_TAB_CHANGE_SET: {
      return state.merge(
        fromJS({
          tab: action.payload.tab,
        }),
      );
    }

    default:
      return state;
  }
}
