import * as ActionTypes from '../../../constants/ActionTypes';
import { generateApiSaga } from '../../../utils/sagas';
import { call, takeEvery } from 'redux-saga/effects';
import CompanyConnector from '../../../connectors/Company';

const doGetAllCompany = generateApiSaga(
  CompanyConnector,
  function* (connector) {
    return yield call([connector, connector.getAll]);
  },
);

export default function* getAll() {
  yield takeEvery(ActionTypes.API_GET_ALL_COMPANIES_INIT, doGetAllCompany);
}
