import { callAndAwaitApi } from '../../../utils/sagas';
import * as ActionTypes from '../../../constants/ActionTypes';
import * as dataActions from '../../../actions/data';
import * as relationsApiAction from '../../../actions/api/relation';
import { put, takeEvery } from 'redux-saga/effects';
import * as EntityTypes from '../../../constants/EntityTypes';
import * as lawItemApiActions from '../../../actions/api/lawitem';
import * as lawPrefaceItemApiActions from '../../../actions/api/lawprefaceitem';
import * as theoryItemApiActions from '../../../actions/api/theoryitem';
import * as processStepApiActions from '../../../actions/api/processstep';
import * as decisionApiActions from '../../../actions/api/decision';
import {
  EntityRenameLinksMap,
  getPropsToEdit,
} from '../../../utils/relationhelper';

const refreshAction = {
  [EntityTypes.LAWITEM]: lawItemApiActions.getByIds,
  [EntityTypes.LAWPREFACEITEM]: lawPrefaceItemApiActions.getByIds,
  [EntityTypes.THEORYITEM]: theoryItemApiActions.getByIds,
  [EntityTypes.PROCESSSTEP]: processStepApiActions.getByIds,
  [EntityTypes.DECISION]: decisionApiActions.getByIds,
};

function* doUpdate(action) {
  const node = action.payload.node;
  const { updatedText, links, jurisdiction } = action.payload.updates;
  let patch = {};
  if (links) {
    patch = EntityRenameLinksMap[node.entityType]
      ? Object.keys(links).reduce(
          (sum, key) => ({
            [EntityRenameLinksMap[node.entityType][key] || key]: links[key],
            ...sum,
          }),
          {},
        )
      : links;
  }
  patch[getPropsToEdit(node)] = updatedText;
  if (node.entityType === EntityTypes.THEORYITEM) {
    patch.jurisdiction = `/${jurisdiction.value}`;
  }
  yield callAndAwaitApi(dataActions.patch(node, patch));
  action.payload.handler && action.payload.handler();
  yield put(refreshAction[node.entityType]([node.id]));
  if (node.entityType !== EntityTypes.LAWPREFACEITEM) {
    yield put(relationsApiAction.updateEntity(node.id, node.entityType));
  }
}

export default function* updateNode() {
  yield takeEvery(ActionTypes.APP_PAGE_ADMIN_UPDATE_NODE, doUpdate);
}
