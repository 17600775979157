import * as EntityTypes from './EntityTypes';

export const NODE = 'node';

const JURISDICTION = {
  URI: 'uri',
};

const LAW = {
  TITLE: 'name',
  URI: 'uri',
  FROM_DATE: 'fromDate',
};

const LAW_GROUP = {
  LABEL: 'label',
};

const LAW_ITEM = {
  TITLE: 'uri',
  URI: 'uri',
  LAW_URI: 'lawUri',
  PARENT: 'parentLawItemId',
  TOP_ID: 'lawId',
  CHANGED_BY: 'changedBy',
  CHANGING: 'changing',
  REPLACED_BY: 'replacedBy',
  REPLACING: 'replacing',
  JURISDICTION_BY: 'jurisdictionBy',
  JURISDICTION: 'jurisdiction',
  RELATED_LAW_PREFACE_ITEMS: 'relatedLawPrefaceItems',
  RELATED_DECISIONS: 'relatedDecisions',
};

const LAW_PREFACE_ITEM = {
  TITLE: 'uri',
  URI: 'uri',
  RELATED_LAW_ITEMS: 'relatedLawItems',
  LFFKAT: 'lffkat',
  LFFO1: 'lffo1',
  LFF02: 'lff02',
  LFF03: 'lff03',
  LFF04: 'lff04',
  LFFPAR: 'lffpar',
  LFFNR: 'lffnr',
  LFFPKT: 'lffpkt',
};

const PROCESS = {
  URI: 'uri',
};

const PROCESSSTEP = {
  URI: 'uri',
};

const THEORY_ITEM = {
  TITLE: 'title',
  URI: 'uri',
  PARENT_ID: 'parentTheoryItemId',
  PARENT: 'parentTheoryItem',
  PARENTS: 'parentTheoryItems',
  CHILDREN: 'childTheoryItems',
  TOP_ID: 'theoryId',
  RELATED_LAW_ITEMS: 'relatedLawItems',
};

const THEORY = {
  TITLE: 'title',
  URI: 'title',
};

const DECISION = {
  RULING_DATE: 'rulingDate',
  EDITOR_NAME: 'editorName',
};

const EntityFields = {};
EntityFields[EntityTypes.JURISDICTION] = JURISDICTION;
EntityFields[EntityTypes.THEORY] = THEORY;
EntityFields[EntityTypes.THEORYITEM] = THEORY_ITEM;
EntityFields[EntityTypes.LAW] = LAW;
EntityFields[EntityTypes.LAWGROUP] = LAW_GROUP;
EntityFields[EntityTypes.LAWITEM] = LAW_ITEM;
EntityFields[EntityTypes.LAWPREFACEITEM] = LAW_PREFACE_ITEM;
EntityFields[EntityTypes.PROCESS] = PROCESS;
EntityFields[EntityTypes.PROCESSSTEP] = PROCESSSTEP;
EntityFields[EntityTypes.DECISION] = DECISION;

export default EntityFields;
