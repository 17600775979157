/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import LicenseCard from '../molecules/LicenseCard';
import { uiColors } from '../styles/styles';

const capabilitiesChildrenProp = PropTypes.shape({
  text: PropTypes.string,
});

capabilitiesChildrenProp.children = PropTypes.arrayOf(capabilitiesChildrenProp);

const capabilitiesProp = PropTypes.shape({
  class: PropTypes.string,
  text: PropTypes.string,
  children: PropTypes.arrayOf(capabilitiesChildrenProp),
  videoTutorialId: PropTypes.string,
});

const cardInfoPropTypes = {
  cardInfos: PropTypes.arrayOf(
    PropTypes.shape({
      headline: PropTypes.string,
      subtitle: PropTypes.string,
      subSubtitle: PropTypes.string,
      imgSrc: PropTypes.string,
      capabilities: PropTypes.arrayOf(capabilitiesProp),
    }),
  ),
};

const cardInfoDefaultProps = {
  cardInfos: [],
};

// The following values have been extracted from the slim design
// seen on the profile page.
const fixedCapabilityHeights = [
  '144px',
  '255px',
  '96px',
  '168px',
  '125px',
  '87px',
  '120px',
  '168px',
  '120px',
  '149px',
  '48px',
  '111px',
  '135px',
  '154px',
  '149px',
  '149px',
  '130px',
  '48px',
  '240px',
  '111px',
  '48px',
];

const styles = {
  // styling hover of listgroup if child .capability exists
  'div.card:not(.no-styling)': {
    '& li.list-group-item': {
      '&:has(div.capability):hover': {
        backgroundColor: 'rgb(244,246,251)',
        color: uiColors.BRAND,
        cursor: 'pointer',
      },
    },
  },
  '.keyword': {
    fontVariant: 'small-caps',
    fontWeight: 500,
  },
  '& .body-text': {
    marginTop: '2em',
  },
  '& .sub-headline': {
    marginTop: '1em',
  },
  '& .list-group-flush>.list-group-item': {
    borderWidth: '0px',
    paddingRight: '0',
  },
  '& .licenses': {
    marginTop: '3em',
    '.card': {
      height: '100%',
      '.card-body': {
        padding: '0',
        paddingTop: '.5em',
        paddingRight: '0',
        '.card-title': {
          paddingBottom: '2em',
          textAlign: 'center',
        },
      },
      '.card-img-top': {
        marginTop: '2em',
        marginBottom: '1em',
        marginRight: 'auto',
        marginLeft: 'auto',
        width: '30%',
      },
      '.capability': {
        paddingLeft: '1.25em',
        ul: {
          paddingInlineStart: '0px',
          listStyle: 'none',
        },
        '.dot': {
          listStyle: 'none',
          textTransform: 'uppercase',
          fontWeight: 700,
        },
        '.dot:before': {
          color: uiColors.BRAND,
          display: 'inline-block',
          width: '1.5em',
          textAlign: 'right',
          marginLeft: '-2.0em',
          marginRight: '0.5em',
          fontWeight: 800,
          fontSize: '1em',
          verticalAlign: 'top',
          lineHeight: '22px',
        },
        '.dot_big:before': {
          content: '"•"',
          display: 'inline-block',
          width: '0.6em',
          marginLeft: '-0.7em',
          marginRight: '0.1em',
          fontWeight: 800,
          fontSize: '4em',
          verticalAlign: 'top',
          lineHeight: '0px',
        },
        '.dot_1_small:before': {
          content: '"•"',
        },
        '.dot_2_small:before': {
          content: '"••"',
        },
        '.dot_3_small:before': {
          content: '"•••"',
        },
        'ul > ul > ul > ul > li': {
          color: 'initial',
          fontVariant: 'initial',
          fontSize: '0.8em',
          fontWeight: 'initial',
          marginLeft: '1.0em',
        },
        'ul > ul > ul > ul > li:before': {
          // Third level content
          content: '"–"',
          color: 'initial',
          display: 'inline-block',
          width: '1em',
          marginLeft: '-1.0em',
          fontSize: '1em',
          fontWeight: 'initial',
          verticalAlign: 'initial',
          lineHeight: 'initial',
        },
      },
    },
  },
  '.color-header': {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    borderTop: '1px solid rgba(0,0,0,.125)',
    color: 'white',
    padding: '.25rem 0.5rem',
    '.vat': {
      fontSize: '0.7rem',
    },
    '&.color-0': {
      backgroundColor: uiColors.LICENSE_BLUE_ANONYMOUS,
    },
    '&.color-1': {
      backgroundColor: uiColors.LICENSE_BLUE_REGULAR,
    },
    '&.color-2': {
      backgroundColor: uiColors.LICENSE_BLUE_NAVIGATOR,
    },
    '&.color-3': {
      backgroundColor: uiColors.LICENSE_BLUE_ANALYST,
    },
  },
  '.license-column': {
    display: 'flex',
    justifyContent: 'center',
  },
};

class CardInfo extends Component {
  render() {
    const { cardInfos } = this.props;
    return (
      <div css={styles}>
        {cardInfos.length > 0 && (
          <div className="licenses">
            <Row xs={1} md={2} lg={4}>
              {cardInfos.map((cardInfo, cardIndex) => (
                <Col key={cardIndex} className="license-column">
                  <LicenseCard
                    cardInfo={cardInfo}
                    cardIndex={cardIndex}
                    heights={fixedCapabilityHeights}
                    // this is temporarily disabled while we dont have correct links for vimeo videos
                    // it adds the hover effect to the list-group-item (when false)
                    // for now we always set to true (no hover effect)
                    // noStyling={cardIndex === 0}
                    noStyling={true}
                  />
                </Col>
              ))}
            </Row>
          </div>
        )}
      </div>
    );
  }
}

CardInfo.propTypes = cardInfoPropTypes;

CardInfo.defaultProps = cardInfoDefaultProps;

export default CardInfo;
