import { call, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import TheoryItemConnector from '../../../connectors/TheoryItem';
import { generateApiSaga } from '../../../utils/sagas';
import { getEntityId } from '../../../utils/entities';

var doGetAllSections = generateApiSaga(
  TheoryItemConnector,
  function* (connector, action) {
    let theoryId = getEntityId(action.payload.theory);
    return yield call([connector, connector.getAllSections], theoryId);
  },
);

export default function* getAllSections() {
  yield takeEvery(
    ActionTypes.API_THEORY_ITEM_GET_ALL_SECTIONS_INIT,
    doGetAllSections,
  );
}
