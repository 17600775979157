import BaseConnector from './Base';
import * as EntityTypes from '../constants/EntityTypes';
import * as Projecitons from '../constants/Projections';

export default class LawGroupConnector extends BaseConnector {
  constructor(...args) {
    super(...args);
    this.entityType = EntityTypes.LAWGROUP;
  }

  // Part of the application assumes this is the only way to retrieve Law Groups. Please keep this in mind when adding new methods to this connector.
  getAll() {
    return this.authGet(
      this.query().withProjection(Projecitons.WITHID).withSize(250).build(),
    );
  }
}
