/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import TreeItemLeaf, {
  BranchPropType,
  renderRelatedItems,
} from './TreeItemLeaf';
import FoldButton from '../common/atoms/FoldButton';
import Text, { TextType } from '../common/atoms/Text';
import { refBind } from '../../utils/ref-bind';
import { sort } from '../../utils/sort';
import ControlledByPermission from '../../containers/shared/ControlledByPermission';
import * as EntityTypes from '../../constants/EntityTypes';
import { TreeTheoryItemWrapperTypes } from '../../constants/ItemTypes';
import { isEqual } from 'lodash';
import { getNavTreeLabel } from '../../utils/treehelpers';
import { Link } from 'react-router-dom';
import { getNodeLink } from '../../utils/link';

class TreeItem extends Component {
  constructor(props) {
    super(props);

    this.state = { showChildren: this.props.expandLevel > this.props.level };
    refBind(
      this,
      'handleClick',
      'renderChildren',
      'renderTreeItem',
      'renderLabel',
      'renderDynamicContent',
      'handleNodeSelect',
    );
  }

  isNodeOrChildSelected() {
    const { branch, selectedNode } = this.props;
    const uri = selectedNode?.node?.uri;
    const { children } = branch;
    return (
      uri &&
      (uri === branch.node.uri || this.recursiveFindSelectedNode(children, uri))
    );
  }

  componentDidMount() {
    const isNodeOrChildSelected = this.isNodeOrChildSelected();
    if (isNodeOrChildSelected) {
      this.setState({ showChildren: true });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const isNodeOrChildSelected = this.isNodeOrChildSelected();
    if (
      (!isEqual(prevProps.selectedNode, this.props.selectedNode) ||
        !isEqual(prevProps.branch.children, this.props.branch.children)) &&
      prevState.showChildren !== true &&
      isNodeOrChildSelected
    ) {
      this.setState({ showChildren: true });
    }
  }

  handleClick(branch) {
    this.props.onNodeExpand &&
      this.props.onNodeExpand(branch, !this.state.showChildren);
    this.setState({ showChildren: !this.state.showChildren });
  }

  handleNodeSelect(branch) {
    this.props.onNodeSelection && this.props.onNodeSelection(branch);
    this.setState({ showChildren: true });
  }

  renderTreeItem(children, selectedNode, showChildren, isChildSelected) {
    const {
      branch,
      parents,
      onNodeExpand,
      onTemplateRef,
      isArticle,
      expandLevel,
      level,
      expandedNodeUri,
      noHover,
      linkHighlightedNodes,
    } = this.props;

    const entityTypeSort = children[0]?.node.entityType || '';
    return sort(children, entityTypeSort).map((child) => (
      <li
        className={`li ${
          isChildSelected || showChildren ? 'show-list-item' : 'hide-list-item'
        }`}
        key={child.node.id}
        id={`tree-${child.node.id}`}
      >
        <TreeItem
          entityType={child.node.entityType}
          branch={child}
          parents={[branch, ...parents]}
          selectedNode={selectedNode}
          onNodeSelection={this.handleNodeSelect}
          isChildSelected={isChildSelected}
          isArticle={isArticle}
          countPermission={this.props.countPermission}
          onNodeExpand={onNodeExpand}
          onTemplateRef={onTemplateRef}
          expandLevel={expandLevel}
          level={level + 1}
          expandedNodeUri={expandedNodeUri}
          noHover={noHover}
          linkHighlightedNodes={linkHighlightedNodes}
        />
      </li>
    ));
  }

  renderLabel(
    branch,
    parents,
    disableFoldButton,
    showChildren,
    isNodeSelected,
    onNodeSelection,
  ) {
    const { isArticle, noHover, linkHighlightedNodes } = this.props;
    const { id } = branch.node;
    const layout = isArticle
      ? 'list-header-article-layout'
      : 'list-header-layout';
    let displayText = getNavTreeLabel(branch.node);
    if (linkHighlightedNodes && branch.highlight) {
      displayText = (
        <Link to={getNodeLink(branch, parents)}>{displayText}</Link>
      );
    }
    return (
      <div
        className={`children-node-wrapper ${
          !noHover && 'li-text'
        } ${layout} ${id}`}
      >
        <div className="d-flex flex-column">
          <FoldButton
            disabled={disableFoldButton}
            isOpen={showChildren}
            className="fold-button"
            onClick={() => this.handleClick(branch)}
          />
          <div className={showChildren ? 'fold-button-extender' : ''} />
        </div>

        <div
          className={`w-100 ${isNodeSelected && 'active-node'}`}
          onClick={() => onNodeSelection(branch)}
        >
          <Text type={TextType.NAVIGATION_TREE_CHILD}>{displayText}</Text>
        </div>
        {isArticle && (
          <ControlledByPermission
            permission={this.props.countPermission}
            render={renderRelatedItems(branch.node, isNodeSelected)}
          />
        )}
      </div>
    );
  }

  renderChildren() {
    const { showChildren } = this.state;
    const { branch, parents, selectedNode } = this.props;
    const { children, node } = branch;
    const isNodeOrChildSelected = this.isNodeOrChildSelected();
    const expandChildren = showChildren ?? isNodeOrChildSelected;
    const disableFoldButton = isNodeOrChildSelected;
    const isNodeSelected = this.isNodeSelected(selectedNode, branch);
    return (
      <Fragment>
        {this.renderLabel(
          branch,
          parents,
          false,
          expandChildren,
          isNodeSelected,
          this.handleNodeSelect,
        )}
        <ul className={`${node.id} ${expandChildren ? 'ul-expanded' : 'ul'}`}>
          {expandChildren &&
            this.renderTreeItem(
              children,
              selectedNode,
              showChildren,
              disableFoldButton,
            )}
        </ul>
      </Fragment>
    );
  }

  renderLeaf() {
    const {
      branch,
      parents,
      selectedNode,
      onNodeSelection,
      isArticle,
      noHover,
      linkHighlightedNodes,
    } = this.props;
    let isNodeActive = this.isNodeSelected(selectedNode, branch);

    let displayText = getNavTreeLabel(branch.node);
    if (linkHighlightedNodes && branch.highlight) {
      displayText = (
        <Link to={getNodeLink(branch, parents)}>{displayText}</Link>
      );
    }
    return (
      <div
        className={`li-leaf-node '${!noHover && 'li-text'} w-100 ${
          isNodeActive ? 'active-node' : ''
        } ${branch.node.id}`}
        onClick={() => onNodeSelection(branch)}
      >
        <div className={isArticle ? 'leaf-article-layout' : 'leaf-layout'}>
          <Text type={TextType.NAVIGATION_TREE_CHILD}>{displayText}</Text>
          {isArticle && (
            <ControlledByPermission
              permission={this.props.countPermission}
              render={this.renderRelatedItems(branch.node)}
            />
          )}
        </div>
      </div>
    );
  }

  renderDynamicContent() {
    let { showChildren } = this.state;
    const { branch, parents, onNodeSelection, onTemplateRef, expandedNodeUri } =
      this.props;
    showChildren =
      showChildren || (expandedNodeUri && expandedNodeUri === branch.node.uri);
    return (
      <div className={`dynamic-content ${branch.node.id}`}>
        {this.renderLabel(
          branch,
          parents,
          false,
          showChildren,
          false,
          onNodeSelection,
        )}
        <div
          className={`${showChildren ? 'show-list-item' : 'hide-list-item'}`}
        >
          {onTemplateRef(branch)}
        </div>
      </div>
    );
  }

  recursiveFindSelectedNode(children, uri) {
    return (children || []).some(
      (child) =>
        child.node.uri === uri ||
        this.recursiveFindSelectedNode(child.children, uri),
    );
  }

  isNodeSelected(selectedNode, currentNode) {
    if (selectedNode && currentNode) {
      return (
        selectedNode?.node?.uri === currentNode?.node?.uri ??
        selectedNode?.node?.id === currentNode?.node?.id
      );
    }
    return false;
  }

  render() {
    const {
      branch,
      parents,
      entityType,
      isArticle,
      onNodeSelection,
      selectedNode,
      noHover,
      linkHighlightedNodes,
    } = this.props;
    const isDynamicContent = !!branch.useTemplateRef;
    const doRenderChildren =
      !!branch?.children?.length ||
      entityType === EntityTypes.LAWPREFACEITEM ||
      entityType === EntityTypes.PROCESS ||
      (entityType === EntityTypes.LAWITEM &&
        branch.node.h5 === branch.node.id) ||
      (entityType === EntityTypes.THEORYITEM &&
        TreeTheoryItemWrapperTypes.some(
          (type) => type === branch?.node?.theoryItemType,
        ));

    let result;
    if (isDynamicContent) {
      result = this.renderDynamicContent();
    } else if (doRenderChildren) {
      result = this.renderChildren();
    } else {
      result = (
        <TreeItemLeaf
          isNodeActive={this.isNodeSelected(selectedNode, branch)}
          isArticle={isArticle}
          onNodeSelection={onNodeSelection}
          branch={branch}
          parents={parents}
          noHover={noHover}
          linkHighlightedNodes={linkHighlightedNodes}
        />
      );
    }
    return result;
  }
}

TreeItem.propTypes = {
  branch: BranchPropType,
  selectedNode: PropTypes.object,
  onNodeSelection: PropTypes.func,
  onNodeExpand: PropTypes.func,
  onTemplateRef: PropTypes.func,
  isArticle: PropTypes.bool,
  expandLevel: PropTypes.number,
  level: PropTypes.number,
};

export default TreeItem;
