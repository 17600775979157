import * as EntityTypes from '../../constants/EntityTypes';
import TaxonomyType from '../../constants/TaxonomyType';
import { List } from 'immutable';
import { createSelector } from 'reselect';

export const getAll = (state) =>
  state.getIn(['api', EntityTypes.TAXONOMY, 'data']);
export const getAllKeywords = createSelector(getAll, (allTaxonomies) =>
  allTaxonomies.filter(
    (x) =>
      x.getIn(['node', 'type']) === TaxonomyType.KEYWORD &&
      x.getIn(['node', 'description']),
  ),
);
export const getById = (state, id) =>
  state.getIn(['api', EntityTypes.TAXONOMY, 'data', id]);
export const getByIds = (state, ids) =>
  List.of(...(ids || []))
    .map((id) => getById(state, Number(id)))
    .filter((v) => !!v);
export const getIsLoadingRelatedTaxonomies = (state) =>
  state.getIn(['api', EntityTypes.TAXONOMY, 'isLoadingRelatedTaxonomies']);
export const getIsLoading = (state) =>
  state.getIn(['api', EntityTypes.TAXONOMY, 'isLoading']);
