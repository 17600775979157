/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment } from 'react';
import { FaLink } from 'react-icons/all';
import Text, { TextType } from './Text';
import { Trans } from 'react-i18next';
import FilterOverview from '../../../containers/search/FilterOverview';
import { decisionFilters } from '../../../containers/search/DecisionSearchView';
import * as SearchTabs from '../../../constants/SearchTabs';
import { fontFamily, fontSize } from '../styles/styles';
import { createStructuredSelector } from 'reselect';
import * as permissionSelectors from '../../../selectors/store/permissions';
import { compose } from 'recompose';
import { connect } from 'react-redux';

const styles = {
  fontSize: '5rem',
  marginTop: '1em',
  '.helper-text': {
    whiteSpace: 'break-spaces',
  },
  '& ul > li': {
    fontFamily: fontFamily.PARAGRAPH_DEFAULT,
    fontSize: fontSize.PARAGRAPH_DEFAULT,
  },
};

class NoData extends React.Component {
  render() {
    const { activeTab, hasAdvancedSearchPermission } = this.props;
    let filterOverview;
    if (activeTab === SearchTabs.DECISION) {
      filterOverview = (
        <FilterOverview
          translationKey="decision-filter"
          filterSet={decisionFilters}
        />
      );
    }
    return (
      <Fragment>
        {filterOverview}
        <div
          css={styles}
          className="no-data-icon d-flex align-items-center flex-column"
        >
          <Text type={TextType.HEADER_1_SMALL}>
            <Trans i18nKey="search-page.no-data-found" />
          </Text>
          <div className="mb-2" />
          <span className="my-2">
            <FaLink />
          </span>
          <div className="mb-5" />
          <div>
            <Text className="helper-text" type={TextType.PARAGRAPH_DEFAULT}>
              <Trans i18nKey="search-page.help.intro" />
            </Text>
            <ul>
              <li>
                <Trans i18nKey="search-page.help.union" />
              </li>
              <li>
                <Trans i18nKey="search-page.help.exact-order" />
              </li>
              <li>
                <Trans i18nKey="search-page.help.intersection" />
              </li>
              {hasAdvancedSearchPermission && (
                <li>
                  <Trans i18nKey="search-page.help.advanced-filters" />
                </li>
              )}
            </ul>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  hasAdvancedSearchPermission:
    permissionSelectors.checkAdvancedSearchPermission,
});

export default compose(connect(mapStateToProps))(NoData);
