/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { compose } from 'recompose';
import { Accordion, Card, Col, Container, Row } from 'react-bootstrap';
import ContentOuterBound from '../../components/common/atoms/ContentOuterBound';
import wavesImagePath from '../../images/wave.svg';
import { Trans, withTranslation } from 'react-i18next';
import { fontColors, uiColors } from '../../components/common/styles/styles';
import DefaultPageLayout from '../../components/common/atoms/DefaultPageLayout';
import Text, { TextType } from '../../components/common/atoms/Text';
import i18next from 'i18next';
import { SMALL_SCREEN } from '../../constants/ScreenSize';

const styles = () => ({
  '& .page-main': {
    background: 'url(' + wavesImagePath + ') ' + uiColors.BLUE_MAGENTA,
    backgroundSize: 'cover',
    color: fontColors.WHITE,
    height: 'fit-content',
    minHeight: '100vh',
    '& .contactBox': {
      padding: '3em',
      paddingTop: '2em',
      paddingBottom: '1em',
      backgroundColor: uiColors.WHITE,
      color: fontColors.BLACK,
    },
  },
  '& .flex-row': {
    alignItems: 'center',
    justifyContent: 'center',
    padding: '50px 0',
  },
  '.contact-info-full': {
    display: 'flex',
    justifyContent: 'space-between',
    whiteSpace: 'pre-wrap',
    '@media (max-width: 991px)': {
      flexDirection: 'column',
    },
  },
  '.contact-info-email': {
    display: 'flex',
  },
  '.accordionBox': {
    paddingTop: '15px',
    paddingBottom: '15px',
  },
  '.accordion-indicator': {
    float: 'inline-end',
  },
  '.card-body-text': {
    whiteSpace: 'break-spaces',
    maxHeight: '640px',
    overflow: 'auto',
    'h5:not(:first-of-type)': {
      marginTop: '2rem',
    },
  },
});

class ContactPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleAccordionOne: false,
      toggleAccordionTwo: false,
      toggleAccordionThree: false,
    };
  }

  getPolicySection(sectionName) {
    return (
      <div>
        {Array.from(
          Array(this.props.t(`contactPage.${sectionName}.length`)),
        ).map((_, i) => (
          <>
            <h5>
              <u>
                {`${i + 1}\t`}
                <Trans i18nKey={`contactPage.${sectionName}.${i}.header`} />
                {`\n`}
              </u>
            </h5>
            <Trans i18nKey={`contactPage.${sectionName}.${i}.text`} />
            {`\n\n`}
          </>
        ))}
      </div>
    );
  }

  getLargeScreenText() {
    const largeScreenText = { title: '', text: '' };
    i18next.t('contactPage.contactInfo', { returnObjects: true }).map((i) => {
      largeScreenText.title = largeScreenText.title + i.title + '\t\n';
      largeScreenText.text = largeScreenText.text + i.text + '\n';
    });
    return largeScreenText;
  }

  render() {
    const largeScreenText =
      window.innerWidth > SMALL_SCREEN ? this.getLargeScreenText() : null;

    return (
      <div css={styles}>
        <div className="page-main">
          <DefaultPageLayout>
            <ContentOuterBound>
              <Container>
                <Row className="flex-row">
                  <Col>
                    <div className="contactBox border rounded">
                      <Text as="p" type={TextType.HEADER_2_LARGE}>
                        <Trans i18nKey="contactPage.header" />
                      </Text>
                      <Text as="p" type={TextType.HEADER_2_SMALL}>
                        <Trans i18nKey="contactPage.pageDescription" />
                      </Text>
                      <div className="contact-info-full">
                        <Text as="p" type={TextType.PARAGRAPH_STRONG}>
                          <Trans i18nKey={'contactPage.address'} />
                          <br />
                          CVR: <Trans i18nKey={'contactPage.cvr'} />
                        </Text>
                        <div>
                          {largeScreenText ? (
                            <div className="contact-info-email">
                              <Text as="p" type={TextType.PARAGRAPH_STRONG}>
                                {largeScreenText.title}
                              </Text>
                              <Text as="p" type={TextType.PARAGRAPH_STRONG}>
                                {largeScreenText.text}
                              </Text>
                            </div>
                          ) : (
                            <div>
                              {Array.from(
                                Array(
                                  this.props.t(
                                    `contactPage.contactInfo.length`,
                                  ),
                                ),
                              ).map((_, i) => (
                                <div>
                                  <Text
                                    as="span"
                                    type={TextType.PARAGRAPH_STRONG}
                                  >
                                    <Trans
                                      i18nKey={`contactPage.contactInfo.${i}.title`}
                                    />
                                  </Text>
                                  <Text
                                    as="span"
                                    type={TextType.PARAGRAPH_STRONG}
                                  >
                                    <Trans
                                      i18nKey={`contactPage.contactInfo.${i}.text`}
                                    />
                                    {`\n`}
                                  </Text>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                      <Accordion className="accordionBox">
                        <Card>
                          <Accordion.Toggle
                            as={Card.Header}
                            eventKey="0"
                            onClick={() =>
                              this.setState({
                                toggleAccordionOne:
                                  !this.state.toggleAccordionOne,
                                toggleAccordionTwo: false,
                                toggleAccordionThree: false,
                              })
                            }
                          >
                            <Trans i18nKey={'contactPage.cookiePolicyHeader'} />
                            <div className="accordion-indicator">
                              <Text className="indicator">
                                {this.state.toggleAccordionOne ? '-' : '+'}
                              </Text>
                            </div>
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey="0">
                            <Card.Body className="card-body-text">
                              {this.getPolicySection('cookiePolicy')}
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                        <Card>
                          <Accordion.Toggle
                            as={Card.Header}
                            eventKey="1"
                            onClick={() =>
                              this.setState({
                                toggleAccordionOne: false,
                                toggleAccordionTwo:
                                  !this.state.toggleAccordionTwo,
                                toggleAccordionThree: false,
                              })
                            }
                          >
                            <Trans
                              i18nKey={'contactPage.personalPolicyHeader'}
                            />
                            <div className="accordion-indicator">
                              <Text className="indicator">
                                {this.state.toggleAccordionTwo ? '-' : '+'}
                              </Text>
                            </div>
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey="1">
                            <Card.Body className="card-body-text">
                              {this.getPolicySection('personalDataPolicy')}
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                        <Card>
                          <Accordion.Toggle
                            as={Card.Header}
                            eventKey="2"
                            onClick={() =>
                              this.setState({
                                toggleAccordionOne: false,
                                toggleAccordionTwo: false,
                                toggleAccordionThree:
                                  !this.state.toggleAccordionThree,
                              })
                            }
                          >
                            <Trans
                              i18nKey={'contactPage.licensePolicyHeader'}
                            />
                            <div className="accordion-indicator">
                              <Text className="indicator">
                                {this.state.toggleAccordionThree ? '-' : '+'}
                              </Text>
                            </div>
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey="2">
                            <Card.Body className="card-body-text">
                              {this.getPolicySection('licensePolicy')}
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      </Accordion>
                    </div>
                  </Col>
                </Row>
              </Container>
            </ContentOuterBound>
          </DefaultPageLayout>
        </div>
      </div>
    );
  }
}

export default compose(withTranslation())(ContactPage);
