import { all, fork } from 'redux-saga/effects';
import pageopenSaga from './pageopen';
import pageloadSaga from './pageload';
import getDecisionsSaga from './getDecisions';
import jurisdictionListener from './jurisdictionlistener';
import handleDecisionDownload from './handleDecisionFileDownload';

export default function* appRootSaga() {
  yield all([
    fork(pageopenSaga),
    fork(pageloadSaga),
    fork(getDecisionsSaga),
    fork(jurisdictionListener),
    fork(handleDecisionDownload),
  ]);
}
