import { call, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import lawItemConnector from '../../../connectors/LawItem';
import { generateApiSaga } from '../../../utils/sagas';

const doGetRelatedLawItemsById = generateApiSaga(
  lawItemConnector,
  function* (connector, action) {
    const { lawId } = action.payload;
    return yield call([connector, connector.getRelatedLawItemsById], lawId);
  },
);

export default function* getRelatedLawItemsById() {
  yield takeEvery(
    ActionTypes.API_LAWITEM_GET_ALL_RELATED_LAWITEM_BY_LAW_ID_INIT,
    doGetRelatedLawItemsById,
  );
}
