/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import * as TableStyle from '../styles/TableStyle';
import Text, { TextType } from '../atoms/Text';
import { Trans } from 'react-i18next';
import { Table } from 'react-bootstrap';
import { DateFormat } from '../../../constants/DateFormat';
import moment from 'moment';
import Button from '../atoms/Button';

class LicenseView extends React.Component {
  constructor(props) {
    super(props);
    this.renderTable = this.renderTable.bind(this);
    this.createOnLicenseClick = this.createOnLicenseClick.bind(this);
  }

  dateView(date) {
    return moment(date).format(DateFormat.requestDate);
  }

  createOnLicenseClick(license) {
    return (
      <Button
        type={Button.PRIMARY_SMALL}
        className="w-100 "
        onClick={() =>
          this.props.onLicenseClick && this.props.onLicenseClick(license)
        }
      >
        <span className="text-capitalize">
          <Trans i18nKey={'license-view.claim-license'} />
        </span>
      </Button>
    );
  }

  renderTable(elements, button) {
    return (
      <Table css={TableStyle.styles} borderless>
        <thead>
          <tr className="text-uppercase">
            <th>
              <Text type={TextType.TABLE_HEADER}>
                <Trans i18nKey="license-view.created" />
              </Text>
            </th>
            <th>
              <Text type={TextType.TABLE_HEADER}>
                <Trans i18nKey="license-view.expires" />
              </Text>
            </th>
            <th>
              <Text type={TextType.TABLE_HEADER}>
                <Trans i18nKey="license-view.role" />
              </Text>
            </th>
            <th />
          </tr>
        </thead>
        <tbody className="table-body">
          {elements.map((license) => (
            <tr className="table-row" key={license.id}>
              <td>
                <Text type={TextType.TABLE_ROW}>
                  {this.dateView(license.createdDate)}
                </Text>
              </td>
              <td>
                <Text type={TextType.TABLE_ROW}>
                  {this.dateView(license.expirationDate)}
                </Text>
              </td>
              <td>
                <Text type={TextType.TABLE_ROW}>{license.role}</Text>
              </td>
              <td>{button && button(license)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }

  render() {
    const { current, empty } = this.props;
    return (
      <div>
        {current && (
          <div>
            <div className="mb-4 text-capitalize">
              <Text type={TextType.HEADER_3}>
                <Trans i18nKey={'license-view.current-license'} />
              </Text>
            </div>
            {this.renderTable(current, null)}
          </div>
        )}

        {!!empty?.length && (
          <div>
            <div className="mb-4 text-capitalize">
              <Text type={TextType.HEADER_3}>
                <Trans i18nKey={'license-view.available-license'} />
              </Text>
            </div>
            {this.renderTable(empty, this.createOnLicenseClick)}
          </div>
        )}
      </div>
    );
  }
}

export default LicenseView;
