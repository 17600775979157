import * as ActionTypes from '../constants/ActionTypes';
import * as Urls from '../constants/Urls';
import { replace } from 'connected-react-router/immutable';

export const initBootstrap = () => ({ type: ActionTypes.APP_BOOTSTRAP_INIT });
export const bootstrapStarted = () => ({
  type: ActionTypes.APP_BOOTSTRAP_STARTED,
});
export const bootstrapCompleted = () => ({
  type: ActionTypes.APP_BOOTSTRAP_COMPLETED,
});
export const preloadStarted = () => ({ type: ActionTypes.APP_PRELOAD_INIT });
export const preloadCompleted = () => ({
  type: ActionTypes.APP_PRELOAD_COMPLETED,
});
export const relocateToFrontPage = () => replace(Urls.HOME_ROUTE);
export const relocateToLoginPage = () => replace(Urls.LOGIN_ROUTE);
export const navigateTo404 = () => replace(Urls.NOTFOUND_ROUTE);
export const savePreviousRoute = (location) => ({
  type: ActionTypes.APP_ROUTE_SAVE,
  payload: { location },
});
export const routeChange = (location, previousLocation, type) => ({
  type: ActionTypes.APP_ROUTE_CHANGE,
  payload: { location, previousLocation, type },
});
