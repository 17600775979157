/* Application */
// noinspection JSUnusedGlobalSymbols

export const APP_BOOTSTRAP_INIT = 'APP_BOOTSTRAP_INIT';
export const APP_BOOTSTRAP_STARTED = 'APP_BOOTSTRAP_STARTED';
export const APP_BOOTSTRAP_COMPLETED = 'APP_BOOTSTRAP_COMPLETED';
export const APP_PRELOAD_INIT = 'APP_PRELOAD_INIT';
export const APP_PRELOAD_COMPLETED = 'APP_PRELOAD_COMPLETED';
export const APP_ROUTER_LOCATION_CHANGE = '@@router/LOCATION_CHANGE';
export const APP_ROUTE_SAVE = 'APP_ROUTE_SAVE';
export const APP_ROUTE_CHANGE = 'APP_ROUTE_CHANGE';
export const APP_GLOBAL_UPDATE = 'APP_GLOBAL_UPDATE';
export const APP_NOTIFICATION_TOAST = 'APP_NOTIFICATION_TOAST';

export const APP_DATA_PATCH_ENTITY_INIT = 'APP_DATA_PATCH_ENTITY_INIT';
export const APP_DATA_PATCH_ENTITY_SUCCESS = 'APP_DATA_PATCH_ENTITY_SUCCESS';
export const APP_DATA_PATCH_ENTITY_ERROR = 'APP_DATA_PATCH_ENTITY_ERROR';
export const APP_DATA_PATCH_ENTITY_REQUEST = 'APP_DATA_PATCH_ENTITY_REQUEST';

/* Auth */
export const AUTH_LOGIN_INIT = 'AUTH_LOGIN_INIT';
export const AUTH_LOGIN_REQUEST = 'AUTH_LOGIN_REQUEST';
export const AUTH_LOGIN_COMPLETE = 'AUTH_LOGIN_COMPLETE';
export const AUTH_LOGOUT_INIT = 'AUTH_LOGOUT_INIT';
export const AUTH_LOGOUT_REQUEST = 'AUTH_LOGOUT_REQUEST';
export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS';
export const AUTH_LOGOUT_ERROR = 'AUTH_LOGOUT_ERROR';
export const AUTH_LOGOUT_COMPLETE = 'AUTH_LOGOUT_COMPLETE';
export const AUTH_INIT = 'AUTH_INIT';
export const AUTH_COMPLETED = 'AUTH_COMPLETED';
export const AUTH_LOGIN_VERIFIED = 'AUTH_LOGIN_VERIFIED';
export const AUTH_LOGIN_REFRESHED = 'AUTH_LOGIN_REFRESHED';
export const AUTH_CLEAR_LOGIN = 'AUTH_CLEAR_LOGIN';
export const AUTH_LOGIN_FAILED = 'AUTH_LOGIN_FAILED';
export const AUTH_JWT_CALL_INIT = 'AUTH_JWT_CALL_INIT';
export const AUTH_JWT_CALL_REQUEST = 'AUTH_JWT_CALL_REQUEST';
export const AUTH_JWT_CALL_SUCCESS = 'AUTH_JWT_CALL_SUCCESS';
export const AUTH_JWT_CALL_ERROR = 'AUTH_JWT_CALL_ERROR';

export const AUTH_JWT_REFRESH_INIT = 'AUTH_JWT_REFRESH_INIT';
export const AUTH_JWT_REFRESH_SUCCESS = 'AUTH_JWT_REFRESH_SUCCESS';
export const AUTH_JWT_REFRESH_ERROR = 'AUTH_JWT_REFRESH_ERROR';
export const AUTH_JWT_REFRESH_REQUEST = 'AUTH_JWT_REFRESH_REQUEST';
export const AUTH_RESET_PASSWORD_INIT = 'AUTH_RESET_PASSWORD_INIT';
export const AUTH_RESET_PASSWORD_REQUEST = 'AUTH_RESET_PASSWORD_REQUEST';
export const AUTH_RESET_PASSWORD_SUCCESS = 'AUTH_RESET_PASSWORD_SUCCESS';
export const AUTH_RESET_PASSWORD_ERROR = 'AUTH_RESET_PASSWORD_ERROR';
export const AUTH_RESET_PASSWORD_CONFIRM = 'AUTH_RESET_PASSWORD_CONFIRM';
export const AUTH_SET_PASSWORD_INIT = 'AUTH_SET_PASSWORD_INIT';
export const AUTH_SET_PASSWORD_REQUEST = 'AUTH_SET_PASSWORD_REQUEST';
export const AUTH_SET_PASSWORD_SUCCESS = 'AUTH_SET_PASSWORD_SUCCESS';
export const AUTH_SET_PASSWORD_ERROR = 'AUTH_SET_PASSWORD_ERROR';
export const AUTH_SET_ERROR = 'AUTH_SET_ERROR';

/* USER */
export const USER_CREATE_LOGIN = 'USER_CREATE_LOGIN';

/* Common */
export const COMMON_DEBOUNCE_ACTION = 'COMMON_DEBOUNCE_ACTION';

/* PAGE */
export const APP_PAGE_THEORY_LOAD = 'APP_PAGE_THEORY_LOAD';
export const APP_PAGE_THEORY_LOADED = 'APP_PAGE_THEORY_LOADED';
export const APP_PAGE_THEORY_SELECT_NODE = 'APP_PAGE_THEORY_SELECT_NODE';
export const PAGE_THEORY_LAST_URI = 'PAGE_THEORY_LAST_URI';
export const PAGE_THEORY_SET_NAVIGATION = 'PAGE_THEORY_SET_NAVIGATION';
export const PAGE_THEORY_CREATE_THEORY_ITEM = 'PAGE_THEORY_CREATE_THEORY_ITEM';
export const PAGE_THEORY_EXPAND_TREENODE = 'PAGE_THEORY_EXPAND_TREENODE';

export const APP_PAGE_DECISION_LOAD = 'APP_PAGE_DECISION_LOAD';
export const APP_PAGE_DECISION_SELECT_NODE = 'APP_PAGE_DECISION_SELECT_NODE';
export const APP_PAGE_DECISIONS_GET = 'APP_PAGE_DECISIONS_GET';
export const APP_PAGE_DECISION_SET_PAGING = 'APP_PAGE_DECISION_SET_PAGING';
export const PAGE_DECISION_SELECT = 'PAGE_DECISION_SELECT';

export const APP_PAGE_LAW_LOAD = 'APP_PAGE_LAW_LOAD';
export const APP_PAGE_LAW_LOADED = 'APP_PAGE_LAW_LOADED';
export const APP_PAGE_LAW_SELECT_CHILD = 'APP_PAGE_LAW_SELECT_CHILD';
export const APP_PAGE_LAW_SELECT_NODE = 'APP_PAGE_LAW_SELECT_NODE';
export const PAGE_LAW_LAST_URI = 'PAGE_LAW_LAST_URI';

export const APP_PAGE_PROCESS_LOAD = 'APP_PAGE_PROCESS_LOAD';
export const APP_PAGE_PROCESS_SELECT_NODE = 'APP_PAGE_PROCESS_SELECT_NODE';

export const APP_PAGE_TAXONOMY_LOAD = 'APP_PAGE_TAXONOMY_LOAD';
export const APP_PAGE_TAXONOMY_SET_FILTERTEXT_INIT =
  'APP_PAGE_TAXONOMY_SET_FILTERTEXT_INIT';
export const APP_PAGE_TAXONOMY_SET_FILTERTEXT =
  'APP_PAGE_TAXONOMY_SET_FILTERTEXT';
export const APP_PAGE_TAXONOMY_SET_CURRENT_TAB =
  'APP_PAGE_TAXONOMY_SET_CURRENT_TAB';
export const APP_PAGE_TAXONOMY_SET_PAGE_NUMBER =
  'APP_PAGE_TAXONOMY_SET_PAGE_NUMBER';

export const APP_PAGE_ADMIN_LOAD = 'APP_PAGE_ADMIN_LOAD';
export const APP_PAGE_ADMIN_SELECT_NODE = 'APP_PAGE_ADMIN_SELECT_NODE';
export const APP_PAGE_ADMIN_UPDATE_NODE = 'APP_PAGE_ADMIN_UPDATE_NODE';
export const APP_PAGE_ADMIN_DELETE_NODE = 'APP_PAGE_ADMIN_DELETE_NODE';
export const PAGE_ADMIN_DECISION_SUBMIT_FORM =
  'PAGE_ADMIN_DECISION_SUBMIT_FORM';
export const PAGE_ADMIN_DECISION_SELECT = 'PAGE_ADMIN_DECISION_SELECT';

export const APP_PAGE_ADMIN_HOME_LOAD = 'APP_PAGE_ADMIN_HOME_LOAD';
export const APP_PAGE_ADMIN_HOME_USER_DETAILS_LOAD_INIT =
  'APP_PAGE_ADMIN_HOME_LOAD_USER_DETAILS_LOAD_INIT';
export const APP_PAGE_ADMIN_HOME_USER_DETAILS_LOAD_ERROR =
  'APP_PAGE_ADMIN_HOME_LOAD_USER_DETAILS_LOAD_DONE';
export const APP_PAGE_ADMIN_HOME_USER_DETAILS_LOAD_SUCCESS =
  'APP_PAGE_ADMIN_HOME_LOAD_USER_DETAILS_LOAD_SUCCESS';
export const APP_PAGE_ADMIN_HOME_USER_DETAILS_UPDATE_INIT =
  'APP_PAGE_ADMIN_HOME_LOAD_USER_DETAILS_UPDATE_INIT';
export const APP_PAGE_ADMIN_HOME_USER_DETAILS_UPDATE_ERROR =
  'APP_PAGE_ADMIN_HOME_LOAD_USER_DETAILS_UPDATE_DONE';
export const APP_PAGE_ADMIN_HOME_USER_DETAILS_UPDATE_SUCCESS =
  'APP_PAGE_ADMIN_HOME_LOAD_USER_DETAILS_UPDATE_SUCCESS';

export const PAGE_SEARCH_FILTER_UPDATE_INIT = 'PAGE_SEARCH_FILTER_UPDATE_INIT';
export const PAGE_SEARCH_FILTER_UPDATE_SET = 'PAGE_SEARCH_FILTER_UPDATE_SET';
export const PAGE_SEARCH_FILTER_UPDATE_COMPLETE =
  'PAGE_SEARCH_FILTER_UPDATE_COMPLETE';
export const PAGE_SEARCH_SEARCH_INIT = 'PAGE_SEARCH_SEARCH_INIT';
export const PAGE_SEARCH_SEARCH_ACTIVE = 'PAGE_SEARCH_SEARCH_ACTIVE';
export const PAGE_SEARCH_SEARCH_SUCCESS = 'PAGE_SEARCH_SEARCH_SUCCESS';
export const PAGE_SEARCH_SEARCH_ABORT = 'PAGE_SEARCH_SEARCH_ABORT';
export const PAGE_SEARCH_SEARCH_ERROR = 'PAGE_SEARCH_SEARCH_ERROR';
export const PAGE_SEARCH_TAB_CHANGE_INIT = 'PAGE_SEARCH_TAB_CHANGE_INIT';
export const PAGE_SEARCH_TAB_CHANGE_SET = 'PAGE_SEARCH_TAB_CHANGE_SET';
export const PAGE_SEARCH_FETCH_RESULTS_INIT = 'PAGE_SEARCH_FETCH_RESULTS_INIT';
export const PAGE_SEARCH_FETCH_RESULTS_REQUEST =
  'PAGE_SEARCH_FETCH_RESULTS_REQUEST';
export const PAGE_SEARCH_FETCH_RESULTS_COMPLETED =
  'PAGE_SEARCH_FETCH_RESULTS_COMPLETED';

export const APP_PAGE_PROFILE_LOAD = 'APP_PAGE_PROFILE_LOAD';
export const APP_PAGE_PROFILE_SELECT_USER = 'APP_PAGE_PROFILE_SELECT_USER';

export const APP_PAGE_FRONT_LOAD = 'APP_PAGE_FRONT_LOAD';

export const APP_PAGE_SIGNUP_LOAD = 'APP_PAGE_SIGNUP_LOAD';
export const PAGE_FOOTER_FETCH_DATA = 'PAGE_FOOTER_FETCH_DATA';
export const PAGE_FOOTER_SELECT_DATA = 'PAGE_FOOTER_SELECT_DATA';
export const PAGE_FOOTER_TOGGLE_RED_THREAD = 'PAGE_FOOTER_TOGGLE_RED_THREAD';

/* PAGE */
export const PAGE_SIGNUP_SUBMIT = 'PAGE_SIGNUP_SUBMIT';

/* DATA */
export const DATA_PRELOAD_TAXONOMY = 'DATA_PRELOAD_TAXONOMY';
export const DATA_PRELOAD_TAXONOMY_COMPLETED =
  'DATA_PRELOAD_TAXONOMY_COMPLETED';
export const DATA_PRELOAD_COURTS_INIT = 'DATA_PRELOAD_COURTS_INIT';
export const DATA_PRELOAD_COURTS_COMPLETED = 'DATA_PRELOAD_COURTS_COMPLETED';
export const DATA_PRELOAD_THEORY_INIT = 'DATA_PRELOAD_THEORY_INIT';
export const DATA_PRELOAD_THEORY_COMPLETED = 'DATA_PRELOAD_THEORY_COMPLETED';
export const DATA_PRELOAD_PROCESS_INIT = 'DATA_PRELOAD_PROCESS_INIT';
export const DATA_PRELOAD_PROCESS_COMPLETED = 'DATA_PRELOAD_PROCESS_COMPLETED';

/* Api */
export const API_CALL_CANCELLED = 'API_CALL_CANCELLED';

export const TXN_ALL_PAGES_INIT = 'TXN_ALL_PAGES_INIT';
export const TXN_ALL_PAGES_COMPLETE = 'TXN_ALL_PAGES_COMPLETE';

export const API_SEARCH_INIT = 'API_SEARCH_INIT';
export const API_SEARCH_ERROR = 'API_SEARCH_ERROR';
export const API_SEARCH_REQUEST = 'API_SEARCH_REQUEST';
export const API_SEARCH_SUCCESS = 'API_SEARCH_SUCCESS';

export const API_JURISDICTION_GET_ALL_INIT = 'API_JURISDICTION_GET_ALL_INIT';
export const API_JURISDICTION_GET_ALL_SUCCESS =
  'API_JURISDICTION_GET_ALL_SUCCESS';
export const API_JURISDICTION_GET_ALL_ERROR = 'API_JURISDICTION_GET_ALL_ERROR';
export const API_JURISDICTION_GET_ALL_REQUEST =
  'API_JURISDICTION_GET_ALL_REQUEST';

export const API_COURT_GET_ALL_INIT = 'API_COURT_GET_ALL_INIT';
export const API_COURT_GET_ALL_SUCCESS = 'API_COURT_GET_ALL_SUCCESS';
export const API_COURT_GET_ALL_ERROR = 'API_COURT_GET_ALL_ERROR';
export const API_COURT_GET_ALL_REQUEST = 'API_COURT_GET_ALL_REQUEST';

export const API_TAXONOMY_GET_ALL_INIT = 'API_TAXONOMY_GET_ALL_INIT';
export const API_TAXONOMY_GET_ALL_REQUEST = 'API_TAXONOMY_GET_ALL_REQUEST';
export const API_TAXONOMY_GET_ALL_ERROR = 'API_TAXONOMY_GET_ALL_ERROR';
export const API_TAXONOMY_GET_ALL_SUCCESS = 'API_TAXONOMY_GET_ALL_SUCCESS';

export const API_TAXONOMY_GET_BY_IDS_INIT = 'API_TAXONOMY_GET_BY_IDS_INIT';
export const API_TAXONOMY_GET_BY_IDS_REQUEST =
  'API_TAXONOMY_GET_BY_IDS_REQUEST';
export const API_TAXONOMY_GET_BY_IDS_ERROR = 'API_TAXONOMY_GET_BY_IDS_ERROR';
export const API_TAXONOMY_GET_BY_IDS_SUCCESS =
  'API_TAXONOMY_GET_BY_IDS_SUCCESS';

export const API_THEORY_GET_ALL_INIT = 'API_THEORY_GET_ALL_INIT';
export const API_THEORY_GET_ALL_SUCCESS = 'API_THEORY_GET_ALL_SUCCESS';
export const API_THEORY_GET_ALL_ERROR = 'API_THEORY_GET_ALL_ERROR';
export const API_THEORY_GET_ALL_REQUEST = 'API_THEORY_GET_ALL_REQUEST';

export const API_THEORY_ITEM_GET_ALL_INIT = 'API_THEORY_ITEM_GET_ALL_INIT';
export const API_THEORY_ITEM_GET_ALL_SUCCESS =
  'API_THEORY_ITEM_GET_ALL_SUCCESS';
export const API_THEORY_ITEM_GET_ALL_ERROR = 'API_THEORY_ITEM_GET_ALL_ERROR';
export const API_THEORY_ITEM_GET_ALL_REQUEST =
  'API_THEORY_ITEM_GET_ALL_REQUEST';

export const API_THEORY_ITEM_GET_ALL_BY_URI_REQUEST =
  'API_THEORY_ITEM_GET_ALL_BY_URI_REQUEST';
export const API_THEORY_ITEM_GET_ALL_BY_URI_INIT =
  'API_THEORY_ITEM_GET_ALL_BY_URI_INIT';
export const API_THEORY_ITEM_GET_ALL_BY_URI_SUCCESS =
  'API_THEORY_ITEM_GET_ALL_BY_URI_SUCCESS';
export const API_THEORY_ITEM_GET_ALL_BY_URI_ERROR =
  'API_THEORY_ITEM_GET_ALL_BY_URI_ERROR';

export const API_THEORY_ITEM_GET_BY_SECTIONNUMBER_AND_TYPE_INIT =
  'API_THEORY_ITEM_GET_BY_SECTIONNUMBER_AND_TYPE_INIT';
export const API_THEORY_ITEM_GET_BY_SECTIONNUMBER_AND_TYPE_SUCCESS =
  'API_THEORY_ITEM_GET_BY_SECTIONNUMBER_AND_TYPE_SUCCESS';
export const API_THEORY_ITEM_GET_BY_SECTIONNUMBER_AND_TYPE_ERROR =
  'API_THEORY_ITEM_GET_BY_SECTIONNUMBER_AND_TYPE_ERROR';
export const API_THEORY_ITEM_GET_BY_SECTIONNUMBER_AND_TYPE_REQUEST =
  'API_THEORY_ITEM_GET_BY_SECTIONNUMBER_AND_TYPE_REQUEST';

export const API_THEORY_ITEM_GET_BY_IDS_INIT =
  'API_THEORY_ITEM_GET_BY_IDS_INIT';
export const API_THEORY_ITEM_GET_BY_IDS_ERROR =
  'API_THEORY_ITEM_GET_BY_IDS_ERROR';
export const API_THEORY_ITEM_GET_BY_IDS_REQUEST =
  'API_THEORY_ITEM_GET_BY_IDS_REQUEST';
export const API_THEORY_ITEM_GET_BY_IDS_SUCCESS =
  'API_THEORY_ITEM_GET_BY_IDS_SUCCESS';

export const API_THEORY_ITEM_GET_BY_ID_EXPANDED_INIT =
  'API_THEORY_ITEM_GET_BY_ID_EXPANDED_INIT';
export const API_THEORY_ITEM_GET_BY_ID_EXPANDED_ERROR =
  'API_THEORY_ITEM_GET_BY_ID_EXPANDED_ERROR';
export const API_THEORY_ITEM_GET_BY_ID_EXPANDED_REQUEST =
  'API_THEORY_ITEM_GET_BY_ID_EXPANDED_REQUEST';
export const API_THEORY_ITEM_GET_BY_ID_EXPANDED_SUCCESS =
  'API_THEORY_ITEM_GET_BY_ID_EXPANDED_SUCCESS';

export const API_THEORY_ITEM_GET_BY_ID_WITH_PARENTS_INIT =
  'API_THEORY_ITEM_GET_BY_ID_WITH_PARENTS_INIT';
export const API_THEORY_ITEM_GET_BY_ID_WITH_PARENTS_ERROR =
  'API_THEORY_ITEM_GET_BY_ID_WITH_PARENTS_ERROR';
export const API_THEORY_ITEM_GET_BY_ID_WITH_PARENTS_REQUEST =
  'API_THEORY_ITEM_GET_BY_ID_WITH_PARENTS_REQUEST';
export const API_THEORY_ITEM_GET_BY_ID_WITH_PARENTS_SUCCESS =
  'API_THEORY_ITEM_GET_BY_ID_WITH_PARENTS_SUCCESS';

export const API_THEORY_ITEM_GET_TOP_INIT = 'API_THEORY_ITEM_GET_TOP_INIT';
export const API_THEORY_ITEM_GET_TOP_ERROR = 'API_THEORY_ITEM_GET_TOP_ERROR';
export const API_THEORY_ITEM_GET_TOP_REQUEST =
  'API_THEORY_ITEM_GET_TOP_REQUEST';
export const API_THEORY_ITEM_GET_TOP_SUCCESS =
  'API_THEORY_ITEM_GET_TOP_SUCCESS';

export const API_THEORY_ITEM_GET_ALL_SECTIONS_INIT =
  'API_THEORY_ITEM_GET_ALL_SECTIONS_INIT';
export const API_THEORY_ITEM_GET_ALL_SECTIONS_ERROR =
  'API_THEORY_ITEM_GET_ALL_SECTIONS_ERROR';
export const API_THEORY_ITEM_GET_ALL_SECTIONS_REQUEST =
  'API_THEORY_ITEM_GET_ALL_SECTIONS_REQUEST';
export const API_THEORY_ITEM_GET_ALL_SECTIONS_SUCCESS =
  'API_THEORY_ITEM_GET_ALL_SECTIONS_SUCCESS';

export const API_THEORY_GET_CHILD_THEORYITEMS_INIT =
  'API_THEORY_GET_CHILD_THEORYITEMS_INIT';
export const API_THEORY_GET_CHILD_THEORYITEMS_ERROR =
  'API_THEORY_GET_CHILD_THEORYITEMS_ERROR';
export const API_THEORY_GET_CHILD_THEORYITEMS_REQUEST =
  'API_THEORY_GET_CHILD_THEORYITEMS_REQUEST';
export const API_THEORY_GET_CHILD_THEORYITEMS_SUCCESS =
  'API_THEORY_GET_CHILD_THEORYITEMS_SUCCESS';

export const API_THEORY_ITEM_GET_ALL_CHILDREN_INIT =
  'API_THEORY_ITEM_GET_ALL_CHILDREN_INIT';
export const API_THEORY_ITEM_GET_ALL_CHILDREN_REQUEST =
  'API_THEORY_ITEM_GET_ALL_CHILDREN_REQUEST';
export const API_THEORY_ITEM_GET_ALL_CHILDREN_ERROR =
  'API_THEORY_ITEM_GET_ALL_CHILDREN_ERROR';
export const API_THEORY_ITEM_GET_ALL_CHILDREN_SUCCESS =
  'API_THEORY_ITEM_GET_ALL_CHILDREN_SUCCESS';

export const API_THEORY_ITEM_GET_CHILDREN_INIT =
  'API_THEORY_ITEM_GET_CHILDREN_INIT';
export const API_THEORY_ITEM_GET_CHILDREN_SUCCESS =
  'API_THEORY_ITEM_GET_CHILDREN_SUCCESS';
export const API_THEORY_ITEM_GET_CHILDREN_ERROR =
  'API_THEORY_ITEM_GET_CHILDREN_ERROR';
export const API_THEORY_ITEM_GET_CHILDREN_REQUEST =
  'API_THEORY_ITEM_GET_CHILDREN_REQUEST';

export const API_THEORY_ITEM_GET_EXPANDED_TREE_BY_THEORY_INIT =
  'API_THEORY_ITEM_GET_EXPANDED_TREE_BY_THEORY_INIT';
export const API_THEORY_ITEM_GET_EXPANDED_TREE_BY_THEORY_SUCCESS =
  'API_THEORY_ITEM_GET_EXPANDED_TREE_BY_THEORY_SUCCESS';
export const API_THEORY_ITEM_GET_EXPANDED_TREE_BY_THEORY_ERROR =
  'API_THEORY_ITEM_GET_EXPANDED_TREE_BY_THEORY_ERROR';
export const API_THEORY_ITEM_GET_EXPANDED_TREE_BY_THEORY_REQUEST =
  'API_THEORY_ITEM_GET_EXPANDED_TREE_BY_THEORY_REQUEST';

export const API_THEORY_ITEM_GET_DEFAULT_URI_INIT =
  'API_THEORY_ITEM_GET_DEFAULT_URI_INIT';
export const API_THEORY_ITEM_GET_DEFAULT_URI_SUCCESS =
  'API_THEORY_ITEM_GET_DEFAULT_URI_SUCCESS';
export const API_THEORY_ITEM_GET_DEFAULT_URI_ERROR =
  'API_THEORY_ITEM_GET_DEFAULT_URI_ERROR';
export const API_THEORY_ITEM_GET_DEFAULT_URI_REQUEST =
  'API_THEORY_ITEM_GET_DEFAULT_URI_REQUEST';

export const API_THEORY_ITEM_GET_RELATION_TREE_BY_ID_INIT =
  'API_THEORY_ITEM_GET_RELATION_TREE_BY_ID_INIT';
export const API_THEORY_ITEM_GET_RELATION_TREE_BY_ID_ERROR =
  'API_THEORY_ITEM_GET_RELATION_TREE_BY_ID_ERROR';
export const API_THEORY_ITEM_GET_RELATION_TREE_BY_ID_REQUEST =
  'API_THEORY_ITEM_GET_RELATION_TREE_BY_ID_REQUEST';
export const API_THEORY_ITEM_GET_RELATION_TREE_BY_ID_SUCCESS =
  'API_THEORY_ITEM_GET_RELATION_TREE_BY_ID_SUCCESS';

export const API_THEORY_ITEM_GET_RELATION_BY_ID_INIT =
  'API_THEORY_ITEM_GET_RELATION_BY_ID_INIT';
export const API_THEORY_ITEM_GET_RELATION_BY_ID_ERROR =
  'API_THEORY_ITEM_GET_RELATION_BY_ID_ERROR';
export const API_THEORY_ITEM_GET_RELATION_BY_ID_REQUEST =
  'API_THEORY_ITEM_GET_RELATION_BY_ID_REQUEST';
export const API_THEORY_ITEM_GET_RELATION_BY_ID_SUCCESS =
  'API_THEORY_ITEM_GET_RELATION_BY_ID_SUCCESS';

export const API_THEORY_ITEM_GET_LATEST_INIT =
  'API_THEORY_ITEM_GET_LATEST_INIT';
export const API_THEORY_ITEM_GET_LATEST_ERROR =
  'API_THEORY_ITEM_GET_LATEST_ERROR';
export const API_THEORY_ITEM_GET_LATEST_REQUEST =
  'API_THEORY_ITEM_GET_LATEST_REQUEST';
export const API_THEORY_ITEM_GET_LATEST_SUCCESS =
  'API_THEORY_ITEM_GET_LATEST_SUCCESS';

export const API_FIND_THEORY_ITEM_BY_TITLE_INIT =
  'API_FIND_THEORY_ITEM_BY_TITLE_INIT';
export const API_FIND_THEORY_ITEM_BY_TITLE_ERROR =
  'API_FIND_THEORY_ITEM_BY_TITLE_ERROR';
export const API_FIND_THEORY_ITEM_BY_TITLE_REQUEST =
  'API_FIND_THEORY_ITEM_BY_TITLE_REQUEST';
export const API_FIND_THEORY_ITEM_BY_TITLE_SUCCESS =
  'API_FIND_THEORY_ITEM_BY_TITLE_SUCCESS';

export const API_CREATE_THEORY_ITEM_INIT = 'API_CREATE_THEORY_ITEM_INIT';
export const API_CREATE_THEORY_ITEM_ERROR = 'API_CREATE_THEORY_ITEM_ERROR';
export const API_CREATE_THEORY_ITEM_REQUEST = 'API_CREATE_THEORY_ITEM_REQUEST';
export const API_CREATE_THEORY_ITEM_SUCCESS = 'API_CREATE_THEORY_ITEM_SUCCESS';

export const API_THEORY_ITEM_DELETE_BY_ID_INIT =
  'API_THEORY_ITEM_DELETE_BY_ID_INIT';
export const API_THEORY_ITEM_DELETE_BY_ID_ERROR =
  'API_THEORY_ITEM_DELETE_BY_ID_ERROR';
export const API_THEORY_ITEM_DELETE_BY_ID_REQUEST =
  'API_THEORY_ITEM_DELETE_BY_ID_REQUEST';
export const API_THEORY_ITEM_DELETE_BY_ID_SUCCESS =
  'API_THEORY_ITEM_DELETE_BY_ID_SUCCESS';

export const API_GET_ALL_THEORY_ITEM_TYPES_INIT =
  'API_GET_ALL_THEORY_ITEM_TYPES_INIT';
export const API_GET_ALL_THEORY_ITEM_TYPES_ERROR =
  'API_GET_ALL_THEORY_ITEM_TYPES_ERROR';
export const API_GET_ALL_THEORY_ITEM_TYPES_REQUEST =
  'API_GET_ALL_THEORY_ITEM_TYPES_REQUEST';
export const API_GET_ALL_THEORY_ITEM_TYPES_SUCCESS =
  'API_GET_ALL_THEORY_ITEM_TYPES_SUCCESS';

export const API_CREATE_DECISION_INIT = 'API_CREATE_DECISION_INIT';
export const API_CREATE_DECISION_ERROR = 'API_CREATE_DECISION_ERROR';
export const API_CREATE_DECISION_SUCCESS = 'API_CREATE_DECISION_SUCCESS';
export const API_CREATE_DECISION_REQUEST = 'API_CREATE_DECISION_REQUEST';

export const API_TAXONOMY_CREATE_INIT = 'API_TAXONOMY_CREATE_INIT';
export const API_TAXONOMY_CREATE_ERROR = 'API_TAXONOMY_CREATE_ERROR';
export const API_TAXONOMY_CREATE_SUCCESS = 'API_TAXONOMY_CREATE_SUCCESS';
export const API_TAXONOMY_CREATE_REQUEST = 'API_TAXONOMY_CREATE_REQUEST';

export const API_UPDATE_DECISION_INIT = 'API_UPDATE_DECISION_INIT';
export const API_UPDATE_DECISION_ERROR = 'API_UPDATE_DECISION_ERROR';
export const API_UPDATE_DECISION_SUCCESS = 'API_UPDATE_DECISION_SUCCESS';
export const API_UPDATE_DECISION_REQUEST = 'API_UPDATE_DECISION_REQUEST';

export const API_UPLOAD_DECISION_FILE_INIT = 'API_UPLOAD_DECISION_FILE_INIT';
export const API_UPLOAD_DECISION_FILE_ERROR = 'API_UPLOAD_DECISION_FILE_ERROR';
export const API_UPLOAD_DECISION_FILE_SUCCESS =
  'API_UPLOAD_DECISION_FILE_SUCCESS';
export const API_UPLOAD_DECISION_FILE_REQUEST =
  'API_UPLOAD_DECISION_FILE_REQUEST';

export const API_DECISION_CASE_TYPE_GET_ALL_INIT =
  'API_DECISION_CASE_TYPE_GET_ALL_INIT';
export const API_DECISION_CASE_TYPE_GET_ALL_SUCCESS =
  'API_DECISION_CASE_TYPE_GET_ALL_SUCCESS';
export const API_DECISION_CASE_TYPE_GET_ALL_ERROR =
  'API_DECISION_CASE_TYPE_GET_ALL_ERROR';
export const API_DECISION_CASE_TYPE_GET_ALL_REQUEST =
  'API_DECISION_CASE_TYPE_GET_ALL_REQUEST';

export const API_DECISION_TYPE_GET_ALL_INIT = 'API_DECISION_TYPE_GET_ALL_INIT';
export const API_DECISION_TYPE_GET_ALL_SUCCESS =
  'API_DECISION_TYPE_GET_ALL_SUCCESS';
export const API_DECISION_TYPE_GET_ALL_ERROR =
  'API_DECISION_TYPE_GET_ALL_ERROR';
export const API_DECISION_TYPE_GET_ALL_REQUEST =
  'API_DECISION_TYPE_GET_ALL_REQUEST';

export const API_LAW_GET_ALL_INIT = 'API_LAW_GET_ALL_INIT';
export const API_LAW_GET_ALL_SUCCESS = 'API_LAW_GET_ALL_SUCCESS';
export const API_LAW_GET_ALL_ERROR = 'API_LAW_GET_ALL_ERROR';
export const API_LAW_GET_ALL_REQUEST = 'API_LAW_GET_ALL_REQUEST';

export const API_LAWGROUP_GET_ALL_INIT = 'API_LAWGROUP_GET_ALL_INIT';
export const API_LAWGROUP_GET_ALL_SUCCESS = 'API_LAWGROUP_GET_ALL_SUCCESS';
export const API_LAWGROUP_GET_ALL_ERROR = 'API_LAWGROUP_GET_ALL_ERROR';
export const API_LAWGROUP_GET_ALL_REQUEST = 'API_LAWGROUP_GET_ALL_REQUEST';

export const API_LAW_GET_CHILDREN_INIT = 'API_LAW_GET_CHILDREN_INIT';
export const API_LAW_GET_CHILDREN_REQUEST = 'API_LAW_GET_CHILDREN_REQUEST';
export const API_LAW_GET_CHILDREN_ERROR = 'API_LAW_GET_CHILDREN_ERROR';
export const API_LAW_GET_CHILDREN_SUCCESS = 'API_LAW_GET_CHILDREN_SUCCESS';

export const API_LAWITEM_GET_ALL_INIT = 'API_LAWITEM_GET_ALL_INIT';
export const API_LAWITEM_GET_ALL_SUCCESS = 'API_LAWITEM_GET_ALL_SUCCESS';
export const API_LAWITEM_GET_ALL_TXN = 'API_LAWITEM_GET_ALL_TXN';
export const API_LAWITEM_GET_ALL_ERROR = 'API_LAWITEM_GET_ALL_ERROR';
export const API_LAWITEM_GET_ALL_REQUEST = 'API_LAWITEM_GET_ALL_REQUEST';

export const API_LAW_PREFACE_ITEM_GET_ALL_INIT =
  'API_LAW_PREFACE_ITEM_GET_ALL_INIT';
export const API_LAW_PREFACE_ITEM_GET_ALL_REQUEST =
  'API_LAW_PREFACE_ITEM_GET_ALL_REQUEST';
export const API_LAW_PREFACE_ITEM_GET_ALL_ERROR =
  'API_LAW_PREFACE_ITEM_GET_ALL_ERROR';
export const API_LAW_PREFACE_ITEM_GET_ALL_SUCCESS =
  'API_LAW_PREFACE_ITEM_GET_ALL_SUCCESS';
export const API_LAW_PREFACE_ITEM_GET_ALL_TXN =
  'API_LAW_PREFACE_ITEM_GET_ALL_TXN';

export const API_LAW_PREFACE_ITEM_GET_ALL_BY_LAW_INIT =
  'API_LAW_PREFACE_ITEM_GET_ALL_BY_LAW_INIT';
export const API_LAW_PREFACE_ITEM_GET_ALL_BY_LAW_REQUEST =
  'API_LAW_PREFACE_ITEM_GET_ALL_BY_LAW_REQUEST';
export const API_LAW_PREFACE_ITEM_GET_ALL_BY_LAW_SUCCESS =
  'API_LAW_PREFACE_ITEM_GET_ALL_BY_LAW_SUCCESS';
export const API_LAW_PREFACE_ITEM_GET_ALL_BY_LAW_ERROR =
  'API_LAW_PREFACE_ITEM_GET_ALL_BY_LAW_ERROR';
export const API_LAW_PREFACE_ITEM_GET_ALL_BY_LAW_TXN =
  'API_LAW_PREFACE_ITEM_GET_ALL_BY_LAW_TXN';

export const API_LAWITEM_GET_ALL_BY_LAW_INIT =
  'API_LAWITEM_GET_ALL_BY_LAW_INIT';
export const API_LAWITEM_GET_ALL_BY_LAW_SUCCESS =
  'API_LAWITEM_GET_ALL_BY_LAW_SUCCESS';
export const API_LAWITEM_GET_ALL_BY_LAW_ERROR =
  'API_LAWITEM_GET_ALL_BY_LAW_ERROR';
export const API_LAWITEM_GET_ALL_BY_LAW_REQUEST =
  'API_LAWITEM_GET_ALL_BY_LAW_REQUEST';
export const API_LAWITEM_GET_ALL_BY_LAW_TXN = 'API_LAWITEM_GET_ALL_BY_LAW_TXN';

export const API_LAWITEM_GET_BY_URI_INIT = 'API_LAWITEM_GET_BY_URI_INIT';
export const API_LAWITEM_GET_BY_URI_ERROR = 'API_LAWITEM_GET_BY_URI_ERROR';
export const API_LAWITEM_GET_BY_URI_REQUEST = 'API_LAWITEM_GET_BY_URI_REQUEST';
export const API_LAWITEM_GET_BY_URI_SUCCESS = 'API_LAWITEM_GET_BY_URI_SUCCESS';

export const API_LAW_PREFACE_ITEM_GET_BY_URI_INIT =
  'API_LAW_PREFACE_ITEM_GET_BY_URI_INIT';
export const API_LAW_PREFACE_ITEM_GET_BY_URI_ERROR =
  'API_LAW_PREFACE_ITEM_GET_BY_URI_ERROR';
export const API_LAW_PREFACE_ITEM_GET_BY_URI_REQUEST =
  'API_LAW_PREFACE_ITEM_GET_BY_URI_REQUEST';
export const API_LAW_PREFACE_ITEM_GET_BY_URI_SUCCESS =
  'API_LAW_PREFACE_ITEM_GET_BY_URI_SUCCESS';

export const API_LAWITEM_GET_TOP_LEVEL_BY_LAW_INIT =
  'API_LAWITEM_GET_TOP_LEVEL_BY_LAW_INIT';
export const API_LAWITEM_GET_TOP_LEVEL_BY_LAW_ERROR =
  'API_LAWITEM_GET_TOP_LEVEL_BY_LAW_ERROR';
export const API_LAWITEM_GET_TOP_LEVEL_BY_LAW_REQUEST =
  'API_LAWITEM_GET_TOP_LEVEL_BY_LAW_REQUEST';
export const API_LAWITEM_GET_TOP_LEVEL_BY_LAW_SUCCESS =
  'API_LAWITEM_GET_TOP_LEVEL_BY_LAW_SUCCESS';

export const API_LAWITEM_GET_ALL_BY_URI_WITH_PARENTS_INIT =
  'API_LAWITEM_GET_ALL_BY_URI_WITH_PARENTS_INIT';
export const API_LAWITEM_GET_ALL_BY_URI_WITH_PARENTS_ERROR =
  'API_LAWITEM_GET_ALL_BY_URI_WITH_PARENTS_ERROR';
export const API_LAWITEM_GET_ALL_BY_URI_WITH_PARENTS_REQUEST =
  'API_LAWITEM_GET_ALL_BY_URI_WITH_PARENTS_REQUEST';
export const API_LAWITEM_GET_ALL_BY_URI_WITH_PARENTS_SUCCESS =
  'API_LAWITEM_GET_ALL_BY_URI_WITH_PARENTS_SUCCESS';

export const API_LAWITEM_GET_ALL_RELATED_LAWITEM_BY_LAW_ID_INIT =
  'API_LAWITEM_GET_ALL_RELATED_LAWITEM_BY_LAW_ID_INIT';
export const API_LAWITEM_GET_ALL_RELATED_LAWITEM_BY_LAW_ID_ERROR =
  'API_LAWITEM_GET_ALL_RELATED_LAWITEM_BY_LAW_ID_ERROR';
export const API_LAWITEM_GET_ALL_RELATED_LAWITEM_BY_LAW_ID_REQUEST =
  'API_LAWITEM_GET_ALL_RELATED_LAWITEM_BY_LAW_ID_REQUEST';
export const API_LAWITEM_GET_ALL_RELATED_LAWITEM_BY_LAW_ID_SUCCESS =
  'API_LAWITEM_GET_ALL_RELATED_LAWITEM_BY_LAW_ID_SUCCESS';

export const API_LAWITEM_GET_BY_IDS_INIT = 'API_LAWITEM_GET_BY_IDS_INIT';
export const API_LAWITEM_GET_BY_IDS_ERROR = 'API_LAWITEM_GET_BY_IDS_ERROR';
export const API_LAWITEM_GET_BY_IDS_REQUEST = 'API_LAWITEM_GET_BY_IDS_REQUEST';
export const API_LAWITEM_GET_BY_IDS_SUCCESS = 'API_LAWITEM_GET_BY_IDS_SUCCESS';

export const API_LAWITEM_GET_ALL_CHILDREN_INIT =
  'API_LAWITEM_GET_ALL_CHILDREN_INIT';
export const API_LAWITEM_GET_ALL_CHILDREN_REQUEST =
  'API_LAWITEM_GET_ALL_CHILDREN_REQUEST';
export const API_LAWITEM_GET_ALL_CHILDREN_ERROR =
  'API_LAWITEM_GET_ALL_CHILDREN_ERROR';
export const API_LAWITEM_GET_ALL_CHILDREN_SUCCESS =
  'API_LAWITEM_GET_ALL_CHILDREN_SUCCESS';

export const API_LAWITEM_SEARCH_BY_NAME_INIT =
  'API_LAWITEM_SEARCH_BY_NAME_INIT';
export const API_LAWITEM_SEARCH_BY_NAME_ERROR =
  'API_LAWITEM_SEARCH_BY_NAME_ERROR';
export const API_LAWITEM_SEARCH_BY_NAME_REQUEST =
  'API_LAWITEM_SEARCH_BY_NAME_REQUEST';
export const API_LAWITEM_SEARCH_BY_NAME_SUCCESS =
  'API_LAWITEM_SEARCH_BY_NAME_SUCCESS';

export const API_DECISION_GET_ALL_INIT = 'API_DECISION_GET_ALL_INIT';
export const API_DECISION_GET_ALL_SUCCESS = 'API_DECISION_GET_ALL_SUCCESS';
export const API_DECISION_GET_ALL_ERROR = 'API_DECISION_GET_ALL_ERROR';
export const API_DECISION_GET_ALL_REQUEST = 'API_DECISION_GET_ALL_REQUEST';

export const API_DECISION_GET_BY_ID_INIT = 'API_DECISION_GET_BY_ID_INIT';
export const API_DECISION_GET_BY_ID_REQUEST = 'API_DECISION_GET_BY_ID_REQUEST';
export const API_DECISION_GET_BY_ID_ERROR = 'API_DECISION_GET_BY_ID_ERROR';
export const API_DECISION_GET_BY_ID_SUCCESS = 'API_DECISION_GET_BY_ID_SUCCESS';

export const API_DECISION_FILE_GET_BY_DECISION_ID_INIT =
  'API_DECISION_FILE_GET_BY_DECISION_ID_INIT';
export const API_DECISION_FILE_GET_BY_DECISION_ID_REQUEST =
  'API_DECISION_FILE_GET_BY_DECISION_ID_REQUEST';
export const API_DECISION_FILE_GET_BY_DECISION_ID_ERROR =
  'API_DECISION_FILE_GET_BY_DECISION_ID_ERROR';
export const API_DECISION_FILE_GET_BY_DECISION_ID_SUCCESS =
  'API_DECISION_FILE_GET_BY_DECISION_ID_SUCCESS';

export const API_DECISIONS_GET_INIT = 'API_DECISIONS_GET_INIT';
export const API_DECISIONS_GET_REQUEST = 'API_DECISIONS_GET_REQUEST';
export const API_DECISIONS_GET_ERROR = 'API_DECISIONS_GET_ERROR';
export const API_DECISIONS_GET_SUCCESS = 'API_DECISIONS_GET_SUCCESS';

export const API_DECISION_GET_BY_IDS_INIT = 'API_DECISION_GET_BY_IDS_INIT';
export const API_DECISION_GET_BY_IDS_ERROR = 'API_DECISION_GET_BY_IDS_ERROR';
export const API_DECISION_GET_BY_IDS_REQUEST =
  'API_DECISION_GET_BY_IDS_REQUEST';
export const API_DECISION_GET_BY_IDS_SUCCESS =
  'API_DECISION_GET_BY_IDS_SUCCESS';

export const API_DECISION_SEARCH_BY_EDITOR_NAME_OR_CASE_NAME_INIT =
  'API_DECISION_SEARCH_BY_EDITOR_NAME_OR_CASE_NAME_INIT';
export const API_DECISION_SEARCH_BY_EDITOR_NAME_OR_CASE_NAME_ERROR =
  'API_DECISION_SEARCH_BY_EDITOR_NAME_OR_CASE_NAME_ERROR';
export const API_DECISION_SEARCH_BY_EDITOR_NAME_OR_CASE_NAME_REQUEST =
  'API_DECISION_SEARCH_BY_EDITOR_NAME_OR_CASE_NAME_REQUEST';
export const API_DECISION_SEARCH_BY_EDITOR_NAME_OR_CASE_NAME_SUCCESS =
  'API_DECISION_SEARCH_BY_EDITOR_NAME_OR_CASE_NAME_SUCCESS';

export const API_PROCESS_GET_ALL_INIT = 'API_PROCESS_GET_ALL_INIT';
export const API_PROCESS_GET_ALL_SUCCESS = 'API_PROCESS_GET_ALL_SUCCESS';
export const API_PROCESS_GET_ALL_ERROR = 'API_PROCESS_GET_ALL_ERROR';
export const API_PROCESS_GET_ALL_REQUEST = 'API_PROCESS_GET_ALL_REQUEST';

export const API_PROCESSSTEP_GET_ALL_BY_PROCESS_INIT =
  'API_PROCESSSTEP_GET_ALL_BY_PROCESS_INIT';
export const API_PROCESSSTEP_GET_ALL_BY_PROCESS_SUCCESS =
  'API_PROCESSSTEP_GET_ALL_BY_PROCESS_SUCCESS';
export const API_PROCESSSTEP_GET_ALL_BY_PROCESS_ERROR =
  'API_PROCESSSTEP_GET_ALL_BY_PROCESS_ERROR';
export const API_PROCESSSTEP_GET_ALL_BY_PROCESS_REQUEST =
  'API_PROCESSSTEP_GET_ALL_BY_PROCESS_REQUEST';
export const API_PROCESSSTEP_GET_ALL_BY_PROCESS_TXN =
  'API_PROCESSSTEP_GET_ALL_BY_PROCESS_TXN';

export const API_USER_GET_ALL_INIT = 'API_USER_GET_ALL_INIT';
export const API_USER_GET_ALL_ERROR = 'API_USER_GET_ALL_ERROR';
export const API_USER_GET_ALL_REQUEST = 'API_USER_GET_ALL_REQUEST';
export const API_USER_GET_ALL_SUCCESS = 'API_USER_GET_ALL_SUCCESS';

export const API_USER_GET_BY_ID_INIT = 'API_USER_GET_BY_ID_INIT';
export const API_USER_GET_BY_ID_ERROR = 'API_USER_GET_BY_ID_ERROR';
export const API_USER_GET_BY_ID_REQUEST = 'API_USER_GET_BY_ID_REQUEST';
export const API_USER_GET_BY_ID_SUCCESS = 'API_USER_GET_BY_ID_SUCCESS';

export const API_USER_CONSENT_GET_USER_CONSENT_BY_ID_INIT =
  'API_USER_CONSENT_GET_USER_CONSENT_BY_ID_INIT';
export const API_USER_CONSENT_GET_USER_CONSENT_BY_ID_ERROR =
  'API_USER_CONSENT_GET_USER_CONSENT_BY_ID_ERROR';
export const API_USER_CONSENT_GET_USER_CONSENT_BY_ID_REQUEST =
  'API_USER_CONSENT_GET_USER_CONSENT_BY_ID_REQUEST';
export const API_USER_CONSENT_GET_USER_CONSENT_BY_ID_SUCCESS =
  'API_USER_CONSENT_GET_USER_CONSENT_BY_ID_SUCCESS';

export const API_USER_UPDATE_APP_USER_INIT = 'API_USER_UPDATE_APP_USER_INIT';
export const API_USER_UPDATE_APP_USER_ERROR = 'API_USER_UPDATE_APP_USER_ERROR';
export const API_USER_UPDATE_APP_USER_REQUEST =
  'API_USER_UPDATE_APP_USER_REQUEST';
export const API_USER_UPDATE_APP_USER_SUCCESS =
  'API_USER_UPDATE_APP_USER_SUCCESS';

export const API_USER_CONSENT_UPDATE_CONSENT_INIT =
  'API_USER_CONSENT_UPDATE_CONSENT_INIT';
export const API_USER_CONSENT_UPDATE_CONSENT_ERROR =
  'API_USER_CONSENT_UPDATE_CONSENT_ERROR';
export const API_USER_CONSENT_UPDATE_CONSENT_REQUEST =
  'API_USER_CONSENT_UPDATE_CONSENT_REQUEST';
export const API_USER_CONSENT_UPDATE_CONSENT_SUCCESS =
  'API_USER_CONSENT_UPDATE_CONSENT_SUCCESS';

export const API_PROFILE_GET_BY_ID_INIT = 'API_PROFILE_GET_BY_ID_INIT';
export const API_PROFILE_GET_BY_ID_ERROR = 'API_PROFILE_GET_BY_ID_ERROR';
export const API_PROFILE_GET_BY_ID_REQUEST = 'API_PROFILE_GET_BY_ID_REQUEST';
export const API_PROFILE_GET_BY_ID_SUCCESS = 'API_PROFILE_GET_BY_ID_SUCCESS';

export const API_PROFILE_GET_USER_CONSENT_BY_ID_INIT =
  'API_PROFILE_GET_USER_CONSENT_BY_ID_INIT';
export const API_PROFILE_GET_USER_CONSENT_BY_ID_ERROR =
  'API_PROFILE_GET_USER_CONSENT_BY_ID_ERROR';
export const API_PROFILE_GET_USER_CONSENT_BY_ID_REQUEST =
  'API_PROFILE_GET_USER_CONSENT_BY_ID_REQUEST';
export const API_PROFILE_GET_USER_CONSENT_BY_ID_SUCCESS =
  'API_PROFILE_GET_USER_CONSENT_BY_ID_SUCCESS';

export const API_PROFILE_UPDATE_APP_USER_INIT =
  'API_PROFILE_UPDATE_APP_USER_INIT';
export const API_PROFILE_UPDATE_APP_USER_ERROR =
  'API_PROFILE_UPDATE_APP_USER_ERROR';
export const API_PROFILE_UPDATE_APP_USER_REQUEST =
  'API_PROFILE_UPDATE_APP_USER_REQUEST';
export const API_PROFILE_UPDATE_APP_USER_SUCCESS =
  'API_PROFILE_UPDATE_APP_USER_SUCCESS';

export const API_PROFILE_UPDATE_CONSENT_INIT =
  'API_PROFILE_UPDATE_CONSENT_INIT';
export const API_PROFILE_UPDATE_CONSENT_ERROR =
  'API_PROFILE_UPDATE_CONSENT_ERROR';
export const API_PROFILE_UPDATE_CONSENT_REQUEST =
  'API_PROFILE_UPDATE_CONSENT_REQUEST';
export const API_PROFILE_UPDATE_CONSENT_SUCCESS =
  'API_PROFILE_UPDATE_CONSENT_SUCCESS';

export const API_SIGNUP_SUBMIT_INIT = 'API_SIGNUP_SUBMIT_INIT';
export const API_SIGNUP_SUBMIT_ERROR = 'API_SIGNUP_SUBMIT_ERROR';
export const API_SIGNUP_SUBMIT_REQUEST = 'API_SIGNUP_SUBMIT_REQUEST';
export const API_SIGNUP_SUBMIT_SUCCESS = 'API_SIGNUP_SUBMIT_SUCCESS';

export const API_SIGNUP_CONFIRM_INIT = 'API_SIGNUP_CONFIRM_INIT';
export const API_SIGNUP_CONFIRM_ERROR = 'API_SIGNUP_CONFIRM_ERROR';
export const API_SIGNUP_CONFIRM_REQUEST = 'API_SIGNUP_CONFIRM_REQUEST';
export const API_SIGNUP_CONFIRM_SUCCESS = 'API_SIGNUP_CONFIRM_SUCCESS';

export const API_GET_FAVORITES_INIT = 'API_GET_FAVORITES_INIT';
export const API_GET_FAVORITES_ERROR = 'API_GET_FAVORITES_ERROR';
export const API_GET_FAVORITES_REQUEST = 'API_GET_FAVORITES_REQUEST';
export const API_GET_FAVORITES_SUCCESS = 'API_GET_FAVORITES_SUCCESS';

export const API_GET_ALL_FAVORITES_INIT = 'API_GET_ALL_FAVORITES_INIT';
export const API_GET_ALL_FAVORITES_ERROR = 'API_GET_ALL_FAVORITES_ERROR';
export const API_GET_ALL_FAVORITES_REQUEST = 'API_GET_ALL_FAVORITES_REQUEST';
export const API_GET_ALL_FAVORITES_SUCCESS = 'API_GET_ALL_FAVORITES_SUCCESS';

export const API_GET_ALL_FAVORITES_EXPANDED_INIT =
  'API_GET_ALL_FAVORITES_EXPANDED_INIT';
export const API_GET_ALL_FAVORITES_EXPANDED_ERROR =
  'API_GET_ALL_FAVORITES_EXPANDED_ERROR';
export const API_GET_ALL_FAVORITES_EXPANDED_REQUEST =
  'API_GET_ALL_FAVORITES_EXPANDED_REQUEST';
export const API_GET_ALL_FAVORITES_EXPANDED_SUCCESS =
  'API_GET_ALL_FAVORITES_EXPANDED_SUCCESS';

export const API_SET_FAVORITE_INIT = 'API_SET_FAVORITE_INIT';
export const API_SET_FAVORITE_ERROR = 'API_SET_FAVORITE_ERROR';
export const API_SET_FAVORITE_REQUEST = 'API_SET_FAVORITE_REQUEST';
export const API_SET_FAVORITE_SUCCESS = 'API_SET_FAVORITE_SUCCESS';

export const API_GET_ALL_COMPANIES_INIT = 'API_GET_ALL_COMPANIES_INIT';
export const API_GET_ALL_COMPANIES_ERROR = 'API_GET_ALL_COMPANIES_ERROR';
export const API_GET_ALL_COMPANIES_REQUEST = 'API_GET_ALL_COMPANIES_REQUEST';
export const API_GET_ALL_COMPANIES_SUCCESS = 'API_GET_ALL_COMPANIES_SUCCESS';

export const API_UPDATE_COMPANY_INIT = 'API_UPDATE_COMPANY_INIT';
export const API_UPDATE_COMPANY_ERROR = 'API_UPDATE_COMPANY_ERROR';
export const API_UPDATE_COMPANY_REQUEST = 'API_UPDATE_COMPANY_REQUEST';
export const API_UPDATE_COMPANY_SUCCESS = 'API_UPDATE_COMPANY_SUCCESS';

export const API_GET_ALL_ROLES_INIT = 'API_GET_ALL_ROLES_INIT';
export const API_GET_ALL_ROLES_ERROR = 'API_GET_ALL_ROLES_ERROR';
export const API_GET_ALL_ROLES_REQUEST = 'API_GET_ALL_ROLES_REQUEST';
export const API_GET_ALL_ROLES_SUCCESS = 'API_GET_ALL_ROLES_SUCCESS';

export const API_LICENSE_CREATE_INIT = 'API_LICENSE_CREATE_INIT';
export const API_LICENSE_CREATE_ERROR = 'API_LICENSE_CREATE_ERROR';
export const API_LICENSE_CREATE_REQUEST = 'API_LICENSE_CREATE_REQUEST';
export const API_LICENSE_CREATE_SUCCESS = 'API_LICENSE_CREATE_SUCCESS';

export const API_LICENSE_UPDATE_INIT = 'API_LICENSE_UPDATE_INIT';
export const API_LICENSE_UPDATE_ERROR = 'API_LICENSE_UPDATE_ERROR';
export const API_LICENSE_UPDATE_REQUEST = 'API_LICENSE_UPDATE_REQUEST';
export const API_LICENSE_UPDATE_SUCCESS = 'API_LICENSE_UPDATE_SUCCESS';

export const API_LICENSE_DELETE_INIT = 'API_LICENSE_DELETE_INIT';
export const API_LICENSE_DELETE_ERROR = 'API_LICENSE_DELETE_ERROR';
export const API_LICENSE_DELETE_REQUEST = 'API_LICENSE_DELETE_REQUEST';
export const API_LICENSE_DELETE_SUCCESS = 'API_LICENSE_DELETE_SUCCESS';

export const API_LICENSE_GET_AVAILABLE_INIT = 'API_LICENSE_GET_AVAILABLE_INIT';
export const API_LICENSE_GET_AVAILABLE_ERROR =
  'API_LICENSE_GET_AVAILABLE_ERROR';
export const API_LICENSE_GET_AVAILABLE_REQUEST =
  'API_LICENSE_GET_AVAILABLE_REQUEST';
export const API_LICENSE_GET_AVAILABLE_SUCCESS =
  'API_LICENSE_GET_AVAILABLE_SUCCESS';

export const API_LICENSE_GET_ALL_INIT = 'API_LICENSE_GET_ALL_INIT';
export const API_LICENSE_GET_ALL_ERROR = 'API_LICENSE_GET_ALL_ERROR';
export const API_LICENSE_GET_ALL_REQUEST = 'API_LICENSE_GET_ALL_REQUEST';
export const API_LICENSE_GET_ALL_SUCCESS = 'API_LICENSE_GET_ALL_SUCCESS';

export const API_CLAIM_LICENSE_INIT = 'API_CLAIM_LICENSE_INIT';
export const API_CLAIM_LICENSE_ERROR = 'API_CLAIM_LICENSE_ERROR';
export const API_CLAIM_LICENSE_REQUEST = 'API_CLAIM_LICENSE_REQUEST';
export const API_CLAIM_LICENSE_SUCCESS = 'API_CLAIM_LICENSE_SUCCESS';

export const API_DECISIONS_GET_TOP_INIT = 'API_DECISIONS_GET_TOP_INIT';
export const API_DECISIONS_GET_TOP_ERROR = 'API_DECISIONS_GET_TOP_ERROR';
export const API_DECISIONS_GET_TOP_REQUEST = 'API_DECISIONS_GET_TOP_REQUEST';
export const API_DECISIONS_GET_TOP_SUCCESS = 'API_DECISIONS_GET_TOP_SUCCESS';

export const API_LAW_ITEM_GET_TOP_INIT = 'API_LAW_ITEM_GET_TOP_INIT';
export const API_LAW_ITEM_GET_TOP_ERROR = 'API_LAW_ITEM_GET_TOP_ERROR';
export const API_LAW_ITEM_GET_TOP_REQUEST = 'API_LAW_ITEM_GET_TOP_REQUEST';
export const API_LAW_ITEM_GET_TOP_SUCCESS = 'API_LAW_ITEM_GET_TOP_SUCCESS';

export const API_FIND_PROCESS_STEP_BY_NAME_INIT =
  'API_FIND_PROCESS_STEP_BY_NAME_INIT';
export const API_FIND_PROCESS_STEP_BY_NAME_ERROR =
  'API_FIND_PROCESS_STEP_BY_NAME_ERROR';
export const API_FIND_PROCESS_STEP_BY_NAME_REQUEST =
  'API_FIND_PROCESS_STEP_BY_NAME_REQUEST';
export const API_FIND_PROCESS_STEP_BY_NAME_SUCCESS =
  'API_FIND_PROCESS_STEP_BY_NAME_SUCCESS';

export const API_GET_PROCESS_STEP_BY_IDS_INIT =
  'API_GET_PROCESS_STEP_BY_IDS_INIT';
export const API_GET_PROCESS_STEP_BY_IDS_ERROR =
  'API_GET_PROCESS_STEP_BY_IDS_ERROR';
export const API_GET_PROCESS_STEP_BY_IDS_REQUEST =
  'API_GET_PROCESS_STEP_BY_IDS_REQUEST';
export const API_GET_PROCESS_STEP_BY_IDS_SUCCESS =
  'API_GET_PROCESS_STEP_BY_IDS_SUCCESS';

export const API_LAW_ITEM_GET_RELATION_TREE_BY_ID_INIT =
  'API_LAW_ITEM_GET_RELATION_TREE_BY_ID_INIT';
export const API_LAW_ITEM_GET_RELATION_TREE_BY_ID_ERROR =
  'API_LAW_ITEM_GET_RELATION_TREE_BY_ID_ERROR';
export const API_LAW_ITEM_GET_RELATION_TREE_BY_ID_REQUEST =
  'API_LAW_ITEM_GET_RELATION_TREE_BY_ID_REQUEST';
export const API_LAW_ITEM_GET_RELATION_TREE_BY_ID_SUCCESS =
  'API_LAW_ITEM_GET_RELATION_TREE_BY_ID_SUCCESS';

export const API_LAW_PREFACE_ITEM_GET_RELATION_TREE_BY_ID_INIT =
  'API_LAW_PREFACE_ITEM_GET_RELATION_TREE_BY_ID_INIT';
export const API_LAW_PREFACE_ITEM_GET_RELATION_TREE_BY_ID_ERROR =
  'API_LAW_PREFACE_ITEM_GET_RELATION_TREE_BY_ID_ERROR';
export const API_LAW_PREFACE_ITEM_GET_RELATION_TREE_BY_ID_REQUEST =
  'API_LAW_PREFACE_ITEM_GET_RELATION_TREE_BY_ID_REQUEST';
export const API_LAW_PREFACE_ITEM_GET_RELATION_TREE_BY_ID_SUCCESS =
  'API_LAW_PREFACE_ITEM_GET_RELATION_TREE_BY_ID_SUCCESS';

export const API_LAW_PREFACE_ITEM_GET_BY_IDS_INIT =
  'API_LAW_PREFACE_ITEM_GET_BY_IDS_INIT';
export const API_LAW_PREFACE_ITEM_GET_BY_IDS_ERROR =
  'API_LAW_PREFACE_ITEM_GET_BY_IDS_ERROR';
export const API_LAW_PREFACE_ITEM_GET_BY_IDS_REQUEST =
  'API_LAW_PREFACE_ITEM_GET_BY_IDS_REQUEST';
export const API_LAW_PREFACE_ITEM_GET_BY_IDS_SUCCESS =
  'API_LAW_PREFACE_ITEM_GET_BY_IDS_SUCCESS';

export const API_LAW_ITEM_GET_RELATION_BY_ID_INIT =
  'API_LAW_ITEM_GET_RELATION_BY_ID_INIT';
export const API_LAW_ITEM_GET_RELATION_BY_ID_ERROR =
  'API_LAW_ITEM_GET_RELATION_BY_ID_ERROR';
export const API_LAW_ITEM_GET_RELATION_BY_ID_REQUEST =
  'API_LAW_ITEM_GET_RELATION_BY_ID_REQUEST';
export const API_LAW_ITEM_GET_RELATION_BY_ID_SUCCESS =
  'API_LAW_ITEM_GET_RELATION_BY_ID_SUCCESS';

export const API_LAW_PREFACE_ITEM_GET_RELATION_BY_ID_INIT =
  'API_LAW_PREFACE_ITEM_GET_RELATION_BY_ID_INIT';
export const API_LAW_PREFACE_ITEM_GET_RELATION_BY_ID_ERROR =
  'API_LAW_PREFACE_ITEM_GET_RELATION_BY_ID_ERROR';
export const API_LAW_PREFACE_ITEM_GET_RELATION_BY_ID_REQUEST =
  'API_LAW_PREFACE_ITEM_GET_RELATION_BY_ID_REQUEST';
export const API_LAW_PREFACE_ITEM_GET_RELATION_BY_ID_SUCCESS =
  'API_LAW_PREFACE_ITEM_GET_RELATION_BY_ID_SUCCESS';

export const API_DECISION_GET_RELATION_TREE_BY_ID_INIT =
  'API_DECISION_GET_RELATION_TREE_BY_ID_INIT';
export const API_DECISION_GET_RELATION_TREE_BY_ID_ERROR =
  'API_DECISION_GET_RELATION_TREE_BY_ID_ERROR';
export const API_DECISION_GET_RELATION_TREE_BY_ID_REQUEST =
  'API_DECISION_GET_RELATION_TREE_BY_ID_REQUEST';
export const API_DECISION_GET_RELATION_TREE_BY_ID_SUCCESS =
  'API_DECISION_GET_RELATION_TREE_BY_ID_SUCCESS';

export const API_TAXONOMY_GET_RELATION_TREE_BY_ID_INIT =
  'API_TAXONOMY_GET_RELATION_TREE_BY_ID_INIT';
export const API_TAXONOMY_GET_RELATION_TREE_BY_ID_ERROR =
  'API_TAXONOMY_GET_RELATION_TREE_BY_ID_ERROR';
export const API_TAXONOMY_GET_RELATION_TREE_BY_ID_REQUEST =
  'API_TAXONOMY_GET_RELATION_TREE_BY_ID_REQUEST';
export const API_TAXONOMY_GET_RELATION_TREE_BY_ID_SUCCESS =
  'API_TAXONOMY_GET_RELATION_TREE_BY_ID_SUCCESS';

export const API_DECISION_GET_RELATED_BY_ID_INIT =
  'API_DECISION_GET_RELATED_BY_ID_INIT';
export const API_DECISION_GET_RELATED_BY_ID_ERROR =
  'API_DECISION_GET_RELATED_BY_ID_ERROR';
export const API_DECISION_GET_RELATED_BY_ID_REQUEST =
  'API_DECISION_GET_RELATED_BY_ID_REQUEST';
export const API_DECISION_GET_RELATED_BY_ID_SUCCESS =
  'API_DECISION_GET_RELATED_BY_ID_SUCCESS';

export const API_PROCESS_STEP_GET_RELATION_TREE_BY_ID_INIT =
  'API_PROCESS_STEP_GET_RELATION_TREE_BY_ID_INIT';
export const API_PROCESS_STEP_GET_RELATION_TREE_BY_ID_ERROR =
  'API_PROCESS_STEP_GET_RELATION_TREE_BY_ID_ERROR';
export const API_PROCESS_STEP_GET_RELATION_TREE_BY_ID_REQUEST =
  'API_PROCESS_STEP_GET_RELATION_TREE_BY_ID_REQUEST';
export const API_PROCESS_STEP_GET_RELATION_TREE_BY_ID_SUCCESS =
  'API_PROCESS_STEP_GET_RELATION_TREE_BY_ID_SUCCESS';

export const API_LAW_ITEM_GET_FIRST_ITEM_BY_LAW_INIT =
  'API_LAW_ITEM_GET_FIRST_ITEM_BY_LAW_INIT';
export const API_LAW_ITEM_GET_FIRST_ITEM_BY_LAW_ERROR =
  'API_LAW_ITEM_GET_FIRST_ITEM_BY_LAW_ERROR';
export const API_LAW_ITEM_GET_FIRST_ITEM_BY_LAW_REQUEST =
  'API_LAW_ITEM_GET_FIRST_ITEM_BY_LAW_REQUEST';
export const API_LAW_ITEM_GET_FIRST_ITEM_BY_LAW_SUCCESS =
  'API_LAW_ITEM_GET_FIRST_ITEM_BY_LAW_SUCCESS';

export const API_UPDATE_RELATION_COUNT_INIT = 'API_UPDATE_RELATION_COUNT_INIT';
export const API_UPDATE_RELATION_COUNT_ERROR =
  'API_UPDATE_RELATION_COUNT_ERROR';
export const API_UPDATE_RELATION_COUNT_REQUEST =
  'API_UPDATE_RELATION_COUNT_REQUEST';
export const API_UPDATE_RELATION_COUNT_SUCCESS =
  'API_UPDATE_RELATION_COUNT_SUCCESS';

export const API_UPDATE_RELATION_COUNT_FOR_NODE_INIT =
  'API_UPDATE_RELATION_COUNT_FOR_NODE_INIT';
export const API_UPDATE_RELATION_COUNT_FOR_NODE_ERROR =
  'API_UPDATE_RELATION_COUNT_FOR_NODE_ERROR';
export const API_UPDATE_RELATION_COUNT_FOR_NODE_REQUEST =
  'API_UPDATE_RELATION_COUNT_FOR_NODE_REQUEST';
export const API_UPDATE_RELATION_COUNT_FOR_NODE_SUCCESS =
  'API_UPDATE_RELATION_COUNT_FOR_NODE_SUCCESS';

export const API_FULL_SYNC_SOLR_DATA_INIT = 'API_FULL_SYNC_SOLR_DATA_INIT';
export const API_FULL_SYNC_SOLR_DATA_ERROR = 'API_FULL_SYNC_SOLR_DATA_ERROR';
export const API_FULL_SYNC_SOLR_DATA_REQUEST =
  'API_FULL_SYNC_SOLR_DATA_REQUEST';
export const API_FULL_SYNC_SOLR_DATA_SUCCESS =
  'API_FULL_SYNC_SOLR_DATA_SUCCESS';

export const API_GET_ALL_LAW_ITEM_TYPES_INIT =
  'API_GET_ALL_LAW_ITEM_TYPES_INIT';
export const API_GET_ALL_LAW_ITEM_TYPES_ERROR =
  'API_GET_ALL_LAW_ITEM_TYPES_ERROR';
export const API_GET_ALL_LAW_ITEM_TYPES_REQUEST =
  'API_GET_ALL_LAW_ITEM_TYPES_REQUEST';
export const API_GET_ALL_LAW_ITEM_TYPES_SUCCESS =
  'API_GET_ALL_LAW_ITEM_TYPES_SUCCESS';

export const API_GET_ALL_PERMISSION_INIT = 'API_GET_ALL_PERMISSION_INIT';
export const API_GET_ALL_PERMISSION_ERROR = 'API_GET_ALL_PERMISSION_ERROR';
export const API_GET_ALL_PERMISSION_REQUEST = 'API_GET_ALL_PERMISSION_REQUEST';
export const API_GET_ALL_PERMISSION_SUCCESS = 'API_GET_ALL_PERMISSION_SUCCESS';

export const API_UPDATE_ROLE_INIT = 'API_UPDATE_ROLE_INIT';
export const API_UPDATE_ROLE_ERROR = 'API_UPDATE_ROLE_ERROR';
export const API_UPDATE_ROLE_REQUEST = 'API_UPDATE_ROLE_REQUEST';
export const API_UPDATE_ROLE_SUCCESS = 'API_UPDATE_ROLE_SUCCESS';

export const API_NEWSLETTER_CREATE_INIT = 'API_NEWSLETTER_CREATE_INIT';
export const API_NEWSLETTER_CREATE_ERROR = 'API_NEWSLETTER_CREATE_ERROR';
export const API_NEWSLETTER_CREATE_REQUEST = 'API_NEWSLETTER_CREATE_REQUEST';
export const API_NEWSLETTER_CREATE_SUCCESS = 'API_NEWSLETTER_CREATE_SUCCESS';
export const API_NEWSLETTER_DISABLE_BUTTON = 'API_NEWSLETTER_DISABLE_BUTTON';

export const API_UNSUBSCRIBE_NOTIFICATION_INIT =
  'API_UNSUBSCRIBE_NOTIFICATION_INIT';
export const API_UNSUBSCRIBE_NOTIFICATION_ERROR =
  'API_UNSUBSCRIBE_NOTIFICATION_ERROR';
export const API_UNSUBSCRIBE_NOTIFICATION_REQUEST =
  'API_UNSUBSCRIBE_NOTIFICATION_REQUEST';
export const API_UNSUBSCRIBE_NOTIFICATION_SUCCESS =
  'API_UNSUBSCRIBE_NOTIFICATION_SUCCESS';

export const API_UNSUBSCRIBE_ALL_NOTIFICATIONS_INIT =
  'API_UNSUBSCRIBE_ALL_NOTIFICATIONS_INIT';
export const API_UNSUBSCRIBE_ALL_NOTIFICATIONS_ERROR =
  'API_UNSUBSCRIBE_ALL_NOTIFICATIONS_ERROR';
export const API_UNSUBSCRIBE_ALL_NOTIFICATIONS_REQUEST =
  'API_UNSUBSCRIBE_ALL_NOTIFICATIONS_REQUEST';
export const API_UNSUBSCRIBE_ALL_NOTIFICATIONS_SUCCESS =
  'API_UNSUBSCRIBE_ALL_NOTIFICATIONS_SUCCESS';

export const API_NEWSWORTHY_ITEM_CREATE_INIT =
  'API_NEWSWORTHY_ITEM_CREATE_INIT';
export const API_NEWSWORTHY_ITEM_CREATE_REQUEST =
  'API_NEWSWORTHY_ITEM_CREATE_REQUEST';
export const API_NEWSWORTHY_ITEM_CREATE_SUCCESS =
  'API_NEWSWORTHY_ITEM_CREATE_SUCCESS';
export const API_NEWSWORTHY_ITEM_CREATE_ERROR =
  'API_NEWSWORTHY_ITEM_CREATE_ERROR';

// Actions emitted when data extract has been performed on relation trees to ensure that data goes to the correct reducers
export const API_GET_RELATION_TREE_THEORY_ITEMS =
  'API_GET_RELATION_TREE_THEORY_ITEMS';
export const API_GET_RELATION_TREE_LAW_ITEMS =
  'API_GET_RELATION_TREE_LAW_ITEMS';
export const API_GET_RELATION_TREE_LAW_PREFACE_ITEMS =
  'API_GET_RELATION_TREE_LAW_PREFACE_ITEMS';
export const API_GET_RELATION_TREE_DECISIONS =
  'API_GET_RELATION_TREE_DECISIONS';
export const API_GET_RELATION_TREE_PROCESS_STEPS =
  'API_GET_RELATION_TREE_PROCESS_STEPS';
export const API_GET_RELATION_TREE_TAXONOMY = 'API_GET_RELATION_TREE_TAXONOMY';
