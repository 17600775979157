import { takeLatestWhenAppReady } from '../../../utils/sagas';
import * as ActionTypes from '../../../constants/ActionTypes';
import * as searchPageActions from '../../../actions/page/search';
import { put, select } from '@redux-saga/core/effects';
import * as searchPageSelectors from '../../../selectors/page/search';
import * as routerSelectors from '../../../selectors/store/router';
import { buildSearchLocation } from '../../../utils/link';
import { push } from 'connected-react-router/immutable';

function* doUpdateTab(action) {
  const targetTab = action.payload.tab;
  const currentFilter = yield select(
    searchPageSelectors.getCurrentSearchFilter,
  );

  // update url if necessary
  const [pageSegment, tabSegment] = yield select(
    routerSelectors.getCurrentPathParts,
  );
  if (targetTab !== tabSegment) {
    yield put(push(buildSearchLocation(targetTab, currentFilter)));
  }

  // update state if necessary
  const currentTab = yield select(searchPageSelectors.getCurrentTab);
  if (targetTab !== currentTab) {
    yield put(searchPageActions.setTab(targetTab));
    yield put(searchPageActions.fetchResults());
  }
}

export default function* updateTab(action) {
  yield takeLatestWhenAppReady(
    ActionTypes.PAGE_SEARCH_TAB_CHANGE_INIT,
    doUpdateTab,
  );
}
