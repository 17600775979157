import { call, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import TaxonomyConnector from '../../../connectors/Taxonomy';
import { generateApiSaga } from '../../../utils/sagas';

var doGetTaxonomyByIds = generateApiSaga(
  TaxonomyConnector,
  function* (connector, action) {
    let nodeFragment = action.payload.ids;
    return yield call([connector, connector.getByIdsWithParents], nodeFragment);
  },
);

export default function* getbyids() {
  yield takeEvery(ActionTypes.API_TAXONOMY_GET_BY_IDS_INIT, doGetTaxonomyByIds);
}
