import { all, fork } from 'redux-saga/effects';
import getByIdSaga from './getbyid';
import getByIdsSaga from './getbyids';
import createDecision from './createdecision';
import getDecisionCaseType from './getdecisioncasetype';
import getDecisionType from './getdecisiontype';
import uploadDecisionFile from './uploaddecisionfile';
import searchByEditorNameOrByCaseName from './searchByEditorNameOrByCaseName';
import getAllByCourtIdsSaga from './getbycourt';
import getNewest from './getnewest';
import getRelationTree from './getrelationtree';
import getRelated from './getrelatedbyid';
import updateDecision from './updatedecision';
import getDecisionFileByDecisionId from './getDecisionFileByDecisionId';

export default function* apiDecisionSagas() {
  yield all([
    fork(getByIdSaga),
    fork(getByIdsSaga),
    fork(getAllByCourtIdsSaga),
    fork(createDecision),
    fork(getDecisionCaseType),
    fork(getDecisionType),
    fork(uploadDecisionFile),
    fork(searchByEditorNameOrByCaseName),
    fork(getNewest),
    fork(getRelationTree),
    fork(getRelated),
    fork(updateDecision),
    fork(getDecisionFileByDecisionId),
  ]);
}
