/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import Filter from '../../components/common/molecules/Filter';
import * as commonActions from '../../actions/commonactions';
import * as theoryItemApiAction from '../../actions/api/theoryitem';
import * as theoryItemApiSelector from '../../selectors/api/theoryitem';
import { refBind } from '../../utils/ref-bind';
import * as SelectUtils from '../../utils/AutocompleteMapper';
import { createTagName } from '../../utils/filter';

class TheorySearch extends Component {
  constructor(props) {
    super(props);
    this.state = { theoryFilter: '', selected: null };
    refBind(this, 'handleTheorySearch', 'handleChange');
  }

  componentDidMount() {
    const { actions } = this.props;
    actions.searchTheory('');
  }

  handleTheorySearch(theoryFilter) {
    const { actions } = this.props;
    this.setState({ theoryFilter });
    theoryFilter &&
      actions.debounce(theoryItemApiAction.findByTitle(theoryFilter));
  }

  handleChange(selected) {
    const { clearOnSelect, onTheoryChange } = this.props;
    if (!clearOnSelect) {
      this.setState({ selected });
    }
    onTheoryChange && onTheoryChange(selected);
  }

  render() {
    const { theoryItemData, ignoreOptions, isDisabled, isMulti } = this.props;
    const { theoryFilter, selected } = this.state;
    const currentTheoryItemFilter = createTagName(theoryFilter);
    const filterResult = theoryItemData[currentTheoryItemFilter];
    const theoryOptions = SelectUtils.removeIgnoredItems(
      SelectUtils.mapTheoryItems(filterResult),
      ignoreOptions,
    );

    return (
      <Filter
        isDisabled={isDisabled}
        options={theoryOptions || []}
        isMulti={isMulti || false}
        value={selected}
        onInputChange={this.handleTheorySearch}
        onChange={this.handleChange}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const theoryItemData = theoryItemApiSelector.getSearchData(state);
  return {
    ...ownProps,
    theoryItemData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      searchTheory: theoryItemApiAction.findByTitle,
      debounce: commonActions.debounceAction,
    },
    dispatch,
  ),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withImmutablePropsToJS,
)(TheorySearch);
