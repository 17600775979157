import * as ActionTypes from '../../../constants/ActionTypes';
import { generateApiSaga } from '../../../utils/sagas';
import { call, takeEvery } from 'redux-saga/effects';
import LicenseConnector from '../../../connectors/License';
import moment from 'moment';
import { DateFormat } from '../../../constants/DateFormat';

const doCreateLicense = generateApiSaga(
  LicenseConnector,
  function* (connector, action) {
    const data = action.payload.licenseData;

    const license = {
      expirationDate: moment(data.license.expirationDate)
        .endOf('day')
        .format(DateFormat.requestDateTime),
      role: `role/${data.license.role.value}`,
      company: `company/${data.license.company.value}`,
    };
    if (data.user) {
      license.user = `appuser/${data.user.id}`;
    }

    const licenses = [];
    for (let i = 0; i < data.quantity; i++) {
      licenses.push({ ...license });
    }
    return yield call([connector, connector.create], licenses);
  },
);

export default function* createLicense() {
  yield takeEvery(ActionTypes.API_LICENSE_CREATE_INIT, doCreateLicense);
}
