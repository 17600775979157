import { call, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import { generateApiSaga } from '../../../utils/sagas';
import AppUserConnector from '../../../connectors/AppUser';

const doFetch = generateApiSaga(AppUserConnector, function* (connector) {
  return yield call([connector, connector.getAll]);
});

export default function* getAll() {
  yield takeEvery(ActionTypes.API_USER_GET_ALL_INIT, doFetch);
}
