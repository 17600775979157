import { all, fork } from 'redux-saga/effects';
import setFavorite from './setfavorite';
import getFavorite from './getfavoritesbytype';
import getAllFavorites from './getallfavorites';
import getAllFavoritesExpanded from './getAllFavoritesExpanded';

export default function* favoriteSaga() {
  yield all([
    fork(getFavorite),
    fork(getAllFavorites),
    fork(setFavorite),
    fork(getAllFavoritesExpanded),
  ]);
}
