/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import ContentOuterBound from '../../components/common/atoms/ContentOuterBound';
import DefaultPageLayout from '../../components/common/atoms/DefaultPageLayout';
import Text, { TextType } from '../../components/common/atoms/Text';
import { FaUnlink } from 'react-icons/fa';

class Page404 extends React.Component {
  render() {
    return (
      <DefaultPageLayout>
        <ContentOuterBound>
          <div className="mt-5 d-flex align-items-center flex-column">
            <div style={{ fontSize: '10rem' }}>
              <FaUnlink />
            </div>
            <Text type={TextType.HEADER_1_LARGE}>404</Text>
          </div>
        </ContentOuterBound>
      </DefaultPageLayout>
    );
  }
}

export default Page404;
