/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import ProcurementButton from '../atoms/Button';
import { Link } from 'react-router-dom';
import { getEntityLink } from '../../../utils/link';
import { MEMBERSHIP as EntityTypes } from '../../../constants/EntityTypes';

const styles = {
  '.body': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: '11px',
    minWidth: '400px',
  },
  '.footer': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  '.editable': {
    height: '400px',
    width: '100%',
    fontSize: '12px',
  },
};

const propTypes = {
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  acceptClickHandler: PropTypes.func.isRequired,
  cancelClickHandler: PropTypes.func,
  cancelButtonText: PropTypes.string,
  acceptButtonText: PropTypes.string,
  isConfirmationOnly: PropTypes.bool,
  promoteSubscription: PropTypes.bool,
};

const defaultProps = {
  cancelClickHandler: () => {},
  cancelButtonText: 'Tilbage',
  acceptButtonText: 'Fjern relation',
  isConfirmationOnly: false,
  promoteSubscription: false,
};

export default class CustomModal extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      show,
      title,
      body,
      cancelClickHandler,
      acceptClickHandler,
      cancelButtonText,
      acceptButtonText,
      isConfirmationOnly,
      promoteSubscription,
    } = this.props;

    return (
      <Modal show={show} css={styles}>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className={'body'}>{body}</div>
        </Modal.Body>

        <Modal.Footer className={'footer'}>
          {promoteSubscription ? (
            <Link to={getEntityLink(EntityTypes.MEMBERSHIP)}>
              <ProcurementButton type="orange" onClick={acceptClickHandler}>
                {acceptButtonText}
              </ProcurementButton>
            </Link>
          ) : (
            <ProcurementButton type="orange" onClick={acceptClickHandler}>
              {acceptButtonText}
            </ProcurementButton>
          )}
          {!isConfirmationOnly && (
            <ProcurementButton type="bottom" onClick={cancelClickHandler}>
              {cancelButtonText}
            </ProcurementButton>
          )}
        </Modal.Footer>
      </Modal>
    );
  }
}

CustomModal.propTypes = propTypes;

CustomModal.defaultProps = defaultProps;
