import { put } from 'redux-saga/effects';
import { takeLatestWhenAppReady } from '../../../utils/sagas';
import * as ActionTypes from '../../../constants/ActionTypes';
import * as EntityType from '../../../constants/EntityTypes';
import * as processPageActions from '../../../actions/page/process';
import * as routerActionSelector from '../../../selectors/actions/router';

function* doLocationChange(action) {
  const path = routerActionSelector.getSplitPath(action);
  if (!path.length) return;

  const [entity, ...uri] = path;

  if (entity === EntityType.PROCESS) {
    yield put(processPageActions.pageLoad(uri));
  }
}

export default function* locationChange() {
  yield takeLatestWhenAppReady(
    ActionTypes.APP_ROUTER_LOCATION_CHANGE,
    doLocationChange,
  );
}
