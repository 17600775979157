import * as ActionTypes from '../../../constants/ActionTypes';
import { call, takeEvery } from 'redux-saga/effects';
import { generateApiSaga } from '../../../utils/sagas';
import LicenseConnector from '../../../connectors/License';

const doSave = generateApiSaga(LicenseConnector, function* (connector, action) {
  const licenses = action.payload.licenses;
  return yield call([connector, connector.delete], licenses);
});

export default function* deleteLicense() {
  yield takeEvery(ActionTypes.API_LICENSE_DELETE_INIT, doSave);
}
