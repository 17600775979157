import { takeLatestWhenAppReady } from '../../../utils/sagas';
import * as ActionTypes from '../../../constants/ActionTypes';
import * as searchPageActions from '../../../actions/page/search';
import { put, select } from '@redux-saga/core/effects';
import * as searchPageSelectors from '../../../selectors/page/search';

function* doUpdateFilter(action) {
  let newFilter = action.payload.filter;
  let curFilter = yield select(searchPageSelectors.getCurrentSearchFilter);
  if (!curFilter.equals(newFilter)) {
    yield put(searchPageActions.setFilter(newFilter));
  }
  yield put(searchPageActions.updateFilterComplete());
}

export default function* updateFilter(action) {
  yield takeLatestWhenAppReady(
    ActionTypes.PAGE_SEARCH_FILTER_UPDATE_INIT,
    doUpdateFilter,
  );
}
