/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Component } from 'react';
import Text, { TextType } from '../../components/common/atoms/Text';
import { Trans, withTranslation } from 'react-i18next';
import Draggable from 'react-draggable';
import { Button, Modal } from 'react-bootstrap';
import { FaInfoCircle, FaPaperPlane } from 'react-icons/all';
import { refBind } from '../../utils/ref-bind';
import { uiColors } from '../../components/common/styles/styles';
import PropTypes from 'prop-types';
import * as newsletterSelector from '../../selectors/page/newsletter';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import { createStructuredSelector } from 'reselect';

const styles = {
  '.tooltip-button': {
    backgroundColor: 'white',
    borderColor: 'white',
    '&:active': {
      backgroundColor: 'white',
    },
    '&:focus': {
      borderColor: 'white',
    },
    '&:focus-visible': {
      borderColor: 'white',
    },
  },
  '.tooltip-icon': {
    verticalAlign: 'baseline',
    fill: 'black',
    color: uiColors.BLUE_MAGENTA,
  },
  '.tooltip-bar': {
    display: 'flex',
    justifyContent: 'space-between',
  },
  '.button-icon': {
    verticalAlign: 'baseline',
  },
};

class WriteNewsletter extends Component {
  constructor(props) {
    super(props);
    refBind(
      this,
      'toggleModal',
      'handleTitleChange',
      'handleTextChange',
      'createNewsletter',
    );
    this.state = { modalIsOpen: false, title: '', text: '' };
  }

  static propTypes = {
    onNewsletterCreation: PropTypes.func.isRequired,
  };

  toggleModal() {
    this.setState({ modalIsOpen: !this.state.modalIsOpen });
  }

  handleTitleChange(event) {
    this.setState({ title: event.target.value });
  }

  handleTextChange(event) {
    this.setState({ text: event.target.value });
  }

  createNewsletter() {
    const { onNewsletterCreation } = this.props;
    const { title, text } = this.state;
    onNewsletterCreation({
      title,
      text,
    });
  }

  render() {
    const { modalIsOpen, title, text } = this.state;
    const { t, isButtonDisabled } = this.props;
    return (
      <div css={styles}>
        <div className="mb-4 text-capitalize">
          <Text type={TextType.HEADER_3}>
            <Trans i18nKey="write-newsletter.title" />
          </Text>
        </div>
        <div className="form-group">
          <label htmlFor="newsletter-title">
            <Trans i18nKey="write-newsletter.write-title" />
          </label>
          <input
            type="title"
            className="form-control"
            id="newsletter-title"
            onChange={this.handleTitleChange}
            value={title}
          />
        </div>
        <div className="form-group">
          <label htmlFor="newsletter-text">
            <Trans i18nKey="write-newsletter.write-text" />
          </label>
          <textarea
            className="form-control"
            id="newsletter-text"
            rows="3"
            onChange={this.handleTextChange}
            value={text}
          />
        </div>
        <div className="tooltip-bar">
          <div>
            <Text>
              <Trans i18nKey="write-newsletter.markdown-tooltip" />
            </Text>
            <Button
              size="sm"
              onClick={this.toggleModal}
              title="Tooltip"
              className="tooltip-button"
            >
              <FaInfoCircle className="tooltip-icon" />
            </Button>
          </div>
          <Button
            onClick={this.createNewsletter}
            disabled={isButtonDisabled || !title || !text}
          >
            <Text>
              <Trans i18nKey="Send email" />{' '}
            </Text>
            <FaPaperPlane className="button-icon" />
          </Button>
        </div>
        {modalIsOpen && (
          <Draggable handle=".handle">
            <Modal.Dialog>
              <Modal.Header className="handle">
                <Modal.Title>
                  <Trans i18nKey="write-newsletter.markdown-modal-header" />
                </Modal.Title>
                <Button
                  onClick={this.toggleModal}
                  className="seo-header-button"
                >
                  X
                </Button>
              </Modal.Header>
              <Modal.Body>
                <div
                  dangerouslySetInnerHTML={{
                    __html: t('write-newsletter.markdown-syntax'),
                  }}
                />
              </Modal.Body>
            </Modal.Dialog>
          </Draggable>
        )}
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  isButtonDisabled: newsletterSelector.getButtonIsDisabled,
});

export default compose(
  connect(mapStateToProps),
  withImmutablePropsToJS,
  withTranslation(),
)(WriteNewsletter);
