import { call, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import TheoryItemConnector from '../../../connectors/TheoryItem';
import { generateApiSaga } from '../../../utils/sagas';

var doGetAllTheory = generateApiSaga(
  TheoryItemConnector,
  function* (connector, action) {
    return yield call([connector, connector.getAll]);
  },
);

export default function* getAll() {
  yield takeEvery(ActionTypes.API_THEORY_ITEM_GET_ALL_INIT, doGetAllTheory);
}
