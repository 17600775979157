/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { fontSize } from '../styles/styles';

const rowStyle = {
  display: 'grid',
  gridTemplateColumns: 'repeat(5, 1.5rem)',
  gridGap: '0.25rem',
  '& > div': {
    display: 'flex',
    justifyContent: 'center',
    fontSize: fontSize.PARAGRAPH_SMALL,
  },
  '& * img': {
    width: '1.25rem',
  },
};

class ArticleCountGrid extends React.Component {
  render() {
    return (
      <div css={rowStyle}>
        <div>{this.props.children[0]}</div>
        <div>{this.props.children[1]}</div>
        <div>{this.props.children[2]}</div>
        <div>{this.props.children[3]}</div>
        <div>{this.props.children[4]}</div>
      </div>
    );
  }
}

export default ArticleCountGrid;
