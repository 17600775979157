import { put, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import * as decisionApiAction from '../../../actions/api/decision';
import { callAndAwaitApi } from '../../../utils/sagas';
import { push } from 'connected-react-router/immutable';
import * as linkUtils from '../../../utils/link';
import * as urls from '../../../constants/Urls';
import { CREATE_DECISION_MODE } from '../../../constants/CreateDecisionMode';
import * as EntityTypes from '../../../constants/EntityTypes';
import moment from 'moment';
import { DateFormat } from '../../../constants/DateFormat';

/**
 * Redirects the page to the newly created decision page and calls a handler function that is part of the action.
 * @param action decision action objects that holds action type, payload of submit decision.
 * @param createdDecisionId a decision object that comes from backend with a decision id.
 */
function* redirectToNewDecision(action, createdDecisionId) {
  yield put(
    push(
      linkUtils.buildPageLink(urls.DECISION_ROUTE, 'all', createdDecisionId),
    ),
  );
  yield callHandler(action);
}

/**
 * Loads the latest object for the decision that just has been updated, from the back-end and calls passed handler.
 * @param action decision action objects that holds action type and payload of submit decision.
 */
function* loadEditedDecision(action) {
  const { decision } = action.payload;
  yield put(decisionApiAction.getById(decision.id));
  yield callHandler(action);
}

/**
 * Calls the passed handler if a handler is passed to the submit action.
 * @param action decision action objects that holds action type and payload of submit decision.
 */
function* callHandler(action) {
  const { handler } = action.payload;
  // eslint-disable-next-line no-unused-expressions
  handler?.();
}

/**
 * Calls the responseFunction if the decision action has no file otherwise it will upload the file for the decision and if the file get uploaded successfully, it calls the callback function.
 * @param action decision action objects that holds action type and payload of submit decision.
 * @param decisionId decision id of the operated decision.
 * @param responseFunction a decision object that comes from backend with a decision id.
 */
function* handleResponse(action, decisionId, responseFunction) {
  if (!decisionId) {
    return;
  }
  if (action.payload.file) {
    const uploadedDataResult = yield callAndAwaitApi(
      decisionApiAction.uploadDecisionFile(decisionId, action.payload.file),
    );
    if (uploadedDataResult.success?.payload.response) {
      return yield responseFunction(action, decisionId);
    }
  }
  return yield responseFunction(action, decisionId);
}

/**
 * Creates a decision or update a decision based on the mode passed through action and redirect to or load the created/updated decision.
 * @param action decision action objects that holds action type and payload of submit decision.
 */
function* submitData(action) {
  const { decision, mode } = action.payload;
  prepareDecisionData(decision);
  if (mode === CREATE_DECISION_MODE.NEW) {
    const createdDecision = yield callAndAwaitApi(
      decisionApiAction.createDecision(decision),
    );
    return yield handleResponse(
      action,
      createdDecision.success?.payload.responseData.id,
      redirectToNewDecision,
    );
  } else {
    const updatedDecision = yield callAndAwaitApi(
      decisionApiAction.updateDecision(decision),
    );
    return yield handleResponse(
      action,
      updatedDecision.success?.payload.responseData.id,
      loadEditedDecision,
    );
  }
}

const prepareDecisionData = (decision) => {
  decision.court = `${EntityTypes.COURT}/${decision.court.value}`;
  decision.decisionCaseType = decision.decisionCaseType?.value;
  decision.decisionType = decision.decisionType?.value;
  decision.initiatedDate = decision.initiatedDate
    ? moment(decision.initiatedDate).format(DateFormat.requestDate)
    : null;
  decision.rulingDate = decision.rulingDate
    ? moment(decision.rulingDate).format(DateFormat.requestDate)
    : null;
};

export default function* submitFormData() {
  yield takeEvery(ActionTypes.PAGE_ADMIN_DECISION_SUBMIT_FORM, submitData);
}
