import * as ActionTypes from '../../constants/ActionTypes';

export const pageLoad = (uri) => ({
  type: ActionTypes.APP_PAGE_THEORY_LOAD,
  payload: { uri },
});
export const selectNode = (node) => ({
  type: ActionTypes.APP_PAGE_THEORY_SELECT_NODE,
  payload: { node },
});
export const getChildren = (nodeId) => ({
  type: ActionTypes.API_THEORY_ITEM_GET_ALL_CHILDREN_INIT,
  payload: { nodeId },
});
export const setLastUri = (uri) => ({
  type: ActionTypes.PAGE_THEORY_LAST_URI,
  payload: { uri },
});
export const setNavigation = (previousNode, nextNode) => ({
  type: ActionTypes.PAGE_THEORY_SET_NAVIGATION,
  payload: { previousNode, nextNode },
});
export const createTheoryItem = (itemData, onSuccessHandler) => ({
  type: ActionTypes.PAGE_THEORY_CREATE_THEORY_ITEM,
  payload: { itemData, onSuccessHandler },
});
