import BaseConnector from './Base';
import * as EntityTypes from '../constants/EntityTypes';
import * as Projections from '../constants/Projections';

export default class ProcessConnector extends BaseConnector {
  constructor(...args) {
    super(...args);
    this.entityType = EntityTypes.PROCESS;
  }

  getAll() {
    return this.authGet(
      this.query().withProjection(Projections.WITHID).build(),
    );
  }
}
