import { put, select } from 'redux-saga/effects';
import { head, last } from 'lodash';
import {
  awaitApiCall,
  callAndAwaitApi,
  takeLatestWhenPreloadReady,
} from '../../../utils/sagas';
import * as ActionTypes from '../../../constants/ActionTypes';
import * as TheoryItemType from '../../../constants/TheoryItemType';
import * as theoryItemApiSelectors from '../../../selectors/api/theoryitem';
import * as theoryPageSelectors from '../../../selectors/page/theory';
import * as theoryItemActions from '../../../actions/api/theoryitem';
import * as dataActions from '../../../actions/data';
import * as theoryApiSelectors from '../../../selectors/api/theory';
import * as favoriteApiActions from '../../../actions/api/favorite';
import { PageEntities } from '../../../constants/PageEntities';
import * as favoriteApiSelector from '../../../selectors/api/favorite';
import * as theoryPageActions from '../../../actions/page/theory';
import { hasAnyPermissions } from '../../../selectors/store/permissions';
import { PERMISSIONS } from '../../../constants/Permissions';
import * as appActions from '../../../actions/app';

function* doTheoryPageLoad(action) {
  let retrieveTheoryItemByDisplayNodeInitAction;
  let retrieveTheoryItemByDisplayNodeApiResults;
  let retrieveGetChildrenInitAction;
  let retrieveGetChildrenApiResults;
  // Lookup theoryItem by URI
  if (!action.payload.uri) return; // Don't do loading if you haven't selected a URI

  yield getFavorites();
  yield getTheoryItemTypes();

  let isLoaded = yield select(
    theoryItemApiSelectors.isNavigationLoadedForTheory,
    action.payload.uri,
  );
  if (!isLoaded) {
    const theory = yield select(theoryApiSelectors.getDefault);
    if (theory) {
      yield put(dataActions.preLoadTheory(theory));
    }
  }

  let theoryItem = yield select(
    theoryPageSelectors.getByUri,
    action.payload.uri,
  );
  let parentPath = yield select(theoryPageSelectors.getParents, theoryItem);
  if (!theoryItem) {
    yield callAndAwaitApi(theoryItemActions.getByUri(action.payload.uri));
    theoryItem = yield select(
      theoryItemApiSelectors.getByUri,
      action.payload.uri,
    );
  } else if (parentPath[0].get('node') == null) {
    // If we have the item but not its parents all the way up
    yield callAndAwaitApi(
      theoryItemActions.getByIdWithParents(parentPath[1].get('parent')),
    );
  }
  if (!theoryItem) {
    yield put(appActions.navigateTo404());
    return;
  }

  if (theoryItem.get('parent')) {
    // If parent is null, we assume top level, otherwise we need to select everything under the second highest level
    // TODO: THIS MIGHT NOT WORK IF YOU ARE TOO FAST
    let parentPath = yield select(theoryPageSelectors.getParents, theoryItem);
    let secondElement = parentPath[1];

    retrieveTheoryItemByDisplayNodeInitAction = yield put(
      theoryItemActions.getChildren(secondElement.getIn(['node', 'id'])),
    );
    retrieveTheoryItemByDisplayNodeApiResults = yield awaitApiCall(
      retrieveTheoryItemByDisplayNodeInitAction,
    );
  } else {
    retrieveGetChildrenInitAction = yield put(
      theoryItemActions.getChildren(theoryItem.getIn(['node', 'id'])),
    );
    retrieveGetChildrenApiResults = yield awaitApiCall(
      retrieveGetChildrenInitAction,
    );
  }

  let mainParentNodeObject;
  let nextNodeId;
  let nextNode;
  let previousNode;
  let previousNodeObject;
  let nextNodeObject;
  let treeIndexSkip;
  let articleIndexSkip;
  const article = yield select(theoryPageSelectors.getArticle);
  if (article) {
    mainParentNodeObject = (yield select(
      theoryPageSelectors.getParent,
      article,
    ))?.toJS();
  }

  let mainParentNodeChildren =
    mainParentNodeObject &&
    mainParentNodeObject.node.childTheoryItems
      .map((child) => child.id)
      ?.sort((c1, c2) => c1 - c2);
  const articleId = article && theoryPageSelectors.getNodeId(article);
  const parentNodeIndexId = mainParentNodeChildren?.indexOf(articleId);
  const navigationTree = yield select(theoryPageSelectors.getNavigationTree);
  const navigationTreeObject = navigationTree && navigationTree.toJS();
  if (mainParentNodeObject) {
    const mainParentNodeIndexId =
      navigationTreeObject &&
      navigationTreeObject
        .map((child) => child.node.id)
        .indexOf(mainParentNodeObject.node.id);
    if (parentNodeIndexId === 0) {
      previousNodeObject = mainParentNodeObject.node;
    } else {
      const previousNodeId = mainParentNodeChildren[parentNodeIndexId - 1];
      previousNode = yield select(theoryApiSelectors.getById, previousNodeId);
      previousNodeObject = previousNode && previousNode.toJS().node;
      articleIndexSkip = 1;
      while (
        previousNodeObject &&
        previousNodeObject.theoryItemType === TheoryItemType.PARAGRAPH
      ) {
        if (parentNodeIndexId - articleIndexSkip >= 0) {
          previousNode = yield select(
            theoryApiSelectors.getById,
            mainParentNodeChildren[parentNodeIndexId - articleIndexSkip],
          );
          previousNodeObject = previousNode?.toJS().node;
          articleIndexSkip++;
        } else {
          previousNodeObject = mainParentNodeObject.node;
        }
      }
    }
    if (parentNodeIndexId === mainParentNodeChildren.length - 1) {
      nextNodeObject =
        navigationTreeObject[mainParentNodeIndexId + 1]?.node ?? undefined;
    } else {
      nextNodeId = mainParentNodeChildren[parentNodeIndexId + 1];
      nextNode = yield select(theoryApiSelectors.getById, nextNodeId);
      nextNodeObject = nextNode && nextNode.toJS().node;
      let nextArticleIndexSkip = 1;
      while (
        nextNodeObject &&
        nextNodeObject.theoryItemType === TheoryItemType.PARAGRAPH
      ) {
        if (
          parentNodeIndexId + nextArticleIndexSkip <
          mainParentNodeChildren.length
        ) {
          nextNode = yield select(
            theoryApiSelectors.getById,
            mainParentNodeChildren[parentNodeIndexId + nextArticleIndexSkip],
          );
          nextNodeObject = nextNode && nextNode.toJS().node;
          nextArticleIndexSkip++;
        } else {
          nextNodeObject =
            navigationTreeObject[mainParentNodeIndexId + 1]?.node ?? undefined;
        }
      }
    }
  } else {
    const articleIndexId =
      article &&
      navigationTreeObject
        .map((article) => article.node.id)
        .indexOf(article.getIn(['node', 'id']));
    const previousNodeChildren =
      navigationTreeObject[articleIndexId - 1]?.node?.childTheoryItems
        .map((child) => child.id)
        .sort((c1, c2) => c1 - c2) ?? undefined;
    const articleObject = article && article.toJS();
    const currentArticleChildren =
      articleObject?.node?.childTheoryItems
        .map((child) => child.id)
        .sort((c1, c2) => c1 - c2) ?? undefined;
    previousNode = yield select(
      theoryApiSelectors.getById,
      last(previousNodeChildren),
    );
    previousNodeObject = previousNode && previousNode.toJS().node;
    nextNode = yield select(
      theoryApiSelectors.getById,
      head(currentArticleChildren),
    );
    nextNodeObject = nextNode && nextNode.toJS().node;
    const previousChildrenLastIndex =
      previousNodeChildren && previousNodeChildren.length - 1;
    articleIndexSkip = 1;
    treeIndexSkip = 1;
    while (
      previousNodeObject &&
      previousNodeObject.theoryItemType === TheoryItemType.PARAGRAPH
    ) {
      if (previousNodeChildren.length > articleIndexSkip) {
        previousNode = yield select(
          theoryApiSelectors.getById,
          previousNodeChildren[previousChildrenLastIndex - articleIndexSkip],
        );
        previousNodeObject = previousNode && previousNode.toJS().node;
        articleIndexSkip++;
      } else {
        previousNodeObject =
          navigationTreeObject[articleIndexId - treeIndexSkip]?.node ??
          undefined;
        treeIndexSkip++;
      }
    }

    articleIndexSkip = 1;
    treeIndexSkip = 1;
    while (
      nextNodeObject &&
      nextNodeObject.theoryItemType === TheoryItemType.PARAGRAPH
    ) {
      if (articleObject.node.childTheoryItems.length > articleIndexSkip) {
        nextNode = yield select(
          theoryApiSelectors.getById,
          currentArticleChildren[articleIndexSkip],
        );
        nextNodeObject = nextNode && nextNode.toJS().node;
        articleIndexSkip++;
      } else {
        nextNodeObject =
          navigationTreeObject[articleIndexId + treeIndexSkip].node;
        treeIndexSkip++;
      }
    }
  }
  yield put(
    theoryPageActions.setNavigation(previousNodeObject, nextNodeObject),
  );
  if (
    retrieveTheoryItemByDisplayNodeApiResults?.success ||
    retrieveGetChildrenApiResults?.success
  ) {
    yield put({ type: ActionTypes.APP_PAGE_THEORY_LOADED });
  }
}

function* getTheoryItemTypes() {
  const hasNoTheoryItemData = !(yield select(
    theoryItemApiSelectors.getTheoryItemTypes,
  )).size;
  if (
    hasNoTheoryItemData &&
    (yield select(hasAnyPermissions, PERMISSIONS.THEORY_UPDATE_CONTENT))
  ) {
    yield put(theoryItemActions.getAllTheoryItemTypes());
  }
}

function* getFavorites() {
  const favorites = yield select(
    favoriteApiSelector.getPageFavorites,
    PageEntities.THEORY_PAGE,
  );
  if (!(favorites && favorites.size)) {
    yield put(favoriteApiActions.getFavoritesByType(PageEntities.THEORY_PAGE));
  }
}

export default function* theoryPageLoad() {
  yield takeLatestWhenPreloadReady(
    ActionTypes.APP_PAGE_THEORY_LOAD,
    doTheoryPageLoad,
  );
}
