/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import ContentOuterBound from '../../components/common/atoms/ContentOuterBound';
import DefaultPageLayout from '../../components/common/atoms/DefaultPageLayout';
import NoPermission from '../../components/common/atoms/NoPermission';

class NoPermissionPage extends React.Component {
  render() {
    return (
      <DefaultPageLayout>
        <ContentOuterBound>
          <NoPermission />
        </ContentOuterBound>
      </DefaultPageLayout>
    );
  }
}

export default NoPermissionPage;
