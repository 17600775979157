/** @jsx jsx */
import { Global, jsx } from '@emotion/core';
import React from 'react';
import RangePicker from 'react-range-picker';
import { rgbaColors, uiColors } from '../styles/styles';
import { Trans } from 'react-i18next';
import moment from 'moment';
import { DateFormat } from '../../../constants/DateFormat';
import { FaRegCalendar, FaTimes } from 'react-icons/fa';
import { refBind } from '../../../utils/ref-bind';

const placeholderStyle = {
  padding: '5px 10px',
  borderRadius: '5px',
  cursor: 'pointer',
  border: `1px solid ${uiColors.GREY}`,
  textTransform: 'title-case',
  ':hover': {
    boxShadow: `1px 1px 1px 0 ${rgbaColors.BLACK_SHADOW_30}`,
    border: `1px solid ${uiColors.DARK}`,
  },
};

const wrapperStyle = {
  '.date-picker-app-wrapper': {
    width: '100%',
  },
};

const panelStyles = {
  '.calendar': {
    zIndex: 5000,
    position: 'relative',
  },
};

// read more about usage at https://www.npmjs.com/package/react-range-picker
class Datepicker extends React.Component {
  constructor(props) {
    super(props);
    refBind(this, 'convertDate', 'placeholder', 'getDateView', 'handleClear');
    this.state = { key: Math.random(), defaultValue: props.defaultValue };
  }

  componentDidMount() {
    setTimeout(() => {
      if (this.props.parentContainer && this.props.parentContainer.current) {
        this.props.parentContainer.current.appendChild(this.getDatepicker());
      }
    });
  }

  getDatepicker() {
    return document.getElementById('__range-picker-container') || null;
  }

  convertDate(date) {
    const { format } = this.props;
    return date ? moment(date).format(format || DateFormat.requestDate) : '';
  }

  placeholder({ startDate, endDate }) {
    return (
      <div css={placeholderStyle}>
        {startDate
          ? this.getDateView(startDate, endDate)
          : this.getPlaceholder(this.props.placeholderKey)}
      </div>
    );
  }

  render() {
    return (
      <div css={wrapperStyle}>
        <Global styles={panelStyles} />
        <RangePicker
          {...this.props}
          defaultValue={this.state.defaultValue}
          key={this.state.key}
          closeOnSelect
          placeholder={this.placeholder}
          footer={() => null}
        />
      </div>
    );
  }

  getDateView(startDate, endDate) {
    startDate = this.convertDate(startDate);
    endDate = this.convertDate(endDate);
    return (
      <div className="d-flex justify-content-between">
        <span>{`${startDate} ${endDate ? `- ${endDate}` : ''}`}</span>
        <div className="d-flex align-items-center" onClick={this.handleClear}>
          <FaTimes />
        </div>
      </div>
    );
  }

  handleClear(event) {
    event.stopPropagation();
    event.preventDefault();
    this.props.onDateSelected &&
      this.props.onDateSelected(undefined, undefined);
    this.setState({ key: Math.random(), defaultValue: null });
  }

  getPlaceholder(key) {
    const placeholderKey =
      key === undefined ? 'common.date-picker.placeholder' : key;
    return key ? <Trans i18nKey={placeholderKey} /> : <FaRegCalendar />;
  }
}

export default Datepicker;
