import { call, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import { generateApiSaga } from '../../../utils/sagas';
import DecisionConnector from '../../../connectors/Decision';

const doGetDecisionFileByDecisionId = generateApiSaga(
  DecisionConnector,
  function* (connector, action) {
    const { id } = action.payload;
    return yield call([connector, connector.getDecisionFileByDecisionId], id);
  },
);

export default function* getDecisionFileByDecisionId() {
  yield takeEvery(
    ActionTypes.API_DECISION_FILE_GET_BY_DECISION_ID_INIT,
    doGetDecisionFileByDecisionId,
  );
}
