/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { FaLowVision } from 'react-icons/fa';
import Text, { TextType } from './Text';
import { Trans } from 'react-i18next';

class NoPermission extends React.Component {
  render() {
    return (
      <div className="mt-5 d-flex align-items-center flex-column">
        <div style={{ 'font-size': '15rem' }}>
          <FaLowVision />
        </div>
        <Text type={TextType.HEADER_1_LARGE}>
          <Trans i18nKey={'no-permission'} />{' '}
        </Text>
      </div>
    );
  }
}

export default NoPermission;
