import { call, select, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import { generateApiSaga } from '../../../utils/sagas';
import SignupConnector from '../../../connectors/Signup';
import { baseAuthUrl } from '../../../selectors/store/app';

const doSubmit = generateApiSaga(
  SignupConnector,
  function* (connector, action) {
    let signupRequest = action.payload.signup;
    return yield call([connector, connector.signup], signupRequest);
  },
  function* (ConnectorClass) {
    const url = yield select(baseAuthUrl);
    return yield new ConnectorClass(url);
  },
);

export default function* submitSignup() {
  yield takeEvery(ActionTypes.API_SIGNUP_SUBMIT_INIT, doSubmit);
}
