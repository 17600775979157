import React from 'react';
import ConditionalRoute from './ConditionalRoute';
import * as Urls from '../../constants/Urls';
import PropTypes from 'prop-types';

const AuthenticatedRoute = ({ component: Component, isLoggedIn, ...rest }) => {
  return (
    <ConditionalRoute
      condition={isLoggedIn}
      component={Component}
      fallbackUrl={Urls.LOGIN_ROUTE}
    />
  );
};

AuthenticatedRoute.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
};

export default AuthenticatedRoute;
