import * as EntityTypes from '../constants/EntityTypes';
import { createLawPrefaceItemText } from './LawHelpers';

export const getNavTreeLabel = (node) => {
  switch (node.entityType) {
    case EntityTypes.JURISDICTION:
      return node.alpha2Code;
    case EntityTypes.COURT:
      return node.name;
    case EntityTypes.DECISION:
      return `${node.rulingDate}     ${node.caseName}`;
    case EntityTypes.LAWGROUP:
      return node.label;
    case EntityTypes.LAWITEM:
      return node.title;
    case EntityTypes.THEORYITEM:
      return node.theoryItemType === 'SECTION'
        ? `${node.sectionNumber} ${node.title}`
        : `${node.sectionNumber}(${node.subSectionNumber})`;
    case EntityTypes.LAWPREFACEITEM:
      return createLawPrefaceItemText(node);
    case EntityTypes.LAW:
      return node.fromDate
        ? `${node.name} - ${new Date(node.fromDate).getFullYear()}`
        : `${node.name}`;
    case EntityTypes.PROCESS:
      return node.name;
    case EntityTypes.PROCESSSTEP:
      return `${node.sectionNumber} ${node.name}`;
    case EntityTypes.ARTICLEFOOTER:
      return node.title;
    case EntityTypes.LAW_ITEM_RELATIONSHIP:
      return node.title;
    case EntityTypes.TAXONOMY:
      return [node.description, node.code].filter(Boolean).join(': ');
    default:
      // Lint want a default branch - this is the one.
      return (
        node.label ||
        node.content ||
        node.uri ||
        node.rawExcelLabel ||
        node.title ||
        node.name ||
        node.uri
      );
  }
};
