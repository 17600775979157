/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component } from 'react';
import { Navbar } from 'react-bootstrap';
import ContentOuterBound from '../../components/common/atoms/ContentOuterBound';
import linkLogo from '../../images/linklogo.png';
import { fontColors } from '../../components/common/styles/styles';

const styles = {
  position: 'fixed',
  top: 0,
  right: 0,
  left: 0,
  zIndex: 1030,
  '& .navbar-light .navbar-brand, & .navbar-light .navbar-nav .nav-link': {
    color: fontColors.WHITE,
  },
  '& .navbar-light .navbar-text a, & a': {
    color: fontColors.WHITE,
    textDecoration: 'none',
  },
  '& .navbar-brand': {
    marginRight: '2em',
    img: {
      position: 'relative',
      top: '3px',
    },
  },
  '& .navbar-nav .nav-link+.nav-link': {
    marginLeft: '1.5em',
  },
  '& .navbar-nav .nav-link+.nav-item': {
    marginLeft: '4.5em',
    fontSize: '1rem',
    color: fontColors.WHITE,
    alignSelf: 'center',
  },
};

class UnsubscribeHeader extends Component {
  render() {
    return (
      <div css={styles}>
        <ContentOuterBound>
          <Navbar>
            <Navbar.Brand>
              <a href="/">
                <img
                  src={linkLogo}
                  alt="logo"
                  className="d-inline-block align-top"
                />{' '}
                ProcurementLink
              </a>
            </Navbar.Brand>
          </Navbar>
        </ContentOuterBound>
      </div>
    );
  }
}

export default UnsubscribeHeader;
