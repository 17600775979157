import { Filter } from '../../utils/filter';

export const getResponseData = (action) => action.payload.responseData;
export const getEmbeddedResponseData = (action) =>
  getResponseData(action)._embedded;
export const getResponseDataByEntityType = (action, entity) =>
  getEmbeddedResponseData(action)[entity];
export const getAllPagesRequestType = (action) =>
  action.payload.initAction.payload.initAction.type;
export const getResponsePagingData = (action) => getResponseData(action).page;
export const getInitAction = (action) => action.payload.initAction;
export const getFilter = (action) => {
  let { url, queryParams } = action.payload.filter;
  return new Filter(url, queryParams);
};
