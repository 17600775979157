import {
  STATE_ENTITY_PROPERTY,
  STATE_ROOT_PROPERTY,
} from '../../constants/StateProperties';

export const getAll = (state) =>
  state.getIn([
    STATE_ROOT_PROPERTY.api,
    'permission',
    STATE_ENTITY_PROPERTY.data,
  ]);
