import EntityFields from '../../constants/EntityFields';
import * as EntityTypes from '../../constants/EntityTypes';
import { getByFn } from './index';

export const getByUri = (state, uri) => {
  let stuff = getByFn(
    state,
    EntityTypes.THEORY,
    (x) => x.get(EntityFields[EntityTypes.THEORY].URI) === uri,
  );
  let stuff2 = getByFn(
    state,
    EntityTypes.THEORYITEM,
    (x) => x.get(EntityFields[EntityTypes.THEORYITEM].URI) === uri,
  );
  return stuff || stuff2;
};
export const getAll = (state) =>
  state.getIn(['api', EntityTypes.THEORY, 'data']);
export const getDefault = (state) =>
  state.getIn(['api', EntityTypes.THEORY, 'data']).first();
export const getById = (state, id) =>
  state.getIn(['api', EntityTypes.THEORYITEM, 'data', id]);
export const getTheoryById = (state, theoryId) =>
  state.getIn(['api', EntityTypes.THEORY, 'data', '' + theoryId]);
export const getTheoryItemById = (state, id) =>
  state.getIn(['api', EntityTypes.THEORYITEM, 'data', id]);
export const getAllTheoryItems = (state) =>
  state.getIn(['api', EntityTypes.THEORYITEM, 'data']);
