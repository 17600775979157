/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component } from 'react';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form, Nav, Navbar, NavDropdown, Spinner } from 'react-bootstrap';
import ContentOuterBound from '../../components/common/atoms/ContentOuterBound';
import * as Urls from '../../constants/Urls';
import * as authSelectors from '../../selectors/store/auth';
import * as appSelectors from '../../selectors/store/app';
import * as authActions from '../../actions/auth';
import * as taxonomyApiSelector from '../../selectors/api/taxonomy';
import { bindActionCreators } from 'redux';
import { FaLock, FaUser, FaUserCog } from 'react-icons/fa';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { getEntityLink, getPageLink } from '../../utils/link';
import linkLogo from '../../images/linklogo.png';
import AutoSuggest from '../../components/common/molecules/AutoSuggest';
import * as EntityTypes from '../../constants/EntityTypes';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import { push } from 'connected-react-router/immutable';
import { Trans } from 'react-i18next';
import { fontColors, uiColors } from '../../components/common/styles/styles';
import SliderToggle from '../../components/common/atoms/SliderToggle';
import * as headerActions from '../../actions/page/header';
import ControlledByPermission from '../shared/ControlledByPermission';
import { AdminHomePagePermissions } from '../../constants/PagePermissions';
import { refBind } from '../../utils/ref-bind';
import * as appUserApiSelector from '../../selectors/api/appuser';
import { head } from 'lodash';
import * as searchPageActions from '../../actions/page/search';
import * as permissionSelectors from '../../selectors/store/permissions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SMALL_SCREEN } from '../../constants/ScreenSize';

const styles = {
  position: 'fixed',
  top: 0,
  right: 0,
  left: 0,
  zIndex: 1030,
  backgroundColor: uiColors.PURPLE_DARK,
  '& .contact-info': {
    marginLeft: '3em',
    a: {
      svg: {
        marginBottom: '2px',
      },
      span: {
        fontSize: '14px',
      },
    },
    'a + a': {
      marginLeft: '1em',
    },
  },
  '& .navbar-light .navbar-brand, & .navbar-light .navbar-nav .nav-link': {
    color: fontColors.WHITE,
  },
  '& .navbar-light .navbar-text a, & a': {
    color: fontColors.GREY,
    textDecoration: 'none',
    '@media (max-width: 991px)': {
      color: fontColors.BLACK,
    },
  },
  '& .navbar-brand': {
    marginRight: '2em',
    img: {
      position: 'relative',
      top: '3px',
    },
  },
  '& .navbar-expand': {
    justifyContent: 'space-between',
  },
  '& .navbar-nav .nav-link+.nav-link': {
    marginLeft: '1.5em',
  },
  '& .navbar-nav .nav-link+.nav-item': {
    marginLeft: '4.5em',
    fontSize: '1rem',
    color: fontColors.WHITE,
    alignSelf: 'center',
  },
  '& form ': {
    '& .input-group-text': {
      backgroundColor: 'white',
    },
    '& input': {
      borderLeft: 'none',
    },
  },
  '& .spinner-border': {
    position: 'absolute',
    top: '20px',
    right: '20px',
  },
  '& .dropdown-list svg': {
    width: '15px',
    marginRight: '20px',
  },
  '.dropdown-toggle::after': {
    display: 'none',
  },
  '.dropdown-menu-right': {
    boxShadow: '5px 10px 5px #8888',
    border: '1px solid #8888',
    capitalize: 'true',
  },
  '.toggle-label': {
    color: fontColors.GREY,
    textDecoration: 'none',
    '@media (max-width: 991px)': {
      color: fontColors.BLACK,
    },
  },
};

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      keyword: undefined,
    };
    refBind(
      this,
      '_onLogout',
      '_onSubmit',
      'onValueChange',
      '_onProfileClick',
      'toggleDkJurisdiction',
      '_onAdminClick',
      'renderDropdownMenu',
      'renderSearch',
    );
  }

  static propTypes = {};

  static defaultProps = {};

  _onLogout() {
    this.props.actions.logout();
  }

  _onProfileClick() {
    this.props.actions.push(getPageLink(Urls.PROFILE_ROUTE));
  }

  _onAdminClick() {
    this.props.actions.push(getPageLink(Urls.ADMIN_HOME_ROUTE));
  }

  _onSubmit(event) {
    event.preventDefault();
    this.props.actions.search(this.state.keyword, true);
    this.setState({ keyword: '' });
  }

  onValueChange = (value) => {
    this.setState({ keyword: head(value.split(': ')) });
  };

  toggleDkJurisdiction(value) {
    const jurisdictionFilter = { ...this.props.jurisdiction, dk: value };
    this.props.actions.updateJurisdictionFilter(jurisdictionFilter);
  }

  render() {
    const { isLoggedIn, hasLiveRequests, currentUser } = this.props;
    const isBigScreen = window.innerWidth > SMALL_SCREEN;

    return (
      <div css={styles}>
        {hasLiveRequests && (
          <Spinner animation="border" variant="light" size="sm" />
        )}
        <ContentOuterBound>
          <Navbar>
            <Navbar.Brand as={Link} to="/">
              <img
                src={linkLogo}
                alt="logo"
                className="d-inline-block align-top"
              />{' '}
              ProcurementLink
            </Navbar.Brand>
            {isBigScreen ? (
              <div>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse>
                  {isLoggedIn && this.renderSearch()}
                  <Nav className="ml-auto text-capitalize">
                    <Nav.Link as={Link} to={getEntityLink(EntityTypes.LAW)}>
                      <Trans i18nKey="header.law" />
                    </Nav.Link>
                    <Nav.Link
                      as={Link}
                      to={getEntityLink(EntityTypes.DECISION)}
                    >
                      <Trans i18nKey="header.decision" />
                    </Nav.Link>
                    <Nav.Link as={Link} to={getEntityLink(EntityTypes.THEORY)}>
                      <Trans i18nKey="header.theory" />
                    </Nav.Link>
                    <Nav.Link as={Link} to={getEntityLink(EntityTypes.PROCESS)}>
                      <Trans i18nKey="header.process" />
                    </Nav.Link>
                    <Nav.Link
                      as={Link}
                      to={getEntityLink(EntityTypes.TAXONOMY)}
                    >
                      <Trans i18nKey="header.taxonomy" />
                    </Nav.Link>
                    <Nav.Item as={Link} to={'/contact'}>
                      <Trans i18nKey="header.contact" />
                    </Nav.Item>
                    {isLoggedIn && currentUser && this.renderDropdownMenu()}
                    {!isLoggedIn && (
                      <Nav.Link as={Link} to={'/login'}>
                        <Trans i18nKey="header.login" />
                      </Nav.Link>
                    )}
                  </Nav>
                </Navbar.Collapse>
              </div>
            ) : (
              <div>{this.renderMobileBurgerMenu()}</div>
            )}
          </Navbar>
        </ContentOuterBound>
      </div>
    );
  }

  renderDropdownMenu() {
    const { currentUser, jurisdiction, togglePermission } = this.props;
    return (
      <div style={{ marginLeft: '1.5em' }}>
        <NavDropdown
          id="nav-menu"
          title={`${currentUser?.node.firstName} ${currentUser?.node.lastName}`}
          className="dropdown-list"
          alignRight
        >
          <NavDropdown.Item onClick={this._onProfileClick}>
            <FaUser /> <Trans i18nKey="header.profile" />
          </NavDropdown.Item>
          <ControlledByPermission
            anyPermissions={AdminHomePagePermissions.permissions}
            render={
              <NavDropdown.Item onClick={this._onAdminClick}>
                <FaUserCog />
                <Trans i18nKey="header.admin" />
              </NavDropdown.Item>
            }
          ></ControlledByPermission>
          <NavDropdown.Item onClick={this._onLogout}>
            <FaLock />
            <Trans i18nKey="header.logout" />
          </NavDropdown.Item>
          {togglePermission && (
            <div className="ml-4 mr-4 text-nowrap navbar-text navbar-light">
              <SliderToggle
                value={jurisdiction.dk}
                onChange={this.toggleDkJurisdiction}
              >
                <Trans i18nKey="header.jurisdiction-dk" />
              </SliderToggle>
            </div>
          )}
        </NavDropdown>
      </div>
    );
  }

  renderSearch() {
    const { taxonomy, isKeywordsLoading } = this.props;
    const keywords = Object.values(taxonomy).map((keyword) => ({
      value: [keyword.node.description, keyword.node.code]
        .filter(Boolean)
        .join(': '),
    }));
    return (
      <Form inline onSubmit={this._onSubmit}>
        <AutoSuggest
          keywords={isKeywordsLoading ? [] : keywords}
          value={this.state.keyword}
          onValueChange={this.onValueChange}
          placeholder="header.search-placeholder"
        />
      </Form>
    );
  }

  renderSearchBurgerItem() {
    return (
      <NavDropdown.Item onClick={this._onSubmit}>
        <Trans i18nKey="header.search" />
      </NavDropdown.Item>
    );
  }

  renderMobileBurgerMenu() {
    const { isLoggedIn, currentUser, togglePermission, jurisdiction } =
      this.props;
    return (
      <NavDropdown
        id="burger-menu"
        title={
          <span>
            <FontAwesomeIcon icon={faBars} size="lg" color={fontColors.GREY} />
          </span>
        }
        className="text-capitalize"
        alignRight
      >
        <NavDropdown.Item as={Link} to={getEntityLink(EntityTypes.LAW)}>
          <Trans i18nKey="header.law" />
        </NavDropdown.Item>
        <NavDropdown.Item as={Link} to={getEntityLink(EntityTypes.DECISION)}>
          <Trans i18nKey="header.decision" />
        </NavDropdown.Item>
        <NavDropdown.Item as={Link} to={getEntityLink(EntityTypes.THEORY)}>
          <Trans i18nKey="header.theory" />
        </NavDropdown.Item>
        <NavDropdown.Item as={Link} to={getEntityLink(EntityTypes.PROCESS)}>
          <Trans i18nKey="header.process" />
        </NavDropdown.Item>
        <NavDropdown.Item as={Link} to={getEntityLink(EntityTypes.TAXONOMY)}>
          <Trans i18nKey="header.taxonomy" />
        </NavDropdown.Item>
        {isLoggedIn && currentUser && this.renderSearchBurgerItem()}
        <NavDropdown.Item as={Link} to={'/contact'}>
          <Trans i18nKey="header.contact" />
        </NavDropdown.Item>
        <NavDropdown.Divider />
        {isLoggedIn && currentUser ? (
          <div className="dropdown-list">
            <NavDropdown.Item onClick={this._onProfileClick}>
              <FaUser /> <Trans i18nKey="header.profile" />
            </NavDropdown.Item>
            <ControlledByPermission
              anyPermissions={AdminHomePagePermissions.permissions}
              render={
                <NavDropdown.Item onClick={this._onAdminClick}>
                  <FaUserCog />
                  <Trans i18nKey="header.admin" />
                </NavDropdown.Item>
              }
            ></ControlledByPermission>
            <NavDropdown.Item onClick={this._onLogout}>
              <FaLock />
              <Trans i18nKey="header.logout" />
            </NavDropdown.Item>
            {togglePermission && (
              <div className="ml-4 mr-4 text-nowrap navbar-text navbar-light">
                <SliderToggle
                  value={jurisdiction.dk}
                  onChange={this.toggleDkJurisdiction}
                >
                  <Trans i18nKey="header.jurisdiction-dk" />
                </SliderToggle>
              </div>
            )}
          </div>
        ) : (
          <NavDropdown.Item as={Link} to={'/login'}>
            <Trans i18nKey="header.login" />
          </NavDropdown.Item>
        )}
      </NavDropdown>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  currentUser: appUserApiSelector.getCurrentUser,
  isLoggedIn: authSelectors.isLoggedIn,
  taxonomy: taxonomyApiSelector.getAll,
  hasLiveRequests: appSelectors.hasLiveApiRequests,
  jurisdiction: appSelectors.jurisdictionFilter,
  isKeywordsLoading: taxonomyApiSelector.getIsLoading,
  togglePermission: permissionSelectors.checkToggleJurisdictionPermission,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      logout: authActions.logout,
      clearLogin: authActions.clearLogin,
      updateJurisdictionFilter: headerActions.updateJurisdictionFilter,
      search: searchPageActions.runSearch,
      push: push,
    },
    dispatch,
  ),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withImmutablePropsToJS,
)(Header);
