import { call, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import SearchConnector from '../../../connectors/Search';
import { generateApiSaga } from '../../../utils/sagas';

const doUpdate = generateApiSaga(SearchConnector, function* (connector) {
  return yield call([connector, connector.fullSync]);
});

export default function* syncSolrData() {
  yield takeEvery(ActionTypes.API_FULL_SYNC_SOLR_DATA_INIT, doUpdate);
}
