import { call, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import { generateApiSaga } from '../../../utils/sagas';
import AppUserConnector from '../../../connectors/AppUser';

const doFetch = generateApiSaga(
  AppUserConnector,
  function* (connector, action) {
    return yield call([connector, connector.getUserById], action.payload.id);
  },
);

export default function* getUserById() {
  yield takeEvery(ActionTypes.API_USER_GET_BY_ID_INIT, doFetch);
}
