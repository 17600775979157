import BaseConnector from './Base';
import * as EntityTypes from '../constants/EntityTypes';
import { WITH_JURISDICTION } from '../constants/Projections';

export default class LawConnector extends BaseConnector {
  constructor(...args) {
    super(...args);
    this.entityType = EntityTypes.LAW;
  }

  getAll() {
    return this.authGet(
      this.query().withProjection(WITH_JURISDICTION).withSize(1000).build(),
    );
  }
}
