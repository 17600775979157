/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import PropTypes from 'prop-types';
import { uiColors } from '../styles/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

const styles = {
  backgroundColor: uiColors.WHITE,
  border: '1px solid ' + uiColors.GREY,
  borderRadius: '3px',
  boxShadow: '0 3px 5px 0 rgba(0, 0, 0, 0.05)',
  '&:hover:enabled': {
    borderColor: 'black',
  },
  '&:focus': {
    outline: 0,
  },
  '&.small-size': {
    fontSize: '10px',
    padding: '0 4px',
  },
};

class FoldButton extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    isOpen: PropTypes.bool,
  };

  static defaultProps = {
    className: '',
    isOpen: false,
  };

  constructor() {
    super();
    this._handleClick = this._handleClick.bind(this);
  }

  _handleClick(e) {
    this.props.onClick && this.props.onClick(e);
  }

  render() {
    const { className, disabled, isOpen } = this.props;
    return (
      <button
        onClick={this._handleClick}
        className={`small-size ${className}`}
        css={styles}
        disabled={disabled}
      >
        <FontAwesomeIcon icon={isOpen ? faMinus : faPlus} />
      </button>
    );
  }
}

export default FoldButton;
