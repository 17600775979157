/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Button, Col, Container, Jumbotron, Row } from 'react-bootstrap';
import ContentOuterBound from '../../components/common/atoms/ContentOuterBound';
import PageFooter from '../../components/common/atoms/PageFooter';
import wavesImagePath from '../../images/wave.svg';
import LinkList from '../../components/common/molecules/LinkList';
import MenuBackdropPageLayout from '../../components/common/atoms/MenuBackdropPageLayout';
import Text, { TextType } from '../../components/common/atoms/Text';
import {
  fontColors,
  gradientColors,
  uiColors,
} from '../../components/common/styles/styles';
import * as appUserApiSelector from '../../selectors/api/appuser';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import { Trans, withTranslation } from 'react-i18next';
import * as decisionApiSelector from '../../selectors/api/decision';
import * as lawItemsApiSelector from '../../selectors/api/lawitem';
import * as lawApiSelector from '../../selectors/api/law';
import * as theoryItemsApiSelector from '../../selectors/api/theoryitem';
import * as Sort from '../../constants/Sort';
import * as Urls from '../../constants/Urls';
import { buildPageLink } from '../../utils/link';
import ReactTooltip from 'react-tooltip';
import { getSortConfiguration } from '../../constants/HalProperties';
import LicensingOverview from '../../components/licensing/LicensingOverview';

const styles = {
  '& .header': {
    background: 'url(' + wavesImagePath + ') ' + uiColors.BLUE_MAGENTA,
    backgroundSize: 'cover',
    minHeight: '420px',
    paddingTop: '100px',
    '& .jumbotron': {
      background: 'none',
      color: fontColors.WHITE,
    },
    '& .jumbotron-promotion': {
      paddingBottom: '80px',
      '& .tag-line': {
        marginTop: '1.5em',
      },
      '& .ctaButton': {
        marginTop: '2.5em',
      },
      button: {
        background: gradientColors.ORANGE,
        borderColor: uiColors.DARK,
      },
    },
  },
  '& .product-presentation': {
    marginBottom: '100px',
    marginTop: '60px',
    paddingLeft: '2rem',
    paddingRight: '2rem',
    '@media (max-width: 576px)': {
      paddingLeft: '1rem',
      paddingRight: '1rem',
    },
  },
  '& .page-content': {
    marginTop: '40px',
  },
  '& .spacer': {
    marginTop: '3em',
    marginBottom: '2em',
  },
  '&  a': {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'block',
  },
  '.three-columns': {
    maxWidth: '33%',
  },
  '.front-page-content': {
    paddingLeft: '2rem',
    paddingRight: '2rem',
    '@media (max-width: 576px)': {
      paddingLeft: '1rem',
      paddingRight: '1rem',
    },
  },
};

class FrontPage extends React.Component {
  constructor() {
    super();
    this._onGetStartedButtonClick = this._onGetStartedButtonClick.bind(this);
  }

  renderDecisions(decisions) {
    return (
      decisions &&
      decisions.map((decision) => {
        const { id, caseName } = decision;
        return (
          <Link
            key={id}
            to={buildPageLink(Urls.DECISION_ROUTE, 'all', id)}
            data-tip={caseName}
          >
            {caseName}
          </Link>
        );
      })
    );
  }

  renderLaw(laws, lawItems) {
    return (
      lawItems &&
      lawItems.map((lawItem) => {
        const { id, title, lawId, uri } = lawItem;
        const text = `${laws[lawId] ? laws[lawId].node.title : ''} ${
          title || uri
        }`;
        return (
          <Link
            key={id}
            to={buildPageLink(Urls.LAW_ROUTE, laws[lawId]?.node.uri, uri)}
            data-tip={text}
          >
            {text}
          </Link>
        );
      })
    );
  }

  renderTheory(theoryItems) {
    return (
      theoryItems &&
      theoryItems.map((theoryItem) => {
        const text = theoryItem.title || theoryItem.uri;
        return (
          <Link
            key={theoryItem.id}
            to={buildPageLink(Urls.THEORY_ROUTE, theoryItem.uri)}
            data-tip={text}
          >
            {text}
          </Link>
        );
      })
    );
  }

  _onGetStartedButtonClick() {
    this.props.history.push(Urls.LOGIN_ROUTE);
  }

  render() {
    const { currentUser, decisions, lawItems, laws, theoryItems, t } =
      this.props;

    return (
      <MenuBackdropPageLayout>
        <ReactTooltip />
        <div css={styles}>
          <div className="header">
            <ContentOuterBound>
              {currentUser ? (
                <Jumbotron>
                  <Container>
                    <Text as="h1" type={TextType.HEADER_EXTRA_LARGE}>
                      <Trans i18nKey="front.welcome-back" />
                      <span>
                        {currentUser && `, ${currentUser.node.firstName}`}
                      </span>
                    </Text>
                  </Container>
                </Jumbotron>
              ) : (
                <Jumbotron className="jumbotron-promotion">
                  <Text as="h1" type={TextType.HEADER_EXTRA_LARGE}>
                    {t('front.promotion.headline')}
                  </Text>
                  <Text as="p" type={TextType.HEADER_3} className="tag-line">
                    {t('front.promotion.tag-line')}
                  </Text>
                  <Button
                    onClick={this._onGetStartedButtonClick}
                    className="ctaButton"
                    size="lg"
                  >
                    {t('front.promotion.cta-button-text')}
                  </Button>
                </Jumbotron>
              )}
            </ContentOuterBound>
          </div>
          {!currentUser && (
            <ContentOuterBound>
              <LicensingOverview showHeading />
            </ContentOuterBound>
          )}
          <div className="page-content">
            <div className="front-page-content">
              <ContentOuterBound>
                <Text type={TextType.PARAGRAPH_STRONG}>
                  <Trans i18nKey="front.latest-updates" />
                </Text>
                <Row>
                  <Col className="three-columns">
                    <LinkList titleKey="front.decisions">
                      {this.renderDecisions(decisions)}
                    </LinkList>
                  </Col>
                  <Col className="three-columns">
                    <LinkList titleKey="front.law">
                      {this.renderLaw(laws, lawItems)}
                    </LinkList>
                  </Col>
                  <Col className="three-columns">
                    <LinkList titleKey="front.theory">
                      {this.renderTheory(theoryItems)}
                    </LinkList>
                  </Col>
                </Row>
              </ContentOuterBound>
            </div>
            <PageFooter />
          </div>
        </div>
      </MenuBackdropPageLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const currentUser = appUserApiSelector.getCurrentUser(state);

  const decisionFilter = JSON.stringify({
    sort: getSortConfiguration(Sort.createdTimeStamp, Sort.Direction.DESC),
  });
  const decisions = decisionApiSelector.getFilterEntities(
    state,
    decisionFilter,
  );

  const updatedTimeStampFilter = JSON.stringify({
    sort: getSortConfiguration(Sort.updatedTimestamp, Sort.Direction.DESC),
  });
  const lawItems = lawItemsApiSelector.getFilterEntities(
    state,
    updatedTimeStampFilter,
  );
  const theoryItems = theoryItemsApiSelector.getFilterEntities(
    state,
    updatedTimeStampFilter,
  );
  const laws = lawApiSelector.getAll(state);

  return {
    currentUser,
    decisions,
    lawItems,
    laws,
    theoryItems,
  };
};

const mapDispatchToProps = {};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withImmutablePropsToJS,
  withRouter,
  withTranslation(),
)(FrontPage);
