import * as ActionTypes from '../../constants/ActionTypes';
import NotificationToast from '../../components/common/atoms/NotificationToast';

export default function notificationReducer(state = {}, action) {
  switch (action.type) {
    case ActionTypes.APP_NOTIFICATION_TOAST:
      // this should be js object, if this is not js object
      // then it will lose pointer of messages in withImmutablePropsToJS and render toast
      return { messages: action.payload.message, type: action.payload.type };
    case ActionTypes.API_LICENSE_CREATE_SUCCESS:
      return {
        messages: ['api-message.license-created'],
        type: NotificationToast.SUCCESS,
      };
    case ActionTypes.API_LICENSE_CREATE_ERROR:
      return {
        messages: ['api-message.licence-failed'],
        type: NotificationToast.ERROR,
      };
    case ActionTypes.API_CLAIM_LICENSE_SUCCESS:
      return {
        messages: ['api-message.license-updated'],
        type: NotificationToast.SUCCESS,
      };
    case ActionTypes.API_CLAIM_LICENSE_ERROR:
      return {
        messages: ['api-message.licence-update-failed'],
        type: NotificationToast.ERROR,
      };
    case ActionTypes.APP_DATA_PATCH_ENTITY_SUCCESS:
      return {
        messages: ['api-message.content-and-links-success'],
        type: NotificationToast.SUCCESS,
      };
    case ActionTypes.APP_DATA_PATCH_ENTITY_ERROR:
      return {
        messages: ['api-message.content-and-links-failed'],
        type: NotificationToast.ERROR,
      };
    case ActionTypes.API_UPDATE_RELATION_COUNT_SUCCESS:
      return {
        messages: ['api-message.relation-recalculation-success'],
        type: NotificationToast.SUCCESS,
      };
    case ActionTypes.API_UPDATE_RELATION_COUNT_ERROR:
      return {
        messages: ['api-message.relation-recalculation-error'],
        type: NotificationToast.ERROR,
      };
    case ActionTypes.API_FULL_SYNC_SOLR_DATA_SUCCESS:
      return {
        messages: ['api-message.solr-data-sync-success'],
        type: NotificationToast.SUCCESS,
      };
    case ActionTypes.API_FULL_SYNC_SOLR_DATA_ERROR:
      return {
        messages: ['api-message.solr-data-sync-error'],
        type: NotificationToast.ERROR,
      };
    case ActionTypes.API_UPLOAD_DECISION_FILE_ERROR:
      return {
        messages: ['api-message.file-already-exists'],
        type: NotificationToast.ERROR,
      };
    case ActionTypes.API_DECISION_FILE_GET_BY_DECISION_ID_ERROR:
      return {
        messages: ['api-message.no-file-exists'],
        type: NotificationToast.ERROR,
      };
    case ActionTypes.API_CREATE_DECISION_ERROR:
      return {
        messages: ['api-message.decision-create-error'],
        type: NotificationToast.ERROR,
      };
    case ActionTypes.API_UPDATE_DECISION_ERROR:
      return {
        messages: ['api-message.decision-updated-error'],
        type: NotificationToast.ERROR,
      };
    case ActionTypes.API_CREATE_DECISION_SUCCESS:
      return {
        messages: ['api-message.decision-created'],
        type: NotificationToast.SUCCESS,
      };
    case ActionTypes.API_UPDATE_DECISION_SUCCESS:
      return {
        messages: ['api-message.decision-updated'],
        type: NotificationToast.SUCCESS,
      };
    case ActionTypes.API_NEWSLETTER_CREATE_SUCCESS:
      return {
        messages: ['api-message.newsletter-created-success'],
        type: NotificationToast.SUCCESS,
      };
    case ActionTypes.API_NEWSLETTER_CREATE_ERROR:
      return {
        messages: ['api-message.newsletter-created-error'],
        type: NotificationToast.ERROR,
      };
    case ActionTypes.API_UNSUBSCRIBE_ALL_NOTIFICATIONS_SUCCESS:
      return {
        messages: ['api-message.unsubscribe-all-success'],
        type: NotificationToast.SUCCESS,
      };
    case ActionTypes.API_UNSUBSCRIBE_ALL_NOTIFICATIONS_ERROR:
      return {
        messages: ['api-message.unsubscribe-all-error'],
        type: NotificationToast.ERROR,
      };
    case ActionTypes.API_UNSUBSCRIBE_NOTIFICATION_ERROR:
      return {
        messages: ['api-message.unsubscribe-single-error'],
        type: NotificationToast.ERROR,
      };
    case ActionTypes.API_SEARCH_ERROR:
      return {
        messages: ['api-message.search-error'],
        type: NotificationToast.ERROR,
      };
    default:
      return state;
  }
}
