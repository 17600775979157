/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import moment from 'moment/moment';
import { refBind } from '../../../utils/ref-bind';
import { Trans, withTranslation } from 'react-i18next';
import Text, { TextType } from '../atoms/Text';
import { CSVLink } from 'react-csv';
import { getCurrentTimestamp } from '../../../utils/hocs/dateutils';
import UserOverviewTable, {
  getTableColumnHeaders,
  getTableRows,
} from '../molecules/UserOverviewTable';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';
import UserOverviewFilter from '../molecules/UserOverviewFilter';
import {
  QuickFilters,
  SearchFilters,
} from '../../../constants/UserOverviewFilterTypes';
import equal from 'fast-deep-equal';

class UserOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetails: this.props.userDetails,
      isLoading: this.props.isLoading,
      csvFileName: undefined,
    };
    refBind(
      this,
      'onUpdateRow',
      'onFilterChange',
      'applyQuickFilter',
      'applySearchFilter',
      'updateCsvFileName',
      'renderDownloadButton',
    );
  }

  static propTypes = {
    userDetails: PropTypes.arrayOf(
      PropTypes.shape({
        user: PropTypes.object,
        company: PropTypes.object,
        licenses: PropTypes.shape({
          all: PropTypes.arrayOf(PropTypes.object),
        }),
      }),
    ),
    onUserSelect: PropTypes.func,
  };

  static defaultProps = {
    userDetails: [],
    isLoading: true,
  };

  componentDidUpdate = (prevProps) => {
    if (!equal(this.props, prevProps)) {
      this.setState({
        userDetails: this.props.userDetails,
        isLoading: this.props.isLoading,
      });
    }
  };

  onUpdateRow = (row) => {
    this.props.onUserSelect({ ...row.userDetail });
  };

  onFilterChange = (filters) => {
    let filteredUserDetails = [...this.props.userDetails];

    filteredUserDetails = this.applyQuickFilter(
      filteredUserDetails,
      filters.quickFilter,
    );
    filteredUserDetails = this.applySearchFilter(
      filteredUserDetails,
      filters.searchFilters,
    );

    this.setState({ userDetails: filteredUserDetails });
  };

  applyQuickFilter = (userDetails, filterType) => {
    switch (filterType) {
      case QuickFilters.ACTIVE:
        return userDetails.filter((userDetail) => userDetail.user.active);
      case QuickFilters.EXPIRES_IN_30_DAYS:
        return userDetails.filter((userDetail) => {
          const dateDiff = moment(
            userDetail.licenses?.current?.expirationDate,
          ).diff(moment(), 'days');
          return (
            userDetail.licenses?.current && dateDiff >= 0 && dateDiff <= 30
          );
        });
      default:
        return userDetails;
    }
  };

  applySearchFilter = (userDetails, searchFilters) => {
    const regexes = Object.values(SearchFilters).reduce(
      (obj, value) => ({
        ...obj,
        [value]: new RegExp(searchFilters[value], 'i'),
      }),
      {},
    );

    return userDetails.filter(
      (userDetail) =>
        (regexes[SearchFilters.NAME].test(userDetail.user.firstName) ||
          regexes[SearchFilters.NAME].test(userDetail.user.lastName)) &&
        regexes[SearchFilters.EMAIL].test(userDetail.user.email) &&
        regexes[SearchFilters.COMPANY_NAME].test(userDetail.company?.name) &&
        regexes[SearchFilters.COMPANY_CVR].test(userDetail.company?.companyVAT),
    );
  };

  updateCsvFileName = () => {
    this.setState({
      csvFileName: this.props.t('user-overview.csv.filename', {
        timestamp: getCurrentTimestamp(),
      }),
    });
  };

  renderDownloadButton = () => {
    const csvRows = getTableRows(this.state.userDetails);
    const csvHeaders = getTableColumnHeaders().map((header) => ({
      label: header.name,
      key: header.fieldAccessor,
    }));

    return !this.state.isLoading ? (
      <CSVLink
        className="btn btn-primary"
        headers={csvHeaders}
        data={csvRows}
        filename={this.state.csvFileName}
        onClick={this.updateCsvFileName}
      >
        <Trans i18nKey={'user-overview.csv.text'} />
      </CSVLink>
    ) : null;
  };

  render() {
    const { userDetails, isLoading } = this.state;

    return (
      <>
        <div className="mb-4 text-capitalize">
          <Text type={TextType.HEADER_3}>
            <Trans i18nKey={'user-overview.header.overview'} />
          </Text>
        </div>
        <UserOverviewFilter
          disabled={isLoading}
          onChange={this.onFilterChange}
          renderDownloadButton={this.renderDownloadButton}
        />
        {this.state.isLoading ? (
          <div className="text-center mt-5">
            <Spinner animation="border" role="status" />
          </div>
        ) : (
          <UserOverviewTable
            userDetails={userDetails}
            onUpdateRow={this.onUpdateRow}
          />
        )}
      </>
    );
  }
}

export default withTranslation()(UserOverview);
