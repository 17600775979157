import { take, put, takeEvery } from 'redux-saga/effects';
import { isEqual } from 'lodash';
import * as ActionTypes from '../../constants/ActionTypes';
import * as courtActions from '../../actions/api/court';
import { getAllPages } from '../../actions/api';
import { preloadCourtsCompleted } from '../../actions/data';

function* doPreloadCourts() {
  const initAction = getAllPages(courtActions.getAll());
  yield put(initAction);
  const completedAction = yield take(
    (action) =>
      action.type === ActionTypes.TXN_ALL_PAGES_COMPLETE &&
      isEqual(action.payload.initAction, initAction),
  );
  yield put(preloadCourtsCompleted(completedAction.payload.isSuccess));
}

export default function* preloadCourts() {
  yield takeEvery(ActionTypes.DATA_PRELOAD_COURTS_INIT, doPreloadCourts);
}
