import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ProductPresentation from '../common/organisms/ProductPresentation';
import { withTranslation } from 'react-i18next';
import cardInfos from './cardInfos';
import CardInfo from '../common/organisms/CardInfo';

class LicensingOverview extends Component {
  static propTypes = {
    showHeading: PropTypes.bool,
  };

  static defaultProps = {
    showHeading: false,
  };

  render() {
    const { t, showHeading } = this.props;

    return (
      <div className="product-presentation">
        {showHeading ? (
          <ProductPresentation
            subHeadline={t('front.promotion.sub-headline')}
            bodytext={t('front.promotion.body-text', { returnObjects: true })}
          />
        ) : null}
        <CardInfo cardInfos={cardInfos()} />
      </div>
    );
  }
}

export default withTranslation()(LicensingOverview);
