import * as EntityTypes from '../../constants/EntityTypes';
import EntityFields from '../../constants/EntityFields';
import * as CommonSelectors from '../entity/common';
import { createSelector } from 'reselect';
import { FILTER_DOG_TAG } from '../../utils/filter';
import { List } from 'immutable';
import * as Searches from '../../constants/Searches';
import {
  STATE_ENTITY_PROPERTY,
  STATE_ROOT_PROPERTY,
} from '../../constants/StateProperties';

export const getAll = (state) =>
  state.getIn(['api', EntityTypes.THEORYITEM, 'data']);
export const getTheory = (state, node) =>
  CommonSelectors.getEntity(
    state,
    EntityTypes.THEORY,
    node.get(EntityFields[EntityTypes.THEORYITEM].TOP_ID),
  );
export const getParents = (state, node) =>
  CommonSelectors.getParents(getAll(state), node);
export const getById = (state, id) =>
  state.getIn(['api', EntityTypes.THEORYITEM, 'data', id]);
export const getByUri = (state, uri) =>
  getAll(state).find((item) => item.get('node').get('uri') === uri);
export const getRelatedLawItemIds = (state, node) =>
  node.get(EntityFields[EntityTypes.THEORYITEM].RELATED_LAW_ITEMS);
export const getTopLevel = createSelector([getAll], (all) =>
  all.filter((val) => val.get('parent') == null),
);
export const getByIds = (state, theoryItemIds) =>
  (theoryItemIds || [])
    .map((theoryId) => getById(state, Number(theoryId)))
    .filter((v) => !!v);
export const getTheoryItemId = (theoryItem) => theoryItem.get('node').get('id');
export const getFilterEntities = (state, filter = '{}') =>
  state.getIn([
    'api',
    EntityTypes.THEORYITEM,
    'page',
    FILTER_DOG_TAG.concat(filter),
    'data',
  ]);
export const getSearchData = (state) =>
  state.getIn(['api', EntityTypes.THEORYITEM, 'searchdata']);
export const getByIdsAsImmutable = (state, theoryItemIds) =>
  getByIds(state, List.of(...(theoryItemIds || [])));
export const isNavigationLoadedForTheory = (state) => {
  const searchTag = FILTER_DOG_TAG.concat(
    JSON.stringify(
      Searches.findByParentTheoryItemIsNullAndTheoryItemType().criteria,
    ),
  );
  return !!state.getIn(['api', EntityTypes.THEORYITEM, 'page', searchTag]);
};
export const getTheoryItemTypes = (state) =>
  state.getIn([
    STATE_ROOT_PROPERTY.api,
    EntityTypes.THEORYITEM,
    STATE_ENTITY_PROPERTY.theoryItemTypes,
  ]);
