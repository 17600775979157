import BaseConnector from './Base';
import * as EntityTypes from '../constants/EntityTypes';
import { NEWSLETTER_UNSUBSCRIBE } from '../constants/ApiLinks';

export default class NewsletterConnector extends BaseConnector {
  constructor(...args) {
    super(...args);
    this.entityType = EntityTypes.NEWSLETTER;
  }

  create(newsletter) {
    return this.authPost(this.query().build(), newsletter);
  }

  unsubscribeSingleNotification(token) {
    return this.authPost(
      this.query().withLink(NEWSLETTER_UNSUBSCRIBE.single).build(),
      { token },
    );
  }

  unsubscribeAllNotifications(token) {
    return this.authPost(
      this.query().withLink(NEWSLETTER_UNSUBSCRIBE.all).build(),
      { token },
    );
  }
}
