import { takeLatestWhenAppReady } from '../../../utils/sagas';
import * as ActionTypes from '../../../constants/ActionTypes';
import * as EntityType from '../../../constants/EntityTypes';
import * as decisionPageActions from '../../../actions/page/decision';
import * as routerActionSelector from '../../../selectors/actions/router';
import { put, select } from 'redux-saga/effects';
import * as appSelector from '../../../selectors/store/app';
import { push } from 'connected-react-router/immutable';
import { buildPageLink } from '../../../utils/link';
import { DECISION_ROUTE } from '../../../constants/Urls';

function* doLocationChange(action) {
  const path = routerActionSelector.getSplitPath(action);
  if (!path.length) return;

  const [entity, ...uri] = path;

  if (entity === EntityType.DECISION) {
    const jurisdictions = yield select(appSelector.getActiveJurisdictions);
    const allJurisdictions = Object.keys(
      (yield select(appSelector.jurisdictionFilter)).toJS(),
    ).map((v) => v.toUpperCase());
    const hasJurisdictionLinkAllLink = uri && uri.length && uri[0] === 'all';
    const hasDecisionId = uri && uri.length > 1 && uri[1];
    if (
      (!uri || (hasJurisdictionLinkAllLink && !hasDecisionId)) &&
      allJurisdictions.length > jurisdictions.length
    ) {
      yield put(push(buildPageLink(DECISION_ROUTE, jurisdictions[0])));
      return;
    }
    yield put(decisionPageActions.pageLoad(uri));
  }
}

export default function* locationChange() {
  yield takeLatestWhenAppReady(
    ActionTypes.APP_ROUTER_LOCATION_CHANGE,
    doLocationChange,
  );
}
