import { call, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import JurisdictionConnector from '../../../connectors/Jurisdiction';
import { generateApiSaga } from '../../../utils/sagas';

var doGetAllJurisdiction = generateApiSaga(
  JurisdictionConnector,
  function* (connector, action) {
    return yield call([connector, connector.getAll]);
  },
);

export default function* getAll() {
  yield takeEvery(
    ActionTypes.API_JURISDICTION_GET_ALL_INIT,
    doGetAllJurisdiction,
  );
}
