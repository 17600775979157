export const uiColors = {
  BRAND: '#FF5E38',
  BRAND_LIGHT: '#FCDDD5',
  DARK: '#383444',
  PURPLE: '#614ca7',
  PURPLE_DARK: '#1d192a',
  WHITE: '#FFFFFF',
  GREEN: '#2FE07E',
  BLUE: '#46ABFF',
  DARK_BLUE: '#0069d9',
  YELLOW: '#F6D014',
  RED: '#F6143F',
  GREY: '#eaeaea',
  GREY_DISABLED: '#cccccc',
  ICON_GRAY: '#ced4dd',
  BLACK: '#000000',
  TEXT: '#212529', // TODO - this should be imported from Bootstrap
  WHITE_BLUE: '#f7f9fc',
  BORDER: '#EBEFF5',
  DARK_GREY: '#adb5bd',
  SEARCH_ICON: '#384B64',
  HOVER_LINK: '#0077cc',
  BLUE_MAGENTA: '#26203E',
  LICENSE_BLUE_ANONYMOUS: '#00b0f0',
  LICENSE_BLUE_REGULAR: '#2e74b5',
  LICENSE_BLUE_NAVIGATOR: '#2f5496',
  LICENSE_BLUE_ANALYST: '#002060',
  TRANSPARENT_GREY: 'rgba(62, 66, 71, 0.1)',
  TRANSPARENT_BLUEISH_GREY: 'rgba(56, 52, 68, 0.2)',
  TRANSPARENT_BLACK_30: 'rgba(0, 0, 0, 0.3)',
  TRANSPARENT_BLACK_05: 'rgba(0, 0, 0, 0.05)',
  TRANSPARENT_BROWN: 'rgba(255, 94, 56, 0.2)',
};

export const fontColors = {
  MAIN: '#384444',
  BRAND: '#FF5E38',
  LIGHT: '#afaeb4',
  GREY: '#aaaaaa',
  DISABLED: '#ccd4df',
  PURPLE: '#6B618C',
  BLACK: '#000000',
  WHITE: '#FFFFFF',
  BLUE: '#46ABFF',
  RED: '#F6143F',
  DARK_BLUE: '#0069d9',
};

export const fontSize = {
  HEADER_EXTRA_LARGE: '36px',
  HEADER_1_LARGE: '30px',
  HEADER_1_SMALL: '24px',
  HEADER_2_LARGE: '24px',
  HEADER_2_SMALL: '20px',
  HEADER_3: '21px',
  HEADER_4: '18px',
  HEADER_5: '16px',
  LIST_CARD: '14px',
  CARD: '14px',
  PARAGRAPH_DEFAULT: '16px',
  PARAGRAPH_STRONG: '16px',
  PARAGRAPH_SMALL: '12px',
  PARAGRAPH_FOOTER: '11px',
  FORM_INPUT: '14px',
  MAIN_SEARCH_INPUT: '20px',
  TOP_SEARCH_INPUT: '13px',
  SIGNUP_INPUT: '18px',
  FORM_LINK: '13px',
  NAVBAR_LINK: '13px',
  FOOTER_LINK: '11px',
  LIST_LINK: '12px',
  SUBMIT_BUTTON: '13px',
  PROFILE_LIST: '13px',
  TABLE_HEADER: '10px',
  TABLE_ROW: '13px',
  READ_MORE: '12px',
  DECISION: '14px',
  FORM_ERROR: '10px',
};

export const fontFamily = {
  HEADER_EXTRA_LARGE: 'Roboto-Bold',
  HEADER_1_LARGE: 'Roboto-Bold',
  HEADER_1_SMALL: 'Roboto-Bold',
  HEADER_2_LARGE: 'Roboto-Bold',
  HEADER_2_SMALL: 'Roboto-Bold',
  HEADER_3: 'Roboto-Bold',
  HEADER_4: 'Roboto-Regular',
  HEADER_5: 'Roboto-Bold',
  LIST_CARD: 'Roboto-Regular',
  CARD: 'Roboto-Bold',
  PARAGRAPH_DEFAULT: 'Roboto-Light',
  PARAGRAPH_STRONG: 'Roboto-Medium',
  PARAGRAPH_SMALL: 'Roboto-Light',
  PARAGRAPH_FOOTER: 'Roboto-Medium',
  FORM_INPUT: 'Roboto-Regular',
  MAIN_SEARCH_INPUT: 'Roboto-Light',
  TOP_SEARCH_INPUT: 'Roboto-Regular',
  SIGNUP_INPUT: 'Roboto-Regular',
  FORM_LINK: 'Roboto-Regular',
  NAVBAR_LINK: 'Roboto-Regular',
  FOOTER_LINK: 'Roboto-Regular',
  SUBMIT_BUTTON: 'Roboto-Medium',
  LIST_LINK: 'Roboto-Regular',
  PROFILE_LIST: 'Roboto-Regular',
  PROFILE_LIST_ACTIVE: 'Roboto-Bold',
  TABLE_HEADER: 'Roboto-Bold',
  TABLE_ROW: 'Roboto-Regular',
};

export const rgbaColors = {
  BLACK_SHADOW_30: 'rgba(0, 0, 0, 0.3)',
  ORANGE_10: 'rgba(255, 94, 56, 0.1)',
  ORANGE_20: 'rgba(255, 94, 56, .2)',
};

export const gradientColors = {
  PURPLE:
    'linear-gradient(0deg, rgba(53,49,70,1) 0%, rgba(78,70,99,1) 100%, rgba(0,212,255,1) 100%)',
  ORANGE:
    'linear-gradient(0deg, rgba(247,99,8,1) 0%, rgba(250,139,71,1) 100%, rgba(0,212,255,1) 100%)',
  LIGHT_ORANGE:
    'linear-gradient(270deg, rgba(255,247,245,0) 0%, rgba(255,247,245,1) 20%)',
};

export const spacing = {
  headerHeight: '56px',
};

export const boxShadows = {
  HOVER_DEFAULT: '1px 1px 4px 0 ' + uiColors.TRANSPARENT_BLACK_30,
  DEFAULT_OR_DISABLED: '0 2px 3px 0 ' + uiColors.TRANSPARENT_BROWN,
  DEFAULT_OR_DISABLED_LIGHT: '0 3px 5px 0 ' + uiColors.TRANSPARENT_BLACK_05,
  DEFAULT_OR_DISABLED_SMALL: '0 2px 3px 0 ' + uiColors.TRANSPARENT_GREY,
};

export const oneRem = 20; // 20px
export const articleFontSize = 12; // 12px
export const articleDecreaseThreshold = 0.2;
