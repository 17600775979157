import * as ActionTypes from '../../constants/ActionTypes';

export const pageLoad = (uri) => ({
  type: ActionTypes.APP_PAGE_LAW_LOAD,
  payload: { uri },
});
export const pageLoaded = () => ({ type: ActionTypes.APP_PAGE_LAW_LOADED });
export const getChildren = (nodeId) => ({
  type: ActionTypes.API_LAWITEM_GET_ALL_CHILDREN_INIT,
  payload: { nodeId },
});
export const setLastUri = (uri) => ({
  type: ActionTypes.PAGE_LAW_LAST_URI,
  payload: { uri },
});
