/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment } from 'react';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import ContentOuterBound from '../../components/common/atoms/ContentOuterBound';
import wavesImagePath from '../../images/wave.svg';
import { FaLockOpen } from 'react-icons/fa';
import * as authSelectors from '../../selectors/store/auth';
import * as authActions from '../../actions/auth';
import { bindActionCreators } from 'redux';
import Text, { TextType } from '../../components/common/atoms/Text';
import {
  fontColors,
  gradientColors,
  uiColors,
} from '../../components/common/styles/styles';
import { Trans, withTranslation } from 'react-i18next';
import { push } from 'connected-react-router/immutable';
import CustomModal from '../../components/common/molecules/CustomModal';
import * as URLs from '../../constants/Urls';
import { getPreviousPathName } from '../../selectors/store/app';

const styles = {
  '& .page-main': {
    background: 'url(' + wavesImagePath + ') ' + uiColors.BLUE_MAGENTA,
    backgroundSize: 'cover',
    height: '100vh',
    color: fontColors.WHITE,
    paddingTop: '200px',
    '& .forgot-password-box': {
      padding: '3em',
      paddingTop: '2em',
      paddingBottom: '1em',
      backgroundColor: fontColors.WHITE,
      color: fontColors.BLACK,
      '& form': {
        '& label': {
          textTransform: 'uppercase',
          color: fontColors.GREY,
        },
        '& Button': {
          borderColor: uiColors.DARK,
          background: gradientColors.PURPLE,
          '& svg': {
            verticalAlign: 'baseline',
          },
        },
      },
      '& hr': {
        marginLeft: '-3em',
        marginRight: '-3em',
      },
      '& a': {
        color: fontColors.GREY,
      },
    },
  },
  '& .flex-row': {
    alignItems: 'center',
    justifyContent: 'center',
  },
  '& .errorText': {
    color: fontColors.RED,
  },
};

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
    };

    this._onEmailChange = this._onEmailChange.bind(this);
    this._onResetPassword = this._onResetPassword.bind(this);
    this._onResetPasswordConfirmation =
      this._onResetPasswordConfirmation.bind(this);
    this._onGotoLogin = this._onGotoLogin.bind(this);
  }

  _onGotoLogin() {
    const { actions, baseLocation } = this.props;
    actions.push(baseLocation ?? URLs.LOGIN_ROUTE);
  }

  _onEmailChange(event) {
    this.setState({ email: event.target.value });
  }

  _onResetPassword(event) {
    event.preventDefault();
    const { email } = this.state;
    const { actions } = this.props;
    actions.resetPassword(email);
  }

  _onResetPasswordConfirmation(event) {
    event.preventDefault();
    const { actions } = this.props;
    actions.push(URLs.LOGIN_ROUTE);
  }

  render() {
    const { isLoading, error, passwordReset } = this.props;
    return (
      <div css={styles}>
        <div className="page-main">
          <ContentOuterBound>
            <Container>
              <Row className="flex-row">
                <Col className="left-side" xs={5}>
                  <div className="forgot-password-box border rounded">
                    <Text as="p" type={TextType.HEADER_2_SMALL}>
                      <Trans i18nKey="forgot-password.header" />
                    </Text>
                    <Text as="p" type={TextType.HEADER_4}>
                      <Trans i18nKey="forgot-password.description" />
                    </Text>
                    <Form onSubmit={this._onResetPassword}>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>
                          <Trans i18nKey="forgot-password.email-label" />
                        </Form.Label>
                        <Form.Control
                          autoComplete="username"
                          onChange={this._onEmailChange}
                          type="email"
                          required
                          placeholder={this.props.t(
                            'forgot-password.user-name-placeholder',
                          )}
                        />
                      </Form.Group>
                      <Form.Group>
                        {error && <pre className="errorText">{error}</pre>}
                        <Button
                          disabled={isLoading}
                          size="lg"
                          variant="primary"
                          type="submit"
                          block
                        >
                          <Fragment>
                            <FaLockOpen />{' '}
                            <Trans i18nKey="forgot-password.submit" />
                          </Fragment>
                        </Button>
                      </Form.Group>
                    </Form>
                    <hr className="mt-4 mb-4" />
                    <p className="text-center">
                      <Link to="#" onClick={this._onGotoLogin}>
                        <Trans i18nKey="forgot-password.cancel" />
                      </Link>
                      <CustomModal
                        acceptButtonText={this.props.t(
                          'forgot-password.modal-button-text',
                        )}
                        isConfirmationOnly
                        acceptClickHandler={this._onResetPasswordConfirmation}
                        body={this.props.t('forgot-password.modal-body')}
                        show={passwordReset}
                        title={this.props.t('forgot-password.modal-title')}
                      />
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </ContentOuterBound>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  isLoading: authSelectors.isLoading,
  error: authSelectors.error,
  passwordReset: authSelectors.passwordReset,
  baseLocation: getPreviousPathName,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      resetPassword: authActions.resetPassword,
      push: push,
    },
    dispatch,
  ),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  withTranslation()(ForgotPassword),
);
