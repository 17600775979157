export const HOME_PAGE_SEGMENT = '';
export const SIGNUP_PAGE_SEGMENT = 'signup';
export const THEORY_PAGE_SEGMENT = 'theory';
export const LAW_PAGE_SEGMENT = 'law';
export const DECISION_PAGE_SEGMENT = 'decision';
export const PROCESS_PAGE_SEGMENT = 'process';
export const TAXONOMY_PAGE_SEGMENT = 'taxonomy';
export const SEARCH_PAGE_SEGMENT = 'search';
export const RESET_PASSWORD_PAGE_SEGMENT = 'reset-password';
export const LOGIN_PAGE_SEGMENT = 'login';
export const FORGOT_PASSWORD_PAGE_SEGMENT = 'forgot-password';
export const ADMIN_HOME_PAGE_SEGMENT = 'admin-home';
export const PROFILE_PAGE_SEGMENT = 'profile';
export const CONTACT_SEGMENT = 'contact';
export const NOTFOUND_PAGE_SEGMENT = '404';
export const UNSUBSCRIBE_PAGE_SEGMENT = 'newsletter/unsubscribe';

export const HOME_ROUTE = `/${HOME_PAGE_SEGMENT}`;
export const SIGNUP_ROUTE = `/${SIGNUP_PAGE_SEGMENT}/:token?`;
export const THEORY_ROUTE = `/${THEORY_PAGE_SEGMENT}/:uri?`;
export const LAW_ROUTE = `/${LAW_PAGE_SEGMENT}/:uri?/:id?`;
export const DECISION_ROUTE = `/${DECISION_PAGE_SEGMENT}/:uri?/:id?`;
export const PROCESS_ROUTE = `/${PROCESS_PAGE_SEGMENT}/:lawuri?/:puri?/:psuri?`;
export const TAXONOMY_ROUTE = `/${TAXONOMY_PAGE_SEGMENT}/:id?`;
export const SEARCH_ROUTE = `/${SEARCH_PAGE_SEGMENT}/:uri?`;
export const RESET_PASSWORD_ROUTE = `/${RESET_PASSWORD_PAGE_SEGMENT}`;
export const LOGIN_ROUTE = `/${LOGIN_PAGE_SEGMENT}`;
export const FORGOT_PASSWORD_ROUTE = `/${FORGOT_PASSWORD_PAGE_SEGMENT}`;
export const ADMIN_HOME_ROUTE = `/${ADMIN_HOME_PAGE_SEGMENT}/:uri?/:id?`;
export const PROFILE_ROUTE = `/${PROFILE_PAGE_SEGMENT}/:uri?`;
export const PROFILE_MEMBERSHIP = `/${PROFILE_PAGE_SEGMENT}/membership`;
export const CONTACT_ROUTE = `/${CONTACT_SEGMENT}`;
export const NOTFOUND_ROUTE = `/${NOTFOUND_PAGE_SEGMENT}`;
export const UNSUBSCRIBE_ROUTE = `/${UNSUBSCRIBE_PAGE_SEGMENT}/:token?`;
