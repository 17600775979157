import { call, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import DecisionConnector from '../../../connectors/Decision';
import { generateApiSaga } from '../../../utils/sagas';
import * as Projections from '../../../constants/Projections';

const doGet = generateApiSaga(DecisionConnector, function* (connector, action) {
  return yield call(
    [connector, connector.getById],
    action.payload.id,
    Projections.RELATED,
  );
});

export default function* getRelated() {
  yield takeEvery(ActionTypes.API_DECISION_GET_RELATED_BY_ID_INIT, doGet);
}
