import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router/immutable';
import { createBrowserHistory } from 'history';
import rootSaga from './sagas';
import configureStore from './store';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './localization/i18n';

const initialState = {};
const history = createBrowserHistory();
const store = configureStore(initialState, history);
store.runSaga(rootSaga);

const render = (Component) => {
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <React.StrictMode>
          <Component />
        </React.StrictMode>
      </ConnectedRouter>
    </Provider>,
    document.getElementById('root'),
  );
};

render(App);

if (module.hot) {
  module.hot.accept('./App', () => {
    // eslint-disable-next-line
    const App = require('./App').default;
    render(App);
  });
}

let globalId = 0;
const prefix = 'id';

export function generateUniqueId() {
  return `${prefix}${++globalId}`;
}

export function getLastUniqueId() {
  return `${prefix}${globalId}`;
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
