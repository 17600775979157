import { all, fork } from 'redux-saga/effects';
import getLawPrefaceItemByUri from './getLawPrefaceItemByUri';
import getLawPrefaceItemByLaw from './getLawPrefaceItemByLaw';
import getAllLawPrefaceItems from './getAllLawPrefaceItems';
import getRelationTree from './getRelationTree';
import getRelatedLawPrefaceById from './getRelatedLawPrefaceById';
import getLawPrefaceItemsByIds from './getLawPrefaceItemsByIds';

export default function* appRootSaga() {
  yield all([
    fork(getLawPrefaceItemByUri),
    fork(getLawPrefaceItemByLaw),
    fork(getAllLawPrefaceItems),
    fork(getRelationTree),
    fork(getRelatedLawPrefaceById),
    fork(getLawPrefaceItemsByIds),
  ]);
}
