import * as ActionTypes from '../../../constants/ActionTypes';
import { generateApiSaga } from '../../../utils/sagas';
import { call, takeEvery } from 'redux-saga/effects';
import ArticleConnector from '../../../connectors/Article';

const doGet = generateApiSaga(ArticleConnector, function* (connector) {
  return yield call([connector, connector.getAllLawItemTypes]);
});

export default function* getAllLawItemTypes() {
  yield takeEvery(ActionTypes.API_GET_ALL_LAW_ITEM_TYPES_INIT, doGet);
}
