import { fromJS } from 'immutable';
import * as ActionTypes from '../../constants/ActionTypes';

const initialState = fromJS({ selectedUser: null });

export default function profilePageReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.AUTH_LOGIN_COMPLETE:
    case ActionTypes.AUTH_LOGOUT_SUCCESS:
      return initialState;
    case ActionTypes.APP_PAGE_PROFILE_SELECT_USER:
      return state.merge({ selectedUser: action.payload.user });
    default:
      return state;
  }
}
