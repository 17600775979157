import * as EntityTypes from '../../constants/EntityTypes';
import * as lawApiSelectors from '../api/law';
import * as lawItemApiSelectors from '../api/lawitem';
import * as lawPrefaceItemApiSelectors from '../api/lawprefaceitem';
import * as treeUtils from '../store/tree';
import { updateParentIdPath } from '../store/tree';
import * as Lookup from '../entity/lookups';
import { List, Map } from 'immutable';
import * as routeSelector from '../store/router';
import { head } from 'lodash';
import { createSelector } from 'reselect';
import * as appSelector from '../store/app';
import { groupingBy } from '../../utils/functionalUtil';

const getLawId = (law) => law?.get('node')?.get('id');
const getLawByUri = (laws, uri) => laws.find(Lookup.byUri(uri));
const getLawItemByUri = (lawItems, uri) => lawItems.find(Lookup.byUri(uri));
const getLawPrefaceItemByUri = (lawPrefaceItems, uri) =>
  lawPrefaceItems.find(Lookup.byUri(uri));

export const getLastLawUri = (state) =>
  state.getIn(['page', EntityTypes.LAW, 'lasturi']);
export const getLawById = (state, id) =>
  state.getIn(['api', EntityTypes.LAW, 'data', id]); // TODO: Move to an API selector
export const getAllLawGroups = (state) =>
  state.getIn(['api', EntityTypes.LAWGROUP, 'data']); // TODO: Move to its own API selector
export const getLawGroupTopLevel = (state) =>
  getAllLawGroups(state).filter((n) => n.get('parent') === null); // TODO: Move to an API selector

export const lookupLawByUri = (state, uri) =>
  getLawByUri(lawApiSelectors.getAll(state), uri);
export const lookupLawItemByUri = (state, uri) =>
  getLawItemByUri(lawItemApiSelectors.getAll(state), uri);
export const lookupLawPrefaceItemByUri = (state, uri) =>
  getLawPrefaceItemByUri(lawPrefaceItemApiSelectors.getAll(state), uri);

export const getCurrentParent = createSelector(
  routeSelector.getCurrentUri,
  lawApiSelectors.getAll,
  (currentUri, laws) => getLawByUri(laws, currentUri),
);

export const getLastLawUriHead = createSelector(getLastLawUri, (lastUri) =>
  head(lastUri?.split('/')),
);

export const getLastLawIdByLawUri = createSelector(
  getLastLawUriHead,
  lawApiSelectors.getAll,
  (lastLawUriHead, laws) =>
    lastLawUriHead && getLawId(getLawByUri(laws, lastLawUriHead)),
);

const getLawPrefaceItemsNavigationTree = (allLawPrefaceItems, lawId) =>
  allLawPrefaceItems
    .filter((item) => item.getIn(['node', 'lawId'], -1) === lawId)
    .filter((item) => !item.get('parent'))
    .toList();

const getLawItemNavigationTreeForLawId = (lawItems, lawId) => {
  const resolveEntity = (entityType, entityId) => {
    if (entityType !== EntityTypes.LAWITEM) {
      throw `this resolveEntity function does not support entity type '${entityType}'`;
    }
    return lawItems.getIn([entityId]);
  };

  const relatedLawItems = lawItems.filter(
    (item) => item.getIn(['node', 'lawId'], -1) === lawId,
  );
  let rootItems = relatedLawItems.filter((item) => !item.get('parent'));
  return rootItems
    .map((rootItem) =>
      rootItem.set('children', treeUtils.resolveTree(rootItem, resolveEntity)),
    )
    .toList();
};

export const getLawItemNavigationTreeForLastLawUri = createSelector(
  lawItemApiSelectors.getAll,
  getLastLawIdByLawUri,
  (lawItems, idForLastLawUri) =>
    getLawItemNavigationTreeForLawId(lawItems, idForLastLawUri),
);

export const getLawPrefaceItemsNavigationTreeForLastLawUri = createSelector(
  lawPrefaceItemApiSelectors.getAll,
  getLastLawIdByLawUri,
  (lawPrefaceItems, idForLastLawUri) =>
    getLawPrefaceItemsNavigationTree(lawPrefaceItems, idForLastLawUri),
);

export const getLawItemsAndLawPrefaceItemsNavigationTreeForLastLawUri =
  createSelector(
    getLawPrefaceItemsNavigationTreeForLastLawUri,
    getLawItemNavigationTreeForLastLawUri,
    (lawPrefaceItemsNavigationTree, lawItemNavigationTree) =>
      lawItemNavigationTree.merge(lawPrefaceItemsNavigationTree),
  );

export const getLawItemNavigationTreeForCurrentParent = createSelector(
  lawItemApiSelectors.getAll,
  getCurrentParent,
  (lawItems, currentParent) =>
    getLawItemNavigationTreeForLawId(lawItems, getLawId(currentParent)),
);

export const getLawPrefaceItemsNavigationTreeForCurrentParent = createSelector(
  lawPrefaceItemApiSelectors.getAll,
  getCurrentParent,
  (lawPrefaceItems, currentParent) =>
    getLawPrefaceItemsNavigationTree(lawPrefaceItems, getLawId(currentParent)),
);

export const getLawItemsAndLawPrefaceItemsNavigationTreeForCurrentParent =
  createSelector(
    getLawPrefaceItemsNavigationTreeForCurrentParent,
    getLawItemNavigationTreeForCurrentParent,
    (lawPrefaceItemsNavigationTree, lawItemNavigationTree) =>
      lawItemNavigationTree.merge(lawPrefaceItemsNavigationTree),
  );

export const getLawItemForCurrentId = createSelector(
  routeSelector.getCurrentId,
  lawItemApiSelectors.getAll,
  (currentId, lawItems) => getLawItemByUri(lawItems, currentId),
);

export const getLawPrefaceItemForCurrentId = createSelector(
  routeSelector.getCurrentId,
  lawPrefaceItemApiSelectors.getAll,
  (currentId, lawPrefaceItems) =>
    getLawPrefaceItemByUri(lawPrefaceItems, currentId),
);

/**
 * This selector checks if a lawItem is currently selected.
 * @returns {lawItem} The lawItem if found, otherwise `null`.
 */
export const getCurrentNode = createSelector(
  getLawItemForCurrentId,
  getLawPrefaceItemForCurrentId,
  (currentIdLawItem, currentIdLawPrefaceItem) =>
    currentIdLawItem ?? currentIdLawPrefaceItem,
);

const currentNodeH5ParentLawItem = createSelector(
  getCurrentNode,
  lawItemApiSelectors.getAll,
  (currentNode, lawItems) => lawItems.get(currentNode.getIn(['node', 'h5'])),
);

export const getArticleNodeForCurrentNode = createSelector(
  getCurrentNode,
  currentNodeH5ParentLawItem,
  lawItemApiSelectors.getAll,
  (currentNode, h5Parent, lawItems) => {
    if (!h5Parent?.get('node')) {
      return currentNode;
    }
    const resolveEntity = (entityType, entityId) => {
      if (entityType !== EntityTypes.LAWITEM) {
        throw `this resolveEntity function does not support entity type '${entityType}'`;
      }
      return lawItems.getIn([entityId]);
    };

    return Map().withMutations((article) =>
      article
        .set('node', h5Parent.get('node'))
        .set('children', treeUtils.resolveTree(h5Parent, resolveEntity)),
    );
  },
);

export const buildLawGroupNavigationTree = (
  jurisdictions,
  top,
  groups,
  laws,
) => {
  return jurisdictions
    .map((jur) => {
      let filteredLaws = laws.filter(
        (o) =>
          o.getIn(['node', 'jurisdictionCode']) ===
          jur.getIn(['node', 'alpha2Code']),
      );
      let filteredGroups = groups.filter(
        (g) =>
          g.get('children').size !== 0 ||
          filteredLaws.find(
            (l) => l.get('node').get('group') === g.get('node').get('id'),
          ),
      );

      return treeUtils.createTreeNode(
        jur.get('node'),
        buildLawGroupTree(top.toList(), filteredGroups, filteredLaws),
      );
    })
    .toList()
    .filter((jur) => jur.get('children')?.size);
};

export const getLawGroupNavigationTree = createSelector(
  appSelector.getFilteredJurisdictions,
  getLawGroupTopLevel,
  getAllLawGroups,
  lawApiSelectors.getAll,
  buildLawGroupNavigationTree,
);

export const buildLawGroupTree = (children, groups, laws) => {
  return children
    .map((c) => {
      const subChildren =
        c.get('children').size !== 0
          ? buildLawGroupTree(
              groups.filter(
                (g) => g.get('node').get('parent') === c.get('node').get('id'),
              ),
              groups,
              laws,
            )
          : laws
              .filter(
                (l) => l.get('node').get('group') === c.get('node').get('id'),
              )
              .toList();

      return treeUtils.createTreeNode(c.get('node'), subChildren, groups, laws);
    })
    .toList()
    .filter((lawGroup) => lawGroup.get('children')?.size);
};

export const getLawFromDateByLaw = (law) =>
  law.getIn(['node', 'fromDate'], null);

export const getLawNameByLaw = (law) => law.getIn(['node', 'name'], null);

export const getUriByLaw = (law) => law.getIn(['node', 'uri'], null);

export const buildLawTree = (laws, lawItemList) => {
  if (!laws) {
    return;
  }
  // remove duplicates
  const cleanList = Map().asMutable();
  lawItemList
    .map((node) => getParentPathForNode(lawItemList, node))
    .forEach((innerList) =>
      innerList.forEach((node) =>
        cleanList.set(node.getIn(['node', 'id']), node),
      ),
    );

  // map to laws
  const lawListMap = cleanList
    .asImmutable()
    .toList()
    .filter((lawItem) => !lawItem.get('parent'))
    .map((lawItem) => getTreeChildren(lawItem, cleanList))
    .reduce(...groupingBy((lawItem) => lawItem.getIn(['node', 'lawId'])));

  return laws
    .toList()
    .map((law) =>
      law.set('children', List(lawListMap[law.getIn(['node', 'id'])])),
    )
    .filter((v) => v.get('children')?.size);
};

const getTreeChildren = (node, cleanList) => {
  if (node && node.get('children')) {
    node.set(
      'children',
      node
        .get('children')
        .map((childId) => (isNaN(childId) ? childId : cleanList.get(childId)))
        .filter((v) => !!v)
        .map((child) => getTreeChildren(child, cleanList)),
    );
  }
  return node;
};

const getParentPathForNode = (lawItemList, lawItem) => {
  if (!lawItem) {
    return List();
  }
  const list = List().push(lawItem);
  return updateParentIdPath(lawItemList, lawItem.get('parent'), list).reverse();
};
