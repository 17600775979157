import { createSelector } from 'reselect';
import { fromJS, List, Map, Set } from 'immutable';
import {
  STATE_ENTITY,
  STATE_ENTITY_PROPERTY,
  STATE_ROOT_PROPERTY,
} from '../../constants/StateProperties';
import * as EntityTypes from '../../constants/EntityTypes';
import EntityFields from '../../constants/EntityFields';
import I18n from '../../localization/i18n';
import * as routerSelectors from '../store/router';
import * as theorySelector from './theory';
import * as lawSelector from './law';
import * as decisionSelector from './decision';
import * as processSelector from './process';
import * as taxonomySelector from './taxonomy';
import * as lawApiSelector from '../api/law';
import * as lawItemApiSelector from '../api/lawitem';
import * as lawPrefaceItemApiSelector from '../api/lawprefaceitem';
import * as decisionApiSelector from '../api/decision';
import * as processApiSelector from '../api/process';
import * as processStepApiSelector from '../api/processstep';
import * as theoryItemApiSelector from '../api/theoryitem';
import * as taxonomyApiSelector from '../api/taxonomy';
import * as treeUtils from '../store/tree';
import * as appSelector from '../store/app';
import * as courtApiSelectors from '../api/court';
import { RED_THREAD } from '../../utils/relationhelper';
import { groupingByImmutable, identity } from '../../utils/functionalUtil';
import * as jurisdictionApiSelector from '../api/jurisdiction';
import { checkRedThreadPermission } from '../store/permissions';

export const getAllFooters = (state) =>
  state.getIn([STATE_ROOT_PROPERTY.page, STATE_ENTITY.articleFooter]);
export const getLoading = (state) =>
  state.getIn([
    STATE_ROOT_PROPERTY.page,
    STATE_ENTITY.articleFooter,
    STATE_ENTITY_PROPERTY.isLoading,
  ]) > 0;
const getShowRedThread = (state) =>
  state.getIn([
    STATE_ROOT_PROPERTY.page,
    STATE_ENTITY.articleFooter,
    'showRedThread',
  ]);

export const getSelectedNode = createSelector(
  routerSelectors.getCurrentPageSegment,
  theorySelector.getSelectedNode,
  lawSelector.getCurrentNode,
  decisionSelector.getSelectedDecision,
  processSelector.getCurrentNode,
  taxonomySelector.getCurrentNode,
  (
    pageSegment,
    selectedTheory,
    selectedLaw,
    selectedDecision,
    selectedProcess,
    selectedTaxonomy,
  ) => {
    switch (pageSegment) {
      case EntityTypes.THEORY:
        return selectedTheory;
      case EntityTypes.LAW:
        return selectedLaw;
      case EntityTypes.DECISION:
        return selectedDecision;
      case EntityTypes.PROCESS:
        return selectedProcess;
      case EntityTypes.TAXONOMY:
        return selectedTaxonomy;
      default:
        return null;
    }
  },
);

export const showRedThread = createSelector(
  routerSelectors.getCurrentPageSegment,
  getShowRedThread,
  (pageSegment, show) => pageSegment === EntityTypes.LAW && show,
);

const getSelectedArticle = createSelector(
  getAllFooters,
  getSelectedNode,
  (allFooters, selectedNode) => {
    if (!selectedNode) {
      return null;
    }
    const type = selectedNode.getIn(['node', 'entityType']);
    const id = selectedNode.getIn(['node', 'id']);
    return allFooters.getIn([type, id]);
  },
);

export const getRelatedLawItemCount = createSelector(
  getSelectedArticle,
  getSelectedNode,
  (selectedArticle, selectedNode) => {
    if (selectedArticle) {
      return (
        selectedArticle.get(EntityTypes.LAWITEM).size +
        selectedArticle.get(EntityFields[EntityTypes.LAWITEM].CHANGING).size +
        selectedArticle.get(EntityFields[EntityTypes.LAWITEM].CHANGED_BY).size +
        selectedArticle.get(EntityFields[EntityTypes.LAWITEM].REPLACING).size +
        selectedArticle.get(EntityFields[EntityTypes.LAWITEM].REPLACED_BY)
          .size +
        selectedArticle.get(EntityFields[EntityTypes.LAWITEM].JURISDICTION)
          .size +
        selectedArticle.get(EntityFields[EntityTypes.LAWITEM].JURISDICTION_BY)
          .size +
        selectedArticle.get(EntityTypes.LAWPREFACEITEM).size
      );
    } else {
      return selectedNode?.getIn(['node', 'relatedLawCount']) || 0;
    }
  },
);

const getRelatedLawItemTree = createSelector(
  getSelectedArticle,
  appSelector.getFilteredJurisdictions,
  lawSelector.getLawGroupTopLevel,
  lawSelector.getAllLawGroups,
  lawApiSelector.getAll,
  lawItemApiSelector.getAll,
  (
    selectedArticle,
    jurisdictions,
    topLawGroups,
    allLawGroups,
    allLaws,
    allLawItems,
  ) => {
    const relatedLawItemList =
      selectedArticle
        ?.get(EntityTypes.LAWITEM)
        .map((id) => allLawItems.get(id))
        .map((lawItem) =>
          selectedArticle.get('type') === EntityTypes.LAWPREFACEITEM
            ? lawItem.setIn(
                ['node', 'title'],
                lawItem.getIn(['node', 'title']) + ' ▼',
              )
            : lawItem,
        ) || List();
    const lawItemTree = treeUtils.buildTreeExcerpt(
      allLawItems,
      relatedLawItemList,
    );
    const lawTree = lawSelector.buildLawTree(allLaws, lawItemTree);
    return lawSelector.buildLawGroupNavigationTree(
      jurisdictions,
      topLawGroups,
      allLawGroups,
      lawTree,
    );
  },
);

const getChangingLawItemTree = createSelector(
  getSelectedArticle,
  appSelector.getFilteredJurisdictions,
  lawSelector.getLawGroupTopLevel,
  lawSelector.getAllLawGroups,
  lawApiSelector.getAll,
  lawItemApiSelector.getAll,
  (
    selectedArticle,
    jurisdictions,
    topLawGroups,
    allLawGroups,
    allLaws,
    allLawItems,
  ) => {
    const oldRelatedLawItemList =
      selectedArticle
        ?.get(EntityFields[EntityTypes.LAWITEM].CHANGING)
        .map((id) => allLawItems.get(id))
        .map((lawitem) =>
          lawitem.setIn(
            ['node', 'title'],
            lawitem.getIn(['node', 'title']) + ' ▲',
          ),
        ) || List();
    const newRelatedLawItemList =
      selectedArticle
        ?.get(EntityFields[EntityTypes.LAWITEM].CHANGED_BY)
        .map((id) => allLawItems.get(id))
        .map((lawitem) =>
          lawitem.setIn(
            ['node', 'title'],
            lawitem.getIn(['node', 'title']) + ' ▼',
          ),
        ) || List();
    const lawItemTree = treeUtils.buildTreeExcerpt(
      allLawItems,
      List.of(...oldRelatedLawItemList, ...newRelatedLawItemList),
    );
    const lawTree = lawSelector.buildLawTree(allLaws, lawItemTree);
    return lawSelector.buildLawGroupNavigationTree(
      jurisdictions,
      topLawGroups,
      allLawGroups,
      lawTree,
    );
  },
);

const getReplacingLawItemTree = createSelector(
  getSelectedArticle,
  appSelector.getFilteredJurisdictions,
  lawSelector.getLawGroupTopLevel,
  lawSelector.getAllLawGroups,
  lawApiSelector.getAll,
  lawItemApiSelector.getAll,
  (
    selectedArticle,
    jurisdictions,
    topLawGroups,
    allLawGroups,
    allLaws,
    allLawItems,
  ) => {
    const oldRelatedLawItemList =
      selectedArticle
        ?.get(EntityFields[EntityTypes.LAWITEM].REPLACING)
        .map((id) => allLawItems.get(id))
        .map((lawitem) =>
          lawitem.setIn(
            ['node', 'title'],
            lawitem.getIn(['node', 'title']) + ' ▲',
          ),
        ) || List();
    const newRelatedLawItemList =
      selectedArticle
        ?.get(EntityFields[EntityTypes.LAWITEM].REPLACED_BY)
        .map((id) => allLawItems.get(id))
        .map((lawitem) =>
          lawitem.setIn(
            ['node', 'title'],
            lawitem.getIn(['node', 'title']) + ' ▼',
          ),
        ) || List();
    const lawItemTree = treeUtils.buildTreeExcerpt(
      allLawItems,
      List.of(...oldRelatedLawItemList, ...newRelatedLawItemList),
    );
    const lawTree = lawSelector.buildLawTree(allLaws, lawItemTree);
    return lawSelector.buildLawGroupNavigationTree(
      jurisdictions,
      topLawGroups,
      allLawGroups,
      lawTree,
    );
  },
);

const getJurisdictionLawItemTree = createSelector(
  getSelectedArticle,
  appSelector.getFilteredJurisdictions,
  lawSelector.getLawGroupTopLevel,
  lawSelector.getAllLawGroups,
  lawApiSelector.getAll,
  lawItemApiSelector.getAll,
  (
    selectedArticle,
    jurisdictions,
    topLawGroups,
    allLawGroups,
    allLaws,
    allLawItems,
  ) => {
    const oldRelatedLawItemList =
      selectedArticle
        ?.get(EntityFields[EntityTypes.LAWITEM].JURISDICTION)
        .map((id) => allLawItems.get(id))
        .map((lawitem) =>
          lawitem.setIn(
            ['node', 'title'],
            lawitem.getIn(['node', 'title']) + ' ▲',
          ),
        ) || List();
    const newRelatedLawItemList =
      selectedArticle
        ?.get(EntityFields[EntityTypes.LAWITEM].JURISDICTION_BY)
        .map((id) => allLawItems.get(id))
        .map((lawitem) =>
          lawitem.setIn(
            ['node', 'title'],
            lawitem.getIn(['node', 'title']) + ' ▼',
          ),
        ) || List();
    const lawItemTree = treeUtils.buildTreeExcerpt(
      allLawItems,
      List.of(...oldRelatedLawItemList, ...newRelatedLawItemList),
    );
    const lawTree = lawSelector.buildLawTree(allLaws, lawItemTree);
    return lawSelector.buildLawGroupNavigationTree(
      jurisdictions,
      topLawGroups,
      allLawGroups,
      lawTree,
    );
  },
);

const getRelatedLawPrefaceItemTree = createSelector(
  getSelectedArticle,
  appSelector.getFilteredJurisdictions,
  lawSelector.getLawGroupTopLevel,
  lawSelector.getAllLawGroups,
  lawApiSelector.getAll,
  lawPrefaceItemApiSelector.getAll,
  (
    selectedArticle,
    jurisdictions,
    topLawGroups,
    allLawGroups,
    allLaws,
    allLawPrefaceItems,
  ) => {
    const relatedLawPrefaceItemList =
      selectedArticle
        ?.get(EntityTypes.LAWPREFACEITEM)
        .map((id) => allLawPrefaceItems.get(id))
        .map((lawPrefaceItem) =>
          lawPrefaceItem.setIn(
            ['node', 'lffpkt'],
            lawPrefaceItem.getIn(['node', 'lffpkt'])
              ? lawPrefaceItem.getIn(['node', 'lffpkt']) + ' ▲'
              : '▲',
          ),
        ) || List();
    const lawPrefaceItemTree = treeUtils.buildTreeExcerpt(
      allLawPrefaceItems,
      relatedLawPrefaceItemList,
    );
    const lawTree = lawSelector.buildLawTree(allLaws, lawPrefaceItemTree);
    return lawSelector.buildLawGroupNavigationTree(
      jurisdictions,
      topLawGroups,
      allLawGroups,
      lawTree,
    );
  },
);

export const getLawItemTree = createSelector(
  getRelatedLawItemTree,
  getChangingLawItemTree,
  getReplacingLawItemTree,
  getJurisdictionLawItemTree,
  getRelatedLawPrefaceItemTree,
  (
    relatedLawTree,
    changingLawItemTree,
    replacingLawItemTree,
    jurisdictionLawItemTree,
    relatedLawPrefaceTree,
  ) => {
    let lawTree = relatedLawTree;
    if (replacingLawItemTree.size) {
      // Relation type 1
      const replacingNode = {
        title: I18n.t(
          `law-item-relationship.${
            EntityFields[EntityTypes.LAWITEM].REPLACING
          }`,
        ),
        entityType: EntityTypes.LAW_ITEM_RELATIONSHIP,
        id: EntityFields[EntityTypes.LAWITEM].REPLACING,
      };
      const replacingTreeNode = treeUtils.createTreeNode(
        replacingNode,
        replacingLawItemTree,
      );
      lawTree = lawTree.push(replacingTreeNode);
    }
    if (changingLawItemTree.size) {
      // Relation type 2
      const changingNode = {
        title: I18n.t(
          `law-item-relationship.${EntityFields[EntityTypes.LAWITEM].CHANGING}`,
        ),
        entityType: EntityTypes.LAW_ITEM_RELATIONSHIP,
        id: EntityFields[EntityTypes.LAWITEM].CHANGING,
      };
      const changingTreeNode = treeUtils.createTreeNode(
        changingNode,
        changingLawItemTree,
      );
      lawTree = lawTree.push(changingTreeNode);
    }
    if (relatedLawPrefaceTree.size) {
      // Relation type 3
      const prefaceNode = {
        title: I18n.t(`law-item-relationship.${EntityTypes.LAWPREFACEITEM}`),
        entityType: EntityTypes.LAW_ITEM_RELATIONSHIP,
        id: EntityTypes.LAWPREFACEITEM,
      };
      const prefaceTreeNode = treeUtils.createTreeNode(
        prefaceNode,
        relatedLawPrefaceTree,
      );
      lawTree = lawTree.push(prefaceTreeNode);
    }
    if (jurisdictionLawItemTree.size) {
      // Relation type 4
      const jurisdictionNode = {
        title: I18n.t(
          `law-item-relationship.${
            EntityFields[EntityTypes.LAWITEM].JURISDICTION
          }`,
        ),
        entityType: EntityTypes.LAW_ITEM_RELATIONSHIP,
        id: EntityFields[EntityTypes.LAWITEM].JURISDICTION,
      };
      const jurisdictionTreeNode = treeUtils.createTreeNode(
        jurisdictionNode,
        jurisdictionLawItemTree,
      );
      lawTree = lawTree.push(jurisdictionTreeNode);
    }
    return lawTree;
  },
);

/**
 * Creates the very top part of the red thread tree.
 * This depend on very few parts and is somewhat cacheable (except for the fact that it depends on the selected article
 * - rereselect might be a good option here.
 */
const getRedThreadH5LawItems = createSelector(
  getSelectedArticle,
  lawItemApiSelector.getAll,
  (selectedArticle, allLawItems) => {
    return (selectedArticle || Map())
      .get(RED_THREAD, List())
      .map((id) => allLawItems.getIn([id, 'node', 'h5'])) // Get H5 id of each item
      .toSet() // Remove duplicates
      .map((id) => allLawItems.get(id)); // get the h5 law items
  },
);

/**
 * Creates most of the red thread tree if the user has permission to see the red thread, otherwise returns an empty list.
 * Decisions are not included in the tree at this stage.
 */
const getTopRedThread = createSelector(
  getRedThreadH5LawItems,
  lawApiSelector.getAll,
  lawItemApiSelector.getAll,
  checkRedThreadPermission,
  (redThreadH5LawItems, allLaws, allLawItems, hasPermission) => {
    if (!hasPermission) {
      return List();
    }
    const flattenAndResolveChildren = (lawItemIds) => {
      return lawItemIds
        .reduce((accumulator, lawItemId) => {
          const lawItem = allLawItems.get(lawItemId);
          accumulator.push(lawItem);
          return accumulator.merge(
            flattenAndResolveChildren(lawItem.get('children')),
          ); // Recursive to get all levels of children
        }, List().asMutable())
        .asImmutable();
    };
    return (
      redThreadH5LawItems
        .map((h5Item) => {
          if (!h5Item.get('node')) {
            return null;
          }
          const lawId = h5Item.getIn(['node', 'lawId']);
          const law = allLaws.get(lawId);
          if (!law) {
            return h5Item
              .setIn(['node', 'entityType'], EntityTypes.LAW)
              .set(
                'children',
                flattenAndResolveChildren(h5Item.get('children')),
              );
          }
          const fromDate = law.getIn(['node', 'fromDate']);
          const uri = `${law.getIn(['node', 'uri'])}/${h5Item.getIn([
            'node',
            'uri',
          ])}`;
          // Get the year from the law - we will group by this
          return (
            h5Item
              .set('year', new Date(fromDate).getFullYear())
              // Get the title from the law now that we have it
              .setIn(
                ['node', 'name'],
                `${h5Item.getIn(['node', 'title'])} ${I18n.t(
                  'article-footer.of',
                )} ${law.getIn(['node', 'name'])}`,
              )
              // Fix the URI so that we can add a link
              .setIn(['node', 'entityType'], EntityTypes.LAW)
              .setIn(['node', 'uri'], uri)
              .set('highlight', true)
              // Retrieve the entire child hierarchy and store them as full object - we are going to need them.
              .set(
                'children',
                flattenAndResolveChildren(h5Item.get('children')),
              )
          );
        })
        // group the items by year of their law
        .reduce(...groupingByImmutable((h5Item) => h5Item.get('year')))
        // use the year to create a new node and flip back to a list
        .map((lawList, year) => {
          const yearNode = {
            title: year,
            id: year,
            entityType: RED_THREAD,
          };
          return treeUtils.createTreeNode(yearNode, lawList);
        })
        .toList()
        .filter(Boolean)
        .sort((a, b) => a.getIn(['node', 'title']) - b.getIn(['node', 'title']))
    ); // Sort the list by year (stored in title)
  },
);

/**
 * Finishes the read thread by adding in the missing decisions. Too much of the tree is returned, as the jurisdiction
 * filter is not applied.
 */
const getTotalRedThread = createSelector(
  getTopRedThread,
  jurisdictionApiSelector.getAll,
  courtApiSelectors.getAll,
  decisionApiSelector.getAll,
  (topRedThread, allJurisdictions, allCourts, allDecisions) => {
    const getDecisions = (lawItem) => {
      if (!lawItem?.get('node')) {
        return List();
      }
      const decisions = Map().asMutable();
      const addDecisionToMap = (decisionId, lawItem) => {
        const decision = decisions.get(
          decisionId,
          allDecisions
            .get(decisionId, fromJS({ id: decisionId }))
            .set('highlight', true)
            .set('lawItem', Set()),
        );
        decisions.set(
          decisionId,
          decision.set('lawItem', decision.get('lawItem').add(lawItem)),
        );
      };
      (
        lawItem
          .getIn(['node', 'relatedDecisions'])
          ?.map((link) => link.get('id')) || List()
      ).forEach((decisionId) => addDecisionToMap(decisionId, lawItem));
      // We have already flattened the list of children, so there is only one level.
      lawItem.get('children').forEach((child) => {
        (
          child
            .getIn(['node', 'relatedDecisions'])
            ?.map((link) => link.get('id')) || List()
        ).forEach((decisionId) => addDecisionToMap(decisionId, child));
      });
      return decisions
        .asImmutable()
        .toList()
        .map((decision) => {
          const suffix = decision
            .get('lawItem')
            .map((lawItem) => lawItem.getIn(['node', 'title']))
            .sort()
            .join(' ― ');
          const caseName = decision.getIn(['node', 'caseName']);
          return decision
            .setIn(['node', 'caseName'], `${caseName} (${suffix})`)
            .delete('lawItem');
        });
    };
    return topRedThread.map((year) => {
      return year.set(
        'children',
        year.get('children').map((h5Item) => {
          // Find the decisions and group them by jurisdiction so we can set them as children
          const jurisdictions = getDecisions(h5Item)
            .sort((a, b) =>
              a
                .getIn(['node', 'rulingDate'])
                ?.localeCompare(b.getIn(['node', 'rulingDate'])),
            )
            // group the decisions by jurisdiction
            .reduce(
              ...groupingByImmutable((decision) => {
                const courtId = decision.getIn(['node', 'court']);
                const court = allCourts.get(courtId);
                return court?.getIn(['node', 'jurisdiction']);
              }),
            )
            .map((decisionList, jurisdictionId) =>
              allJurisdictions
                .get(jurisdictionId)
                ?.set('children', decisionList)
                //set the uri for the jurisdictions same as the h5 item - this will ensure that they open in the nav tree
                .setIn(['node', 'uri'], h5Item.getIn(['node', 'uri'])),
            )
            .toList()
            .filter(Boolean)
            .sort((a, b) => !!a.get('parent') - !!b.get('parent')); // We may want a fallback for when multiple jurisdictions exists under EU.
          return h5Item.set('children', jurisdictions);
        }),
      );
    });
  },
);

export const getRedThread = createSelector(
  getTotalRedThread,
  appSelector.jurisdictionFilter,
  (redThread, jurisdictionFilter) => {
    if (jurisdictionFilter.every(identity)) {
      return redThread;
    }
    return redThread.map((year) => {
      return year.set(
        'children',
        year.get('children').map((h5Item) => {
          return h5Item.set(
            'children',
            h5Item.get('children').filter((jurisdiction) => {
              return jurisdictionFilter.get(
                jurisdiction.getIn(['node', 'alpha2Code'], '').toLowerCase(),
              );
            }),
          );
        }),
      );
    });
  },
);

/**
 * Creates a node equivalent to the selected node, but modified in the same way as entried in the red thread.
 * This is useful for indicing which node is selected in a navigation tree based on the red thread.
 */
export const getRedThreadNode = createSelector(
  getSelectedNode,
  lawApiSelector.getAll,
  lawItemApiSelector.getAll,
  (selectedNode, allLaws, allLawItems) => {
    if (selectedNode) {
      const lawId = selectedNode.getIn(['node', 'lawId']);
      const law = allLaws.get(lawId);
      const h5Id = selectedNode.getIn(['node', 'h5']);
      const h5 = allLawItems.get(h5Id);
      return law && h5
        ? selectedNode
            .setIn(['node', 'entityType'], EntityTypes.LAW)
            .setIn(
              ['node', 'uri'],
              `${law.getIn(['node', 'uri'])}/${h5.getIn(['node', 'uri'])}`,
            )
        : selectedNode;
    }
  },
);

export const getLawNameRelatedToLawItem = createSelector(
  lawApiSelector.getAll,
  getSelectedNode,
  (allLaws, selectedNode) => {
    if (!selectedNode) {
      return null;
    }
    const lawId = selectedNode.getIn(['node', 'lawId']);
    return allLaws.getIn([lawId, 'node', 'name']);
  },
);

export const getRelatedTheoryItemCount = createSelector(
  getSelectedArticle,
  getSelectedNode,
  (selectedArticle, selectedNode) =>
    selectedArticle?.get(EntityTypes.THEORYITEM).size ||
    selectedNode?.getIn(['node', 'relatedTheoryCount']) ||
    0,
);

export const getRelatedTheoryItemTree = createSelector(
  getSelectedArticle,
  theoryItemApiSelector.getAll,
  (selectedArticle, allTheoryItems) => {
    const relatedTheoryItemList =
      selectedArticle
        ?.get(EntityTypes.THEORYITEM)
        .map((id) => allTheoryItems.get(id)) || List();
    return treeUtils.buildTreeExcerpt(allTheoryItems, relatedTheoryItemList);
  },
);

export const getRelatedTaxonomyCount = createSelector(
  getSelectedArticle,
  getSelectedNode,
  (selectedArticle, selectedNode) =>
    selectedArticle?.get(EntityTypes.TAXONOMY).size ||
    selectedNode?.getIn(['node', 'relatedKeywordCount']) ||
    0,
);

export const getRelatedTaxonomyTree = createSelector(
  getSelectedArticle,
  taxonomyApiSelector.getAll,
  (selectedArticle, allTaxonomy) => {
    const relatedTaxonomy =
      selectedArticle
        ?.get(EntityTypes.TAXONOMY)
        .map((id) => allTaxonomy.get(id))
        .map((taxonomy) => taxonomy.set('highlight', true)) || List();
    return taxonomySelector.buildTaxonomyNavigationTree(relatedTaxonomy);
  },
);

export const getRelatedDecisionCount = createSelector(
  getSelectedArticle,
  getSelectedNode,
  (selectedArticle, selectedNode) =>
    selectedArticle?.get(EntityTypes.DECISION).size ||
    selectedNode?.getIn(['node', 'relatedDecisionCount']) ||
    0,
);

export const getRelatedDecisionTree = createSelector(
  getSelectedArticle,
  appSelector.getFilteredJurisdictions,
  courtApiSelectors.getAll,
  decisionApiSelector.getAll,
  (selectedArticle, filteredJurisdictions, allCourts, allDecisions) => {
    const relatedDecisionList =
      selectedArticle
        ?.get(EntityTypes.DECISION)
        .map((id) => allDecisions.get(id))
        .map((decision) => decision.set('highlight', true)) || List();
    return decisionSelector.buildNavigationTree(
      filteredJurisdictions,
      allCourts,
      relatedDecisionList,
    );
  },
);

export const getRelatedProcessStepCount = createSelector(
  getSelectedArticle,
  getSelectedNode,
  (selectedArticle, selectedNode) =>
    selectedArticle?.get(EntityTypes.PROCESSSTEP).size ||
    selectedNode?.getIn(['node', 'relatedProcessCount']) ||
    0,
);

export const getRelatedProcessStepTree = createSelector(
  getSelectedArticle,
  lawApiSelector.getAll,
  processApiSelector.getAll,
  processStepApiSelector.getAll,
  (selectedArticle, allLaws, allProcesses, allProcessSteps) => {
    const relatedProcessStepList =
      selectedArticle
        ?.get(EntityTypes.PROCESSSTEP)
        .map((id) => allProcessSteps.get(id)) || List();
    const processStepTree = treeUtils.buildTreeExcerpt(
      allProcessSteps,
      relatedProcessStepList,
    );
    return processSelector.buildProcessTree(
      allLaws,
      allProcesses,
      processStepTree,
    );
  },
);
