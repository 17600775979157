import { push } from 'connected-react-router/immutable';
import { put, select } from 'redux-saga/effects';
import { takeLatestWhenAppReady } from '../../../utils/sagas';
import * as processPageSelectors from '../../../selectors/page/process';
import * as ActionTypes from '../../../constants/ActionTypes';

function* doSelectProcessStep(action) {
  const node = action.payload.node;

  if (!node) {
    return;
  }
  const link = yield select(processPageSelectors.getProcessStepUri, node);

  return yield put(push(link));
}

export default function* SelectProcessStep() {
  yield takeLatestWhenAppReady(
    ActionTypes.APP_PAGE_PROCESS_SELECT_NODE,
    doSelectProcessStep,
  );
}
