/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component } from 'react';
import ContentOuterBound from '../../components/common/atoms/ContentOuterBound';
import DefaultPageLayout from '../../components/common/atoms/DefaultPageLayout';
import { Col, Nav, Row, Tab } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router/immutable';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import { Trans } from 'react-i18next';
import * as profilePageSelector from '../../selectors/page/profile';
import * as profilePageActions from '../../actions/page/profile';
import * as userApiActions from '../../actions/api/appuser';
import * as userConsentApiActions from '../../actions/api/appuserconsent';
import * as favoriteApiSelector from '../../selectors/api/favorite';
import { fromJS } from 'immutable';
import UserGeneralInformation from '../../components/common/organisms/UserGeneralInformation';
import * as ConsentType from '../../constants/ConsentTypes';
import FavoriteList from '../../components/common/molecules/FavoriteList';
import { getPageLink } from '../../utils/link';
import { PROFILE_ROUTE } from '../../constants/Urls';
import * as routerSelectors from '../../selectors/store/router';
import { PROFILE_TABS } from '../../constants/ProfileTabs';
import * as favoriteApiActions from '../../actions/api/favorite';
import * as TabsStyles from '../../components/common/styles/TabsStyles';
import LicenseView from '../../components/common/molecules/LicenseView';
import * as licenseApiActions from '../../actions/api/license';
import ChangePassword from '../../components/common/organisms/ChangePassword';
import Subscriptions from '../../components/common/molecules/Subscriptions';
import { refBind } from '../../utils/ref-bind';
import { getAppUserConsents } from '../../selectors/api/appuserconsent';
import { MOBILE_SIZE } from '../../constants/ScreenSize';
import NoMobileSupportPage from './NoMobileSupportPage';
import ControlledByPermission from '../shared/ControlledByPermission';
import { PERMISSIONS } from '../../constants/Permissions';
import LicensingOverview from '../../components/licensing/LicensingOverview';

class ProfilePage extends Component {
  constructor(props) {
    super(props);
    refBind(
      this,
      'handleUserUpdate',
      'handleTabSelect',
      'handleFavoriteClick',
      'handleLicenseClick',
      'handleConsentCheckbox',
      'handleSaveChanges',
    );
  }

  handleUserUpdate(user) {
    const { actions } = this.props;
    actions.updateUser(fromJS(user));
  }

  handleConsentCheckbox(consent) {
    const { actions, user } = this.props;
    actions.updateConsentApi(consent, user.id);
  }

  handleSaveChanges() {
    const { user, actions } = this.props;
    actions.updateAppUserApi(user);
  }

  handleTabSelect(eventKey) {
    const { actions } = this.props;
    actions.push(`${getPageLink(PROFILE_ROUTE)}/${eventKey}`);
  }

  handleFavoriteClick(favorite) {
    const { actions } = this.props;
    actions.toggleFavorite(favorite);
  }

  handleLicenseClick(license) {
    const { actions } = this.props;
    actions.claimLicense(license);
  }

  parseConsentObject(consents) {
    return {
      newsConsent: this.filterConsents(consents, ConsentType.NEWS_CONSENT),
      instantConsent: this.filterConsents(
        consents,
        ConsentType.INSTANT_CONSENT,
      ),
      dailyConsent: this.filterConsents(
        consents,
        ConsentType.DAILY_ALL_CONSENT,
      ),
      dailyFavoritesConsent: this.filterConsents(
        consents,
        ConsentType.DAILY_FAVORITE_CONSENT,
      ),
    };
  }

  filterConsents(consents, type) {
    const consent =
      (consents &&
        Object.entries(consents).find((c) => c[1].node.consentType === type)) ||
      null;
    return (consent && consent[1].node) || null;
  }

  render() {
    const {
      user,
      consents,
      uri,
      favorites,
      currentLicense,
      emptyLicense,
      actions,
    } = this.props;
    const allConsents = this.parseConsentObject(consents);
    const isMobile = window.innerWidth < MOBILE_SIZE;

    return (
      <DefaultPageLayout>
        {isMobile ? (
          <NoMobileSupportPage />
        ) : (
          <ContentOuterBound>
            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey={uri}
              onSelect={this.handleTabSelect}
            >
              <Row>
                <Col sm={3} className="mt-5">
                  <Nav
                    className="flex-column text-capitalize mt-3"
                    css={TabsStyles.styles}
                    variant="fill"
                  >
                    <Nav.Item>
                      <Nav.Link eventKey={PROFILE_TABS.GENERAL}>
                        <Trans i18nKey="profile.general" />
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey={PROFILE_TABS.SUBSCRIPTION}>
                        <Trans i18nKey="profile.notifications" />
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey={PROFILE_TABS.SECURITY}>
                        <Trans i18nKey="profile.password" />
                      </Nav.Link>
                    </Nav.Item>
                    <ControlledByPermission
                      permission={PERMISSIONS.FAVORITE}
                      render={
                        <Nav.Item>
                          <Nav.Link eventKey={PROFILE_TABS.FAVORITES}>
                            <Trans i18nKey="profile.favorites" />
                          </Nav.Link>
                        </Nav.Item>
                      }
                    />
                    <Nav.Item>
                      <Nav.Link eventKey={PROFILE_TABS.MEMBERSHIP}>
                        <Trans i18nKey="profile.membership" />
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col sm={9}>
                  <Tab.Content className="mt-4">
                    <Tab.Pane eventKey={PROFILE_TABS.GENERAL}>
                      <UserGeneralInformation
                        user={user}
                        headerText={'user-overview.header.personal'}
                        onUserChanged={this.handleUserUpdate}
                        onSave={this.handleSaveChanges}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey={PROFILE_TABS.SUBSCRIPTION}>
                      <Subscriptions
                        onConsentCheckbox={this.handleConsentCheckbox}
                        allConsents={allConsents}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey={PROFILE_TABS.SECURITY}>
                      <ChangePassword push={actions.push} />
                    </Tab.Pane>
                    <ControlledByPermission
                      permission={PERMISSIONS.FAVORITE}
                      render={
                        <Tab.Pane eventKey={PROFILE_TABS.FAVORITES}>
                          <FavoriteList
                            data={favorites}
                            onFavoriteClick={this.handleFavoriteClick}
                          />
                        </Tab.Pane>
                      }
                    />
                    <Tab.Pane eventKey={PROFILE_TABS.MEMBERSHIP}>
                      <LicenseView
                        current={currentLicense}
                        empty={emptyLicense}
                        onLicenseClick={this.handleLicenseClick}
                      />
                      <LicensingOverview />
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </ContentOuterBound>
        )}
      </DefaultPageLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const uri = routerSelectors.getCurrentUri(state);
  const user = profilePageSelector.getSelectedUser(state);
  switch (uri) {
    case PROFILE_TABS.GENERAL:
      return { uri, user };
    case PROFILE_TABS.SUBSCRIPTION:
      const consents = getAppUserConsents(state);
      return { consents, user };
    case PROFILE_TABS.FAVORITES:
      const favorites = favoriteApiSelector.getAllGroupedByPage(state);
      return { favorites, uri };
    case PROFILE_TABS.MEMBERSHIP:
      const currentLicense = profilePageSelector.getUserLicense(state);
      const emptyLicense = profilePageSelector.getNotClaimedAllLicense(state);
      return { uri, currentLicense, emptyLicense };
    default:
      return { uri };
  }
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      push: push,
      updateUser: profilePageActions.selectUser,
      updateAppUserApi: userApiActions.update,
      updateConsentApi: userConsentApiActions.updateConsent,
      toggleFavorite: favoriteApiActions.toggleFavorite,
      claimLicense: licenseApiActions.claimLicense,
    },
    dispatch,
  ),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withImmutablePropsToJS,
)(ProfilePage);
