import * as ActionTypes from '../../constants/ActionTypes';

export const submit = (forms) => ({
  type: ActionTypes.PAGE_SIGNUP_SUBMIT,
  payload: forms,
});
export const pageLoad = (uri) => ({
  type: ActionTypes.APP_PAGE_SIGNUP_LOAD,
  payload: { uri },
});
