import { isImmutable } from 'immutable';
import moment from 'moment';
import * as entityTypes from '../constants/EntityTypes.js';
import { DANISH_LOCALE } from '../constants/locale';
import { startEUDate } from '../constants/DateFormat';
import EntityFields, { NODE } from '../constants/EntityFields';
import * as Sort from '../constants/Sort';
import * as TheoryItemType from '../constants/TheoryItemType';
import * as EntityTypes from '../constants/EntityTypes';

const getAttr = (item, path) =>
  isImmutable(item)
    ? item.getIn(path)
    : path.reduce(
        (acc, cur) =>
          (acc !== undefined && acc !== null && cur in acc && acc[cur]) ||
          undefined,
        item,
      );

/**
 * Compares two strings or numbers with each other, if one object is falsy, it will consider that object as the smaller one.
 * @param a {string | number} string, number, undefined or null.
 * @param b {string | number} string, number, undefined or null.
 * @returns {number} +1 if a is greater, 0 if a and b are equal -1 if a is smaller than b.
 */
const compare = (a, b) => {
  if ((!a && b) || a < b) {
    return -1;
  }
  if ((a && !b) || a > b) {
    return 1;
  }
  return 0;
};

/**
 * Extracts the numbers from the strings based on the regex pattern with a capture group for a number and compares them, if it can't extract numbers by the regex pattern from the strings or numbers are equal, it will compare the strings.
 * @param a {string}
 * @param b {string}
 * @param pattern {RegExp}
 * @returns {number} +1 if a is greater, 0 if a and b are equal -1 if a is smaller than b.
 */
const compareByNumberAndText = (a, b, pattern) => {
  const numberA = Number(a.match(pattern)?.[1]);
  const numberB = Number(b.match(pattern)?.[1]);
  return compare(numberA, numberB) || compare(a, b);
};

/**
 * Compares two law preface item by multiple fields with this order: LFFKAT, LFFO1, LFF02, LFF03, LFF04, LFFPAR, LFFNR, LFFPKT.
 * @param a {Object} law preface item object
 * @param b {Object} law preface item object
 * @return {number} +1 if a is greater, 0 if a and b are equal -1 if a is smaller than b.
 */
const byMultipleFieldsOfLawPrefaceItem = (a, b) => {
  let result = 0;
  let fieldIndex = 0;
  while (result === 0 && fieldIndex < Sort.LAW_PREFACE_ITEM_SORT_ORDER.length) {
    const fieldName = Sort.LAW_PREFACE_ITEM_SORT_ORDER[fieldIndex];
    fieldIndex++;
    const aField = a.node[fieldName];
    const bField = b.node[fieldName];
    switch (fieldName) {
      case EntityFields[EntityTypes.LAWPREFACEITEM].LFFO1:
        result = compareByNumberAndText(aField, bField, /^(\d+)\./);
        break;
      case EntityFields[EntityTypes.LAWPREFACEITEM].LFF02:
        result = compareByNumberAndText(aField, bField, /\.(\d+)/);
        break;
      case EntityFields[EntityTypes.LAWPREFACEITEM].LFF03:
        result = compareByNumberAndText(aField, bField, /\.\d+\.(\d+)/);
        break;
      case EntityFields[EntityTypes.LAWPREFACEITEM].LFFPAR:
      case EntityFields[EntityTypes.LAWPREFACEITEM].LFFNR:
        result = compareByNumberAndText(aField, bField, /(\d+)/);
        break;
      case EntityFields[EntityTypes.LAWPREFACEITEM].LFFPKT:
        result = compare(Number(aField), Number(bField));
        break;
      default:
        result = compare(aField, bField);
    }
  }
  return result;
};

export const sort = (array, sortType, direction = Sort.Direction.ASC) => {
  let sorted = array.slice();
  let valuePath = [NODE, 'uri'];
  switch (sortType) {
    case entityTypes.LAWGROUP:
      valuePath = [NODE, EntityFields[entityTypes.LAWGROUP].LABEL];
      sorted = sorted.sort((c1, c2) =>
        (getAttr(c1, valuePath) || '').localeCompare(
          getAttr(c2, valuePath),
          DANISH_LOCALE,
        ),
      );
      break;
    case entityTypes.LAW:
      const fromDatePath = [NODE, EntityFields[entityTypes.LAW].FROM_DATE];
      sorted = sorted.sort(
        (c1, c2) =>
          getAttr(c1, fromDatePath) &&
          new moment(getAttr(c1, fromDatePath) || startEUDate) -
            new moment(getAttr(c2, fromDatePath) || startEUDate),
      );
      break;
    case entityTypes.PROCESSSTEP:
      sorted = sorted.sort(
        (i1, i2) =>
          parseInt(getAttr(i1, valuePath).replace(/\D/g, '')) -
          parseInt(getAttr(i2, valuePath).replace(/\D/g, '')),
      );
      break;
    case entityTypes.THEORYITEM:
      sorted = sorted
        .sort((c1, c2) => {
          const uri1 = getAttr(c1, valuePath);
          const uri2 = getAttr(c2, valuePath);
          return uri1?.replace(/\D/g, '') - uri2?.replace(/\D/g, '');
        })
        .slice()
        .sort((c1, c2) => {
          valuePath = [NODE, 'theoryItemType'];
          const theoryItemType1 = getAttr(c1, valuePath);
          const theoryItemType2 = getAttr(c2, valuePath);
          return (
            (theoryItemType1 === TheoryItemType.SECTION) -
            (theoryItemType2 === TheoryItemType.SECTION)
          );
        });
      break;
    case entityTypes.LAWPREFACEITEM:
      sorted = sorted.sort(byMultipleFieldsOfLawPrefaceItem);
      break;
    case entityTypes.LAWITEM:
      sorted = sorted.sort((c1, c2) => {
        const c1IsBetragtning = getAttr(c1, [NODE, 'title']) === 'Betragninger';
        const c2IsBetragtning = getAttr(c2, [NODE, 'title']) === 'Betragninger';
        return c1IsBetragtning || c2IsBetragtning
          ? c2IsBetragtning - c1IsBetragtning
          : (getAttr(c1, valuePath) || '').localeCompare(
              getAttr(c2, valuePath),
            );
      });
      break;
    case entityTypes.DECISION:
      valuePath = [NODE, EntityFields[entityTypes.DECISION].RULING_DATE];
      sorted = sorted.sort(
        (a, b) =>
          new moment(getAttr(a, valuePath) || startEUDate) -
          new moment(getAttr(b, valuePath) || startEUDate),
      );
      break;
    case entityTypes.TAXONOMY:
      valuePath = [NODE, 'description'];
      sorted = sorted.sort((c1, c2) => {
        const desc1 = getAttr(c1, valuePath);
        const desc2 = getAttr(c2, valuePath);
        return (desc1 || '').localeCompare(desc2, DANISH_LOCALE);
      });
      break;
    case Sort.DECISION_LIST:
      const indexOfVar = (variable) =>
        Sort.DECISION_SORT_ORDER.indexOf(variable) !== -1
          ? Sort.DECISION_SORT_ORDER.indexOf(variable)
          : Sort.DECISION_SORT_ORDER.length;
      sorted = sorted.sort((a, b) => indexOfVar(a) - indexOfVar(b));
      break;
    case Sort.NAMES_ALPHABETICALLY_BASED:
      sorted = sorted.sort((a, b) =>
        a.name?.localeCompare(b.name, DANISH_LOCALE),
      );
      break;
    case Sort.COURT_OPTION_SORT_INDEX_BASED:
      valuePath = [NODE, 'sortIndex'];
      sorted = sorted.sort(
        (c1, c2) => getAttr(c1, valuePath) - getAttr(c2, valuePath),
      );
      break;
    default:
      break;
  }
  return direction === Sort.Direction.DESC ? sorted.reverse() : sorted;
};
