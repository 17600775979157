import { call, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import processStepConnector from '../../../connectors/ProcessStep';
import { generateApiSaga } from '../../../utils/sagas';

const doGetByIds = generateApiSaga(
  processStepConnector,
  function* (connector, action) {
    return yield call([connector, connector.searchByIds], action.payload.ids);
  },
);

export default function* getByProcessStepsByIds() {
  yield takeEvery(ActionTypes.API_GET_PROCESS_STEP_BY_IDS_INIT, doGetByIds);
}
