import * as ActionTypes from '../../constants/ActionTypes';

export const getAll = () => ({
  type: ActionTypes.API_LAW_PREFACE_ITEM_GET_ALL_INIT,
});
export const getAllTxn = () => ({
  type: ActionTypes.API_LAW_PREFACE_ITEM_GET_ALL_TXN,
});
export const getAllByLaw = (node) => ({
  type: ActionTypes.API_LAW_PREFACE_ITEM_GET_ALL_BY_LAW_INIT,
  payload: { node },
});
export const getAllByLawTxn = (node) => ({
  type: ActionTypes.API_LAW_PREFACE_ITEM_GET_ALL_BY_LAW_TXN,
  payload: { node },
});
export const getByUri = (uri) => ({
  type: ActionTypes.API_LAW_PREFACE_ITEM_GET_BY_URI_INIT,
  payload: { uri },
});
export const getRelationTreeById = (id) => ({
  type: ActionTypes.API_LAW_PREFACE_ITEM_GET_RELATION_TREE_BY_ID_INIT,
  payload: { id },
});
export const getByIds = (ids) => ({
  type: ActionTypes.API_LAW_PREFACE_ITEM_GET_BY_IDS_INIT,
  payload: { ids },
});
export const getRelationById = (id) => ({
  type: ActionTypes.API_LAW_PREFACE_ITEM_GET_RELATION_BY_ID_INIT,
  payload: { id },
});
