/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'recompose';
import { Spinner, Tab, Tabs } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import ContentOuterBound from '../../components/common/atoms/ContentOuterBound';
import DefaultPageLayout from '../../components/common/atoms/DefaultPageLayout';
import * as searchPageSelectors from '../../selectors/page/search';
import * as permissionSelectors from '../../selectors/store/permissions';
import GeneralEntityFilter from '../search/GeneralEntityFilter';
import * as SearchTabs from '../../constants/SearchTabs';
import {
  fontColors,
  spacing,
  uiColors,
} from '../../components/common/styles/styles';
import DecisionSearchView from '../search/DecisionSearchView';
import TheorySearchView from '../search/TheorySearchView';
import LawSearchView from '../search/LawSearchView';
import { refBind } from '../../utils/ref-bind';
import * as searchPageActions from '../../actions/page/search';
import ProcessSearchView from '../search/ProcessSearchView';
import SearchOverlay from '../../components/common/molecules/SearchOverlay';
import DecisionFilter from '../search/DecisionFilter';
import NoData from '../../components/common/atoms/NoData';
import NoSearch from '../../components/common/atoms/NoSearch';
import { MOBILE_SIZE } from '../../constants/ScreenSize';
import NoMobileSupportPage from './NoMobileSupportPage';

const headerHeight = 56;
const styles = {
  minHeight: `calc(100vh - ${spacing.headerHeight})`,
  display: 'flex',
  flexDirection: 'column',
  '.search-wrapper': {
    paddingBottom: '20px',
    paddingTop: '20px',
  },
  '.tab-body': {
    flex: '1 1 auto',
    backgroundColor: uiColors.WHITE_BLUE,
    backgroundImage:
      "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAMSURBVBhXY/j//z8ABf4C/qc1gYQAAAAASUVORK5CYII=')",
    backgroundSize: '1px ' + headerHeight + 'px',
    backgroundRepeat: 'repeat-x',
  },
  '.search-header': {
    flex: '0 1 auto',
  },
  '.nav-link': {
    padding: '15px 30px',
    fontFamily: 'Roboto',
    fontSize: '14px',
    lineHeight: '22px',
    textAlign: 'left',
    color: fontColors.LIGHT,
    border: 0,
  },
  '.nav-tabs': {
    border: 0,
  },
  '.nav-link.active': {
    fontWeight: 'bold',
    color: fontColors.BLACK,
    border: 0,
    borderBottom: `2px solid ${uiColors.BLACK}`,
    borderColor: uiColors.BLACK,
  },
  '.headline': {
    zIndex: 'unset',
  },
  '.spinner': {
    color: `${fontColors.BLUE}`,
    height: '15rem',
    width: '15rem',
    fontSize: '2rem',
    marginTop: '1rem',
  },
  '.progress-bar': {
    opacity: '100%',
    transition: 'opacity 3s ease-in 4s',
  },
  '.loading-done': {
    opacity: 0,
    animation: 'fadeOut ease 8s',
    '-webkit-animation': 'fadeOut ease 8s',
    '-moz-animation': 'fadeOut ease 8s',
    '-o-animation': 'fadeOut ease 8s',
    '-ms-animation': 'fadeOut ease 8s',
  },
  '@keyframes fadeOut': {
    '0%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0,
    },
  },
  '@-moz-keyframes fadeOut': {
    '0%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0,
    },
  },
  '@-webkit-keyframes fadeOut': {
    '0%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0,
    },
  },
  '@-o-keyframes fadeOut': {
    '0%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0,
    },
  },
  '@-ms-keyframes fadeOut': {
    '0%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0,
    },
  },
};

class SearchPage extends React.Component {
  constructor(props) {
    super(props);
    refBind(this, 'onSelectTab', 'getTabTitle', 'renderSearchView');
    this.decisionOverlay = React.createRef();
    this.tabBody = React.createRef();
  }

  onSelectTab(tab) {
    this.props.actions.changeTab(tab);
  }

  getTabTitle(title, count = 0) {
    return `${this.props.t('search-page.' + title)} (${count})`;
  }

  renderSearchView(searchView, areResultsFound) {
    const { activeTab, isSearchRunningForCurrentFilter, latestSearchResult } =
      this.props;
    if (isSearchRunningForCurrentFilter) {
      return (
        <div className={'d-flex justify-content-center align-items-center'}>
          <Spinner animation="border" className="spinner" />
        </div>
      );
    } else if (!latestSearchResult) {
      return <NoSearch />;
    } else if (!areResultsFound) {
      return <NoData activeTab={activeTab} />;
    } else {
      return searchView;
    }
  }

  render() {
    const {
      activeTab,
      theoryItemIds,
      lawItemIds,
      decisionIds,
      processStepsId,
      hasAdvancedSearchPermission,
    } = this.props;
    const isMobile = window.innerWidth < MOBILE_SIZE;

    return (
      <DefaultPageLayout>
        {isMobile ? (
          <NoMobileSupportPage />
        ) : (
          <div css={styles}>
            <div className="search-header">
              <ContentOuterBound>
                <div className="search-wrapper">
                  <GeneralEntityFilter />
                </div>
              </ContentOuterBound>
            </div>
            <div className="tab-body" ref={this.tabBody}>
              <ContentOuterBound>
                <Tabs activeKey={activeTab} onSelect={this.onSelectTab}>
                  <Tab
                    eventKey={SearchTabs.THEORY}
                    title={
                      <React.Fragment>
                        {this.getTabTitle('theory', theoryItemIds?.size)}
                      </React.Fragment>
                    }
                  >
                    {this.renderSearchView(
                      <TheorySearchView />,
                      theoryItemIds?.size,
                    )}
                  </Tab>
                  <Tab
                    eventKey={SearchTabs.LAW}
                    title={
                      <React.Fragment>
                        {this.getTabTitle('law', lawItemIds?.size)}
                      </React.Fragment>
                    }
                  >
                    {this.renderSearchView(<LawSearchView />, lawItemIds?.size)}
                  </Tab>
                  <Tab
                    eventKey={SearchTabs.DECISION}
                    title={
                      <React.Fragment>
                        {this.getTabTitle('decision', decisionIds?.size)}
                        {activeTab === SearchTabs.DECISION && (
                          <SearchOverlay
                            ref={this.decisionOverlay}
                            target={this.tabBody}
                            offset={headerHeight}
                            style={{ display: 'inline' }}
                            disabled={!hasAdvancedSearchPermission}
                          >
                            {hasAdvancedSearchPermission && (
                              <DecisionFilter
                                close={() =>
                                  this.decisionOverlay.current.setShow()
                                }
                              />
                            )}
                          </SearchOverlay>
                        )}
                      </React.Fragment>
                    }
                  >
                    {this.renderSearchView(
                      <DecisionSearchView />,
                      decisionIds?.size,
                    )}
                  </Tab>
                  <Tab
                    eventKey={SearchTabs.PROCESS}
                    title={
                      <React.Fragment>
                        {this.getTabTitle('process', processStepsId?.size)}
                      </React.Fragment>
                    }
                  >
                    {this.renderSearchView(
                      <ProcessSearchView />,
                      processStepsId?.size,
                    )}
                  </Tab>
                </Tabs>
              </ContentOuterBound>
            </div>
          </div>
        )}
      </DefaultPageLayout>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  activeTab: searchPageSelectors.getCurrentTab,
  decisionIds: searchPageSelectors.getLatestSearchResultDecisionIds,
  theoryItemIds: searchPageSelectors.getLatestSearchResultTheoryItemIds,
  lawItemIds: searchPageSelectors.getLatestSearchResultLawItemIds,
  processStepsId: searchPageSelectors.getLatestSearchResultProcessStepIds,
  isSearchRunningForCurrentFilter:
    searchPageSelectors.isSearchRunningForCurrentFilter,
  latestSearchResult: searchPageSelectors.getLatestSearchResult,
  hasAdvancedSearchPermission:
    permissionSelectors.checkAdvancedSearchPermission,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      changeTab: searchPageActions.changeTab,
    },
    dispatch,
  ),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
)(SearchPage);
