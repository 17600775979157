import { getEntityId } from '../../utils/entities';
import * as theoryApiSelector from '../api/theory';
import * as routerSelector from '../store/router';
import * as EntityTypes from '../../constants/EntityTypes';
import * as appSelector from '../store/app';
import { createSelector } from 'reselect';

export const getNodeId = (node) => node.getIn(['node', 'id'], -1);

export const getPreviousNode = (state) =>
  state.getIn(['page', EntityTypes.THEORY, 'previousNode']);
export const getNextNode = (state) =>
  state.getIn(['page', EntityTypes.THEORY, 'nextNode']);
export const getIsLoading = (state) =>
  state.getIn(['page', EntityTypes.THEORY, 'isLoading']);
export const getLastUri = (state) =>
  state.getIn(['page', EntityTypes.THEORY, 'lasturi']);

const filterTheoryItems = (filter, items) => {
  const path = ['node', 'jurisdiction', 'alpha2Code'];
  const preFiltered = (items || []).filter((item) => item.get('node'));
  return filter
    ? preFiltered.filter(
        (item) =>
          filter.get(item.getIn(path, '').toLowerCase()) ||
          item.get('children')?.size,
      )
    : preFiltered;
};

const findNodeByUri = (allTheoryItems, uri) =>
  allTheoryItems.find((n) => n.getIn(['node', 'uri']) === uri);
const findNodeById = (allTheoryItems, id) => allTheoryItems.get(id);
const resolveTreeFromNode = (allTheoryItems, jurisdictionFilter, node) => {
  let result = null;
  if (node) {
    const children = node
      .get('children')
      .map((id) =>
        resolveTreeFromNode(
          allTheoryItems,
          jurisdictionFilter,
          findNodeById(allTheoryItems, id),
        ),
      );
    return node.set(
      'children',
      filterTheoryItems(jurisdictionFilter, children),
    );
  }
  return result;
};
const getAllNodeParents = (allTheoryItems, childNode) => {
  let result = [];
  let curNode = childNode;
  while (curNode) {
    result.push(curNode);
    curNode = findNodeById(allTheoryItems, curNode.get('parent'));
  }
  return result.reverse();
};

export const getSelectedNode = createSelector(
  routerSelector.getCurrentUri,
  theoryApiSelector.getAllTheoryItems,
  (uri, allTheoryItems) =>
    uri ? findNodeByUri(allTheoryItems, uri) : undefined,
);

export const getSelectedNodesParent = createSelector(
  getSelectedNode,
  theoryApiSelector.getAllTheoryItems,
  (selectedNode, allTheoryItems) =>
    selectedNode && allTheoryItems.get(getParentItemId(selectedNode)),
);

export const getArticle = createSelector(
  getSelectedNode,
  theoryApiSelector.getAllTheoryItems,
  appSelector.jurisdictionFilter,
  (selectedNode, allTheoryItems, currentJurisdictionFilter) => {
    let parentpath = getAllNodeParents(allTheoryItems, selectedNode);
    return parentpath.length > 1
      ? resolveTreeFromNode(
          allTheoryItems,
          currentJurisdictionFilter,
          parentpath[1],
        )
      : selectedNode && selectedNode.set('children', []);
  },
);

export const getCurrentTheory = createSelector(
  theoryApiSelector.getAll,
  getSelectedNode,
  (theories, currentNode) =>
    theories.get(currentNode?.get('node').get('theoryId')),
);

export const getTopLevel = createSelector(
  theoryApiSelector.getAllTheoryItems,
  (allTheoryItems) =>
    allTheoryItems.filter((theory) => theory.get('parent') === null),
);

const idPath = ['node', 'id'];
export const getNavigationTree = createSelector(
  appSelector.jurisdictionFilter,
  getTopLevel,
  theoryApiSelector.getAllTheoryItems,
  (currentJurisdictionFilter, topLevelTheoryItems, allTheoryItems) =>
    filterTheoryItems(currentJurisdictionFilter, topLevelTheoryItems)
      .toList()
      .map((node) =>
        resolveTreeFromNode(allTheoryItems, currentJurisdictionFilter, node),
      )
      .sort((b1, b2) => b1.getIn(idPath) - b2.getIn(idPath)),
);

export const getByUri = (state, uri) =>
  findNodeByUri(theoryApiSelector.getAllTheoryItems(state), uri);

export const getParents = (state, entry) => {
  if (!entry) return [];
  let parent = theoryApiSelector.getTheoryItemById(state, entry.get('parent'));
  return getParents(state, parent).concat(entry);
};

export const getParent = (state, node) => {
  let parentId = node ? getParentItemId(node) : -1;
  return theoryApiSelector.getTheoryItemById(state, parentId);
};

export const getAllChildren = (state, node) => {
  let theoryItems = theoryApiSelector.getAllTheoryItems(state);
  let childTheoryItems = theoryItems.filter(
    (item) => getParentItemId(item) === getEntityId(node),
  );
  let output = { node: node.toJS(), children: [] };
  childTheoryItems.forEach((item) =>
    output.children.push(getAllChildren(state, item)),
  );
  return output;
};

export const getAllParents = (state, node) => {
  let parentId = getParentItemId(node);
  let parentItem = theoryApiSelector.getTheoryItemById(state, parentId);
  if (!parentItem) {
    return {
      node: theoryApiSelector.getTheoryById(state, getTheoryId(node)).toJS(),
      parent: undefined,
    };
  } else {
    return {
      node: parentItem.toJS(),
      parent: getAllParents(state, parentItem),
    };
  }
};

// Entity field selectors:
export const getParentItemId = (node) => node.get('parent');
export const getTheoryId = (node) => node.get('theoryId');
