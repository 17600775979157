export const getSplitPath = (action) =>
  splitRoute(action.payload.location.pathname);
export const splitRoute = (route) => (route || '').split('/').slice(1);
export const getPageSegment = (action) => getSplitPath(action)[0];
export const getQueryParams = (action) =>
  action.payload.location.search
    .replace(/^\?/, '')
    .split('&')
    .filter((s) => !!s)
    .reduce((acc, cur) => {
      let m = cur.match(/([^=]+)=([^=]+)/);
      acc[m[1]] = m[2];
      return acc;
    }, {});
export const getPreviousPageSegment = (action) =>
  (splitRoute(action.payload.previousLocation?.pathname) || [])[0];

export const getChangeType = (currentLocation, previousLocation) => {
  if (previousLocation) {
    const [previousEntity, previousUrl, previousId] = splitRoute(
      previousLocation.pathname || '/',
    );
    const [entity, url, id] = splitRoute(currentLocation.pathname || '/');

    if (previousEntity !== entity) {
      return LOCATION_CHANGE_TYPE.ENTITY_CHANGE;
    }
    if (previousUrl !== url) {
      return LOCATION_CHANGE_TYPE.URI_CHANGE;
    }
    if (previousId !== id) {
      return LOCATION_CHANGE_TYPE.ID_CHANGE;
    }
    if (previousLocation.hash !== currentLocation.hash) {
      return LOCATION_CHANGE_TYPE.HASH_CHANGE;
    }
    if (previousLocation.state !== currentLocation.state) {
      return LOCATION_CHANGE_TYPE.STATE_CHANGE;
    }
    return LOCATION_CHANGE_TYPE.NO_CHANGE;
  }

  return LOCATION_CHANGE_TYPE.ENTITY_CHANGE;
};
export const LOCATION_CHANGE_TYPE = {
  ENTITY_CHANGE: 'ENTITY_CHANGE',
  URI_CHANGE: 'URI_CHANGE',
  ID_CHANGE: 'ID_CHANGE',
  HASH_CHANGE: 'HASH_CHANGE',
  STATE_CHANGE: 'STATE_CHANGE',
  NO_CHANGE: 'NO_CHANGE',
};
