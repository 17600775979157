import { takeEvery, call } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import TheoryConnector from '../../../connectors/Theory';
import { generateApiSaga } from '../../../utils/sagas';

var doGetAllTheory = generateApiSaga(
  TheoryConnector,
  function* (connector, action) {
    return yield call([connector, connector.getAll]);
  },
);

export default function* getAll() {
  yield takeEvery(ActionTypes.API_THEORY_GET_ALL_INIT, doGetAllTheory);
}
