import BaseConnector from './Base';
import { SEARCH } from '../constants/Resources';

export default class SearchConnector extends BaseConnector {
  constructor(...args) {
    super(...args);
    this.entityType = SEARCH;
  }

  // getAllByTheory(action, page) {
  searchQuery(filter) {
    let url = this.query().withLink('query').build();

    return this.authPost(url, filter);
  }

  fullSync() {
    return this.authPost(this.query().withLink('fullsync').build(), {});
  }

  updateSearchFilter(filter) {
    return this.authPatch(this.query().withId(filter.id).build(), filter);
  }

  createFilter(filter) {
    return this.authPost(this.query().build(), filter);
  }

  deleteFilter(id) {
    return this.authDelete(this.query().withId(id).build());
  }

  getAllFilters() {
    return this.authGet(this.query().build());
  }
}
