import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import * as permissionSelector from '../../selectors/store/permissions';
import PropTypes from 'prop-types';

class ControlledByPermission extends React.Component {
  static propTypes = {
    anyPermissions: PropTypes.array,
    everyPermissions: PropTypes.array,
    noPermissions: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    permission: PropTypes.string,
    noPermissionsSecond: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.string,
    ]),
    permissionSecond: PropTypes.string,
    fallback: PropTypes.object,
    fallbackFn: PropTypes.func,
    fallbackArgs: PropTypes.object,
    key: PropTypes.string,
    doubleCheck: PropTypes.bool,
  };

  static defaultProps = {
    doubleCheck: false,
  };

  getComponentIfHasPermission(hasPermission, isDefaultRender = true) {
    const { render, renderSecond } = this.props;
    const renderSection = isDefaultRender ? render : renderSecond;
    return hasPermission ? (
      this.props.key ? (
        <div key={this.props.key}>{renderSection}</div>
      ) : (
        renderSection
      )
    ) : undefined;
  }

  getFallBack() {
    const { fallbackFn, fallbackArgs, fallback } = this.props;
    return fallbackFn && fallbackArgs
      ? fallbackFn(fallbackArgs)
      : fallback || null;
  }

  render() {
    const {
      hasAny,
      hasEvery,
      hasSingle,
      hasNot,
      hasSingleSecond,
      hasNotSecond,
      doubleCheck,
    } = this.props;
    const hasPermission = hasAny && hasEvery && hasSingle && hasNot;
    const hasPermissionSecond =
      hasAny && hasEvery && hasSingleSecond && hasNotSecond && doubleCheck;
    return (
      this.getComponentIfHasPermission(hasPermission) ??
      this.getComponentIfHasPermission(hasPermissionSecond, false) ??
      this.getFallBack()
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const hasAny = ownProps.anyPermissions
    ? permissionSelector.hasAnyPermissions(state, ownProps.anyPermissions)
    : true;
  const hasEvery = ownProps.everyPermissions
    ? permissionSelector.hasEveryPermissions(state, ownProps.everyPermissions)
    : true;
  const hasSingle = ownProps.permission
    ? permissionSelector.hasAnyPermissions(state, ownProps.permission)
    : true;
  const hasNot = ownProps.noPermissions
    ? permissionSelector.hasAnyPermissions(state, ownProps.noPermissions, true)
    : true;
  const hasSingleSecond = permissionSelector.hasAnyPermissions(
    state,
    ownProps.permissionSecond,
  );
  const hasNotSecond = ownProps.noPermissionsSecond
    ? permissionSelector.hasAnyPermissions(
        state,
        ownProps.noPermissionsSecond,
        true,
      )
    : true;

  return { hasAny, hasEvery, hasSingle, hasNot, hasSingleSecond, hasNotSecond };
};

export default compose(connect(mapStateToProps))(ControlledByPermission);
