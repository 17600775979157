/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component } from 'react';
import PropTypes from 'prop-types';
import Toast from 'react-bootstrap/Toast';
import { Trans } from 'react-i18next';
import { fontColors, spacing, uiColors } from '../styles/styles';

const style = {
  position: 'fixed',
  zIndex: 1100,
  top: spacing.headerHeight,
  marginLeft: 'auto',
  marginRight: 'auto',
  minWidth: '50rem',
  left: 0,
  right: 0,
  textAlign: 'center',

  '& .success': {
    backgroundColor: uiColors.GREEN,
    color: fontColors.BLACK,
  },
  '& .error': {
    backgroundColor: uiColors.RED,
    color: fontColors.WHITE,
  },
  '& .info': {
    backgroundColor: uiColors.GREY,
    color: fontColors.BLACK,
  },
};

class NotificationToast extends Component {
  static SUCCESS = 'success';
  static ERROR = 'error';
  static INFO = 'info';
  static propTypes = {
    type: PropTypes.oneOf([
      NotificationToast.SUCCESS,
      NotificationToast.ERROR,
      NotificationToast.INFO,
    ]),
    messageKeys: PropTypes.array,
  };

  static defaultProps = {
    type: 'success',
    messageKeys: [],
  };

  constructor(props) {
    super(props);
    this.state = { show: false };
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.messageKeys !== this.props.messageKeys && !this.state.show) {
      this.setState({ show: true });
    }
  }

  handleClose() {
    this.setState({ show: false });
  }

  render() {
    const { type, messageKeys, errorDuration, sucessDuration } = this.props;
    const show = !!(this.state.show && messageKeys && messageKeys.length);
    const duration =
      type === NotificationToast.ERROR ? errorDuration : sucessDuration;
    return (
      <Toast
        autohide
        delay={duration}
        onClose={this.handleClose}
        show={show}
        css={style}
      >
        <Toast.Body className={type}>
          {messageKeys.map((key, index) => (
            <Trans i18nKey={key} key={index} />
          ))}
        </Toast.Body>
      </Toast>
    );
  }
}

export default NotificationToast;
