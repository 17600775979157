import * as ActionTypes from '../../../constants/ActionTypes';
import { put, select, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router/immutable';
import { callAndAwaitApi } from '../../../utils/sagas';
import * as theoryItemActions from '../../../actions/api/theoryitem';
import * as theorySelector from '../../../selectors/api/theory';
import { buildPageLink } from '../../../utils/link';
import * as Urls from '../../../constants/Urls';
import * as relationsApiAction from '../../../actions/api/relation';
import { THEORYITEM } from '../../../constants/EntityTypes';

function* doCreate(action) {
  const { itemData, onSuccessHandler } = action.payload;
  itemData.jurisdiction = `/${itemData.jurisdiction.value}`;
  itemData.theoryItemType = itemData.articleType.value;
  itemData.theory = `/${(yield select(theorySelector.getDefault)).getIn([
    'node',
    'id',
  ])}`;
  const updateRelations = itemData.links;
  if (itemData.links) {
    itemData.relatedLawItems = itemData.links.relatedLawItems;
    itemData.relatedDecisions = itemData.links.relatedDecisions;
    itemData.keywords = itemData.links.keywords;
    itemData.relatedProcessSteps = itemData.links.relatedProcessSteps;
    delete itemData.links;
  }
  const res = yield callAndAwaitApi(
    theoryItemActions.createTheoryItem(itemData),
  );
  if (res.success?.payload.responseData) {
    yield put(
      push(
        buildPageLink(Urls.THEORY_ROUTE, res.success.payload.responseData.uri),
      ),
    );
    onSuccessHandler && onSuccessHandler();
    if (updateRelations) {
      yield put(
        relationsApiAction.updateEntity(
          res.success.payload.responseData.id,
          THEORYITEM,
        ),
      );
    }
  }
}

export default function* theoryCreateItem() {
  yield takeEvery(ActionTypes.PAGE_THEORY_CREATE_THEORY_ITEM, doCreate);
}
