import { fromJS } from 'immutable';
import * as ActionTypes from '../../constants/ActionTypes';
import * as EntityTypes from '../../constants/EntityTypes';
import { buildStructuredTree, mergeResponseToState } from './index';
import { getAllPagesRequestType } from '../../selectors/actions';

const initialState = fromJS({
  data: {},
  tree: {}, // TODO - THIS SHOULD BE OBTAINED VIA A CACHED (PAGE) SELECTOR
});
export default function jurisdictionApiReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.API_JURISDICTION_GET_ALL_SUCCESS:
      return mergeResponseToState(state, action, EntityTypes.JURISDICTION);
    case ActionTypes.TXN_ALL_PAGES_COMPLETE:
      if (
        getAllPagesRequestType(action) ===
        ActionTypes.API_JURISDICTION_GET_ALL_INIT
      ) {
        return buildStructuredTree(state); // TODO - THIS SHOULD BE OBTAINED VIA A CACHED (PAGE) SELECTOR
      }
      return state;
    default:
      return state;
  }
}
