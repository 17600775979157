import { call, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import { generateApiSaga } from '../../../utils/sagas';
import AppUserConnector from '../../../connectors/AppUser';

const doFetch = generateApiSaga(
  AppUserConnector,
  function* (connector, action) {
    return yield call(
      [connector, connector.getUserConsentById],
      action.payload.id,
    );
  },
);

export default function* getUserConsentById() {
  yield takeEvery(
    ActionTypes.API_USER_CONSENT_GET_USER_CONSENT_BY_ID_INIT,
    doFetch,
  );
}
