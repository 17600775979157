import { fromJS } from 'immutable';
import * as ActionTypes from '../../constants/ActionTypes';

const initialState = fromJS({
  isLoading: true,
});

export default function adminHomePageReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.APP_PAGE_ADMIN_HOME_USER_DETAILS_LOAD_INIT:
      return state.merge({ isLoading: true });
    case ActionTypes.APP_PAGE_ADMIN_HOME_USER_DETAILS_LOAD_ERROR:
    case ActionTypes.APP_PAGE_ADMIN_HOME_USER_DETAILS_LOAD_SUCCESS:
      return state.merge({ isLoading: false });
    default:
      return state;
  }
}
