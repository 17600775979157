/* CONSTANTS */
const THEORY_ITEM_FIND_BY_SECTIONNUMBER_AND_THEORYITEMTYPE =
  'findBySectionNumberAndTheoryItemType';
const FIND_BY_URI = 'findByUri';
const FIND_BY_IDS = 'findByIdIn';
const FIND_BY_COURT_ID_IN = 'findByCourt_IdIn';
const FIND_BY_THEORY_AND_THEORY_ITEM_TYPE = 'findByTheoryAndTheoryItemType';
const FIND_BY_LAW = 'findByLaw';
const FIND_BY_PROCESS_ID = 'findByProcessId';
const FIND_BY_TITLE_OR_CONTENT = 'findByTitleContainsOrContentContains';
const THEORY_ITEM_TOP_LEVEL = 'findByParentTheoryItemIsNullAndTheoryItemType';
const LAW_ITEM_TOP_LEVEL = 'findByLawAndParentLawItemIsNull';
const FIND_BY_TYPE = 'findByTypeIn';
const FIND_LAW_ITEM_BY_NAME = 'findByRawExcelLabelContainsIgnoreCase';
const DECISION_FIND_BY_RELATED_THEORY_ITEM_ID = 'findByRelatedTheoryItems';
const LICENSE_AVAILABLE_LICENSE = 'findByExpirationDateAfter';
const COUNT_BY_ACTIVE_AND_CONSENT_TYPE =
  'countDistinctByConsents_activeAndConsents_consentType';
const FIND_BY_ACTIVE_AND_CONSENT_TYPE =
  'findDistinctByConsents_activeAndConsents_consentType';
const FIND_BY_TITLE_IGNORE_CASE = 'findByTitleContainingIgnoreCase';
const FIND_BY_URI_IS_NOT_NULL = 'findByUriIsNotNull';
const FIND_BY_NAME_IGNORE_CASE = 'findByNameContainingIgnoreCase';
const FIND_THEORY_ITEM_WITH_PARENT_AND_JURISDICTION =
  'findByParentTheoryItemIsNotNullAndJurisdiction_Alpha2CodeIn';
const FIND_BY_DECISION_TYPE_IN = 'findByDecisionTypeIn';
const FIND_BY_DECISION_TYPE_IN_AND_COURT_ID_IN =
  'findByDecisionTypeInAndCourt_IdIn';
const FIND_BY_EDITOR_NAME_OR_CASE_NAME =
  'findByEditorNameContainsIgnoreCaseOrEditorNameIsNullAndCaseNameContainsIgnoreCase';

/* FUNCTIONS */
export const findByParentTheoryItemIsNullAndTheoryItemType = () =>
  findBy(THEORY_ITEM_TOP_LEVEL, { type: 'SECTION' });
export const findBySectionNumberAndTheoryItemType = (
  sectionNumber,
  theoryItemType,
) =>
  findBy(THEORY_ITEM_FIND_BY_SECTIONNUMBER_AND_THEORYITEMTYPE, {
    sectionNumber: sectionNumber,
    theoryItemType: theoryItemType,
  });
export const findByUri = (uri) => findBy(FIND_BY_URI, { uri: uri });
export const findByIds = (ids) => findBy(FIND_BY_IDS, { ids: ids });

export const findByCourtIdIn = (courts) =>
  findBy(FIND_BY_COURT_ID_IN, { courtIds: courts.join(',') });
export const findByDecisionTypeIn = (decisionTypes) =>
  findBy(FIND_BY_DECISION_TYPE_IN, { decisionTypes: decisionTypes.join(',') });
export const findByDecisionTypeInAndCourtIdIn = (decisionTypes, courts) =>
  findBy(FIND_BY_DECISION_TYPE_IN_AND_COURT_ID_IN, {
    decisionTypes: decisionTypes.join(','),
    courtIds: courts.join(','),
  });

export const findByLaw = (lawId) =>
  findBy(FIND_BY_LAW, { law: `law/${lawId}` });
export const findByProcessId = (processId) =>
  findBy(FIND_BY_PROCESS_ID, { processId: processId });
export const findByTheoryAndTheoryItemType = (theoryId, type) =>
  findBy(FIND_BY_THEORY_AND_THEORY_ITEM_TYPE, {
    theory: `theory/${theoryId}`,
    type: type,
  });
export const findByTitleOrContent = (title, content) =>
  findBy(FIND_BY_TITLE_OR_CONTENT, {
    title: title,
    content: content,
  });
export const findLawItemByLawId = (lawId) =>
  findBy(LAW_ITEM_TOP_LEVEL, { law: `law/${lawId}` });
export const findFavoritesByTypes = (types) =>
  findBy(FIND_BY_TYPE, { types: (types || []).join(',') });
export const findLawItemByName = (name) =>
  findBy(FIND_LAW_ITEM_BY_NAME, { rawExcelLabel: name });
export const findByUriIsNotNull = () => ({ search: FIND_BY_URI_IS_NOT_NULL });
export const findDecisionEditorNameOrCaseName = (editorNameOrCaseName) =>
  findBy(FIND_BY_EDITOR_NAME_OR_CASE_NAME, { search: editorNameOrCaseName });
export const findByRelatedTheoryItem = (theoryItemId) =>
  findBy(DECISION_FIND_BY_RELATED_THEORY_ITEM_ID, {
    item: `theoryitem/${theoryItemId}`,
  });
export const claimLicense = (id) => findBy('claimLicens', { id });
export const availableLicense = (date) =>
  findBy(LICENSE_AVAILABLE_LICENSE, { expirationDate: date });
export const countUsersByConsent = (consentType) =>
  findBy(COUNT_BY_ACTIVE_AND_CONSENT_TYPE, {
    consents_consentType: consentType,
    consents_active: true,
  });
export const findUsersByConsent = (consentType) =>
  findBy(FIND_BY_ACTIVE_AND_CONSENT_TYPE, {
    consents_consentType: consentType,
    consents_active: true,
  });
export const findTheoryItemByTitle = (title) =>
  findBy(FIND_BY_TITLE_IGNORE_CASE, { title });
export const findProcessStepByName = (name) =>
  findBy(FIND_BY_NAME_IGNORE_CASE, { name });
export const findTheoryItemsWithParentAndJurisdiction = (codes) =>
  findBy(FIND_THEORY_ITEM_WITH_PARENT_AND_JURISDICTION, { codes });

/* HELPERS */
export const findBy = (search, criteria) => ({ search, criteria });
