import { call, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../constants/ActionTypes';

import { generateApiSaga } from '../../utils/sagas';
import itemConnector from '../../connectors/Item';
import { getEntityId, getEntityType } from '../../utils/entities';

const doPatch = generateApiSaga(itemConnector, function* (connector, action) {
  const nodeId = getEntityId(action.payload.node);
  const nodeType = getEntityType(action.payload.node);
  const patch = action.payload.patch;
  return yield call(
    [connector, connector.patchEntity],
    nodeType,
    nodeId,
    patch,
  );
});

export default function* handleDataPatchAction() {
  yield takeEvery(ActionTypes.APP_DATA_PATCH_ENTITY_INIT, doPatch);
}
