import * as ActionTypes from '../../constants/ActionTypes';

export const getTopLevel = (node) => ({
  type: ActionTypes.API_LAWITEM_GET_TOP_LEVEL_BY_LAW_INIT,
  payload: { node },
});
export const getFirstLawItem = (node) => ({
  type: ActionTypes.API_LAW_ITEM_GET_FIRST_ITEM_BY_LAW_INIT,
  payload: { node },
});
export const getParent = (nodeIds) => ({
  type: ActionTypes.API_LAWITEM_GET_ALL_BY_URI_WITH_PARENTS_INIT,
  payload: { nodeIds },
});
export const getChildrenByItemId = (node) => ({
  type: ActionTypes.API_LAWITEM_GET_ALL_CHILDREN_INIT,
  payload: { node },
});
export const getAll = () => ({ type: ActionTypes.API_LAWITEM_GET_ALL_INIT });
export const getAllTxn = () => ({ type: ActionTypes.API_LAWITEM_GET_ALL_TXN });
export const getAllByLaw = (node) => ({
  type: ActionTypes.API_LAWITEM_GET_ALL_BY_LAW_INIT,
  payload: { node },
});
export const getAllByLawTxn = (node) => ({
  type: ActionTypes.API_LAWITEM_GET_ALL_BY_LAW_TXN,
  payload: { node },
});
export const getByUri = (uri) => ({
  type: ActionTypes.API_LAWITEM_GET_BY_URI_INIT,
  payload: { uri },
});
export const getByIds = (ids) => ({
  type: ActionTypes.API_LAWITEM_GET_BY_IDS_INIT,
  payload: { ids },
});
export const getRelationTreeById = (id) => ({
  type: ActionTypes.API_LAW_ITEM_GET_RELATION_TREE_BY_ID_INIT,
  payload: { id },
});
export const getRelationById = (id) => ({
  type: ActionTypes.API_LAW_ITEM_GET_RELATION_BY_ID_INIT,
  payload: { id },
});
export const searchByName = (name) => ({
  type: ActionTypes.API_LAWITEM_SEARCH_BY_NAME_INIT,
  payload: { name },
});
export const getLatest = () => ({
  type: ActionTypes.API_LAW_ITEM_GET_TOP_INIT,
  payload: {},
});
export const getRelatedLawByLawId = (lawId) => ({
  type: ActionTypes.API_LAWITEM_GET_ALL_RELATED_LAWITEM_BY_LAW_ID_INIT,
  payload: { lawId },
});
export const getAllLawItemsTypes = () => ({
  type: ActionTypes.API_GET_ALL_LAW_ITEM_TYPES_INIT,
});
