/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose, pure } from 'recompose';
import { createStructuredSelector } from 'reselect';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import * as EntityTypes from '../../constants/EntityTypes';
import { push } from 'connected-react-router/immutable';
import { ProgressBar } from 'react-bootstrap';
import { uiColors } from '../../components/common/styles/styles';
import NavigationTree from '../../components/navigationTree/NavigationTree';
import * as linkUtils from '../../utils/link';
import * as urls from '../../constants/Urls';
import * as searchPageSelectors from '../../selectors/page/search';
import * as searchPageActions from '../../actions/page/search';

const styles = { backgroundColor: uiColors.WHITE };

const SmartNavigationTree = pure(withImmutablePropsToJS(NavigationTree));

class TheorySearchView extends React.Component {
  constructor(props) {
    super(props);
    this.updateFilter = this.updateFilter.bind(this);
    this.handleRedirect = this.handleRedirect.bind(this);
  }

  updateFilter(filter) {
    this.props.actions.updateSearch(filter);
  }

  handleRedirect(selectedNode) {
    this.props.actions.push(
      linkUtils.buildPageLink(urls.THEORY_ROUTE, selectedNode.node.uri),
    );
  }

  render() {
    const { theoryItemSummary, tree } = this.props;
    const totalItems = theoryItemSummary.get('totalItems');
    const loadedItems =
      theoryItemSummary.get('totalItems') -
      theoryItemSummary.get('totalItemsMissing');

    return (
      <React.Fragment>
        <ProgressBar
          animated
          max={totalItems}
          now={loadedItems}
          label={`${loadedItems}/${totalItems}`}
          className={loadedItems === totalItems ? 'loading-done' : ''}
        />
        <div css={styles}>
          <SmartNavigationTree
            entityType={EntityTypes.THEORYITEM}
            tree={tree}
            onNodeSelection={this.handleRedirect}
          />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  theoryItemSummary: searchPageSelectors.getLatestSearchTheoryItemSummary,
  tree: searchPageSelectors.getLatestSearchTheoryItemTree,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      push: push,
      updateSearch: searchPageActions.updateFilter,
    },
    dispatch,
  ),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  TheorySearchView,
);
