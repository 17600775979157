import { takeEvery, put, call } from 'redux-saga/effects';
import * as ActionTypes from '../../constants/ActionTypes';
import { awaitApiCalls, callAndAwaitApi } from '../../utils/sagas';
import { cloneInitAction, getAllPagesComplete } from '../../actions/api';
import * as actionSelectors from '../../selectors/actions';

function* doGetAllPages(action) {
  let initAction = cloneInitAction(
    actionSelectors.getInitAction(action),
    1000,
    0,
  );
  let firstResult = yield callAndAwaitApi(initAction);
  let success = firstResult.success;
  if (success) {
    let paging = actionSelectors.getResponsePagingData(firstResult.success);
    if (paging && paging.totalPages > 1) {
      let totalPages = paging.totalPages;

      let pageActions = [];
      for (let i = 1; i < totalPages; i++) {
        pageActions.push(
          cloneInitAction(actionSelectors.getInitAction(action), 1000, i),
        );
      }
      let pageResults = yield call(awaitApiCalls, pageActions);
      success = pageResults.isSuccess();
    }
  }
  return yield put(getAllPagesComplete(action, success));
}
export default function* getAllPagesTxn() {
  yield takeEvery(ActionTypes.TXN_ALL_PAGES_INIT, doGetAllPages);
}
