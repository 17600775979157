import * as ActionTypes from '../../constants/ActionTypes';

export const getAll = () => ({ type: ActionTypes.API_USER_GET_ALL_INIT });
export const getById = (id) => ({
  type: ActionTypes.API_USER_GET_BY_ID_INIT,
  payload: { id },
});
export const update = (appUser) => ({
  type: ActionTypes.API_USER_UPDATE_APP_USER_INIT,
  payload: { appUser },
});
