import { isImmutable } from 'immutable';
import * as EntityTypes from '../constants/EntityTypes';
import EntityFields from '../constants/EntityFields';
import * as TheoryItemType from '../constants/TheoryItemType';
import TaxonomyType from '../constants/TaxonomyType';

export const getNodeKey = (node, key) =>
  isImmutable(node) ? node.get(key) : node?.[key];
export const getNodeSection = (node) => getNodeKey(node, 'node');
export const getEntityType = (node) => getNodeKey(node, 'entityType');
export const getEntityId = (node) => getNodeKey(node, 'id');
export const getEntityUri = (node) =>
  getNodeKey(node, EntityFields[getEntityType(node)].URI) || '';
export const isKeyword = (obj) =>
  typeof obj === 'object' &&
  Number.isInteger(obj.id) &&
  (obj.type === TaxonomyType.CPV ||
    obj.type === TaxonomyType.KEYWORD ||
    obj.type === TaxonomyType.SERVICE);

export const getCompositeTitle = (node) => {
  if (isImmutable(node)) {
    node = node.get('node').toJS();
  }
  switch (node.entityType) {
    case EntityTypes.LAWITEM:
      // let law = state.getIn(['api', EntityTypes.LAW, 'data', node.id])
      // return law.getIn(['node', 'name']) + ' ' + node.title
      return node.title;
    case EntityTypes.THEORYITEM:
      return node.title;
    case EntityTypes.DECISION:
      return node.shortName;
    default:
      return node.uri;
  }
};

let resolveCanonicalName = {
  [EntityTypes.THEORYITEM]: (node) =>
    node.theoryItemType === TheoryItemType.PARAGRAPH
      ? node.sectionNumber + '-' + node.subSectionNumber
      : node.sectionNumber,
  [EntityTypes.LAWITEM]: (node) => node.itemPath.join('.'),
};

export const getEntityCanonicalDisplayName = (node) => {
  let result = 'unknown';
  if (isEntity(node)) {
    let f = resolveCanonicalName[getEntityType(node)];
    if (f) {
      result = f(node);
    }
  }
  return result;
};

export const isEntity = (node) =>
  isImmutable(node)
    ? !!node.getIn(['_links'])
    : typeof node == 'object' && !!node._links;

export const getChildTypes = (node) => {
  return getChildType(getEntityType(node));
};
export const getChildType = (type) => {
  switch (type) {
    case EntityTypes.THEORY:
    case EntityTypes.THEORYITEM:
      return EntityTypes.THEORYITEM;
    case EntityTypes.LAW:
    case EntityTypes.LAWITEM:
      return EntityTypes.LAWITEM;
    default:
      return '';
  }
};
