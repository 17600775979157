import { fromJS } from 'immutable';
import * as ActionTypes from '../../constants/ActionTypes';

const initialState = fromJS({});

export default function theoryPageReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.APP_PAGE_THEORY_LOADED:
      return state.merge(
        fromJS({
          isLoading: false,
        }),
      );
    case ActionTypes.APP_PAGE_THEORY_LOAD:
      return state.merge(
        fromJS({
          isLoading: true,
        }),
      );
    case ActionTypes.PAGE_THEORY_LAST_URI:
      return state.merge(
        fromJS({
          lasturi: action.payload.uri,
        }),
      );
    case ActionTypes.PAGE_THEORY_SET_NAVIGATION:
      return state.merge(
        fromJS({
          previousNode: action.payload.previousNode,
          nextNode: action.payload.nextNode,
        }),
      );
    default:
      return state;
  }
}
