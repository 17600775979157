import { call, put, select } from 'redux-saga/effects';
import { generateApiSaga, takeLatestWhenAppReady } from '../../utils/sagas';
import * as ActionTypes from '../../constants/ActionTypes';
import * as LoginTypes from '../../constants/LoginTypes';
import * as LocalStorageKeys from '../../constants/LocalStorageKeys';
import * as authSelectors from '../../selectors/store/auth';
import * as appActions from '../../actions/app';
import AuthConnector from '../../connectors/Auth';
import * as AppSelectors from '../../selectors/store/app';

function* doDeveloperLogout() {
  yield call(() =>
    window.localStorage.removeItem(LocalStorageKeys.LOGIN_DEVELOPER_USERNAME),
  );
}

function* doJwtLogout() {
  yield call(() =>
    window.localStorage.removeItem(LocalStorageKeys.LOGIN_JWT_TOKEN_EXPIRATION),
  );
}

const doLogout = generateApiSaga(
  AuthConnector,
  function* (connector, action) {
    const currentLoginType = yield select(authSelectors.currentLoginType);

    const map = {
      [LoginTypes.DEVELOPER_LOGIN]: doDeveloperLogout,
      [LoginTypes.JWT_LOGIN]: doJwtLogout,
    };

    if (currentLoginType in map) {
      yield call(map[currentLoginType], action);
    } else {
      console.warn('Unknown login type: ' + currentLoginType);
      console.trace();
    }
    if (!action.payload.backgroundLogout) {
      yield put(appActions.relocateToLoginPage());
    }
    return yield call([connector, connector.logOut]);
  },
  function* (ConnectorClass) {
    const url = yield select(AppSelectors.baseAuthUrl);
    return yield new ConnectorClass(url);
  },
);

export default function* logout() {
  yield takeLatestWhenAppReady(ActionTypes.AUTH_LOGOUT_INIT, doLogout);
}
