import * as EntityTypes from '../../constants/EntityTypes';
import { List, Map } from 'immutable';
import { LawPageEntities, PageEntities } from '../../constants/PageEntities';
import * as urls from '../../constants/Urls';
import { buildPageLink } from '../../utils/link';
import { createSelector } from 'reselect';
import { NAMES_ALPHABETICALLY_BASED } from '../../constants/Sort';
import { sort } from '../../utils/sort';

const filterFavoritesByPage = (allFavorites, pageFavorites) =>
  allFavorites.filter((entity, key) => pageFavorites.includes(key));

export const getPageFavorites = (state, pageFavorites) =>
  getAll(state).filter((entity, key) => pageFavorites.includes(key));

export const getFavorites = (state) => getAll(state);

export const getDecisionPageFavorites = createSelector(
  getFavorites,
  (favorites) =>
    favorites.filter((entity, key) => PageEntities.DECISION_PAGE.includes(key)),
);

export const getAll = (state) =>
  state.getIn(['api', EntityTypes.FAVORITE, 'data'], Map());

export const hasAll = (state) => state.getIn(['filter', 'all']);

export const getTheoryPageFavorites = createSelector(getAll, (allFavorites) =>
  filterFavoritesByPage(allFavorites, PageEntities.THEORY_PAGE),
);

export const getTaxonomyPageFavorites = createSelector(getAll, (allFavorites) =>
  filterFavoritesByPage(allFavorites, PageEntities.TAXONOMY_PAGE),
);

export const getLawPageFavorites = createSelector(getAll, (allFavorites) =>
  filterFavoritesByPage(
    allFavorites,
    PageEntities.LAW_PAGE[LawPageEntities.LAWITEM],
  ).merge(
    filterFavoritesByPage(
      allFavorites,
      PageEntities.LAW_PAGE[LawPageEntities.LAWPREFACEITEM],
    ),
  ),
);

export const getAllGroupedByPage = (state) => {
  let map = Map();
  Object.entries(PageEntities).forEach(([key, value]) => {
    const favorites = getPageFavorites(state, value).reduce(
      (prev, current) => prev.push(...updateUri(state, current)),
      List(),
    );
    if (favorites.size) {
      const sortedFavorites = sort(
        favorites.toJS(),
        NAMES_ALPHABETICALLY_BASED,
      );
      map = map.set(key, sortedFavorites);
    }
  });
  return map;
};

export const updateUri = (state, map) => {
  return map.toList().map((entry) => getUriUpdateFnByType(state, entry));
};

export const updateLawItemUri = (state, entry) => {
  return entry.set(
    'uri',
    `${buildPageLink(urls.LAW_ROUTE, 'all', entry.get('uri'))}`,
  );
};

export const updateTheoryItemUri = (state, entry) => {
  return entry.set(
    'uri',
    `${buildPageLink(urls.THEORY_ROUTE, entry.get('uri'))}`,
  );
};

export const updateDecisionUri = (state, entry) => {
  return entry.set(
    'uri',
    `${buildPageLink(urls.DECISION_ROUTE, 'all', entry.get('uri'))}`,
  );
};

export const updateTaxonomyUri = (state, entry) => {
  entry = entry.set('uri', `${buildPageLink(urls.SEARCH_ROUTE)}`);
  const favoriteState = Map({
    value: entry.get('entityId'),
    label: entry.get('name'),
  });
  return entry.set('uriState', Map().set('keywords', List.of(favoriteState)));
};

export const getUriUpdateFnByType = (state, entry) => {
  switch (entry.get('type')) {
    case EntityTypes.LAWITEM:
      return updateLawItemUri(state, entry);
    case EntityTypes.THEORYITEM:
      return updateTheoryItemUri(state, entry);
    case EntityTypes.DECISION:
      return updateDecisionUri(state, entry);
    case EntityTypes.TAXONOMY:
      return updateTaxonomyUri(state, entry);
    default:
      return entry;
  }
};
