/** @jsx jsx */
import React from 'react';
import { Card, ListGroup } from 'react-bootstrap';
import CapabilityGroup from '../molecules/CapabilityGroup';
import { jsx } from '@emotion/core';

/**
 * Each card represents a license type. It has a header with pricing information and lists a sequence of Capabilities.
 */
class LicenseCard extends React.Component {
  render() {
    const { cardInfo, cardIndex, heights, noStyling } = this.props;

    return (
      <>
        <Card className={noStyling ? 'no-styling' : null}>
          <Card.Img variant="top" src={cardInfo.imgSrc} />
          <Card.Header className={`color-header color-${cardIndex}`}>
            <Card.Title>{cardInfo.headline ?? ' '}</Card.Title>
            <Card.Subtitle>{cardInfo.subtitle ?? ' '}</Card.Subtitle>
            <Card.Subtitle className="vat">
              {cardInfo.subSubtitle ?? ' '}
            </Card.Subtitle>
          </Card.Header>
          <Card.Body>
            <ListGroup as="ol" variant="flush">
              {cardInfo.capabilities.map((capability, row) => (
                <CapabilityGroup
                  capability={capability}
                  height={heights[row]}
                  key={row}
                />
              ))}
            </ListGroup>
          </Card.Body>
        </Card>
      </>
    );
  }
}

export default LicenseCard;
