/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { spacing, uiColors } from '../styles/styles';

const backdropStyles = {
  background: uiColors.PURPLE_DARK,
  height: spacing.headerHeight,
  position: 'fixed',
  width: '100%',
  zIndex: 1000,
};

const spacerStyles = {
  height: spacing.headerHeight,
};

class DefaultPageLayout extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div css={backdropStyles} />
        <div css={spacerStyles} />
        {this.props.children}
      </React.Fragment>
    );
  }
}

export default DefaultPageLayout;
