import { fontColors } from './styles';

export const styles = {
  '.active': {
    fontWeight: 'bold',
  },
  '.tab-color:hover': {
    transform: 'scale(1.01)',
  },
  '.nav-link': {
    color: fontColors.BLACK,
  },
};
