/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import Filter from '../../components/common/molecules/Filter';
import * as commonActions from '../../actions/commonactions';
import * as lawItemApiAction from '../../actions/api/lawitem';
import * as lawItemApiSelector from '../../selectors/api/lawitem';
import { refBind } from '../../utils/ref-bind';
import * as SelectUtils from '../../utils/AutocompleteMapper';
import { createTagName } from '../../utils/filter';

class LawSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = { lawItemFilter: '', selected: null };
    refBind(this, 'searchRelatedLaws', 'handleChange');
  }

  componentDidMount() {
    const { actions } = this.props;
    actions.searchLawItems('');
  }

  searchRelatedLaws(lawItemFilter) {
    const { actions } = this.props;
    this.setState({ lawItemFilter });
    lawItemFilter &&
      actions.debounce(lawItemApiAction.searchByName(lawItemFilter));
  }

  handleChange(selected) {
    const { clearOnSelect, onLawItemsChange } = this.props;
    if (!clearOnSelect) {
      this.setState({ selected });
    }
    onLawItemsChange && onLawItemsChange(selected);
  }

  render() {
    const { isDisabled, lawItemData, ignoreOptions, isMulti } = this.props;
    const { lawItemFilter, selected } = this.state;
    const currentLawItemFilter = createTagName(lawItemFilter);
    const filterData = lawItemData[currentLawItemFilter];
    const lawItemOptions = SelectUtils.removeIgnoredItems(
      SelectUtils.mapLawItems(filterData),
      ignoreOptions,
    );
    return (
      <Filter
        isDisabled={isDisabled}
        options={lawItemOptions || []}
        isMulti={isMulti}
        value={selected}
        onInputChange={this.searchRelatedLaws}
        onChange={this.handleChange}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const lawItemData = lawItemApiSelector.getSearchData(state);

  return {
    ...ownProps,
    lawItemData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      searchLawItems: lawItemApiAction.searchByName,
      debounce: commonActions.debounceAction,
    },
    dispatch,
  ),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withImmutablePropsToJS,
)(LawSearch);
