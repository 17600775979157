import { call, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import TheoryItemConnector from '../../../connectors/TheoryItem';
import { generateApiSaga } from '../../../utils/sagas';

const doGetAllChildren = generateApiSaga(
  TheoryItemConnector,
  function* (connector, action) {
    const nodeId = action.payload.nodeId;
    return yield call([connector, connector.getChildren], nodeId);
  },
);

export default function* getAllChildren() {
  yield takeEvery(
    ActionTypes.API_THEORY_ITEM_GET_ALL_CHILDREN_INIT,
    doGetAllChildren,
  );
}
