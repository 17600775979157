import { call } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import { takeLatestWhenAppReady } from '../../../utils/sagas';
import * as decisionActionSelector from '../../../selectors/actions/decision';

function* doHandleDecisionDownload(downloadFileResult) {
  const fileBlob = yield call(() => downloadFileResult.payload.blob);
  const fileName = decisionActionSelector.getFileName(downloadFileResult);

  const linkToFileDownload = document.createElement('a');
  linkToFileDownload.href = URL.createObjectURL(fileBlob);
  linkToFileDownload.download = fileName;
  document.body.appendChild(linkToFileDownload);
  linkToFileDownload.click();
  document.body.removeChild(linkToFileDownload);
  // For some versions of Firefox it is necessary to delay revoking the ObjectURL
  setTimeout(() => {
    // For optimization and security on all browsers, it removes the blob from browser's memory.
    window.URL.revokeObjectURL(linkToFileDownload.href);
  }, 1000);
}

export default function* handleDecisionDownload() {
  yield takeLatestWhenAppReady(
    ActionTypes.API_DECISION_FILE_GET_BY_DECISION_ID_SUCCESS,
    doHandleDecisionDownload,
  );
}
