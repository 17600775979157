import * as ActionTypes from '../../constants/ActionTypes';

export const pageLoad = (node) => ({
  type: ActionTypes.APP_PAGE_ADMIN_LOAD,
  payload: { node },
});
export const selectNode = (node) => ({
  type: ActionTypes.APP_PAGE_ADMIN_SELECT_NODE,
  payload: { node },
});
export const saveUpdates = (node, updates, handler) => ({
  type: ActionTypes.APP_PAGE_ADMIN_UPDATE_NODE,
  payload: { node, updates, handler },
});
export const deleteArticle = (node, handler) => ({
  type: ActionTypes.APP_PAGE_ADMIN_DELETE_NODE,
  payload: { node, handler },
});
