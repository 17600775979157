import * as ActionTypes from '../../constants/ActionTypes';
import { fromJS } from 'immutable';
import { mergeResponseToState } from './index';
import { APPUSER } from '../../constants/EntityTypes';

const initialState = fromJS({
  data: {},
  count: -1, // TODO: RENAME ME! And move me to appuserconsent.js
});

export default function appUserApiReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.AUTH_LOGIN_COMPLETE:
    case ActionTypes.AUTH_LOGOUT_SUCCESS:
      return initialState;
    case ActionTypes.API_USER_GET_ALL_SUCCESS:
    case ActionTypes.API_USER_GET_BY_ID_SUCCESS:
    case ActionTypes.API_USER_UPDATE_APP_USER_SUCCESS:
      return mergeResponseToState(state, action, APPUSER);
    default:
      return state;
  }
}
