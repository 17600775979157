/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router/immutable';
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Dropdown,
  Form,
  Modal,
  Row,
} from 'react-bootstrap';
import ContentOuterBound from '../../components/common/atoms/ContentOuterBound';
import wavesImagePath from '../../images/wave.svg';
import { bindActionCreators } from 'redux';
import Text, { TextType } from '../../components/common/atoms/Text';
import { Trans, withTranslation } from 'react-i18next';
import * as signupPageSelectors from '../../selectors/page/signup';
import * as signupPageActions from '../../actions/page/signup';
import UserForm from '../../components/common/molecules/UserForm';
import CompanyForm from '../../components/common/molecules/CompanyForm';
import PasswordForm from '../../components/common/molecules/PasswordForm';
import ConsentForm from '../../components/common/molecules/ConsentForm';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import {
  fontColors,
  gradientColors,
  rgbaColors,
  uiColors,
} from '../../components/common/styles/styles';
import * as URLs from '../../constants/Urls';
import DefaultPageLayout from '../../components/common/atoms/DefaultPageLayout';

const styles = (isMessageResponse) => ({
  '& .page-main': {
    background: 'url(' + wavesImagePath + ') ' + uiColors.BLUE_MAGENTA,
    backgroundSize: 'cover',
    color: fontColors.WHITE,
    height: isMessageResponse ? '100vh' : 'auto',
    '& .right-side': {
      '& button+button': {
        marginLeft: '1em',
      },
      '& button': {
        background: rgbaColors.BLACK_SHADOW_30,
        '&.accent': {
          background: rgbaColors.ORANGE_20,
          borderColor: uiColors.BRAND,
        },
      },
    },
    '& .signupBox': {
      padding: '3em',
      paddingTop: '2em',
      paddingBottom: '1em',
      backgroundColor: uiColors.WHITE,
      color: fontColors.BLACK,
      '& form': {
        '& label': {
          textTransform: 'uppercase',
          color: fontColors.GREY,
        },
        '& Button.btn-primary': {
          '& svg': {
            verticalAlign: 'baseline',
          },
          '&.accent': {
            background: gradientColors.ORANGE,
            borderColor: uiColors.DARK,
          },
        },
        '& .dropdown-button': {
          width: '100%',
        },
      },
      '& hr': {
        marginLeft: '-3em',
        marginRight: '-3em',
      },
      '& .reset-passwd, & .reset-passwd a': {
        color: fontColors.GREY,
      },
    },
  },
  '& .consent': {
    overflow: 'auto',
    '& label': {
      float: 'left',
    },
    '& input': {
      float: 'left',
      margin: '-6px 0px 0 10px',
      width: 'initial',
    },
  },
  '& .flex-row': {
    alignItems: 'center',
    justifyContent: 'center',
    padding: '50px 0',
  },
  '.error-text': {
    color: fontColors.BRAND,
    margin: '10px',
  },
  '.form-control:focus': {
    boxShadow: 'none',
  },
});

const modalStyles = {
  'button.accent': {
    background: gradientColors.ORANGE,
    borderColor: uiColors.DARK,
  },
  '.modal-body': {
    whiteSpace: 'break-spaces',
    'h5:not(:first-of-type)': {
      marginTop: '2rem',
    },
  },
};

const passwordSelectorId = 'password-form.password-id';
const repeatPasswordSelectorId = 'password-form.password-repeat-id';
const passwordRepeatElementId = 'password-repeat';

class SignupPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: '',
      forms: {},
      isForm: false,
      errorSection: null,
      showPrivacyPolicy: false,
    };

    this._onSubmit = this._onSubmit.bind(this);
    this.onFormFieldChange = this.onFormFieldChange.bind(this);
    this.goBackToSignUpForm = this.goBackToSignUpForm.bind(this);
    this._onTogglePrivacyPolicy = this._onTogglePrivacyPolicy.bind(this);
  }

  createErrorSection() {
    const { errors } = this.props;
    let errorSection =
      errors &&
      (errors.indexOf('[') > -1
        ? errors.replace(/[[\]']/g, '').split(', ')
        : [errors]);
    return errorSection?.map((error) => (
      <p key={error}>
        <Trans
          i18nKey={`errors.${error}`}
          values={{
            vat: this.state.forms.company?.vat,
            resetPasswordLink: URLs.FORGOT_PASSWORD_ROUTE,
          }}
          components={{ Link: <Link /> }}
        />
      </p>
    ));
  }

  componentDidMount() {
    const errorSection = this.createErrorSection();
    this.setState({ errorSection });
  }

  componentDidUpdate(prevProps, prevState) {
    const { errors } = this.props;
    const { isForm } = this.state;
    if (prevState.isForm !== isForm && isForm) {
      this.setState({ errorSection: null });
    } else if (prevProps.errors !== errors) {
      const errorSection = this.createErrorSection();
      this.setState({ errorSection });
    }
  }

  _onSubmit(event) {
    const { actions } = this.props;
    const form = event.currentTarget;
    const password = form.elements[passwordSelectorId];
    const repeatPassword = form.elements[repeatPasswordSelectorId];
    if (password.value !== repeatPassword.value) {
      password.classList.add('is-invalid');
      repeatPassword.classList.add('is-invalid');
      password.setCustomValidity(this.props.t('errors.password-mismatch'));
      repeatPassword.setCustomValidity(
        this.props.t('errors.password-mismatch'),
      );
    }
    event.stopPropagation();
    event.preventDefault();
    if (form.checkValidity() && password.value === repeatPassword.value) {
      this.setState({
        submitted: this.state.forms.user.email,
        isForm: false,
      });
      actions.submit(this.state.forms);
    }
  }

  onFormFieldChange(form_name, event) {
    let forms = this.state.forms;
    const field = forms[form_name];
    forms[form_name] = { ...field, [event.target.name]: event.target.value };
    if (
      forms.password?.password === forms.password?.[passwordRepeatElementId]
    ) {
      const password = document.getElementById(passwordSelectorId);
      const passwordRepeat = document.getElementById(repeatPasswordSelectorId);
      password.classList.remove('is-invalid');
      passwordRepeat.classList.remove('is-invalid');
      password.setCustomValidity('');
      passwordRepeat.setCustomValidity('');
    }
    this.setState({ forms });
  }

  goBackToSignUpForm() {
    const { actions } = this.props;
    this.setState({ isForm: true });
    actions.push();
  }

  _onTogglePrivacyPolicy() {
    this.setState({ showPrivacyPolicy: !this.state.showPrivacyPolicy });
  }

  getPolicySection(sectionName) {
    return (
      <div>
        {Array.from(
          Array(this.props.t(`contactPage.${sectionName}.length`)),
        ).map((_, i) => (
          <>
            <h5>
              <u>
                {`${i + 1}\t`}
                <Trans i18nKey={`contactPage.${sectionName}.${i}.header`} />
                {`\n`}
              </u>
            </h5>
            <Trans i18nKey={`contactPage.${sectionName}.${i}.text`} />
            {`\n\n`}
          </>
        ))}
      </div>
    );
  }

  render() {
    const { submitted, isForm, errorSection, showPrivacyPolicy } = this.state;
    const { submitResponse, confirmResponse, errors, t } = this.props;
    const showActivationMessage =
      confirmResponse &&
      confirmResponse.statusCode === 202 &&
      !submitResponse &&
      !isForm;
    const showActivationErrorMessage =
      confirmResponse && confirmResponse.statusCode !== 202 && !isForm;
    const showSignUpSuccessMessage =
      submitResponse && submitResponse.statusCode === 201 && !isForm;
    const showSignUpForm =
      (!confirmResponse &&
        (!submitResponse || submitResponse.statusCode !== 201)) ||
      isForm;

    return (
      <div css={styles(!showSignUpForm)}>
        <Modal
          show={!!submitted && !submitResponse}
          backdrop="static"
          keyboard={true}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <Trans i18nKey="signup.create-in-progress" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body />
        </Modal>
        <Modal
          show={showPrivacyPolicy}
          backdrop="static"
          keyboard={true}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          css={modalStyles}
        >
          <Modal.Header>
            <Modal.Title>
              <Trans i18nKey="signup.consent.policy-headline" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.getPolicySection('personalDataPolicy')}</Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              className="accent"
              onClick={this._onTogglePrivacyPolicy}
            >
              <Trans i18nKey="signup.consent.policy-close-button-text" />
            </Button>
          </Modal.Footer>
        </Modal>
        <div className="page-main">
          <DefaultPageLayout>
            <ContentOuterBound>
              <Container>
                <Row className="flex-row">
                  <Col>
                    <div className="signupBox border rounded">
                      {showActivationMessage && (
                        <div>
                          <Trans i18nKey="signup.activated-success" />
                          &nbsp;
                          <Link to={'/login'}>
                            <Trans i18nKey="signup.log-in" />
                          </Link>
                        </div>
                      )}
                      {showActivationErrorMessage && (
                        <div>
                          <Text as="p" type={TextType.HEADER_3}>
                            <Trans i18nKey="signup.error-confirm" />
                          </Text>
                          <Text as="div" type={TextType.PARAGRAPH_DEFAULT}>
                            {errorSection}
                          </Text>
                          <a href="#" onClick={this.goBackToSignUpForm}>
                            <Text as="p" type={TextType.PARAGRAPH_DEFAULT}>
                              <Trans as="p" i18nKey={'signup.go-to-signup'} />
                            </Text>
                          </a>
                        </div>
                      )}
                      {showSignUpSuccessMessage && (
                        <div>
                          <Text as="p" type={TextType.HEADER_2_LARGE}>
                            <Trans i18nKey="signup.verify-head" />
                          </Text>
                          <Text as="p" type={TextType.PARAGRAPH_DEFAULT}>
                            <Trans
                              i18nKey="signup.verify"
                              values={{
                                email:
                                  submitResponse.initAction.payload.signup
                                    .customer.email,
                              }}
                            />
                          </Text>
                          <Text as="p" type={TextType.PARAGRAPH_DEFAULT}>
                            <Trans i18nKey="signup.verify2" />
                          </Text>
                          <Text as="p" type={TextType.PARAGRAPH_STRONG}>
                            <Trans i18nKey="signup.verify-checkspam" />
                          </Text>
                        </div>
                      )}
                      {showSignUpForm && (
                        <div>
                          <Text as="p" type={TextType.HEADER_2_LARGE}>
                            <Trans i18nKey="signup.introhead" />
                          </Text>
                          <Text as="p" type={TextType.HEADER_2_SMALL}>
                            <Trans i18nKey="signup.intro" />
                          </Text>
                          <Text as="p" type={TextType.FOOTER_LINK}>
                            <Trans i18nKey="signup.intro2" />
                          </Text>

                          <Form onSubmit={this._onSubmit}>
                            <Text as="p" type={TextType.HEADER_2_SMALL}>
                              <Trans i18nKey="signup.company-intro" />
                            </Text>
                            <CompanyForm
                              onChange={this.onFormFieldChange}
                              defaults={this.state.forms.company}
                            />

                            <Text as="p" type={TextType.HEADER_2_SMALL}>
                              <Trans i18nKey="signup.profile-intro" />
                            </Text>
                            <UserForm
                              onChange={this.onFormFieldChange}
                              defaults={this.state.forms.user}
                            />

                            <Text as="p" type={TextType.HEADER_2_SMALL}>
                              <Trans i18nKey="signup.password-intro" />
                            </Text>
                            <PasswordForm
                              onChange={this.onFormFieldChange}
                              defaults={this.state.forms.password}
                            />

                            <Text as="p" type={TextType.HEADER_2_SMALL}>
                              <Trans i18nKey="signup.consent-intro" />
                            </Text>
                            <div className="consent">
                              <ConsentForm
                                onChange={this.onFormFieldChange}
                                defaults={this.state.forms.consent}
                              />
                            </div>
                            <div>
                              <Text as="p" type={TextType.PARAGRAPH_DEFAULT}>
                                <Trans i18nKey="signup.consent.link-pretext" />
                                <Button
                                  variant="link"
                                  onClick={this._onTogglePrivacyPolicy}
                                >
                                  <Trans i18nKey="signup.consent.link-text" />
                                </Button>
                              </Text>
                            </div>
                            {errors && (
                              <Fragment>
                                <br />
                                <Text
                                  as="div"
                                  type={TextType.HEADER_2_SMALL}
                                  className={'error-text'}
                                >
                                  {errorSection}
                                </Text>
                              </Fragment>
                            )}
                            <Form.Group>
                              <Dropdown
                                as={ButtonGroup}
                                className="mt-4 dropdown-button"
                              >
                                <Button
                                  size="lg"
                                  variant="primary"
                                  className="accent"
                                  type="submit"
                                  block
                                >
                                  <Trans i18nKey="signup.create-button" />
                                </Button>
                              </Dropdown>
                            </Form.Group>
                          </Form>
                        </div>
                      )}
                      <hr className="mt-4 mb-4" />
                    </div>
                  </Col>
                </Row>
              </Container>
            </ContentOuterBound>
          </DefaultPageLayout>
        </div>
      </div>
    );
  }
}

const pushSuccessEventToDatalayer = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'signup_success',
  });
};

const mapStateToProps = (state) => {
  let confirmResponse = signupPageSelectors.getConfirmResponse(state);
  let submitResponse = signupPageSelectors.getResponse(state);
  let statusCode =
    submitResponse && signupPageSelectors.responseStatusCode(submitResponse);
  let errors;
  if (submitResponse) {
    if (statusCode && statusCode === 201) {
      pushSuccessEventToDatalayer();
    }
    else {
      errors = signupPageSelectors.getResponseMessage(submitResponse);
    }
    confirmResponse = null;
  } else if (
    confirmResponse &&
    signupPageSelectors.responseStatusCode(confirmResponse) !== 202
  ) {
    errors = signupPageSelectors.getResponseMessage(confirmResponse);
    submitResponse = null;
  }
  return {
    confirmResponse,
    submitResponse,
    errors,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      submit: signupPageActions.submit,
      push,
    },
    dispatch,
  ),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withImmutablePropsToJS,
  withTranslation(),
)(SignupPage);
