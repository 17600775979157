import BaseConnector from './Base';
import * as EntityTypes from '../constants/EntityTypes';

export default class Newsworthy extends BaseConnector {
  constructor(...args) {
    super(...args);
    this.entityType = EntityTypes.NEWSWORTHY;
  }

  createNewsworthyItem(newsworthyItem) {
    return this.authPost(this.query().build(), newsworthyItem);
  }
}
