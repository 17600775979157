import * as ActionTypes from '../../constants/ActionTypes';
import { fromJS } from 'immutable';

const initialState = fromJS({
  lasturi: null,
});
export default function lawPageReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.APP_PAGE_LAW_LOAD:
      return state.merge(
        fromJS({
          loading: true,
        }),
      );
    case ActionTypes.APP_PAGE_LAW_LOADED:
      return state.merge(
        fromJS({
          loading: false,
        }),
      );
    case ActionTypes.PAGE_LAW_LAST_URI:
      return state.merge(
        fromJS({
          lasturi: action.payload.uri,
        }),
      );
    default:
      return state;
  }
}
