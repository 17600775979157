import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Text, { TextType } from './Text';
import { jsx } from '@emotion/core';
import Button from './Button';
import { Trans } from 'react-i18next';

class TableBody extends Component {
  static propTypes = {
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        fieldAccessor: PropTypes.string,
        name: PropTypes.string,
      }),
    ),
    rows: PropTypes.arrayOf(PropTypes.object),
    renderRowButton: PropTypes.func,
  };

  static defaultProps = {
    columns: [],
    rows: [],
  };

  render() {
    const { columns, rows, renderRowButton } = this.props;
    return (
      <tbody className="table-body">
        {rows.map((row, index) => (
          <tr className="table-row" key={`row-${index}`}>
            {columns.map(({ key, fieldAccessor }) => (
              <td key={key}>
                <Text type={TextType.TABLE_ROW}>
                  {row[fieldAccessor] ?? '——'}
                </Text>
              </td>
            ))}
            {renderRowButton && (
              <td key="row-button">{renderRowButton(row)}</td>
            )}
          </tr>
        ))}
      </tbody>
    );
  }
}

export default TableBody;
