import { call, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import lawPrefaceItemConnector from '../../../connectors/LawPrefaceItem';
import { generateApiSaga } from '../../../utils/sagas';

const doGetLawPrefaceItemByUri = generateApiSaga(
  lawPrefaceItemConnector,
  function* (connector, action) {
    let nodeFragment = action.payload.uri;
    return yield call([connector, connector.getByUri], nodeFragment);
  },
);

export default function* getLawPrefaceItemByUri() {
  yield takeEvery(
    ActionTypes.API_LAW_PREFACE_ITEM_GET_BY_URI_INIT,
    doGetLawPrefaceItemByUri,
  );
}
