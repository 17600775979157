import { fromJS } from 'immutable';
import * as ActionTypes from '../../constants/ActionTypes';
import * as EntityTypes from '../../constants/EntityTypes';
import { mergeResponseToState } from './index';

const initialState = fromJS({ data: {} });
export default function roleApiReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.API_GET_ALL_ROLES_SUCCESS:
      return mergeResponseToState(state, action, EntityTypes.ROLE);
    default:
      return state;
  }
}
