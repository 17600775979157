import {
  all,
  call,
  cancelled,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import * as ActionTypes from '../constants/ActionTypes';
import * as appSelectors from '../selectors/store/app';
import { baseApiUrl } from '../selectors/store/app';
import 'core-js/features/array/every';
import { LOCATION_CHANGE } from 'connected-react-router/immutable';
import { bearerToken } from '../selectors/store/auth';
import { Filter } from './filter';
import { getAllPages } from '../actions/api';
import { endsWith, isEqual } from 'lodash';

const callTypes = {
  INIT: 'INIT',
  REQUEST: 'REQUEST',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

function runWhenBootstrapped(fn, waitPreload = false) {
  return function* (action) {
    const isReady = yield select(appSelectors.appIsReady);
    if (!isReady) {
      yield take(ActionTypes.APP_BOOTSTRAP_COMPLETED);
    }
    if (waitPreload && !(yield select(appSelectors.preloadReady))) {
      yield take(ActionTypes.APP_PRELOAD_COMPLETED);
    }
    yield call(fn, action);
  };
}

export function* takeLatestWhenAppReady(actionType, fn) {
  yield takeLatest(actionType, runWhenBootstrapped(fn));
}

export function* takeEveryWhenAppReady(actionType, fn) {
  yield takeEvery(actionType, runWhenBootstrapped(fn));
}

export function* takeEveryWhenPreloadReady(actionType, fn) {
  yield takeEvery(actionType, runWhenBootstrapped(fn, true));
}

export function* takeLatestWhenPreloadReady(actionType, fn) {
  yield takeLatest(actionType, runWhenBootstrapped(fn, true));
}

export function* takeWhenAppReady(actionType, fn) {
  yield take(actionType, runWhenBootstrapped(fn));
}

export function* callAndAwaitApi(initAction) {
  let action = yield put(initAction);
  return yield awaitApiCall(action);
}

export function awaitApiCall(initAction) {
  let actionPrefix = initAction.type.replace(/INIT$/, '');
  let errorActionType = actionPrefix + callTypes.ERROR;
  let successActionType = actionPrefix + callTypes.SUCCESS;
  return race({
    error: take(
      (action) =>
        action.type === errorActionType &&
        action.payload.initAction === initAction,
    ),
    success: take(
      (action) =>
        action.type === successActionType &&
        action.payload.initAction === initAction,
    ),
  });
}

export function* captureLocationUpdate(locationMatcherFn, fn) {
  yield takeLatestWhenAppReady(
    (action) =>
      action.type === LOCATION_CHANGE &&
      locationMatcherFn(action.payload.location),
    fn,
  );
}

const createAggregateResponseObject = (allReponses) => ({
  allReponses,
  isSuccess: function () {
    return this.allReponses.every((r) => !!r.success);
  },
  getReponse: function (initAction) {
    let response = this.allReponses.find(
      (r) =>
        (r.success && r.success.payload.initAction === initAction) ||
        (r.error && r.error.payload.initAction),
    );
    return response.success || response.error;
  },
});

export function* awaitApiCalls(initActions) {
  let allReponses = yield all(initActions.slice().map(callAndAwaitApi));
  return createAggregateResponseObject(allReponses);
}

export function* callAndThrottleApi(initActions, numConcurrentCalls = 3) {
  let idx = 0,
    activeEffects = [],
    results = [];
  while (results.length < initActions.length) {
    while (
      activeEffects.length < numConcurrentCalls &&
      idx < initActions.length
    ) {
      let action = yield put(initActions[idx++]);
      activeEffects.push(action);
    }
    let raceObj = activeEffects.reduce((acc, cur, aIdx) => {
      acc[aIdx] = awaitApiCall(cur);
      return acc;
    }, {});
    let winner = yield race(raceObj);
    if (Object.keys(winner).filter((aIdx) => activeEffects[aIdx]) === 0) {
      // no match, probably a 'cancel'
      break;
    }
    Object.keys(winner).forEach((aIdx) => {
      activeEffects.splice(aIdx, 1);
      results.push(winner[aIdx]);
    });
  }
  return createAggregateResponseObject(results);
}

function* stdInstantiateConnector(ConnectorClass) {
  const url = yield select(baseApiUrl);
  const token = yield select(bearerToken);
  return yield new ConnectorClass(url, token);
}

function* generateFilter(url) {
  return yield new Filter(url);
}

function* handleCallException(error, actionPrefix, response, action) {
  yield put({
    type: ActionTypes[actionPrefix + callTypes.ERROR],
    payload: {
      error: error.toString(),
      stackTrace: error.stack,
      initAction: action,
      statusCode: response && response.status,
    },
  });
}

function* handleResponse(response, actionPrefix, action) {
  const latestResponse = response.length
    ? response[response.length - 1]
    : response;
  const statusCode = latestResponse.status;

  if (statusCode === 401 || statusCode === 403) {
    const error = 'Authentication error ' + statusCode;

    yield put({
      type: ActionTypes[actionPrefix + callTypes.ERROR],
      payload: {
        error,
        initAction: action,
        statusCode: statusCode,
      },
    });
  } else if (
    latestResponse.ok &&
    !Number(latestResponse.headers.get('content-length')) &&
    !latestResponse.headers.get('content-encoding')
  ) {
    yield put({
      type: ActionTypes[actionPrefix + callTypes.SUCCESS],
      payload: {
        response,
        initAction: action,
        statusCode: statusCode,
      },
    });
  } else if (
    latestResponse.ok &&
    latestResponse.headers.get('content-type') === 'multipart/form-data'
  ) {
    yield put({
      type: ActionTypes[actionPrefix + callTypes.SUCCESS],
      payload: {
        blob: response.blob(),
        fileName: response.headers
          .get('content-disposition')
          ?.match(/"[^"]*"|^[^"]*$/)[0]
          .replace(/"/g, ''),
        initAction: action,
        statusCode: statusCode,
      },
    });
  } else {
    const url = latestResponse.url;

    const filter = yield call(generateFilter, url);
    const jsonData = response.length
      ? yield getResponseData(response)
      : yield call([response, response?.json]);
    yield put({
      type: latestResponse.ok
        ? ActionTypes[actionPrefix + callTypes.SUCCESS]
        : ActionTypes[actionPrefix + callTypes.ERROR],
      payload: {
        filter: filter,
        responseData: jsonData,
        response,
        initAction: action,
        statusCode: statusCode,
      },
    });
  }
}

export function generateApiSaga(
  ConnectorClass,
  genRequestFunc,
  instantiateConnector = stdInstantiateConnector,
) {
  return function* (action) {
    let actionPrefix = action.type.replace(/INIT$/, '');
    let response = null;
    try {
      yield put({
        type: ActionTypes[actionPrefix + callTypes.REQUEST],
        payload: {
          initAction: action,
        },
      });
      let connector = yield call(instantiateConnector, ConnectorClass);
      response = yield call(genRequestFunc, connector, action);
      yield call(handleResponse, response, actionPrefix, action);
    } catch (error) {
      yield call(handleCallException, error, actionPrefix, response, action);
    } finally {
      if (yield cancelled()) {
        yield put({
          type: ActionTypes.API_CALL_CANCELLED,
          payload: { initAction: action },
        });
      }
    }
  };
}

export function generateDataAction(func) {
  return function* (action) {
    let actionPrefix = action.type.replace(/INIT$/, '');

    try {
      yield call(func, action);
    } catch (error) {
      yield put({
        type: ActionTypes[actionPrefix + callTypes.ERROR],
        payload: {
          error,
          initAction: action,
        },
      });
    } finally {
      yield put({
        type: ActionTypes[actionPrefix + callTypes.SUCCESS],
        payload: { initAction: action },
      });
    }
  };
}

export function* getAndAwaitAllPages(apiAction) {
  const initAction = getAllPages(apiAction);
  yield put(initAction);
  return race({
    error: take(
      (action) =>
        endsWith(action.type, 'ERROR') &&
        isEqual(action.payload.initAction, initAction),
    ),
    success: take(
      (action) =>
        action.type === ActionTypes.TXN_ALL_PAGES_COMPLETE &&
        isEqual(action.payload.initAction, initAction),
    ),
  });
}

function* getResponseData(responses) {
  const data = [];
  for (let response of responses) {
    data.push(yield call([response, response?.json || response?.ok]));
  }
  return data;
}
