import { call, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import TheoryItemConnector from '../../../connectors/TheoryItem';
import { generateApiSaga } from '../../../utils/sagas';

var doGetAllTheoryItemsByUri = generateApiSaga(
  TheoryItemConnector,
  function* (connector, action) {
    let nodeFragment = action.payload.uri;
    return yield call([connector, connector.getByUriWithParents], nodeFragment);
  },
);

export default function* getAllBySectionAndType() {
  yield takeEvery(
    ActionTypes.API_THEORY_ITEM_GET_ALL_BY_URI_INIT,
    doGetAllTheoryItemsByUri,
  );
}
