/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { refBind } from '../../../utils/ref-bind';
import { Form, Modal } from 'react-bootstrap';
import { Trans, withTranslation } from 'react-i18next';
import RelationEditor from '../organisms/RelationEditor';
import Button from '../atoms/Button';
import { fontSize } from '../styles/styles';
import Input from '../atoms/Input';
import Filter from './Filter';

const styles = {
  '.body': {
    display: 'flex',
    justifyContent: 'space-between',
    height: '300px',
  },
  '.header': {
    textTransform: 'uppercase',
  },
  '.footer': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  '.editable': {
    height: '100%',
    width: '100%',
    fontSize: fontSize.PARAGRAPH_SMALL,
  },
  '.modal-body': {
    height: '70vh',
    overflow: 'auto',
  },
  '.hidden': {
    height: 0,
    opacity: 0,
    transition: '0.5s',
    '& > *': {
      display: 'none',
    },
  },
  '.shown': {
    transition: '0.5s',
  },
};

const sectionRegex = /^(\d+((\.?\d+)+)?)?$/;
const numbersOnly = /^[0-9]*$/;

class ArticleCreator extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getStateFromProps(this.props);
    refBind(
      this,
      'updateRelations',
      'handleInputChange',
      'acceptClickHandler',
      'renderContentInput',
      'handleArticleTypeChange',
      'handleJurisdictionChange',
      'updateSectionNumber',
      'updateSubSectionNumber',
      'updateTitle',
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.show && !prevProps.show) {
      this.setState(this.getStateFromProps(this.props));
    }
  }

  getStateFromProps(props) {
    const articleTypes = (props.articleTypes || []).map((a) => ({
      label: this.translate(a.name),
      value: a.name,
      ...a,
    }));
    const jurisdictions = Object.values(props.jurisdictions || []).map((j) => ({
      label: this.translate(j.node.alpha2Code),
      value: j.node.id,
    }));
    return {
      jurisdictions,
      jurisdiction: jurisdictions[0],
      articleTypes,
      articleType: articleTypes[0],
      sectionNumber: '',
      subSectionNumber: '',
      content: '',
      title: '',
      links: null,
      validated: false,
      creationId: Math.random(),
    };
  }

  translate(key) {
    return this.props.t(
      `create-article.${(key || '').toLowerCase().split(' ').join('-')}`,
    );
  }

  updateRelations(update) {
    this.setState({ links: { ...this.state.links, ...update } });
  }

  handleInputChange(event) {
    this.setState({ content: event.target.value });
  }

  acceptClickHandler() {
    const {
      content,
      title,
      links,
      jurisdiction,
      articleType,
      sectionNumber,
      subSectionNumber,
    } = this.state;
    const sectionHasError = this.hasError(sectionNumber, true, sectionRegex);
    const subsectionHasError = articleType.hasContent
      ? this.hasError(subSectionNumber, true, numbersOnly) ||
        subSectionNumber <= 0
      : false;
    const titleHasError = articleType.hasTitle ? !title : false;
    const contentHasError = articleType.hasContent
      ? !content || this.hasError(subSectionNumber, true, numbersOnly)
      : false;
    if (
      sectionHasError ||
      titleHasError ||
      contentHasError ||
      subsectionHasError
    ) {
      this.setState({ validated: true });
    } else {
      this.props.onSave({
        content,
        title,
        links,
        jurisdiction,
        articleType,
        sectionNumber,
        subSectionNumber: subSectionNumber || 0,
      });
    }
  }

  handleArticleTypeChange(update) {
    this.setState({ articleType: update });
  }

  handleJurisdictionChange(update) {
    this.setState({ jurisdiction: update });
  }

  updateSectionNumber(event) {
    this.setState({ sectionNumber: event.target.value });
  }

  updateSubSectionNumber(event) {
    this.setState({ subSectionNumber: event.target.value });
  }

  updateTitle(event) {
    this.setState({ title: event.target.value });
  }

  getError(hasError) {
    return (
      <div className="error-message">
        {' '}
        {hasError && <Trans i18nKey="create-article.error" />}
      </div>
    );
  }

  renderInput(key, value, fn, hasError) {
    return (
      <div className="my-3">
        <Form.Label>
          <span className="text-title-case">
            <Trans i18nKey={`create-article.${key}`} />:
          </span>
        </Form.Label>
        <Input type={Input.FORM} value={value} onChange={fn} />
        {this.getError(hasError)}
      </div>
    );
  }

  renderContentInput() {
    const isShown = this.state.articleType && this.state.articleType.hasContent;
    return (
      <div
        className={`mt-3 body d-flex flex-column ${
          isShown ? 'shown' : 'hidden'
        }`}
      >
        <Form.Label>
          <span className="text-title-case">
            <Trans i18nKey={`create-article.content`} />:
          </span>
        </Form.Label>
        <textarea
          className="editable"
          value={this.state.content}
          onChange={this.handleInputChange}
        />
        {this.getError(this.state.validated && !this.state.content)}
      </div>
    );
  }

  hasError(value, required, pattern) {
    const hasInvalidPattern = pattern && !pattern.test(value || '');
    return required ? !value || hasInvalidPattern : value && hasInvalidPattern;
  }

  render() {
    const { show, title, entityType, onCancel } = this.props;
    const {
      articleType,
      articleTypes,
      jurisdiction,
      jurisdictions,
      sectionNumber,
      subSectionNumber,
      creationId,
      validated,
    } = this.state;
    const sectionHasError =
      validated && this.hasError(sectionNumber, true, sectionRegex);
    const subSectionHasError =
      validated &&
      (this.hasError(subSectionNumber, true, numbersOnly) ||
        subSectionNumber <= 0);
    return (
      <Modal show={show} css={styles} size="xl">
        <Modal.Header>
          <Modal.Title>
            <div className="header">
              <Trans i18nKey={title} />
            </div>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className={'scroll-bar'}>
          {show && (
            <div>
              <div className="my-3">
                <Form.Label>
                  <span className="text-title-case">
                    <Trans i18nKey={`create-article.jurisdiction`} />:
                  </span>
                </Form.Label>
                <Filter
                  options={jurisdictions}
                  value={jurisdiction}
                  onChange={this.handleJurisdictionChange}
                />
              </div>
              <div className="my-3">
                <Form.Label>
                  <span className="text-title-case">
                    <Trans i18nKey={`create-article.article-type`} />:
                  </span>
                </Form.Label>
                <Filter
                  options={articleTypes}
                  value={articleType}
                  onChange={this.handleArticleTypeChange}
                />
              </div>

              {this.renderInput(
                'section-number',
                sectionNumber,
                this.updateSectionNumber,
                sectionHasError,
              )}

              <div
                className={
                  articleType && articleType.hasContent ? 'shown' : 'hidden'
                }
              >
                {this.renderInput(
                  'sub-section-number',
                  subSectionNumber,
                  this.updateSubSectionNumber,
                  subSectionHasError,
                )}
              </div>
              <div
                className={
                  articleType && articleType.hasTitle ? 'shown' : 'hidden'
                }
              >
                {this.renderInput(
                  'title',
                  this.state.title,
                  this.updateTitle,
                  validated && !this.state.title,
                )}
              </div>
              {this.renderContentInput()}
              <div className="my-3">
                <RelationEditor
                  entityType={entityType}
                  entityId={creationId}
                  onChange={this.updateRelations}
                />
              </div>
            </div>
          )}
        </Modal.Body>

        <Modal.Footer className={'footer'}>
          <Button type={Button.PRIMARY_DARK} onClick={onCancel}>
            <Trans i18nKey="create-article.back" />
          </Button>
          <Button type={Button.PRIMARY_BRAND} onClick={this.acceptClickHandler}>
            <Trans i18nKey="create-article.save" />
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default withTranslation()(ArticleCreator);
