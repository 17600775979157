import { callAndAwaitApi } from '../../../utils/sagas';
import * as ActionTypes from '../../../constants/ActionTypes';
import { put, takeEvery } from 'redux-saga/effects';
import * as EntityTypes from '../../../constants/EntityTypes';
import * as theoryItemApiActions from '../../../actions/api/theoryitem';
import { buildPageLink } from '../../../utils/link';
import * as Urls from '../../../constants/Urls';
import { push } from 'connected-react-router/immutable';

const deleteActions = {
  [EntityTypes.THEORYITEM]: theoryItemApiActions.deleteById,
};

const redirectActions = {
  [EntityTypes.THEORYITEM]: () => buildPageLink(Urls.THEORY_ROUTE),
};

function* doDelete(action) {
  const node = action.payload.node;
  const op = deleteActions[node.entityType];
  if (op) {
    const res = yield callAndAwaitApi(op(node.id));
    if (res.success) {
      yield put(push(redirectActions[node.entityType]()));
      action.payload.handler && action.payload.handler();
    }
  }
}

export default function* deleteNode() {
  yield takeEvery(ActionTypes.APP_PAGE_ADMIN_DELETE_NODE, doDelete);
}
