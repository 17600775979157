import * as ActionTypes from '../../constants/ActionTypes';

export const lawItems = (lawItems) => ({
  type: ActionTypes.API_GET_RELATION_TREE_LAW_ITEMS,
  payload: lawItems,
});
export const lawPrefaceItems = (lawPrefaceItems) => ({
  type: ActionTypes.API_GET_RELATION_TREE_LAW_PREFACE_ITEMS,
  payload: lawPrefaceItems,
});
export const decisions = (decisions) => ({
  type: ActionTypes.API_GET_RELATION_TREE_DECISIONS,
  payload: decisions,
});
export const processSteps = (processSteps) => ({
  type: ActionTypes.API_GET_RELATION_TREE_PROCESS_STEPS,
  payload: processSteps,
});
export const theoryItems = (theoryItems) => ({
  type: ActionTypes.API_GET_RELATION_TREE_THEORY_ITEMS,
  payload: theoryItems,
});
export const taxonomy = (taxonomy) => ({
  type: ActionTypes.API_GET_RELATION_TREE_TAXONOMY,
  payload: taxonomy,
});
