import { Map } from 'immutable';
import moment from 'moment';
import { DateFormat } from '../../constants/DateFormat';
import { CREATE_DECISION_MODE } from '../../constants/CreateDecisionMode';
import { sort } from '../../utils/sort';
import { COURT_OPTION_SORT_INDEX_BASED } from '../../constants/Sort';

const isNumber = (value) =>
  !!(
    value !== '' &&
    value !== null &&
    !isNaN(Number(value)) &&
    (Number(value) || Number(value) === 0)
  );

/**
 * if input string value is a number in Danish numeric format, it will parse it into a number.
 * @param {String} value
 * @returns {Number} a number or `null` when the provided string cannot be parsed as a Danish number.
 */
export const getRawNumber = (value) => {
  const rawNumberString = value?.replaceAll('.', '').replace(',', '.');
  return isNumber(rawNumberString) ? Number(rawNumberString) : null;
};

export const getFormattedMoney = (price) =>
  isNumber(price)
    ? new Intl.NumberFormat('da-DK', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(price)
    : '';

export const getLocalizationKeys = (mode) => {
  return mode === CREATE_DECISION_MODE.NEW
    ? {
        title: 'add-decision.create-decision',
        submitBtn: 'add-decision.add-button',
      }
    : {
        title: 'add-decision.update-decision',
        submitBtn: 'add-decision.update-button',
      };
};

export const mapCourtsToSelectOptions = (courts) =>
  sort(courts, COURT_OPTION_SORT_INDEX_BASED).map((c) =>
    getOption(
      `(${getFromNode(c, 'shortName', '')}) ${getFromNode(c, 'name', '')}`,
      getFromNode(c, 'id', ''),
    ),
  );
export const mapCaseTypesToOptions = (types) =>
  types.map((el) => getOption(el.get('label'), el.get('code')));
export const mapItemsToOptions = (items) =>
  items.map((el, index) => getOption(el, index));
export const getOption = (label, value) => Map({ label, value });
export const getFromNode = (node, param, defaultValue) =>
  node ? node.getIn(['node', param], defaultValue) : defaultValue;

export const getDecisionState = (decision) => {
  return {
    id: decision.id || '',
    caseName: decision.caseName || '',
    decisionCaseType: decision.decisionCaseType && {
      value: decision.decisionCaseType,
    },
    decisionType: decision.decisionType && { value: decision.decisionType },
    initiatedDate:
      (decision.initiatedDate &&
        moment(decision.initiatedDate, DateFormat.requestDate).toDate()) ||
      '',
    rulingDate:
      (decision.rulingDate &&
        moment(decision.rulingDate, DateFormat.requestDate).toDate()) ||
      '',
    judges: decision.judges || '',
    defendant: decision.defendant || '',
    defendantRepresentative: decision.defendantRepresentative || '',
    plaintiff: decision.plaintiff || '',
    plaintiffRepresentative: decision.plaintiffRepresentative || '',
    court: decision.court && { value: decision.court },
    procurementType: decision.procurementType || '',
    keywords: idOnlyMap(decision.keywords),
    precedingDecisions: idOnlyMap(decision.precedingDecisions),
    relatedDecisions: idOnlyMap(decision.relatedDecisions),
    relatedLawItems: idOnlyMap(decision.relatedLawItems),
    relatedTheoryItems: idOnlyMap(decision.relatedTheoryItems),
    initKeywords: decision.keywords || [],
    initPrecedingDecisions: decision.precedingDecisions || [],
    initRelatedDecisions: decision.relatedDecisions || [],
    initRelatedLawItems: decision.relatedLawItems || [],
    initRelatedTheoryItems: decision.relatedTheoryItems || [],
    datepickerKey: decision.id || Math.random(),
    validated: true,
    caseType: decision.caseType && { value: decision.caseType },
    meritType: decision.meritType && { value: decision.meritType },
    reactionType: decision.reactionType && { value: decision.reactionType },
    formalityType: decision.formalityType && { value: decision.formalityType },
    reactionFormality: decision.reactionFormality && {
      value: decision.reactionFormality,
    },
    suspensiveEffect: decision.suspensiveEffect && {
      value: decision.suspensiveEffect,
    },
    procurementForm: decision.procurementForm && {
      value: decision.procurementForm,
    },
    procurementFormProcedure: decision.procurementFormProcedure && {
      value: decision.procurementFormProcedure,
    },
    procurementFormAddon: decision.procurementFormAddon && {
      value: decision.procurementFormAddon,
    },
    posOpfIntClaim: decision.posOpfIntClaim,
    posOpfIntClaimCaption: getFormattedMoney(decision?.posOpfIntClaim),
    posOpfIntAwarded: decision.posOpfIntAwarded,
    posOpfIntAwardedCaption: getFormattedMoney(decision?.posOpfIntAwarded),
    negKonsIntClaim: decision.negKonsIntClaim,
    negKonsIntClaimCaption: getFormattedMoney(decision?.negKonsIntClaim),
    negKonsIntAwarded: decision.negKonsIntAwarded,
    negKonsIntAwardedCaption: getFormattedMoney(decision?.negKonsIntAwarded),
    caseCost: decision.caseCost,
    caseCostCaption: getFormattedMoney(decision?.caseCost),
    reactionFormalityAmount: decision.reactionFormalityAmount,
    reactionFormalityAmountCaption: getFormattedMoney(
      decision?.reactionFormalityAmount,
    ),
    editorName: decision.editorName || '',
    ufrReference: decision.ufrReference || '',
    shortName: decision.shortName || '',
    caseNumber: decision.caseNumber || '',
    file: null,
  };
};

export const getEmptyState = () => {
  return {
    id: null,
    caseName: '',
    decisionCaseType: '',
    decisionType: '',
    initiatedDate: '',
    rulingDate: '',
    judges: '',
    defendant: '',
    defendantRepresentative: '',
    plaintiff: '',
    plaintiffRepresentative: '',
    court: '',
    procurementType: '',
    keywords: [],
    precedingDecisions: [],
    relatedDecisions: [],
    relatedLawItems: [],
    relatedTheoryItems: [],
    initKeywords: [],
    initPrecedingDecisions: [],
    initRelatedDecisions: [],
    initRelatedLawItems: [],
    initRelatedTheoryItems: [],
    datepickerKey: Math.random(),
    validated: false,
    caseType: '',
    meritType: '',
    reactionType: '',
    formalityType: '',
    reactionFormality: '',
    suspensiveEffect: '',
    procurementForm: '',
    procurementFormProcedure: '',
    procurementFormAddon: '',
    posOpfIntClaim: '',
    posOpfIntAwarded: '',
    negKonsIntClaim: '',
    negKonsIntAwarded: '',
    caseCost: '',
    reactionFormalityAmount: '',
    editorName: '',
    ufrReference: '',
    shortName: '',
    caseCostCaption: '',
    reactionFormalityAmountCaption: '',
    posOpfIntClaimCaption: '',
    posOpfIntAwardedCaption: '',
    negKonsIntClaimCaption: '',
    negKonsIntAwardedCaption: '',
    caseNumber: '',
    file: null,
    isNewsworthy: false,
  };
};

export const mapDecisionStateToDecision = (state) => {
  return {
    id: state.id,
    court: state.court,
    precedingDecisions: state.precedingDecisions,
    relatedDecisions: state.relatedDecisions,
    keywords: state.keywords,
    relatedLawItems: state.relatedLawItems,
    relatedTheoryItems: state.relatedTheoryItems,
    initiatedDate: state.initiatedDate,
    rulingDate: state.rulingDate,
    decisionCaseType: state.decisionCaseType,
    judges: state.judges,
    plaintiff: state.plaintiff,
    plaintiffRepresentative: state.plaintiffRepresentative,
    defendant: state.defendant,
    defendantRepresentative: state.defendantRepresentative,
    procurementType: state.procurementType,
    caseName: state.caseName,
    caseType: state.caseType?.value,
    decisionType: state.decisionType,
    meritType: state.meritType?.value,
    reactionType: state.reactionType?.value,
    formalityType: state.formalityType?.value,
    reactionFormality: state.reactionFormality?.value,
    suspensiveEffect: state.suspensiveEffect?.value,
    procurementForm: state.procurementForm?.value,
    procurementFormProcedure: state.procurementFormProcedure?.value,
    procurementFormAddon: state.procurementFormAddon?.value,
    posOpfIntClaim: state.posOpfIntClaim,
    posOpfIntAwarded: state.posOpfIntAwarded,
    negKonsIntClaim: state.negKonsIntClaim,
    negKonsIntAwarded: state.negKonsIntAwarded,
    caseCost: state.caseCost,
    reactionFormalityAmount: state.reactionFormalityAmount,
    editorName: state.editorName,
    ufrReference: state.ufrReference,
    shortName: state.shortName,
    caseNumber: state.caseNumber,
  };
};

const idOnlyMap = (items) => (items || []).map((i) => `/${i.id}`);
