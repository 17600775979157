/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';
import wavesImagePath from '../../images/wave.svg';
import { bindActionCreators } from 'redux';
import Text, { TextType } from '../../components/common/atoms/Text';
import { fontColors } from '../../components/common/styles/styles';
import { Trans } from 'react-i18next';
import * as unsubscribeActions from '../../actions/api/unsubscribe';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import { refBind } from '../../utils/ref-bind';
import * as unsubscribeSelectors from '../../selectors/page/unsubscribe';
import { createStructuredSelector } from 'reselect';
import * as ConsentType from '../../constants/ConsentTypes';

const styles = {
  '& .page-unsubscribe': {
    background: 'url(' + wavesImagePath + ') #26203E',
    backgroundSize: 'cover',
    height: '100vh',
    color: fontColors.WHITE,
    paddingTop: '200px',
    '& .left-side .unsubscribe-box': {
      padding: '3em',
      paddingTop: '2em',
      paddingBottom: '1em',
      backgroundColor: fontColors.WHITE,
      color: fontColors.BLACK,
    },
  },
  '& .flex-row': {
    alignItems: 'center',
    justifyContent: 'center',
  },
  '& .errorText': {
    color: fontColors.RED,
  },
  '& .unsubscribe-text': {
    textDecoration: 'underline',
  },
};

class Unsubscribe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: new URLSearchParams(window.location.search).get('token'),
    };
    refBind(this, 'unsubscribeAllNotifications');
  }

  componentDidMount() {
    const { actions } = this.props;
    const { token } = this.state;
    actions.unsubscribeSingleNotification(token);
  }

  unsubscribeAllNotifications() {
    const { actions } = this.props;
    const { token } = this.state;
    actions.unsubscribeAllNotifications(token);
  }

  getTitle(unsubscribedNotificationType) {
    switch (unsubscribedNotificationType) {
      case ConsentType.NEWS_CONSENT:
        return 'subscriptions.newsletter-tooltip';
      case ConsentType.DAILY_FAVORITE_CONSENT:
        return 'subscriptions.favorites-tooltip';
      case ConsentType.DAILY_ALL_CONSENT:
        return 'subscriptions.all-tooltip';
      case ConsentType.INSTANT_CONSENT:
        return 'subscriptions.instant-mail-tooltip';
      default:
        return '';
    }
  }

  render() {
    const { unsubscribedNotificationType } = this.props;
    const title =
      unsubscribedNotificationType === ConsentType.NONE ? (
        <Trans i18nKey={'unsubscribe.title.none'} />
      ) : (
        <>
          <Trans i18nKey={this.getTitle(unsubscribedNotificationType)} />{' '}
          <Trans i18nKey={'unsubscribe.title.all'} />
        </>
      );
    return (
      <div css={styles}>
        <div className="page-unsubscribe">
          <Container>
            <Row className="flex-row">
              <Col className="left-side" xs={5}>
                <div className="unsubscribe-box border rounded">
                  <Text as="p" type={TextType.HEADER_1_SMALL}>
                    {title}
                  </Text>
                  <Text>
                    <Trans i18nKey="unsubscribe.button-text-first" />
                  </Text>
                  <a
                    onClick={this.unsubscribeAllNotifications}
                    className="unsubscribe-text"
                  >
                    <Trans i18nKey="unsubscribe.button-text-second" />
                  </a>
                  <hr className="mt-4 mb-4" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  unsubscribedNotificationType:
    unsubscribeSelectors.getUnsubscribedNotificationType,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      unsubscribeSingleNotification:
        unsubscribeActions.unsubscribeTypeOfNotification,
      unsubscribeAllNotifications:
        unsubscribeActions.unsubscribeAllNotifications,
    },
    dispatch,
  ),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withImmutablePropsToJS,
)(Unsubscribe);
