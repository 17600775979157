import { isArray } from 'lodash';
import { createSelector } from 'reselect';
import { PERMISSIONS } from '../../constants/Permissions';

export const getAllPermissions = (state) =>
  state.getIn(['app', 'auth', 'privileges']);

export const hasAnyPermissions = (state, permissions, isNot) =>
  Boolean(
    Boolean(
      getAllPermissions(state).some((assignedPermission) =>
        isArray(permissions)
          ? permissions.find((permission) => permission === assignedPermission)
          : permissions === assignedPermission,
      ),
    ) ^ Boolean(isNot),
  );

export const hasEveryPermissions = (state, permissions) =>
  permissions.every((p) => hasAnyPermissions(state, p));

export const checkAdvancedSearchPermission = createSelector(
  getAllPermissions,
  (permissions) => {
    return permissions.includes(PERMISSIONS.SEARCH_ADVANCED_FILTERS);
  },
);

export const checkToggleJurisdictionPermission = createSelector(
  getAllPermissions,
  (permissions) => {
    return permissions.includes(PERMISSIONS.TOGGLE_JURISDICTION);
  },
);

export const checkRedThreadPermission = createSelector(
  getAllPermissions,
  (permissions) => {
    return (
      permissions.includes(PERMISSIONS.LAW_FOOTER_LAW_RED_THREAD) ||
      permissions.includes(PERMISSIONS.DECISION_FOOTER_LAW_RED_THREAD)
    );
  },
);

export const checkGeneralSearchFilterPermission = createSelector(
  getAllPermissions,
  (permissions) => {
    return permissions.includes(PERMISSIONS.SEARCH_GENERAL_FILTERS);
  },
);
