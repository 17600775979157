import * as ActionTypes from '../constants/ActionTypes';
import * as LoginTypes from '../constants/LoginTypes';

export const loginVerified = (username, loginType, bearerToken) => ({
  type: ActionTypes.AUTH_LOGIN_VERIFIED,
  payload: {
    username,
    loginType,
    bearerToken,
  },
});

export const loginRefreshed = (bearerToken) => ({
  type: ActionTypes.AUTH_LOGIN_REFRESHED,
  payload: {
    bearerToken,
  },
});
export const initAuth = () => ({
  type: ActionTypes.AUTH_INIT,
});

export const authCompleted = () => ({
  type: ActionTypes.AUTH_COMPLETED,
});

export const login = (username, password, loginType) => ({
  type: ActionTypes.AUTH_LOGIN_INIT,
  payload: {
    username,
    password,
    loginType,
  },
});

export const jwtLogin = (username, password) => ({
  type: ActionTypes.AUTH_LOGIN_INIT,
  payload: {
    username,
    password,
    loginType: LoginTypes.JWT_LOGIN,
  },
});

export const devLogin = (username) => ({
  type: ActionTypes.AUTH_LOGIN_INIT,
  payload: {
    username,
    loginType: LoginTypes.DEVELOPER_LOGIN,
  },
});

export const loginComplete = () => ({
  type: ActionTypes.AUTH_LOGIN_COMPLETE,
});

export const logout = (backgroundLogout = false) => ({
  type: ActionTypes.AUTH_LOGOUT_INIT,
  payload: {
    backgroundLogout,
  },
});

export const logoutComplete = () => ({
  type: ActionTypes.AUTH_LOGOUT_COMPLETE,
});

export const clearLogin = () => ({
  type: ActionTypes.AUTH_CLEAR_LOGIN,
});

export const loginFailed = (message) => ({
  type: ActionTypes.AUTH_LOGIN_FAILED,
  payload: { message },
});

export const initJwtLogin = (username, password) => ({
  type: ActionTypes.AUTH_JWT_CALL_INIT,
  payload: { username, password },
});

export const refreshJwtLogin = () => ({
  type: ActionTypes.AUTH_JWT_REFRESH_INIT,
});

export const resetPassword = (username) => ({
  type: ActionTypes.AUTH_RESET_PASSWORD_INIT,
  payload: {
    username,
  },
});

export const setPassword = (accessToken, newPassword) => ({
  type: ActionTypes.AUTH_SET_PASSWORD_INIT,
  payload: {
    accessToken,
    newPassword,
  },
});

export const setError = (error) => ({
  type: ActionTypes.AUTH_SET_ERROR,
  error,
});
