import * as ActionTypes from '../../../constants/ActionTypes';
import { generateApiSaga } from '../../../utils/sagas';
import { call, takeEvery } from 'redux-saga/effects';
import RoleConnector from '../../../connectors/Role';

const doGetAllCompany = generateApiSaga(RoleConnector, function* (connector) {
  return yield call([connector, connector.getAll]);
});

export default function* getAll() {
  yield takeEvery(ActionTypes.API_GET_ALL_ROLES_INIT, doGetAllCompany);
}
