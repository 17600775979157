import * as ActionTypes from '../../../constants/ActionTypes';
import { takeEveryWhenAppReady } from '../../../utils/sagas';
import { ADMIN_HOME_TABS } from '../../../constants/AdminHomeTabs';
import * as companyActions from '../../../actions/api/company';
import * as licenseActions from '../../../actions/api/license';
import * as permissionActions from '../../../actions/api/permission';
import * as roleActions from '../../../actions/api/role';
import * as userActions from '../../../actions/api/appuser';
import * as userSelectors from '../../../selectors/api/appuser';
import * as companySelectors from '../../../selectors/api/company';
import * as permissionSelectors from '../../../selectors/api/permissions';
import * as roleSelectors from '../../../selectors/api/role';
import * as licenseSelectors from '../../../selectors/api/license';
import * as adminHomePageActions from '../../../actions/page/adminhome';
import { all, put, race, select, take } from 'redux-saga/effects';
import * as authSelectors from '../../../selectors/store/auth';

function* doAdminHomePageLoad(action) {
  let [uri] = action.payload.uri;
  uri = uri || ADMIN_HOME_TABS.LICENSE;

  switch (uri) {
    case ADMIN_HOME_TABS.LICENSE:
      yield doRolesLoad();
      yield doCompaniesLoad();
      break;
    case ADMIN_HOME_TABS.ROLES:
      yield doRolesLoad();
      yield doPermissionsLoad();
      break;
    case ADMIN_HOME_TABS.USERS:
      yield doUserDetailsLoad();
      break;
    default:
      break;
  }
}

function* doCompaniesLoad() {
  const companies = yield select(companySelectors.getAll);
  if (!(companies && companies.size)) {
    yield put(companyActions.getAll());
  }
}

function* doRolesLoad() {
  const roles = yield select(roleSelectors.getAll);
  if (!(roles && roles.size)) {
    yield put(roleActions.getAll());
  }
}

function* doPermissionsLoad() {
  const permissions = yield select(permissionSelectors.getAll);
  if (!(permissions && permissions.size)) {
    yield put(permissionActions.getPermissions());
  }
}

function* doUserDetailsLoad() {
  yield put(adminHomePageActions.loadUserDetails());

  const users = yield select(userSelectors.getAll);
  const companies = yield select(companySelectors.getAll);
  const licenses = yield select(licenseSelectors.getAll);
  const roles = yield select(roleSelectors.getAll);

  const currentUserId = yield select(authSelectors.getUserId);
  const currentUser = currentUserId && users.get(Number(currentUserId));

  // logic assumes that data needs to be loaded if it is only present for current user
  let actions = [];
  let actionTypes = [];

  if (!(roles && roles.size)) {
    actions.push(roleActions.getAll());
    actionTypes.push({
      error: ActionTypes.API_GET_ALL_ROLES_ERROR,
      success: ActionTypes.API_GET_ALL_ROLES_SUCCESS,
    });
  }

  if (
    !(
      users &&
      users.filterNot((x) => x.getIn(['node', 'id']) === currentUserId).size > 1
    )
  ) {
    actions.push(userActions.getAll());
    actionTypes.push({
      error: ActionTypes.API_USER_GET_ALL_ERROR,
      success: ActionTypes.API_USER_GET_ALL_SUCCESS,
    });
  }

  if (
    !(
      companies &&
      companies.filterNot(
        (x) =>
          x.getIn(['node', 'id']) ===
          currentUser?.getIn(['node', 'company', 'id']),
      ).size > 1
    )
  ) {
    actions.push(companyActions.getAll());
    actionTypes.push({
      error: ActionTypes.API_GET_ALL_COMPANIES_ERROR,
      success: ActionTypes.API_GET_ALL_COMPANIES_SUCCESS,
    });
  }

  if (
    !(
      licenses &&
      licenses.filterNot(
        (x) => x.getIn(['node', 'user', 'id']) === currentUserId,
      ).size > 1
    )
  ) {
    actions.push(licenseActions.getAll());
    actionTypes.push({
      error: ActionTypes.API_LICENSE_GET_ALL_ERROR,
      success: ActionTypes.API_LICENSE_GET_ALL_SUCCESS,
    });
  }

  yield all(actions.map((action) => put(action)));
  const { error } = yield race({
    error: take(actionTypes.map((a) => a.error)),
    success: all(actionTypes.map((a) => take(a.success))),
  });

  if (error) {
    yield put(adminHomePageActions.loadUserDetailsError());
  } else {
    yield put(adminHomePageActions.loadUserDetailsSuccess());
  }
}

export default function* adminHomePageLoad() {
  yield takeEveryWhenAppReady(
    ActionTypes.APP_PAGE_ADMIN_HOME_LOAD,
    doAdminHomePageLoad,
  );
}
