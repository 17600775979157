import { put, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import * as processStepActions from '../../../actions/api/processstep';
import { getAllPages } from '../../../actions/api';

function* doGetAllByProcess(action) {
  const node = action.payload.node;
  const initAction = processStepActions.getAllByProcess(node);
  yield put(getAllPages(initAction));
}

export default function* getAllByProcess() {
  yield takeEvery(
    ActionTypes.API_PROCESSSTEP_GET_ALL_BY_PROCESS_TXN,
    doGetAllByProcess,
  );
}
