import { fromJS } from 'immutable';
import * as ActionTypes from '../../constants/ActionTypes';
import { mergeResponseToState } from './index';
import * as EntityTypes from '../../constants/EntityTypes';
import { getInitAction, getResponseData } from '../../selectors/actions';

const initialState = fromJS({
  data: {},
});

export default function licenseApiReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.API_LICENSE_GET_ALL_SUCCESS:
    case ActionTypes.API_LICENSE_GET_AVAILABLE_SUCCESS:
    case ActionTypes.API_LICENSE_CREATE_SUCCESS:
    case ActionTypes.API_LICENSE_UPDATE_SUCCESS:
      return mergeResponseToState(state, action, EntityTypes.LICENSE);
    case ActionTypes.API_CLAIM_LICENSE_SUCCESS:
      return state.setIn(
        ['data', action.payload.responseData.id, 'node', 'user'],
        'me',
      );
    case ActionTypes.API_LICENSE_DELETE_SUCCESS:
      const removedLicenseIds = getInitAction(action).payload.licenses.map(
        (l) => l.id,
      );
      const newData = state
        .getIn(['data'])
        .filterNot((x) => removedLicenseIds.includes(x.getIn(['node', 'id'])));
      return state.setIn(['data'], newData);
    default:
      return state;
  }
}
