import * as EntityTypes from '../constants/EntityTypes';
import { isImmutable } from 'immutable';
import * as lawItemApiActions from '../actions/api/lawitem';
import * as lawPrefaceItemApiActions from '../actions/api/lawprefaceitem';
import * as theoryItemApiActions from '../actions/api/theoryitem';
import * as processStepApiActions from '../actions/api/processstep';
import * as decisionApiActions from '../actions/api/decision';
import * as taxonomyApiActions from '../actions/api/taxonomy';
import EntityFields from '../constants/EntityFields';

// region ArticleEditor

export const getRelationFromNode = (node, field) => {
  if (node) {
    const getter = isImmutable(node) ? immutableGetter : jsGetter;
    const defaultValue = getter(node, field);
    const entityType = getter(node, 'entityType');
    const newField =
      EntityRenameLinksMap[entityType] &&
      EntityRenameLinksMap[entityType][field];
    return (newField ? getter(node, newField) : defaultValue) || [];
  }
  return [];
};

const immutableGetter = (node, field) => node.get(field);
const jsGetter = (node, field) => node[field];

export const EntityRenameLinksMap = {
  [EntityTypes.LAWITEM]: {
    relatedLawItems: 'replacedBy',
  },
};

export const getPropsToEdit = (node) => {
  return node.content ? 'content' : 'title';
};

// endregion ArticleEditor

// region ArticleFooter
export const dataActionMap = {
  [EntityTypes.LAWITEM]: lawItemApiActions.getRelationTreeById,
  [EntityTypes.LAWPREFACEITEM]: lawPrefaceItemApiActions.getRelationTreeById,
  [EntityTypes.THEORYITEM]: theoryItemApiActions.getRelationTreeById,
  [EntityTypes.PROCESSSTEP]: processStepApiActions.getRelationTreeById,
  [EntityTypes.DECISION]: decisionApiActions.getRelationTreeById,
  [EntityTypes.TAXONOMY]: taxonomyApiActions.getRelationTreeById,
};

export const RelationshipKeys = {
  lawitem: 'relatedLawItems',
  theoryitem: 'relatedTheoryItems',
  decision: 'relatedDecisions',
  processstep: 'relatedProcessSteps',
  taxonomy: 'relatedTaxonomy',
};

export const getEntityType = (item) => {
  if (item.hasOwnProperty('entityType')) return item.entityType;
  if (item.hasOwnProperty('lawItemType') || item.hasOwnProperty('lawId'))
    return EntityTypes.LAWITEM;
  if (item.hasOwnProperty('caseType')) return EntityTypes.DECISION;
  if (item.hasOwnProperty('theoryId')) return EntityTypes.THEORYITEM;
};

export const RED_THREAD = 'redThread';

// region LawItem

const addRedThreadLawItems = (
  redThreadLawItems,
  relationType,
  combinedList,
  level,
  relatedItemId,
  lawId,
) => {
  redThreadLawItems.forEach((lawItem) => {
    if (lawItem[EntityFields[EntityTypes.LAWITEM].REPLACED_BY]) {
      addRedThreadLawItems(
        lawItem[EntityFields[EntityTypes.LAWITEM].REPLACED_BY],
        RED_THREAD,
        combinedList,
        lawItem.level,
        lawItem.id,
        lawItem.lawId,
      );
    }
    if (lawItem[EntityFields[EntityTypes.LAWITEM].REPLACING]) {
      addRedThreadLawItems(
        lawItem[EntityFields[EntityTypes.LAWITEM].REPLACING],
        RED_THREAD,
        combinedList,
        lawItem.level,
        lawItem.id,
        lawItem.lawId,
      );
    }
    addRedThreadDecisions(lawItem.h5Item, combinedList, level);
    combinedList[EntityTypes.LAWITEM][relationType][lawItem.id] = prepareEntity(
      lawItem,
      level,
      relatedItemId,
      EntityTypes.LAWITEM,
      lawId,
    );
    if (relationType !== RED_THREAD) {
      combinedList[EntityTypes.LAWITEM][RED_THREAD][lawItem.id] = prepareEntity(
        lawItem,
        level,
        relatedItemId,
        EntityTypes.LAWITEM,
        lawId,
      );
    }
  });
};

const addRedThreadDecisions = (item, combinedList, level) => {
  if (item?.relatedDecisions) {
    item.relatedDecisions.forEach((decision) => {
      combinedList[EntityTypes.DECISION][RED_THREAD][decision.id] =
        prepareEntity(
          decision,
          level,
          item.id,
          getEntityType(item),
          item.lawId ?? item.title,
        );
    });
  }
  if (item?.childLawItems) {
    item.childLawItems.forEach((childLawItem) => {
      addRedThreadDecisions(childLawItem, combinedList, level + 1);
      combinedList[EntityTypes.LAWITEM][RED_THREAD][childLawItem.id] =
        prepareEntity(
          childLawItem,
          level,
          item.id,
          EntityTypes.LAWITEM,
          item.lawId,
        );
    });
  }
};

export const addLawItem = (lawItems, combinedList, level) => {
  (lawItems || []).forEach((lawItem) => {
    // We are adding an extra field to the related laws such that all types of relationships can exist without overwriting each other
    if (lawItem[EntityFields[EntityTypes.LAWITEM].CHANGED_BY]) {
      lawItem[EntityFields[EntityTypes.LAWITEM].CHANGED_BY].forEach(
        (i) =>
          (combinedList[EntityTypes.LAWITEM][
            EntityFields[EntityTypes.LAWITEM].CHANGED_BY
          ][i.id] = prepareEntity(
            i,
            level,
            lawItem.id,
            getEntityType(lawItem),
            lawItem.lawId,
          )),
      );
    }
    if (lawItem[EntityFields[EntityTypes.LAWITEM].CHANGING]) {
      lawItem[EntityFields[EntityTypes.LAWITEM].CHANGING].forEach(
        (i) =>
          (combinedList[EntityTypes.LAWITEM][
            EntityFields[EntityTypes.LAWITEM].CHANGING
          ][i.id] = prepareEntity(
            i,
            level,
            lawItem.id,
            getEntityType(lawItem),
            lawItem.lawId,
          )),
      );
    }
    if (lawItem[EntityFields[EntityTypes.LAWITEM].REPLACED_BY]) {
      addRedThreadLawItems(
        lawItem[EntityFields[EntityTypes.LAWITEM].REPLACED_BY],
        EntityFields[EntityTypes.LAWITEM].REPLACED_BY,
        combinedList,
        lawItem.level,
        lawItem.id,
        lawItem.lawId,
      );
    }
    if (lawItem[EntityFields[EntityTypes.LAWITEM].REPLACING]) {
      addRedThreadLawItems(
        lawItem[EntityFields[EntityTypes.LAWITEM].REPLACING],
        EntityFields[EntityTypes.LAWITEM].REPLACING,
        combinedList,
        lawItem.level,
        lawItem.id,
        lawItem.lawId,
      );
    }
    addRedThreadDecisions(lawItem.h5Item, combinedList, lawItem.level);
    if (lawItem[EntityFields[EntityTypes.LAWITEM].JURISDICTION_BY]) {
      lawItem[EntityFields[EntityTypes.LAWITEM].JURISDICTION_BY].forEach(
        (i) =>
          (combinedList[EntityTypes.LAWITEM][
            EntityFields[EntityTypes.LAWITEM].JURISDICTION_BY
          ][i.id] = prepareEntity(
            i,
            level,
            lawItem.id,
            getEntityType(lawItem),
            lawItem.lawId,
          )),
      );
    }
    if (lawItem[EntityFields[EntityTypes.LAWITEM].JURISDICTION]) {
      lawItem[EntityFields[EntityTypes.LAWITEM].JURISDICTION].forEach(
        (i) =>
          (combinedList[EntityTypes.LAWITEM][
            EntityFields[EntityTypes.LAWITEM].JURISDICTION
          ][i.id] = prepareEntity(
            i,
            level,
            lawItem.id,
            getEntityType(lawItem),
            lawItem.lawId,
          )),
      );
    }
    if (lawItem[EntityFields[EntityTypes.LAWITEM].RELATED_LAW_PREFACE_ITEMS]) {
      lawItem[
        EntityFields[EntityTypes.LAWITEM].RELATED_LAW_PREFACE_ITEMS
      ].forEach(
        (i) =>
          (combinedList[EntityTypes.LAWPREFACEITEM][i.id] = prepareEntity(
            i,
            level,
            lawItem.id,
            getEntityType(lawItem),
            lawItem.lawId,
          )),
      );
    }

    if (lawItem.childLawItems) {
      addLawItem(lawItem.childLawItems, combinedList, level + 1);
    }
    if (lawItem?.relatedDecisions) {
      lawItem.relatedDecisions.forEach(
        (i) =>
          (combinedList[EntityTypes.DECISION][i.id] = prepareEntity(
            i,
            level,
            lawItem.id,
            getEntityType(lawItem),
            lawItem.lawId ?? lawItem.title,
            lawItem.title,
          )),
      );
    }
    if (lawItem.keywords) {
      lawItem.keywords.forEach(
        (i) =>
          (combinedList[EntityTypes.TAXONOMY][i.id] = prepareEntity(i, level)),
      );
    }
    if (lawItem.relatedProcessSteps) {
      lawItem.relatedProcessSteps.forEach(
        (i) =>
          (combinedList[EntityTypes.PROCESSSTEP][i.id] = prepareEntity(
            i,
            level,
            null,
            getEntityType(lawItem),
          )),
      );
    }

    if (lawItem.relatedTheoryItems) {
      lawItem.relatedTheoryItems.forEach(
        (theoryItem) =>
          (combinedList[EntityTypes.THEORYITEM][theoryItem.id] = prepareEntity(
            theoryItem,
            level,
          )),
      );
    }
    if (level === 0) {
      combinedList[EntityTypes.LAWITEM][RED_THREAD][lawItem.id] = prepareEntity(
        lawItem,
        level,
      );
    }
  });
};
// endregion LawItem

// region LawPrefaceItem

export const addLawPrefaceItem = (lawPrefaceItems, combinedList, level) => {
  (lawPrefaceItems || []).forEach((lawPrefaceItem) => {
    if (lawPrefaceItem.relatedLawItems) {
      lawPrefaceItem.relatedLawItems.forEach(
        (lawItem) =>
          (combinedList[EntityTypes.LAWITEM][lawItem.id] = prepareEntity(
            lawItem,
            level,
          )),
      );
    }
    if (lawPrefaceItem.childItems) {
      addLawPrefaceItem(lawPrefaceItem.childItems, combinedList, level + 1);
    }
    if (lawPrefaceItem.keywords) {
      lawPrefaceItem.keywords.forEach(
        (keyword) =>
          (combinedList[EntityTypes.TAXONOMY][keyword.id] = prepareEntity(
            keyword,
            level,
          )),
      );
    }
  });
};

// endregion LawPrefaceItem

// region TheoryItem

export const addTheoryItem = (theoryItems, combinedList, addSelf, level) => {
  (theoryItems || []).forEach((theoryItem) => {
    if (theoryItem.childTheoryItems) {
      addTheoryItem(theoryItem.childTheoryItems, combinedList);
    }

    if (theoryItem.relatedLawItems) {
      theoryItem.relatedLawItems.forEach((lawItem) =>
        addLawItemProcessSteps(lawItem, combinedList, level),
      );
      theoryItem.relatedLawItems.forEach(
        (lawItem) =>
          (combinedList[EntityTypes.LAWITEM][lawItem.id] = prepareEntity(
            lawItem,
            level,
          )),
      );
    }
    if (theoryItem?.relatedDecisions) {
      theoryItem.relatedDecisions.forEach(
        (decision) =>
          (combinedList[EntityTypes.DECISION][decision.id] = prepareEntity(
            decision,
            level,
            theoryItem.id,
            getEntityType(theoryItem),
            theoryItem.title,
          )),
      );
    }
    if (theoryItem.keywords) {
      theoryItem.keywords.forEach(
        (keyword) =>
          (combinedList[EntityTypes.TAXONOMY][keyword.id] = prepareEntity(
            keyword,
            level,
          )),
      );
    }

    if (addSelf) {
      combinedList[EntityTypes.THEORYITEM][theoryItem.id] = prepareEntity(
        theoryItem,
        level,
      );
    }
  });
};
// endregion TheoryItem

// region Taxonomy

export const addKeywordItem = (keywordItems, combinedList, addSelf, level) => {
  (keywordItems || []).forEach((keyword) => {
    if (keyword.lawItem) {
      keyword.lawItem.forEach((lawItem) =>
        addLawItemProcessSteps(lawItem, combinedList, level),
      );
      keyword.lawItem.forEach(
        (lawItem) =>
          (combinedList[EntityTypes.LAWITEM][lawItem.id] = prepareEntity(
            lawItem,
            level,
          )),
      );
    }
    if (keyword.decision) {
      keyword.decision.forEach(
        (decision) =>
          (combinedList[EntityTypes.DECISION][decision.id] = prepareEntity(
            decision,
            level,
          )),
      );
    }
    if (keyword.theoryItem) {
      keyword.theoryItem.forEach(
        (theoryItem) =>
          (combinedList[EntityTypes.THEORYITEM][theoryItem.id] = prepareEntity(
            theoryItem,
            level,
          )),
      );
    }
  });
};

// endregion Taxonomy

// region Decisions

export const addDecisions = (entity, combinedList, level) => {
  if (entity.keywords) {
    entity.keywords.forEach(
      (keyword) =>
        (combinedList[EntityTypes.TAXONOMY][keyword.id] = prepareEntity(
          keyword,
          0,
        )),
    );
  }

  if (entity.relatedTheoryItems) {
    entity.relatedTheoryItems.forEach(
      (theoryItem) =>
        (combinedList[EntityTypes.THEORYITEM][theoryItem.id] = theoryItem),
    );
  }
  if (entity.relatedLawItems) {
    entity.relatedLawItems.forEach((lawItem) =>
      addLawItemProcessSteps(lawItem, combinedList, level),
    );
    entity.relatedLawItems.forEach(
      (lawItem) =>
        (combinedList[EntityTypes.LAWITEM][lawItem.id] = prepareEntity(
          lawItem,
          level,
        )),
    );
  }

  if (entity.relatedDecisions) {
    entity.relatedDecisions.forEach(
      (decision) =>
        (combinedList[EntityTypes.DECISION][decision.id] = decision),
    );
  }
};

// endregion Decisions

// region ProcessStep

export const addProcessStepData = (processSteps, combinedList) => {
  (processSteps || []).forEach((entity) => {
    addProcessStepData(entity.children, combinedList);
    if (entity.keywords) {
      entity.keywords.forEach(
        (keyword) =>
          (combinedList[EntityTypes.TAXONOMY][keyword.id] = prepareEntity(
            keyword,
            0,
          )),
      );
    }

    if (entity.relatedLawItems) {
      entity.relatedLawItems.forEach((lawItem) => {
        addProcessStepLawItemDecisions(lawItem, combinedList);
        addProcessStepLawItemKeywords(lawItem, combinedList);
        addProcessStepLawItemTheoryItems(lawItem, combinedList);
        combinedList[EntityTypes.LAWITEM][lawItem.id] = lawItem;
      });
    }
  });
};

const addProcessStepLawItemDecisions = (lawItem, combinedList, level = 1) => {
  if (lawItem.childLawItems) {
    lawItem.childLawItems.forEach((child) =>
      addProcessStepLawItemDecisions(child, combinedList, level + 1),
    );
  }
  if (lawItem.relatedDecisions) {
    lawItem.relatedDecisions.forEach(
      (decision) =>
        (combinedList[EntityTypes.DECISION][decision.id] = prepareEntity(
          decision,
          level,
          lawItem.id,
          getEntityType(lawItem),
          lawItem.lawId,
        )),
    );
  }
};

const addProcessStepLawItemTheoryItems = (lawItem, combinedList, level = 1) => {
  if (lawItem.childLawItems) {
    lawItem.childLawItems.forEach((child) =>
      addProcessStepLawItemTheoryItems(child, combinedList, level + 1),
    );
  }
  if (lawItem.relatedTheoryItems) {
    lawItem.relatedTheoryItems.forEach(
      (theoryItem) =>
        (combinedList[EntityTypes.THEORYITEM][theoryItem.id] = prepareEntity(
          theoryItem,
          level,
          lawItem.title,
          getEntityType(lawItem),
          lawItem.lawId,
        )),
    );
  }
};

const addProcessStepLawItemKeywords = (lawItem, combinedList, level = 1) => {
  if (lawItem.childLawItems) {
    lawItem.childLawItems.forEach((child) =>
      addProcessStepLawItemKeywords(child, combinedList, level + 1),
    );
  }
  if (lawItem.keywords) {
    lawItem.keywords.forEach((keyword) => {
      combinedList[EntityTypes.TAXONOMY][keyword.id] = prepareEntity(
        keyword,
        level,
        lawItem.title,
        getEntityType(lawItem),
        lawItem.lawId,
      );
    });
  }
};

// endregion ProcessStep

// region Common Helpers

const addLawItemProcessSteps = (lawItem, combinedList, level) => {
  if (lawItem.parentItem) {
    addLawItemProcessSteps(lawItem.parentItem, combinedList, level + 1);
  }
  if (lawItem.relatedProcessSteps) {
    lawItem.relatedProcessSteps.forEach(
      (processStep) =>
        (combinedList[EntityTypes.PROCESSSTEP][processStep.id] = prepareEntity(
          processStep,
          level,
          null,
          getEntityType(lawItem),
        )),
    );
  }
};

// endregion Common Helpers

// region cleanup

const toIdOnly = (entity) => ({ id: entity.id });

const prepareEntity = (
  e,
  level,
  relatedItemId,
  relatedEntityType,
  relatedInformation,
  relationType,
) => {
  // clean the entity
  if (e.relatedTheoryItems) {
    e.relatedTheoryItems = e.relatedTheoryItems.map(toIdOnly);
  }
  if (e.relatedLawItems) {
    e.relatedLawItems = e.relatedLawItems.map(toIdOnly);
  }
  if (e.relatedDecisions) {
    e.relatedDecisions = e.relatedDecisions.map(toIdOnly);
  }
  if (e.relatedProcessSteps) {
    e.relatedProcessSteps = e.relatedProcessSteps.map(toIdOnly);
  }
  if (e.keywords) {
    e.keywords = e.keywords.map(toIdOnly);
  }
  switch (getEntityType(e)) {
    case EntityTypes.LAWITEM: {
      if (e[EntityFields[EntityTypes.LAWITEM].CHANGED_BY]) {
        e[EntityFields[EntityTypes.LAWITEM].CHANGED_BY] =
          e[EntityFields[EntityTypes.LAWITEM].CHANGED_BY].map(toIdOnly);
      }
      if (e[EntityFields[EntityTypes.LAWITEM].CHANGING]) {
        e[EntityFields[EntityTypes.LAWITEM].CHANGING] =
          e[EntityFields[EntityTypes.LAWITEM].CHANGING].map(toIdOnly);
      }
      if (e[EntityFields[EntityTypes.LAWITEM].REPLACED_BY]) {
        e[EntityFields[EntityTypes.LAWITEM].REPLACED_BY] =
          e[EntityFields[EntityTypes.LAWITEM].REPLACED_BY].map(toIdOnly);
      }
      if (e[EntityFields[EntityTypes.LAWITEM].REPLACED_BY]) {
        e[EntityFields[EntityTypes.LAWITEM].REPLACED_BY] =
          e[EntityFields[EntityTypes.LAWITEM].REPLACED_BY].map(toIdOnly);
      }
      if (e[EntityFields[EntityTypes.LAWITEM].JURISDICTION_BY]) {
        e[EntityFields[EntityTypes.LAWITEM].JURISDICTION_BY] =
          e[EntityFields[EntityTypes.LAWITEM].JURISDICTION_BY].map(toIdOnly);
      }
      if (e[EntityFields[EntityTypes.LAWITEM].JURISDICTION]) {
        e[EntityFields[EntityTypes.LAWITEM].JURISDICTION] =
          e[EntityFields[EntityTypes.LAWITEM].JURISDICTION].map(toIdOnly);
      }
      if (e[EntityFields[EntityTypes.LAWITEM].RELATED_LAW_PREFACE_ITEMS]) {
        e[EntityFields[EntityTypes.LAWITEM].RELATED_LAW_PREFACE_ITEMS] =
          e[EntityFields[EntityTypes.LAWITEM].RELATED_LAW_PREFACE_ITEMS].map(
            toIdOnly,
          );
      }
      delete e.childLawItems;
      break;
    }
    case EntityTypes.LAWPREFACEITEM: {
      delete e.childLawItems;
      break;
    }
    case EntityTypes.THEORYITEM: {
      // clean the entity  // TODO - Are there more?
      delete e.childTheoryItems;
      break;
    }
    default: {
      break;
    }
  }
  switch (relatedEntityType) {
    case EntityTypes.LAWITEM: {
      return {
        ...e,
        relatedEntityType,
        relatedLawId: relatedInformation,
        relatedLawItemId: relatedItemId,
        level: level || 0,
        relationType,
      };
    }
    case EntityTypes.THEORYITEM: {
      return {
        ...e,
        relatedEntityType,
        relatedLawId: relatedInformation,
        relatedLawItemTitle: relatedItemId,
        level: level || 0,
        relationType,
      };
    }
    default: {
      return {
        ...e,
        relatedEntityType,
        relatedItemId,
        level: level || 0,
      };
    }
  }
};

// endregion cleanup

// endregion ArticleFooter
