import * as ENTITIES from './EntityTypes';

export const PageEntities = {
  THEORY_PAGE: [ENTITIES.THEORYITEM],
  LAW_PAGE: [ENTITIES.LAWITEM, ENTITIES.LAWPREFACEITEM],
  DECISION_PAGE: [ENTITIES.DECISION],
  TAXONOMY_PAGE: [ENTITIES.TAXONOMY],
};

export const LawPageEntities = Object.freeze({
  LAWITEM: 0,
  LAWPREFACEITEM: 1,
});
