import * as ActionTypes from '../../constants/ActionTypes';

export const updateConsent = (consent, userId) => ({
  type: ActionTypes.API_USER_CONSENT_UPDATE_CONSENT_INIT,
  payload: { consent, userId },
});
export const getConsentsById = (id) => ({
  type: ActionTypes.API_USER_CONSENT_GET_USER_CONSENT_BY_ID_INIT,
  payload: { id },
});
