import * as ActionTypes from '../../../constants/ActionTypes';
import { call, takeEvery } from 'redux-saga/effects';
import { generateApiSaga } from '../../../utils/sagas';
import CompanyConnector from '../../../connectors/Company';

const doSave = generateApiSaga(CompanyConnector, function* (connector, action) {
  const company = action.payload.company;
  return yield call([connector, connector.updateCompany], company);
});

export default function* updateCompany() {
  yield takeEvery(ActionTypes.API_UPDATE_COMPANY_INIT, doSave);
}
