import * as ActionTypes from '../../constants/ActionTypes';

export const createLicense = (licenseData) => ({
  type: ActionTypes.API_LICENSE_CREATE_INIT,
  payload: { licenseData },
});
export const updateLicense = (licenses) => ({
  type: ActionTypes.API_LICENSE_UPDATE_INIT,
  payload: { licenses },
});
export const deleteLicense = (licenses) => ({
  type: ActionTypes.API_LICENSE_DELETE_INIT,
  payload: { licenses },
});
export const getAvailable = () => ({
  type: ActionTypes.API_LICENSE_GET_AVAILABLE_INIT,
  payload: {},
});
export const getAll = () => ({
  type: ActionTypes.API_LICENSE_GET_ALL_INIT,
  payload: {},
});
export const claimLicense = (license) => ({
  type: ActionTypes.API_CLAIM_LICENSE_INIT,
  payload: { license },
});
