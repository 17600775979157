/** @jsx jsx */
import { css } from 'emotion';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { fontColors, uiColors } from '../styles/styles';
import { jsx } from '@emotion/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const styles = {
  checkboxWrapper: {
    '.checkbox-container': {
      display: 'block',
      position: 'relative',
      paddingLeft: '35px',
      paddingTop: '5px',
      marginTop: '0',
      marginBottom: '0',
      cursor: 'pointer',
      fontSize: '14px',
      webkitUserSelect: 'none',
      mozUserSelect: 'none',
      msUserSelect: 'none',
      userSelect: 'none',
      input: {
        position: 'absolute',
        opacity: '0',
        cursor: 'pointer',
        display: 'none ',
      },

      '.checkmark': {
        position: 'absolute',
        top: '7px',
        left: '0',
        height: '20px',
        width: '20px',
        backgroundColor: uiColors.WHITE,
        border: '1px solid ' + uiColors.GREY,
        borderRadius: '4px',
        fontSize: '14px',
        lineHeight: '20px',
        textAlign: 'center',
        color: uiColors.WHITE,
        ':hover': {
          border: '1px solid ' + uiColors.DARK,
        },
        ':active': {
          boxShadow: 'inset 0px  0px 2px 2px rgba(62, 66, 71, 0.1)',
          fontSize: '12px',
        },
      },
      'input:checked ~ .checkmark': {
        backgroundColor: uiColors.BRAND,
        border: 'none',
        color: fontColors.WHITE,
      },
      'input:disabled ~ .checkmark': {
        opacity: '0.5',
        lineHeight: '18px',
        fontSize: '14px',
        cursor: 'default',
        border: '1px solid ' + uiColors.GREY,
        boxShadow: 'none',
      },
    },
  },
  '.checkmark:after': {
    position: 'absolute',
    content: '""',
    display: 'none',
  },
};

class Checkbox extends Component {
  static propTypes = {
    cssOverrides: PropTypes.object,
  };

  static defaultProps = {
    cssOverrides: {},
    disabled: false,
  };

  render() {
    const { checked, disabled } = this.props;
    return (
      <div className={css(styles.checkboxWrapper)} disabled={disabled}>
        <label className="checkbox-container" css={styles} disabled={disabled}>
          {this.props.children}
          <input
            type="checkbox"
            checked={checked}
            onChange={this.props.onChange}
            disabled={disabled}
          />
          <span className="checkmark" disabled={disabled}>
            <FontAwesomeIcon icon={faCheck} />
          </span>
        </label>
      </div>
    );
  }
}

export default Checkbox;
