import * as ActionTypes from '../../constants/ActionTypes';
import * as EntityTypes from '../../constants/EntityTypes';
import { fromJS } from 'immutable';
import { STATE_ENTITY_PROPERTY } from '../../constants/StateProperties';

const initialState = fromJS({
  [EntityTypes.LAWITEM]: {},
  [EntityTypes.DECISION]: {},
  [EntityTypes.PROCESSSTEP]: {},
  [EntityTypes.THEORYITEM]: {},
  [EntityTypes.TAXONOMY]: {},
  isLoading: 0,
  showRedThread: false,
});
export default function articleFooter(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.API_LAW_ITEM_GET_RELATION_TREE_BY_ID_REQUEST:
    case ActionTypes.API_THEORY_ITEM_GET_RELATION_TREE_BY_ID_REQUEST:
    case ActionTypes.API_PROCESS_STEP_GET_RELATION_TREE_BY_ID_REQUEST:
    case ActionTypes.API_DECISION_GET_RELATION_TREE_BY_ID_REQUEST:
    case ActionTypes.API_TAXONOMY_GET_RELATION_TREE_BY_ID_REQUEST:
    case ActionTypes.API_LAW_PREFACE_ITEM_GET_RELATION_TREE_BY_ID_REQUEST:
      return (state = setCount(state, 1));
    case ActionTypes.PAGE_FOOTER_SELECT_DATA:
      state = setCount(state, -1);
      const data = action.payload.entities;
      return state.setIn(
        [action.payload.type, action.payload.id],
        fromJS({ ...data, type: action.payload.type, id: action.payload.id }),
      );
    case ActionTypes.PAGE_FOOTER_TOGGLE_RED_THREAD:
      return state.set('showRedThread', !state.get('showRedThread'));
    case ActionTypes.API_LAW_ITEM_GET_RELATION_TREE_BY_ID_ERROR:
    case ActionTypes.API_LAW_PREFACE_ITEM_GET_RELATION_TREE_BY_ID_ERROR:
    case ActionTypes.API_THEORY_ITEM_GET_RELATION_TREE_BY_ID_ERROR:
    case ActionTypes.API_PROCESS_STEP_GET_RELATION_TREE_BY_ID_ERROR:
    case ActionTypes.API_DECISION_GET_RELATION_TREE_BY_ID_ERROR:
    case ActionTypes.API_TAXONOMY_GET_RELATION_TREE_BY_ID_ERROR:
      return (state = setCount(state, -1));
    default:
      return state;
  }
}

const setCount = (state, change) => {
  const count = state.get(STATE_ENTITY_PROPERTY.isLoading, 0) + change;
  return state.set(STATE_ENTITY_PROPERTY.isLoading, count <= 0 ? 0 : count);
};
