import { fromJS } from 'immutable';
import * as ActionTypes from '../../constants/ActionTypes';
import { mergeResponseToState } from './index';
import * as EntityTypes from '../../constants/EntityTypes';

const initialState = fromJS({
  data: {},
});

export default function lawGroupApiReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.API_LAWGROUP_GET_ALL_SUCCESS:
      return mergeResponseToState(state, action, EntityTypes.LAWGROUP);
    default:
      return state;
  }
}
