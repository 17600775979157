/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component } from 'react';
import Checkbox from '../atoms/Checkbox';
import { refBind } from '../../../utils/ref-bind';
import PropTypes from 'prop-types';
import { Trans, withTranslation } from 'react-i18next';

const styles = {
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
};

const propTypes = {
  onChange: PropTypes.func.isRequired,
  onClickCheckbox: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  changeDescription: PropTypes.string,
};

const defaultProps = {
  checked: false,
  changeDescription: '',
};

class Newsworthy extends Component {
  constructor(props) {
    super(props);
    refBind(this, 'handleOnChange');
  }

  handleOnChange(event) {
    const { onChange } = this.props;
    onChange(event.target.value);
  }

  render() {
    const { t, checked, onClickCheckbox, changeDescription } = this.props;
    return (
      <div css={styles}>
        <Checkbox onChange={onClickCheckbox} checked={checked}>
          <label>
            <Trans i18nKey="newsworthy.label" />
          </label>
        </Checkbox>
        {checked ? (
          <textarea
            onChange={this.handleOnChange}
            placeholder={t('newsworthy.placeholder')}
            value={changeDescription}
          />
        ) : null}
      </div>
    );
  }
}

Newsworthy.propTypes = propTypes;
Newsworthy.defaultProps = defaultProps;

export default withTranslation()(Newsworthy);
