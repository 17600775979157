import * as ActionTypes from '../../constants/ActionTypes';

export const pageLoad = (url) => ({
  type: ActionTypes.APP_PAGE_PROFILE_LOAD,
  payload: { url },
});
export const selectUser = (user) => ({
  type: ActionTypes.APP_PAGE_PROFILE_SELECT_USER,
  payload: { user },
});
