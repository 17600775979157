import { call, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import lawItemConnector from '../../../connectors/LawItem';
import { generateApiSaga } from '../../../utils/sagas';

const doGetLawItemByUri = generateApiSaga(
  lawItemConnector,
  function* (connector, action) {
    let nodeFragment = action.payload.uri;
    return yield call([connector, connector.getByUri], nodeFragment);
  },
);

export default function* getAllBySectionAndType() {
  yield takeEvery(ActionTypes.API_LAWITEM_GET_BY_URI_INIT, doGetLawItemByUri);
}
