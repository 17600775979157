import * as EntityTypes from '../../constants/EntityTypes';
import * as authSelectors from '../store/auth';
import * as CommonSelectors from '../entity/common';

export const getAll = (state) =>
  CommonSelectors.getAll(state, EntityTypes.APPUSER);
export const getUserById = (state, id) =>
  CommonSelectors.getEntity(state, EntityTypes.APPUSER, id);
export const getCurrentUser = (state) =>
  getUserById(state, Number(authSelectors.getUserId(state)));
