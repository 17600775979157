/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import PropTypes from 'prop-types';
import Text, { TextType } from '../common/atoms/Text';
import ArticleCountGrid from '../common/molecules/ArticleCountGrid';
import ControlledByPermission from '../../containers/shared/ControlledByPermission';
import { Link } from 'react-router-dom';
import { getNodeLink } from '../../utils/link';
import { getNavTreeLabel } from '../../utils/treehelpers';

export const BranchPropType = PropTypes.shape({
  parent: PropTypes.number,
  node: PropTypes.object,
  children: PropTypes.array,
  useTemplateRef: PropTypes.bool,
});

const propTypes = {
  isNodeActive: PropTypes.bool,
  isArticle: PropTypes.bool,
  onNodeSelection: PropTypes.func,
  branch: BranchPropType,
  linkHighlightedNodes: PropTypes.bool,
};

export function renderRelatedItems(node, isSelected) {
  const {
    relatedLawCount,
    relatedDecisionCount,
    relatedProcessCount,
    relatedKeywordCount,
    relatedTheoryCount,
  } = node;
  return (
    <div className={isSelected ? 'active-node' : ''}>
      <ArticleCountGrid>
        <div>{relatedLawCount}</div>
        <div>{relatedDecisionCount}</div>
        <div>{relatedTheoryCount}</div>
        <div>{relatedProcessCount}</div>
        <div>{relatedKeywordCount}</div>
      </ArticleCountGrid>
    </div>
  );
}

class TreeItemLeaf extends React.Component {
  constructor(props) {
    super(props);
    this._onNodeSelection = this._onNodeSelection.bind(this);
  }

  _onNodeSelection() {
    this.props.onNodeSelection(this.props.branch);
  }

  render() {
    const {
      isNodeActive,
      branch,
      parents,
      isArticle,
      noHover,
      linkHighlightedNodes,
    } = this.props;
    let displayText = getNavTreeLabel(branch.node);
    if (linkHighlightedNodes && branch.highlight) {
      displayText = (
        <Link to={getNodeLink(branch, parents)}>{displayText}</Link>
      );
    }
    const id = branch.node.id;
    return (
      <div
        key={`key-tree-${id}`}
        className={`${id} li-leaf-node ${!noHover && 'li-text'} w-100 ${
          isNodeActive ? 'active-node' : ''
        }`}
        onClick={this._onNodeSelection}
      >
        <div className={isArticle ? 'leaf-article-layout' : 'leaf-layout'}>
          <Text type={TextType.NAVIGATION_TREE_CHILD}>{displayText}</Text>
          {isArticle && (
            <ControlledByPermission
              permission={this.props.countPermission}
              render={renderRelatedItems(branch.node)}
            />
          )}
        </div>
      </div>
    );
  }
}

TreeItemLeaf.propTyeps = propTypes;

export default TreeItemLeaf;
