import ItemConnector from './Item';
import { getEntityId } from '../utils/entities';
import * as Searches from '../constants/Searches';
import * as Projections from '../constants/Projections';
import * as EntityTypes from '../constants/EntityTypes';
import * as LinkResources from '../constants/LinkRessources';
import * as Sort from '../constants/Sort';
import { getSortConfiguration } from '../constants/HalProperties';

export default class LawItemConnector extends ItemConnector {
  constructor(...args) {
    super(...args);
    this.entityType = EntityTypes.LAWITEM;
  }

  getAll(pageSize, page) {
    const query = this.query()
      .withProjection(Projections.WITHID)
      .withSize(pageSize)
      .withPage(page)
      .build();

    return this.authGet(query);
  }

  getAllByLawNode(law) {
    const lawid = getEntityId(law);
    const href = 'law/' + lawid + '/lawItems?projection=expanded';
    return fetch(this.baseApiUrl + href);
  }

  getAllByLaw(lawId, pageszie, page) {
    const query = this.query()
      .withSearch(Searches.findByLaw, [lawId])
      .withProjection(Projections.WITHID)
      .withSize(pageszie)
      .withPage(page)
      .build();

    return this.authGet(query);
  }

  getByUri(uri) {
    return this.authGet(
      this.query()
        .withSearch(Searches.findByUri, [uri])
        .withProjection(Projections.WITHID)
        .build(),
    );
  }

  getByUriWithParents(uri) {
    const query = this.query()
      .withSearch(Searches.findByUri, [uri])
      .withProjection(Projections.PARENTS)
      .build();

    return this.authGet(query);
  }

  getByIdsWithParents(ids) {
    const query = this.query()
      .withSearch(Searches.findByIds, [ids])
      .withProjection(Projections.PARENTS)
      .withSize(1000) // TODO: HACK, Works until documents grow larger :/
      .build();

    return this.authGet(query);
  }

  getByTitleOrContent(title, content) {
    const query = this.query()
      .withSearch(Searches.findByTitleOrContent, [title, content])
      .withSize(1000) // TODO: HACK, Works until documents grow larger :/
      .withProjection(Projections.PARENTS)
      .build();
    return this.authGet(query);
  }

  getTopLevel(id, projection = Projections.TOP, size = 1000) {
    const query = this.query()
      .withSearch(Searches.findLawItemByLawId, [id])
      .withProjection(projection)
      .withSize(size) // TODO: HACK, Works until documents grow larger :/
      .build();
    return this.authGet(query);
  }

  getLawItemChildren(id, paging) {
    const query = this.query()
      .withId(id)
      .withLink(LinkResources.childLawItems)
      .withProjection(Projections.EXPAND)
      .withPaging(paging)
      .build();
    return this.authGet(query);
  }

  searchByName(name) {
    const query = this.query()
      .withSearch(Searches.findLawItemByName, [name])
      .build();
    return this.authGet(query);
  }

  getNewest() {
    const url = this.query()
      .withSearch(Searches.findByUriIsNotNull, [])
      .withSort(
        getSortConfiguration(Sort.updatedTimestamp, Sort.Direction.DESC),
      )
      .withSize(15)
      .withProjection(Projections.WITHID)
      .build();
    return this.authGet(url);
  }

  getRelatedLawItemsById(id) {
    const query = this.query()
      .withSearch(Searches.findByIds, [id])
      .withProjection(Projections.HISTORY)
      .withSize(1000) // TODO: HACK, Works until documents grow larger :/
      .build();
    return this.authGet(query);
  }

  getLawItemById(id, projection) {
    const query = this.query().withId(id).withProjection(projection).build();
    return this.authGet(query);
  }
}
