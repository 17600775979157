import { call, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import SearchConnector from '../../../connectors/Search';
import { generateApiSaga } from '../../../utils/sagas';

const doSearch = generateApiSaga(
  SearchConnector,
  function* (connector, action) {
    return yield call([connector, connector.searchQuery], action.payload);
  },
);

export default function* getAll() {
  yield takeEvery(ActionTypes.API_SEARCH_INIT, doSearch);
}
