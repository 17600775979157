import BaseConnector from './Base';
import { ARTICLE } from '../constants/Resources';
import { ARTICLE_LINKS } from '../constants/ApiLinks';

export default class ArticleConnector extends BaseConnector {
  constructor(...args) {
    super(...args);
    this.entityType = ARTICLE;
  }

  getAllTheoryTypes() {
    return this.authGet(
      this.query().withLink(ARTICLE_LINKS.theoryItemTypes).build(),
    );
  }

  getAllLawItemTypes() {
    return this.authGet(
      this.query().withLink(ARTICLE_LINKS.lawItemTypes).build(),
    );
  }
}
