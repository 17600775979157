import { all, fork } from 'redux-saga/effects';
import searchSaga from './search';
import pageOpenSage from './pageopen';
import updateFilterSaga from './updatefilter';
import backgroundSearchSaga from './backgroundsearch';
import updateTabSaga from './updatetab';
import fetchResultsSaga from './fetchresults';

export default function* appSearchSaga() {
  yield all([
    fork(searchSaga),
    fork(pageOpenSage),
    fork(updateFilterSaga),
    fork(backgroundSearchSaga),
    fork(updateTabSaga),
    fork(fetchResultsSaga),
  ]);
}
