import unsubscribeAllNotifications from './unsubscribeAllNotifications';
import unsubscribeSingleNotification from './unsubscribeSingleNotification';
import { all, fork } from 'redux-saga/effects';

export default function* unsubscribeRootSaga() {
  yield all([
    fork(unsubscribeAllNotifications),
    fork(unsubscribeSingleNotification),
  ]);
}
