import * as ActionTypes from '../constants/ActionTypes';

export const updateSolrData = () => ({
  type: ActionTypes.API_FULL_SYNC_SOLR_DATA_INIT,
  payload: {},
});

export const search = (contentFilter) => ({
  type: ActionTypes.API_SEARCH_INIT,
  payload: contentFilter,
});
