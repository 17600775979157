import * as ActionTypes from '../../../constants/ActionTypes';
import * as companyActions from '../../../actions/api/company';
import * as userActions from '../../../actions/api/appuser';
import * as licenseActions from '../../../actions/api/license';
import * as userSelectors from '../../../selectors/api/appuser';
import * as companySelectors from '../../../selectors/api/company';
import * as adminHomePageActions from '../../../actions/page/adminhome';
import { all, put, race, select, take, takeEvery } from 'redux-saga/effects';
import equal from 'fast-deep-equal';
import { CREATED, DELETED, UPDATED } from '../../../constants/UpdateType';

function* doUpdateUserDetails(action) {
  const { user, company, licenses } = action.payload.userDetails;

  // handle user and company
  const userFromState = yield select(userSelectors.getUserById, user.id);
  const companyFromState = yield select(
    companySelectors.getCompanyById,
    company.id,
  );

  const persistedUser = userFromState.size && userFromState.get('node').toJS();
  const persistedCompany =
    companyFromState.size && companyFromState.get('node').toJS();

  let updateError = false;
  let actions = [];
  let actionTypes = [];

  if (persistedUser && !equal(persistedUser, user)) {
    actions.push(userActions.update(user));
    actionTypes.push({
      error: ActionTypes.API_USER_UPDATE_APP_USER_SUCCESS,
      success: ActionTypes.API_USER_UPDATE_APP_USER_ERROR,
    });
  }

  if (persistedCompany && !equal(persistedCompany, company)) {
    actions.push(companyActions.update(company));
    actionTypes.push({
      error: ActionTypes.API_UPDATE_COMPANY_SUCCESS,
      success: ActionTypes.API_UPDATE_COMPANY_ERROR,
    });
  }

  const licenseOperations = licenses
    .filter((l) => l.operation)
    .reduce(
      (acc, { operation, ...license }) => ({
        ...acc,
        [operation]: [...(acc[operation] ?? []), license],
      }),
      {},
    );

  if (licenseOperations[UPDATED]) {
    actions.push(licenseActions.updateLicense(licenseOperations[UPDATED]));
    actionTypes.push({
      error: ActionTypes.API_LICENSE_UPDATE_ERROR,
      success: ActionTypes.API_LICENSE_UPDATE_SUCCESS,
    });
  }

  if (licenseOperations[DELETED]) {
    actions.push(licenseActions.deleteLicense(licenseOperations[DELETED]));
    actionTypes.push({
      error: ActionTypes.API_LICENSE_UPDATE_ERROR,
      success: ActionTypes.API_LICENSE_UPDATE_SUCCESS,
    });
  }

  yield all(actions.map((action) => put(action)));
  const { error } = yield race({
    error: take(actionTypes.map((a) => a.error)),
    success: all(actionTypes.map((a) => take(a.success))),
  });

  if (error) updateError = true;

  // license creation handled separately as there is one action per license
  if (!updateError && licenseOperations[CREATED]) {
    const licenseData = licenseOperations[CREATED].map((license) => ({
      user,
      license: {
        company: { value: license.company.id },
        role: { value: license.role.id },
        expirationDate: license.expirationDate,
      },
      quantity: 1,
    }));

    for (let i = 0; i < licenseData.length; i++) {
      yield put(licenseActions.createLicense(licenseData[i]));
      const { creationError } = yield race({
        creationError: take(ActionTypes.API_LICENSE_CREATE_ERROR),
        creationSuccess: take(ActionTypes.API_LICENSE_CREATE_SUCCESS),
      });

      if (creationError) {
        updateError = true;
        break;
      }
    }
  }

  if (updateError) {
    yield put(adminHomePageActions.updateUserDetailsError());
  } else {
    yield put(adminHomePageActions.updateUserDetailsSuccess());
  }
}

export default function* updateUserDetails() {
  yield takeEvery(
    ActionTypes.APP_PAGE_ADMIN_HOME_USER_DETAILS_UPDATE_INIT,
    doUpdateUserDetails,
  );
}
