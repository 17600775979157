import { put, select } from 'redux-saga/effects';
import { takeLatestWhenAppReady } from '../../../utils/sagas';
import * as ActionTypes from '../../../constants/ActionTypes';
import * as EntityType from '../../../constants/EntityTypes';
import * as lawPageActions from '../../../actions/page/law';
import * as routerActionSelector from '../../../selectors/actions/router';
import * as lawPageSelector from '../../../selectors/page/law';

function* doLocationChange(action) {
  const path = routerActionSelector.getSplitPath(action);
  if (!path.length) return;

  const [entity, uri, id] = path;

  if (entity === EntityType.LAW) {
    // TODO: find better solution for duplicated route change event
    let lastUri = yield select(lawPageSelector.getLastLawUri);
    const path = `${uri}/${id || ''}`;
    if (lastUri === path) return;
    yield put(lawPageActions.setLastUri(path));
    yield put(lawPageActions.pageLoad([uri, id]));
  }
}

export default function* locationChange() {
  yield takeLatestWhenAppReady(
    ActionTypes.APP_ROUTER_LOCATION_CHANGE,
    doLocationChange,
  );
}
