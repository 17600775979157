import { takeLatestWhenAppReady } from '../../../utils/sagas';
import * as ActionTypes from '../../../constants/ActionTypes';
import * as EntityType from '../../../constants/EntityTypes';
import * as taxonomyPageActions from '../../../actions/page/taxonomy';
import * as routerActionSelector from '../../../selectors/actions/router';
import { put } from 'redux-saga/effects';

function* doLocationChange(action) {
  let path = routerActionSelector.getSplitPath(action);
  if (!path.length) return;

  const [entity, id] = path;
  if (entity === EntityType.TAXONOMY) {
    yield put(taxonomyPageActions.pageLoad(id));
  }
}

export default function* locationChange() {
  yield takeLatestWhenAppReady(
    ActionTypes.APP_ROUTER_LOCATION_CHANGE,
    doLocationChange,
  );
}
