import { Button, Overlay } from 'react-bootstrap';
import React from 'react';
import { FaSearch } from 'react-icons/fa';
import { fontColors, uiColors } from '../styles/styles';

const style = {
  backgroundColor: uiColors.BRAND,
  border: '1px solid ' + uiColors.GREY,
  color: fontColors.WHITE,
  borderRadius: '50%',
  padding: '0 8px',

  '&:hover:enabled': {
    borderColor: uiColors.WHITE,
    transform: 'scale(1.1)',
  },
  '&:focus': {
    outline: '0',
  },
};

export class SearchOverlay extends React.Component {
  constructor(props) {
    super(props);
    this.setShow = this.setShow.bind(this);
    this.dismiss = this.dismiss.bind(this);
    this.state = { show: false };
    this.target = React.createRef();
  }

  setShow() {
    this.setState({ show: !this.state.show });
  }

  dismiss(event) {
    this.setState({ show: false });
    event.stopPropagation();
  }

  render() {
    const { show } = this.state;
    const { target, disabled } = this.props;

    return (
      <div style={{ ...this.props.style, margin: '5px' }}>
        <Button
          className={`round-button filter-icon`}
          style={style}
          ref={this.target}
          onClick={this.setShow}
          disabled={disabled}
        >
          <FaSearch size={8} />
          {this.props.header}
        </Button>
        <Overlay
          target={target}
          show={show}
          placement="bottom"
          rootClose
          onHide={this.dismiss}
        >
          {({ placement, arrowProps, show: _show, popper, ...props }) => {
            let y =
              (popper.state &&
                popper.state.elements.reference.offsetTop +
                  (this.props.offset || 0)) ||
              0;
            return (
              <div
                style={{
                  top: `${y}px`,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  position: 'fixed',
                  backgroundColor: 'rgba(128,128,128,0.5)',
                }}
              >
                <div
                  {...props}
                  className={'scroll-bar'}
                  style={{
                    ...props.style,
                    backgroundColor: uiColors.GREY,
                    overflow: 'auto',
                    width: '450px',
                    height: '100%',
                    transform: 'none',
                  }}
                >
                  <div className="px-3 py-2">{this.props.children}</div>
                </div>
              </div>
            );
          }}
        </Overlay>
      </div>
    );
  }
}

export default SearchOverlay;
