import * as ActionTypes from '../../constants/ActionTypes';

export const pageLoad = (uri) => ({
  type: ActionTypes.APP_PAGE_DECISION_LOAD,
  payload: { uri },
});
export const selectNode = (node) => ({
  type: ActionTypes.APP_PAGE_DECISION_SELECT_NODE,
  payload: { node },
});
export const getDecisions = (getNextPage) => ({
  type: ActionTypes.APP_PAGE_DECISIONS_GET,
  payload: { getNextPage },
});
export const getDecisionFileByDecisionId = (id) => ({
  type: ActionTypes.API_DECISION_FILE_GET_BY_DECISION_ID_INIT,
  payload: { id },
});
export const submitDecisionFormData = (file, decision, mode, handler) => ({
  type: ActionTypes.PAGE_ADMIN_DECISION_SUBMIT_FORM,
  payload: { file, decision, mode, handler },
});
