import { all, fork } from 'redux-saga/effects';
import getAll from './getall';
import getByIds from './getbyids';
import getRelationTree from './getRelationTree';
import createTaxonomy from './createTaxonomy';

export default function* taxonomyRootSaga() {
  yield all([
    fork(getAll),
    fork(getByIds),
    fork(getRelationTree),
    fork(createTaxonomy),
  ]);
}
