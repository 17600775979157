import { Map } from 'immutable';
import * as EntityTypes from '../../constants/EntityTypes';
import * as commonSelectors from '../entity/common';
import * as appUserSelectors from './appuser';
import { createSelector } from 'reselect';

export const getAll = (state) =>
  commonSelectors.getAll(state, EntityTypes.LICENSE);

export const getAvailable = createSelector(
  getAll,
  appUserSelectors.getCurrentUser,
  (licenses, user) => {
    const email = user && user.getIn(['node', 'email']);
    const companyVAT = user && user.getIn(['node', 'company']);

    return Map(
      licenses.filter(
        (license) =>
          license.getIn(['node', 'user']) === email ||
          license.getIn(['node', 'company']) === companyVAT,
      ),
    );
  },
);
