import { all, fork } from 'redux-saga/effects';
import articleFooterSaga from './articlefooter';
import lawSaga from './law';
import theorySaga from './theory';
import decisionSaga from './decision';
import processSaga from './process';
import adminSaga from './admin';
import searchSaga from './search';
import taxonomySaga from './taxonomy';
import signupSaga from './signup';
import profileSaga from './profile';
import adminDecisionRootSaga from './admindecision';
import appAdminHomeSaga from './adminhome';
import appFrontPageSaga from './front';
import appNewsletterPageSaga from './newsletter';

export default function* appRootSaga() {
  yield all([
    fork(articleFooterSaga),
    fork(lawSaga),
    fork(theorySaga),
    fork(decisionSaga),
    fork(processSaga),
    fork(adminSaga),
    fork(searchSaga),
    fork(taxonomySaga),
    fork(profileSaga),
    fork(taxonomySaga),
    fork(signupSaga),
    fork(adminDecisionRootSaga),
    fork(appAdminHomeSaga),
    fork(appFrontPageSaga),
    fork(appNewsletterPageSaga),
  ]);
}
