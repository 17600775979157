import { call, select, takeLatest } from 'redux-saga/effects';
import * as ActionTypes from '../../constants/ActionTypes';
import AuthConnector from '../../connectors/Auth';
import { generateApiSaga } from '../../utils/sagas';
import * as AppSelectors from '../../selectors/store/app';

const doResetPassword = generateApiSaga(
  AuthConnector,
  function* (connector, action) {
    const { username } = action.payload;
    return yield call([connector, connector.resetPassword], username);
  },
  function* (ConnectorClass) {
    const url = yield select(AppSelectors.baseAuthUrl);
    return yield new ConnectorClass(url);
  },
);

export default function* resetPassword() {
  yield takeLatest(ActionTypes.AUTH_RESET_PASSWORD_INIT, doResetPassword);
}
