import * as ActionTypes from '../../../constants/ActionTypes';
import { generateApiSaga } from '../../../utils/sagas';
import { call, select, takeEvery } from 'redux-saga/effects';
import LicenseConnector from '../../../connectors/License';
import * as authSelectors from '../../../selectors/store/auth';

const doUpdateLicense = generateApiSaga(
  LicenseConnector,
  function* (connector, action) {
    const license = action.payload.license;
    const patch = { user: `appUser/${yield select(authSelectors.getUserId)}` };
    return yield call([connector, connector.claim], license.id, patch);
  },
);

export default function* claimLicense() {
  yield takeEvery(ActionTypes.API_CLAIM_LICENSE_INIT, doUpdateLicense);
}
