import BaseConnector from './Base';

export default class SignupConnector extends BaseConnector {
  signup(request) {
    const url = this.baseApiUrl + 'signup';
    return this.post(url, request);
  }

  confirm(token) {
    const url = this.baseApiUrl + 'confirm';
    return this.post(url, { token: token });
  }
}
