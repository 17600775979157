import * as ActionTypes from '../../../constants/ActionTypes';
import { put, takeLatest } from 'redux-saga/effects';
import * as signupActions from '../../../actions/api/signup';

// {
//     "company": {
//     "companyVAT": "123"
// },
//     "consent": {
//     "active": true,
//         "consentType": "NEWS_CONSENT"
// },
//     "customer": {
//     "active": true,
//         "address": "string",
//         "email": "string",
//         "firstName": "string",
//         "id": 0,
//         "lastName": "string",
//         "phoneNumber": "string"
// },
//     "password": "string"
// }

function* doSubmitSignup(action) {
  const { company, user, password } = action.payload;
  // Create the signupRequest
  const signupRequest = {
    company: {
      companyVAT: company.vat,
      name: company.name,
    },
    customer: user,
    consent: {
      consentType: 'NEWS_CONSENT',
    },
    password: password.password,
  };
  return yield put(signupActions.requestSignup(signupRequest));
}

export default function* submitSignup() {
  yield takeLatest(ActionTypes.PAGE_SIGNUP_SUBMIT, doSubmitSignup);
}
