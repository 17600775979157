import React from 'react';
import ConditionalRoute from './ConditionalRoute';
import * as Urls from '../../constants/Urls';
import PropTypes from 'prop-types';

const NoAuthenticatedRoute = ({ component: Component, isNotLoggedIn }) => {
  return (
    <ConditionalRoute
      condition={isNotLoggedIn}
      component={Component}
      fallbackUrl={Urls.HOME_ROUTE}
    />
  );
};

NoAuthenticatedRoute.propTypes = {
  isNotLoggedIn: PropTypes.bool.isRequired,
};

export default NoAuthenticatedRoute;
