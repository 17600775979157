import { put, select, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import * as ActionTypes from '../../../constants/ActionTypes';
import * as Urls from '../../../constants/Urls';
import {
  callAndAwaitApi,
  takeLatestWhenPreloadReady,
} from '../../../utils/sagas';
import * as searchActions from '../../../actions/search';
import * as searchPageSelector from '../../../selectors/page/search';
import * as routerSelectors from '../../../selectors/store/router';
import { getResponseData } from '../../../selectors/actions';
import * as searchPageActions from '../../../actions/page/search';
import { buildSearchLocation } from '../../../utils/link';
import * as appSelector from '../../../selectors/store/app';

function* doSearch(action) {
  yield put(searchPageActions.active());

  // replace the currently active filter, if one was explicitly provided with the search action
  const savedFilter = yield select(searchPageSelector.getCurrentSearchFilter);
  if (action.payload.filter && !action.payload.filter.equals(savedFilter)) {
    yield put(searchPageActions.setFilter(action.payload.filter));
  }

  const filter = (yield select(
    searchPageSelector.getCurrentSearchFilter,
  )).copy();

  // update url to reflect current search
  filter._filter.date.uuid = undefined;
  const currentQueryParams = yield select(
    routerSelectors.getCurrentQueryParams,
  );
  if (
    !currentQueryParams['filter'] ||
    currentQueryParams['filter'] !== filter.serialize()
  ) {
    const currentTab = yield select(searchPageSelector.getCurrentTab);
    yield put(push(buildSearchLocation(currentTab, filter)));
  }

  if (!filter.toContentFilter()) {
    yield put(searchPageActions.searchAbort(filter));
  } else {
    const jurisdictionFilter = yield select(
      appSelector.getFilteredJurisdictions,
    );
    filter.jurisdiction = jurisdictionFilter.keySeq().toJS();
    const result = yield callAndAwaitApi(
      searchActions.search(filter.toContentFilter()),
    );
    if (result.success) {
      const searchResult = getResponseData(result.success);
      yield put(searchPageActions.searchSuccessful(searchResult, filter));
      yield put(searchPageActions.fetchResults());
    } else {
      const searchError = getResponseData(result.error);
      yield put(searchPageActions.searchError(searchError.error, filter));
    }
  }
}

function* redirectToSearchPage(action) {
  const currentTab = yield select(searchPageSelector.getCurrentTab);
  yield put(push(buildSearchLocation(currentTab, action.payload.filter)));
}

function* determineSearchAction(action) {
  // detect if this is a redirect to the search pages, which will rerun this saga from a different url
  const pageSegment = yield select(routerSelectors.getCurrentPageSegment);
  const isAtSearchPage = pageSegment === Urls.SEARCH_PAGE_SEGMENT;

  if (isAtSearchPage) {
    // run regular search
    yield call(doSearch, action);
  } else if (action.payload.navigateToSearchPage) {
    // treat this as a redirect, possibly with a custom filter
    yield call(redirectToSearchPage, action);
  } else {
    yield put(
      searchPageActions.searchError(
        'Search page ordered to do search while not showing search page. Ignoring.',
      ),
    );
  }
}

export default function* search(action) {
  yield takeLatestWhenPreloadReady(
    ActionTypes.PAGE_SEARCH_SEARCH_INIT,
    determineSearchAction,
  );
}
