/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { Trans } from 'react-i18next';
import { Form } from 'react-bootstrap';
import Datepicker from '../atoms/Datepicker';
import Filter from './Filter';
import Text, { TextType } from '../atoms/Text';
import Button from '../atoms/Button';
import { refBind } from '../../../utils/ref-bind';
import Input from '../atoms/Input';
import NotificationToast from '../atoms/NotificationToast';

class LicenseForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { quantity: 1, message: [], type: 'success' };
    refBind(
      this,
      'handleCompanyChange',
      'handleRoleChange',
      'handleQuantityChange',
      'handleExpirationDate',
      'createLicense',
    );
  }

  handleCompanyChange(event) {
    this.setState({ company: event });
  }

  handleRoleChange(event) {
    this.setState({ role: event });
  }

  handleQuantityChange(event) {
    this.setState({ quantity: event.target.value });
  }

  handleExpirationDate(date) {
    this.setState({ expirationDate: date });
  }

  createLicense() {
    const license = {
      company: this.state.company,
      role: this.state.role,
      expirationDate: this.state.expirationDate,
    };
    const hasUndefined = Object.values(license).some((val) => !val);
    if (hasUndefined) {
      this.setState({
        message: ['license-form.all-fields-are-required'],
        type: 'error',
      });
      return;
    }
    this.props.onLicenseCreate &&
      this.props.onLicenseCreate({ license, quantity: this.state.quantity });
  }

  render() {
    return (
      <div>
        <NotificationToast messageKeys={this.state.message} type={'error'} />
        <div className="mb-4 text-capitalize">
          <Text type={TextType.HEADER_3}>
            <Trans i18nKey={'license-form.create-license'} />
          </Text>
        </div>
        <div className="mt-3">
          <Form.Label>
            <span className="text-title-case">
              <Trans i18nKey="license-form.company" />:
            </span>
          </Form.Label>
          <Filter
            options={this.props.companies || []}
            placeholder={''}
            onChange={this.handleCompanyChange}
          />
        </div>
        <div className="mt-3">
          <Form.Label>
            <span className="text-title-case">
              <Trans i18nKey="license-form.license-type" />:
            </span>
          </Form.Label>
          <Filter
            options={this.props.licenseTypes || []}
            placeholder={''}
            onChange={this.handleRoleChange}
          />
        </div>

        <div>
          <Form.Label>
            <span className="text-title-case">
              <Trans i18nKey="license-form.quantity" />:
            </span>
          </Form.Label>
          <Input
            type={Input.FORM}
            dataType={'number'}
            value={this.state.quantity}
            onChange={this.handleQuantityChange}
          />
        </div>

        <div>
          <Form.Label>
            <span className="text-title-case">
              <Trans i18nKey="license-form.expiration-date" />:
            </span>
          </Form.Label>
          <Datepicker
            placeholderKey={''}
            disableRange
            onDateSelected={this.handleExpirationDate}
          />
        </div>

        <div>
          <Button className="w-100 mt-3" onClick={this.createLicense}>
            <span className="text-capitalize">
              <Trans i18nKey={'license-form.create-license'} />
            </span>
          </Button>
        </div>
      </div>
    );
  }
}

export default LicenseForm;
