import BaseConnector from './Base';
import * as EntityTypes from '../constants/EntityTypes';
import * as Projections from '../constants/Projections';

export default class CourtConnector extends BaseConnector {
  constructor(...args) {
    super(...args);
    this.entityType = EntityTypes.COURT;
  }

  getAll(pagesize, page) {
    return this.authGet(
      this.query()
        .withProjection(Projections.WITHID)
        .withSize(pagesize)
        .withPage(page)
        .build(),
    );
  }
}
