/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose, pure } from 'recompose';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import ContentOuterBound from '../../components/common/atoms/ContentOuterBound';
import DefaultPageLayout from '../../components/common/atoms/DefaultPageLayout';
import * as theoryPageSelector from '../../selectors/page/theory';
import * as jurisdictionApiSelector from '../../selectors/api/jurisdiction';
import * as theoryApiSelector from '../../selectors/api/theoryitem';
import * as theoryPageActions from '../../actions/page/theory';
import * as favoriteApiActions from '../../actions/api/favorite';
import { push } from 'connected-react-router/immutable';
import { goToAnchor } from 'react-scrollable-anchor';
import LayoutGrid1To2 from '../../components/common/atoms/LayoutGrid1To2';
import ArticleNode from '../../components/common/molecules/ArticleNode';
import NavigationTree from '../../components/navigationTree/NavigationTree';
import * as favoriteSelector from '../../selectors/api/favorite';
import * as entityTypes from '../../constants/EntityTypes';
import ArticleFooter from '../../components/common/molecules/ArticleFooter';
import * as urls from '../../constants/Urls';
import { buildPageLink } from '../../utils/link';
import { refBind } from '../../utils/ref-bind';
import * as htmlHelpers from '../../utils/HtmlHelpers';
import ControlledByPermission from '../shared/ControlledByPermission';
import {
  PERMISSIONS,
  THEORY_FOOTER_PERMISSIONS,
} from '../../constants/Permissions';
import FloatingButton from '../../components/common/atoms/FloatingButton';
import { floatingButtonStyle } from '../../components/common/styles/FloatingButtonStyle';
import ArticleCreator from '../../components/common/molecules/ArticleCreator';
import { createStructuredSelector } from 'reselect';
import { MOBILE_SIZE } from '../../constants/ScreenSize';
import NoMobileSupportPage from './NoMobileSupportPage';

const SmartNavigationTree = pure(withImmutablePropsToJS(NavigationTree));
const SmartArticleCreator = pure(withImmutablePropsToJS(ArticleCreator));
const SmartArticleNode = pure(withImmutablePropsToJS(ArticleNode));

class TheoryPage extends React.Component {
  constructor(props) {
    super(props);
    refBind(
      this,
      'handleNavigationClick',
      'handleNodeExpand',
      'handleNodeFavorite',
      'handleNewTheoryMode',
      'goBack',
      'goNext',
      'handleSaveArticle',
      'renderLeftColumn',
      'renderRightColumn',
    );
    this.state = { showAddTheory: false };
  }

  componentDidMount() {
    const { currentNode } = this.props;
    const currentNodeId = currentNode && currentNode.getIn(['node', 'id']);
    if (currentNodeId) {
      goToAnchor(currentNodeId);
      htmlHelpers.goToAnchorInContainerWithScroll(
        'overflow-holder',
        currentNodeId,
        140,
        'tree',
      );
    }
  }

  componentDidUpdate(prevProps) {
    const { currentNode } = this.props;
    const currentNodeId = currentNode && currentNode.getIn(['node', 'id']);
    const prevNodeId =
      prevProps.currentNode && prevProps.currentNode.getIn(['node', 'id']);

    if (currentNode && prevNodeId !== currentNodeId) {
      goToAnchor(currentNodeId);
      htmlHelpers.goToAnchorInContainerWithScroll(
        'overflow-holder',
        currentNodeId,
        140,
        'tree',
      );
    }
  }

  handleNavigationClick(node) {
    this.props.actions.push(buildPageLink(urls.THEORY_ROUTE, node.node.uri));
  }

  handleNodeExpand(node, expanded) {
    expanded && this.props.actions.getChildren(node.node.id);
  }

  handleNodeFavorite(node) {
    this.props.actions.toggleFavorite(node, this.props.favorites.toJS());
  }

  goNext() {
    const { nextNode, actions } = this.props;
    nextNode &&
      actions.push(buildPageLink(urls.THEORY_ROUTE, nextNode.get('uri')));
  }

  goBack() {
    const { previousNode, actions } = this.props;
    previousNode &&
      actions.push(buildPageLink(urls.THEORY_ROUTE, previousNode.get('uri')));
  }

  handleNewTheoryMode() {
    this.setState({ showAddTheory: !this.state.showAddTheory });
  }

  handleSaveArticle(article) {
    this.props.actions.createTheoryItem(article, this.handleNewTheoryMode);
  }

  renderLeftColumn() {
    const { currentNode, navigationTree, currentTheory, article } = this.props;
    const currentNodeEntityType =
      currentNode && currentNode.getIn(['node', 'entityType']);
    const currentTheoryTitle =
      currentTheory && currentTheory.getIn(['node', 'title']);
    return article ? (
      <ControlledByPermission
        permission={PERMISSIONS.THEORY_VIEW_HEADERS}
        render={
          <SmartNavigationTree
            entityType={currentNodeEntityType}
            tree={navigationTree}
            headline={currentTheoryTitle}
            selectedNode={currentNode}
            countPermission={PERMISSIONS.THEORY_VIEW_RELATED_COUNT}
            onNodeSelection={this.handleNavigationClick}
            onNodeExpand={this.handleNodeExpand}
            isArticle
          />
        }
      />
    ) : null;
  }

  renderRightColumn() {
    const {
      favorites,
      isLoading,
      previousNode,
      nextNode,
      article,
      currentNode,
    } = this.props;
    const isGoPreviousButtonDisabled = isLoading || !previousNode;
    const isGoNextButtonDisabled = isLoading || !nextNode;
    const previousTitle =
      !isGoPreviousButtonDisabled && previousNode.get('title');
    const nextTitle = !isGoNextButtonDisabled && nextNode.get('title');
    return article ? (
      <SmartArticleNode
        isGoPreviousButtonDisabled={isGoPreviousButtonDisabled}
        isGoNextButtonDisabled={isGoNextButtonDisabled}
        goNext={this.goNext}
        goBack={this.goBack}
        viewPermission={PERMISSIONS.THEORY_VIEW_CONTENT}
        previousTitle={previousTitle}
        nextTitle={nextTitle}
        onArticleNodeClick={this.handleNavigationClick}
        onFavoriteClick={this.handleNodeFavorite}
        articleNode={article}
        favorites={favorites}
        currentNode={currentNode}
      />
    ) : null;
  }

  render() {
    const { currentNodeParent, jurisdictions, theoryItemTypes } = this.props;
    const isMobile = window.innerWidth < MOBILE_SIZE;

    return (
      <DefaultPageLayout>
        {isMobile ? (
          <NoMobileSupportPage />
        ) : (
          <div>
            <ArticleFooter
              permissionSet={THEORY_FOOTER_PERMISSIONS}
              currentNodeParent={currentNodeParent}
            />
            <ContentOuterBound>
              <LayoutGrid1To2
                onRenderColumn1={this.renderLeftColumn}
                onRenderColumn2={this.renderRightColumn}
              />
            </ContentOuterBound>
            <ControlledByPermission
              permission={PERMISSIONS.THEORY_UPDATE_CONTENT}
              render={
                <div css={floatingButtonStyle}>
                  <FloatingButton onClick={this.handleNewTheoryMode} />
                </div>
              }
            />
            <SmartArticleCreator
              entityType={entityTypes.THEORYITEM}
              show={this.state.showAddTheory}
              title={'create-article.create-theory-item'}
              jurisdictions={jurisdictions}
              articleTypes={theoryItemTypes}
              onCancel={this.handleNewTheoryMode}
              onSave={this.handleSaveArticle}
            />
          </div>
        )}
      </DefaultPageLayout>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  currentNode: theoryPageSelector.getSelectedNode,
  currentNodeParent: theoryPageSelector.getSelectedNodesParent,
  currentTheory: theoryPageSelector.getCurrentTheory,
  navigationTree: theoryPageSelector.getNavigationTree,
  article: theoryPageSelector.getArticle,
  previousNode: theoryPageSelector.getPreviousNode,
  nextNode: theoryPageSelector.getNextNode,
  isLoading: theoryPageSelector.getIsLoading,
  favorites: favoriteSelector.getTheoryPageFavorites,
  jurisdictions: jurisdictionApiSelector.getAll,
  theoryItemTypes: theoryApiSelector.getTheoryItemTypes,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      push: push,
      getChildren: theoryPageActions.getChildren,
      toggleFavorite: favoriteApiActions.toggleFavoriteByNode,
      createTheoryItem: theoryPageActions.createTheoryItem,
    },
    dispatch,
  ),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  TheoryPage,
);
