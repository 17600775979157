/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import { push } from 'connected-react-router/immutable';
import ContentOuterBound from '../../components/common/atoms/ContentOuterBound';
import DefaultPageLayout from '../../components/common/atoms/DefaultPageLayout';
import * as decisionPageSelector from '../../selectors/page/decision';
import * as courtApiSelectors from '../../selectors/api/court';
import * as DecisionPageActions from '../../actions/page/decision';
import ArticleFooter from '../../components/common/molecules/ArticleFooter';
import Button from '../../components/common/atoms/Button';
import DecisionTable from '../../components/common/molecules/DecisionTable';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import NavigationTree from '../../components/navigationTree/NavigationTree';
import { Trans, withTranslation } from 'react-i18next';
import LayoutGrid1To2 from '../../components/common/atoms/LayoutGrid1To2';
import * as favoriteApiActions from '../../actions/api/favorite';
import * as favoriteSelector from '../../selectors/api/favorite';
import { buildPageLink } from '../../utils/link';
import * as urls from '../../constants/Urls';
import * as EntityTypes from '../../constants/EntityTypes';
import FloatingButton from '../../components/common/atoms/FloatingButton';
import DecisionEditor from '../shared/DecisionEditor';
import { refBind } from '../../utils/ref-bind';
import ControlledByPermission from '../shared/ControlledByPermission';
import {
  DECISION_FOOTER_PERMISSIONS,
  PERMISSIONS,
} from '../../constants/Permissions';
import { CREATE_DECISION_MODE } from '../../constants/CreateDecisionMode';
import { floatingButtonStyle } from '../../components/common/styles/FloatingButtonStyle';
import DecisionTableEntry from '../../components/common/molecules/DecisionTableEntry';
import LicenseRequiredView from '../../components/common/molecules/LicenseRequiredView';
import { MOBILE_SIZE } from '../../constants/ScreenSize';
import NoMobileSupportPage from './NoMobileSupportPage';

class DecisionPage extends Component {
  constructor(props) {
    super(props);
    refBind(
      this,
      'handleNavigationClick',
      'handleGetMoreClick',
      'renderDecisionList',
      'handleListClick',
      'getSelectedNodeRoot',
      'handleViewChange',
      'handleEditDecisionMode',
      'handleNewDecisionMode',
      'handlePermissionRequired',
      'renderLeftColumn',
      'renderRightColumn',
      'handleDownloadDecisionFile',
    );
    this.state = { show: false, showLicenseRequired: false };
  }

  getSelectedNodeRoot() {
    const { selectedNode } = this.props;
    return selectedNode && selectedNode.length === 1 ? selectedNode[0] : null;
  }

  handleGetMoreClick() {
    const { actions } = this.props;
    actions.getMore(true);
  }

  handleNavigationClick(obj) {
    this.props.actions.push(buildPageLink(urls.DECISION_ROUTE, obj.node.uri));
  }

  handleListClick(obj) {
    const selected = this.getSelectedNodeRoot();
    this.props.actions.push(
      buildPageLink(
        urls.DECISION_ROUTE,
        selected ? selected.node.uri : 'all',
        obj.node.id,
      ),
    );
  }

  handleFavoriteClick(node) {
    this.props.actions.toggleFavorite(node, this.props.favorites);
  }

  handleEditDecisionMode() {
    this.handleViewChange(CREATE_DECISION_MODE.EDIT);
  }

  handleNewDecisionMode() {
    this.handleViewChange(CREATE_DECISION_MODE.NEW);
  }

  handleViewChange(mode = CREATE_DECISION_MODE.NEW) {
    this.setState({ show: !this.state.show, mode });
  }

  handleDownloadDecisionFile() {
    const { actions, decisionData } = this.props;
    actions.getDecisionFileByDecisionId(decisionData.decision.id);
  }

  renderDecision() {
    const { decisionData, favorites, actions } = this.props;
    const node = decisionData.decision;
    return (
      <DecisionTableEntry
        decisionData={decisionData}
        push={actions.push}
        favorite={
          favorites[node.entityType] && favorites[node.entityType][`${node.id}`]
        }
        onFavoriteClick={() => this.handleFavoriteClick(node)}
        onHiddenClicked={this.handlePermissionRequired}
        onDownloadDecisionFile={this.handleDownloadDecisionFile}
      />
    );
  }

  handlePermissionRequired() {
    this.setState({ showLicenseRequired: !this.state.showLicenseRequired });
  }

  renderDecisionList(decisionList, courts, hasPagesLeft, pagesLeft) {
    return (
      <Fragment>
        <DecisionTable
          items={decisionList}
          allCourts={courts}
          onDecisionClick={this.handleListClick}
          onHiddenClicked={this.handlePermissionRequired}
        />
        <div className="text-center pt-3 pb-5">
          {hasPagesLeft && (
            <Button onClick={this.handleGetMoreClick}>
              <span className="mr-1">
                <Trans i18nKey="decision.get-more" />
              </span>
              <span>({pagesLeft})</span>
            </Button>
          )}
        </div>
      </Fragment>
    );
  }

  renderLeftColumn() {
    const { navigationTree } = this.props;
    return navigationTree ? (
      <NavigationTree
        entityType={EntityTypes.DECISION}
        headline={this.props.t('decision.jurisdiction')}
        tree={navigationTree}
        expandLevel={1}
        selectedNode={this.getSelectedNodeRoot()}
        onNodeSelection={this.handleNavigationClick}
      />
    ) : null;
  }

  renderRightColumn() {
    const { decisionList, decisionData, pagesLeft, hasPagesLeft, courts } =
      this.props;
    return decisionData
      ? this.renderDecision()
      : this.renderDecisionList(decisionList, courts, hasPagesLeft, pagesLeft);
  }

  render() {
    const { decisionData } = this.props;
    const { showLicenseRequired, show, mode } = this.state;
    const isMobile = window.innerWidth < MOBILE_SIZE;

    return (
      <DefaultPageLayout>
        {isMobile ? (
          <NoMobileSupportPage />
        ) : (
          <div>
            <LicenseRequiredView
              show={showLicenseRequired}
              onClose={this.handlePermissionRequired}
            />
            {decisionData && (
              <ArticleFooter permissionSet={DECISION_FOOTER_PERMISSIONS} />
            )}
            <ContentOuterBound>
              <DecisionEditor
                show={show}
                decisionId={decisionData?.decision?.id}
                mode={mode}
                onCancel={this.handleNewDecisionMode}
              />
              <LayoutGrid1To2
                onRenderColumn1={this.renderLeftColumn}
                onRenderColumn2={this.renderRightColumn}
              />
            </ContentOuterBound>
            <ControlledByPermission
              permission={PERMISSIONS.DECISION_UPDATE_CONTENT}
              render={
                <div css={floatingButtonStyle}>
                  {decisionData && (
                    <FloatingButton
                      icon={faPencilAlt}
                      offset={1}
                      onClick={this.handleEditDecisionMode}
                    />
                  )}
                  <FloatingButton onClick={this.handleNewDecisionMode} />
                </div>
              }
            />
          </div>
        )}
      </DefaultPageLayout>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  navigationTree: decisionPageSelector.getNavigationTree,
  selectedNode: decisionPageSelector.getSelectedBranch,
  courts: courtApiSelectors.getAll,
  decisionList: decisionPageSelector.getDecisionList,
  decisionData: decisionPageSelector.getSelectedDecisionAsTableEntry,
  pagesLeft: decisionPageSelector.getPagesLeft,
  hasPagesLeft: decisionPageSelector.getHasPagesLeft,
  favorites: favoriteSelector.getDecisionPageFavorites,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      push,
      pageLoad: DecisionPageActions.pageLoad,
      getMore: DecisionPageActions.getDecisions,
      toggleFavorite: favoriteApiActions.toggleFavoriteByNode,
      getDecisionFileByDecisionId:
        DecisionPageActions.getDecisionFileByDecisionId,
    },
    dispatch,
  ),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withImmutablePropsToJS,
)(withTranslation()(DecisionPage));
