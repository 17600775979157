import { takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';

function* doExpandTheoryNode(action) {
  // const uri = action.payload.uri;
}

export default function* theoryPageLoad() {
  yield takeEvery(ActionTypes.PAGE_THEORY_EXPAND_TREENODE, doExpandTheoryNode);
}
