/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose, pure } from 'recompose';
import { createStructuredSelector } from 'reselect';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import { push } from 'connected-react-router/immutable';
import { ProgressBar } from 'react-bootstrap';
import * as searchPageSelectors from '../../selectors/page/search';
import * as linkUtils from '../../utils/link';
import * as urls from '../../constants/Urls';
import NavigationTree from '../../components/navigationTree/NavigationTree';
import * as searchPageActions from '../../actions/page/search';
import FilterOverview from './FilterOverview';
import * as EntityTypes from '../../constants/EntityTypes';
import { uiColors } from '../../components/common/styles/styles';

const styles = { backgroundColor: uiColors.WHITE };

const SmartNavigationTree = pure(withImmutablePropsToJS(NavigationTree));

export const decisionFilters = new Set([
  'facetDecisionCourt',
  'facetDecisionDecisionType',
  'facetDecisionCaseType',
  'facetDecisionMeritType',
  'facetDecisionReactionType',
  'facetDecisionSuspensiveEffect',
  'facetDecisionFormalityType',
  'facetDecisionReactionFormality',
  'rangeDecisionReactionFormality',
  'searchTextDecisionJudges',
  'searchTextDecisionPlaintiff',
  'searchTextDecisionPlaintiffRepresentative',
  'searchTextDecisionDefendant',
  'searchTextDecisionDefendantRepresentative',
  'facetDecisionProcurementForm',
  'facetDecisionProcurementFormProcedure',
  'facetDecisionProcurementFormAddon',
  'rangeDecisionPosOpfIntClaim',
  'rangeDecisionPosOpfIntAwarded',
  'rangeDecisionNegKonsIntClaim',
  'rangeDecisionNegKonsIntAwarded',
  'rangeDecisionCaseCostMin',
  'rangeDecisionCaseCostMax',
]);

class DecisionSearchView extends React.Component {
  constructor(props) {
    super(props);
    this.updateFilter = this.updateFilter.bind(this);
    this.handleRedirect = this.handleRedirect.bind(this);
  }

  updateFilter(filter) {
    this.props.actions.updateSearch(filter);
  }

  handleRedirect(selectedNode) {
    if (selectedNode.node.entityType !== EntityTypes.DECISION) {
      return;
    }
    this.props.actions.push(
      linkUtils.buildPageLink(urls.DECISION_ROUTE, 'all', selectedNode.node.id),
    );
  }

  render() {
    const { decisionSummary, tree } = this.props;
    const totalItems = decisionSummary.get('totalItems');
    const loadedItems =
      decisionSummary.get('totalItems') -
      decisionSummary.get('totalItemsMissing');

    return (
      <React.Fragment>
        <ProgressBar
          animated
          max={totalItems}
          now={loadedItems}
          label={`${loadedItems}/${totalItems}`}
          className={loadedItems === totalItems ? 'loading-done' : ''}
        />
        <FilterOverview
          translationKey="decision-filter"
          filterSet={decisionFilters}
        />
        <div css={styles}>
          <SmartNavigationTree
            entityType={EntityTypes.DECISION}
            tree={tree}
            onNodeSelection={this.handleRedirect}
          />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  decisionSummary: searchPageSelectors.getLatestSearchDecisionSummary,
  tree: searchPageSelectors.getLatestSearchDecisionsTree,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      push: push,
      updateSearch: searchPageActions.updateFilter,
    },
    dispatch,
  ),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  DecisionSearchView,
);
