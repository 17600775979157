import { call, select, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import { generateApiSaga } from '../../../utils/sagas';
import SignupConnector from '../../../connectors/Signup';
import { baseAuthUrl } from '../../../selectors/store/app';

var doConfirm = generateApiSaga(
  SignupConnector,
  function* (connector, action) {
    let token = action.payload.token;
    return yield call([connector, connector.confirm], token);
  },
  function* (ConnectorClass) {
    const url = yield select(baseAuthUrl);
    return yield new ConnectorClass(url);
  },
);

export default function* confirmSignup() {
  yield takeEvery(ActionTypes.API_SIGNUP_CONFIRM_INIT, doConfirm);
}
