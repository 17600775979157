import * as Urls from '../constants/Urls';
import {
  getEntityId,
  getEntityType,
  getEntityUri,
  getNodeKey,
  getNodeSection,
} from './entities';
import * as EntityTypes from '../constants/EntityTypes';

export const getNodeLink = (entity, parents) => {
  const node = getNodeSection(entity);
  const entityType = getEntityType(node);
  const replace = {};
  switch (entityType) {
    case EntityTypes.LAWITEM: {
      let parentLaw,
        i = 0;
      do {
        parentLaw = getNodeSection(parents[i]);
        i++;
      } while (
        i < parents.length &&
        getEntityType(parentLaw) !== EntityTypes.LAW
      );
      replace[':uri?'] = getEntityUri(parentLaw);
      replace[':id?'] = getEntityUri(node);
      break;
    }
    case EntityTypes.LAWPREFACEITEM: {
      replace[':uri?'] = getNodeKey(node, 'lawUri');
      replace[':id?'] = getEntityUri(node);
      break;
    }
    case EntityTypes.PROCESS: {
      let parentLaw,
        i = 0;
      do {
        parentLaw = getNodeSection(parents[i]);
        i++;
      } while (
        i < parents.length &&
        getEntityType(parentLaw) !== EntityTypes.LAW
      );
      replace[':lawuri?'] = getEntityUri(parentLaw);
      replace[':puri?'] = getEntityUri(node);
      replace[':psuri?'] = '';
      break;
    }
    case EntityTypes.PROCESSSTEP: {
      let parentLaw,
        parentProcess,
        i = 0;
      do {
        parentLaw = getNodeSection(parents[i]);
        if (
          getEntityType(parentLaw) === EntityTypes.PROCESS &&
          !parentProcess
        ) {
          parentProcess = parentLaw;
        }
        i++;
      } while (
        i < parents.length &&
        getEntityType(parentLaw) !== EntityTypes.LAW
      );
      replace[':lawuri?'] = getEntityUri(parentLaw);
      replace[':puri?'] = getEntityUri(parentProcess);
      replace[':psuri?'] = getEntityUri(node);
      break;
    }
    case EntityTypes.LAW: {
      replace[':uri?'] = getEntityUri(node);
      replace[':id?'] = '';
      break;
    }
    case EntityTypes.DECISION: {
      replace[':uri?'] = 'all';
      replace[':id?'] = getEntityId(node);
      break;
    }
    case EntityTypes.TAXONOMY: {
      replace[':id?'] = getEntityId(node);
      break;
    }
    default: {
      replace[':uri?'] = getEntityUri(node);
    }
  }

  let link = getNodeUrl(node);
  Object.entries(replace).forEach(
    ([key, value]) => (link = link.replace(key, value)),
  );
  return link;
};
export const getEntityLink = (type) => getPageLink(getTypeUrl(type));
export const getPageLink = (pageUrl) =>
  pageUrl
    .split('/')
    .filter((l) => !l.startsWith(':'))
    .join('/');
export const buildPageLink = (pageUrl, uri = '', id = '') =>
  pageUrl.replace(':uri?', uri).replace(':id?', id);
export const customBuildPageLink = (pageUrl, uris) => {
  uris.forEach((uri) => {
    pageUrl = pageUrl.replace(`:${uri.name}?`, uri.value);
  });
  return pageUrl.split(':')[0];
};

export const buildSearchLocation = (tab, filter) => ({
  pathname: `/${Urls.SEARCH_PAGE_SEGMENT}/${tab}`,
  search: filter && !filter.isEmpty() ? `?filter=${filter.serialize()}` : '',
});

export const getNodeUrl = (node) => getTypeUrl(getEntityType(node));
export const getTypeUrl = (type) => {
  switch (type) {
    case EntityTypes.THEORY:
    case EntityTypes.THEORYITEM:
      return Urls.THEORY_ROUTE;
    case EntityTypes.LAW:
    case EntityTypes.LAWITEM:
    case EntityTypes.LAWPREFACEITEM:
      return Urls.LAW_ROUTE;
    case EntityTypes.DECISION:
      return Urls.DECISION_ROUTE;
    case EntityTypes.PROCESS:
    case EntityTypes.PROCESSSTEP:
      return Urls.PROCESS_ROUTE;
    case EntityTypes.TAXONOMY:
      return Urls.TAXONOMY_ROUTE;
    case EntityTypes.MEMBERSHIP:
      return Urls.PROFILE_MEMBERSHIP;
    default:
      return '';
  }
};
