import { createSelector } from 'reselect';

export const currentLoginType = (store) =>
  store.getIn(['app', 'auth', 'currentLoginType']);

export const currentUsername = (store) =>
  store.getIn(['app', 'auth', 'currentUsername']);

export const bearerToken = (store) =>
  store.getIn(['app', 'auth', 'bearerToken']);

export const isLoading = (store) => store.getIn(['app', 'auth', 'isLoading']);

export const isInitializingAuth = (store) =>
  store.getIn(['app', 'auth', 'isInitializingAuth']);

export const isLoggedIn = createSelector(
  currentLoginType,
  isInitializingAuth,
  (currentLoginType, isInitAuth) => currentLoginType !== null && !isInitAuth,
);

export const getUserId = (store) =>
  store.getIn(['app', 'auth', 'currentUserId']);

export const error = (store) => store.getIn(['app', 'auth', 'error']);

export const loginError = (store) =>
  error(store) && JSON.parse(error(store)).message;

export const passwordReset = (store) =>
  store.getIn(['app', 'auth', 'passwordReset']);

export const setPassword = (store) =>
  store.getIn(['app', 'auth', 'setPassword']);
