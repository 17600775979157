/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import GenericForm from './GenericForm';

class ConsentForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getForm();
    this.props.defaults &&
      Object.entries(this.props.defaults).forEach(([key, value]) => {
        if (this.state.form[key]) {
          this.state.form[key].value = value;
        }
      });
  }

  getForm() {
    return {
      form_name: 'consent',
      form: {
        consent: {
          value: '',
          type: 'checkbox',
          required: true,
        },
      },
    };
  }

  render() {
    return <GenericForm form={this.state} onChange={this.props.onChange} />;
  }
}

export default withTranslation()(ConsentForm);
