/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { Nav } from 'react-bootstrap';
import Text, { TextType } from '../atoms/Text';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import FavoriteIcon from '../atoms/FavoriteIcon';
import { uiColors } from '../styles/styles';

const style = {
  '.nav-link': {
    color: uiColors.BLACK,
    padding: 0,
    margin: 0,
  },
};

class FavoriteList extends React.Component {
  constructor(props) {
    super(props);
  }

  renderFavorite(favorite, onFavoriteClick) {
    // When toggling favourites new ones are added while old ones have their active state set to false
    if (favorite.active) {
      return (
        <div className="show-on-hover-container d-flex" key={favorite.id}>
          <Nav.Link
            as={Link}
            to={{ pathname: favorite.uri, state: favorite.uriState }}
            className="mb-1"
          >
            <Text type={TextType.LIST_LINK_BRAND}>
              {favorite.name || 'no name'}
            </Text>
          </Nav.Link>
          <div className="ml-2 show-on-hover">
            <FavoriteIcon
              favorite={favorite}
              onFavoriteClick={() =>
                onFavoriteClick && onFavoriteClick(favorite)
              }
            />
          </div>
        </div>
      );
    }
    return null;
  }

  render() {
    const { data, onFavoriteClick } = this.props;

    return (
      <Nav className="ml-auto d-flex flex-column" css={style}>
        {data &&
          Object.entries(data).map(([key, values]) => {
            return (
              <div key={key} className="d-flex flex-column mb-3">
                <Text type={TextType.HEADER_3} className="text-capitalize">
                  <Trans i18nKey={'profile.favorite.' + key.toLowerCase()} />
                </Text>
                {values &&
                  values.map((value) =>
                    this.renderFavorite(value, onFavoriteClick),
                  )}
              </div>
            );
          })}
      </Nav>
    );
  }
}

export default FavoriteList;
