import { all, fork } from 'redux-saga/effects';
import locationChange from './pageopen';
import adminHomePageLoad from './pageload';
import updateUserDetails from './updateuserdetails';

export default function* appAdminHomeSaga() {
  yield all([
    fork(locationChange),
    fork(adminHomePageLoad),
    fork(updateUserDetails),
  ]);
}
