/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment } from 'react';
import { Col, Row } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import { connect } from 'react-redux';
import { capitalize, isEqual } from 'lodash';
import { Trans, withTranslation } from 'react-i18next';
import { FiSearch } from 'react-icons/fi';
import { compose } from 'recompose';
import lawLogo from '../../../images/lawlogo.PNG';
import decisionLogo from '../../../images/deicisionlogo.PNG';
import theoryLogo from '../../../images/theorylogo.PNG';
import processLogo from '../../../images/processlogo.PNG';
import keywordLogo from '../../../images/keywords.png';
import * as EntityTypes from '../../../constants/EntityTypes';
import { fontColors, fontFamily, fontSize, uiColors } from '../styles/styles';
import ControlledByPermission from '../../../containers/shared/ControlledByPermission';
import { refBind } from '../../../utils/ref-bind';
import ArticleFooterLayout from './ArticleFooterLayout';
import * as lawSelector from '../../../selectors/api/law';
import * as processSelector from '../../../selectors/api/process';
import * as articleFooterPageSelector from '../../../selectors/page/articlefooter';
import * as articleFooterActions from '../../../actions/page/articlefooter';
import LicenseRequiredView from './LicenseRequiredView';
import HiddenText from '../atoms/HiddenText';
import { RelationshipKeys } from '../../../utils/relationhelper';
import NavigationTree from '../../navigationTree/NavigationTree';
import { createLawPrefaceItemText } from '../../../utils/LawHelpers';
import SearchFilter from '../../../utils/searchfilter';
import * as searchPageActions from '../../../actions/page/search';
import SliderToggle from '../atoms/SliderToggle';
import Text from '../atoms/Text';

const styles = (collapsed) => ({
  zIndex: 1,
  position: 'fixed !important',
  textAlign: 'center',
  width: '100%',
  left: 0,
  bottom: 0,
  marginBottom: 0,
  '.layout': {
    backgroundColor: fontColors.WHITE,
    overflow: 'hidden',
  },
  '.cursor-pointer': {
    cursor: 'pointer',
  },
  '.chosen-item-box': {
    border: `solid 1px ${uiColors.BORDER}`,
    borderLeft: 'none',
    minHeight: '4rem',
    maxHeight: '4rem',
    borderBottom: 'none',
    color: `${fontColors.BRAND}`,
    position: 'relative',
    transform: 'translateY(-4rem)',
    minWidth: '41.666667vw',
    '@media (min-width: 1280px)': {
      minWidth: 'initial',
    },
  },
  '.wrapper': {
    borderTop: `solid 1px ${uiColors.BORDER}`,
    '.item-col': {
      overflowY: collapsed ? 'none' : 'auto',
      height: 'calc(100% - 50px)',
      overscrollBehavior: 'none',
    },
  },
  '& img': {
    paddingRight: '5px',
  },
  '& .footerCount': {
    color: fontColors.BRAND,
  },
  '& .icon': {
    display: 'inline-block',
    width: '40px',
    height: '42px',
    borderRadius: '100%',
    backgroundColor: uiColors.WHITE_BLUE,
    color: uiColors.SEARCH_ICON,
    border: 'none',
    outline: 'none',
    lineHeight: '0.7',
  },
  '& .spinner': {
    color: `${fontColors.BLUE}`,
    height: '15rem',
    width: '15rem',
    fontSize: '2rem',
  },
  '.footer-table-not-collapsed': {
    height: '450px',
    '@media (min-height: 821px)': {
      height: '650px',
    },
    transition: 'height .7s ease-in-out',
  },
  '.footer-table-collapsed': {
    transition: 'height .7s ease-in-out',
  },
  '.footer-expandable': {
    bottom: '-645px',
  },
  '.footer-expanded': {
    marginTop: 0,
    position: 'sticky',
    top: 0,
    zIndex: 100,
    backgroundColor: uiColors.WHITE,
  },
  '.footer-content-row': {
    minHeight: '0px',
    height: '0px',
    transition: 'min-height .7s ease-in-out',
    '.related-item-set': {
      fontSize: '14px',
      '.related-simple-link': {
        color: fontColors.BLACK,
      },
      '.related-decisions-count, .item-title a': {
        color: fontColors.BRAND,
      },
    },
    '.current-item': {
      textDecoration: 'underline',
      fontWeight: 'bold',
    },
  },
  '.footer-content-expanded': {
    minHeight: '620px',
    transition: 'min-height .7s ease-in-out',
  },
  '.footer-content': {
    marginBottom: 'auto',
    '& .footer-item': {
      color: uiColors.BRAND,
    },
    '.navigation-tree-child': {
      fontFamily: fontFamily.LIST_LINK,
      fontSize: fontSize.LIST_LINK,
      color: uiColors.TEXT,
      a: {
        color: uiColors.BRAND,
      },
    },
  },
  '& .level-indicator': {
    display: 'inline-block',
    width: '0.6rem',
    height: '0.6rem',
    borderRadius: '50%',
  },
  '& .wrapper-overflow': {
    overflowY: 'visible',
    overflowX: 'visible',
  },
  '.first-col': {
    overflowY: collapsed ? 'none' : 'auto',
    height: '101%',
  },
  '.no-permission-item': {
    display: 'inline-block',
    height: '1.25rem',
    width: '100%',
    backgroundColor: uiColors.GREY,
    cursor: 'pointer',
  },
  '.list-item-bullet': {
    span: {
      cursor: 'default',
    },
  },
  '.children-node-wrapper.li-text.list-header-layout': {
    cursor: 'default',
  },
  '.mediumblue-color': {
    color: 'mediumblue',
  },
  '.taxonomy-items': {
    '.list-item-text': {
      textTransform: 'none',
    },
  },
  '.toggle': {
    float: 'right',
  },
  '.header-item': {
    minHeight: '42px',
  },
  '.license-notification': {
    display: 'table',
  },
});

class ArticleFooter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: true,
      isShowDialogBox: false,
    };
    refBind(
      this,
      'toggleCollapse',
      'toggleDialogBox',
      'renderContentView',
      'mockItems',
      'renderHeaderItem',
      'goToSearch',
      'renderRedThread',
      'renderNormal',
    );
  }

  toggleCollapse() {
    const { collapsed } = this.state;
    const { actions, currentNode } = this.props;
    if (collapsed) {
      const action = articleFooterActions.getDataAction(currentNode);
      actions.historyIndexing(
        action,
        currentNode && currentNode.node.entityType,
      );
    }
    this.setState({ collapsed: !collapsed });
  }

  toggleDialogBox() {
    this.toggleCollapse();
    this.setState((prevState) => ({
      isShowDialogBox: !prevState.isShowDialogBox,
    }));
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentNode, actions } = this.props;
    if (!isEqual(currentNode, prevProps.currentNode)) {
      if (!currentNode && !prevProps.collapsed) {
        this.setState({ collapsed: true });
      }
    }
    if (
      currentNode &&
      !prevState.collapsed &&
      currentNode.node?.id !== prevProps?.currentNode?.node?.id
    ) {
      const action = articleFooterActions.getDataAction(currentNode);
      actions.historyIndexing(action, currentNode.node.entityType);
    }
  }

  goToSearch(itemType, event) {
    const { actions, currentNode, allLaws, allProcesses } = this.props;
    const searchFilter = new SearchFilter();
    const relation = RelationshipKeys[currentNode.node.entityType];
    const title = ArticleFooter.getTitle(
      currentNode.node,
      allLaws,
      allProcesses,
    );
    searchFilter[relation] = [{ value: currentNode.node.id, label: title }];
    const tabName = Object.entries(RelationshipKeys).find(
      ([, value]) => value === itemType,
    )[0];
    actions.setTab(tabName);
    actions.search(searchFilter, true);
    event.stopPropagation();
  }

  static getTitle(node, allLaws, allProcesses) {
    switch (node.entityType) {
      case 'theoryitem':
        return `${node.sectionNumber} ${node.title}`;
      case 'lawitem': {
        const law = allLaws[node.lawId].node;
        return `${law.name} ${node.title}`;
      }
      case 'decision':
        return node.shortName;
      case 'processstep': {
        const process = allProcesses[node.processId].node;
        const law = allLaws[process.lawId].node;
        return `${law.name}, ${process.name}, ${node.sectionNumber} ${node.name}`;
      }
      default:
        return node.id;
    }
  }

  renderHeaderItem(logo, imageText, count, key) {
    const { currentNode } = this.props;
    const { collapsed } = this.state;
    return (
      <Row className="justify-content-between header-item p-0">
        <Col
          xs={12}
          sm={10}
          onClick={this.toggleCollapse}
          className="cursor-pointer my-auto"
        >
          <img src={logo} alt={imageText} />
          <ControlledByPermission
            permission={this.props.permissionSet?.count}
            render={
              <Fragment>
                <span className="footerCount">
                  &nbsp;
                  {`${!currentNode && collapsed ? '' : count}`}
                </span>
                &nbsp;
              </Fragment>
            }
          />
          <Trans i18nKey={`article-footer.${key}`} />
        </Col>
        {key !== RelationshipKeys.taxonomy && key !== 'red-thread' && (
          <Col xs={12} sm={2} className={'p-0'}>
            <button
              disabled={!currentNode}
              className="icon"
              onClick={(event) => this.goToSearch(key, event)}
            >
              <FiSearch />
            </button>
          </Col>
        )}
      </Row>
    );
  }

  renderRelatedList(list, selectedNode) {
    const expandLevel = selectedNode ? 0 : Infinity;
    return (
      <NavigationTree
        className="footer-content text-left"
        entityType={EntityTypes.ARTICLEFOOTER}
        tree={list}
        expandLevel={expandLevel}
        selectedNode={selectedNode}
        noHover
        linkHighlightedNodes
      />
    );
  }

  getCurrentNodeCaption() {
    // TODO - This should/could be moved to the selector. In any case, the  currentNodeParent(title) should be obtained via (redux) state instead of props.
    const {
      currentNode,
      currentNodeParent,
      lawNameRelatedToLawItem,
      showRedThread,
      actions: { toggleRedThread },
      t,
    } = this.props;
    switch (currentNode?.node?.entityType) {
      case EntityTypes.LAWPREFACEITEM:
        return `${capitalize(createLawPrefaceItemText(currentNode.node))} ${t(
          'article-footer.of',
        )} ${lawNameRelatedToLawItem}`;
      case EntityTypes.LAWITEM:
        return (
          <div>
            {`${capitalize(currentNode.node.title)} ${t(
              'article-footer.of',
            )} ${lawNameRelatedToLawItem}`}
            <SliderToggle
              value={showRedThread}
              onChange={toggleRedThread}
              className="toggle"
            >
              <Trans i18nKey="article-footer.red-thread" />
            </SliderToggle>
          </div>
        );
      case EntityTypes.THEORYITEM:
        if (currentNode.node.theoryItemType === 'SECTION') {
          return `${currentNode.node.sectionNumber} ${currentNode.node.title}`;
        }
        return currentNodeParent?.node?.title
          ? `${currentNode.node.sectionNumber}(${currentNode.node.subSectionNumber}) ${currentNodeParent.node.title}`
          : `${currentNode.node.sectionNumber}(${currentNode.node.subSectionNumber})`;
      case EntityTypes.DECISION:
        return `${currentNode.node.caseName}`;
      case EntityTypes.PROCESSSTEP:
        return `${currentNode.node.uri} - ${currentNode.node.name}`;
      case EntityTypes.TAXONOMY:
        return [
          t(`taxonomy.${currentNode.node.type}`),
          currentNode.node.description,
          currentNode.node.code,
        ]
          .filter(Boolean)
          .join(': ');
      default:
        // Should not be possible, but lint really likes to have a default...
        return currentNode?.node?.name;
    }
  }

  renderContentView() {
    const { collapsed } = this.state;
    const {
      isLoading,
      currentNode,
      relatedLawItemCount,
      lawItemTree,
      relatedTheoryItemCount,
      relatedTheoryItemTree,
      relatedDecisionTree,
      relatedDecisionCount,
      relatedProcessStepCount,
      relatedTaxonomyTree,
      relatedTaxonomyCount,
      relatedProcessStepTree,
      permissionSet = {},
    } = this.props;
    const currentEntityType = currentNode?.node?.entityType;
    return (
      <ArticleFooterLayout
        entityType={currentEntityType}
        className={`footer-content-row ${
          collapsed ? '' : 'footer-content-expanded'
        }`}
        colClassName="mb-auto item-col scroll-bar"
        collapsed={collapsed}
        isLoading={isLoading}
        useSeparator
      >
        <ControlledByPermission
          permission={permissionSet.lawView}
          fallbackFn={this.mockItems}
          fallbackArgs={{ amount: relatedLawItemCount }}
          render={this.renderRelatedList(lawItemTree)}
        />
        <ControlledByPermission
          permission={permissionSet.decisionView}
          fallbackFn={this.mockItems}
          fallbackArgs={{ amount: relatedDecisionCount }}
          render={this.renderRelatedList(relatedDecisionTree)}
        />
        <ControlledByPermission
          permission={permissionSet.theoryView}
          fallbackFn={this.mockItems}
          fallbackArgs={{ amount: relatedTheoryItemCount }}
          render={this.renderRelatedList(relatedTheoryItemTree)}
        />
        <ControlledByPermission
          permission={permissionSet.processStepView}
          fallbackFn={this.mockItems}
          fallbackArgs={{ amount: relatedProcessStepCount }}
          render={this.renderRelatedList(relatedProcessStepTree)}
        />
        <ControlledByPermission
          permission={permissionSet.taxonomyView}
          fallbackFn={this.mockItems}
          fallbackArgs={{ amount: relatedTaxonomyCount }}
          render={this.renderRelatedList(relatedTaxonomyTree)}
        />
      </ArticleFooterLayout>
    );
  }

  mockItems(data) {
    const returnData = [];
    const max = data.amount > 0 ? data.amount : 10;
    for (let i = 0; i < max; i++) {
      returnData.push(
        <HiddenText
          className="d-inline-block w-100"
          key={i}
          text="***********"
          onHiddenClicked={this.toggleDialogBox}
        />,
      );
    }
    returnData.push(
      <Text className="license-notification">
        <Trans i18nKey="article-footer.wrong-license" />
      </Text>,
    );
    return returnData;
  }

  renderRedThread() {
    const { isShowDialogBox, collapsed } = this.state;
    const {
      redThread,
      redThreadNode,
      isLoading,
      permissionSet = {},
    } = this.props;
    return (
      <div css={styles(collapsed)}>
        <div
          className={
            collapsed ? 'footer-table-collapsed' : 'footer-table-not-collapsed'
          }
        >
          <div className={`scroll-bar ${collapsed ? '' : 'wrapper-overflow'}`}>
            <LicenseRequiredView
              show={isShowDialogBox}
              onClose={this.toggleDialogBox}
            />
            <ArticleFooterLayout
              entityType="red-thread"
              onClick={this.toggleCollapse}
              className={`footer-expandable ${
                collapsed ? '' : 'footer-expanded'
              }`}
              colClassName="my-auto"
              useSeparator
              isWithCaption
              currentNodeCaption={this.getCurrentNodeCaption()}
              currentNode={redThreadNode}
            >
              <div />
              {this.renderHeaderItem(lawLogo, 'Regler', '', 'red-thread')}
              <div />
            </ArticleFooterLayout>
            <ArticleFooterLayout
              entityType="red-thread"
              className={`footer-content-row ${
                collapsed ? '' : 'footer-content-expanded'
              }`}
              colClassName="mb-auto item-col scroll-bar"
              collapsed={collapsed}
              isLoading={isLoading}
              useSeparator
            >
              <div />
              <ControlledByPermission
                permission={permissionSet.decisionViewRedThread}
                fallbackFn={this.mockItems}
                fallbackArgs={{ amount: 10 }} // TODO - Do we have an actual number for this?
                render={this.renderRelatedList(redThread, redThreadNode)}
              />
              <div />
            </ArticleFooterLayout>
          </div>
        </div>
      </div>
    );
  }

  renderNormal() {
    const { isShowDialogBox, collapsed } = this.state;
    const {
      currentNode,
      relatedLawItemCount,
      relatedTheoryItemCount,
      relatedDecisionCount,
      relatedProcessStepCount,
      relatedTaxonomyCount,
    } = this.props;
    const currentEntityType = currentNode?.node?.entityType;
    return (
      <div css={styles(collapsed)}>
        <div
          className={
            collapsed ? 'footer-table-collapsed' : 'footer-table-not-collapsed'
          }
        >
          <div className={`scroll-bar ${collapsed ? '' : 'wrapper-overflow'}`}>
            <LicenseRequiredView
              show={isShowDialogBox}
              onClose={this.toggleDialogBox}
            />
            <ArticleFooterLayout
              entityType={currentEntityType}
              onClick={this.toggleCollapse}
              className={`footer-expandable ${
                collapsed ? '' : 'footer-expanded'
              }`}
              colClassName="my-auto"
              useSeparator
              isWithCaption
              currentNodeCaption={this.getCurrentNodeCaption()}
              currentNode={currentNode}
            >
              {this.renderHeaderItem(
                lawLogo,
                'Regler',
                relatedLawItemCount,
                RelationshipKeys.lawitem,
              )}
              {this.renderHeaderItem(
                decisionLogo,
                'Praksis',
                relatedDecisionCount,
                RelationshipKeys.decision,
              )}
              {this.renderHeaderItem(
                theoryLogo,
                'Teori',
                relatedTheoryItemCount,
                RelationshipKeys.theoryitem,
              )}
              {this.renderHeaderItem(
                processLogo,
                'Processer',
                relatedProcessStepCount,
                RelationshipKeys.processstep,
              )}
              {this.renderHeaderItem(
                keywordLogo,
                'Keyword',
                relatedTaxonomyCount,
                RelationshipKeys.taxonomy,
              )}
            </ArticleFooterLayout>
            {this.renderContentView()}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { showRedThread } = this.props;
    return showRedThread ? this.renderRedThread() : this.renderNormal();
  }
}

const mapStateToProps = (state) => {
  if (articleFooterPageSelector.showRedThread(state)) {
    return {
      showRedThread: articleFooterPageSelector.showRedThread(state),
      isLoading: articleFooterPageSelector.getLoading(state),
      currentNode: articleFooterPageSelector.getSelectedNode(state),
      redThread: articleFooterPageSelector.getRedThread(state),
      redThreadNode: articleFooterPageSelector.getRedThreadNode(state),
      lawNameRelatedToLawItem:
        articleFooterPageSelector.getLawNameRelatedToLawItem(state),
    };
  } else {
    return {
      showRedThread: articleFooterPageSelector.showRedThread(state),
      isLoading: articleFooterPageSelector.getLoading(state),
      currentNode: articleFooterPageSelector.getSelectedNode(state),
      lawItemTree: articleFooterPageSelector.getLawItemTree(state),
      relatedLawItemCount:
        articleFooterPageSelector.getRelatedLawItemCount(state),
      lawNameRelatedToLawItem:
        articleFooterPageSelector.getLawNameRelatedToLawItem(state),
      relatedTheoryItemTree:
        articleFooterPageSelector.getRelatedTheoryItemTree(state),
      relatedTheoryItemCount:
        articleFooterPageSelector.getRelatedTheoryItemCount(state),
      relatedTaxonomyTree:
        articleFooterPageSelector.getRelatedTaxonomyTree(state),
      relatedTaxonomyCount:
        articleFooterPageSelector.getRelatedTaxonomyCount(state),
      relatedDecisionTree:
        articleFooterPageSelector.getRelatedDecisionTree(state),
      relatedDecisionCount:
        articleFooterPageSelector.getRelatedDecisionCount(state),
      relatedProcessStepTree:
        articleFooterPageSelector.getRelatedProcessStepTree(state),
      relatedProcessStepCount:
        articleFooterPageSelector.getRelatedProcessStepCount(state),
      allLaws:
        lawSelector.getAll(
          state,
        ) /*TODO - we should be able to find a better way to create filter names for search than this*/,
      allProcesses:
        processSelector.getAll(
          state,
        ) /*TODO - we should be able to find a better way to create filter names for search than this*/,
    };
  }
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      historyIndexing: articleFooterActions.footerStartFetching,
      search: searchPageActions.runSearch,
      setTab: searchPageActions.setTab,
      toggleRedThread: articleFooterActions.toggleRedThread,
    },
    dispatch,
  ),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withImmutablePropsToJS,
)(withTranslation()(ArticleFooter));
