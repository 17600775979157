import { takeLatestWhenPreloadReady } from '../../../utils/sagas';
import * as ActionTypes from '../../../constants/ActionTypes';
import * as searchPageActions from '../../../actions/page/search';
import { put, delay } from '@redux-saga/core/effects';

function* doBackgroundSearch(action) {
  //    yield delay(2000);
  // check that search is not already running with this filter
  //    yield put(searchPageActions.runSearch());
}

export default function* backgroundSearch(action) {
  yield takeLatestWhenPreloadReady(
    ActionTypes.PAGE_SEARCH_FILTER_UPDATE_COMPLETE,
    doBackgroundSearch,
  );
}
