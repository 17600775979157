import BaseConnector from './Base';
import * as EntityTypes from '../constants/EntityTypes';
import * as Projections from '../constants/Projections';

export default class RoleConnector extends BaseConnector {
  constructor(...args) {
    super(...args);
    this.entityType = EntityTypes.ROLE;
  }

  getAll() {
    return this.authGet(
      this.query().withProjection(Projections.WITHID).build(),
    );
  }

  updateRole(id, patch) {
    return this.authPatch(this.query().withId(id).build(), patch);
  }
}
