import { takeLatestWhenPreloadReady } from '../../../utils/sagas';
import * as ActionTypes from '../../../constants/ActionTypes';
import * as routerActionSelector from '../../../selectors/actions/router';
import { put } from '@redux-saga/core/effects';
import { getPageLink } from '../../../utils/link';
import * as Urls from '../../../constants/Urls';
import * as profilePageActions from '../../../actions/page/profile';
import { push } from 'connected-react-router/immutable';
import { PROFILE_TABS } from '../../../constants/ProfileTabs';

function* doLocationChange(action) {
  let pageSegment = routerActionSelector.getPageSegment(action);
  if (pageSegment === Urls.PROFILE_PAGE_SEGMENT) {
    const [entity, uri, ...rest] = routerActionSelector.getSplitPath(action);
    if (!uri) {
      yield put(
        push(`${getPageLink(Urls.PROFILE_ROUTE)}/${PROFILE_TABS.GENERAL}`),
      );
    }
    yield put(profilePageActions.pageLoad([uri, ...rest]));
  }
}

export default function* locationChange() {
  yield takeLatestWhenPreloadReady(
    ActionTypes.APP_ROUTER_LOCATION_CHANGE,
    doLocationChange,
  );
}
