import { callAndAwaitApi } from '../../../utils/sagas';
import { put, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import * as adminPageActions from '../../../actions/page/admin';
import * as theoryItemApiActions from '../../../actions/api/theoryitem';
import * as lawItemApiActions from '../../../actions/api/lawitem';
import * as lawPrefaceItemApiActions from '../../../actions/api/lawprefaceitem';
import * as EntityTypes from '../../../constants/EntityTypes';
import { compressApiResponse } from '../../../reducers/api';

function* doAdminPageLoad(action) {
  const node = action.payload.node;
  let result = null;
  switch (node.entityType) {
    case EntityTypes.LAWITEM:
      result = yield callAndAwaitApi(
        lawItemApiActions.getRelationById(node.id),
      );
      break;
    case EntityTypes.LAWPREFACEITEM:
      result = yield callAndAwaitApi(
        lawPrefaceItemApiActions.getRelationById(node.id),
      );
      break;
    case EntityTypes.THEORYITEM:
      result = yield callAndAwaitApi(
        theoryItemApiActions.getRelationById(node.id),
      );
      break;
    default:
      break;
  }
  if (result && result.success) {
    const entity = compressApiResponse(result.success?.payload.responseData);
    entity.entityType = node.entityType;
    yield put(adminPageActions.selectNode(entity));
  }
}

export default function* pageLoad() {
  yield takeEvery(ActionTypes.APP_PAGE_ADMIN_LOAD, doAdminPageLoad);
}
