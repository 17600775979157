import { Direction } from './Sort';

export const PAGING_DEFAULT = { page: 0, size: 20 };
export const getSortConfiguration = (property, direction = Direction.ASC) =>
  `${property},${direction}`;
export const getPaging = (
  page = PAGING_DEFAULT.page,
  size = PAGING_DEFAULT.size,
) => ({ page, size });
export const getFilter = (params = []) => params;
export const HAL_LIST_PROPERTY = '_embedded';
