import * as ActionTypes from '../constants/ActionTypes';

export const preloadCourts = () => ({
  type: ActionTypes.DATA_PRELOAD_COURTS_INIT,
});
export const preloadDecisionCaseTypes = () => ({
  type: ActionTypes.API_DECISION_CASE_TYPE_GET_ALL_INIT,
});
export const preloadDecisionTypes = () => ({
  type: ActionTypes.API_DECISION_TYPE_GET_ALL_INIT,
});
export const preLoadTheory = (theory) => ({
  type: ActionTypes.DATA_PRELOAD_THEORY_INIT,
  payload: { theory },
});
export const preloadTaxonomy = () => ({
  type: ActionTypes.DATA_PRELOAD_TAXONOMY,
});
export const patch = (node, patch) => ({
  type: ActionTypes.APP_DATA_PATCH_ENTITY_INIT,
  payload: { node, patch },
});

export const preloadCourtsCompleted = (isSuccess) => ({
  type: ActionTypes.DATA_PRELOAD_COURTS_COMPLETED,
  payload: { isSuccess },
});

export const preloadProcesses = () => ({
  type: ActionTypes.DATA_PRELOAD_PROCESS_INIT,
});

export const preloadProcessesCompleted = (isSuccess) => ({
  type: ActionTypes.DATA_PRELOAD_PROCESS_COMPLETED,
  payload: { isSuccess },
});

export const preloadTaxonomyCompleted = (isSuccess) => ({
  type: ActionTypes.DATA_PRELOAD_TAXONOMY_COMPLETED,
  payload: { isSuccess },
});

export const preloadTheoryCompleted = (isSuccess) => ({
  type: ActionTypes.DATA_PRELOAD_THEORY_COMPLETED,
  payload: { isSuccess },
});
