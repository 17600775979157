import * as TheoryItemType from '../constants/TheoryItemType';

export const mapTheoryItems = (nodes) =>
  (nodes || []).map((theoryItem) =>
    theoryItem.theoryItemType === TheoryItemType.SECTION
      ? createOption(theoryItem.title, theoryItem.id)
      : createOption(theoryItem.uri, theoryItem.id),
  );
export const mapLawItems = (nodes) =>
  (nodes || []).map((lawItem) =>
    createOption(lawItem.rawExcelLabel || lawItem.uri, lawItem.id),
  );
export const mapDecisions = (nodes) =>
  (nodes || []).map((decision) =>
    createOption(decision.editorName || decision.caseName, decision.id),
  );
export const mapKeywords = (nodes) =>
  (nodes || []).map((keyword) =>
    createOption(
      [keyword.description, keyword.code].filter(Boolean).join(': '),
      keyword.id,
    ),
  );

export const mapProcessSteps = (nodes, withProcessAndLawName) =>
  (nodes || []).map((processStep) =>
    withProcessAndLawName
      ? createOption(
          `${processStep.name} - ${processStep.processName} - ${processStep.lawName}`,
          processStep.id,
        )
      : createOption(processStep.name, processStep.id),
  );
export const mapImmutableKeywords = (nodes) =>
  (nodes || []).map((keyword) =>
    createOption(
      [keyword.getIn(['node', 'description']), keyword.getIn(['node', 'code'])]
        .filter(Boolean)
        .join(': '),
      keyword.getIn(['node', 'id']),
    ),
  );

export const removeIgnoredItems = (list, ignoredElements) => {
  return ignoredElements
    ? (list || []).filter(
        (i) => !ignoredElements.some((e) => i.value === e.value),
      )
    : list;
};

const createOption = (label, value) => ({ label, value });
