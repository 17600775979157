import * as ActionTypes from '../../constants/ActionTypes';
import { getFilter, getPaging } from '../../constants/HalProperties';

// TODO: NEW WAY, REFACTOR OLD TO USE THIS
export const getTopLevel = (
  node,
  paging = getPaging(),
  filter = getFilter(),
) => ({
  type: ActionTypes.API_THEORY_ITEM_GET_TOP_INIT,
  payload: { node, paging, filter },
});
export const getAll = () => ({
  type: ActionTypes.API_THEORY_ITEM_GET_ALL_INIT,
});
export const getBySectionNumberAndTheoryItemType = (
  sectionNumber,
  theoryItemType,
) => ({
  type: ActionTypes.API_THEORY_ITEM_GET_BY_SECTIONNUMBER_AND_TYPE_INIT,
  payload: { sectionNumber, theoryItemType },
});
export const getByUri = (uri) => ({
  type: ActionTypes.API_THEORY_ITEM_GET_ALL_BY_URI_INIT,
  payload: { uri },
});
export const getChildren = (nodeId) => ({
  type: ActionTypes.API_THEORY_ITEM_GET_ALL_CHILDREN_INIT,
  payload: { nodeId },
});
export const getByIds = (ids) => ({
  type: ActionTypes.API_THEORY_ITEM_GET_BY_IDS_INIT,
  payload: { ids },
});
export const getByIdWithParents = (id) => ({
  type: ActionTypes.API_THEORY_ITEM_GET_BY_ID_WITH_PARENTS_INIT,
  payload: { id },
});
export const deleteById = (id) => ({
  type: ActionTypes.API_THEORY_ITEM_DELETE_BY_ID_INIT,
  payload: { id },
});
export const getAllSections = (theory) => ({
  type: ActionTypes.API_THEORY_ITEM_GET_ALL_SECTIONS_INIT,
  payload: { theory },
});
export const getExpandedTreeByTheory = (theory) => ({
  type: ActionTypes.API_THEORY_ITEM_GET_EXPANDED_TREE_BY_THEORY_INIT,
  payload: { theory },
});
export const findByTitle = (title) => ({
  type: ActionTypes.API_FIND_THEORY_ITEM_BY_TITLE_INIT,
  payload: { title },
});
export const getLatest = () => ({
  type: ActionTypes.API_THEORY_ITEM_GET_LATEST_INIT,
  payload: {},
});
export const getRelationTreeById = (id) => ({
  type: ActionTypes.API_THEORY_ITEM_GET_RELATION_TREE_BY_ID_INIT,
  payload: { id },
});
export const getDefaultUri = () => ({
  type: ActionTypes.API_THEORY_ITEM_GET_DEFAULT_URI_INIT,
});
export const getRelationById = (id) => ({
  type: ActionTypes.API_THEORY_ITEM_GET_RELATION_BY_ID_INIT,
  payload: { id },
});
export const getAllTheoryItemTypes = () => ({
  type: ActionTypes.API_GET_ALL_THEORY_ITEM_TYPES_INIT,
});
export const createTheoryItem = (item) => ({
  type: ActionTypes.API_CREATE_THEORY_ITEM_INIT,
  payload: { item },
});
