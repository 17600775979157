/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';

/**
 * Takes a tokenized sequence of strings and returns a <span> where contents between angle brackets are styled differently
 * In the translation.json, surround a word or expression with angle brackets < > to denote it as a keyword
 **/
class KeywordAdder extends React.Component {
  render() {
    const { input } = this.props;

    let tokens = input.split(/(<|>)/g).filter((e) => e !== '');

    let components = [];
    for (let i = 0; i < tokens.length; i++) {
      if (tokens[i] === '<') {
        components.push(
          <span className={'keyword'} key={i}>
            {tokens[i + 1]}
          </span>,
        );
        i += 2; // Skips the word and the end bracket >
      } else {
        components.push(<span key={i}>{tokens[i]}</span>);
      }
    }

    return components;
  }
}

export default KeywordAdder;
