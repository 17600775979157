import { last } from 'lodash';
import { createSelector } from 'reselect';

export const getCurrentPath = (store) =>
  store.getIn(['router', 'location', 'pathname']);
export const getCurrentSearch = (store) =>
  store.getIn(['router', 'location', 'search']);

export const getCurrentPathParts = createSelector(getCurrentPath, (path) =>
  path.split('/').slice(1),
);

export const getCurrentPageSegment = createSelector(
  getCurrentPathParts,
  (parts) => parts[0],
);

export const getCurrentQueryParams = createSelector(
  getCurrentSearch,
  (searchText) =>
    searchText
      .replace(/^\?/, '')
      .split('&')
      .filter((s) => !!s)
      .reduce((acc, cur) => {
        let m = cur.match(/([^=]+)=([^=]+)/);
        acc[m[1]] = m[2];
        return acc;
      }, {}),
);

export const getCurrentUri = createSelector(
  getCurrentPathParts,
  (urlPathParts) => urlPathParts[1],
);

export const getCurrentId = createSelector(getCurrentPathParts, (urlPath) =>
  urlPath.length > 2 ? urlPath[2] : null,
);

export const getCurrentIdForTaxonomy = createSelector(
  getCurrentPathParts,
  getCurrentId,
  (urlPath, currentId) => (urlPath.length === 2 ? last(urlPath) : currentId),
);
