import { call, select, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import { generateApiSaga } from '../../../utils/sagas';
import processStepConnector from '../../../connectors/ProcessStep';
import * as processStepApiSelector from '../../../selectors/api/processstep';
import * as Projections from '../../../constants/Projections';
import { createTagName } from '../../../utils/filter';

const doSearch = generateApiSaga(
  processStepConnector,
  function* (connector, action) {
    const { name } = action.payload;
    const processStepData = yield select(processStepApiSelector.getSearchData);
    if (!processStepData.get(createTagName(name))) {
      return yield call(
        [connector, connector.searchByName],
        name,
        Projections.PROJECTION_SEARCH_SUGGESTION,
      );
    }
  },
);

export default function* searchByName() {
  yield takeEvery(ActionTypes.API_FIND_PROCESS_STEP_BY_NAME_INIT, doSearch);
}
