import { call, takeEvery } from 'redux-saga/effects';
import { generateApiSaga } from '../../../utils/sagas';
import DecisionConnector from '../../../connectors/Decision';
import * as ActionTypes from '../../../constants/ActionTypes';

const doGetAllByCourts = generateApiSaga(
  DecisionConnector,
  function* (connector, action) {
    const { courts, paging, sort } = action.payload;
    return courts
      ? yield call([connector, connector.getAllByCourts], courts, paging, sort)
      : yield call([connector, connector.getAll], paging, sort);
  },
);

export default function* getAllByCourt() {
  yield takeEvery(ActionTypes.API_DECISIONS_GET_INIT, doGetAllByCourts);
}
