import { call, takeEvery } from 'redux-saga/effects';
import { generateApiSaga } from '../../../utils/sagas';
import * as ActionTypes from '../../../constants/ActionTypes';
import TaxonomyConnector from '../../../connectors/Taxonomy';

const doGetAllTaxonomy = generateApiSaga(
  TaxonomyConnector,
  function* (connector, action) {
    return yield call([connector, connector.getAll], action.payload.paging);
  },
);

export default function* getAll() {
  yield takeEvery(ActionTypes.API_TAXONOMY_GET_ALL_INIT, doGetAllTaxonomy);
}
