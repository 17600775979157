/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import PropTypes from 'prop-types';
import { Trans, withTranslation } from 'react-i18next';
import Text, { TextType } from '../atoms/Text';
import { Form, Modal } from 'react-bootstrap';
import UserForm from '../molecules/UserForm';
import CompanyForm from '../molecules/CompanyForm';
import Button from '../atoms/Button';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import equal from 'fast-deep-equal';
import { refBind } from '../../../utils/ref-bind';
import UserLicenseTable, { getTableRows } from '../molecules/UserLicenseTable';
import { CREATED, UPDATED, DELETED } from '../../../constants/UpdateType';
import LicenseModal from '../molecules/LicenseModal';

class EditUserView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.userDetails.user,
      company: this.props.userDetails.company,
      licenses: this.props.userDetails.licenses?.all,
      roles: this.props.roles,
      licenseModal: {
        show: false,
        license: undefined,
      },
    };
    this.formRef = React.createRef();
    refBind(
      this,
      'onToggleActive',
      'onToggleShowLicenseModal',
      'onAcceptLicense',
      'onDeleteLicense',
      'onFormChange',
      'onSubmitForm',
    );
  }

  static propTypes = {
    userDetails: PropTypes.shape({
      user: PropTypes.object,
      company: PropTypes.object,
      licenses: PropTypes.shape({
        all: PropTypes.arrayOf(PropTypes.object),
      }),
    }),
    roles: PropTypes.arrayOf(PropTypes.object),
    licenseTypes: PropTypes.arrayOf(PropTypes.object),
    onBackNavigation: PropTypes.func,
    onSaveChanges: PropTypes.func.isRequired,
  };

  static defaultProps = {
    userDetails: {},
    roles: [],
    licenseTypes: [],
  };

  componentDidUpdate = (prevProps) => {
    if (!equal(this.props.userDetails, prevProps.userDetails)) {
      this.setState({
        user: this.props.userDetails.user,
        company: this.props.userDetails.company,
        licenses: this.props.userDetails.licenses?.all,
        roles: this.props.roles,
      });
    }
  };

  onToggleActive = () => {
    // reset changes to user, company, and licenses (except for user.active)
    const active = !this.state.user.active;
    const userDetails = {
      ...this.props.userDetails,
      user: { ...this.props.userDetails.user, active: active },
    };

    this.setState({
      user: userDetails.user,
      company: userDetails.company,
      licenses: userDetails.licenses?.all,
    });
  };

  onToggleShowLicenseModal = (row = undefined) => {
    const { licenses, licenseModal } = this.state;
    const originalLicenses = this.props.userDetails.licenses?.all;

    let updatedModalState = {
      show: !licenseModal.show,
    };

    if (updatedModalState.show) {
      let license = row?.license;

      // create new license if empty
      if (!license) {
        const newLicenseId =
          Math.min(
            ...licenses.filter((l) => l.operation === CREATED).map((l) => l.id),
            0,
          ) - 1;

        license = {
          id: newLicenseId,
          company: { id: this.state.company.id },
          user: { id: this.state.user.id },
        };
      }

      // tag license with which type of operation it is (UPDATED or CREATED)
      const isPersisted = originalLicenses.some((l) => l.id === license.id);
      const isUpdate = licenses.some((l) => l.id === license.id);
      license.operation = isPersisted && isUpdate ? UPDATED : CREATED;
      updatedModalState = { ...updatedModalState, license: license };
    }

    this.setState({ licenseModal: updatedModalState });
  };

  onAcceptLicense = (license) => {
    this.setState({
      licenses: [
        ...this.state.licenses.filter((l) => l.id !== license.id),
        license,
      ],
    });
    this.onToggleShowLicenseModal();
  };

  onDeleteLicense = (row) => {
    const license = { ...row.license };
    let licenses = this.state.licenses.filter((l) => l.id !== license.id);

    // CREATED licenses (not yet persisted) do not need to be marked DELETED
    if (license.operation !== CREATED) {
      license.operation = DELETED;
      licenses.push(license);
    }

    this.setState({ licenses: licenses });
  };

  onFormChange = (form_name, { target: { name, value } }) =>
    this.setState({ [form_name]: { ...this.state[form_name], [name]: value } });

  onSubmitForm = () => {
    if (this.formRef.current.checkValidity() !== false) {
      const { roles, licenseModal, ...userDetails } = this.state;
      this.props.onSaveChanges && this.props.onSaveChanges(userDetails);
      this.props.onBackNavigation && this.props.onBackNavigation();
    }
  };

  render() {
    const { user, company, licenses, licenseModal, roles } = this.state;
    const { userDetails, onBackNavigation } = this.props;

    const filteredLicenses = licenses.filter((l) => l.operation !== DELETED);
    const saveDisabled =
      equal(userDetails.user, user) &&
      equal(userDetails.company, company) &&
      equal(userDetails.licenses?.all, licenses);

    return (
      <Form ref={this.formRef}>
        {licenseModal.show && (
          <LicenseModal
            show={licenseModal.show}
            license={licenseModal.license}
            roles={roles}
            onAccept={this.onAcceptLicense}
            onDecline={this.onToggleShowLicenseModal}
          />
        )}
        <div className="mb-4 text-capitalize">
          <Text type={TextType.HEADER_3}>
            <Trans i18nKey={'edit-user-view.header'} />
          </Text>
        </div>
        <div className="mb-3">
          {onBackNavigation && (
            <Button
              className="d-inline-block"
              type={Button.BACK}
              onClick={onBackNavigation}
            />
          )}
          <Form.Group
            className="d-inline-block float-right"
            controlId={'is-active-id'}
            key={'is-active-key'}
          >
            <Form.Switch
              id={'is-active-switch'}
              value={user.active}
              checked={user.active}
              label={<Trans i18nKey={`edit-user-view.is-active-label`} />}
              onChange={this.onToggleActive}
            />
          </Form.Group>
        </div>
        <div className="mb-3">
          <Text type={TextType.HEADER_4}>
            <Trans i18nKey={'edit-user-view.user-header'} />
          </Text>
        </div>
        <UserForm
          user={user}
          disabled={!user.active}
          numberOfColumns={2}
          onChange={this.onFormChange}
        />
        <div className="mb-3">
          <Text type={TextType.HEADER_4}>
            <Trans i18nKey={'edit-user-view.company-header'} />
          </Text>
        </div>
        <CompanyForm
          company={company}
          disabled={!user.active}
          numberOfColumns={2}
          onChange={this.onFormChange}
        />
        <div className="mb-3 d-flex justify-content-between">
          <Text type={TextType.HEADER_4}>
            <Trans i18nKey={'edit-user-view.license-header'} />
          </Text>
          <Button
            type={Button.PRIMARY_SMALL}
            onClick={this.onToggleShowLicenseModal}
            disabled={!user.active}
          >
            <FontAwesomeIcon className={'icon'} icon={faPlus} />
          </Button>
        </div>
        <UserLicenseTable
          licenses={filteredLicenses}
          onUpdateRow={this.onToggleShowLicenseModal}
          onDeleteRow={this.onDeleteLicense}
          disabledButtons={!user.active}
        />
        <Button
          className="w-100 mt-3"
          type={Button.FORM_SUBMIT}
          onClick={this.onSubmitForm}
          disabled={saveDisabled}
        >
          <span className="text-capitalize">
            <Trans i18nKey={'edit-user-view.save-changes-label'} />
          </span>
        </Button>
      </Form>
    );
  }
}

export default withTranslation()(EditUserView);
