/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose, pure } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { Pagination, Row, Tab, Tabs } from 'react-bootstrap';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import { AiOutlineFileSearch } from 'react-icons/ai';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ContentOuterBound from '../../components/common/atoms/ContentOuterBound';
import wavesImagePath from '../../images/wave.svg';
import DefaultPageLayout from '../../components/common/atoms/DefaultPageLayout';
import { push } from 'connected-react-router/immutable';
import {
  fontColors as fontColor,
  uiColors,
} from '../../components/common/styles/styles';
import Text, { TextType } from '../../components/common/atoms/Text';
import FavoriteIcon from '../../components/common/atoms/FavoriteIcon';
import * as favoriteSelector from '../../selectors/api/favorite';
import * as taxonomyApiSelectors from '../../selectors/api/taxonomy';
import * as favoriteApiActions from '../../actions/api/favorite';
import NavigationTree from '../../components/navigationTree/NavigationTree';
import * as EntityTypes from '../../constants/EntityTypes';
import * as entityTypes from '../../constants/EntityTypes';
import LayoutGrid1To2 from '../../components/common/atoms/LayoutGrid1To2';
import { withTranslation } from 'react-i18next';
import { refBind } from '../../utils/ref-bind';
import ArticleFooter from '../../components/common/molecules/ArticleFooter';
import TaxonomyType from '../../constants/TaxonomyType';
import * as taxonomyPageSelector from '../../selectors/page/taxonomy';
import AutoSuggest from '../../components/common/molecules/AutoSuggest';
import { buildPageLink } from '../../utils/link';
import * as urls from '../../constants/Urls';
import { getIsLoading } from '../../selectors/api/taxonomy';
import {
  PERMISSIONS,
  TAXONOMY_FOOTER_PERMISSIONS,
} from '../../constants/Permissions';
import ControlledByPermission from '../shared/ControlledByPermission';
import { floatingButtonStyle } from '../../components/common/styles/FloatingButtonStyle';
import FloatingButton from '../../components/common/atoms/FloatingButton';
import CreateTaxonomy from '../shared/CreateTaxonomy';
import * as PageActions from '../../actions/page/taxonomy';
import { MOBILE_SIZE } from '../../constants/ScreenSize';
import NoMobileSupportPage from './NoMobileSupportPage';

const styles = {
  a: {
    color: fontColor.BRAND,
  },
  '& .header': {
    background: 'url(' + wavesImagePath + ') ' + uiColors.BLUE_MAGENTA,
    backgroundSize: 'cover',
    paddingTop: '200px',
  },
  '& articlebreadcrumb': {
    paddingTop: '20px',
  },
  '& li': {
    cursor: 'pointer',
  },
  '& .row': {
    margin: 0,
  },
  '& .row.scroll-bar': {
    overflowY: 'auto',
    '& .taxonomy': {
      overflowWrap: 'anywhere',
    },
  },
  '.search-icon': {
    margin: '4px 0 0 7px',
  },
  '.vh-20': {
    maxHeight: '20vh',
  },
  '.border': {
    border: `1px solid ${uiColors.DARK_GREY}`,
    borderRadius: '10px',
    '& .row:first-of-type': {
      borderBottom: `1px solid ${uiColors.BORDER}`,
    },
  },
  '.taxonomy': {
    '& .search-button': {
      '& :hover': {
        transform: 'scale(1.05)',
        color: uiColors.BRAND,
      },
    },
  },
  '.taxonomy-pagination': {
    float: 'right',
    marginTop: '1em',
    '& .page-item.active .page-link': {
      backgroundColor: uiColors.BRAND,
      borderColor: uiColors.BRAND,
    },
  },
  '.taxonomy-list': {
    columnCount: 2,
    columnGap: '50px',
    listStyle: 'circle',
    '& li:hover': {
      color: uiColors.BRAND,
    },
  },
  '.taxonomy-selected-entry': {
    marginTop: '2em',
    marginBottom: '3em',
    '& .taxonomy-selected-entry-title': {
      marginLeft: '2em',
    },
    '& .taxonomy-selected-entry-actionbar': {
      display: 'flex',
      alignItems: 'flex-end',
      paddingBottom: '5px',
      '& .search-button': {
        cursor: 'pointer',
      },
    },
    '& .taxonomy-selected-entry-bar': {
      display: 'flex',
      alignItems: 'center',
    },
    '& .taxonomy-selected-entry-lead-text': {
      marginBottom: '1em',
    },
  },
};

const SmartNavigationTree = pure(withImmutablePropsToJS(NavigationTree));
const SmartAutoSuggest = pure(withImmutablePropsToJS(AutoSuggest));
const SmartArticleFooter = pure(withImmutablePropsToJS(ArticleFooter));

function TaxonomyEntry(props) {
  const { entry, onClickEntry } = props;
  const node = (!!entry && entry.node) || {};
  const text = [node.description, node.code].filter(Boolean).join(': ');
  return (
    <li onClick={() => onClickEntry(node)}>
      <Text type={TextType.LIST_LINK_BRAND}>{text}</Text>
    </li>
  );
}

function TaxonomyList(props) {
  const { taxonomy, onClickEntry } = props;
  return (
    <ul className="taxonomy-list">
      {taxonomy.map((entry) => (
        <TaxonomyEntry
          key={`taxonomy-entry-${entry.node.id}`}
          onClickEntry={onClickEntry}
          entry={entry}
        />
      ))}
    </ul>
  );
}

const SmartTaxonomyList = pure(withImmutablePropsToJS(TaxonomyList));

function SelectedTaxonomyEntry(props) {
  let { entry, onClickToggleFavorite, onSearchClick, t, favorite } = props;
  let hasNode = !!entry && !!entry.node;
  let node = hasNode ? entry.node : {};

  const taxonomyTypeDisplay =
    node.type === TaxonomyType.KEYWORD
      ? t('taxonomy.Keyword')
      : node.type === TaxonomyType.CPV
      ? t('taxonomy.CPV')
      : t('taxonomy.Service');

  return (
    <div className="taxonomy-selected-entry">
      {hasNode ? (
        <React.Fragment>
          <div className="taxonomy-selected-entry-lead-text">
            <Text type={TextType.LIST_LINK_BRAND}>
              {t('taxonomy.selected-node')}
            </Text>
          </div>
          <div className="taxonomy-selected-entry-bar">
            <div className="taxonomy-selected-entry-actionbar">
              <ControlledByPermission
                permission={PERMISSIONS.FAVORITE}
                render={
                  <FavoriteIcon
                    favorite={favorite}
                    onFavoriteClick={() => onClickToggleFavorite(node)}
                  />
                }
              />
              <FontAwesomeIcon
                className="search-button ml-2"
                onClick={() => onSearchClick(node)}
                icon={faSearch}
              />
            </div>
            <div className="taxonomy-selected-entry-title">
              <Text type={TextType.HEADER_3}>
                {taxonomyTypeDisplay}:{' '}
                {[node.description, node.code].filter(Boolean).join(': ')}
              </Text>
            </div>
          </div>
        </React.Fragment>
      ) : (
        <Text type={TextType.LIST_LINK_BRAND}>
          {t('taxonomy.selected-node-none')}
        </Text>
      )}
    </div>
  );
}

const SmartSelectedTaxonomyEntry = pure(
  withImmutablePropsToJS(SelectedTaxonomyEntry),
);

class TaxonomyPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddTaxonomy: false,
    };
    refBind(
      this,
      'handleTaxonomyClick',
      'handleFavoriteClick',
      'handleNodeSelection',
      'onFilterValueChange',
      'handleSearchTaxonomyClick',
      'handleTabSelection',
      'handlePageNumberSelection',
      'handleNewTaxonomyMode',
      'renderLeftColumn',
      'renderRightColumn',
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const { createdItemId } = this.props;
    if (
      createdItemId !== null &&
      (prevProps.createdItemId === null ||
        prevProps.createdItemId !== createdItemId)
    ) {
      this.handleTaxonomyClick(createdItemId);
    }
  }

  componentWillUnmount() {
    this.props.actions.setFilterText('');
  }

  handleSearchTaxonomyClick(taxonomy) {
    const { actions } = this.props;
    actions.push('/search', {
      keywords: [{ value: taxonomy.id, label: taxonomy.description }],
    });
  }

  handleTaxonomyClick(taxonomy) {
    const { actions } = this.props;
    actions.push(
      buildPageLink(urls.TAXONOMY_ROUTE, null, taxonomy?.id || taxonomy),
    );
  }

  handleFavoriteClick(node) {
    this.props.actions.toggleFavorite(node, this.props.favorites.toJS());
  }

  handleTabSelection(tabName) {
    const { actions } = this.props;
    actions.setCurrentTab(tabName);
  }

  handlePageNumberSelection(pageNumber) {
    this.props.actions.setCurrentPageNumber(pageNumber);
  }

  handleNodeSelection(selectedNode) {
    const { id } = selectedNode.node;
    this.handleTabSelection(id);
  }

  onFilterValueChange(value) {
    this.props.actions.setFilterText(value);
  }

  handleNewTaxonomyMode() {
    this.setState({ showAddTaxonomy: !this.state.showAddTaxonomy });
  }

  getPaginationForCurrentTaxonomy() {
    const { filterCounts, pageSize, currentPageNumber, selectedTab } =
      this.props;
    const numPages = Math.ceil(filterCounts[selectedTab] / pageSize);
    const pageNo = Math.min(currentPageNumber, numPages);
    const paginationItems = [];
    for (let idx = 0; idx < numPages; idx++) {
      paginationItems.push(
        <Pagination.Item
          key={idx}
          active={idx === pageNo}
          onClick={() => this.handlePageNumberSelection(idx)}
        >
          {idx + 1}
        </Pagination.Item>,
      );
    }
    return <Pagination size="sm">{paginationItems}</Pagination>;
  }

  renderLeftColumn() {
    const { taxonomyTree, selectedTab } = this.props;
    let selectedCategory = taxonomyTree
      .first()
      .get('children')
      .find((child) => child.getIn(['node', 'title']) === selectedTab);
    return (
      <SmartNavigationTree
        entityType={EntityTypes.TAXONOMY}
        headline={this.props.t('taxonomy.taxonomy')}
        tree={taxonomyTree}
        expandLevel={1}
        onNodeSelection={this.handleNodeSelection}
        selectedNode={selectedCategory}
      />
    );
  }

  renderRightColumn() {
    const {
      t,
      currentNode,
      favorites,
      selectedTab,
      currentTaxonomySelection,
      filterCounts,
    } = this.props;
    const pagination = this.getPaginationForCurrentTaxonomy();
    const tabContent = (
      <SmartTaxonomyList
        onClickEntry={this.handleTaxonomyClick}
        taxonomy={currentTaxonomySelection}
      />
    );
    let currentNodeFavorite = null;
    if (currentNode) {
      const currentNodeEntityType = currentNode.getIn(['node', 'entityType']);
      const currentNodeId = currentNode.getIn(['node', 'id']);
      currentNodeFavorite =
        favorites.has(currentNodeEntityType) &&
        favorites.get(currentNodeEntityType).get(`${currentNodeId}`);
    }
    return (
      <div css={styles}>
        <Row className="my-3">
          <SmartAutoSuggest
            iconClassName="search-icon"
            placeholder="taxonomy.filter-placeholder"
            keywords={[]}
            onValueChange={this.onFilterValueChange}
            searchIconComponent={<AiOutlineFileSearch />}
          />
        </Row>
        <SmartSelectedTaxonomyEntry
          entry={currentNode}
          onClickToggleFavorite={this.handleFavoriteClick}
          favorite={currentNodeFavorite}
          onSearchClick={this.handleSearchTaxonomyClick}
          t={t}
        />
        <div className="taxonomy-pagination">{pagination}</div>
        <Tabs
          activeKey={selectedTab}
          className="flex-nowrap"
          id="taxonomy-tabs"
          onSelect={this.handleTabSelection}
        >
          <Tab
            eventKey={TaxonomyType.KEYWORD}
            title={`${t('taxonomy.Keyword')} (${
              filterCounts[TaxonomyType.KEYWORD]
            })`}
          >
            {tabContent}
          </Tab>
          <Tab
            eventKey={TaxonomyType.CPV}
            title={`${t('taxonomy.CPV')} (${filterCounts[TaxonomyType.CPV]})`}
          >
            {tabContent}
          </Tab>
          <Tab
            eventKey={TaxonomyType.SERVICE}
            title={`${t('taxonomy.Service')} (${
              filterCounts[TaxonomyType.SERVICE]
            })`}
          >
            {tabContent}
          </Tab>
        </Tabs>
      </div>
    );
  }

  render() {
    const { currentNode } = this.props;
    const { showAddTaxonomy } = this.state;
    const isMobile = window.innerWidth < MOBILE_SIZE;

    return (
      <DefaultPageLayout>
        {isMobile ? (
          <NoMobileSupportPage />
        ) : (
          <div>
            <ContentOuterBound>
              <SmartArticleFooter
                currentNode={currentNode}
                relatedPage={entityTypes.TAXONOMY}
                permissionSet={TAXONOMY_FOOTER_PERMISSIONS}
                addLevelView
              />
              <LayoutGrid1To2
                base12LeftPanelSmSize={3}
                onRenderColumn1={this.renderLeftColumn}
                onRenderColumn2={this.renderRightColumn}
              />
            </ContentOuterBound>
            <ControlledByPermission
              permission={PERMISSIONS.TAXONOMY_UPDATE_CONTENT}
              render={
                <div css={floatingButtonStyle}>
                  <FloatingButton onClick={this.handleNewTaxonomyMode} />
                </div>
              }
            />
            <CreateTaxonomy
              show={showAddTaxonomy}
              onCancel={this.handleNewTaxonomyMode}
              onSubmit={this.handleNewTaxonomyMode}
            />
          </div>
        )}
      </DefaultPageLayout>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  taxonomies: taxonomyApiSelectors.getAll,
  currentNode: taxonomyPageSelector.getCurrentNode,
  favorites: favoriteSelector.getTaxonomyPageFavorites,
  isLoadingTaxonomies: getIsLoading,
  createdItemId: taxonomyPageSelector.getCreatedItemId,
  taxonomyTree: taxonomyPageSelector.getTaxonomyNavigationTree,
  selectedTab: taxonomyPageSelector.getSelectedTab,
  currentTaxonomySelection: taxonomyPageSelector.getCurrentTaxonomySelection,
  filterCounts: taxonomyPageSelector.getCurrentFilterCounts,
  pageSize: taxonomyPageSelector.getPageSize,
  currentPageNumber: taxonomyPageSelector.getCurrentPageNumber,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      push: push,
      toggleFavorite: favoriteApiActions.toggleFavoriteByNode,
      setCurrentTab: PageActions.setCurrentTab,
      setCurrentPageNumber: PageActions.setCurrentPageNumber,
      setFilterText: PageActions.setFilterText,
    },
    dispatch,
  ),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  withTranslation()(TaxonomyPage),
);
