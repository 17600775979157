import { fromJS } from 'immutable';
import * as ActionTypes from '../../constants/ActionTypes';
import { mergeEntityListToState, mergeResponseToState } from './index';
import * as EntityTypes from '../../constants/EntityTypes';
import { getAllPagesRequestType } from '../../selectors/actions';
import { STATE_ENTITY_PROPERTY } from '../../constants/StateProperties';

const initialState = fromJS({
  [STATE_ENTITY_PROPERTY.isLoading]: false, // TODO: Move to a page reducer
  [STATE_ENTITY_PROPERTY.data]: {},
  [STATE_ENTITY_PROPERTY.page]: {},
});

export default function lawPrefaceItemApiReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.AUTH_LOGIN_COMPLETE:
    case ActionTypes.AUTH_LOGOUT_SUCCESS:
      return initialState;
    case ActionTypes.API_LAW_PREFACE_ITEM_GET_BY_IDS_REQUEST:
      return state.merge({ isLoading: true }); // TODO: Move to a page reducer
    case ActionTypes.API_LAW_PREFACE_ITEM_GET_BY_IDS_SUCCESS:
      return state.withMutations((ctx) => {
        mergeResponseToState(ctx, action, EntityTypes.LAWPREFACEITEM);
        ctx.set(STATE_ENTITY_PROPERTY.isLoading, false);
      });
    case ActionTypes.API_LAW_PREFACE_ITEM_GET_ALL_REQUEST:
      return state.merge({
        isLoading: true, // TODO: Move to a page reducer
      });
    case ActionTypes.TXN_ALL_PAGES_COMPLETE:
      if (
        getAllPagesRequestType(action) ===
        ActionTypes.API_LAW_PREFACE_ITEM_GET_ALL_INIT
      ) {
        return state.merge({
          isLoading: false, // TODO: Move to a page reducer
        });
      }
      return state;
    case ActionTypes.API_LAW_PREFACE_ITEM_GET_ALL_SUCCESS:
    case ActionTypes.API_LAW_PREFACE_ITEM_GET_BY_URI_SUCCESS:
    case ActionTypes.API_LAW_PREFACE_ITEM_GET_ALL_BY_LAW_SUCCESS:
      return mergeResponseToState(state, action, EntityTypes.LAWPREFACEITEM);
    case ActionTypes.API_GET_RELATION_TREE_LAW_PREFACE_ITEMS:
      return mergeEntityListToState(
        state,
        action.payload,
        EntityTypes.LAWPREFACEITEM,
      );
    default:
      return state;
  }
}
