import * as ActionTypes from '../../../constants/ActionTypes';
import { call, takeEvery } from 'redux-saga/effects';
import { generateApiSaga } from '../../../utils/sagas';
import AppUserConnector from '../../../connectors/AppUser';

const doSave = generateApiSaga(AppUserConnector, function* (connector, action) {
  const user = action.payload.userId;
  const consent = action.payload.consent;
  return yield call([connector, connector.updateAppUserConsent], user, consent);
});

export default function* updateConsent() {
  yield takeEvery(ActionTypes.API_USER_CONSENT_UPDATE_CONSENT_INIT, doSave);
}
