import { Promise } from 'es6-promise';
import { QueryBuilder } from '../utils/queryBuilder';

export default class BaseConnector {
  constructor(baseApiUrl, bearerToken = null) {
    if (!baseApiUrl) {
      throw new Error('Cannot instantiate constructor without base api url');
    }
    this.baseApiUrl = baseApiUrl;
    this.entityType = null;
    this.bearerToken = bearerToken;

    this.authGet = this.authGet.bind(this);
  }

  fetch(url, options = {}, addDefaultHeader = true) {
    return new Promise((resolve, reject) => {
      options.headers = options.headers || new Headers();
      if (!options.headers.has('Content-Type') && addDefaultHeader) {
        options.headers.append(
          'Content-Type',
          'application/json; charset=utf-8',
        );
      }
      fetch(url, options).then(resolve).catch(reject);
    });
  }

  post(url, object) {
    return this.fetch(url, {
      method: 'POST',
      body: JSON.stringify(object),
    });
  }

  get(url) {
    return this.fetch(url);
  }

  // decorate fetch with authentication
  authFetch(url, options = {}, addDefaultHeader = true) {
    if (this.bearerToken) {
      options.headers = options.headers || new Headers();
      options.headers.append('Authorization', 'Bearer ' + this.bearerToken);
    }
    return this.fetch(url, options, addDefaultHeader);
  }

  authGet(url) {
    return this.authFetch(url);
  }

  authPost(url, object) {
    return this.authFetch(url, {
      method: 'POST',
      body: JSON.stringify(object),
    });
  }

  authPut(url, object) {
    return this.authFetch(url, {
      method: 'PUT',
      body: JSON.stringify(object),
    });
  }

  authPatch(url, object, headers = undefined) {
    const body = typeof object === 'string' ? object : JSON.stringify(object);
    return this.authFetch(url, {
      body,
      method: 'PATCH',
      headers: headers,
    });
  }

  authDelete(url) {
    return this.authFetch(url, { method: 'DELETE' });
  }

  query(entityType = this.entityType) {
    return new QueryBuilder(this.baseApiUrl, entityType);
  }
}
