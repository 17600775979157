import ItemConnector from './Item';
import * as Searches from '../constants/Searches';
import * as EntityTypes from '../constants/EntityTypes';
import * as Projections from '../constants/Projections';
import * as Sort from '../constants/Sort';
import { getSortConfiguration } from '../constants/HalProperties';

export default class TheoryItemConnector extends ItemConnector {
  constructor(...args) {
    super(...args);
    this.entityType = EntityTypes.THEORYITEM;
  }

  getAll() {
    let query = this.query().withProjection(Projections.EXPAND).build();
    return this.authGet(query);
  }

  getTopLevel(theoryId, paging) {
    let query = this.query()
      .withSearch(Searches.findByParentTheoryItemIsNullAndTheoryItemType, [])
      .withPaging(paging)
      .withProjection(Projections.TOP)
      .build();

    return this.authGet(query);
  }

  getAllBySectionNumberAndType(sectionNumber, theoryItemType) {
    let query = this.query()
      .withSearch(Searches.findBySectionNumberAndTheoryItemType, [
        sectionNumber,
        theoryItemType,
      ])
      .withProjection(Projections.WITHID)
      .build();

    return this.authGet(query);
  }

  getByUriWithParents(uri) {
    let query = this.query()
      .withSearch(Searches.findByUri, [uri])
      .withProjection(Projections.PARENTS)
      .build();

    return this.authGet(query);
  }

  getChildren(nodeid) {
    let query = this.query()
      .withId(nodeid)
      .withProjection(Projections.EXPAND)
      .build();

    return this.authGet(query);
  }

  getByIdsWithParents(ids) {
    let query = this.query()
      .withSearch(Searches.findByIds, [ids])
      .withProjection(Projections.PARENTS)
      .withSize(1000) // TODO: HACK, Works until documents grow larger :/
      .build();

    return this.authGet(query);
  }

  getAllSections(theoryId) {
    let query = this.query()
      .withSearch(Searches.findByTheoryAndTheoryItemType, [theoryId, 'SECTION'])
      .withProjection(Projections.WITHID)
      .withSize(1000) // TODO: HACK, Works until documents grow larger :/
      .build();
    return this.authGet(query);
  }

  getNewest() {
    const url = this.query()
      .withSort(
        getSortConfiguration(Sort.updatedTimestamp, Sort.Direction.DESC),
      )
      .withSize(15)
      .build();
    return this.authGet(url);
  }

  searchByTitle(title) {
    let query = this.query()
      .withSearch(Searches.findTheoryItemByTitle, [title])
      .build();
    return this.authGet(query);
  }

  getById(id, projection) {
    let query = this.query().withId(id).withProjection(projection).build();

    return this.authGet(query);
  }

  getTheoryItemWithParentAndJurisdiction(jurisdictions) {
    let query = this.query()
      .withSearch(Searches.findTheoryItemsWithParentAndJurisdiction, [
        jurisdictions,
      ])
      .withSize(1)
      .withSort(getSortConfiguration(Sort.sectionNumber))
      .build();
    return this.authGet(query);
  }

  createItem(item) {
    return this.authPost(this.query().build(), item);
  }

  deleteById(id) {
    return this.authDelete(this.query().withId(id).build());
  }
}
