import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE } from '../constants/Paging';
import * as Projections from '../constants/Projections';

export class QueryBuilder {
  constructor(baseApiUrl, entityType) {
    this.baseApiUrl = baseApiUrl;
    this.entityType = entityType;
    this.querystring = {
      projection: Projections.STANDARD,
    };
    this.nodeId = null;
    this.resource = null;
    this.search = null;
  }

  withId(nodeId) {
    this.nodeId = nodeId;
    return this;
  }

  withLink(resource) {
    this.resource = resource;
    return this;
  }

  withSearch(method, params) {
    let { search, criteria } = method(...params);
    this.search = search;
    this.querystring = { ...this.querystring, ...criteria };
    return this;
  }

  withProjection(projection) {
    this.querystring['projection'] = projection;
    return this;
  }

  withPage(page) {
    this.querystring['page'] = page || DEFAULT_PAGE_INDEX;
    return this;
  }

  withSize(pagesize) {
    this.querystring['size'] = pagesize || DEFAULT_PAGE_SIZE;
    return this;
  }

  withPaging(paging) {
    this.querystring = { ...this.querystring, ...paging };
    return this;
  }

  withSort(sort) {
    this.querystring['sort'] = sort;
    return this;
  }

  build() {
    // guards
    if (this.nodeId !== null && this.search !== null) {
      console.error(
        'QueryBuilder configured for both resource id and search at the same time. Impossible!',
        JSON.parse(JSON.stringify(this)),
      );
      console.trace();
    }
    if (typeof this.entityType !== 'string') {
      console.error('QueryBuilder not configured with an entityType');
      console.trace();
    }

    // query composition
    let query = this.baseApiUrl + this.entityType;
    if (this.nodeId !== null) {
      query += '/' + this.nodeId;
    }
    if (this.resource !== null) {
      query += '/' + this.resource;
    }
    if (this.search !== null) {
      query += '/search/' + this.search;
    }

    let criteria = Object.entries(this.querystring)
      .filter(([key, value]) => value !== null && value !== undefined && key)
      .map(([key, value]) => key + '=' + value)
      .join('&');
    return query + (criteria ? '?' : '') + criteria;
  }
}
