import { put, debounce } from 'redux-saga/effects';
import * as ActionTypes from '../../constants/ActionTypes';

function* callAction(action) {
  yield put(action.payload.action);
}

export default function* debounceSaga() {
  yield debounce(500, ActionTypes.COMMON_DEBOUNCE_ACTION, callAction);
}
