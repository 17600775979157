import * as EntityTypes from '../../constants/EntityTypes';
import * as CommonSelectors from '../entity/common';
import { List } from 'immutable';

export const getAll = (state) =>
  CommonSelectors.getAll(state, EntityTypes.PROCESSSTEP);
export const getTree = (state) =>
  CommonSelectors.getTree(state, EntityTypes.PROCESSSTEP);
export const getById = (state, id) =>
  CommonSelectors.getEntity(state, EntityTypes.PROCESSSTEP, id);
export const getByUri = (state, uri) =>
  CommonSelectors.getByUri(state, uri, EntityTypes.PROCESSSTEP);
export const lookup = (state, fn) => getAll(state).find(fn);
export const getSearchData = (state) =>
  state.getIn(['api', EntityTypes.PROCESSSTEP, 'searchdata']);
export const getByIds = (state, ids) =>
  List.of(
    ...(ids || []).map((id) => getById(state, Number(id))).filter((v) => !!v),
  );
