/** @jsx jsx */
import { jsx } from '@emotion/core';
import { PureComponent } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  url: PropTypes.string,
};

const styles = {
  border: '0px',
};

class VimeoVideo extends PureComponent {
  render() {
    const { id } = this.props;
    return (
      <iframe
        css={styles}
        className="vimeo-container"
        // dnt disallows Vimeo to perform tracking (GDPR compliance)
        src={`https://player.vimeo.com/video/${id}?dnt=1`}
        allow="fullscreen; picture-in-picture"
        allowFullScreen
        width="100%"
        height="100%"
      />
    );
  }
}

VimeoVideo.propTypes = propTypes;

export default VimeoVideo;
