import * as ActionTypes from '../../../constants/ActionTypes';
import { call, takeEvery } from 'redux-saga/effects';
import { generateApiSaga } from '../../../utils/sagas';
import LicenseConnector from '../../../connectors/License';

const doSave = generateApiSaga(LicenseConnector, function* (connector, action) {
  const licenses = action.payload.licenses.map((license) => ({
    ...license,
    user: `appuser/${license.user.id}`,
    company: `company/${license.company.id}`,
    role: `role/${license.role.id}`,
  }));
  return yield call([connector, connector.update], licenses);
});

export default function* updateLicense() {
  yield takeEvery(ActionTypes.API_LICENSE_UPDATE_INIT, doSave);
}
