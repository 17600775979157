/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component } from 'react';
import packageJson from '../package.json';
import 'bootstrap/dist/css/bootstrap.min.css';
import WithErrors from './utils/hocs/WithErrors';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Header from './containers/common/Header';
import { Route, Switch, withRouter } from 'react-router-dom';
import Page404 from './containers/page/404';
import FrontPage from './containers/page/Front';
import LoginPage from './containers/page/Login';
import TheoryPage from './containers/page/Theory';
import LawPage from './containers/page/Law';
import DecisionPage from './containers/page/Decision';
import ProcessPage from './containers/page/Process';
import SearchPage from './containers/page/Search';
import SignupPage from './containers/page/Signup';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import * as appActions from './actions/app';
import * as Urls from './constants/Urls';
import TaxonomyPage from './containers/page/Taxonomy';
import { configureAnchors } from 'react-scrollable-anchor';
import * as authSelectors from './selectors/store/auth';
import * as appSelectors from './selectors/store/app';
import { withTranslation } from 'react-i18next';
import GlobalStyles from './containers/common/GlobalStyles';
import ProfilePage from './containers/page/Profile';
import * as Localization from './utils/localization';
import { Locale } from './constants/SupportedLocales';
import ResetPassword from './containers/page/ResetPassword';
import PermissionRoute from './containers/common/PermissionRoute';
import * as permission from './constants/PagePermissions';
import AuthenticatedRoute from './components/routing/AuthenticatedRoute';
import AdminHome from './containers/page/AdminHome';
import ForgotPassword from './containers/page/ForgotPassword';
import NotificationToast from './components/common/atoms/NotificationToast';
import NoAuthenticateRoute from './components/routing/NoAuthenticateRoute';
import ContactPage from './containers/page/Contact';
import Unsubscribe from './containers/page/Unsubscribe';
import UnsubscribeHeader from './containers/page/UnsubscribeHeader';

configureAnchors({ offset: -100, scrollDuration: 0 });
library.add(fab, faSearch);

const defaultNotificationDurationTime = {
  success: 10000,
  error: 20000,
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { showUnsubscribePage: true };
  }

  componentDidMount() {
    const { actions, location } = this.props;
    if (!location.pathname.includes(Urls.UNSUBSCRIBE_PAGE_SEGMENT)) {
      this.setState({ showUnsubscribePage: false });
      actions.initBootstrap();
    }
    Localization.setLocale(Locale.DA);
  }

  renderRouting(isLoggedIn) {
    return (
      <Switch>
        <AuthenticatedRoute
          isLoggedIn={isLoggedIn}
          exact
          path={Urls.PROFILE_ROUTE}
          component={ProfilePage}
        />
        <PermissionRoute
          isLoggedIn={isLoggedIn}
          permission={permission.SearchPermissions}
          exact
          path={Urls.SEARCH_ROUTE}
          component={SearchPage}
        />
        <PermissionRoute
          isLoggedIn={isLoggedIn}
          permission={permission.AdminPagePermissions}
          exact
          path={Urls.ADMIN_HOME_ROUTE}
          component={AdminHome}
        />
        <NoAuthenticateRoute
          isNotLoggedIn={!isLoggedIn}
          exact
          path={Urls.LOGIN_ROUTE}
          component={LoginPage}
        />
        <Route exact path={Urls.HOME_ROUTE} component={FrontPage} />
        <Route exact path={Urls.TAXONOMY_ROUTE} component={TaxonomyPage} />
        <Route exact path={Urls.LAW_ROUTE} component={LawPage} />
        <Route exact path={Urls.DECISION_ROUTE} component={DecisionPage} />
        <Route exact path={Urls.PROCESS_ROUTE} component={ProcessPage} />
        <Route exact path={Urls.THEORY_ROUTE} component={TheoryPage} />
        <Route
          exact
          path={Urls.FORGOT_PASSWORD_ROUTE}
          component={ForgotPassword}
        />
        <Route path={Urls.RESET_PASSWORD_ROUTE} component={ResetPassword} />
        <Route exact path={Urls.SIGNUP_ROUTE} component={SignupPage} />
        <Route exact path={Urls.CONTACT_ROUTE} component={ContactPage} />
        <Route exact path="*" component={Page404} />
      </Switch>
    );
  }

  renderUnsubscribeRouting() {
    return (
      <Switch>
        <Route exact path={Urls.UNSUBSCRIBE_ROUTE} component={Unsubscribe} />
      </Switch>
    );
  }

  render() {
    const { appIsReady, isLoggedIn, notificationMessage, notificationType } =
      this.props;
    const { showUnsubscribePage } = this.state;
    return (
      <div className="app" data-version={packageJson.version}>
        {showUnsubscribePage ? (
          <div>
            <UnsubscribeHeader />
            <GlobalStyles />
            <NotificationToast
              messageKeys={notificationMessage}
              type={notificationType}
              sucessDuration={defaultNotificationDurationTime.success}
              errorDuration={defaultNotificationDurationTime.error}
            />
            <div className="main-content">
              {this.renderUnsubscribeRouting()}
            </div>
          </div>
        ) : (
          <div>
            <Header />
            <NotificationToast
              messageKeys={notificationMessage}
              type={notificationType}
              sucessDuration={defaultNotificationDurationTime.success}
              errorDuration={defaultNotificationDurationTime.error}
            />
            <GlobalStyles />
            <div className="main-content">
              {appIsReady && this.renderRouting(isLoggedIn)}
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  isLoggedIn: authSelectors.isLoggedIn,
  appIsReady: appSelectors.appIsReady,
  notificationMessage: appSelectors.notificationMessage,
  notificationType: appSelectors.notificationType,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      initBootstrap: appActions.initBootstrap,
    },
    dispatch,
  ),
});

export default compose(
  WithErrors,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(withTranslation()(App));
