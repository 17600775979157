/** @jsx jsx */
import { css, Global, jsx } from '@emotion/core';
import { Component } from 'react';
import {
  fontColors,
  fontSize,
  rgbaColors,
  spacing,
  uiColors,
} from '../../components/common/styles/styles';

const cssStyles = `
.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: ${uiColors.DARK};
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: ${fontColors.BLUE};
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

:disabled {
    opacity: 0.5 !important;
    cursor: default !important;
    outline: none !important;
}

@font-face {
    font-family: 'Roboto-Regular';
    src: url('/fonts/roboto-regular.eot'); /* IE9 Compat Modes */
    src: url('/fonts/roboto-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/fonts/roboto-regular.woff2') format('woff2'), /* Modern Browsers */
    url('/fonts/roboto-regular.woff') format('woff'), /* Modern Browsers */
    url('/fonts/roboto-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/fonts/roboto-regular.svg') format('svg'); /* Legacy iOS */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-Medium';
    src: url('/fonts/roboto-medium.eot'); /* IE9 Compat Modes */
    src: url('/fonts/roboto-medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/fonts/roboto-medium.woff2') format('woff2'), /* Modern Browsers */
    url('/fonts/roboto-medium.woff') format('woff'), /* Modern Browsers */
    url('/fonts/roboto-medium.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/fonts/roboto-medium.svg') format('svg'); /* Legacy iOS */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-Light';
    src: url('/fonts/roboto-light.eot'); /* IE9 Compat Modes */
    src: url('/fonts/roboto-light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/fonts/roboto-light.woff2') format('woff2'), /* Modern Browsers */
    url('/fonts/roboto-light.woff') format('woff'), /* Modern Browsers */
    url('/fonts/roboto-light.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/fonts/roboto-light.svg') format('svg'); /* Legacy iOS */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-Bold';
    src: url('/fonts/roboto-bold.eot'); /* IE9 Compat Modes */
    src: url('/fonts/roboto-bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/fonts/roboto-bold.woff2') format('woff2'), /* Modern Browsers */
    url('/fonts/roboto-bold.woff') format('woff'), /* Modern Browsers */
    url('/fonts/roboto-bold.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/fonts/roboto-bold.svg') format('svg'); /* Legacy iOS */
    font-weight: normal;
    font-style: normal;
}

body::-webkit-scrollbar-track, .scroll-bar::-webkit-scrollbar-track, .react-autosuggest__suggestions-container--open::-webkit-scrollbar-track, .css-4ljt47-MenuList::-webkit-scrollbar-track, {
    -webkit-box-shadow: inset 0 0 6px ${rgbaColors.BLACK_SHADOW_30};
    background-color: ${uiColors.GREY};
}

.scroll-bar::-webkit-scrollbar-track, .react-autosuggest__suggestions-container--open::-webkit-scrollbar-track, .css-4ljt47-MenuList::-webkit-scrollbar-track {
    border-radius: 20px;
}

.scroll-bar::-webkit-scrollbar, .react-autosuggest__suggestions-container--open::-webkit-scrollbar, .css-4ljt47-MenuList::-webkit-scrollbar{
    width: 7px;
    height: 7px;
    border-radius: 20px;
    background-color: ${uiColors.GREY};
}

body::-webkit-scrollbar {
    width: 12px;
}

body::-webkit-scrollbar-thumb, .scroll-bar::-webkit-scrollbar-thumb, .react-autosuggest__suggestions-container--open::-webkit-scrollbar-thumb, .css-4ljt47-MenuList::-webkit-scrollbar-thumb{
    border-radius: 20px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: ${uiColors.ICON_GRAY};
}

/* for firefox */
.scroll-bar, .react-autosuggest__suggestions-container--open, .css-4ljt47-MenuList {
    scrollbar-width: thin;
    scrollbar-color: ${uiColors.ICON_GRAY} ${uiColors.GREY};
}

.color-primary {
    color: ${fontColors.BRAND}
}

.color-gray {
    color: ${fontColors.GREY}
}

.show-on-hover-by-visibility {
    visibility: hidden;
}

.show-on-hover-container:hover .show-on-hover-by-visibility,  .show-on-hover-by-visibility .favorite{
    visibility: visible;
}

.show-on-hover {
    display: none;
}

.show-on-hover-container:hover .show-on-hover {
    display: inline-block;
}

.show-on-hover-container:hover .change-color-on-hover-to-brand-color {
    color: ${fontColors.BRAND}
}

.text-title-case {
    display: inline-block;
}

.text-title-case:first-letter {
    text-transform: capitalize;
}

.modal {
    top: ${spacing.headerHeight};
    z-index: 4000;
}

.modal-xl {
    height: 80vh;
 } 

.error-message {
    height: 5px;
    fontSize: ${fontSize.PARAGRAPH_FOOTER};
    color: ${fontColors.RED};
}

.react-autosuggest__suggestions-container--open{
    max-height: 200px;
    overflow-y: auto;
}

.display-webkit-box{
    display: -webkit-box;
}

.text-with-new-line{
    margin-bottom: 12px;
    display: inline-block;
}

a, a:hover {
    color: ${uiColors.BRAND}
}
`;

class GlobalStyles extends Component {
  render() {
    return (
      <Global
        styles={css`
          ${cssStyles}
        `}
      />
    );
  }
}

export default GlobalStyles;
