import * as ActionTypes from '../../../constants/ActionTypes';
import { put, select } from 'redux-saga/effects';
import { takeLatestWhenPreloadReady } from '../../../utils/sagas';
import { getCurrentPathParts } from '../../../selectors/store/router';
import * as appSelector from '../../../selectors/store/app';
import * as EntityType from '../../../constants/EntityTypes';
import { push } from 'connected-react-router/immutable';
import { buildPageLink } from '../../../utils/link';
import { DECISION_ROUTE } from '../../../constants/Urls';

function* doDecisionUrlChange(action) {
  if (action.payload.jurisdictionFilter) {
    const [entity, court, decision] = yield select(getCurrentPathParts);
    if (entity === EntityType.DECISION) {
      const jurisdictions = yield select(appSelector.getActiveJurisdictions);
      const allJurisdictions = Object.keys(
        (yield select(appSelector.jurisdictionFilter)).toJS(),
      ).map((v) => v.toUpperCase());
      if (!decision && allJurisdictions.length > jurisdictions.length) {
        if (!court) {
          yield put(push(buildPageLink(DECISION_ROUTE, jurisdictions[0])));
        } else {
          allJurisdictions.push('ALL');
          const isJurisdiction = allJurisdictions.includes(court.toUpperCase());
          if (isJurisdiction) {
            yield put(push(buildPageLink(DECISION_ROUTE, jurisdictions[0])));
          }
        }
      }
    }
  }
}

export default function* jurisdictionListener() {
  yield takeLatestWhenPreloadReady(
    ActionTypes.APP_GLOBAL_UPDATE,
    doDecisionUrlChange,
  );
}
