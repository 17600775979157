import { combineReducers } from 'redux-immutable';
import authReducer from './auth';
import runtimeReducer from './runtime';
import initReducer from './appinit';
import notificationReducer from './notification';

export default combineReducers({
  auth: authReducer,
  runtime: runtimeReducer,
  global: initReducer,
  notification: notificationReducer,
});
