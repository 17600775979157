/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Component } from 'react';
import Table from 'react-bootstrap/Table';
import TableHead from '../atoms/TableHead';
import TableBody from '../atoms/TableBody';
import equal from 'fast-deep-equal';
import * as TableStyle from '../styles/TableStyle';

class GenericTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: this.props.rows,
    };
    this.handleSorting = this.handleSorting.bind(this);
  }

  static defaultProps = {
    rows: [],
    isSortable: false,
  };

  componentDidUpdate = (prevProps) => {
    if (!equal(this.props.rows, prevProps.rows)) {
      this.setState({ rows: this.props.rows });
    }
  };

  handleSorting = (sortField, sortOrder) => {
    if (sortField) {
      const sortedRows = [...this.state.rows].sort((a, b) => {
        if (!a[sortField] && !b[sortField]) return 0;
        return (
          (a[sortField] || '')
            .toString()
            .localeCompare((b[sortField] || '').toString(), 'da', {
              numeric: true,
            }) * (sortOrder === 'asc' ? 1 : -1)
        );
      });

      this.setState({ rows: sortedRows });
    }
  };

  render() {
    const { columns, isSortable, renderRowButton } = this.props;

    return (
      <Table css={TableStyle.styles} borderless className="generic-table">
        <TableHead
          columns={columns}
          isSortable={isSortable}
          handleSorting={this.handleSorting}
          hasExtraColumn={!!renderRowButton}
        />
        <TableBody
          columns={columns}
          rows={this.state.rows}
          renderRowButton={renderRowButton}
        />
      </Table>
    );
  }
}

export default GenericTable;
