import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import Text, { TextType } from './Text';
import { Trans } from 'react-i18next';
import { jsx } from '@emotion/core';

class TableHead extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortField: '',
      sortOrder: 'asc',
    };
    this.onChangeSorting = this.onChangeSorting.bind(this);
  }

  static propTypes = {
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        fieldAccessor: PropTypes.string,
        name: PropTypes.string,
      }),
    ),
    handleSorting: PropTypes.func,
    hasRowContent: PropTypes.bool,
  };

  static defaultProps = {
    columns: [],
    isSortable: false,
    includeExtraColumn: false,
  };

  onChangeSorting = (fieldAccessor) => {
    const sortOrder =
      this.state.sortField === fieldAccessor && this.state.sortOrder === 'asc'
        ? 'desc'
        : 'asc';
    this.setState({
      sortField: fieldAccessor,
      sortOrder: sortOrder,
    });
    this.props.handleSorting(fieldAccessor, sortOrder);
  };

  render() {
    const { columns, isSortable, hasRowContent } = this.props;

    return (
      <thead className={`table-head ${isSortable ? 'clickable' : ''}`}>
        <tr className="text-uppercase">
          {columns.map(({ key, fieldAccessor, name }) => (
            <th
              key={key}
              onClick={() => isSortable && this.onChangeSorting(fieldAccessor)}
            >
              <Text type={TextType.TABLE_HEADER}>{name}</Text>
              {this.state.sortField === fieldAccessor && (
                <div className={'sort-icon'}>
                  <FontAwesomeIcon
                    className={'icon'}
                    icon={
                      this.state.sortOrder === 'asc' ? faAngleUp : faAngleDown
                    }
                  />
                </div>
              )}
            </th>
          ))}
          {hasRowContent && <th />}
        </tr>
      </thead>
    );
  }
}

export default TableHead;
