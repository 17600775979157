import { call, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import processStepConnector from '../../../connectors/ProcessStep';
import { generateApiSaga } from '../../../utils/sagas';
import { getEntityId } from '../../../utils/entities';

var doGetProcessStepByProcess = generateApiSaga(
  processStepConnector,
  function* (connector, action) {
    let processId = getEntityId(action.payload.node);
    let { pagesize, page } = action.payload.paging;
    return yield call(
      [connector, connector.getAllByProcess],
      processId,
      pagesize,
      page,
    );
  },
);

export default function* getByProcess() {
  yield takeEvery(
    ActionTypes.API_PROCESSSTEP_GET_ALL_BY_PROCESS_INIT,
    doGetProcessStepByProcess,
  );
}
