import { call, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import Decision from '../../../connectors/Decision';
import { generateApiSaga } from '../../../utils/sagas';

const uploadFile = generateApiSaga(Decision, function* (connector, action) {
  const { decisionId, file } = action.payload;
  return yield call([connector, connector.uploadFile], decisionId, file);
});

export default function* uploadDecisionFile() {
  yield takeEvery(ActionTypes.API_UPLOAD_DECISION_FILE_INIT, uploadFile);
}
