import { put, select, take, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import * as processApiSelectors from '../../../selectors/api/process';
import * as lawsApiSelectors from '../../../selectors/api/law';
import { awaitApiCall } from '../../../utils/sagas';
import * as processApiActions from '../../../actions/api/process';
import * as processStepApiActions from '../../../actions/api/processstep';

function* doProcessPageLoad(action) {
  const [lawUri, processUri, processStepUri] = action.payload.uri;
  const laws = yield select(lawsApiSelectors.getAll);
  if (!laws || laws.size === 0) {
    yield take(ActionTypes.API_LAW_GET_ALL_SUCCESS);
  }
  let processes = yield select(processApiSelectors.getAll);
  if (!processes || processes.size === 0) {
    const retrieveProcessInitAction = yield put(processApiActions.getAll());
    yield awaitApiCall(retrieveProcessInitAction);
  }
  if (processUri) {
    const process = yield select(processApiSelectors.getByUri, processUri);
    yield put(processStepApiActions.getAllByProcessTxn(process.get('node')));
  }
}

export default function* processPageOpen() {
  yield takeEvery(ActionTypes.APP_PAGE_PROCESS_LOAD, doProcessPageLoad);
}
