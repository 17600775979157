import BaseConnector from './Base';
import * as EntityTypes from '../constants/EntityTypes';

export default class TheoryConnector extends BaseConnector {
  constructor(...args) {
    super(...args);
    this.entityType = EntityTypes.THEORY;
  }

  getAll() {
    return this.authGet(this.baseApiUrl + 'theory');
  }
}
