/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment } from 'react';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import ContentOuterBound from '../../components/common/atoms/ContentOuterBound';
import wavesImagePath from '../../images/wave.svg';
import { RiLockPasswordLine } from 'react-icons/ri';
import * as authSelectors from '../../selectors/store/auth';
import * as authActions from '../../actions/auth';
import { bindActionCreators } from 'redux';
import Text, { TextType } from '../../components/common/atoms/Text';
import {
  fontColors,
  gradientColors,
  uiColors,
} from '../../components/common/styles/styles';
import { Trans, withTranslation } from 'react-i18next';
import { push } from 'connected-react-router/immutable';
import CustomModal from '../../components/common/molecules/CustomModal';
import * as Urls from '../../constants/Urls';

const styles = {
  '& .page-main': {
    background: 'url(' + wavesImagePath + ') ' + uiColors.BLUE_MAGENTA,
    backgroundSize: 'cover',
    height: '100vh',
    color: fontColors.WHITE,
    paddingTop: '200px',
    '& .right-side': {
      '& button+button': {
        marginLeft: '1em',
      },
      '& button': {
        background: gradientColors.PURPLE,
        borderColor: uiColors.DARK,
        '&.accent': {
          background: gradientColors.ORANGE,
          borderColor: uiColors.DARK,
        },
      },
    },
    '& .left-side .reset-password-Box': {
      padding: '3em',
      paddingTop: '2em',
      paddingBottom: '1em',
      backgroundColor: fontColors.WHITE,
      color: fontColors.BLACK,
      '& form': {
        '& label': {
          textTransform: 'uppercase',
          color: fontColors.GREY,
        },
        '& Button': {
          borderColor: uiColors.DARK,
          background: gradientColors.PURPLE,
          '& svg': {
            verticalAlign: 'baseline',
          },
        },
        '& .dropdown-button': {
          width: '100%',
        },
      },
      '& hr': {
        marginLeft: '-3em',
        marginRight: '-3em',
      },
      '& .reset-passwd, & .reset-passwd a': {
        color: fontColors.GREY,
      },
    },
  },
  '& .flex-row': {
    alignItems: 'center',
    justifyContent: 'center',
  },
  '& .errorText': {
    color: fontColors.RED,
  },
};

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      repeatPassword: '',
    };

    this._onSetPassword = this._onSetPassword.bind(this);
    this._onPasswordChange = this._onPasswordChange.bind(this);
    this._onConfirmPasswordChange = this._onConfirmPasswordChange.bind(this);
    this._onSetPasswordConfirmation =
      this._onSetPasswordConfirmation.bind(this);
  }

  _onPasswordChange(event) {
    this.setState({ password: event.target.value });
  }

  _onConfirmPasswordChange(event) {
    this.setState({ repeatPassword: event.target.value });
  }

  _onSetPassword(event) {
    event.preventDefault();
    const { password, repeatPassword } = this.state;
    const pathNameSections = window.location.pathname.split('/');
    const token = pathNameSections[pathNameSections.length - 1];
    const { actions } = this.props;
    if (!password) {
      actions.setError('empty-password');
      return;
    }
    if (password !== repeatPassword) {
      actions.setError('password-mismatch');
      return;
    }
    actions.setPassword(token, password);
  }

  _onSetPasswordConfirmation(event) {
    event.preventDefault();
    const { actions } = this.props;
    actions.push(Urls.LOGIN_ROUTE);
  }

  render() {
    const { isLoading, error, setPassword, t } = this.props;
    let errorSection = error?.replace(/[[\]']/g, '');
    errorSection = errorSection?.split(', ');
    errorSection = errorSection?.map((error) => (
      <p key={error} className="errorText">
        {t(`reset-password.${error}`)}
      </p>
    ));
    return (
      <div css={styles}>
        <div className="page-main">
          <ContentOuterBound>
            <Container>
              <Row className="flex-row">
                <Col className="left-side" xs={5}>
                  <div className="reset-password-Box border rounded">
                    <CustomModal
                      acceptButtonText={t(
                        'reset-password.new-password-modal-button-text',
                      )}
                      isConfirmationOnly
                      acceptClickHandler={this._onSetPasswordConfirmation}
                      body={t(
                        'reset-password.new-password-modal-successful-message',
                      )}
                      show={setPassword}
                      title={t('reset-password.new-password-modal-title')}
                    />
                    <Text as="p" type={TextType.HEADER_2_SMALL}>
                      <Trans i18nKey={'reset-password.title'} />
                    </Text>
                    <Form>
                      <Fragment>
                        <Form.Group controlId="formBasicPassword">
                          <Form.Label>
                            <Trans i18nKey="reset-password.password-label" />
                          </Form.Label>
                          <Form.Control
                            autoComplete="new-password"
                            onChange={this._onPasswordChange}
                            type="password"
                            placeholder={t(
                              'reset-password.password-placeholder',
                            )}
                          />
                        </Form.Group>
                        <Form.Group controlId="formBasicRepeatPassword">
                          <Form.Label>
                            <Trans i18nKey="reset-password.repeat-password-label" />
                          </Form.Label>
                          <Form.Control
                            autoComplete="new-password"
                            onChange={this._onConfirmPasswordChange}
                            type="password"
                            placeholder={t(
                              'reset-password.repeat-password-placeholder',
                            )}
                          />
                        </Form.Group>
                      </Fragment>
                      <Form.Group>
                        {errorSection}
                        <Button
                          disabled={isLoading}
                          onClick={this._onSetPassword}
                          size="lg"
                          variant="primary"
                          type="submit"
                          block
                        >
                          <Fragment>
                            <RiLockPasswordLine />{' '}
                            <Trans i18nKey="reset-password.set-password-button-label" />
                          </Fragment>
                        </Button>
                      </Form.Group>
                    </Form>
                    <hr className="mt-4 mb  -4" />
                  </div>
                </Col>
              </Row>
            </Container>
          </ContentOuterBound>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  isLoading: authSelectors.isLoading,
  error: authSelectors.error,
  setPassword: authSelectors.setPassword,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setPassword: authActions.setPassword,
      setError: authActions.setError,
      push: push,
    },
    dispatch,
  ),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  withTranslation()(ResetPassword),
);
