/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment } from 'react';
import Text, { TextType } from '../atoms/Text';
import { Trans } from 'react-i18next';
import { FORGOT_PASSWORD_ROUTE } from '../../../constants/Urls';
import Button from '../atoms/Button';
import PropTypes from 'prop-types';

export default class ChangePassword extends React.Component {
  static propTypes = {
    push: PropTypes.func.isRequired,
  };

  render() {
    const { push } = this.props;
    return (
      <Fragment>
        <div className="mb-4 text-capitalize">
          <Text type={TextType.HEADER_3}>
            <Trans i18nKey={'profile.change-password'} />
          </Text>
        </div>
        <Button type={Button.LINK} onClick={() => push(FORGOT_PASSWORD_ROUTE)}>
          <Trans i18nKey={'profile.forgot-password'} />
        </Button>
      </Fragment>
    );
  }
}
