import * as ActionTypes from '../../../constants/ActionTypes';
import { generateApiSaga } from '../../../utils/sagas';
import { call, takeEvery } from 'redux-saga/effects';
import PermissionConnector from '../../../connectors/Permission';

const doGetAll = generateApiSaga(PermissionConnector, function* (connector) {
  return yield call([connector, connector.getAll]);
});

export default function* getAll() {
  yield takeEvery(ActionTypes.API_GET_ALL_PERMISSION_INIT, doGetAll);
}
