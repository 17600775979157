/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose, pure } from 'recompose';
import { createStructuredSelector } from 'reselect';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import { push } from 'connected-react-router/immutable';
import { ProgressBar } from 'react-bootstrap';
import * as linkUtils from '../../utils/link';
import * as urls from '../../constants/Urls';
import { fontColors, uiColors } from '../../components/common/styles/styles';
import * as searchPageSelectors from '../../selectors/page/search';
import * as searchPageActions from '../../actions/page/search';
import NavigationTree from '../../components/navigationTree/NavigationTree';
import * as EntityTypes from '../../constants/EntityTypes';

const styles = {
  backgroundColor: uiColors.WHITE,
  minHeight: '3rem',
  borderRadius: '3px',
  alignItems: 'center',
  margin: '0.5rem 0',

  '& .link': {
    color: fontColors.BRAND,
    ':hover': {
      textDecoration: 'none',
    },
  },
  '& .description': {
    width: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
};

const SmartNavigationTree = pure(withImmutablePropsToJS(NavigationTree));

class ProcessSearchView extends React.Component {
  constructor(props) {
    super(props);
    this.handleRedirect = this.handleRedirect.bind(this);
  }

  handleRedirect(selectedNode) {
    if (selectedNode.node.entityType !== EntityTypes.PROCESSSTEP) {
      return;
    }
    const linkParams = [
      { name: 'lawuri', value: selectedNode.node.lawUri },
      { name: 'puri', value: selectedNode.node.processUri },
      { name: 'psuri', value: selectedNode.node.uri },
    ];
    this.props.actions.push(
      linkUtils.customBuildPageLink(urls.PROCESS_ROUTE, linkParams),
    );
  }

  render() {
    const { processStepSummary, tree } = this.props;
    const totalItems = processStepSummary.get('totalItems');
    const loadedItems =
      processStepSummary.get('totalItems') -
      processStepSummary.get('totalItemsMissing');
    return (
      <React.Fragment>
        <ProgressBar
          animated
          max={totalItems}
          now={loadedItems}
          label={`${loadedItems}/${totalItems}`}
          className={loadedItems === totalItems ? 'loading-done' : ''}
        />
        <div css={styles}>
          <SmartNavigationTree
            entityType={EntityTypes.PROCESSSTEP}
            tree={tree}
            onNodeSelection={this.handleRedirect}
          />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  processStepSummary: searchPageSelectors.getLatestSearchProcessStepSummary,
  tree: searchPageSelectors.getLatestSearchProcessStepTree,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      push: push,
      updateSearch: searchPageActions.updateFilter,
    },
    dispatch,
  ),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ProcessSearchView,
);
