import { Map } from 'immutable';
import { createSelector } from 'reselect';
import * as lawApiSelectors from '../api/law';
import { getJurisdictionFilteredLaws } from '../api/law';
import * as processApiSelectors from '../api/process';
import * as processStepApiSelectors from '../api/processstep';
import { createTreeNode, findBranchInTree } from '../store/tree';
import * as routerSelectors from '../store/router';
import * as EntityTypes from '../../constants/EntityTypes';
import * as common from '../entity/common';
import { customBuildPageLink } from '../../utils/link';
import * as urls from '../../constants/Urls';

export const getProcessStepUri = (state, node) => {
  const uriArgs = [
    { name: 'lawuri', value: node.node.lawUri }, // Were gonna do lookup in api.prrocess and then api.law but apparently someone did a projection to include data
    { name: 'puri', value: node.node.processUri },
    { name: 'psuri', value: node.node.uri },
  ];
  return customBuildPageLink(urls.PROCESS_ROUTE, uriArgs);
};

export const getAll = (state) => state.getIn(['api', 'process', 'data']);
export const getCurrentProcessUri = (state) =>
  routerSelectors.getCurrentPathParts(state)[2];
export const getCurrentProcessStepUri = (state) =>
  routerSelectors.getCurrentPathParts(state)[3];
export const getCurrentLawUri = (state) => routerSelectors.getCurrentUri(state);
export const getCurrentNode = createSelector(
  lawApiSelectors.getAll,
  processApiSelectors.getAll,
  processStepApiSelectors.getAll,
  getCurrentLawUri,
  getCurrentProcessUri,
  getCurrentProcessStepUri,
  (
    allLaws,
    allProcesses,
    allProcessSteps,
    lawUri,
    processUri,
    processStepUri,
  ) => {
    if (processStepUri) {
      return findNodeByUri(allProcessSteps, processStepUri);
    }
    if (processUri) {
      return findNodeByUri(allProcesses, processUri);
    }
    return findNodeByUri(allLaws, lawUri);
  },
);

const findNodeByUri = (allItems, uri) =>
  allItems.find((n) => n.getIn(['node', 'uri']) === uri);

export const getCurrentNodeByUri = (
  state,
  lawUri,
  processUri,
  processStepUri,
) => {
  if (processStepUri) {
    return common.getByUri(state, processStepUri, EntityTypes.PROCESSSTEP);
  }
  if (processUri) {
    return common.getByUri(state, processUri, EntityTypes.PROCESS);
  }
  return common.getByUri(state, lawUri, EntityTypes.LAW);
};

// TODO: Re-select this? and also, should it be restricted to only the tree relevant for a selectedNode?
export const getNavigationTree = (state) => {
  let processes = processApiSelectors.getAll(state);

  let processesTree = processStepApiSelectors.getTree(state);
  let tree = processes.map((process) =>
    createTreeNode(
      process,
      processesTree.filter(
        (x) => x.get('node').get('processId') === process.get('id'),
      ) || Map(),
    ),
  );

  return Map().set('top', createTreeNode(Map({ id: 'top', uri: '' }), tree));
};

/**
 * Gets Navigation tree from state in format,
 * Law list with related Processes as children
 * Process List with related Process steps as gantt
 */
export const getNavigation = createSelector(
  processApiSelectors.getAll,
  getJurisdictionFilteredLaws,
  processStepApiSelectors.getAll,
  (processes, laws, processSteps) =>
    (laws || Map())
      .filter((law) =>
        processes.find(
          (process) =>
            process.get('node').get('lawId') === law.get('node').get('id'),
        ),
      )
      .map((law) =>
        createTreeNode(
          law.get('node'),
          (processes || Map())
            .filter(
              (x) => x.get('node').get('lawId') === law.get('node').get('id'),
            )
            .sortBy((process) => process.getIn(['node', 'name']))
            .map((process) => mapProcessSteps(process, processSteps))
            .toList(),
        ),
      )
      .toList(),
);

/**
 * filters out process steps that are not connected to process
 * returns process steps as node with process, gantt as gant view model, and useTemplateRef to control view tre
 */
export const mapProcessSteps = (process, processSteps) => {
  const steps = (processSteps || Map()).filter(
    (step) =>
      step.getIn(['node', 'processId']) === process.getIn(['node', 'id']),
  );
  const gantTree = mapToGantt(steps);
  return createTreeNode(process.get('node'), [])
    .set('gantt', gantTree)
    .set('useTemplateRef', gantTree.size > 0);
};

export const getNavigationNode = (state, uri) => {
  let tree = getNavigationTree(state);
  if (!uri) {
    uri = '';
  }
  return findBranchInTree(
    tree,
    (branch) => branch.getIn(['node', 'uri']) === uri,
  );
};
/**
 * converts process steps list into process step tree, sorts it by offset
 */
export const createGantTree = (step, allSteps, offsetMap) => {
  const mapper = (childId) =>
    createGantTree(
      allSteps.find((stepEntry) => stepEntry.get('node').get('id') === childId),
      allSteps,
      offsetMap,
    );
  const children = step && step.get('children').map(mapper).sort(offsetSort);
  return createGantEntry(step.get('node'), children, offsetMap);
};

/**
 * creates process dataset for gantt graph
 */
export const mapToGantt = (allSteps) => {
  allSteps = (allSteps || Map()).sort(
    (a, b) => a.get('node').get('id') - b.get('node').get('id'),
  );
  const offsetMap = createOffsetMap(allSteps);
  const rootSteps = allSteps.filter((step) => !step.get('node').get('parent'));
  return rootSteps
    .map((step) => createGantTree(step, allSteps, offsetMap))
    .sort(offsetSort)
    .toList();
};

const createGantEntry = (node, children, offsetMap) => {
  return Map()
    .set('id', node.get('id'))
    .set('node', node)
    .set('text', node.get('name'))
    .set('duration', node.get('noDays'))
    .set('offset', offsetMap[node.get('id')] || 0)
    .set('children', children);
};

/**
 * helper function to start creating offset map for each node,
 * initializing recursive function
 */
const createOffsetMap = (nodeMap) => {
  const offsetMap = {};
  nodeMap &&
    nodeMap
      .toList()
      .forEach((node) =>
        updateOffsetMap(node.get('node'), offsetMap, nodeMap.toList()),
      );
  return offsetMap;
};

/**
 * updates offset map for target node and returns offset for successor of this node
 */
const updateOffsetMap = (node, offsetMap, nodeMap) => {
  if (offsetMap[node.get('id')] === undefined) {
    if (node.get('predecessorSteps', Map()).size) {
      const values = node
        .get('predecessorSteps')
        .map((step) =>
          nodeMap.find((node) => node.get('node').get('id') === step.get('id')),
        )
        .filter((step) => step)
        .map((step) => updateOffsetMap(step.get('node'), offsetMap, nodeMap));
      offsetMap[node.get('id')] = Math.max(...values);
    } else {
      offsetMap[node.get('id')] = 0;
    }
  }
  return offsetMap[node.get('id')] + node.get('noDays');
};

const offsetSort = (stepA, stepB) => stepA.get('offset') - stepB.get('offset');

export const getRelatedLawItemsId = (state, processStepUri) =>
  processStepApiSelectors
    .getByUri(state, processStepUri)
    .getIn(['node', 'relatedLawItems']);

export function buildProcessTree(laws, processes, tree) {
  return (laws || Map())
    .map((law) =>
      createTreeNode(
        law.get('node'),
        (processes || Map())
          .filter(
            (x) => x.getIn(['node', 'lawId']) === law.getIn(['node', 'id']),
          )
          .map((process) =>
            createTreeNode(
              process.get('node'),
              tree.filter(
                (processStep) =>
                  processStep.getIn(['node', 'processId']) ===
                  process.getIn(['node', 'id']),
              ),
            ),
          )
          .toList()
          .filter((process) => process.get('children').size),
      ),
    )
    .toList()
    .filter((law) => law.get('children').size);
}
