import * as ActionTypes from '../../constants/ActionTypes';

export const updateAll = () => ({
  type: ActionTypes.API_UPDATE_RELATION_COUNT_INIT,
  payload: {},
});

export const updateEntity = (id, entityType) => ({
  type: ActionTypes.API_UPDATE_RELATION_COUNT_FOR_NODE_INIT,
  payload: { id, entityType },
});
