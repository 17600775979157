/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import ContentOuterBound from '../../components/common/atoms/ContentOuterBound';
import * as processPageSelector from '../../selectors/page/process';
import ArticleFooter from '../../components/common/molecules/ArticleFooter';
import NavigationTree from '../../components/navigationTree/NavigationTree';
import GanttChart from '../../components/gantt/GanttChart';
import DefaultPageLayout from '../../components/common/atoms/DefaultPageLayout';
import { Trans } from 'react-i18next';
import * as entityTypes from '../../constants/EntityTypes';
import * as EntityTypes from '../../constants/EntityTypes';
import {
  PERMISSIONS,
  PROCESS_FOOTER_PERMISSIONS,
} from '../../constants/Permissions';
import ControlledByPermission from '../shared/ControlledByPermission';
import NoPermission from '../../components/common/atoms/NoPermission';
import { refBind } from '../../utils/ref-bind';
import { sort } from '../../utils/sort';
import * as processApiActions from '../../actions/api/processstep';
import * as processPageActions from '../../actions/page/process';
import { MOBILE_SIZE } from '../../constants/ScreenSize';
import NoMobileSupportPage from './NoMobileSupportPage';

class ProcessPage extends React.Component {
  constructor(props) {
    super(props);
    refBind(this, 'getTemplateRef', 'onNodeExpanded');
  }

  handleExpandTreeItem = (node) => {
    const { entityType } = node.node;

    if (entityType === EntityTypes.PROCESS && node.gantt) {
      return this.props.actions.loadGantt(node.node);
    }
    if (entityType === EntityTypes.PROCESSSTEP) {
      return this.props.actions.selectNode(node);
    }
  };

  getTemplateRef(node) {
    const sortedData = sort(node.gantt, EntityTypes.PROCESSSTEP);
    return (
      <ControlledByPermission
        key={`template-key-${node.node.id}`}
        permission={PERMISSIONS.PROCESS_VIEW_CONTENT}
        fallback={<NoPermission />}
        render={
          <GanttChart
            onSelectNode={this.handleExpandTreeItem}
            data={sortedData}
            selectedItem={this.props.selectedProcessStep}
            id={node.node.id}
          />
        }
      />
    );
  }

  onNodeExpanded(selectedNode) {
    this.handleExpandTreeItem(selectedNode);
  }

  render() {
    const { currentNode, navigationTree, selectedTreeNode } = this.props;
    const viewFooter =
      currentNode && currentNode.node.entityType === entityTypes.PROCESSSTEP;
    const isMobile = window.innerWidth < MOBILE_SIZE;

    return (
      <DefaultPageLayout>
        {isMobile ? (
          <NoMobileSupportPage />
        ) : (
          <div>
            {viewFooter ? (
              <ArticleFooter permissionSet={PROCESS_FOOTER_PERMISSIONS} />
            ) : null}
            <ContentOuterBound>
              <ControlledByPermission
                permission={PERMISSIONS.PROCESS_VIEW_HEADERS}
                fallback={<NoPermission />}
                render={
                  <NavigationTree
                    entityType={EntityTypes.PROCESS}
                    headline={
                      <span className="text-capitalize">
                        <Trans i18nKey="process.navigation-tree.process" />
                      </span>
                    }
                    tree={navigationTree}
                    selectedNode={selectedTreeNode}
                    onNodeExpand={this.onNodeExpanded}
                    onTemplateRef={this.getTemplateRef}
                  />
                }
              />
            </ContentOuterBound>
          </div>
        )}
      </DefaultPageLayout>
    );
  }
}

const mapStateToProps = (state) => {
  const navigationTree = processPageSelector.getNavigation(state);
  const currentLawUri = processPageSelector.getCurrentLawUri(state);
  const currentProcessUri = processPageSelector.getCurrentProcessUri(state);
  const currentProcessStepUri =
    processPageSelector.getCurrentProcessStepUri(state);
  const currentNode = processPageSelector.getCurrentNode(state);
  const selectedTreeNode = processPageSelector.getCurrentNodeByUri(
    state,
    currentLawUri,
    currentProcessUri,
    null,
  );
  const selectedProcessStep = currentProcessStepUri && currentNode;
  return {
    currentLawUri,
    currentProcessStepUri,
    currentNode,
    navigationTree,
    selectedTreeNode,
    selectedProcessStep,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      loadGantt: processApiActions.getAllByProcessTxn,
      selectNode: processPageActions.selectNode,
    },
    dispatch,
  ),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withImmutablePropsToJS,
)(ProcessPage);
