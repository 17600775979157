import EntityFields from './EntityFields';
import * as EntityTypes from './EntityTypes';

export const createdTimeStamp = 'createdTimestamp';
export const updatedTimestamp = 'updatedTimestamp';
export const sectionNumber = 'sectionNumber';

export const Direction = Object.freeze({
  DESC: 'desc',
  ASC: 'asc',
});

export const NAMES_ALPHABETICALLY_BASED = 'namesAlphabeticallyBased';
export const COURT_OPTION_SORT_INDEX_BASED = 'courtOptionSortIndexBased';
export const DECISION_LIST = 'decisionList';

export const DECISION_SORT_ORDER = [
  'decision.table.official-name',
  'decision.table.short-name',
  'decision.table.document-date',
  'decision.table.case-opening',
  'decision.table.service',
  'decision.table.decision-type',
  'decision.table.case-type',
  'decision.table.jurisdiction',
  'decision.table.court',
  'decision.table.merit-type',
  'decision.table.reaction-type',
  'decision.table.formality-type',
  'decision.table.reaction-formality',
  'decision.table.reaction-formality-amount',
  'decision.table.suspensive-effect',
  'decision.table.editor-name',
  'decision.table.ufr-Reference',
  'decision.table.judge',
  'decision.table.complaint',
  'decision.table.complaint-representative',
  'decision.table.accused',
  'decision.table.accused-representative',
  'decision.table.procurement-form',
  'decision.table.procurement-form-procedure',
  'decision.table.procurement-form-addon',
  'decision.table.pos-opf-int-claim',
  'decision.table.pos-opf-int-awarded',
  'decision.table.neg-kons-int-claim',
  'decision.table.neg-kons-int-awarded',
  'decision.table.case-cost',
  'decision.table.eurlex-document-name',
];

export const LAW_PREFACE_ITEM_SORT_ORDER = [
  EntityFields[EntityTypes.LAWPREFACEITEM].LFFKAT,
  EntityFields[EntityTypes.LAWPREFACEITEM].LFFO1,
  EntityFields[EntityTypes.LAWPREFACEITEM].LFF02,
  EntityFields[EntityTypes.LAWPREFACEITEM].LFF03,
  EntityFields[EntityTypes.LAWPREFACEITEM].LFF04,
  EntityFields[EntityTypes.LAWPREFACEITEM].LFFPAR,
  EntityFields[EntityTypes.LAWPREFACEITEM].LFFNR,
  EntityFields[EntityTypes.LAWPREFACEITEM].LFFPKT,
];
