import { all, call, put, select, take, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../constants/ActionTypes';
import * as appActions from '../../actions/app';
import * as lawAction from '../../actions/api/law';
import * as lawGoupAction from '../../actions/api/lawgroup';
import * as theoryAction from '../../actions/api/theory';
import * as jurisdictionAction from '../../actions/api/jurisdiction';
import * as dataActions from '../../actions/data';
import * as appUserApiActions from '../../actions/api/appuser';
import * as theoryApiSelectors from '../../selectors/api/theory';
import { callAndAwaitApi } from '../../utils/sagas';
import * as authSelectors from '../../selectors/store/auth';

function* doPreload() {
  yield put(dataActions.preloadCourts());
  yield put(dataActions.preloadProcesses());
  yield put(dataActions.preloadTaxonomy());
  yield put(dataActions.preloadDecisionCaseTypes());
  yield put(dataActions.preloadDecisionTypes());
  yield put(theoryAction.getAll());
  take(ActionTypes.API_THEORY_GET_ALL_SUCCESS);
  const theory = yield select(theoryApiSelectors.getDefault);
  yield put(dataActions.preLoadTheory(theory));
  yield all([
    take(ActionTypes.DATA_PRELOAD_COURTS_COMPLETED),
    take(ActionTypes.DATA_PRELOAD_THEORY_COMPLETED),
    call(callAndAwaitApi, jurisdictionAction.getAll()),
    call(callAndAwaitApi, lawAction.getAll()),
    call(callAndAwaitApi, lawGoupAction.getAll()),
    call(callAndAwaitApi, theoryAction.getAll()),
    call(
      callAndAwaitApi,
      appUserApiActions.getById(yield select(authSelectors.getUserId)),
    ),
  ]);
  yield put(appActions.preloadCompleted());
}

export default function* preload() {
  yield takeEvery(ActionTypes.AUTH_COMPLETED, doPreload);
}
