export const LAWGROUP = 'lawgroup';
export const TAXONOMY = 'taxonomy';
export const JURISDICTION = 'jurisdiction';
export const COURT = 'court';
export const THEORY = 'theory';
export const THEORYITEM = 'theoryitem';
export const DECISION = 'decision';
export const DECISIONITEM = 'decisionitem';
export const PROCESS = 'process';
export const PROCESSSTEP = 'processstep';
export const LAW = 'law';
export const LAWITEM = 'lawitem';
export const NEWSLETTER = 'newsletter';
export const UNSUBSCRIBE = 'unsubscribe';
export const NEWSWORTHY = 'newsworthy';
export const MEMBERSHIP = 'membership';
/**
 * Fake entity type used to enable translation of top nodes of law item relationships in navigation trees
 */
export const LAW_ITEM_RELATIONSHIP = 'lawItemRelationship';
export const LAWPREFACEITEM = 'lawprefaceitem';
export const APPUSER = 'appuser';
export const APPUSERCONSENT = 'appuserconsent';
export const FAVORITE = 'favorite';
export const COMPANY = 'company';
export const ROLE = 'role';
export const LICENSE = 'license';
export const PERMISSION = 'permission';
export const ARTICLEFOOTER = 'articlefooter';
