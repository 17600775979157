import React from 'react';

export const goToAnchorInContainerWithScroll = (
  containerClassName,
  anchorId,
  headerOffset,
  preId,
) => {
  const overflow = document.querySelector(`.${containerClassName}`);
  const targetId = preId ? `${preId}-${anchorId}` : anchorId;
  const anchor = document.getElementById(targetId);
  if (overflow && anchor) {
    const rectAnchor = anchor.getBoundingClientRect();
    const yAxis =
      rectAnchor.top > overflow.scrollTop
        ? rectAnchor.top - overflow.scrollTop - headerOffset
        : rectAnchor.top + overflow.scrollTop - headerOffset;
    overflow.scrollTo(0, yAxis);
  }
};

export const newLineText = (
  text,
  Tag = 'p',
  className = 'text-with-new-line',
) =>
  text?.split('\n').map((str, index) => (
    <Tag key={index} className={className}>
      {str}
    </Tag>
  ));
