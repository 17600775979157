/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import Autosuggest from 'react-autosuggest';
import { debounce, isEmpty, isNil } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { fontFamily, fontSize, uiColors } from '../styles/styles';
import { withTranslation } from 'react-i18next';

const styles = {
  '.autoSuggestContainerIcon': {
    position: 'absolute',
    zIndex: '1',
    color: uiColors.DARK,
  },
  '.defaultIcon': {
    marginTop: '7px',
    marginLeft: '7px',
  },
  '.react-autosuggest__container': {
    position: 'relative',
  },
  '.react-autosuggest__input': {
    width: '240px',
    height: '38px',
    padding: '10px 25px',
    fontFamily: fontFamily.TOP_SEARCH_INPUT,
    fontWeight: '300',
    fontSize: fontSize.TOP_SEARCH_INPUT,
    border: '1px solid #aaa',
    borderRadius: '4px',
  },
  '.react-autosuggest__input--focused': {
    outline: 'none',
  },
  '.react-autosuggest__input--open': {
    borderBottomLeftRadius: '0',
    borderBottomRightRadius: '0',
  },
  '.react-autosuggest__suggestions-container': {
    display: 'none',
  },
  '.react-autosuggest__suggestions-container--open': {
    display: 'block',
    position: 'absolute',
    top: '37px',
    width: '280px',
    border: '1px solid #aaa',
    backgroundColor: '#fff',
    fontFamily: fontFamily.TOP_SEARCH_INPUT,
    fontWeight: '300',
    fontSize: fontSize.TOP_SEARCH_INPUT,
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
    zIndex: '2',
  },
  '.react-autosuggest__suggestions-list': {
    margin: '0',
    padding: '0',
    listStyleType: 'none',
  },
  '.react-autosuggest__suggestion': {
    cursor: 'pointer',
    padding: '10px 20px',
  },
  '.react-autosuggest__suggestion--highlighted': {
    backgroundColor: '#ddd',
  },
  '.react-autosuggest__suggestion-match': {
    color: uiColors.BRAND,
    fontWeight: 'bold',
  },
};

function escapeRegexCharacters(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

function getSuggestions(value, keywords) {
  if (value.length < 2) {
    return [];
  }

  const escapedValue = escapeRegexCharacters(value.trim());
  if (escapedValue === '') {
    return [];
  }

  const regex = new RegExp(escapedValue, 'i');
  return keywords?.filter((keyword) => regex.test(keyword.value));
}

function getSuggestionValue(suggestion) {
  return suggestion.value;
}

function renderSuggestion(suggestion, { query }) {
  const parts = getParts(query, suggestion);
  return (
    <span>
      {parts.map((part, index) => {
        const className = part.highlight
          ? 'react-autosuggest__suggestion-match'
          : null;
        return (
          <span className={className} key={index}>
            {part.text}
          </span>
        );
      })}
    </span>
  );
}

function getParts(query, suggestion) {
  if (isNil(suggestion) || isNil(query)) {
    return [];
  }
  const text = suggestion.value.toLowerCase();
  const parts = [];
  let offset = 0;
  while (true) {
    const begin = text.indexOf(query.toLowerCase(), offset);
    if (begin === -1) {
      if (offset < text.length) {
        addPart(parts, suggestion, offset, text.length, false);
      }
      break;
    } else if (begin > offset) {
      addPart(parts, suggestion, offset, begin, false);
      addPart(parts, suggestion, begin, begin + query.length, true);
    } else {
      addPart(parts, suggestion, 0, query.length, true);
    }
    offset = begin + query.length;
  }
  return parts;
}

function addPart(parts, suggestion, begin, end, highlight) {
  parts.push({
    text: suggestion.value.substring(begin, end),
    highlight: highlight,
  });
}

class AutoSuggest extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      suggestions: [],
    };
    this.onSuggestionsFetchRequested = debounce(
      this.onSuggestionsFetchRequested.bind(this),
      800,
    );
    this.onChange = this.onChange.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (
      !isEmpty(props.suggestions) &&
      props.suggestions.length !== state.suggestions.length
    ) {
      return {
        ...state,
        suggestions: props.suggestions,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.value && !this.props.value) {
      this.setState({ value: '' });
    }
  }

  onChange = (event, { newValue, method }) => {
    this.setState({
      value: newValue,
    });
    if (this.props.onValueChange) {
      this.props.onValueChange(newValue);
    }
  };

  onSuggestionsFetchRequested = ({ value }) => {
    const { keywords } = this.props;
    if (!isEmpty(keywords)) {
      this.setState({
        suggestions: getSuggestions(value, this.props.keywords),
      });
    }
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  render() {
    const { value, suggestions } = this.state;
    const { searchIconComponent, placeholder, iconClassName } = this.props;
    return (
      <div className={'autoSuggestContainer'} css={styles}>
        <span
          className={`autoSuggestContainerIcon ${
            iconClassName ? iconClassName : 'defaultIcon'
          }`}
        >
          {searchIconComponent ?? <FontAwesomeIcon icon={faSearch} />}
        </span>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={{
            placeholder: this.props.t(
              placeholder ?? 'auto-suggest.placeholder',
            ),
            value,
            onChange: this.onChange,
          }}
        />
      </div>
    );
  }
}

export default withTranslation()(AutoSuggest);
