import { call, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import lawItemConnector from '../../../connectors/LawItem';
import { generateApiSaga } from '../../../utils/sagas';

const doGetLawItemByLaw = generateApiSaga(
  lawItemConnector,
  function* (connector, action) {
    let lawId = action.payload.node.get('id');
    let { pagesize, page } = action.payload.paging;
    return yield call(
      [connector, connector.getAllByLaw],
      lawId,
      pagesize,
      page,
    );
  },
);

export default function* getAllBySectionAndType() {
  yield takeEvery(
    ActionTypes.API_LAWITEM_GET_ALL_BY_LAW_INIT,
    doGetLawItemByLaw,
  );
}
