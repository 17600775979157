import { call, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import LawConnector from '../../../connectors/Law';
import { generateApiSaga } from '../../../utils/sagas';

var doGetAllLaws = generateApiSaga(LawConnector, function* (connector, action) {
  return yield call([connector, connector.getAll]);
});

export default function* getAll() {
  yield takeEvery(ActionTypes.API_LAW_GET_ALL_INIT, doGetAllLaws);
}
