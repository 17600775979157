/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { Form } from 'react-bootstrap';
import Text, { TextType } from '../atoms/Text';
import { Trans } from 'react-i18next';
import UserForm from '../molecules/UserForm';
import Button from '../atoms/Button';
import { refBind } from '../../../utils/ref-bind';

class UserGeneralInformation extends React.Component {
  constructor(props) {
    super(props);
    refBind(this, 'handleSubmit', 'handleUserUpdate');
  }

  handleSubmit(event) {
    const { disabled, onSave } = this.props;
    const form = event.currentTarget;
    event.stopPropagation();
    event.preventDefault();
    if (form.checkValidity() && !disabled) {
      onSave();
    }
  }

  handleUserUpdate(form_name, event) {
    const userCopy = { ...(this.props.user || {}) };
    userCopy[event.target.name] = event.target.value;
    this.props.onUserChanged && this.props.onUserChanged(userCopy);
  }

  render() {
    const { user, headerText, disabled } = this.props;
    return (
      <Form className="text-capitalize" onSubmit={this.handleSubmit}>
        <div className="mb-4 text-capitalize">
          <Text type={TextType.HEADER_3}>
            <Trans i18nKey={headerText} />
          </Text>
        </div>
        {user && (
          <UserForm
            isUpdate
            disabled={disabled}
            onChange={this.handleUserUpdate}
            defaults={user}
          />
        )}
        {!disabled && (
          <Button>
            <span className="text-capitalize">
              <Trans i18nKey="profile.save-changes" />
            </span>
          </Button>
        )}
      </Form>
    );
  }
}

export default UserGeneralInformation;
