import { put, select } from 'redux-saga/effects';
import { fromJS } from 'immutable';
import * as ActionTypes from '../../../constants/ActionTypes';
import * as decisionPageSelector from '../../../selectors/page/decision';
import { makeFilter } from '../../../selectors/page/decision';
import * as decisionApiActions from '../../../actions/api/decision';
import { takeLatestWhenAppReady } from '../../../utils/sagas';
import {
  getPaging,
  getSortConfiguration,
} from '../../../constants/HalProperties';
import * as Sort from '../../../constants/Sort';
import * as routerSelectors from '../../../selectors/store/router';
import EntityFields from '../../../constants/EntityFields';
import * as EntityTypes from '../../../constants/EntityTypes';

function* doGetDecisions(action) {
  const { getNextPage } = action.payload;
  let shouldFetchData = getNextPage;
  const courts = yield select(decisionPageSelector.getCourtFilter);
  const uri = yield select(routerSelectors.getCurrentUri);
  let pagingInformation = yield select(
    decisionPageSelector.getPagingOnAPI,
    makeFilter(courts, uri),
  );
  if (!pagingInformation) {
    pagingInformation = fromJS(getPaging());
    shouldFetchData = true;
  }
  const currentPageNumber = pagingInformation.getIn(['paging', 'number']);
  const size = pagingInformation.getIn(['paging', 'size']);
  const paging = getPaging(currentPageNumber + (getNextPage ? 1 : 0), size);
  const node = yield select(decisionPageSelector.getNavigationNode, uri);
  const sort = getSortConfiguration(
    EntityFields[EntityTypes.DECISION].RULING_DATE,
    Sort.Direction.DESC,
  );
  if (shouldFetchData) {
    if (uri === 'all' || !node) {
      yield put(decisionApiActions.getDecisions(paging, sort));
    } else {
      yield put(decisionApiActions.getDecisions(paging, sort, courts));
    }
  }
}

export default function* getDecisions() {
  yield takeLatestWhenAppReady(
    ActionTypes.APP_PAGE_DECISIONS_GET,
    doGetDecisions,
  );
}
