import { call, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import LawItemConnector from '../../../connectors/LawItem';
import { generateApiSaga } from '../../../utils/sagas';

const doGetNewest = generateApiSaga(LawItemConnector, function* (connector) {
  return yield call([connector, connector.getNewest]);
});

export default function* getNewest() {
  yield takeEvery(ActionTypes.API_LAW_ITEM_GET_TOP_INIT, doGetNewest);
}
