import { List, Map } from 'immutable';
import { createSelector } from 'reselect';
import EntityFields from '../../constants/EntityFields';
import { getByFn } from '../api';
import * as jurisdictionApiSelectors from '../api/jurisdiction';
import * as courtApiSelectors from '../api/court';
import * as EntityTypes from '../../constants/EntityTypes';
import { getEntityType } from '../../utils/entities';
import * as decisionApiSelectors from '../api/decision';
import * as routerSelectors from '../store/router';
import * as appSelector from '../store/app';
import { formatToRequestDate } from '../../utils/hocs/dateutils';
import { DateFormat } from '../../constants/DateFormat';
import { sort } from '../../utils/sort';
import { FILTER_DOG_TAG } from '../../utils/filter';
import { COURT_OPTION_SORT_INDEX_BASED, Direction } from '../../constants/Sort';
import { not } from '../../utils/functionalUtil';

export const getAll = (state) =>
  state.getIn(['api', EntityTypes.DECISION, 'data']);

export const getCurrentNode = (state) =>
  state.getIn(['page', EntityTypes.DECISION, 'currentNode']);
export const getEditNode = (state) =>
  state.getIn(['page', EntityTypes.DECISION, 'editNode']);
export const isLoading = (state) =>
  state.getIn(['page', EntityTypes.DECISION, 'isLoading']);

export const getPagingOnAPI = (state, filter) => {
  return state.getIn(['api', EntityTypes.DECISION, 'page', filter]);
};

export const getSelectedDecision = createSelector(
  getAll,
  routerSelectors.getCurrentId,
  (allDecisions, uri) => {
    return !isNaN(uri) && allDecisions.get(Number(uri));
  },
);

/**
 * @param state
 * @returns immutable map with property name as a i18n key and value ready to display in DecisionTable
 */
export const getSelectedDecisionAsTableEntry = (state) => {
  // TODO - Remake with Reselect
  const selectedDecision = getSelectedDecision(state);
  return (
    selectedDecision &&
    convertDecisionToTableEntry(state, selectedDecision.get('node'))
  );
};

export const buildNavigationTree = (
  filteredJurisdictions,
  allCourts,
  decisions,
) => {
  const idPath = ['node', 'id'];
  const rootCourtLevels = ['0', '1', '2'];
  const isRootCourt = (court) =>
    rootCourtLevels.includes(court.getIn(['node', 'level']));
  const isJurisdiction = (jurisdiction) => (court) =>
    court.getIn(['node', 'jurisdiction']) === jurisdiction.getIn(idPath);
  const shouldFilter = !!decisions;

  return filteredJurisdictions
    .map((jurisdiction) => {
      const getCourtDecisions = (courtId) =>
        decisions?.filter(
          (decision) => decision.getIn(['node', 'court']) === courtId,
        ) || List();

      // flatten the top level / "root" courts by replacing the children w. its decisions - we don't want too much nesting
      const jurisdictionCourts = allCourts
        .filter(isJurisdiction(jurisdiction))
        .map((court) =>
          court.set(
            'children',
            court.get(
              'decisions',
              getCourtDecisions(court.getIn(['node', 'id'])),
            ),
          ),
        )
        .filter((court) => !shouldFilter || court.get('children').size);
      const rootCourts = jurisdictionCourts.filter(isRootCourt).toList();

      if (rootCourts.size === jurisdictionCourts.size) {
        // Nothing is filtered so we just set all the courts on the jurisdiction
        return jurisdiction.set('children', rootCourts);
      }

      const nonRootCourts = sort(
        jurisdictionCourts.filter(not(isRootCourt)).toList(),
        COURT_OPTION_SORT_INDEX_BASED,
      );

      // TODO - this assumes that if we reach this point, we are in the Danish jurisdiction.
      const cityCourtWrapper = createCityCourtWrapper(
        jurisdiction.getIn(idPath),
      ).set('children', nonRootCourts);
      // DA-KU needs to be last element - so if we find it we remove it and add it again after adding the city court. If not, we just add the city court.
      const daKUIndex = rootCourts.findIndex(
        (e) => e.getIn(['node', 'shortName']) === 'DA-KU',
      );
      if (daKUIndex === -1) {
        return jurisdiction.set('children', rootCourts.push(cityCourtWrapper));
      } else {
        const daKu = rootCourts.get(daKUIndex);
        return jurisdiction.set(
          'children',
          rootCourts.remove(daKUIndex).push(cityCourtWrapper).push(daKu),
        );
      }
    })
    .toList()
    .filter(
      (jurisdiction) => !shouldFilter || jurisdiction.get('children').size,
    );
};

export const getNavigationTree = createSelector(
  appSelector.getFilteredJurisdictions,
  courtApiSelectors.getAll,
  buildNavigationTree,
);

export const getSelectedBranch = createSelector(
  routerSelectors.getCurrentUri,
  getNavigationTree,
  (uri, tree) => (uri ? getList(findBranchInTree(tree, uri), null) : tree),
);

export const getCourtFilter = createSelector(
  getSelectedBranch,
  courtApiSelectors.getAll,
  (branch, courts) => {
    if (branch && branch.size) {
      if (
        getEntityType(branch.first().get('node')) === EntityTypes.JURISDICTION
      ) {
        const jurisdictionIds = branch.map((rootNode) => getId(rootNode));
        return (courts || List())
          .filter((court) =>
            jurisdictionIds.contains(court.get('node').get('jurisdiction')),
          )
          .map((court) => getId(court))
          .toList();
      } else {
        return branchCourtIdReducer(branch);
      }
    }
    return List();
  },
);

export const getFilterPages = createSelector(
  decisionApiSelectors.getAllFilterPages,
  getCourtFilter,
  routerSelectors.getCurrentUri,
  (preFilterPages, courtFilter, currentUri) => {
    const filter = makeFilter(courtFilter, currentUri);
    return preFilterPages?.get(filter);
  },
);

const getDecisionNumbers = createSelector(
  getFilterPages,
  (filterPage) =>
    (filterPage?.getIn(['paging', 'number'], 0) + 1) *
    filterPage?.getIn(['paging', 'size'], 1),
);

export const getDecisionList = createSelector(
  getSelectedBranch,
  getAll,
  getCourtFilter,
  routerSelectors.getCurrentUri,
  getDecisionNumbers,
  (selectedNode, decisions, courtFilter, currentUri, getDecisionNumbers) => {
    if (selectedNode && decisions) {
      if (!currentUri) {
        return sort(decisions.toList(), EntityTypes.DECISION, Direction.DESC);
      }
      const filteredDecisions = decisions.filter((decision) =>
        courtFilter.includes(decision.getIn(['node', 'court'])),
      );
      return sort(
        filteredDecisions.toList(),
        EntityTypes.DECISION,
        Direction.DESC,
      ).slice(0, getDecisionNumbers);
    }
    return decisions || List();
  },
);

export const getPagesLeft = createSelector(
  getSelectedDecisionAsTableEntry,
  getFilterPages,
  (showDecision, filterPages) => {
    if (showDecision || !filterPages) return 0;
    const numberOfPages = filterPages.getIn(['paging', 'number']) + 1; // page index + 1 to get the number of pages
    const totalElements = filterPages.getIn(['paging', 'totalElements']);
    const pageSize = filterPages.getIn(['paging', 'size']);
    const remainingElements = totalElements - pageSize * numberOfPages;
    return Math.max(0, remainingElements);
  },
);

/**
 *
 * @param pagesLeft
 * @returns true if has more than 0 pages left
 */
export const getHasPagesLeft = createSelector(
  getPagesLeft,
  (pagesLeft) => pagesLeft > 0,
);

export const makeFilter = (allCourts, uri) => {
  let filter;
  if ((allCourts && !allCourts.size) || uri === 'all' || !uri) {
    filter = { sort: 'rulingDate,desc' };
  } else {
    filter = { courtIds: allCourts.join(','), sort: 'rulingDate,desc' };
  }
  return FILTER_DOG_TAG.concat(JSON.stringify(filter));
};

export const getByUri = (state, uri) => {
  // TODO - Remake with Reselect
  return getByFn(
    state,
    EntityTypes.DECISION,
    (x) => x.get(EntityFields[EntityTypes.DECISION].URI) === uri,
  );
};

export const getById = (state, id) => {
  // TODO - Remake with Reselect
  return state.getIn(['api', EntityTypes.DECISION, 'data', +id]);
};

export const getNavigationNode = (state, uri) => {
  if (uri) {
    const tree = getNavigationTree(state);
    return getList(findBranchInTree(tree, uri));
  }
  return undefined;
};

const convertDecisionToTableEntry = (state, selectedDecision) => {
  const jurisdiction = getJurisdictionCodeByCourtId(
    state,
    selectedDecision.get('court'),
  );
  const isEu = 'EU' === jurisdiction;
  const data = Map()
    .set('fileRef', `/doc/pdf/${selectedDecision.get('fileRef')}`)
    .set('decision', selectedDecision)
    .set(
      'node',
      Map({
        'decision.table.official-name': selectedDecision.get('caseName'),
        'decision.table.official-case-number':
          selectedDecision.get('caseNumber'),
        'decision.table.document-date': formatToRequestDate(
          selectedDecision.get(EntityFields[EntityTypes.DECISION].RULING_DATE),
          DateFormat.requestDate,
        ),
        'decision.table.case-opening': formatToRequestDate(
          selectedDecision.get('initiatedDate'),
          DateFormat.requestDate,
        ),
        'decision.table.service': selectedDecision.get('service'),
        'decision.table.decision-type': selectedDecision.get('decisionType'),
        'decision.table.case-type': selectedDecision.get('caseType'),
        'decision.table.court': resolveCourtName(
          state,
          selectedDecision.get('court'),
        ),
        'decision.table.merit-type': selectedDecision.get('meritType'),
        'decision.table.reaction-type': selectedDecision.get('reactionType'),
        'decision.table.formality-type': selectedDecision.get('formalityType'),
        'decision.table.reaction-formality':
          selectedDecision.get('reactionFormality'),
        'decision.table.reaction-formality-amount': selectedDecision.get(
          'reactionFormalityAmount',
        ),
        'decision.table.suspensive-effect':
          selectedDecision.get('suspensiveEffect'),
        'decision.table.editor-name': selectedDecision.get('editorName'),
        'decision.table.ufr-Reference': selectedDecision.get('ufrReference'),
        'decision.table.judge': selectedDecision.get('judges'),
        'decision.table.complaint': selectedDecision.get('plaintiff'),
        'decision.table.complaint-representative': selectedDecision.get(
          'plaintiffRepresentative',
        ),
        'decision.table.accused': selectedDecision.get('defendant'),
        'decision.table.accused-representative': selectedDecision.get(
          'defendantRepresentative',
        ),
        'decision.table.procurement-form':
          selectedDecision.get('procurementForm'),
        'decision.table.procurement-form-procedure': selectedDecision.get(
          'procurementFormProcedure',
        ),
        'decision.table.procurement-form-addon': selectedDecision.get(
          'procurementFormAddon',
        ),
        'decision.table.pos-opf-int-claim':
          selectedDecision.get('posOpfIntClaim'),
        'decision.table.pos-opf-int-awarded':
          selectedDecision.get('posOpfIntAwarded'),
        'decision.table.neg-kons-int-claim':
          selectedDecision.get('negKonsIntClaim'),
        'decision.table.neg-kons-int-awarded':
          selectedDecision.get('negKonsIntAwarded'),
        'decision.table.case-cost': selectedDecision.get('caseCost'),
        'decision.table.short-name': selectedDecision.get('shortName'),
        'decision.jurisdiction': jurisdiction,
      }),
    );
  return isEu
    ? data.setIn(
        ['node', 'decision.table.eurlex-document-name'],
        selectedDecision.get('celexNumber'),
      )
    : data;
};

const resolveCourtName = (state, courtId) => {
  const court = courtApiSelectors.getById(state, courtId);
  return court
    ? `(${court.getIn(['node', 'shortName'])}) ${court.getIn(['node', 'name'])}`
    : '';
};

const getJurisdictionCodeByCourtId = (state, courtId) => {
  const court = courtApiSelectors.getById(state, courtId);
  const jurisdiction =
    court &&
    jurisdictionApiSelectors.getById(
      state,
      court.getIn(['node', 'jurisdiction']),
    );
  return jurisdiction && jurisdiction.getIn(['node', 'alpha2Code']);
};

// private helpers
const getList = (listItems, fallback) =>
  listItems ? List.of(listItems) : fallback;

const getId = (node) => node.get('node').get('id');

/**
 * Hack method to create the Danish city court grouping
 * TODO - We should find a better way for this - perhaps change our data in DB? 🤔
 */
const createCityCourtWrapper = (jurisdiction) => {
  return Map({
    node: Map({
      id: -1,
      name: 'Byretten',
      uri: 'Byretten',
      jurisdiction,
      shortName: 'Byretten',
    }),
  });
};

/**
 *
 * @param branch
 * @returns array with node ids
 */
const branchCourtIdReducer = (branch) => {
  let result = List();
  if (branch) {
    branch.forEach(
      (court) =>
        (result = result.push(
          getId(court),
          ...branchCourtIdReducer(court.get('children')),
        )),
    );
  }
  return result;
};

const findBranchInTree = (tree, uri) => {
  if (!(tree && tree.size)) {
    return undefined;
  }

  let lookup = tree.find(
    (branch) =>
      branch.get('node').get('alpha2Code') === uri ||
      branch.get('node').get('shortName') === uri,
  );
  if (lookup) {
    return lookup;
  }

  let childLookup = null;
  tree.takeUntil((branch) => {
    childLookup = findBranchInTree(branch.get('children'), uri);
    return childLookup;
  });
  return childLookup;
};
