/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import PropTypes from 'prop-types';
import { uiColors } from '../styles/styles';
import { DateFormat } from '../../../constants/DateFormat';
import moment from 'moment';

const styles = {
  background: uiColors.WHITE_BLUE,
  color: uiColors.WHITE_BLUE,
  fontSize: '0.8rem',
  lineHeight: '1.25rem',
  userSelect: 'none',
  cursor: 'pointer',
};

class HiddenText extends React.Component {
  static DATE = 'date';
  static TEXT = 'text';

  static propTypes = {
    text: PropTypes.string,
    type: PropTypes.oneOf([HiddenText.DATE, HiddenText.TEXT]),
    dateFormat: PropTypes.oneOf([
      DateFormat.requestDate,
      DateFormat.requestDateTime,
    ]),
    onHiddenClicked: PropTypes.func,
    className: PropTypes.string,
  };

  static defaultProps = {
    text: '',
    type: HiddenText.TEXT,
    dateFormat: DateFormat.requestDate,
    className: '',
  };

  render() {
    if (this.props.type === HiddenText.DATE) {
      if (this.props.text) {
        const date = moment(this.props.text, this.props.dateFormat);
        return (
          <span
            onClick={this.props.onHiddenClicked}
            className={this.props.className}
          >
            <span css={styles}>**</span>&nbsp;
            <span css={styles}>***</span>&nbsp;
            <span>{date.format('YYYY')}</span>
          </span>
        );
      }
      return null;
    }
    const text = this.props.text?.split('').join(' ');
    return (
      <span
        onClick={this.props.onHiddenClicked}
        css={styles}
        className={this.props.className}
      >
        {text}
      </span>
    );
  }
}

export default HiddenText;
