/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import Filter from '../../components/common/molecules/Filter';
import * as commonActions from '../../actions/commonactions';
import * as processStepApiAction from '../../actions/api/processstep';
import * as processStepApiSelector from '../../selectors/api/processstep';
import { refBind } from '../../utils/ref-bind';
import * as SelectUtils from '../../utils/AutocompleteMapper';
import { createTagName } from '../../utils/filter';
import * as decisionApiAction from '../../actions/api/decision';

class ProcessStepSearch extends Component {
  constructor(props) {
    super(props);
    this.state = { processStepFilter: '', selected: null };
    refBind(this, 'searchRelatedProcessSteps', 'handleChange');
  }

  componentDidMount() {
    const { actions } = this.props;
    actions.searchProcessStepsItems('');
  }

  searchRelatedProcessSteps(processStepFilter) {
    const { actions } = this.props;
    this.setState({ processStepFilter });
    processStepFilter &&
      actions.debounce(decisionApiAction.searchByName(processStepFilter));
  }

  handleChange(selected) {
    const { clearOnSelect, onProcessStepChange } = this.props;
    if (!clearOnSelect) {
      this.setState({ selected });
    }
    onProcessStepChange && onProcessStepChange(selected);
  }

  render() {
    const { processData, ignoreOptions, isDisabled, isMulti } = this.props;
    const { processStepFilter, selected } = this.state;
    const currentProcessStepFilter = createTagName(processStepFilter);
    const filteredData = processData[currentProcessStepFilter];
    const processStepOptions = SelectUtils.removeIgnoredItems(
      SelectUtils.mapProcessSteps(filteredData, true),
      ignoreOptions,
    );

    return (
      <Filter
        isDisabled={isDisabled}
        options={processStepOptions || []}
        isMulti={isMulti}
        value={selected}
        onInputChange={this.searchRelatedProcessSteps}
        onChange={this.handleChange}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const processData = processStepApiSelector.getSearchData(state);
  return {
    ...ownProps,
    processData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      searchProcessStepsItems: processStepApiAction.searchByName,
      debounce: commonActions.debounceAction,
    },
    dispatch,
  ),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withImmutablePropsToJS,
)(ProcessStepSearch);
