import { call, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import lawItemConnector from '../../../connectors/LawItem';
import { generateApiSaga } from '../../../utils/sagas';

var doGetLawItemsByIds = generateApiSaga(
  lawItemConnector,
  function* (connector, action) {
    let nodeFragment = action.payload.ids;
    return yield call([connector, connector.getByIdsWithParents], nodeFragment);
  },
);

export default function* getbyids() {
  yield takeEvery(ActionTypes.API_LAWITEM_GET_BY_IDS_INIT, doGetLawItemsByIds);
}
