import { call, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import TheoryItemConnector from '../../../connectors/TheoryItem';
import { generateApiSaga } from '../../../utils/sagas';
import { API_THEORY_ITEM_GET_LATEST_INIT } from '../../../constants/ActionTypes';

const doGetNewest = generateApiSaga(TheoryItemConnector, function* (connector) {
  return yield call([connector, connector.getNewest]);
});

export default function* getNewest() {
  yield takeEvery(ActionTypes.API_THEORY_ITEM_GET_LATEST_INIT, doGetNewest);
}
