/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import PropTypes from 'prop-types';
import { uiColors } from '../styles/styles';

const styles = {
  borderLeft: `1px solid ${uiColors.BORDER}`,
  height: 'auto',
};

class VerticalLine extends React.Component {
  static propTypes = {
    className: PropTypes.string,
  };

  static defaultProps = {
    className: '',
  };

  render() {
    const { className } = this.props;

    return <div className={className} css={styles} />;
  }
}

export default VerticalLine;
