import {
  callAndThrottleApi,
  takeLatestWhenAppReady,
} from '../../../utils/sagas';
import * as ActionTypes from '../../../constants/ActionTypes';
import * as SearchTabs from '../../../constants/SearchTabs';
import * as searchPageActions from '../../../actions/page/search';
import { call, put, select } from '@redux-saga/core/effects';
import * as searchPageSelectors from '../../../selectors/page/search';
import * as theoryItemApiActions from '../../../actions/api/theoryitem';
import * as lawItemApiActions from '../../../actions/api/lawitem';
import * as decisionApiActions from '../../../actions/api/decision';
import * as processStepApiActions from '../../../actions/api/processstep';
import * as lawgroupApiActions from '../../../actions/api/lawgroup';
import * as lawPageSelectors from '../../../selectors/page/law';

const chopItUp = (array, chunkSize) => {
  let i,
    j,
    result = [];
  for (i = 0, j = array.length; i < j; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize));
  }
  return result;
};

function* fetchMissingResults(selector, getter) {
  const summary = yield select(selector);
  if (summary.get('totalItemsMissing') > 0) {
    yield put(searchPageActions.fetchResultsRequest());
    const missingIds = summary.get('missingEntityIds').toJS();
    let fetches = chopItUp(missingIds, 20).map((chunk) => {
      return getter(chunk);
    });
    yield call(callAndThrottleApi, fetches);
  }
}

function* doFetchTheoryResults(action) {
  yield* fetchMissingResults(
    searchPageSelectors.getLatestSearchTheoryItemSummary,
    (chunk) => theoryItemApiActions.getByIds(chunk),
  );
}

function* doFetchLawResults(action) {
  const lawGroups = yield select(lawPageSelectors.getAllLawGroups);
  if (!lawGroups.size) {
    // Here we assume that we always get all law groups, and never individually or as parts.
    yield put(lawgroupApiActions.getAll());
  }
  yield* fetchMissingResults(
    searchPageSelectors.getLatestSearchLawItemSummary,
    (chunk) => lawItemApiActions.getByIds(chunk),
  );
}

function* doFetchProcessResults(action) {
  yield* fetchMissingResults(
    searchPageSelectors.getLatestSearchProcessStepSummary,
    (chunk) => processStepApiActions.getByIds(chunk),
  );
}

function* doFetchDecisionResults(action) {
  yield* fetchMissingResults(
    searchPageSelectors.getLatestSearchDecisionSummary,
    (chunk) => decisionApiActions.getByIds(chunk),
  );
}

const fetchMap = {
  [SearchTabs.THEORY]: doFetchTheoryResults,
  [SearchTabs.LAW]: doFetchLawResults,
  [SearchTabs.PROCESS]: doFetchProcessResults,
  [SearchTabs.DECISION]: doFetchDecisionResults,
};

function* doFetchResults(action) {
  const currentTab = yield select(searchPageSelectors.getCurrentTab);
  const latestSearchResult = yield select(
    searchPageSelectors.getLatestSearchResult,
  );

  if (latestSearchResult !== null) {
    yield call(fetchMap[currentTab], action);
  }

  yield put(searchPageActions.fetchResultsCompleted());
}

export default function* fetchResults(action) {
  yield takeLatestWhenAppReady(
    ActionTypes.PAGE_SEARCH_FETCH_RESULTS_INIT,
    doFetchResults,
  );
}
