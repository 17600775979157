import moment from 'moment';

export const camelizeKebabCase = (text) =>
  text.replace(/-./g, (x) => x[1].toUpperCase());

export const kebabizeCamelCase = (text) =>
  text
    .replace(/([A-Z0-9])([A-Z])/g, '$1-$2')
    .replace(/([a-z])([A-Z0-9])/g, '$1-$2')
    .replace(/[\s_]+/g, '-')
    .toLowerCase();

export const formatDate = (text) => moment(text).format('yyyy-MM-DD');
