import ItemConnector from './Item';
import * as Searches from '../constants/Searches';
import * as Projections from '../constants/Projections';
import * as EntityTypes from '../constants/EntityTypes';

export default class ProcessStepConnector extends ItemConnector {
  constructor(...args) {
    super(...args);
    this.entityType = EntityTypes.PROCESSSTEP;
  }

  getAllByProcess(processId, pageszie, page) {
    let query = this.query()
      .withSearch(Searches.findByProcessId, [processId])
      .withProjection(Projections.WITHID)
      .withSize(pageszie)
      .withPage(page)
      .build();

    return this.authGet(query);
  }

  searchByName(name, projection) {
    let query = this.query()
      .withSearch(Searches.findProcessStepByName, [name])
      .withProjection(projection)
      .build();
    return this.authGet(query);
  }

  searchByIds(ids) {
    let query = this.query()
      .withSearch(Searches.findByIds, [ids])
      .withProjection(Projections.PARENTS)
      .build();
    return this.authGet(query);
  }

  getById(id, projection) {
    const query = this.query().withId(id).withProjection(projection).build();
    return this.authGet(query);
  }
}
