import { takeEvery, put } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import * as lawItemActions from '../../../actions/api/lawitem';
import { getAllPages } from '../../../actions/api';

function* doGetAllLawItems(action) {
  let initAction = lawItemActions.getAll();
  yield put(getAllPages(initAction));
}
export default function* getAllLawItems() {
  yield takeEvery(ActionTypes.API_LAWITEM_GET_ALL_TXN, doGetAllLawItems);
}
