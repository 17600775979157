import { call, put, select } from 'redux-saga/effects';
import { takeLatestWhenAppReady } from '../../../utils/sagas';
import * as ActionTypes from '../../../constants/ActionTypes';
import * as searchPageActions from '../../../actions/page/search';
import * as routerActionSelector from '../../../selectors/actions/router';
import SearchFilter from '../../../utils/searchfilter';
import * as searchPageSelectors from '../../../selectors/page/search';
import * as Urls from '../../../constants/Urls';
import * as SearchTabs from '../../../constants/SearchTabs';
import * as authSelectors from '../../../selectors/store/auth';

function* doPageOpen(action) {
  // check if url contains tabSegment and adjust if necessary
  const [, tabSegment] = routerActionSelector.getSplitPath(action);
  if (tabSegment) {
    const currentTab = yield select(searchPageSelectors.getCurrentTab);
    if (
      currentTab !== tabSegment &&
      Object.values(SearchTabs).includes(tabSegment)
    ) {
      yield put(searchPageActions.setTab(tabSegment));
    }
  }

  const qParams = routerActionSelector.getQueryParams(action);
  const newFilter = SearchFilter.deserialize(qParams['filter']);
  if (!newFilter.isEmpty()) {
    // we do a search right away
    yield put(searchPageActions.runSearch(newFilter));
  } else {
    // no search just yet, check that we shouldn't redirect for the tab
    const currentTab = yield select(searchPageSelectors.getCurrentTab);
    yield put(searchPageActions.changeTab(currentTab));
  }
}

export default function* detectNavigation() {
  yield takeLatestWhenAppReady(
    ActionTypes.APP_ROUTE_CHANGE,
    function* (action) {
      const previousPageSegment =
        routerActionSelector.getPreviousPageSegment(action);
      const newPageSegment = routerActionSelector.getPageSegment(action);
      const userId = yield select(authSelectors.getUserId);
      if (
        userId &&
        newPageSegment === Urls.SEARCH_PAGE_SEGMENT &&
        newPageSegment !== previousPageSegment
      ) {
        yield call(doPageOpen, action);
      }
    },
  );
}
