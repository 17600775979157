import { takeLatest } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import { callAndAwaitApi } from '../../../utils/sagas';
import * as signupApiAction from '../../../actions/api/signup';

function* doSignupPageLoad(action) {
  const [token, ...rest] = action.payload.uri;
  if (token) {
    yield callAndAwaitApi(signupApiAction.confirmSignup(token));
  }
}

export default function* signupPageLoad() {
  yield takeLatest(ActionTypes.APP_PAGE_SIGNUP_LOAD, doSignupPageLoad);
}
