import * as taxonomyApiSelectors from '../api/taxonomy';
import * as routeSelector from '../store/router';
import * as EntityTypes from '../../constants/EntityTypes';
import { fromJS, List, Map } from 'immutable';
import { createSelector } from 'reselect';
import TaxonomyType from '../../constants/TaxonomyType';
import { sort } from '../../utils/sort';
import I18n from '../../localization/i18n';
import { identity, toMap } from '../../utils/functionalUtil';
import { DANISH_LOCALE } from '../../constants/locale';

export const getCreatedItemId = (state) =>
  state.getIn(['page', EntityTypes.TAXONOMY, 'createdItemId']);
export const getFilterText = (state) =>
  state.getIn(['page', EntityTypes.TAXONOMY, 'filterText']);
export const getSelectedTab = (state) =>
  state.getIn(['page', EntityTypes.TAXONOMY, 'selectedTab']);
export const getCurrentPageNumber = (state) =>
  state.getIn(['page', EntityTypes.TAXONOMY, 'selectedPageNumber']);
export const getPageSize = (state) =>
  state.getIn(['page', EntityTypes.TAXONOMY, 'pageSize']);

const taxonomyNavigationTree = fromJS([
  {
    children: [],
    node: {
      id: 'Keyword',
      uri: 2,
      description: I18n.t('taxonomy.Keyword'),
      entityType: EntityTypes.TAXONOMY,
    },
    parent: null,
  },
  {
    children: [],
    node: {
      id: 'CPV',
      uri: 3,
      description: I18n.t('taxonomy.CPV'),
      entityType: EntityTypes.TAXONOMY,
    },
    parent: null,
  },
  {
    children: [],
    node: {
      id: 'Service',
      uri: 4,
      description: I18n.t('taxonomy.Service'),
      entityType: EntityTypes.TAXONOMY,
    },
    parent: null,
  },
]);

export const getTaxonomyNavigationTree = () => {
  return taxonomyNavigationTree;
};

export const buildTaxonomyNavigationTree = (taxonomyList) => {
  let navigationTree = taxonomyNavigationTree.reduce(
    ...toMap((node) => node.getIn(['node', 'id']), identity),
  );
  taxonomyList
    .sort((a, b) =>
      a
        .getIn(['node', 'description'], '')
        .localeCompare(b.getIn(['node', 'description'], ''), DANISH_LOCALE),
    )
    .filter((taxonomy) => taxonomy.getIn(['node', 'type']))
    .forEach((taxonomy) => {
      const taxonomyType = taxonomy.getIn(['node', 'type']);
      const children = navigationTree
        .getIn([taxonomyType, 'children'])
        .push(taxonomy);
      navigationTree = navigationTree.setIn(
        [taxonomyType, 'children'],
        children,
      );
    });
  return navigationTree.toList().filter((node) => node.get('children').size);
};

export const getCurrentNode = createSelector(
  routeSelector.getCurrentIdForTaxonomy,
  taxonomyApiSelectors.getAll,
  (taxonomyId, allTaxonomy) => allTaxonomy.get(Number(taxonomyId)),
);

export const getEmptyState = () => {
  return {
    description: null,
    type: null,
    code: null,
  };
};

export const getLocalizationKeys = () => ({
  title: 'add-taxonomy.create',
  submitBtn: 'add-taxonomy.add-button',
});

export const mapTypesToOptions = (types) =>
  List(Object.values(types).map((type, index) => getOption(type, index)));
export const getOption = (label, value) => Map({ label, value });

export const mapTaxonomyStateToTaxonomy = (state) => {
  return {
    description: state.description,
    type: state.type,
    code: state.code,
  };
};

const separators = ['', '-', ':', ',', '/'].flatMap((elm) => [
  elm,
  ` ${elm}`,
  ` ${elm} `,
  `${elm} `,
]);

const getCombinations = (items) =>
  separators
    .map((separator) => items[0] + separator + items[1])
    .concat(separators.map((separator) => items[1] + separator + items[0]));

const filterByDifferentParameters = (words, filterText) => {
  switch (words.length) {
    case 1:
      return words[0].toLowerCase().includes(filterText.toLowerCase());
    case 2:
      return getCombinations(words).some((combination) =>
        combination.toLowerCase().includes(filterText.toLowerCase()),
      );
    default:
      return false;
  }
};

const filterTaxonomy = (allTaxonomy, type, filterText) => {
  const taxonomiesByType = allTaxonomy.filter(
    (taxonomy) => taxonomy.getIn(['node', 'type']) === type,
  );
  return filterText && filterText.trim().length > 0
    ? taxonomiesByType.filter((taxonomy) =>
        filterByDifferentParameters(
          [
            ...[
              taxonomy.getIn(['node', 'description']),
              taxonomy.getIn(['node', 'code']),
            ].filter(Boolean),
          ],
          filterText,
        ),
      )
    : taxonomiesByType;
};

export const getCurrentTaxonomySelection = createSelector(
  taxonomyApiSelectors.getAll,
  getSelectedTab,
  getCurrentPageNumber,
  getPageSize,
  getFilterText,
  (allTaxonomy, selectedTab, currentPageNumber, pageSize, filterText) => {
    let filtered = filterTaxonomy(
      allTaxonomy,
      selectedTab,
      filterText,
    ).toList();
    let baseLine = sort(filtered, EntityTypes.TAXONOMY);
    let numPages = Math.ceil(baseLine.size / pageSize);
    let pageNo = Math.min(numPages, currentPageNumber);
    let fromIdx = pageNo * pageSize;
    return baseLine.slice(fromIdx, fromIdx + pageSize).toList();
  },
);

export const getCurrentFilterCounts = createSelector(
  taxonomyApiSelectors.getAll,
  getFilterText,
  (allTaxonomy, filterText) =>
    Object.values(TaxonomyType).reduce((acc, cur) => {
      const baseLine = filterTaxonomy(allTaxonomy, cur, filterText);
      acc[cur] = baseLine.size;
      return acc;
    }, {}),
);
