import { put, select } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import { takeEveryWhenAppReady } from '../../../utils/sagas';
import * as favoriteApiSelector from '../../../selectors/api/favorite';
import { PageEntities } from '../../../constants/PageEntities';
import * as favoriteApiActions from '../../../actions/api/favorite';

function* doTaxonomyPageLoad() {
  const favorites = yield select(
    favoriteApiSelector.getPageFavorites,
    PageEntities.TAXONOMY_PAGE,
  );
  if (!(favorites && favorites.size)) {
    yield put(
      favoriteApiActions.getFavoritesByType(PageEntities.TAXONOMY_PAGE),
    );
  }
}

export default function* taxonomyPageLoadSaga() {
  yield takeEveryWhenAppReady(
    ActionTypes.APP_PAGE_TAXONOMY_LOAD,
    doTaxonomyPageLoad,
  );
}
