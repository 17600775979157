import * as ActionTypes from '../../constants/ActionTypes';

import { fromJS } from 'immutable';
const initialState = fromJS({
  currentNode: null,
});
export default function processPageReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.APP_PAGE_PROCESS_SELECT_NODE:
      return state.merge({
        currentNode: action.payload.node,
        currentParent: action.payload.parent,
      });

    default:
      return state;
  }
}
