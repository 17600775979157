import { put, take, takeEvery } from 'redux-saga/effects';
import { isEqual } from 'lodash';
import * as ActionTypes from '../../constants/ActionTypes';
import * as taxonomyAction from '../../actions/api/taxonomy';
import { getAllPages } from '../../actions/api';
import { preloadTaxonomyCompleted } from '../../actions/data';

function* doPreloadTaxonomy() {
  const initAction = getAllPages(taxonomyAction.getAll());
  yield put(initAction);
  const completedAction = yield take(
    (action) =>
      action.type === ActionTypes.TXN_ALL_PAGES_COMPLETE &&
      isEqual(action.payload.initAction, initAction),
  );
  yield put(preloadTaxonomyCompleted(completedAction.payload.isSuccess));
}

export default function* preloadTaxonomy() {
  yield takeEvery(ActionTypes.DATA_PRELOAD_TAXONOMY, doPreloadTaxonomy);
}
