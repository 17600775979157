/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment } from 'react';
import { Col, Row } from 'react-bootstrap';
import ScrollableAnchor from 'react-scrollable-anchor';
import { FaPencilAlt } from 'react-icons/all';
import {
  articleDecreaseThreshold,
  articleFontSize,
  fontColors,
  oneRem,
  uiColors,
} from '../styles/styles';
import ControlledByPermission from '../../../containers/shared/ControlledByPermission';
import {
  ADMIN_UPDATE_PERMISSIONS,
  PERMISSIONS,
} from '../../../constants/Permissions';
import { refBind } from '../../../utils/ref-bind';
import ArticleEditor from '../../../containers/shared/ArticleEditor';
import * as EntityTypes from '../../../constants/EntityTypes';
import * as ItemTypes from '../../../constants/ItemTypes';
import LicenseRequiredView from './LicenseRequiredView';
import HiddenText from '../atoms/HiddenText';
import { sort } from '../../../utils/sort';
import { SECTION } from '../../../constants/ArticleTypes';
import FavoriteIcon from '../atoms/FavoriteIcon';
import * as htmlHelpers from '../../../utils/HtmlHelpers';

const style = (nodeLevel) => ({
  '& span': {
    '& h4, & h5, & p': {
      display: 'inline',
      fontSize:
        oneRem - articleDecreaseThreshold * nodeLevel > articleFontSize
          ? `${1.25 - nodeLevel / 10}+rem`
          : `${oneRem}px`,
      span: {
        fontWeight: 'bolder',
      },
    },
  },
  '& .article-section-link': {
    color: 'inherit',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  '.no-permission': {
    position: 'absolute',
    top: '3.5rem',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    fontSize: '2rem',
  },
  '& .article-row': {
    marginBottom: '.5em',
    '& .article-row-indicator': {
      display: 'inline-block',
      flexBasis: '8px',
      marginRight: '10px',
      flexShrink: 0,
      '& div': {
        border: '1px solid',
        borderColor: uiColors.ICON_GRAY,
        height: '8px',
        width: '8px',
        marginTop: '9px',
        borderRadius: '4px',
      },
    },
    '& .article-row-canonical-name': {
      flexBasis: '10px',
      marginRight: '10px',
      flexShrink: 0,
      '& .parent-name': {
        color: fontColors.GREY,
      },
    },
    '& .article-row-content': {
      '& h5': {
        fontSize:
          oneRem - articleDecreaseThreshold * nodeLevel > articleFontSize
            ? `${1.25 - nodeLevel / 10}rem`
            : `${oneRem}px`,
        fontWeight: 'bolder',
      },
      '&.node-level': {
        paddingLeft: `${nodeLevel * 1.2}em`,
      },
      '& .grey-vertical-line': {
        borderLeft: `3px solid ${uiColors.GREY}`,
        padding: ' 5px 10px',
        fontSize: `${articleFontSize}px`,
      },
    },
    '&.active': {
      '& .article-row-indicator div': {
        borderColor: uiColors.BRAND,
        background: uiColors.BRAND,
      },
    },
    '&:hover': {
      '& .article-row-indicator div': {
        borderColor: uiColors.BRAND,
      },
    },
    '& .text-container': {
      border: 'solid 1px transparent',
      cursor: 'pointer',
      '&:hover': {
        borderBottom: `1px solid ${uiColors.BRAND}`,
      },
    },
  },
  '.visible-part': {
    position: 'relative',
    lineHeight: '1.25rem',
  },
  '.visible-part:after': {
    backgroundImage: 'linear-gradient(rgba(255,255,255,0), #fff)',
    position: 'absolute',
    bottom: '0',
    right: '0',
    left: '0',
    height: '1.25rem',
    content: '""',
  },
});

class ArticleNodeChildren extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showModalEditor: false, showLicenseRequired: false };
    refBind(
      this,
      '_handleArticleNodeClick',
      'toggleEditor',
      'handleNoLicense',
      'getPartialView',
      'getArticleNodeTitle',
    );
  }

  renderNode(articleNode, nodeLevel, permission, entityType) {
    const Tag = this.getRenderTag();
    let text;
    let title = this.getArticleNodeTitle();

    if (
      (nodeLevel > 1 ||
        articleNode.children?.length === 0 ||
        entityType === EntityTypes.LAWITEM) &&
      articleNode.node.content
    )
      text = articleNode.node.content;
    else text = articleNode.node.title.replace(articleNode.node.uri, '').trim();

    return (
      <Col
        className={`article-row-content node-level-${nodeLevel} overflow-hidden`}
      >
        {title}
        {Tag === 'p' ? (
          <ControlledByPermission
            permission={permission}
            fallbackFn={this.getPartialView}
            fallbackArgs={{ text }}
            render={
              <Tag className="grey-vertical-line">
                {htmlHelpers.newLineText(text, 'span')}
              </Tag>
            }
          />
        ) : (
          <Tag>{text}</Tag>
        )}
      </Col>
    );
  }

  _handleArticleNodeClick() {
    this.props.onArticleNodeClick &&
      this.props.onArticleNodeClick(this.props.articleNode);
  }

  getClass(isActive) {
    return isActive ? ' active' : '';
  }

  toggleEditor(node) {
    this.setState({
      editNode: node,
      showModalEditor: !this.state.showModalEditor,
    });
  }

  getRenderTag() {
    const { nodeLevel, articleNode } = this.props;
    if (
      articleNode.node.entityType === EntityTypes.THEORYITEM &&
      articleNode.node.theoryItemType === SECTION
    ) {
      return 'h5';
    } else {
      return 'p';
    }
  }
  getArticleNodeTitle() {
    const { articleNode, entityType } = this.props;

    if (entityType === EntityTypes.LAWITEM) {
      return <h5>{articleNode.node.title}</h5>;
    }
    return;
  }

  getArticleNumber() {
    const { articleNode, entityType } = this.props;
    const Tag = this.getRenderTag();
    if (entityType === EntityTypes.LAWITEM) {
      return (
        <Tag>
          <span>{}</span>
        </Tag>
      );
    } else if (articleNode.node.entityType === EntityTypes.THEORYITEM) {
      if (articleNode.node.theoryItemType === ItemTypes.SECTION) {
        return (
          <Tag>
            <span>{articleNode.node.sectionNumber}</span>
          </Tag>
        );
      }
      return (
        <Tag>{`${articleNode.node.sectionNumber}(${articleNode.node.subSectionNumber})`}</Tag>
      );
    }
  }

  handleNoLicense() {
    this.setState({ showLicenseRequired: !this.state.showLicenseRequired });
  }

  getActiveArticle() {
    const { articleNode, currentNode, entityType } = this.props;
    return (
      currentNode &&
      articleNode.node &&
      ((entityType === EntityTypes.LAWITEM &&
        articleNode.node.id === currentNode.parent) ||
        articleNode.node.id === currentNode.node.id)
    );
  }

  render() {
    const {
      articleNode,
      currentNode,
      nodeLevel,
      onArticleNodeClick,
      viewPermission,
      entityType,
      handleOnFavoriteClick,
      favorites,
      permissionView,
      getFavorite,
      singleLevel,
    } = this.props;
    const isActive = this.getActiveArticle();
    const nodeText =
      articleNode.node &&
      this.renderNode(articleNode, nodeLevel, viewPermission, entityType);
    const permission = ADMIN_UPDATE_PERMISSIONS[articleNode?.node.entityType];
    const articleNumber = this.getArticleNumber();

    return (
      <Fragment>
        {this.state.showLicenseRequired && (
          <LicenseRequiredView
            show={this.state.showLicenseRequired}
            onClose={this.handleNoLicense}
          />
        )}
        <div css={style(nodeLevel)}>
          {articleNode.node && (
            <ScrollableAnchor id={`${articleNode.node.id}`}>
              <Row className={'article-row sub' + this.getClass(isActive)}>
                <Col xs={2}>
                  <div
                    onClick={this._handleArticleNodeClick}
                    className="article-section-link"
                  >
                    <div className="article-row-indicator">
                      <div className="d-inline-block" />
                      {articleNumber && (
                        <span className="d-inline ml-2">{articleNumber}</span>
                      )}
                    </div>
                  </div>
                  <div className="article-row-canonical-name">
                    <span className="parent-name" />
                  </div>
                </Col>
                <Col xs={9}>{nodeText}</Col>
                <Col xs={1}>
                  <ControlledByPermission
                    permission={permission}
                    render={
                      <div
                        className="show-on-hover-container pr-2 d-inline-block"
                        onClick={() => this.toggleEditor(articleNode.node)}
                      >
                        <span className="change-color-on-hover-to-brand-color color-gray text-container edit-icon">
                          <FaPencilAlt />
                        </span>
                      </div>
                    }
                  />
                  {entityType === EntityTypes.LAWITEM && (
                    <ControlledByPermission
                      permission={PERMISSIONS.FAVORITE}
                      render={
                        <div className="favorite-icon d-inline-block">
                          <FavoriteIcon
                            favorite={getFavorite(articleNode.node, favorites)}
                            onFavoriteClick={() =>
                              handleOnFavoriteClick(articleNode.node)
                            }
                          />
                        </div>
                      }
                    />
                  )}
                </Col>
              </Row>
            </ScrollableAnchor>
          )}
        </div>
        {this.state.editNode && (
          <ArticleEditor
            show={this.state.showModalEditor}
            title={'article-editor.edit-section'}
            article={this.state.editNode}
            cancelClickHandler={this.toggleEditor}
          ></ArticleEditor>
        )}
        {!singleLevel &&
          articleNode.children &&
          sort(
            Object.values(articleNode.children),
            articleNode.node?.entityType,
          ).map((value, key) => {
            const myKey =
              'article-branch-ArticleNodeChildren-' +
              (entityType || articleNode.node?.entityType) +
              '-' +
              articleNode.node?.id +
              '-' +
              value.node?.id +
              '-' +
              (nodeLevel + 1) +
              '-' +
              key;
            return (
              <ArticleNodeChildren
                key={myKey}
                entityType={entityType}
                articleNode={value}
                nodeLevel={nodeLevel + 1}
                viewPermission={this.props.viewPermission}
                onArticleNodeClick={onArticleNodeClick}
                currentNode={currentNode}
                handleOnFavoriteClick={handleOnFavoriteClick}
                favorites={favorites}
                permissionView={permissionView}
                getFavorite={getFavorite}
              />
            );
          })}
      </Fragment>
    );
  }

  getPartialView(args) {
    const anonymizeIndex = (args.text || '').indexOf('***');
    const viewParts = [];
    let invisiblePart = '';

    if (anonymizeIndex === -1) {
      viewParts.push(<span key={'show'}>{args.text}</span>);
    } else {
      const visiblePart = args.text.substring(0, anonymizeIndex);
      invisiblePart = args.text.substring(anonymizeIndex);
      if (visiblePart.length > 10) {
        const fadeIndex = visiblePart.length - 10;
        const fade = visiblePart.substring(fadeIndex);
        viewParts.push(
          <span key={'fade-part'}>{visiblePart.substring(0, fadeIndex)}</span>,
        );
        fade.split('').forEach((char, index) => {
          viewParts.push(
            <span
              key={'show-opacity-' + index}
              style={{ opacity: 1 - index * 0.1 }}
            >
              {char}
            </span>,
          );
        });
      } else {
        viewParts.push(<span key={'visible-part'}>{visiblePart}</span>);
      }
    }
    return (
      <p onClick={this.handleNoLicense}>
        <span className="d-block visible-part">{viewParts}</span>
        <HiddenText text={invisiblePart.split('').join(' ')} />
      </p>
    );
  }
}

export default ArticleNodeChildren;
