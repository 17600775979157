export const getSignup = (state) => state.getIn(['page', 'signup', 'signup']);
export const getSubmitted = (state) =>
  state.getIn(['page', 'signup', 'submitted']);
export const getResponse = (state) =>
  state.getIn(['page', 'signup', 'response']);
export const getConfirmResponse = (state) =>
  state.getIn(['page', 'signup', 'token']);
export const responseStatusCode = (response) => response.get('statusCode');
export const getResponseMessage = (response) =>
  response.get('responseData')?.get('message') ?? 'error';
