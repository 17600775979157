import { put, take, takeEvery } from 'redux-saga/effects';
import { isEqual } from 'lodash';
import * as ActionTypes from '../../constants/ActionTypes';
import * as processActions from '../../actions/api/process';
import { getAllPages } from '../../actions/api';
import { preloadProcessesCompleted } from '../../actions/data';

function* doPreloadProcess() {
  let initAction = getAllPages(processActions.getAll());
  yield put(initAction);
  const completedAction = yield take(
    (action) =>
      action.type === ActionTypes.TXN_ALL_PAGES_COMPLETE &&
      isEqual(action.payload.initAction, initAction),
  );
  yield put(preloadProcessesCompleted(completedAction.payload.isSuccess));
}

export default function* preloadProcesses() {
  yield takeEvery(ActionTypes.DATA_PRELOAD_PROCESS_INIT, doPreloadProcess);
}
