import * as ActionTypes from '../../../constants/ActionTypes';
import { call, takeEvery } from 'redux-saga/effects';
import { generateApiSaga } from '../../../utils/sagas';
import NewsletterConnector from '../../../connectors/Newsletter';

const doCreateNewsletter = generateApiSaga(
  NewsletterConnector,
  function* (connector, action) {
    const { newsletter } = action.payload;
    return yield call([connector, connector.create], newsletter);
  },
);

export default function* createNewsletter() {
  yield takeEvery(ActionTypes.API_NEWSLETTER_CREATE_INIT, doCreateNewsletter);
}
