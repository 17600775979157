/**
 * @constant
 * Represents name of root properties in the state.
 */
export const STATE_ROOT_PROPERTY = {
  api: 'api',
  page: 'page',
  node: 'node',
};

/**
 * @constant
 * Represents name of entity properties in the state.
 */
export const STATE_ENTITY_PROPERTY = {
  isLoadingRelatedLawItems: 'isLoadingRelatedLawItems',
  data: 'data',
  searchdata: 'searchdata',
  page: 'page',
  isLoading: 'isLoading',
  lawItemTypes: 'lawitemtypes',
  theoryItemTypes: 'theoryitemtypes',
  item: 'item',
  lawItem: 'lawitem',
  law: 'law',
  relatedItems: 'relatedItems',
  profile: 'profile',
};

/**
 * @constant
 * Represents name of entities in the state.
 */
export const STATE_ENTITY = {
  adminHome: 'adminhome',
  articleFooter: 'articlefooter',
  search: 'search',
  decisions: 'decisions',
  processSteps: 'processSteps',
  isNested: 'isNested',
  decisionsCount: 'decisionsCount',
  processStepsCount: 'processStepsCount',
};
