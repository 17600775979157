export const PERMISSIONS = {
  PROCESS_VIEW_CONTENT: 'ROLE_PROCESS_VIEW_CONTENT',
  PROCESS_VIEW_HEADERS: 'ROLE_PROCESS_VIEW_HEADERS',
  PROCESS_VIEW_RELATED_COUNT: 'ROLE_PROCESS_VIEW_RELATED_COUNT',
  PROCESS_FOOTER_SEARCH: 'ROLE_PROCESS_FOOTER_SEARCH',
  PROCESS_FOOTER_VIEW_LAW: 'ROLE_PROCESS_FOOTER_VIEW_LAW',
  PROCESS_FOOTER_VIEW_THEORY: 'ROLE_PROCESS_FOOTER_VIEW_THEORY',
  PROCESS_FOOTER_VIEW_DECISION: 'ROLE_PROCESS_FOOTER_VIEW_DECISION',
  PROCESS_FOOTER_VIEW_TAXONOMY: 'ROLE_PROCESS_FOOTER_VIEW_TAXONOMY',
  PROCESS_FOOTER_NAVIGATE_TO_LAW: 'ROLE_PROCESS_FOOTER_NAVIGATE_TO_LAW',
  PROCESS_FOOTER_NAVIGATE_TO_THEORY: 'ROLE_PROCESS_FOOTER_NAVIGATE_TO_THEORY',
  PROCESS_FOOTER_NAVIGATE_TO_DECISION:
    'ROLE_PROCESS_FOOTER_NAVIGATE_TO_DECISION',
  PROCESS_FOOTER_NAVIGATE_TO_TAXONOMY:
    'ROLE_PROCESS_FOOTER_NAVIGATE_TO_TAXONOMY',
  LAW_VIEW_CONTENT: 'ROLE_LAW_VIEW_CONTENT',
  LAW_VIEW_HEADERS: 'ROLE_LAW_VIEW_HEADERS',
  LAW_VIEW_RELATED_COUNT: 'ROLE_LAW_VIEW_RELATED_COUNT',
  LAW_FOOTER_LAW_RED_THREAD: 'ROLE_LAW_FOOTER_LAW_RED_THREAD',
  LAW_FOOTER_LAW_RED_THREAD_SORTED: 'ROLE_LAW_FOOTER_LAW_RED_THREAD_SORTED',
  LAW_FOOTER_SEARCH: 'ROLE_LAW_FOOTER_SEARCH',
  LAW_FOOTER_VIEW_LAW: 'ROLE_LAW_FOOTER_VIEW_LAW',
  LAW_FOOTER_VIEW_THEORY: 'ROLE_LAW_FOOTER_VIEW_THEORY',
  LAW_FOOTER_VIEW_DECISION: 'ROLE_LAW_FOOTER_VIEW_DECISION',
  LAW_FOOTER_VIEW_PROCESS: 'ROLE_LAW_FOOTER_VIEW_PROCESS',
  LAW_FOOTER_VIEW_TAXONOMY: 'ROLE_LAW_FOOTER_VIEW_TAXONOMY',
  LAW_FOOTER_NAVIGATE_TO_LAW: 'ROLE_LAW_FOOTER_NAVIGATE_TO_LAW',
  LAW_FOOTER_NAVIGATE_TO_THEORY: 'ROLE_LAW_FOOTER_NAVIGATE_TO_THEORY',
  LAW_FOOTER_NAVIGATE_TO_DECISION: 'ROLE_LAW_FOOTER_NAVIGATE_TO_DECISION',
  LAW_FOOTER_NAVIGATE_TO_PROCESS: 'ROLE_LAW_FOOTER_NAVIGATE_TO_PROCESS',
  LAW_FOOTER_NAVIGATE_TO_TAXONOMY: 'ROLE_LAW_FOOTER_NAVIGATE_TO_TAXONOMY',
  LAW_FOOTER_NAVIGATE_RED_THREAD: 'ROLE_LAW_FOOTER_NAVIGATE_RED_THREAD',
  DECISION_VIEW_CONTENT: 'ROLE_DECISION_VIEW_CONTENT',
  DECISION_VIEW_ANONYMOUS: 'ROLE_DECISION_VIEW_ANONYMOUS',
  DECISION_VIEW_RELATED_COUNT: 'ROLE_DECISION_VIEW_RELATED_COUNT',
  DECISION_FOOTER_LAW_RED_THREAD: 'ROLE_DECISION_FOOTER_LAW_RED_THREAD',
  DECISION_FOOTER_SEARCH: 'ROLE_DECISION_FOOTER_SEARCH',
  DECISION_FOOTER_VIEW_LAW: 'ROLE_DECISION_FOOTER_VIEW_LAW',
  DECISION_FOOTER_VIEW_THEORY: 'ROLE_DECISION_FOOTER_VIEW_THEORY',
  DECISION_FOOTER_VIEW_PROCESS: 'ROLE_DECISION_FOOTER_VIEW_PROCESS',
  DECISION_FOOTER_VIEW_TAXONOMY: 'ROLE_DECISION_FOOTER_VIEW_TAXONOMY',
  DECISION_FOOTER_NAVIGATE_TO_LAW: 'ROLE_DECISION_FOOTER_NAVIGATE_TO_LAW',
  DECISION_FOOTER_NAVIGATE_TO_THEORY: 'ROLE_DECISION_FOOTER_NAVIGATE_TO_THEORY',
  DECISION_FOOTER_NAVIGATE_TO_PROCESS:
    'ROLE_DECISION_FOOTER_NAVIGATE_TO_PROCESS',
  DECISION_FOOTER_NAVIGATE_TO_TAXONOMY:
    'ROLE_DECISION_FOOTER_NAVIGATE_TO_TAXONOMY',
  DECISION_FOOTER_NAVIGATE_LAW_RED_THREAD:
    'ROLE_DECISION_FOOTER_NAVIGATE_LAW_RED_THREAD',
  THEORY_VIEW_ANONYMOUS: 'ROLE_THEORY_VIEW_ANONYMOUS',
  THEORY_VIEW_CONTENT: 'ROLE_THEORY_VIEW_CONTENT',
  THEORY_VIEW_HEADERS: 'ROLE_THEORY_VIEW_HEADERS',
  THEORY_VIEW_RELATED_COUNT: 'ROLE_THEORY_VIEW_RELATED_COUNT',
  THEORY_FOOTER_SEARCH: 'ROLE_THEORY_FOOTER_SEARCH',
  THEORY_FOOTER_VIEW_LAW: 'ROLE_THEORY_FOOTER_VIEW_LAW',
  THEORY_FOOTER_VIEW_DECISION: 'ROLE_THEORY_FOOTER_VIEW_DECISION',
  THEORY_FOOTER_VIEW_PROCESS: 'ROLE_THEORY_FOOTER_VIEW_PROCESS',
  THEORY_FOOTER_VIEW_TAXONOMY: 'ROLE_THEORY_FOOTER_VIEW_TAXONOMY',
  THEORY_FOOTER_NAVIGATE_TO_LAW: 'ROLE_THEORY_FOOTER_NAVIGATE_TO_LAW',
  THEORY_FOOTER_NAVIGATE_TO_DECISION: 'ROLE_THEORY_FOOTER_NAVIGATE_TO_DECISION',
  THEORY_FOOTER_NAVIGATE_TO_PROCESS: 'ROLE_THEORY_FOOTER_NAVIGATE_TO_PROCESS',
  THEORY_FOOTER_NAVIGATE_TO_TAXONOMY: 'ROLE_THEORY_FOOTER_NAVIGATE_TO_TAXONOMY',
  TAXONOMY_VIEW_CONTENT_KEYWORD: 'ROLE_TAXONOMY_VIEW_CONTENT_KEYWORD',
  TAXONOMY_VIEW_CONTENT_CONTRACT_PERFORMANCE:
    'ROLE_TAXONOMY_VIEW_CONTENT_CONTRACT_PERFORMANCE',
  TAXONOMY_VIEW_CONTENT_CPV: 'ROLE_TAXONOMY_VIEW_CONTENT_CPV',
  TAXONOMY_VIEW_RELATED_COUNT: 'ROLE_TAXONOMY_VIEW_RELATED_COUNT',
  TAXONOMY_FOOTER_SEARCH: 'ROLE_TAXONOMY_FOOTER_SEARCH',
  TAXONOMY_FOOTER_VIEW_LAW: 'ROLE_TAXONOMY_FOOTER_VIEW_LAW',
  TAXONOMY_FOOTER_VIEW_DECISION: 'ROLE_TAXONOMY_FOOTER_VIEW_DECISION',
  TAXONOMY_FOOTER_VIEW_THEORY: 'ROLE_TAXONOMY_FOOTER_VIEW_THEORY',
  TAXONOMY_FOOTER_NAVIGATE_TO_LAW: 'ROLE_TAXONOMY_FOOTER_NAVIGATE_TO_LAW',
  TAXONOMY_FOOTER_NAVIGATE_TO_DECISION:
    'ROLE_TAXONOMY_FOOTER_NAVIGATE_TO_DECISION',
  TAXONOMY_FOOTER_NAVIGATE_TO_THEORY: 'ROLE_TAXONOMY_FOOTER_NAVIGATE_TO_THEORY',
  SEARCH_VIEW_CONTENT: 'ROLE_SEARCH_VIEW_CONTENT',
  SEARCH_VIEW_FILTERS: 'ROLE_SEARCH_VIEW_FILTERS',
  SEARCH_FREETEXT: 'ROLE_SEARCH_FREETEXT',
  SEARCH_GENERAL_FILTERS: 'ROLE_SEARCH_GENERAL_FILTERS',
  SEARCH_ADVANCED_FILTERS: 'ROLE_SEARCH_ADVANCED_FILTERS',
  FEEDS_DAILY: 'ROLE_FEEDS_DAILY',
  FEEDS_WEEKLY: 'ROLE_FEEDS_WEEKLY',
  FEEDS_ADVANCED: 'ROLE_FEEDS_ADVANCED',
  FAVORITE: 'ROLE_FAVORITE',
  LAW_UPDATE_CONTENT: 'ROLE_LAW_UPDATE_CONTENT',
  TAXONOMY_UPDATE_CONTENT: 'ROLE_TAXONOMY_UPDATE_CONTENT',
  THEORY_UPDATE_CONTENT: 'ROLE_THEORY_UPDATE_CONTENT',
  DECISION_UPDATE_CONTENT: 'ROLE_DECISION_UPDATE_CONTENT',
  PROCESS_UPDATE_CONTENT: 'ROLE_PROCESS_UPDATE_CONTENT',
  USER_VIEW_NEWS_SUBSCRIPTIONS: 'ROLE_USER_VIEW_NEWS_SUBSCRIPTIONS',
  USER_CREATE_LICENSE: 'ROLE_USER_CREATE_LICENSE',
  USER_MANAGE_ROLES: 'ROLE_USER_MANAGE_ROLES',
  TOGGLE_JURISDICTION: 'ROLE_TOGGLE_JURISDICTION',
};

export const ADMIN_UPDATE_PERMISSIONS = {
  lawitem: PERMISSIONS.LAW_UPDATE_CONTENT,
  law: PERMISSIONS.LAW_UPDATE_CONTENT,
  theory: PERMISSIONS.THEORY_UPDATE_CONTENT,
  theoryitem: PERMISSIONS.THEORY_UPDATE_CONTENT,
};
export const VIEW_PERMISSIONS = {
  lawitem: PERMISSIONS.LAW_VIEW_CONTENT,
  law: PERMISSIONS.LAW_VIEW_CONTENT,
  theory: PERMISSIONS.THEORY_VIEW_CONTENT,
  theoryitem: PERMISSIONS.THEORY_VIEW_CONTENT,
};

export const THEORY_FOOTER_PERMISSIONS = {
  lawView: PERMISSIONS.THEORY_FOOTER_VIEW_LAW,
  theoryView: null,
  decisionView: PERMISSIONS.THEORY_FOOTER_VIEW_DECISION,
  processStepView: PERMISSIONS.THEORY_FOOTER_VIEW_PROCESS,
  taxonomyView: PERMISSIONS.THEORY_FOOTER_VIEW_TAXONOMY,
  lawNavigate: PERMISSIONS.THEORY_FOOTER_NAVIGATE_TO_LAW,
  decisionViewRedThread: PERMISSIONS.DECISION_FOOTER_LAW_RED_THREAD,
  theoryNavigate: null,
  decisionNavigate: PERMISSIONS.THEORY_FOOTER_NAVIGATE_TO_DECISION,
  processStepNavigate: PERMISSIONS.THEORY_FOOTER_NAVIGATE_TO_PROCESS,
  taxonomyNavigate: PERMISSIONS.THEORY_FOOTER_NAVIGATE_TO_TAXONOMY,
  history: null,
  count: PERMISSIONS.THEORY_VIEW_RELATED_COUNT,
};

export const LAW_FOOTER_PERMISSIONS = {
  lawView: PERMISSIONS.LAW_FOOTER_LAW_RED_THREAD,
  theoryView: PERMISSIONS.LAW_FOOTER_VIEW_THEORY,
  decisionViewRedThread: PERMISSIONS.DECISION_FOOTER_LAW_RED_THREAD,
  decisionView: PERMISSIONS.LAW_FOOTER_VIEW_DECISION,
  processStepView: PERMISSIONS.LAW_FOOTER_VIEW_PROCESS,
  taxonomyView: PERMISSIONS.LAW_FOOTER_VIEW_TAXONOMY,
  lawNavigate: PERMISSIONS.LAW_FOOTER_NAVIGATE_TO_LAW,
  theoryNavigate: PERMISSIONS.LAW_FOOTER_NAVIGATE_TO_THEORY,
  decisionNavigate: PERMISSIONS.LAW_FOOTER_NAVIGATE_TO_DECISION,
  processStepNavigate: PERMISSIONS.LAW_FOOTER_NAVIGATE_TO_PROCESS,
  taxonomyNavigate: PERMISSIONS.LAW_FOOTER_NAVIGATE_TO_TAXONOMY,
  history: [
    PERMISSIONS.LAW_FOOTER_LAW_RED_THREAD,
    PERMISSIONS.LAW_FOOTER_LAW_RED_THREAD_SORTED,
  ],
  count: PERMISSIONS.LAW_VIEW_RELATED_COUNT,
};

export const DECISION_FOOTER_PERMISSIONS = {
  lawView: PERMISSIONS.DECISION_FOOTER_VIEW_LAW,
  decisionViewRedThread: PERMISSIONS.DECISION_FOOTER_LAW_RED_THREAD,
  theoryView: PERMISSIONS.DECISION_FOOTER_VIEW_THEORY,
  decisionView: null,
  processStepView: PERMISSIONS.DECISION_FOOTER_VIEW_PROCESS,
  taxonomyView: PERMISSIONS.DECISION_FOOTER_VIEW_TAXONOMY,
  lawNavigate: PERMISSIONS.DECISION_FOOTER_NAVIGATE_TO_LAW,
  theoryNavigate: PERMISSIONS.DECISION_FOOTER_NAVIGATE_TO_THEORY,
  decisionNavigate: null,
  processStepNavigate: PERMISSIONS.DECISION_FOOTER_NAVIGATE_TO_PROCESS,
  taxonomyNavigate: PERMISSIONS.DECISION_FOOTER_NAVIGATE_TO_TAXONOMY,
  history: [PERMISSIONS.DECISION_FOOTER_NAVIGATE_LAW_RED_THREAD],
  count: PERMISSIONS.DECISION_VIEW_RELATED_COUNT,
};

export const PROCESS_FOOTER_PERMISSIONS = {
  lawView: PERMISSIONS.PROCESS_FOOTER_VIEW_LAW,
  theoryView: PERMISSIONS.PROCESS_FOOTER_VIEW_THEORY,
  decisionView: PERMISSIONS.PROCESS_FOOTER_VIEW_DECISION,
  decisionViewRedThread: PERMISSIONS.DECISION_FOOTER_LAW_RED_THREAD,
  processStepView: null,
  taxonomyView: PERMISSIONS.PROCESS_FOOTER_VIEW_TAXONOMY,
  lawNavigate: PERMISSIONS.PROCESS_FOOTER_NAVIGATE_TO_LAW,
  theoryNavigate: PERMISSIONS.PROCESS_FOOTER_NAVIGATE_TO_THEORY,
  decisionNavigate: PERMISSIONS.PROCESS_FOOTER_NAVIGATE_TO_DECISION,
  processStepNavigate: null,
  taxonomyNavigate: PERMISSIONS.PROCESS_FOOTER_NAVIGATE_TO_TAXONOMY,
  history: null,
  count: PERMISSIONS.PROCESS_VIEW_RELATED_COUNT,
};

export const TAXONOMY_FOOTER_PERMISSIONS = {
  lawView: PERMISSIONS.TAXONOMY_FOOTER_VIEW_LAW,
  theoryView: PERMISSIONS.TAXONOMY_FOOTER_VIEW_THEORY,
  decisionView: PERMISSIONS.TAXONOMY_FOOTER_VIEW_DECISION,
  decisionViewRedThread: PERMISSIONS.DECISION_FOOTER_LAW_RED_THREAD,
  processStepView: null,
  taxonomyView: null,
  lawNavigate: PERMISSIONS.TAXONOMY_FOOTER_NAVIGATE_TO_LAW,
  theoryNavigate: PERMISSIONS.TAXONOMY_FOOTER_NAVIGATE_TO_THEORY,
  decisionNavigate: PERMISSIONS.TAXONOMY_FOOTER_NAVIGATE_TO_DECISION,
  processStepNavigate: null,
  taxonomyNavigate: null,
  count: PERMISSIONS.TAXONOMY_VIEW_RELATED_COUNT,
};

export const ANONYMOUS_PERMISSIONS = [
  PERMISSIONS.PROCESS_VIEW_CONTENT,
  PERMISSIONS.PROCESS_VIEW_HEADERS,
  PERMISSIONS.PROCESS_VIEW_RELATED_COUNT,
  PERMISSIONS.LAW_VIEW_HEADERS,
  PERMISSIONS.LAW_VIEW_RELATED_COUNT,
  PERMISSIONS.DECISION_VIEW_ANONYMOUS,
  PERMISSIONS.DECISION_VIEW_RELATED_COUNT,
  PERMISSIONS.THEORY_VIEW_ANONYMOUS,
  PERMISSIONS.THEORY_VIEW_HEADERS,
  PERMISSIONS.THEORY_VIEW_RELATED_COUNT,
  PERMISSIONS.TAXONOMY_VIEW_RELATED_COUNT,
  PERMISSIONS.SEARCH_VIEW_FILTERS,
  PERMISSIONS.SEARCH_VIEW_CONTENT,
];

export const ADMIN_ONLY_PERMISSIONS = [
  PERMISSIONS.LAW_UPDATE_CONTENT,
  PERMISSIONS.TAXONOMY_UPDATE_CONTENT,
  PERMISSIONS.THEORY_UPDATE_CONTENT,
  PERMISSIONS.DECISION_UPDATE_CONTENT,
  PERMISSIONS.PROCESS_UPDATE_CONTENT,
  PERMISSIONS.USER_VIEW_NEWS_SUBSCRIPTIONS,
  PERMISSIONS.USER_CREATE_LICENSE,
  PERMISSIONS.USER_MANAGE_ROLES,
];
