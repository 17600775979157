import * as ActionTypes from '../../constants/ActionTypes';

export const requestSignup = (signup) => ({
  type: ActionTypes.API_SIGNUP_SUBMIT_INIT,
  payload: { signup },
});
export const confirmSignup = (token) => ({
  type: ActionTypes.API_SIGNUP_CONFIRM_INIT,
  payload: { token },
});
