import { call, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import TaxonomyConnector from '../../../connectors/Taxonomy';
import { generateApiSaga } from '../../../utils/sagas';
import * as Projections from '../../../constants/Projections';

const doGet = generateApiSaga(TaxonomyConnector, function* (connector, action) {
  return yield call(
    [connector, connector.getById],
    action.payload.id,
    Projections.RELATION_TREE,
  );
});

export default function* getRelationTree() {
  yield takeEvery(ActionTypes.API_TAXONOMY_GET_RELATION_TREE_BY_ID_INIT, doGet);
}
