import { call, select, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import TheoryItemConnector from '../../../connectors/TheoryItem';
import { generateApiSaga } from '../../../utils/sagas';
import * as theoryItemApiSelector from '../../../selectors/api/theoryitem';
import { createTagName } from '../../../utils/filter';

const doSearch = generateApiSaga(
  TheoryItemConnector,
  function* (connector, action) {
    const title = action.payload.title;
    const theoryItemData = yield select(theoryItemApiSelector.getSearchData);
    if (!theoryItemData.get(createTagName(title))) {
      return yield call([connector, connector.searchByTitle], title);
    }
  },
);

export default function* findByTitle() {
  yield takeEvery(ActionTypes.API_FIND_THEORY_ITEM_BY_TITLE_INIT, doSearch);
}
