import * as ActionTypes from '../../constants/ActionTypes';
import SearchFilter from '../../utils/searchfilter';

export const updateFilter = (newFilter) => ({
  type: ActionTypes.PAGE_SEARCH_FILTER_UPDATE_INIT,
  payload: {
    filter: newFilter,
  },
});

export const setFilter = (filter) => ({
  type: ActionTypes.PAGE_SEARCH_FILTER_UPDATE_SET,
  payload: { filter },
});

export const active = () => ({
  type: ActionTypes.PAGE_SEARCH_SEARCH_ACTIVE,
});

export const updateFilterComplete = () => ({
  type: ActionTypes.PAGE_SEARCH_FILTER_UPDATE_COMPLETE,
});

export const runSearch = (filterOrSearchText, navigateToSearchPage = false) => {
  let filter = filterOrSearchText;
  if (typeof filterOrSearchText === 'string') {
    filter = new SearchFilter();
    filterOrSearchText.length && (filter.searchText = filterOrSearchText);
  }
  return {
    type: ActionTypes.PAGE_SEARCH_SEARCH_INIT,
    payload: { filter, navigateToSearchPage },
  };
};

export const searchSuccessful = (searchResult, appliedFilter) => ({
  type: ActionTypes.PAGE_SEARCH_SEARCH_SUCCESS,
  payload: {
    filter: appliedFilter,
    searchResult,
  },
});

export const searchError = (message, appliedFilter = null) => ({
  type: ActionTypes.PAGE_SEARCH_SEARCH_ERROR,
  payload: {
    filter: appliedFilter,
    message,
  },
});

export const searchAbort = (appliedFilter = null) => ({
  type: ActionTypes.PAGE_SEARCH_SEARCH_ABORT,
  payload: {
    filter: appliedFilter,
  },
});

export const changeTab = (tabName) => ({
  type: ActionTypes.PAGE_SEARCH_TAB_CHANGE_INIT,
  payload: {
    tab: tabName,
  },
});

export const setTab = (tabName) => ({
  type: ActionTypes.PAGE_SEARCH_TAB_CHANGE_SET,
  payload: {
    tab: tabName,
  },
});

export const fetchResults = () => ({
  type: ActionTypes.PAGE_SEARCH_FETCH_RESULTS_INIT,
});

export const fetchResultsCompleted = () => ({
  type: ActionTypes.PAGE_SEARCH_FETCH_RESULTS_COMPLETED,
});

export const fetchResultsRequest = () => ({
  type: ActionTypes.PAGE_SEARCH_FETCH_RESULTS_REQUEST,
});
