import { all, fork } from 'redux-saga/effects';
import getAll from './getall';
import getAllBySectionAndType from './getallbysectionandtype';
import getallbyuri from './getallbyuri';
import getAllChildren from './getallchildren';
import getAllTopLevel from './getalltoplevel';
import getAllSections from './getallsections';
import getByIds from './getbyids';
import getByIdWithParents from './getByIdWithParents';
import findByTitle from './findbytitle';
import getNewest from './getnewest';
import getRelationTree from './getrelationtree';
import findUriToSelect from './finduritoselect';
import getRelatedById from './getrelatedbyid';
import getAllTheoryItemTypes from './gettheoryitemtypes';
import createTheoryItem from './createtheoryitem';
import deleteItem from './deletebyid';

export default function* theoryRootSaga() {
  yield all([
    fork(getAll),
    fork(getAllBySectionAndType),
    fork(getallbyuri),
    fork(getAllChildren),
    fork(getAllTopLevel),
    fork(getByIds),
    fork(getByIdWithParents),
    fork(getAllSections),
    fork(findByTitle),
    fork(getNewest),
    fork(getRelationTree),
    fork(findUriToSelect),
    fork(getRelatedById),
    fork(getAllTheoryItemTypes),
    fork(createTheoryItem),
    fork(deleteItem),
  ]);
}
