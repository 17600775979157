import * as ActionTypes from '../../../constants/ActionTypes';
import { debounce, put } from 'redux-saga/effects';
import { effectuateSetFilterText } from '../../../actions/page/taxonomy';

function* doSetSearchText(action) {
  yield put(effectuateSetFilterText(action.payload.filterText));
}

export default function* setFilterText() {
  yield debounce(
    1000,
    ActionTypes.APP_PAGE_TAXONOMY_SET_FILTERTEXT_INIT,
    doSetSearchText,
  );
}
