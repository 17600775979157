import { generateApiSaga } from '../../../utils/sagas';
import lawItemConnector from '../../../connectors/LawItem';
import { call, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import * as Projections from '../../../constants/Projections';

const goGetLawItem = generateApiSaga(
  lawItemConnector,
  function* (connector, action) {
    const { id } = action.payload;
    return yield call(
      [connector, connector.getLawItemById],
      id,
      Projections.RELATED,
    );
  },
);

export default function* getRelatedById() {
  yield takeEvery(
    ActionTypes.API_LAW_ITEM_GET_RELATION_BY_ID_INIT,
    goGetLawItem,
  );
}
