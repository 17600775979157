import * as ActionTypes from '../../../constants/ActionTypes';
import { generateApiSaga } from '../../../utils/sagas';
import { call, takeEvery } from 'redux-saga/effects';
import LicenseConnector from '../../../connectors/License';

const doCreateLicense = generateApiSaga(
  LicenseConnector,
  function* (connector) {
    return yield call([connector, connector.getAvailable]);
  },
);

export default function* getAvailableLicenses() {
  yield takeEvery(ActionTypes.API_LICENSE_GET_AVAILABLE_INIT, doCreateLicense);
}
