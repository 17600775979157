/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import { Form, Modal } from 'react-bootstrap';
import * as createTaxonomyPageSelector from '../../selectors/page/taxonomy';
import Filter from '../../components/common/molecules/Filter';
import Text, { TextType } from '../../components/common/atoms/Text';
import { Trans } from 'react-i18next';
import Input from '../../components/common/atoms/Input';
import { refBind } from '../../utils/ref-bind';
import Button from '../../components/common/atoms/Button';
import { fontColors, fontSize } from '../../components/common/styles/styles';
import TaxonomyType from '../../constants/TaxonomyType';
import * as taxonomyActions from '../../actions/page/taxonomy';
import PropTypes from 'prop-types';

const styles = {
  '.layout-container': {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridGap: '.5rem 1rem',
    marginBottom: '3rem',
  },
  '.modal-body': {
    height: '70vh',
    overflow: 'auto',
  },
  '.error': {
    height: '5px',
    fontSize: fontSize.PARAGRAPH_FOOTER,
    color: fontColors.RED,
  },
};

class CreateTaxonomy extends React.Component {
  constructor(props) {
    super(props);
    this.state = createTaxonomyPageSelector.getEmptyState();
    refBind(
      this,
      'renderForm',
      'submitTaxonomy',
      'setStateByStateName',
      'getError',
    );
  }

  static propTypes = {
    types: PropTypes.array.isRequired,
    show: PropTypes.bool,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
  };

  static defaultProps = {
    show: false,
    onSubmit: () => {},
    onCancel: () => {},
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.props.show && prevProps.show) {
      this.setState(createTaxonomyPageSelector.getEmptyState());
    }
  }

  async submitTaxonomy() {
    const { actions, onSubmit } = this.props;
    const { description, type, code } = this.state;
    if (description && type !== null) {
      if (type === 1 && !code) {
        return;
      }
      actions.createTaxonomyAction(
        createTaxonomyPageSelector.mapTaxonomyStateToTaxonomy(this.state),
      );
      onSubmit();
    }
  }

  getError(hasError) {
    return (
      <div className="error my-2">
        {' '}
        {hasError && <Trans i18nKey="add-taxonomy.required" />}
      </div>
    );
  }

  setStateByStateName(event, stateName, isFilter) {
    if (isFilter) {
      this.setState({ [stateName]: event.value });
    } else {
      this.setState({ [stateName]: event.target.value });
    }
  }

  renderInput(key, value, hasErrors, disabled) {
    return (
      <div>
        <Form.Label>
          <span className="text-title-case">
            <Trans i18nKey={`add-taxonomy.${key}`} />:
          </span>
        </Form.Label>
        <Input
          disabled={disabled}
          type={Input.FORM}
          value={value}
          onChange={(event) => this.setStateByStateName(event, key)}
        />
        {this.getError(hasErrors)}
      </div>
    );
  }

  renderFilter(key, value, options, hasErrors) {
    const displayValue =
      value !== null
        ? (options || []).find(
            (option) => option.value === value || option.label === value,
          )
        : null;
    return (
      <div>
        <Form.Label>
          <span className="text-title-case">
            <Trans i18nKey={`add-taxonomy.${key}`} />:
          </span>
        </Form.Label>
        <Filter
          options={options}
          value={displayValue}
          onChange={(value) => this.setStateByStateName(value, key, true)}
        />
        {this.getError(hasErrors)}
      </div>
    );
  }

  renderForm() {
    const { description, type, code } = this.state;
    const { types } = this.props;
    return (
      <div className="layout-container">
        {this.renderInput('description', description, !description)}
        {this.renderFilter('type', type, types, type === null)}
        {this.renderInput('code', code, type === 1 && !code, type !== 1)}
      </div>
    );
  }

  render() {
    const { show, onCancel } = this.props;
    const keys = createTaxonomyPageSelector.getLocalizationKeys();

    return (
      <Modal show={show} css={styles} size="xl" scrollable>
        <Modal.Header>
          <Modal.Title>
            <Text as="span" type={TextType.HEADER_1_SMALL}>
              <Trans i18nKey={keys.title} />
            </Text>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="modal-body scroll-bar">
          {show && this.renderForm()}
        </Modal.Body>
        <Modal.Footer>
          <div className="my-3 w-100 d-flex justify-content-between">
            <Button type={Button.PRIMARY_DARK} onClick={onCancel}>
              <span className="text-title-case">
                <Trans i18nKey="add-taxonomy.cancel-button" />
              </span>
            </Button>

            <Button type={Button.PRIMARY_BRAND} onClick={this.submitTaxonomy}>
              <span className="text-title-case">
                <Trans i18nKey={keys.submitBtn} />
              </span>
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const types = createTaxonomyPageSelector.mapTypesToOptions(TaxonomyType);
  return {
    ...ownProps,
    types,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      createTaxonomyAction: taxonomyActions.createTaxonomy,
    },
    dispatch,
  ),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withImmutablePropsToJS,
)(CreateTaxonomy);
