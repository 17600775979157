export const STANDARD = 'standard';
export const TOP = 'top';
export const PARENTS = 'parents';
export const EXPAND = 'expanded';
export const WITHID = 'withId';
export const NAME = 'name';
export const HISTORY = 'history';
export const RELATED = 'related';
export const RELATED_IDS = 'relatedIds';
export const RELATION_TREE = 'relationTree';
export const WITH_JURISDICTION = 'withJurisdiction';
export const WITH_LAW_ID = 'withLawId';
export const PROJECTION_SEARCH_SUGGESTION = 'searchSuggestion';
