/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { FaRegStar, FaStar } from 'react-icons/all';
import { uiColors } from '../styles/styles';

const styles = {
  width: 20,
  height: 20,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  '& :hover': {
    transform: 'scale(1.05)',
    color: uiColors.BRAND,
  },
};

class FavoriteIcon extends React.Component {
  getEnabledFavoriteIcon() {
    return (
      <span className="color-primary">
        <FaStar />
      </span>
    );
  }

  getDisabledFavoriteIcon() {
    return (
      <span className="color-gray">
        <FaRegStar />
      </span>
    );
  }

  render() {
    const { favorite, onFavoriteClick, className } = this.props;
    const favoriteIcon =
      favorite && favorite.active
        ? this.getEnabledFavoriteIcon()
        : this.getDisabledFavoriteIcon();
    return (
      <div css={styles} className={className} onClick={onFavoriteClick}>
        <span
          className={`favorite-icon-container ${
            favorite && favorite.active ? 'favorite' : null
          }`}
        >
          {favoriteIcon}
        </span>
      </div>
    );
  }
}

export default FavoriteIcon;
