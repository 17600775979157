import { put, select, take, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../constants/ActionTypes';
import * as appActions from '../../actions/app';
import * as authActions from '../../actions/auth';
import * as appSelector from '../../selectors/store/app';

const header = String.raw`
______                                                             _    _      _         _
| ___ \                                                           | |  | |    (_)       | |
| |_/ /_ __  ___    ___  _   _  _ __  ___  _ __ ___    ___  _ __  | |_ | |     _  _ __  | | __
|  __/| '__|/ _ \  / __|| | | || '__|/ _ \| '_ ' _ \  / _ \| '_ \ | __|| |    | || '_ \ | |/ /
| |   | |  | (_) || (__ | |_| || |  |  __/| | | | | ||  __/| | | || |_ | |____| || | | ||   <
\_|   |_|   \___/  \___| \__,_||_|   \___||_| |_| |_| \___||_| |_| \__|\_____/|_||_| |_||_|\_\
==============================================================================================
`;

function* doBootstrap() {
  yield put(appActions.bootstrapStarted());

  const buildVersion = yield select(appSelector.buildVersion);
  const buildEnv = yield select(appSelector.buildEnv);
  const spaces = ' '.repeat(
    94 - 18 - 2 - buildVersion.length - buildEnv.length,
  );
  const version = `ProcurementLink - ${buildEnv}${spaces}(${buildVersion})`;
  console.log(`${header}${version}`);

  yield put(authActions.initAuth());
  yield take(ActionTypes.AUTH_COMPLETED);

  // const isLoggedIn = yield select(authSelectors.isLoggedIn);
  // if(!isLoggedIn) yield put(appActions.relocateToLoginPage());

  yield put(appActions.bootstrapCompleted());
}

export default function* bootstrap() {
  yield takeEvery(ActionTypes.APP_BOOTSTRAP_INIT, doBootstrap);
}
