import { put, takeLatest } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import * as routerActionSelector from '../../../selectors/actions/router';
import * as signupPageActions from '../../../actions/page/signup';
import { SIGNUP } from '../../../constants/Resources';

function* doLocationChange(action) {
  const path = routerActionSelector.getSplitPath(action);

  if (!path.length) return;
  const [entity, ...uri] = path;

  if (entity === SIGNUP) {
    yield put(signupPageActions.pageLoad(uri));
  }
}

export default function* signupPageOpen() {
  yield takeLatest(ActionTypes.APP_ROUTER_LOCATION_CHANGE, doLocationChange);
}
