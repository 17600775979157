/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import GenericForm from './GenericForm';

class UserForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getForm(this.props);
    this.props.defaults &&
      Object.entries(this.props.defaults).forEach(([key, value]) => {
        if (this.state.form[key]) {
          this.state.form[key].value = value;
        }
      });
  }

  componentDidUpdate(prevProps) {
    if (this.props.disabled != prevProps.disabled) {
      this.setState(this.getForm(this.props));
    }
  }

  getForm({ user, disabled }) {
    return {
      form_name: 'user',
      form: {
        firstName: {
          value: user?.firstName ?? '',
          type: 'text',
          required: true,
          pattern: '[^0-9]+',
          disabled: disabled,
        },
        lastName: {
          value: user?.lastName ?? '',
          type: 'text',
          required: true,
          pattern: '[^0-9]+',
          disabled: disabled,
        },
        email: {
          value: user?.email ?? '',
          type: 'email',
          required: true,
          pattern: '(.*?)+@(.*)+',
          autoComplete: 'username',
          disabled: disabled,
        },
        phoneNumber: {
          value: user?.phoneNumber ?? '',
          type: 'text',
          required: !this.props.isUpdate,
          pattern: '^([+]{0,1}[0-9]{5,15})$',
          disabled: disabled,
        },
      },
    };
  }

  render() {
    const { numberOfColumns, onChange } = this.props;
    return (
      <GenericForm
        form={this.state}
        numberOfColumns={numberOfColumns}
        onChange={onChange}
      />
    );
  }
}

export default withTranslation()(UserForm);
