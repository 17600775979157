import { call, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import { generateApiSaga } from '../../../utils/sagas';
import CourtConnector from '../../../connectors/Court';

const doGetAllCourts = generateApiSaga(
  CourtConnector,
  function* (connector, action) {
    const { pagesize, page } = action.payload.paging;
    return yield call([connector, connector.getAll], pagesize, page);
  },
);

export default function* getAll() {
  yield takeEvery(ActionTypes.API_COURT_GET_ALL_INIT, doGetAllCourts);
}
