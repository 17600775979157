import { call, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import lawItemConnector from '../../../connectors/LawItem';
import { generateApiSaga } from '../../../utils/sagas';
import { getEntityId } from '../../../utils/entities';

const doGetTopLevel = generateApiSaga(
  lawItemConnector,
  function* (connector, action) {
    const nodeId = getEntityId(action.payload.node);
    return yield call([connector, connector.getTopLevel], nodeId);
  },
);

export default function* getTopLevelByLawId() {
  yield takeEvery(
    ActionTypes.API_LAWITEM_GET_TOP_LEVEL_BY_LAW_INIT,
    doGetTopLevel,
  );
}
