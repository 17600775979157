/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import GenericTable from '../molecules/GenericTable';
import { camelizeKebabCase, formatDate } from '../../../utils/stringHelper';
import { faEdit, faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../atoms/Button';
import i18n from 'i18next';

const buttonStyles = (numberOfButtons) => ({
  '.row-button': {
    maxWidth: `${(100 - 2 * numberOfButtons) / numberOfButtons}%`,
  },
});

export const getTableColumnHeaders = () =>
  Object.entries(
    i18n.t('user-license-table.column-headers', { returnObjects: true }),
  ).map(([key, value]) => ({
    key: key,
    fieldAccessor: camelizeKebabCase(key),
    name: value,
  }));

export const getTableRows = (licenses) =>
  (licenses || []).map((license) => ({
    license: license,
    createdDate: license.createdDate
      ? formatDate(license.createdDate)
      : undefined,
    expirationDate: license.expirationDate
      ? formatDate(license.expirationDate)
      : undefined,
    role: license.role?.roleName,
  }));

class UserLicenseTable extends React.Component {
  constructor(props) {
    super(props);
  }

  static propTypes = {
    licenses: PropTypes.arrayOf(PropTypes.object),
    disabledButtons: PropTypes.bool,
    onUpdateRow: PropTypes.func,
    onDeleteRow: PropTypes.func,
  };

  static defaultProps = {
    licenses: [],
  };

  renderRowButtons = (row) => {
    const functions = [
      { fn: this.props.onDeleteRow, icon: faMinus },
      { fn: this.props.onUpdateRow, icon: faEdit },
    ].filter((x) => x.fn !== undefined);

    return functions ? (
      <div
        className={'d-flex justify-content-between'}
        css={buttonStyles(functions.length)}
      >
        {functions.map((x) => (
          <Button
            type={Button.PRIMARY_SMALL}
            className={'row-button'}
            onClick={() => x.fn(row)}
            disabled={this.props.disabledButtons}
          >
            <FontAwesomeIcon className={'icon'} icon={x.icon} />
          </Button>
        ))}
      </div>
    ) : undefined;
  };

  render() {
    const columns = getTableColumnHeaders();
    const rows = getTableRows(this.props.licenses);

    return (
      <GenericTable
        columns={columns}
        rows={rows}
        renderRowButton={this.renderRowButtons}
      />
    );
  }
}

export default withTranslation()(UserLicenseTable);
