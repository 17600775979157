import { takeLatestWhenAppReady } from '../../../utils/sagas';
import * as ActionTypes from '../../../constants/ActionTypes';
import * as frontPageActions from '../../../actions/page/front';
import * as routerActionSelector from '../../../selectors/actions/router';
import { put } from 'redux-saga/effects';

function* doLocationChange(action) {
  let path = routerActionSelector.getSplitPath(action);
  if (!(path && path.length && path[0])) {
    yield put(frontPageActions.pageLoad());
  }
}

export default function* locationChange() {
  yield takeLatestWhenAppReady(
    ActionTypes.APP_ROUTER_LOCATION_CHANGE,
    doLocationChange,
  );
}
