import { all, fork } from 'redux-saga/effects';
import getAll from './getall';
import getByProcess from './getbyprocess';
import getAllByProcessTxn from './getallbyprocesstxn';
import searchByName from './findprocessstepbyname';
import getByProcessStepsByIds from './getprocessstepsbyids';
import getRelationTree from './getrelationtree';

export default function* appRootSaga() {
  yield all([
    fork(getAll),
    fork(getByProcess),
    fork(getAllByProcessTxn),
    fork(searchByName),
    fork(getByProcessStepsByIds),
    fork(getRelationTree),
  ]);
}
