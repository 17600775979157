import { fromJS } from 'immutable';
import * as ActionTypes from '../../constants/ActionTypes';

export default function signupPageReducer(state = fromJS({}), action) {
  switch (action.type) {
    case ActionTypes.API_SIGNUP_CONFIRM_SUCCESS:
    case ActionTypes.API_SIGNUP_CONFIRM_ERROR:
      return state.merge(fromJS({ token: action.payload }));
    case ActionTypes.API_SIGNUP_SUBMIT_ERROR:
    case ActionTypes.API_SIGNUP_SUBMIT_SUCCESS:
      return state.merge(fromJS({ response: action.payload }));
    case ActionTypes.APP_PAGE_SIGNUP_LOAD:
      return fromJS({});
    default:
      return state;
  }
}
