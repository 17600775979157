import BaseConnector from './Base';

export default class RelationConnector extends BaseConnector {
  constructor(...args) {
    super(...args);
    this.entityType = 'relation';
  }

  updateAll() {
    return this.authPost(this.query().build(), {});
  }

  updateEntity(id, entity) {
    return this.authPost(this.query().withLink(`${entity}/${id}`).build(), {});
  }
}
