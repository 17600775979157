/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { fontColors, fontFamily, fontSize, uiColors } from '../styles/styles';

const styles = {
  '&.main-search': {
    display: 'inline-block',
    position: 'relative',
    backgroundColor: 'transparant',
    color: fontColors.PURPLE,
    border: 'none',
    outline: 'none',
    fontFamily: fontFamily.MAIN_SEARCH_INPUT,
    fontSize: fontSize.MAIN_SEARCH_INPUT,
    lineHeight: '20px',
    textAlign: 'left',
    width: '300px',
    height: '48px',
    boxShadow: 'none',
    '&.placeholder': {
      color: fontColors.MAIN,
    },
    '.icon-container': {
      textAlign: 'center',
      position: 'absolute',
      borderRight: '0px',
      top: '15px',
      left: '0px',
    },
    '.input-field': {
      backgroundColor: 'transparant',
      paddingLeft: '30px',
      color: fontColors.PURPLE,
      border: 'none',
      outline: 'none',
      fontFamily: fontFamily.MAIN_SEARCH_INPUT,
      fontSize: fontSize.MAIN_SEARCH_INPUT,
      lineHeight: '20px',
      textAlign: 'left',
      width: '300px',
      height: '48px',
      boxShadow: 'none',
    },
  },
  '&.top-search': {
    display: 'inline-block',
    position: 'relative',
    backgroundColor: uiColors.WHITE,
    boxShadow: 'inset 0 1px 2px 0 ' + uiColors.GREY,
    borderRadius: '3px',
    borderColor: uiColors.WHITE,
    outline: 'none',
    fontFamily: fontFamily.TOP_SEARCH_INPUT,
    fontSize: fontSize.TOP_SEARCH_INPUT,
    lineHeight: '22px',
    textAlign: 'left',
    width: '280px',
    height: '38px',
    cursor: 'text',
    '&.placeholder': {
      color: fontColors.MAIN,
    },
    '.icon-container': {
      position: 'absolute',
      borderRight: '0px',
      left: '7.5px',
      top: '10px',
    },
    '.input-field': {
      backgroundColor: uiColors.WHITE,
      paddingLeft: '30px',
      border: '1px solid ' + uiColors.GREY,
      boxShadow: 'inset 0 1px 2px 0 ' + uiColors.GREY,
      borderRadius: '3px',
      borderColor: uiColors.WHITE,
      outline: 'none',
      fontFamily: fontFamily.TOP_SEARCH_INPUT,
      fontSize: fontSize.TOP_SEARCH_INPUT,
      lineHeight: '22px',
      textAlign: 'left',
      width: '280px',
      height: '38px',
      cursor: 'text',
      '&.placeholder': {
        color: fontColors.MAIN,
      },
    },
  },
  '&.signup': {
    '.input-field': {
      backgroundColor: uiColors.WHITE,
      border: '1px solid ' + uiColors.GREY,
      borderRadius: '4px',
      boxShadow: 'inset 0 1px 2px 0 ' + uiColors.GREY,
      outline: 'none',
      width: '290px',
      height: '38px',
      fontSize: fontSize.SIGNUP_INPUT,
      textAlign: 'left',
      paddingLeft: '6px',
      '&:hover': {
        border: '1px solid ' + uiColors.DARK,
        boxShadow: '1px 1px 1px 0 rgba(0 , 0, 0, 0.3)',
      },
      '&:focus': {
        border: '1px solid ' + uiColors.BRAND_LIGHT,
      },
      '&:disabled': {
        border: '1px solid ' + uiColors.GREY,
        boxShadow: 'inset 0 1px 2px 0 ' + uiColors.GREY,
      },
      '&.placeholder': {
        color: fontColors.MAIN,
      },
    },
  },
  '&.form': {
    '.input-field': {
      color: fontColors.MAIN,
      backgroundColor: uiColors.WHITE,
      border: '1px solid ' + uiColors.GREY,
      borderRadius: '4px',
      outline: 'none',
      boxShadow: 'inset 0 1px 2px 0 ' + uiColors.GREY,
      width: '264px',
      height: '38px',
      cursor: 'text',
      paddingLeft: '6px',
      fontSize: fontSize.FORM_INPUT,
      '&:hover': {
        border: '1px solid ' + uiColors.DARK,
        boxShadow: '1px 1px 1px 0 rgba(0 , 0, 0, 0.3)',
      },
      '&:focus': {
        border: '1px solid ' + uiColors.BRAND_LIGHT,
      },
      '&.placeholder': {
        color: fontColors.MAIN,
      },
      '&:disabled': {
        border: '1px solid ' + uiColors.GREY,
        boxShadow: 'inset 0 1px 2px 0 ' + uiColors.GREY,
      },
    },
  },
  '&.with-label': {
    boxShadow: 'inset 0 1px 2px 0' + uiColors.GREY,
    border: '1px solid ' + uiColors.GREY,
    borderRadius: '4px',
    display: 'flex',
    '.input-field': {
      border: 'none',
      flexBasis: '90%',
      '&:focus': {
        border: 'none',
      },
      '&:hover': {
        boxShadow: 'none',
        border: 'none',
      },
    },
    '.label': {
      borderRadius: '.3rem 0 0 .3rem',
      background: uiColors.BORDER,
      textAlign: 'center',
      padding: '5px',
      flexBasis: '10%',
    },
    ':hover': {
      border: '1px solid ' + uiColors.DARK,
      boxShadow: '1px 1px 1px 0 rgb(0 0 0 / 30%)',
    },
    '&:focus-within': {
      border: '1px solid ' + uiColors.BRAND_LIGHT,
    },
  },
  '.input-field:disabled': {
    color: fontColors.BLACK,
    backgroundColor: uiColors.GREY_DISABLED,
    border: '1px solid ' + uiColors.DARK_GREY,
  },
};

const noop = () => {};

class Input extends React.Component {
  static propTypes = {
    placeholder: PropTypes.string,
    nameOfClass: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    dataType: PropTypes.oneOf(['text', 'number']),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyDown: PropTypes.func,
  };

  static defaultProps = {
    placeholder: '',
    type: Input.FORM,
    value: '',
    dataType: 'text',
    onChange: noop,
    onBlur: noop,
    onFocus: noop,
    onKeyDown: noop,
  };

  render() {
    const {
      value,
      className,
      type,
      disabled,
      placeholder,
      onChange,
      onBlur,
      onKeyDown,
      dataType,
      onFocus,
      labelCaption,
    } = this.props;
    const isSearch = type === Input.SEARCH_MAIN || type === Input.SEARCH_TOP;
    const isWithLabel = type === Input.WITH_LABEL;
    const types = type === Input.WITH_LABEL ? `${type} ${Input.FORM}` : type;
    return (
      <div className={types + (className ? ' ' + className : '')} css={styles}>
        {isSearch && (
          <div className="icon-container">
            <FontAwesomeIcon icon={faSearch} />
          </div>
        )}
        {isWithLabel && labelCaption && (
          <span className="label">{labelCaption}</span>
        )}
        <input
          placeholder={placeholder}
          className="input-field w-100"
          disabled={disabled}
          onChange={onChange}
          onKeyDown={onKeyDown}
          type={dataType}
          value={value}
          onBlur={onBlur}
          onFocus={onFocus}
          {...(this.props.inputProps || {})}
        />
      </div>
    );
  }
}

Input.SEARCH_MAIN = 'main-search';
Input.SEARCH_TOP = 'top-search';
Input.SIGNUP = 'signup';
Input.FORM = 'form';
Input.WITH_LABEL = 'with-label';

export default Input;
