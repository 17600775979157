import BaseConnector from './Base';
import * as EntityTypes from '../constants/EntityTypes';
import * as Projections from '../constants/Projections';
import * as Searches from '../constants/Searches';
import { Promise } from 'es6-promise';
import { DateFormat } from '../constants/DateFormat';
import moment from 'moment';

export default class LicenseConnector extends BaseConnector {
  constructor(...args) {
    super(...args);
    this.entityType = EntityTypes.LICENSE;
  }

  create(licenses) {
    return Promise.all(
      licenses.map((license) =>
        this.authPost(
          this.query().withProjection(Projections.RELATED_IDS).build(),
          license,
        ),
      ),
    );
  }

  update(licenses) {
    return Promise.all(
      licenses.map((license) =>
        this.authPatch(
          this.query()
            .withProjection(Projections.RELATED_IDS)
            .withId(license.id)
            .build(),
          license,
        ),
      ),
    );
  }

  delete(licenses) {
    return Promise.all(
      licenses.map((license) =>
        this.authDelete(this.query().withId(license.id).build()),
      ),
    );
  }

  claim(id, patch) {
    return this.authPatch(this.query().withId(id).build(), patch);
  }

  getAvailable() {
    const url = this.query()
      .withSearch(Searches.availableLicense, [
        moment().format(DateFormat.requestDateTime),
      ])
      .withProjection(Projections.NAME)
      .build();
    return this.authGet(url);
  }

  getAll() {
    return this.authGet(
      this.query()
        .withProjection(Projections.RELATED_IDS)
        .withSize(1000)
        .build(),
    );
  }
}
