export const QuickFilters = {
  ALL: 'all',
  ACTIVE: 'active',
  EXPIRES_IN_30_DAYS: 'expiresIn30Days',
};

export const SearchFilters = {
  NAME: 'name',
  EMAIL: 'email',
  COMPANY_NAME: 'companyName',
  COMPANY_CVR: 'companyCvr',
};
