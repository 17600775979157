import { all, fork } from 'redux-saga/effects';
import createLicense from './createlicense';
import updateLicense from './updatelicense';
import deleteLicense from './deletelicense';
import getAvailableLicenses from './getavailable';
import getAll from './getAll';
import claimLicense from './claimlicense';

export default function* apiLicenseSaga() {
  yield all([
    fork(createLicense),
    fork(updateLicense),
    fork(deleteLicense),
    fork(getAvailableLicenses),
    fork(getAll),
    fork(claimLicense),
  ]);
}
