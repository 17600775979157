/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Trans, withTranslation } from 'react-i18next';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import { refBind } from '../../utils/ref-bind';
import * as adminPageActions from '../../actions/page/admin';
import * as adminPageSelector from '../../selectors/page/admin';
import * as taxonomyApiSelector from '../../selectors/api/taxonomy';
import * as newsworthyApiActions from '../../actions/api/newsworthy';
import { fontColors, fontSize } from '../../components/common/styles/styles';
import Button from '../../components/common/atoms/Button';
import {
  getPropsToEdit,
  getRelationFromNode,
} from '../../utils/relationhelper';
import RelationEditor from '../../components/common/organisms/RelationEditor';
import { FaTrash } from 'react-icons/all';
import CustomModal from '../../components/common/molecules/CustomModal';
import Newsworthy from '../../components/common/molecules/Newsworthy';
import * as UpdateType from '../../constants/UpdateType';
import * as UpdatedEntityType from '../../constants/UpdatedEntityType';
import * as EntityTypes from '../../constants/EntityTypes';
import Filter from '../../components/common/molecules/Filter';
import * as jurisdictionApiSelector from '../../selectors/api/jurisdiction';

const styles = {
  '.body': {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: fontSize.PARAGRAPH_SMALL,
    height: '300px',
  },
  '.pre-body': {
    marginBottom: '1rem',
  },
  '.header': {
    textTransform: 'uppercase',
  },
  '.footer': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  '.editable': {
    height: '100%',
    width: '100%',
    fontSize: fontSize.PARAGRAPH_SMALL,
  },
  '.modal-body': {
    height: '70vh',
    overflow: 'auto',
  },
  '.delete-button': {
    color: fontColors.BRAND,
    cursor: 'pointer',
    ':hover': {
      transform: 'scale(1.1)',
    },
  },
};

class ArticleEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputText: props.article[getPropsToEdit(props.article)],
      links: null,
      isShowDialogBox: false,
      isNewsworthy: false,
      jurisdiction: props.article.jurisdiction
        ? {
            value: props.article.jurisdiction.id,
            label: this.translate(props.article.jurisdiction.alpha2Code),
          }
        : undefined,
    };
    refBind(
      this,
      'updateRelations',
      'handleInputChange',
      'acceptClickHandler',
      'handleRemove',
      'handleWarning',
      'handleNewsworthyChange',
      'handleNewsworthyCheckbox',
      'handleJurisdictionChange',
    );
  }

  componentDidMount(prevProps, prevState, snapshot) {
    if (this.props.show) {
      this.props.actions.loadArticleData(this.props.article);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.show && !prevProps.show) {
      this.props.actions.loadArticleData(this.props.article);
      this.setState({ links: null, isShowDialogBox: false });
    }
  }

  updateRelations(update) {
    this.setState({ links: { ...this.state.links, ...update } });
  }

  handleInputChange(event) {
    this.setState({ inputText: event.target.value });
  }

  handleJurisdictionChange(update) {
    this.setState({ jurisdiction: update });
  }

  handleWarning() {
    this.setState({ isShowDialogBox: !this.state.isShowDialogBox });
  }

  handleRemove() {
    this.handleWarning();
    this.props.actions.deleteArticle(
      this.props.article,
      this.props.cancelClickHandler,
    );
  }

  handleNewsworthyChange(description) {
    this.setState({ changeDescription: description });
  }

  handleNewsworthyCheckbox() {
    const { isNewsworthy } = this.state;
    this.setState({ isNewsworthy: !isNewsworthy });
  }

  acceptClickHandler() {
    const { changeDescription, inputText, links, isNewsworthy, jurisdiction } =
      this.state;
    const { article, actions, cancelClickHandler } = this.props;
    const updatedEntityType =
      article.entityType === EntityTypes.LAWITEM
        ? UpdatedEntityType.LAW_ITEM
        : UpdatedEntityType.THEORY_ITEM;
    actions.saveData(
      article,
      {
        updatedText: inputText,
        jurisdiction: jurisdiction,
        links,
      },
      cancelClickHandler,
    );
    if (changeDescription && article && isNewsworthy) {
      actions.createNewsworthyItem({
        description: changeDescription,
        entityType: updatedEntityType,
        entityId: article.id,
        updateType: UpdateType.UPDATED,
      });
    }
  }

  translate(key) {
    return this.props.t(
      `create-article.${(key || '').toLowerCase().split(' ').join('-')}`,
    );
  }

  render() {
    const {
      show,
      title,
      cancelClickHandler,
      article,
      theoryItems,
      lawItems,
      decisions,
      processSteps,
      taxonomy,
      enableRemove,
      jurisdictions,
      t,
    } = this.props;
    const { isNewsworthy, changeDescription, jurisdiction } = this.state;
    return (
      <Modal show={show} css={styles} size="xl">
        <Modal.Header>
          <Modal.Title className="w-100">
            <div className="d-flex justify-content-between w-100">
              <div className="header">
                <Trans i18nKey={title} />
              </div>
              <div>
                {enableRemove && (
                  <FaTrash
                    className="delete-button"
                    onClick={this.handleWarning}
                  />
                )}
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <CustomModal
            show={this.state.isShowDialogBox}
            title={t('article-editor.delete-item')}
            body={t('article-editor.warning-message')}
            cancelButtonText={t('article-editor.back')}
            acceptButtonText={t('article-editor.remove')}
            acceptClickHandler={this.handleRemove}
            cancelClickHandler={this.handleWarning}
          />
          {show && (
            <div>
              {article.entityType === EntityTypes.THEORYITEM && (
                <div className={'pre-body d-flex flex-column'}>
                  <Form.Label>
                    <span className="text-title-case">
                      <Trans i18nKey={`create-article.jurisdiction`} />:
                    </span>
                  </Form.Label>
                  <Filter
                    options={(jurisdictions || []).map((j) => ({
                      label: this.translate(j.node.alpha2Code),
                      value: j.node.id,
                    }))}
                    value={jurisdiction}
                    onChange={this.handleJurisdictionChange}
                  />
                </div>
              )}
              <div className={'body d-flex flex-column'}>
                <textarea
                  className={'editable'}
                  value={this.state.inputText}
                  onChange={this.handleInputChange}
                />
              </div>
              <RelationEditor
                entityType={article.entityType}
                entityId={article.id}
                decisions={decisions}
                lawItems={lawItems}
                taxonomy={taxonomy}
                theoryItems={
                  article.entityType !== EntityTypes.THEORYITEM
                    ? theoryItems
                    : 'disabled'
                }
                processSteps={
                  article.entityType !== EntityTypes.THEORYITEM
                    ? processSteps
                    : 'disabled'
                }
                onChange={this.updateRelations}
              />
              <Newsworthy
                onChange={this.handleNewsworthyChange}
                onClickCheckbox={this.handleNewsworthyCheckbox}
                checked={isNewsworthy}
                changeDescription={changeDescription}
              />
            </div>
          )}
        </Modal.Body>

        <Modal.Footer className={'footer'}>
          <Button type={Button.PRIMARY_DARK} onClick={cancelClickHandler}>
            <Trans i18nKey="article-editor.back" />
          </Button>
          <Button type={Button.PRIMARY_BRAND} onClick={this.acceptClickHandler}>
            <Trans i18nKey="article-editor.save" />
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  if (!ownProps.show) {
    return { ...ownProps };
  }
  const selectedNode = adminPageSelector.getSelectedNode(
    state,
    ownProps.article.id,
  );
  const theoryItems = getRelationFromNode(selectedNode, 'relatedTheoryItems');
  const lawItems = getRelationFromNode(selectedNode, 'relatedLawItems');
  const decisions = getRelationFromNode(selectedNode, 'relatedDecisions');
  const processSteps = getRelationFromNode(selectedNode, 'relatedProcessSteps');

  const keywords = getRelationFromNode(selectedNode, 'keywords');
  const keywordIds =
    keywords.size > 0 ? keywords.map((x) => x.get('id'))?.toJS() : [];
  const taxonomy = taxonomyApiSelector
    .getByIds(state, keywordIds)
    .map((i) => i.get('node'));
  const jurisdictions = jurisdictionApiSelector.getAll(state).toList();
  return {
    theoryItems,
    lawItems,
    decisions,
    processSteps,
    taxonomy,
    jurisdictions,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      loadArticleData: adminPageActions.pageLoad,
      saveData: adminPageActions.saveUpdates,
      deleteArticle: adminPageActions.deleteArticle,
      createNewsworthyItem: newsworthyApiActions.createNewsworthyItem,
    },
    dispatch,
  ),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withImmutablePropsToJS,
)(withTranslation()(ArticleEditor));
