import * as ActionTypes from '../../constants/ActionTypes';
import { put, select, takeEvery } from 'redux-saga/effects';
import * as appActions from '../../actions/app';
import { getCurrentLocation } from '../../selectors/store/app';
import { getChangeType } from '../../selectors/actions/router';

function* doLocationSave(action) {
  const previousLocation = yield select(getCurrentLocation);
  const currentLocation = action.payload.location;
  const changeType = getChangeType(currentLocation, previousLocation);
  yield put(
    appActions.routeChange(currentLocation, previousLocation, changeType),
  );
  yield put(appActions.savePreviousRoute(action.payload.location));
}

export default function* keepHistoryTrack() {
  yield takeEvery(ActionTypes.APP_ROUTER_LOCATION_CHANGE, doLocationSave);
}
