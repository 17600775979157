import { call, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import lawItemConnector from '../../../connectors/LawItem';
import { generateApiSaga } from '../../../utils/sagas';

const getWithParents = generateApiSaga(
  lawItemConnector,
  function* (connector, action) {
    const nodeIds = action.payload.nodeIds;
    return yield call([connector, connector.getByUriWithParents], nodeIds);
  },
);

export default function* getLawItemParentById() {
  yield takeEvery(
    ActionTypes.API_LAWITEM_GET_ALL_BY_URI_WITH_PARENTS_INIT,
    getWithParents,
  );
}
