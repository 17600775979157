import * as ActionTypes from '../../constants/ActionTypes';
import { dataActionMap } from '../../utils/relationhelper';

export const footerStartFetching = (action, entityType) => ({
  type: ActionTypes.PAGE_FOOTER_FETCH_DATA,
  payload: { action, entityType },
});
export const footerSelectData = (id, type, entities) => ({
  type: ActionTypes.PAGE_FOOTER_SELECT_DATA,
  payload: { id, type, entities },
});
export const toggleRedThread = () => ({
  type: ActionTypes.PAGE_FOOTER_TOGGLE_RED_THREAD,
});
export const getDataAction = (node) =>
  dataActionMap[node?.node.entityType] &&
  dataActionMap[node?.node.entityType](node?.node.id);
