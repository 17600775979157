import { call, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import { generateApiSaga } from '../../../utils/sagas';
import FavoriteConnector from '../../../connectors/Favorite';

const doFetch = generateApiSaga(
  FavoriteConnector,
  function* (connector, action) {
    return yield call([connector, connector.getAllExpanded]);
  },
);

export default function* getAllFavoritesExpanded() {
  yield takeEvery(ActionTypes.API_GET_ALL_FAVORITES_EXPANDED_INIT, doFetch);
}
