/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import DefaultPageLayout from '../../components/common/atoms/DefaultPageLayout';
import ContentOuterBound from '../../components/common/atoms/ContentOuterBound';
import { Col, Nav, Row, Tab } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import { buildPageLink } from '../../utils/link';
import { ADMIN_HOME_ROUTE } from '../../constants/Urls';
import { ADMIN_HOME_TABS } from '../../constants/AdminHomeTabs';
import * as routerSelectors from '../../selectors/store/router';
import { push } from 'connected-react-router/immutable';
import * as TabsStyles from '../../components/common/styles/TabsStyles';
import { refBind } from '../../utils/ref-bind';
import LicenseForm from '../../components/common/molecules/LicenseForm';
import WriteNewsletter from '../newsletter/WriteNewsletter';
import * as adminHomeSelector from '../../selectors/page/adminhome';
import * as roleApiSelector from '../../selectors/api/role';
import * as companyApiSelector from '../../selectors/api/company';
import * as licenseApiActions from '../../actions/api/license';
import * as newsletterApiActions from '../../actions/api/newsletter';
import * as roleApiActions from '../../actions/api/role';
import { FaSync } from 'react-icons/all';
import * as relationApiActions from '../../actions/api/relation';
import Button from '../../components/common/atoms/Button';
import { updateSolrData } from '../../actions/search';
import { MOBILE_SIZE } from '../../constants/ScreenSize';
import NoMobileSupportPage from './NoMobileSupportPage';
import UserOverview from '../../components/common/organisms/UserOverview';
import EditUserView from '../../components/common/organisms/EditUserView';
import * as userApiActions from '../../actions/api/appuser';
import * as companyApiActions from '../../actions/api/company';
import * as adminHomePageActions from '../../actions/page/adminhome';

class AdminHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showEditUserForm: false,
      selectedUserDetails: undefined,
    };
    refBind(
      this,
      'handleTabSelect',
      'toggleEditUserForm',
      'handleLicenseCreation',
      'handleRoleUpdate',
      'handleUserDetailsUpdate',
      'handleNewsletterCreation',
      'renderUserTab',
      'renderConsoleTab',
    );
  }

  handleTabSelect(eventKey) {
    const { actions } = this.props;
    actions.push(buildPageLink(ADMIN_HOME_ROUTE, eventKey));

    this.setState({
      showEditUserForm: false,
      selectedUserDetails: undefined,
    });
  }

  toggleEditUserForm = (userDetails) => {
    const showEditUserForm = !this.state.showEditUserForm;

    this.setState({
      showEditUserForm: showEditUserForm,
      selectedUserDetails: showEditUserForm ? userDetails : undefined,
    });
  };

  handleLicenseCreation(licenseData) {
    const { actions } = this.props;
    actions.createLicense(licenseData);
  }

  handleNewsletterCreation(newsletter) {
    const { actions } = this.props;
    actions.createNewsletter(newsletter);
  }

  handleRoleUpdate(role) {
    const { actions } = this.props;
    actions.updateRole(role);
  }

  handleUserDetailsUpdate(userDetails) {
    const { actions } = this.props;
    actions.updateUserDetails(userDetails);
  }

  render() {
    const { uri, roles, companies } = this.props;
    const isMobile = window.innerWidth < MOBILE_SIZE;

    return (
      <DefaultPageLayout>
        {isMobile ? (
          <NoMobileSupportPage />
        ) : (
          <ContentOuterBound>
            <Tab.Container
              defaultActiveKey={uri}
              onSelect={this.handleTabSelect}
            >
              <Row>
                <Col sm={3} className="mt-5 position-inherit">
                  <Nav
                    className="flex-column text-capitalize mt-3"
                    variant="fill"
                    css={TabsStyles.styles}
                  >
                    <Nav.Item>
                      <Nav.Link eventKey={ADMIN_HOME_TABS.LICENSE}>
                        <Trans i18nKey="admin-home.license" />
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey={ADMIN_HOME_TABS.USERS}>
                        <Trans i18nKey="admin-home.users" />
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey={ADMIN_HOME_TABS.CREATE_NEWSLETTER}>
                        <Trans i18nKey="admin-home.write-newsletter" />
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey={ADMIN_HOME_TABS.CONSOLE}>
                        <Trans i18nKey="admin-home.console" />
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col sm={9}>
                  <Tab.Content className="mt-4">
                    <Tab.Pane eventKey={ADMIN_HOME_TABS.LICENSE}>
                      <LicenseForm
                        companies={companies}
                        licenseTypes={roles}
                        onLicenseCreate={this.handleLicenseCreation}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey={ADMIN_HOME_TABS.USERS}>
                      {this.renderUserTab(this.state.showEditUserForm)}
                    </Tab.Pane>
                    <Tab.Pane eventKey={ADMIN_HOME_TABS.CREATE_NEWSLETTER}>
                      <WriteNewsletter
                        onNewsletterCreation={this.handleNewsletterCreation}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey={ADMIN_HOME_TABS.CONSOLE}>
                      {this.renderConsoleTab()}
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </ContentOuterBound>
        )}
      </DefaultPageLayout>
    );
  }

  renderUserTab(showEditUserForm) {
    return showEditUserForm ? (
      <EditUserView
        userDetails={this.state.selectedUserDetails}
        roles={this.props.roles}
        onBackNavigation={this.toggleEditUserForm}
        onSaveChanges={this.handleUserDetailsUpdate}
      />
    ) : (
      <UserOverview
        userDetails={this.props.userDetails}
        isLoading={this.props.isLoading}
        onUserSelect={this.toggleEditUserForm}
      />
    );
  }

  renderConsoleTab() {
    return (
      <div className="container-fluid">
        <div className="row d-flex align-items-baseline my-3">
          <span className="col-4">
            <Trans i18nKey="admin-home.update-relations" />
          </span>
          <span className="col-8">
            <Button
              type={Button.PRIMARY_SMALL}
              onClick={() => this.props.actions.recalculateRelations()}
            >
              <FaSync />
            </Button>
          </span>
        </div>
        <div className="row d-flex align-items-baseline my-3">
          <span className="col-4">
            <Trans i18nKey="admin-home.update-solr-data" />
          </span>
          <span className="col-8">
            <Button
              type={Button.PRIMARY_SMALL}
              onClick={() => this.props.actions.syncSolr()}
            >
              <FaSync />
            </Button>
          </span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const mappedState = {
    uri: routerSelectors.getCurrentUri(state),
    isLoading: adminHomeSelector.isLoading(state),
  };

  const roles = adminHomeSelector.mapRoleToOptions(
    roleApiSelector.getAll(state),
  );

  switch (mappedState.uri) {
    case ADMIN_HOME_TABS.LICENSE:
      const companies = adminHomeSelector.mapCompanyToOptions(
        companyApiSelector.getAll(state),
      );
      return { ...mappedState, roles, companies };
    case ADMIN_HOME_TABS.USERS:
      const userDetails = adminHomeSelector.getAllUserDetails(state);

      return { ...mappedState, roles, userDetails };
    case ADMIN_HOME_TABS.ROLES:
      const rolesWithPermissions =
        adminHomeSelector.getRolesWithPermissions(state);
      return { ...mappedState, rolesWithPermissions };
    default:
      return mappedState;
  }
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      push: push,
      createLicense: licenseApiActions.createLicense,
      updateLicense: licenseApiActions.updateLicense,
      deleteLicense: licenseApiActions.deleteLicense,
      recalculateRelations: relationApiActions.updateAll,
      syncSolr: updateSolrData,
      updateRole: roleApiActions.updateRole,
      updateAppUser: userApiActions.update,
      updateCompany: companyApiActions.update,
      createNewsletter: newsletterApiActions.createNewsletter,
      updateUserDetails: adminHomePageActions.updateUserDetails,
    },
    dispatch,
  ),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withImmutablePropsToJS,
)(AdminHome);
