import { call, select, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import LawItemConnector from '../../../connectors/LawItem';
import { generateApiSaga } from '../../../utils/sagas';
import * as lawItemApiSelector from '../../../selectors/api/lawitem';
import { createTagName } from '../../../utils/filter';

const search = generateApiSaga(LawItemConnector, function* (connector, action) {
  const name = action.payload.name;
  const lawItemData = yield select(lawItemApiSelector.getSearchData);
  if (!lawItemData.get(createTagName(name))) {
    return yield call([connector, connector.searchByName], name);
  }
});

export default function* searchByRowName() {
  yield takeEvery(ActionTypes.API_LAWITEM_SEARCH_BY_NAME_INIT, search);
}
