import { call, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import Decision from '../../../connectors/Decision';
import { generateApiSaga } from '../../../utils/sagas';

const doGetAll = generateApiSaga(Decision, function* (connector) {
  return yield call([connector, connector.getNewestDecisions]);
});

export default function* getNewest() {
  yield takeEvery(ActionTypes.API_DECISIONS_GET_TOP_INIT, doGetAll);
}
