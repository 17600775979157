/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import VimeoVideo from '../atoms/VimeoVideo';

const propTypes = {
  url: PropTypes.string,
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  size: PropTypes.string,
};

const defaultProps = {
  isOpen: false,
  closeModal: () => false,
};

const defineSizing = (size) => {
  switch (size) {
    case 'lg':
      return '440px';
    case 'xl':
      return '632px';
    default:
      return '260px';
  }
};

const styles = (size) => {
  return {
    '& .vimeo-container': {
      margin: 'auto',
      // height adjusts the size of the video.
      // height: "260px", // default size modal
      // height: '440px', // lg size modal
      // height: "632px" // xl size modal
      // TODO create mediaquery sizing
      height: defineSizing(size),
    },
  };
};

class VimeoModal extends Component {
  constructor(props) {
    super(props);
  }

  // This component I am unsure of how to handle
  render() {
    const { id, isOpen, closeModal, size } = this.props;

    return (
      <Modal
        show={isOpen}
        onHide={closeModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size={size}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="vimeo-embedding-component" css={styles(size)}>
            <VimeoVideo id={id} />
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

VimeoModal.propTypes = propTypes;

VimeoModal.defaultProps = defaultProps;

export default VimeoModal;
