export const DECISION_LINKS = {
  decisionCaseTypes: 'decisioncasetypes',
  decisionTypes: 'decisionitemtypes',
  pdf: 'pdf',
};

export const ARTICLE_LINKS = {
  lawItemTypes: 'lawitemtype',
  theoryItemTypes: 'theoryitemtype',
};

export const NEWSLETTER_UNSUBSCRIBE = {
  single: 'unsubscribe',
  all: 'unsubscribe/all',
};
