/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { uiColors } from '../styles/styles';

const style = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',

  '.switch': {
    position: 'relative',
    display: 'inline-block',
    width: '26px',
    height: '10px',
    marginTop: '5px',
  },
  label: {
    margin: 0,
    padding: 0,
  },

  '.toggle-label': {
    height: '100%',
    verticalAlign: 'center',
  },

  '.switch input': {
    opacity: 0,
    width: 0,
    height: 0,
  },

  '.slider': {
    position: 'absolute',
    cursor: 'pointer',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    backgroundColor: uiColors.GREY_DISABLED,
    transition: '0.4s',
  },

  '.slider:before': {
    position: 'absolute',
    content: '""',
    height: '14px',
    width: '14px',
    left: '0',
    bottom: '-2px',
    backgroundColor: uiColors.GREY,
    transition: '.4s',
  },

  'input:checked + .slider:before': {
    transform: 'translateX(12px)',
    backgroundColor: uiColors.BRAND,
  },
  'input:disabled + .slider': {
    cursor: 'not-allowed',
  },
  '.slider.round': {
    borderRadius: '16px',
  },

  '.slider.round:before': {
    borderRadius: '50%',
  },
};

class SliderToggle extends React.Component {
  constructor(props) {
    super(props);
    this.state = { checked: props.value || false };
    this.toggle = this.toggle.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.value === this.props.value) {
      return;
    }
    this.setState({ checked: this.props.value });
  }

  toggle() {
    this.setState({ checked: !this.state.checked });
    this.props.onChange && this.props.onChange(!this.state.checked);
  }

  render() {
    return (
      <div
        css={style}
        className={this.props.className}
        onClick={(event) => event.stopPropagation()}
      >
        <label className="switch mr-2">
          <input
            type="checkbox"
            defaultChecked={this.state.checked}
            onChange={this.toggle}
            disabled={this.props.disabled || false}
          />
          <span className="slider round" />
        </label>
        <div className="toggle-label">{this.props.children}</div>
      </div>
    );
  }
}

export default SliderToggle;
