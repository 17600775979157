import { call, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import LawGroupConnector from '../../../connectors/LawGroup';
import { generateApiSaga } from '../../../utils/sagas';

var doGetAllLawGroups = generateApiSaga(
  LawGroupConnector,
  function* (connector, action) {
    return yield call([connector, connector.getAll]);
  },
);

export default function* getAll() {
  yield takeEvery(ActionTypes.API_LAWGROUP_GET_ALL_INIT, doGetAllLawGroups);
}
