import { fromJS, List, Map } from 'immutable';
import { combineReducers } from 'redux-immutable';
import lawApiReducer from './law';
import lawItemReducer from './lawitem';
import theoryReducer from './theory';
import theoryItemReducer from './theoryitem';
import jurisdictionReducer from './jurisdiction';
import courtReducer from './court';
import taxonomyReducer from './taxonomy';
import decisionReducer from './decision';
import processReducer from './process';
import processstepReducer from './processstep';
import {
  getFilter,
  getResponseData,
  getResponsePagingData,
} from '../../selectors/actions';
import { treeNode } from '../../selectors/store/tree';
import consentApiReducer from './appuserconsent';
import favoriteApiReducer from './favorite';
import * as EntityTypes from '../../constants/EntityTypes';
import companyApiReducer from './company';
import roleApiReducer from './role';
import licenseApiReducer from './license';
import appUserApiReducer from './appuser';
import permissionApiReducer from './permissions';
import lawPrefaceItem from './lawprefaceitem';
import lawGroupApiReducer from './lawgroup';

export default combineReducers({
  [EntityTypes.LAW]: lawApiReducer,
  [EntityTypes.LAWITEM]: lawItemReducer,
  [EntityTypes.LAWPREFACEITEM]: lawPrefaceItem,
  [EntityTypes.THEORYITEM]: theoryItemReducer,
  [EntityTypes.THEORY]: theoryReducer,
  [EntityTypes.DECISION]: decisionReducer,
  [EntityTypes.JURISDICTION]: jurisdictionReducer,
  [EntityTypes.COURT]: courtReducer,
  [EntityTypes.TAXONOMY]: taxonomyReducer,
  [EntityTypes.PROCESS]: processReducer,
  [EntityTypes.PROCESSSTEP]: processstepReducer,
  [EntityTypes.APPUSERCONSENT]: consentApiReducer,
  [EntityTypes.FAVORITE]: favoriteApiReducer,
  [EntityTypes.COMPANY]: companyApiReducer,
  [EntityTypes.ROLE]: roleApiReducer,
  [EntityTypes.LICENSE]: licenseApiReducer,
  [EntityTypes.APPUSER]: appUserApiReducer,
  [EntityTypes.PERMISSION]: permissionApiReducer,
  [EntityTypes.LAWGROUP]: lawGroupApiReducer,
});

// TODO: Refactor these methods to util file
export const mergeEntityListToState = (state, entities, entityType) => {
  entities.forEach((entity) => {
    // Get existing or build new
    if (entity.children) {
      state = mergeEntityListToState(state, entity.children, entityType);
    }
    if (entity.parentItem) {
      state = mergeEntityListToState(state, [entity.parentItem], entityType);
    }

    let cleanedEntity = compressApiResponse(entity);
    cleanedEntity['entityType'] = entityType;

    let node = state.getIn(
      ['data', entity.id],
      treeNode(cleanedEntity, entity.parent, []),
    );

    if (entity.parent) {
      let parent = state.getIn(
        ['data', entity.parent],
        treeNode(null, null, []),
      ); // Get existing parent or build new
      let children = parent.get('children');
      if (!children.contains(entity.id)) {
        parent = parent.set('children', parent.get('children').push(entity.id));
      }
      state = state.setIn(['data', entity.parent], parent);
      if (node.get('parent') === null) {
        node = node.set('parent', entity.parent);
      }
    }
    let newnode = fromJS(cleanedEntity);
    if (state.getIn(['data', entity.id, 'node'])) {
      newnode = state.getIn(['data', entity.id, 'node']).merge(newnode);
    }
    node = node.set('node', newnode); // TODO: TEST Needed to update existing if it was actually found above. Is if branching faster?
    state = state.setIn(['data', entity.id], node);
  });
  return state;
};
export const mergeResponseToState = (
  state,
  action,
  entityType,
  savePageData = false,
) => {
  let entities = getEntities(action, entityType);
  state = state.withMutations((ctx) => {
    mergeEntityListToState(ctx, entities, entityType);
    mergeFilterToState(ctx, action, savePageData && entities);
  });

  return state;
};

export const mergeFilterToState = (state, action, entities) => {
  const responsePage = getResponsePagingData(action);
  if (responsePage) {
    const pagingData = fromJS(responsePage);
    const filter = getFilter(action);
    const dataPath = ['page', filter.serializeFilter()];
    const pages = state.getIn(dataPath, Map());
    const paging = Map()
      .set('pages', List.of(pagingData.get('number')))
      .set('paging', pagingData)
      .set('data', fromJS(entities));
    return state.setIn(dataPath, pages.mergeDeep(paging));
  }
  return state;
};

export const mergeSingleResponseToState = (state, action, entityType) => {
  return mergeEntityListToState(state, [getResponseData(action)], entityType);
};

export const compressApiResponse = (entity) => {
  delete entity._links;
  delete entity.children;
  delete entity.parentItem;
  return entity;
};

export const buildStructuredTree = (state) => {
  let allNodes = state.get('data');

  let createBranch = (node, children) =>
    new Map().set('node', node).set('children', children);
  let getChildNodes = (parent) =>
    allNodes.filter((node) => node.get('parent') === parent.get('id'));
  let buildTree = (items) =>
    items.map((item) => createBranch(item, buildTree(getChildNodes(item))));

  let treetop = allNodes.filter((item) => item.get('parent') === null);
  let tree = buildTree(treetop);

  let location = ['tree'];

  state = state.setIn(location, state.getIn(location).merge(tree));

  return state;
};

export const getEntities = (action, type) => {
  let responseData = getResponseData(action);
  return responseData['_embedded']
    ? responseData['_embedded'][type]
    : [...(Array.isArray(responseData) ? responseData : [responseData])];
};

export const mergeFavoriteToState = (state, action) => {
  let entities = getEntities(action, EntityTypes.FAVORITE);
  return state.withMutations((ctx) => {
    entities.forEach((entity) => {
      const cleanedEntity = fromJS(compressApiResponse(entity));
      const path = ['data', entity.type, `${entity.entityId}`];
      const node = ctx.getIn(path, Map());
      ctx = ctx.setIn(path, node.merge(cleanedEntity));
    });
    return ctx;
  });
};
