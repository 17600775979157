import React from 'react';
import Text, { TextType } from '../../common/atoms/Text';
import PropTypes from 'prop-types';

class ProductPresentation extends React.Component {
  static propTypes = {
    subHeadline: PropTypes.string,
    bodyText: PropTypes.oneOf([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.string,
    ]),
  };

  static defaultProps = {
    subHeadline: '',
    bodytext: '',
  };

  render() {
    let { subHeadline, bodytext } = this.props;
    bodytext = Array.isArray(bodytext) ? bodytext : [bodytext];

    return (
      <>
        <Text as="h3" type={TextType.HEADER_3} className="sub-headline">
          {subHeadline}
        </Text>
        {bodytext.map((bt, idx) => (
          <Text
            key={'bodytext-p-' + idx}
            as="p"
            type={TextType.PARAGRAPH_STRONG}
            className="body-text"
          >
            {bt}
          </Text>
        ))}
      </>
    );
  }
}

export default ProductPresentation;
