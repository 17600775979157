import * as ActionTypes from '../../constants/ActionTypes';

export const getAllFavorites = () => ({
  type: ActionTypes.API_GET_ALL_FAVORITES_INIT,
  payload: {},
});
export const getFavoritesByType = (types) => ({
  type: ActionTypes.API_GET_FAVORITES_INIT,
  payload: { types: [types] },
});
export const toggleFavoriteByNode = (node, favorites) => ({
  type: ActionTypes.API_SET_FAVORITE_INIT,
  payload: { node, favorites },
});
export const toggleFavorite = (favorite) => ({
  type: ActionTypes.API_SET_FAVORITE_INIT,
  payload: { favorite },
});
export const getAllFavoritesExpanded = () => ({
  type: ActionTypes.API_GET_ALL_FAVORITES_EXPANDED_INIT,
  payload: {},
});
