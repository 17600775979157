import { call, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import TheoryItemConnector from '../../../connectors/TheoryItem';
import { generateApiSaga } from '../../../utils/sagas';

var doGetAllTopLevelChildren = generateApiSaga(
  TheoryItemConnector,
  function* (connector, action) {
    const { node, paging, filter } = action.payload;
    return yield call([connector, connector.getTopLevel], node, paging, filter);
  },
);

export default function* getalltoplevel() {
  yield takeEvery(
    ActionTypes.API_THEORY_ITEM_GET_TOP_INIT,
    doGetAllTopLevelChildren,
  );
}
