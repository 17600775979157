/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { withTranslation } from 'react-i18next';
import TreeItem from './TreeItem';
import Text, { TextType } from '../common/atoms/Text';
import lawLogo from '../../images/lawlogo.PNG';
import decisionLogo from '../../images/deicisionlogo.PNG';
import theoryLogo from '../../images/theorylogo.PNG';
import processLogo from '../../images/processlogo.PNG';
import keywordLogo from '../../images/keywords.png';
import { uiColors } from '../common/styles/styles';
import ArticleCountGrid from '../common/molecules/ArticleCountGrid';
import { sort } from '../../utils/sort';
import ControlledByPermission from '../../containers/shared/ControlledByPermission';

const commonULStyles = {
  root: {
    marginLeft: '0.5em',
    position: 'relative',
    padding: 0,
    listStyle: 'none',
  },
  before: {
    content: `""`,
    display: 'block',
    width: 0,
    position: 'absolute',
    bottom: 0,
    left: 0,
    borderLeft: '1px dotted',
  },
};

const styles = {
  '.headline': {
    padding: '0.75em 0',
    display: 'grid',
    gridTemplateColumns: 'auto 8rem',
    gridGap: '0.1rem',
    borderBottom: `1px solid ${uiColors.BLACK}`,
    backgroundColor: uiColors.WHITE,
    position: 'sticky',
    zIndex: 10,
    top: 0,
    '& .icons': {
      height: 'fit-content',
    },
    p: {
      margin: 0,
    },
  },
  '.list-header-article-layout': {
    display: 'grid',
    gridTemplateColumns: '2rem auto 8.5rem',
  },
  '.list-header-layout': {
    display: 'grid',
    gridTemplateColumns: '2rem auto',
  },
  '.leaf-article-layout': {
    display: 'grid',
    gridTemplateColumns: 'auto 8.5rem',
    gridGap: '0.2rem',
  },
  '.leaf-layout': {
    display: 'grid',
    gridTemplateColumns: '1fr',
  },
  '.no-list': {
    padding: 0,
    listStyle: 'none',
  },
  '.branch': {
    lineHeight: '1.5em',
    position: 'relative',
    zIndex: 0,
  },
  '.ul': {
    ...commonULStyles.root,
    ':before': {
      ...commonULStyles.before,
      top: 0,
    },
  },
  // Prevents dotted line from showing for "hidden" elements
  '.ul-expanded': {
    ...commonULStyles.root,
    ':before': {
      ...commonULStyles.before,
      top: '0',
    },
  },
  '.li': {
    margin: 0,
    padding: '0 0 0 1.5em',
    lineHeight: '1.5em',
    position: 'relative',
    ':before': {
      content: `""`,
      display: 'block',
      width: '1.5em',
      height: 0,
      borderTop: '1px dotted',
      marginTop: '-1px',
      position: 'absolute',
      top: '1em',
      left: 0,
    },
    ':last-child:before': {
      background: '#fff',
      height: 'auto',
      top: '1em',
      bottom: 0,
    },
  },
  '.li-leaf-node': {
    paddingLeft: '5px',
  },
  '.li-text': {
    ':hover': {
      color: uiColors.BRAND,
      backgroundColor: uiColors.WHITE,
      cursor: 'pointer',
    },
  },
  '.active-node': {
    color: uiColors.BRAND,
    backgroundColor: uiColors.WHITE,
  },
  '.fold-button': {
    marginRight: '5px',
    height: '26px',
    width: '20px',
  },
  '.show-list-item': {
    display: 'list-item',
  },
  '.hide-list-item': {
    display: 'none',
  },
  '.children-node-wrapper': {
    marginBottom: '0.5em',
  },
  '.fold-button-extender': {
    width: 0,
    marginLeft: '0.5em',
    position: 'relative',
    borderLeft: '1px dotted',
    zIndex: '-10',
    minHeight: 'calc(100% + 0.5em - 26px)',
  },
  '.dynamic-content .fold-button-extender': {
    border: 0,
  },
};

class NavigationTree extends React.Component {
  constructor(props) {
    super(props);

    this.handleSelectNode = this.handleSelectNode.bind(this);
    this.renderCountElement = this.renderCountElement.bind(this);
  }

  handleSelectNode(selectedNode) {
    this.props.onNodeSelection && this.props.onNodeSelection(selectedNode);
  }

  renderCountElement() {
    return (
      <ArticleCountGrid>
        <img
          src={lawLogo}
          alt={this.props.t('article-editor.lawitem')}
          title={this.props.t('article-editor.lawitem')}
        />
        <img
          src={decisionLogo}
          alt={this.props.t('article-editor.decision')}
          title={this.props.t('article-editor.decision')}
        />
        <img
          src={theoryLogo}
          alt={this.props.t('article-editor.theoryitem')}
          title={this.props.t('article-editor.theoryitem')}
        />
        <img
          src={processLogo}
          alt={this.props.t('article-editor.processstep')}
          title={this.props.t('article-editor.processstep')}
        />
        <img
          src={keywordLogo}
          alt={this.props.t('article-editor.taxonomy')}
          title={this.props.t('article-editor.taxonomy')}
        />
      </ArticleCountGrid>
    );
  }

  getHeadlineSection() {
    const { headline, countPermission, isArticle } = this.props;
    return (
      headline && (
        <div className="headline">
          <div className="mr-2">
            <Text type={TextType.HEADER_5} as="p">
              {headline}
            </Text>
          </div>
          {isArticle && (
            <ControlledByPermission
              permission={countPermission}
              render={
                <div className="icons d-flex justify-content-end">
                  {' '}
                  {this.renderCountElement()}
                </div>
              }
            />
          )}
        </div>
      )
    );
  }

  render() {
    const {
      tree,
      selectedNode,
      isArticle,
      onTemplateRef,
      onNodeExpand,
      expandLevel,
      expandedNodeUri,
      entityType,
      className,
      noHover,
      linkHighlightedNodes,
    } = this.props;
    const headlineSection = this.getHeadlineSection();
    const sortEntityType = entityType || '';
    return (
      <div css={styles} className={`px-1 ${className}`}>
        {headlineSection}
        {tree && (
          <ul className="no-list">
            {sort(tree, sortEntityType).map((branch) => {
              const { id, entityType } = branch.node;
              const shouldExpand = branch.isCurrent && Infinity;
              return (
                <li className="branch" key={id} id={`tree-${id}`}>
                  <TreeItem
                    entityType={entityType}
                    branch={branch}
                    parents={[]}
                    selectedNode={selectedNode}
                    isArticle={isArticle}
                    countPermission={this.props.countPermission}
                    isChildSelected={selectedNode?.node?.id === id}
                    onNodeSelection={this.handleSelectNode}
                    expandLevel={shouldExpand || expandLevel}
                    expandedNodeUri={expandedNodeUri}
                    level={0}
                    onNodeExpand={onNodeExpand}
                    onTemplateRef={onTemplateRef}
                    noHover={noHover}
                    linkHighlightedNodes={linkHighlightedNodes}
                  />
                </li>
              );
            })}
          </ul>
        )}
      </div>
    );
  }
}

export default withTranslation()(NavigationTree);
