/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import Filter from '../../components/common/molecules/Filter';
import * as commonActions from '../../actions/commonactions';
import * as decisionApiAction from '../../actions/api/decision';
import * as decisionApiSelector from '../../selectors/api/decision';
import { refBind } from '../../utils/ref-bind';
import * as SelectUtils from '../../utils/AutocompleteMapper';
import { createStructuredSelector } from 'reselect';
import { createTagName } from '../../utils/filter';

class DecisionSearch extends Component {
  constructor(props) {
    super(props);
    this.state = { decisionFilter: '', selected: null };
    refBind(this, 'searchRelatedDecisions', 'handleChange');
  }

  componentDidMount() {
    const { actions } = this.props;
    actions.searchDecisions('');
  }

  searchRelatedDecisions(event) {
    const { actions } = this.props;
    const decisionFilter = event || '';
    this.setState({ decisionFilter });
    decisionFilter &&
      actions.debounce(decisionApiAction.searchByName(decisionFilter));
  }

  handleChange(selected) {
    const { clearOnSelect, onDecisionsChange } = this.props;
    if (!clearOnSelect) {
      this.setState({ selected });
    }
    onDecisionsChange && onDecisionsChange(selected);
  }

  render() {
    const { decisionData, isDisabled, ignoreOptions, isMulti } = this.props;
    const { decisionFilter, selected } = this.state;
    const currentDecisionFilter = createTagName(decisionFilter);
    const filterData = decisionData[currentDecisionFilter];
    const decisionOptions = SelectUtils.removeIgnoredItems(
      SelectUtils.mapDecisions(filterData),
      ignoreOptions,
    );

    return (
      <Filter
        isDisabled={isDisabled}
        options={decisionOptions || []}
        isMulti={isMulti}
        value={selected}
        onInputChange={this.searchRelatedDecisions}
        onChange={this.handleChange}
      />
    );
  }
}

const mapStateToProps = createStructuredSelector({
  decisionData: decisionApiSelector.getSearchData,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      debounce: commonActions.debounceAction,
      searchDecisions: decisionApiAction.searchByName,
    },
    dispatch,
  ),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withImmutablePropsToJS,
)(DecisionSearch);
