import BaseConnector from './Base';
import * as EntityType from '../constants/EntityTypes';
import * as Projections from '../constants/Projections';

export default class AppUserConnector extends BaseConnector {
  constructor(...args) {
    super(...args);
    this.entityType = EntityType.APPUSER;
  }

  getAll() {
    return this.authGet(
      this.query()
        .withProjection(Projections.RELATED_IDS)
        .withSize(1000)
        .build(),
    );
  }

  getUserById(id) {
    return this.authGet(
      this.query().withProjection(Projections.RELATED_IDS).withId(id).build(),
    );
  }

  getUserConsentById(id) {
    return this.authGet(
      this.query()
        .withId(id)
        .withLink('consents')
        .withProjection(Projections.WITHID)
        .build(),
    );
  }

  updateAppUser(user) {
    return this.authPut(this.query().withId(user.id).build(), user);
  }

  updateAppUserConsent(userId, consent) {
    consent = consent || { active: false };
    return consent.id
      ? this.updateConsent(consent)
      : this.createConsent(userId, consent);
  }

  updateConsent(consent) {
    return this.authPut(
      this.query(EntityType.APPUSERCONSENT).withId(consent.id).build(),
      consent,
    );
  }

  createConsent(appUserId, consent) {
    consent = { ...consent, appUser: `/appUser/${appUserId}` };
    return this.authPost(
      this.query(EntityType.APPUSERCONSENT).build(),
      consent,
    );
  }
}
