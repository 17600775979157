/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { spacing, uiColors } from '../styles/styles';

const styles = {
  height: spacing.headerHeight,
  position: 'fixed',
  top: 0,
  right: 0,
  left: 0,
  zIndex: 1020,
  background: uiColors.PURPLE_DARK,
  transitionProperty: 'opacity',
  transitionTimingFunction: 'ease-in',
  transitionDuration: '150ms',
  willChange: 'opacity',
  opacity: 1,
  '&.active': {
    opacity: 1,
  },
};

class MenuBackdropPageLayout extends React.Component {
  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
    this.state = {
      showBackdrop: false,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(event) {
    let scrollTop = document.documentElement.scrollTop;
    this.setState({ showBackdrop: scrollTop > 0 });
  }

  render() {
    return (
      <React.Fragment>
        <div
          className={this.state.showBackdrop ? 'active' : ''}
          css={styles}
        ></div>
        {this.props.children}
      </React.Fragment>
    );
  }
}

export default MenuBackdropPageLayout;
