import { call, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import lawPrefaceItemConnector from '../../../connectors/LawPrefaceItem';
import { generateApiSaga } from '../../../utils/sagas';

const doGetLawPrefaceItemByLaw = generateApiSaga(
  lawPrefaceItemConnector,
  function* (connector, action) {
    let lawId = action.payload.node.get('id');
    let { pageSize, page } = action.payload?.paging;
    return yield call(
      [connector, connector.getAllByLaw],
      lawId,
      pageSize,
      page,
    );
  },
);

export default function* getLawPrefaceItemByLaw() {
  yield takeEvery(
    ActionTypes.API_LAW_PREFACE_ITEM_GET_ALL_BY_LAW_INIT,
    doGetLawPrefaceItemByLaw,
  );
}
