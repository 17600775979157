import { fromJS } from 'immutable';
import * as ActionTypes from '../../constants/ActionTypes';

const initialState = fromJS({});

export default function adminPageReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.APP_PAGE_ADMIN_SELECT_NODE:
      return state.merge(
        fromJS({
          currentNode: action.payload.node,
          waiting: false,
        }),
      );
    default:
      return state;
  }
}
