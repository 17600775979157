import { call, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import { generateApiSaga } from '../../../utils/sagas';
import Decision from '../../../connectors/Decision';

const create = generateApiSaga(Decision, function* (connector, action) {
  const decision = action.payload.decision;
  return yield call([connector, connector.createDecision], decision);
});

export default function* createDecision() {
  yield takeEvery(ActionTypes.API_CREATE_DECISION_INIT, create);
}
