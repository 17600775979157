import { select } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import { callAndAwaitApi, takeLatestWhenAppReady } from '../../../utils/sagas';
import * as TheorySelectors from '../../../selectors/page/theory';
import * as LawSelectors from '../../../selectors/page/law';
import * as DecisionSelectors from '../../../selectors/page/decision';
import * as theoryItemApiActions from '../../../actions/api/theoryitem';
import * as lawItemApiActions from '../../../actions/api/lawitem';
import * as decisionApiActions from '../../../actions/api/decision';
import * as taxonomyApiActions from '../../../actions/api/taxonomy';

function* loadRelatedTheoryItems(ids) {
  yield callAndAwaitApi(theoryItemApiActions.getByIds(ids));
}

function* loadRelatedLawItems(ids) {
  yield callAndAwaitApi(lawItemApiActions.getByIds(ids));
}

function* loadRelatedDecisions(ids) {
  yield callAndAwaitApi(decisionApiActions.getByIds(ids));
}

function* loadRelatedKeywords(ids) {
  yield callAndAwaitApi(taxonomyApiActions.getByIds(ids));
}

function* doPageSelectNode(item) {
  if (!item) {
    return;
  }

  let itemJS = item.toJS().node;

  if (itemJS.relatedTheoryItems) {
    yield loadRelatedTheoryItems(itemJS.relatedTheoryItems.map((i) => i.id));
  }

  if (itemJS.relatedLawItems) {
    yield loadRelatedLawItems(itemJS.relatedLawItems.map((i) => i.id));
  }

  if (itemJS.relatedDecisions) {
    yield loadRelatedDecisions(itemJS.relatedDecisions.map((i) => i.id));
  }

  if (itemJS.keywords) {
    yield loadRelatedKeywords(itemJS.keywords.map((i) => i.id));
  }
}

function* doPageSelectTheory() {
  let theory = yield select(TheorySelectors.getSelectedNode);
  yield doPageSelectNode(theory);
}

function* doPageSelectLaw() {
  let law = yield select(LawSelectors.getCurrentNode);
  yield doPageSelectNode(law);
}

function* doPageSelectDecision() {
  let decision = yield select(DecisionSelectors.getCurrentNode);
  yield doPageSelectNode(decision);
}

export default function* getAll() {
  yield takeLatestWhenAppReady(
    ActionTypes.APP_PAGE_THEORY_SELECT_NODE,
    doPageSelectTheory,
  );
  yield takeLatestWhenAppReady(
    ActionTypes.APP_PAGE_LAW_SELECT_NODE,
    doPageSelectLaw,
  );
  yield takeLatestWhenAppReady(
    ActionTypes.APP_PAGE_DECISION_SELECT_NODE,
    doPageSelectDecision,
  );
}
