import * as moment from 'moment';
import 'moment/locale/da';
import i18next from 'i18next';

/**
 * @param locale
 * sets Locale for moment and i18next locale
 * moment only imports "en" locale so all supported locales should be manually loaded like "import 'moment/locale/da';"
 */
export const setLocale = (locale) => {
  moment.locale(locale);
  i18next.changeLanguage(locale);
};

export const translationWithDefaultValue = (
  translatorHandler,
  prefix,
  keyword,
  defaultValue,
) => {
  const key = `${prefix}.${keyword}`;
  const translatedValue = translatorHandler(key);
  if (translatedValue === key) {
    return defaultValue ?? keyword;
  }
  return translatedValue;
};
