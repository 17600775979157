/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import KeywordAdder from './KeywordAdder';

/**
 * Recursively builds a nested structure corresponding to nested lists,
 * and adds styling based on information from the translation file
 **/
class ProductPresentationList extends React.Component {
  render() {
    const { input } = this.props;

    if (!input || input.length === 0 || !input.text) {
      return <div />;
    } else if (input.class && input.children) {
      return (
        <ul>
          <li className={'dot ' + input.class}>{input.text}</li>
          <ul>
            {input.children.map((entry, index) => (
              <ProductPresentationList input={entry} key={index} />
            ))}
          </ul>
        </ul>
      );
    } else if (input['children']) {
      return (
        <ul>
          <li>
            <KeywordAdder input={input.text} />
          </li>
          <ul>
            {input.children.map((entry, index) => (
              <ProductPresentationList input={entry} key={index} />
            ))}
          </ul>
        </ul>
      );
    } else {
      return (
        <li>
          <KeywordAdder input={input.text} />
        </li>
      );
    }
  }
}

export default ProductPresentationList;
