import { call, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import { generateApiSaga } from '../../../utils/sagas';
import RelationConnector from '../../../connectors/RelationConnector';

const doUpdate = generateApiSaga(RelationConnector, function* (connector) {
  return yield call([connector, connector.updateAll]);
});

export default function* updateAll() {
  yield takeEvery(ActionTypes.API_UPDATE_RELATION_COUNT_INIT, doUpdate);
}
