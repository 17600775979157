import { fromJS } from 'immutable';
import * as ActionTypes from '../../constants/ActionTypes';
import { mergeResponseToState } from './index';
import * as EntityTypes from '../../constants/EntityTypes';

const initialState = fromJS({
  data: {},
  tree: {}, // TODO - THIS SHOULD BE OBTAINED VIA A CACHED (PAGE) SELECTOR
});

export default function processApiReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.AUTH_LOGIN_COMPLETE:
    case ActionTypes.AUTH_LOGOUT_SUCCESS:
      return initialState;
    case ActionTypes.API_PROCESS_GET_ALL_SUCCESS:
      return mergeResponseToState(state, action, EntityTypes.PROCESS); // TODO - THIS SHOULD BE OBTAINED VIA A CACHED (PAGE) SELECTOR
    default:
      return state;
  }
}
