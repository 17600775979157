import * as ActionTypes from '../../constants/ActionTypes';

export const getAll = () => ({ type: ActionTypes.API_TAXONOMY_GET_ALL_INIT });
export const getByIds = (ids) => ({
  type: ActionTypes.API_TAXONOMY_GET_BY_IDS_INIT,
  payload: { ids },
});
export const getRelationTreeById = (id) => ({
  type: ActionTypes.API_TAXONOMY_GET_RELATION_TREE_BY_ID_INIT,
  payload: { id },
});
