/** @jsx jsx*/
import { jsx } from '@emotion/core';
import React, { Component } from 'react';
import Text, { TextType } from '../atoms/Text';
import { Trans } from 'react-i18next';
import Checkbox from '../atoms/Checkbox';
import * as ConsentTypes from '../../../constants/ConsentTypes';
import PropTypes from 'prop-types';
import { refBind } from '../../../utils/ref-bind';
import ControlledByPermission from '../../../containers/shared/ControlledByPermission';
import { PERMISSIONS } from '../../../constants/Permissions';

const consentPropType = {
  consent: PropTypes.shape({
    active: PropTypes.bool,
    appUserId: PropTypes.number,
    consentType: PropTypes.string,
    entityType: PropTypes.string,
    id: PropTypes.number,
  }),
};

const propTypes = {
  onConsentCheckbox: PropTypes.func.isRequired,
  allConsents: PropTypes.shape({
    newsConsent: consentPropType.consent,
    instantConsent: consentPropType.consent,
    dailyConsent: consentPropType.consent,
    dailyFavoritesConsent: consentPropType.consent,
  }),
};

const defaultProps = {
  allConsents: {
    newsConsent: null,
    instantConsent: null,
    dailyConsent: null,
    dailyFavoritesConsent: null,
  },
};

class Subscriptions extends Component {
  constructor(props) {
    super(props);
    refBind(
      this,
      'handleNewsletterCheckbox',
      'handleInstantCheckbox',
      'handleDailyAllCheckbox',
      'handleDailyFavoritesCheckbox',
    );
  }

  handleNewsletterCheckbox() {
    const { allConsents, onConsentCheckbox } = this.props;
    const consent = allConsents.newsConsent
      ? {
          ...allConsents.newsConsent,
          active: !allConsents.newsConsent.active,
        }
      : {
          active: true,
          consentType: ConsentTypes.NEWS_CONSENT,
        };
    onConsentCheckbox(consent);
  }

  handleInstantCheckbox() {
    const { allConsents, onConsentCheckbox } = this.props;
    const consent = allConsents.instantConsent
      ? {
          ...allConsents.instantConsent,
          active: !allConsents.instantConsent.active,
        }
      : {
          active: true,
          consentType: ConsentTypes.INSTANT_CONSENT,
        };
    onConsentCheckbox(consent);
  }

  handleDailyAllCheckbox() {
    const { allConsents, onConsentCheckbox } = this.props;
    const consent = allConsents.dailyConsent
      ? {
          ...allConsents.dailyConsent,
          active: !allConsents.dailyConsent.active,
        }
      : {
          active: true,
          consentType: ConsentTypes.DAILY_ALL_CONSENT,
        };
    onConsentCheckbox(consent);
  }

  handleDailyFavoritesCheckbox() {
    const { allConsents, onConsentCheckbox } = this.props;
    const consent = allConsents.dailyFavoritesConsent
      ? {
          ...allConsents.dailyFavoritesConsent,
          active: !allConsents.dailyFavoritesConsent.active,
        }
      : {
          active: true,
          consentType: ConsentTypes.DAILY_FAVORITE_CONSENT,
        };
    onConsentCheckbox(consent);
  }

  render() {
    const { allConsents } = this.props;
    return (
      <div>
        <div className="pb-2">
          <div className="mb-4 text-capitalize">
            <Text type={TextType.HEADER_3}>
              <Trans i18nKey="subscriptions.header" />
            </Text>
          </div>
          <Text>
            <Trans i18nKey="subscriptions.description" />
          </Text>
        </div>
        <div className="pb-2">
          <Checkbox
            onChange={this.handleNewsletterCheckbox}
            checked={allConsents.newsConsent?.active}
          >
            <Trans i18nKey="subscriptions.newsletter-tooltip" />
          </Checkbox>
        </div>
        <div className="pb-2">
          <Checkbox
            onChange={this.handleInstantCheckbox}
            checked={allConsents.instantConsent?.active}
          >
            <Trans i18nKey="subscriptions.instant-mail-tooltip" />
          </Checkbox>
        </div>
        <div className="pb-2">
          <Checkbox
            onChange={this.handleDailyAllCheckbox}
            checked={allConsents.dailyConsent?.active}
          >
            <Trans i18nKey="subscriptions.daily-updates.all-tooltip" />
          </Checkbox>
        </div>
        <ControlledByPermission
          permission={PERMISSIONS.FAVORITE}
          render={
            <div className="pb-2">
              <Checkbox
                onChange={this.handleDailyFavoritesCheckbox}
                checked={allConsents.dailyFavoritesConsent?.active}
              >
                <Trans i18nKey="subscriptions.daily-updates.favorites-tooltip" />
              </Checkbox>
            </div>
          }
        />
      </div>
    );
  }
}

Subscriptions.propTypes = propTypes;
Subscriptions.defaultProps = defaultProps;

export default Subscriptions;
