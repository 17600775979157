import * as ActionTypes from '../../constants/ActionTypes';
import { mergeResponseToState } from './index';
import { APPUSERCONSENT } from '../../constants/EntityTypes';

import { fromJS } from 'immutable';

const initialState = fromJS({
  data: {},
});

export default function consentApiReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.AUTH_LOGIN_COMPLETE:
    case ActionTypes.AUTH_LOGOUT_SUCCESS:
      return initialState;
    case ActionTypes.API_USER_CONSENT_UPDATE_CONSENT_SUCCESS:
    case ActionTypes.API_USER_CONSENT_GET_USER_CONSENT_BY_ID_SUCCESS:
      return mergeResponseToState(state, action, APPUSERCONSENT);
    default:
      return state;
  }
}
