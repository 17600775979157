import * as licenseSelector from '../api/license';
import {
  STATE_ENTITY_PROPERTY,
  STATE_ROOT_PROPERTY,
} from '../../constants/StateProperties';

export const getSelectedUser = (state) =>
  state.getIn([
    STATE_ROOT_PROPERTY.page,
    STATE_ENTITY_PROPERTY.profile,
    'selectedUser',
  ]);
export const getSelectedNewsConsent = (state) =>
  state.getIn([
    STATE_ROOT_PROPERTY.page,
    STATE_ENTITY_PROPERTY.profile,
    'newsConsent',
  ]);
export const getSelectedInstantConsent = (state) =>
  state.getIn([
    STATE_ROOT_PROPERTY.page,
    STATE_ENTITY_PROPERTY.profile,
    'instantConsent',
  ]);
export const getSelectedDailyAllConsent = (state) =>
  state.getIn([
    STATE_ROOT_PROPERTY.page,
    STATE_ENTITY_PROPERTY.profile,
    'dailyAllConsent',
  ]);
export const getSelectedDailyFavoritesConsent = (state) =>
  state.getIn([
    STATE_ROOT_PROPERTY.page,
    STATE_ENTITY_PROPERTY.profile,
    'dailyFavoritesConsent',
  ]);
export const getUserLicense = (state) =>
  licenseFormatter(state, (l) => l.getIn([STATE_ROOT_PROPERTY.node, 'user']));
export const getNotClaimedAllLicense = (state) =>
  licenseFormatter(state, (l) => !l.getIn([STATE_ROOT_PROPERTY.node, 'user']));
const licenseFormatter = (state, fn) =>
  licenseSelector
    .getAvailable(state)
    .filter(fn)
    .map((l) => l.get(STATE_ROOT_PROPERTY.node))
    .toList();
