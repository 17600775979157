import * as ActionTypes from '../../constants/ActionTypes';
import { fromJS } from 'immutable';
import * as LocalStorageKeys from '../../constants/LocalStorageKeys';

const filter = window.localStorage.getItem(
  LocalStorageKeys.JURISDICTION_FILTER,
);
const initState = {
  jurisdictionFilter: filter ? JSON.parse(filter) : { eu: true, dk: true },
};
export default function initReducer(state = fromJS(initState), action) {
  switch (action.type) {
    case ActionTypes.APP_GLOBAL_UPDATE:
      if (action.payload.jurisdictionFilter) {
        window.localStorage.setItem(
          LocalStorageKeys.JURISDICTION_FILTER,
          JSON.stringify(action.payload.jurisdictionFilter),
        );
      }
      return state.merge(fromJS(action.payload));
    default:
      return state;
  }
}
