import * as ActionTypes from '../../constants/ActionTypes';

export const getAllByProcess = (node) => ({
  type: ActionTypes.API_PROCESSSTEP_GET_ALL_BY_PROCESS_INIT,
  payload: { node },
});
export const getAllByProcessTxn = (node) => ({
  type: ActionTypes.API_PROCESSSTEP_GET_ALL_BY_PROCESS_TXN,
  payload: { node },
});
export const searchByName = (name) => ({
  type: ActionTypes.API_FIND_PROCESS_STEP_BY_NAME_INIT,
  payload: { name },
});
export const getByIds = (ids) => ({
  type: ActionTypes.API_GET_PROCESS_STEP_BY_IDS_INIT,
  payload: { ids },
});
export const getRelationTreeById = (id) => ({
  type: ActionTypes.API_PROCESS_STEP_GET_RELATION_TREE_BY_ID_INIT,
  payload: { id },
});
