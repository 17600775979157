import * as CommonSelectors from '../entity/common';
import * as EntityTypes from '../../constants/EntityTypes';
import { getByFn } from './index';
import EntityFields from '../../constants/EntityFields';
import { FILTER_DOG_TAG } from '../../utils/filter';
import { List } from 'immutable';
import { STATE_ENTITY_PROPERTY } from '../../constants/StateProperties';

export const getAll = (state) =>
  CommonSelectors.getAll(state, EntityTypes.DECISION);
export const getDecisionsByIds = (state, ids) =>
  ids ? ids.map((id) => getById(state, Number(id))).filter((v) => v) : List();
export const get = (state, id) =>
  CommonSelectors.getEntity(state, EntityTypes.DECISION, parseInt(id));
export const getByUri = (state, uri) =>
  getByFn(
    state,
    EntityTypes.DECISION,
    (x) => x.get(EntityFields[EntityTypes.DECISION].TITLE) === uri,
  );
export const getById = (state, id) =>
  state.getIn(['api', EntityTypes.DECISION, 'data', id]);
export const getFilterPages = (state, filter = '{}') =>
  state.getIn([
    'api',
    EntityTypes.DECISION,
    'page',
    FILTER_DOG_TAG.concat(filter),
  ]);
export const getAllFilterPages = (state) =>
  state.getIn(['api', EntityTypes.DECISION, 'page']);
export const getSearchData = (state) =>
  state.getIn(
    ['api', EntityTypes.DECISION, STATE_ENTITY_PROPERTY.searchdata],
    {},
  );
export const getDecisionCaseTypes = (state) =>
  state.getIn(
    ['api', EntityTypes.DECISION, 'metaData', 'decisionCaseTypes'],
    [],
  );
export const getDecisionTypes = (state) =>
  state.getIn(['api', EntityTypes.DECISION, 'metaData', 'decisionTypes'], []);
export const getDecisionMeritTypes = (state) =>
  state.getIn(
    ['api', EntityTypes.DECISION, 'metaData', 'decisionMeritTypes'],
    [],
  );
export const getDecisionReactionTypes = (state) =>
  state.getIn(
    ['api', EntityTypes.DECISION, 'metaData', 'decisionReactionTypes'],
    [],
  );
export const getDecisionFormalityTypes = (state) =>
  state.getIn(
    ['api', EntityTypes.DECISION, 'metaData', 'decisionFormalityTypes'],
    [],
  );
export const getDecisionReactionFormality = (state) =>
  state.getIn(
    ['api', EntityTypes.DECISION, 'metaData', 'decisionReactionFormality'],
    [],
  );
export const getDecisionSuspensiveEffect = (state) =>
  state.getIn(
    ['api', EntityTypes.DECISION, 'metaData', 'decisionSuspensiveEffect'],
    [],
  );
export const getDecisionProcurementForm = (state) =>
  state.getIn(
    ['api', EntityTypes.DECISION, 'metaData', 'decisionProcurementForm'],
    [],
  );
export const getDecisionProcurementFormProcedure = (state) =>
  state.getIn(
    [
      'api',
      EntityTypes.DECISION,
      'metaData',
      'decisionProcurementFormProcedure',
    ],
    [],
  );
export const getDecisionProcurementFormAddon = (state) =>
  state.getIn(
    ['api', EntityTypes.DECISION, 'metaData', 'decisionProcurementFormAddon'],
    [],
  );
export const getFilterEntities = (state, filter = '{}') =>
  getFilterPages(state, filter)?.get('data');
export const getByIdsAsImmutable = (state, decisionIds) =>
  getDecisionsByIds(state, List.of(...(decisionIds || [])));
