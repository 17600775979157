import { all, fork } from 'redux-saga/effects';
import appSagas from './app';
import apiSagas from './api';
import pageSagas from './page';
import dataSagas from './data';
import commonRootSaga from './common';

export default function* rootSaga() {
  yield all([
    fork(appSagas),
    fork(apiSagas),
    fork(pageSagas),
    fork(dataSagas),
    fork(commonRootSaga),
  ]);
}
