import { takeLatest, call, select } from 'redux-saga/effects';
import * as ActionTypes from '../../constants/ActionTypes';
import AuthConnector from '../../connectors/Auth';
import { generateApiSaga } from '../../utils/sagas';
import { baseAuthUrl } from '../../selectors/store/app';

var doJwtRefresh = generateApiSaga(
  AuthConnector,
  function* (connector, action) {
    return yield call([connector, connector.RefreshJwt]);
  },
  function* (ConnectorClass) {
    const url = yield select(baseAuthUrl);
    return yield new ConnectorClass(url);
  },
);

export default function* jwtRefresh() {
  yield takeLatest(ActionTypes.AUTH_JWT_REFRESH_INIT, doJwtRefresh);
}
