/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import Filter from '../../components/common/molecules/Filter';
import * as taxonomyApiSelectors from '../../selectors/api/taxonomy';
import * as SelectUtils from '../../utils/AutocompleteMapper';

class TaxonomySearch extends Component {
  constructor(props) {
    super(props);
    this.state = { selected: null };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(selected) {
    const { clearOnSelect, onKeywordsChange } = this.props;
    if (!clearOnSelect) {
      this.setState({ selected });
    }
    onKeywordsChange && onKeywordsChange(selected);
  }

  render() {
    const { isDisabled, keywords, isMulti } = this.props;
    const { selected } = this.state;
    return (
      <Filter
        isDisabled={isDisabled}
        options={keywords}
        isMulti={isMulti}
        value={selected}
        onChange={this.handleChange}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const data = SelectUtils.mapImmutableKeywords(
    taxonomyApiSelectors.getAll(state).toList(),
  );
  const keywords = SelectUtils.removeIgnoredItems(data, ownProps.ignoreOptions);
  return {
    ...ownProps,
    keywords,
  };
};

export default compose(
  connect(mapStateToProps),
  withImmutablePropsToJS,
)(TaxonomySearch);
