import { fromJS } from 'immutable';
import * as ActionTypes from '../../constants/ActionTypes';
import { mergeEntityListToState, mergeResponseToState } from './index';
import * as EntityTypes from '../../constants/EntityTypes';
import { getResponseData } from '../../selectors/actions';
import { STATE_ENTITY_PROPERTY } from '../../constants/StateProperties';
import { HAL_LIST_PROPERTY } from '../../constants/HalProperties';
import { createTagName } from '../../utils/filter';

const initialState = fromJS({
  [STATE_ENTITY_PROPERTY.isLoadingRelatedLawItems]: false, // TODO: Move to a page reducer
  [STATE_ENTITY_PROPERTY.data]: {},
  [STATE_ENTITY_PROPERTY.searchdata]: {},
  [STATE_ENTITY_PROPERTY.page]: {},
  [STATE_ENTITY_PROPERTY.lawItemTypes]: [],
});

export default function lawItemApiReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.AUTH_LOGIN_COMPLETE:
    case ActionTypes.AUTH_LOGOUT_SUCCESS:
      return initialState;
    case ActionTypes.API_LAWITEM_GET_BY_IDS_REQUEST:
      return state.merge({ isLoadingRelatedLawItems: true }); // TODO: Move to a page reducer
    case ActionTypes.API_LAWITEM_GET_BY_IDS_SUCCESS:
      return state.withMutations((ctx) => {
        mergeResponseToState(ctx, action, EntityTypes.LAWITEM);
        ctx.set(STATE_ENTITY_PROPERTY.isLoadingRelatedLawItems, false); // TODO: Move to a page reducer
      });
    case ActionTypes.API_GET_RELATION_TREE_LAW_ITEMS:
      return mergeEntityListToState(state, action.payload, EntityTypes.LAWITEM);
    case ActionTypes.API_LAWITEM_GET_ALL_SUCCESS:
    case ActionTypes.API_LAWITEM_GET_BY_URI_SUCCESS:
    case ActionTypes.API_LAWITEM_GET_ALL_BY_LAW_SUCCESS:
    case ActionTypes.API_LAWITEM_GET_TOP_LEVEL_BY_LAW_SUCCESS:
    case ActionTypes.API_LAWITEM_GET_ALL_CHILDREN_SUCCESS:
    case ActionTypes.API_LAWITEM_GET_ALL_BY_URI_WITH_PARENTS_SUCCESS:
      return mergeResponseToState(state, action, EntityTypes.LAWITEM);
    case ActionTypes.API_LAWITEM_SEARCH_BY_NAME_SUCCESS:
      let responseData =
        getResponseData(action)[HAL_LIST_PROPERTY][EntityTypes.LAWITEM];
      const searchKey = createTagName(action.payload.initAction.payload.name);
      return state.setIn(
        [STATE_ENTITY_PROPERTY.searchdata, searchKey],
        fromJS(responseData),
      );
    case ActionTypes.API_LAW_ITEM_GET_TOP_SUCCESS:
      return mergeResponseToState(state, action, EntityTypes.LAWITEM, true);
    case ActionTypes.API_GET_ALL_LAW_ITEM_TYPES_SUCCESS:
      const data = getResponseData(action);
      return state.merge(
        fromJS({ [STATE_ENTITY_PROPERTY.lawItemTypes]: data }),
      );
    default:
      return state;
  }
}
