import * as ActionTypes from '../../../constants/ActionTypes';
import { callAndAwaitApi, takeLatestWhenAppReady } from '../../../utils/sagas';
import { put, select } from 'redux-saga/effects';
import * as authSelectors from '../../../selectors/store/auth';
import * as appUserConsentApiActions from '../../../actions/api/appuserconsent';
import * as profilePageActions from '../../../actions/page/profile';
import * as profilePageSelector from '../../../selectors/page/profile';
import * as appUserApiSelector from '../../../selectors/api/appuser';
import { PROFILE_TABS } from '../../../constants/ProfileTabs';
import * as favoriteActions from '../../../actions/api/favorite';
import * as favoriteApiSelector from '../../../selectors/api/favorite';
import * as licenseSelector from '../../../selectors/api/license';
import * as licenseActions from '../../../actions/api/license';

function* doProfilePageLoad(action) {
  let isLoggedIn = yield select(authSelectors.isLoggedIn);
  if (isLoggedIn) {
    let [uri] = action.payload.url;
    uri = uri || PROFILE_TABS.GENERAL;
    switch (uri) {
      case PROFILE_TABS.GENERAL:
      case PROFILE_TABS.SUBSCRIPTION:
        yield doGeneralContextLoad();
        break;
      case PROFILE_TABS.FAVORITES:
        yield doFavoriteContextLoad();
        break;
      case PROFILE_TABS.MEMBERSHIP:
        yield doMembershipLoad();
        break;
      default:
        break;
    }
  }
}

function* doGeneralContextLoad() {
  let user = yield select(profilePageSelector.getSelectedUser);
  const userId = yield select(authSelectors.getUserId);
  if (!(user && user.get('id') !== userId)) {
    yield callAndAwaitApi(appUserConsentApiActions.getConsentsById(userId));
    user = yield select(appUserApiSelector.getCurrentUser);
    yield put(profilePageActions.selectUser(user && user.get('node')));
  }
}

function* doFavoriteContextLoad() {
  if (yield select(favoriteApiSelector.hasAll)) {
    return;
  }
  yield put(favoriteActions.getAllFavorites());
}

function* doMembershipLoad() {
  if (!(yield select(licenseSelector.getAvailable))?.size) {
    yield put(licenseActions.getAvailable());
  }
}

export default function* profilePageLoad() {
  yield takeLatestWhenAppReady(
    ActionTypes.APP_PAGE_PROFILE_LOAD,
    doProfilePageLoad,
  );
}
