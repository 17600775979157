import { takeEvery, put } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import * as lawPrefaceItemActions from '../../../actions/api/lawprefaceitem';
import { getAllPages } from '../../../actions/api';

function* doGetAllLawPrefaceItems(action) {
  const node = action.payload.node;
  const initAction = lawPrefaceItemActions.getAllByLaw(node);
  return yield put(getAllPages(initAction));
}

export default function* getAllLawPrefaceItems() {
  yield takeEvery(
    ActionTypes.API_LAW_PREFACE_ITEM_GET_ALL_BY_LAW_TXN,
    doGetAllLawPrefaceItems,
  );
}
