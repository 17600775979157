import { all, fork } from 'redux-saga/effects';
import getAll from './getall';
import getByIds from './getbyids';
import getByUri from './getbyuri';
import getAllByLaw from './getbylaw';
import getAllByLawTxn from './getallbylawtxn';
import getAllTxn from './getalltxn';
import getTopLevelByLawId from './gettopbylawid';
import getAllChildren from './getchildren';
import getLawItemParentById from './getwithparents';
import searchByRowName from './searchbylawitemrowname';
import getNewest from './getnewest';
import getRelatedLawItemsById from './getRelatedLawItemsById';
import getRelationTree from './getrelationtree';
import findUriToSelect from './finduritoselect';
import getRelatedById from './getrelatedbyid';
import getAllLawItemTypes from './getlawitemtypes';

export default function* appRootSaga() {
  yield all([
    fork(getAll),
    fork(getByUri),
    fork(getByIds),
    fork(getAllByLaw),
    fork(getAllByLawTxn),
    fork(getAllTxn),
    fork(getTopLevelByLawId),
    fork(getAllChildren),
    fork(getLawItemParentById),
    fork(searchByRowName),
    fork(getNewest),
    fork(getRelatedLawItemsById),
    fork(getRelationTree),
    fork(findUriToSelect),
    fork(getRelatedById),
    fork(getAllLawItemTypes),
  ]);
}
