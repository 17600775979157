import { fromJS } from 'immutable';
import * as ActionTypes from '../../constants/ActionTypes';
import jwtDecode from 'jwt-decode';
import * as LoginType from '../../constants/LoginTypes';
import { getError } from '../../selectors/actions/auth';
import { ANONYMOUS_PERMISSIONS } from '../../constants/Permissions';

const initialState = fromJS({
  currentUsername: null,
  currentLoginType: null,
  currentUserId: null,
  bearerToken: null,
  error: null,
  privileges: ANONYMOUS_PERMISSIONS,
  isLoading: false,
  setPassword: false,
  passwordReset: false,
  isInitializingAuth: false,
});

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.AUTH_LOGIN_REFRESHED:
      let jwtObj = jwtDecode(action.payload.bearerToken);
      let update = {
        currentUsername: jwtObj.email,
        currentUserId: jwtObj.sub,
        currentLoginType: LoginType.JWT_LOGIN,
        bearerToken: action.payload.bearerToken,
        error: null,
        privileges: fromJS(jwtObj.privileges),
      };
      return state.merge(update);
    case ActionTypes.AUTH_LOGIN_VERIFIED: {
      let update = {
        currentUsername: action.payload.username,
        currentLoginType: action.payload.loginType,
        bearerToken: action.payload.bearerToken,
        error: null,
      };

      if (action.payload.bearerToken) {
        let jwtObj = jwtDecode(action.payload.bearerToken);
        update.privileges = fromJS(jwtObj.privileges);
        update.currentUserId = jwtObj.sub;
      }

      return state.merge(update);
    }
    case ActionTypes.AUTH_LOGIN_FAILED:
      return state.merge({
        error: action.payload.message,
      });
    case ActionTypes.AUTH_CLEAR_LOGIN:
    case ActionTypes.AUTH_LOGOUT_SUCCESS:
    case ActionTypes.AUTH_LOGOUT_ERROR:
    case ActionTypes.AUTH_RESET_PASSWORD_INIT:
    case ActionTypes.AUTH_SET_PASSWORD_INIT:
    case ActionTypes.AUTH_LOGIN_INIT:
      return initialState;
    case ActionTypes.AUTH_LOGIN_REQUEST:
    case ActionTypes.AUTH_LOGOUT_REQUEST:
    case ActionTypes.AUTH_SET_PASSWORD_REQUEST:
    case ActionTypes.AUTH_RESET_PASSWORD_REQUEST:
      return state.merge({
        isLoading: true,
      });

    case ActionTypes.AUTH_COMPLETED:
      return state.merge({
        isInitializingAuth: false,
      });

    case ActionTypes.AUTH_INIT:
      return state.merge({
        isInitializingAuth: true,
      });

    case ActionTypes.AUTH_RESET_PASSWORD_ERROR:
    case ActionTypes.AUTH_LOGIN_COMPLETE:
      return state.merge({
        isLoading: false,
      });
    case ActionTypes.AUTH_RESET_PASSWORD_SUCCESS:
      return state.merge({
        isLoading: false,
        passwordReset: true,
      });
    case ActionTypes.AUTH_SET_ERROR:
      return state.merge({
        isLoading: false,
        error: action.error,
      });
    case ActionTypes.AUTH_SET_PASSWORD_ERROR:
      return state.merge({
        isLoading: false,
        error: getError(action),
      });
    case ActionTypes.AUTH_SET_PASSWORD_SUCCESS:
      return state.merge({
        isLoading: false,
        setPassword: true,
      });
    default:
      return state;
  }
}
