/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { Col, Form } from 'react-bootstrap';
import { kebabizeCamelCase } from '../../../utils/stringHelper';
import equal from 'fast-deep-equal';
import PropTypes from 'prop-types';

class GenericForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.props.form;
    this._onValueChanged = this._onValueChanged.bind(this);
  }

  static propTypes = {
    form: PropTypes.object,
    numberOfColumns: PropTypes.number,
  };

  static defaultProps = {
    numberOfColumns: 1,
  };

  componentDidUpdate = (prevProps) => {
    if (!equal(this.props.form, prevProps.form)) {
      this.setState(this.props.form);
    }
  };

  _onValueChanged(event) {
    this.state.form[event.target.name].value = event.target.value;
    this.setState(this.state);
    // Mitigate to parent
    this.props.onChange && this.props.onChange(this.state.form_name, event);
  }

  render() {
    let { form_name, form } = this.state;
    let { numberOfColumns } = this.props;

    const formName = `${form_name}-form`;
    const onChange = this._onValueChanged;

    return Object.entries(form)
      .reduce((accumulator, currentValue, currentIndex, array) => {
        currentIndex % numberOfColumns === 0 &&
          accumulator.push(
            array.slice(currentIndex, currentIndex + numberOfColumns),
          );
        return accumulator;
      }, [])
      .map((set, rowIndex) => (
        <Form.Row key={`${formName}-row-${rowIndex}`}>
          {set.map(([name, field], columnIndex) => {
            const props = { ...field, name, onChange };

            const baseName = `${formName}.${kebabizeCamelCase(name)}`;
            const id = `${baseName}-id`;
            const key = `${baseName}-key`;
            const label = `${baseName}-label`.toLowerCase();

            return (
              <Col key={`${formName}-col-${columnIndex}`}>
                <Form.Group controlId={id} key={key}>
                  <Form.Label>
                    <span className="text-title-case">
                      <Trans i18nKey={label} />:
                    </span>
                  </Form.Label>
                  {React.createElement(Form.Control, props, null)}
                </Form.Group>
              </Col>
            );
          })}{' '}
        </Form.Row>
      ));
  }
}

export default withTranslation()(GenericForm);
