import moment from 'moment';
import { DateFormat } from '../../constants/DateFormat';

export const formatToRequestDateTime = (date, format) =>
  date ? moment(date, format).format(DateFormat.requestDateTime) : '';
export const formatToRequestDate = (date, format) =>
  date ? moment(date, format).format(DateFormat.requestDate) : '';

export const getCurrentTimestamp = () => {
  return moment().format(DateFormat.uniqueDateFormat);
};
