import { generateApiSaga } from '../../../utils/sagas';
import lawPrefaceItemConnector from '../../../connectors/LawPrefaceItem';
import { call, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import * as Projections from '../../../constants/Projections';

const doGetRelatedLawPrefaceById = generateApiSaga(
  lawPrefaceItemConnector,
  function* (connector, action) {
    const { id } = action.payload;
    return yield call([connector, connector.getById], id, Projections.RELATED);
  },
);

export default function* getRelatedLawPrefaceById() {
  yield takeEvery(
    ActionTypes.API_LAW_PREFACE_ITEM_GET_RELATION_BY_ID_INIT,
    doGetRelatedLawPrefaceById,
  );
}
