import * as ActionTypes from '../../constants/ActionTypes';

export const pageLoad = (id) => ({
  type: ActionTypes.APP_PAGE_TAXONOMY_LOAD,
  payload: { id },
});

export const createTaxonomy = (taxonomy) => ({
  type: ActionTypes.API_TAXONOMY_CREATE_INIT,
  payload: { taxonomy },
});

export const setFilterText = (filterText) => ({
  type: ActionTypes.APP_PAGE_TAXONOMY_SET_FILTERTEXT_INIT,
  payload: { filterText },
});

export const effectuateSetFilterText = (filterText) => ({
  type: ActionTypes.APP_PAGE_TAXONOMY_SET_FILTERTEXT,
  payload: { filterText },
});

export const setCurrentTab = (tabName) => ({
  type: ActionTypes.APP_PAGE_TAXONOMY_SET_CURRENT_TAB,
  payload: { tabName },
});

export const setCurrentPageNumber = (pageNumber) => ({
  type: ActionTypes.APP_PAGE_TAXONOMY_SET_PAGE_NUMBER,
  payload: { pageNumber },
});
