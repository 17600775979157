/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import VerticalLine from '../atoms/VerticalLine';
import { Trans } from 'react-i18next';
import Text, { TextType } from '../atoms/Text';

const EntityIndex = {
  LAW: 0,
  DECISION: 1,
  THEORY: 2,
  PROCESS: 3,
  TAXONOMY: 4,
};

class ArticleFooterLayout extends React.Component {
  renderCol(index, isLastColumn = false) {
    const { colClassName, lineClass, useSeparator } = this.props;
    let { children } = this.props;
    if (!(children instanceof Array)) {
      // In case there is only one child, we don't get it wrapped as an array.
      children = [children];
    }
    return (
      <Fragment>
        <Col className={`py-2 ${colClassName}`}>{children[index]}</Col>
        {useSeparator && !isLastColumn && (
          <VerticalLine className={lineClass} />
        )}
      </Fragment>
    );
  }

  renderSpinner(collapsed) {
    return (
      <div
        className={`footer-content-row d-flex justify-content-center align-items-center ${
          collapsed ? '' : 'footer-content-expanded'
        }`}
      >
        <Spinner animation="border" className="spinner" />
      </div>
    );
  }

  loop = (n, fn) => fn(n) && n > 1 && this.loop(--n, fn);

  render() {
    const {
      entityType,
      className,
      currentNode,
      isLoading,
      collapsed,
      onClick,
      isWithCaption,
      currentNodeCaption,
    } = this.props;
    const isReadThread = entityType === 'red-thread';
    const view = {
      law: true,
      decision: true,
      theory: true,
      process: !isReadThread,
      taxonomy: !isReadThread,
    };
    const captionSection = [];
    if (isWithCaption) {
      captionSection.push(
        <Col key="chosen-item-box" className="chosen-item-box layout py-3">
          <Text type={TextType.CHOSEN_ITEM_IN_NAVIGATION_TREE}>
            {currentNode ? (
              <div onClick={onClick}>
                <span className="cursor-pointer">{currentNodeCaption}</span>
              </div>
            ) : (
              <Trans i18nKey={'article-footer-layout.no-item-selected'} />
            )}
          </Text>
        </Col>,
      );

      this.loop(4, (index) =>
        captionSection.push(<Col key={`offset-${index}`} />),
      );
    }
    return (
      <Fragment>
        {captionSection.length > 0 && (
          <Row style={{ height: 0 }}>{captionSection}</Row>
        )}
        <div className="layout scroll-bar">
          {isLoading && !collapsed && this.renderSpinner(collapsed)}
          <Row className={`wrapper ${className}`}>
            {view.law && this.renderCol(EntityIndex.LAW, isReadThread)}
            {view.decision &&
              this.renderCol(EntityIndex.DECISION, isReadThread)}
            {view.theory && this.renderCol(EntityIndex.THEORY, isReadThread)}
            {view.process && this.renderCol(EntityIndex.PROCESS, false)}
            {view.taxonomy && this.renderCol(EntityIndex.TAXONOMY, true)}
          </Row>
        </div>
      </Fragment>
    );
  }
}

export default ArticleFooterLayout;
