export const getSelectedNode = (state, nodeId) => {
  const selected = state.getIn(['page', 'admin', 'currentNode']);
  return selected && nodeId === selected.get('id') ? selected : null;
};

export const getUniqueItems = (list, deletedElements, mappedValues, mapFn) =>
  getUnique(mapFn(list), deletedElements, mappedValues);

const getUnique = (list, deletedElements, mappedValues) => {
  const result = {};
  [...list, ...(mappedValues || [])].forEach((e) => (result[e.value] = e));
  return Object.values(result).filter((i) => !deletedElements[i.value]);
};
