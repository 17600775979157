import { call, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import TheoryItemConnector from '../../../connectors/TheoryItem';
import { generateApiSaga } from '../../../utils/sagas';

const doDeleteItem = generateApiSaga(
  TheoryItemConnector,
  function* (connector, action) {
    return yield call([connector, connector.deleteById], action.payload.id);
  },
);

export default function* deleteItem() {
  yield takeEvery(ActionTypes.API_THEORY_ITEM_DELETE_BY_ID_INIT, doDeleteItem);
}
