import * as ActionTypes from '../../constants/ActionTypes';

export const pageLoad = (uri) => ({
  type: ActionTypes.APP_PAGE_ADMIN_HOME_LOAD,
  payload: { uri },
});

export const loadUserDetails = () => ({
  type: ActionTypes.APP_PAGE_ADMIN_HOME_USER_DETAILS_LOAD_INIT,
});

export const loadUserDetailsSuccess = () => ({
  type: ActionTypes.APP_PAGE_ADMIN_HOME_USER_DETAILS_LOAD_SUCCESS,
});

export const loadUserDetailsError = () => ({
  type: ActionTypes.APP_PAGE_ADMIN_HOME_USER_DETAILS_LOAD_ERROR,
});

export const updateUserDetails = (userDetails) => ({
  type: ActionTypes.APP_PAGE_ADMIN_HOME_USER_DETAILS_UPDATE_INIT,
  payload: { userDetails },
});

export const updateUserDetailsSuccess = () => ({
  type: ActionTypes.APP_PAGE_ADMIN_HOME_USER_DETAILS_UPDATE_SUCCESS,
});

export const updateUserDetailsError = () => ({
  type: ActionTypes.APP_PAGE_ADMIN_HOME_USER_DETAILS_UPDATE_ERROR,
});
