/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import * as Urls from '../../constants/Urls';
import ConditionalRoute from '../../components/routing/ConditionalRoute';
import NoPermissionPage from '../page/NoPermissionPage';

class PermissionRoute extends React.Component {
  static propTypes = {
    permission: PropTypes.shape({
      fn: PropTypes.func.isRequired,
      permissions: PropTypes.array,
    }),
    isLoggedIn: PropTypes.bool.isRequired,
    component: PropTypes.any.isRequired,
  };

  render() {
    const { hasPermission, isLoggedIn, component, exact } = this.props;
    const view = hasPermission ? component : NoPermissionPage;
    return (
      <ConditionalRoute
        exact={exact}
        condition={isLoggedIn}
        component={view}
        fallbackUrl={Urls.LOGIN_ROUTE}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const hasPermission = ownProps.permission.fn(
    state,
    ownProps.permission.permissions,
  );
  return { hasPermission };
};

export default compose(connect(mapStateToProps))(PermissionRoute);
