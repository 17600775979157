/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import PropTypes from 'prop-types';
import Text, { TextType } from '../atoms/Text';
import { fontColors } from '../styles/styles';
import { Trans } from 'react-i18next';

const styles = {
  '& a': {
    color: fontColors.BRAND,
    '&::before': {
      content: '"»"',
      display: 'inline-block',
      marginRight: '1em',
    },
  },
  '& ul': {
    listStyle: 'none',
    paddingLeft: 0,
  },
};

class LinkList extends React.Component {
  static propTypes = {
    title: PropTypes.string,
  };

  static defaultProps = {};

  render() {
    const { title, titleKey } = this.props;
    return (
      <div css={styles}>
        {title && <Text type={TextType.HEADER_3}>{title}</Text>}
        {titleKey && (
          <Text type={TextType.HEADER_3} className="text-title-case">
            <Trans i18nKey={titleKey} />
          </Text>
        )}
        <ul>
          {React.Children.map(this.props.children, (child, index) => (
            <li key={`${titleKey}-${index}`}>{child}</li>
          ))}
        </ul>
      </div>
    );
  }
}

export default LinkList;
