/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import GenericTable from '../molecules/GenericTable';
import { camelizeKebabCase } from '../../../utils/stringHelper';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import Button from '../atoms/Button';
import i18n from 'i18next';

export const getTableColumnHeaders = () =>
  Object.entries(
    i18n.t('user-overview-table.column-headers', { returnObjects: true }),
  ).map(([key, value]) => ({
    key: key,
    fieldAccessor: camelizeKebabCase(key),
    name: value,
  }));

export const getTableRows = (userDetails) =>
  (userDetails || []).map((userDetail) => ({
    userDetail: userDetail,
    firstName: userDetail.user?.firstName,
    lastName: userDetail.user?.lastName,
    companyName: userDetail.company?.name,
    companyCvr: userDetail.company?.companyVAT,
    email: userDetail.user?.email,
    status: userDetail.user?.active
      ? i18n.t('user-overview.status.active')
      : i18n.t('user-overview.status.inactive'),
    role: userDetail.licenses?.current?.role?.roleName,
    expirationDate: userDetail.licenses?.current
      ? moment(userDetail.licenses.current.expirationDate).format('yyyy-MM-DD')
      : undefined,
  }));

class UserOverviewTable extends React.Component {
  constructor(props) {
    super(props);
  }

  static propTypes = {
    userDetails: PropTypes.arrayOf(
      PropTypes.shape({
        user: PropTypes.object,
        company: PropTypes.object,
        licenses: PropTypes.shape({
          current: PropTypes.object,
        }),
      }),
    ),
    onUpdateRow: PropTypes.func,
    onDeleteRow: PropTypes.func,
  };

  static defaultProps = {
    userDetails: [],
  };

  renderRowButton = (row) => {
    return this.props.onUpdateRow ? (
      <Button
        type={Button.PRIMARY_SMALL}
        className="w-100"
        onClick={() => this.props.onUpdateRow(row)}
      >
        <FontAwesomeIcon className={'icon'} icon={faEdit} />
      </Button>
    ) : null;
  };

  render() {
    const columns = getTableColumnHeaders();
    const rows = getTableRows(this.props.userDetails);

    return (
      <GenericTable
        columns={columns}
        rows={rows}
        isSortable={true}
        renderRowButton={this.renderRowButton}
      />
    );
  }
}

export default withTranslation()(UserOverviewTable);
