import { call, select, takeLatest } from 'redux-saga/effects';
import * as ActionTypes from '../../constants/ActionTypes';
import AuthConnector from '../../connectors/Auth';
import { generateApiSaga } from '../../utils/sagas';
import { baseAuthUrl } from '../../selectors/store/app';

const doSetPassword = generateApiSaga(
  AuthConnector,
  function* (connector, action) {
    const { accessToken, newPassword } = action.payload;
    return yield call(
      [connector, connector.setPassword],
      accessToken,
      newPassword,
    );
  },
  function* (ConnectorClass) {
    const url = yield select(baseAuthUrl);
    return yield new ConnectorClass(url);
  },
);

export default function* setPassword() {
  yield takeLatest(ActionTypes.AUTH_SET_PASSWORD_INIT, doSetPassword);
}
