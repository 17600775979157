import { call, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import { generateApiSaga } from '../../../utils/sagas';
import RelationConnector from '../../../connectors/RelationConnector';

const doUpdate = generateApiSaga(
  RelationConnector,
  function* (connector, action) {
    const { id, entityType } = action.payload;
    return yield call([connector, connector.updateEntity], id, entityType);
  },
);

export default function* updateForNode() {
  yield takeEvery(
    ActionTypes.API_UPDATE_RELATION_COUNT_FOR_NODE_INIT,
    doUpdate,
  );
}
