import { call, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import theoryItemConnector from '../../../connectors/TheoryItem';
import { generateApiSaga } from '../../../utils/sagas';
import * as Projections from '../../../constants/Projections';

const doGetTheoryItemsByIdWithParents = generateApiSaga(
  theoryItemConnector,
  function* (connector, action) {
    const id = action.payload.id;
    return yield call([connector, connector.getById], id, Projections.PARENTS);
  },
);

export default function* getByIdWithParents() {
  yield takeEvery(
    ActionTypes.API_THEORY_ITEM_GET_BY_ID_WITH_PARENTS_INIT,
    doGetTheoryItemsByIdWithParents,
  );
}
